import React, { useEffect, useState } from 'react';
import '@Components/ui/switch-input/switch-input-styles.less';
import { classes } from '../utils';
import ConfirmActionModal from '../confirm-action-modal';

const SwitchInput = ({
  isOn, handleToggle, id, disabled = false, large = false,
  confirmEnable = null, confirmDisable = null, ...props
}) => {
  const [checked, setChecked] = useState(isOn || false);
  const [showConfirm, setShowConfirm] = useState(null);
  const hideConfirm = () => setShowConfirm(null);

  useEffect(() => {
    if (isOn !== checked) {
      setChecked(isOn);
    }
  }, [isOn]);

  const onToggle = (ev) => {
    if (disabled) {
      return;
    }
    if (checked && confirmDisable && !showConfirm) {
      setShowConfirm(confirmDisable);
      return;
    }
    if (!checked && confirmEnable && !showConfirm) {
      setShowConfirm(confirmEnable);
      return;
    }

    const prevChecked = checked;
    setChecked(!checked);
    hideConfirm();

    if (typeof handleToggle === 'function') {
      try {
        const func = handleToggle(ev);
        if (typeof func?.then === 'function') {
          func.catch(() => setChecked(prevChecked));
        }
        return func;
      } catch (error) {
        setChecked(prevChecked);
        throw error;
      }
    }
  };

  const labelClass = classes({
    'react-switch-label': true,
    disabled,
    large
  });
  const buttonClass = classes({
    'react-switch-button': true,
    large
  });

  return (
    <>
      <input
        checked={checked}
        onChange={onToggle}
        className="react-switch-checkbox"
        id={id}
        name={id}
        type="checkbox"
        {...props}
      />
      <label className={labelClass} htmlFor={id}>
        <span className={buttonClass} />
      </label>
      {showConfirm && (
        <ConfirmActionModal
          onConfirm={onToggle}
          onCancel={hideConfirm}
          confirmMessage={showConfirm}
        />
      )}
    </>
  );
};

export default SwitchInput;
