import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@Components/ui/button';
import Popover from '@Components/ui/popover';
import ConfirmBlock from '@Components/ui/confirm-block';
import { getOrgWideCustomerDb } from '@State/selectors';
import { deleteCustomer, fetchCustomerLogEntries } from '@State/customer-actions';
import { navigate, getSectionUrl } from '@Utils/navigate';
import SendSmsModal from '@Components/customers/customer-detail/send-sms/send-sms-modal';
import { DialogTitleBtn } from '@Components/dialogs/dialog-styles';
import { txt } from '@Utils/i18n-util';
import msg from './customer-detail-header.msg';

const CustomerDetailHeader = ({
  onCloseForm, modalView = false, routeParams, name, id, phoneNumber, locationId
}) => {
  const dispatch = useDispatch();
  const orgWideCustomerDb = useSelector(getOrgWideCustomerDb);
  const locationNames = useSelector(state => state.locationNames);

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showSendSms, setShowSendSms] = useState(false);
  const mounted = true;

  const handleClose = () => {
    if (mounted) {
      setShowConfirmDelete(false);
    }
  };

  const refreshLogEntries = () => {
    dispatch(fetchCustomerLogEntries(id, true));
  };

  const confirmDelete = async () => {
    await dispatch(deleteCustomer(id));
    handleClose();
    return navigate(getSectionUrl(routeParams, 'customers'));
  };

  const renderPopupBody = (
    <div className="Popover-dialog-sm button-wrapper">
      <ConfirmBlock
        title={txt(msg.confirmDelete)}
        onCancel={() => setShowConfirmDelete(false)}
        onConfirm={confirmDelete}
      />
    </div>
  );

  return (
    <div className="mb2">
      {modalView ? (
        <div className="pull-right">
          <DialogTitleBtn onClick={onCloseForm}>{txt(msg.btnClose)}</DialogTitleBtn>
        </div>
      ) : (
        <div className="pull-right">
          <Button gray small marginRight onClick={() => setShowSendSms(true)}>
            {txt(msg.btnSendSms)}
          </Button>
          <Popover
            preferPlace="below"
            isOpen={showConfirmDelete}
            onOuterAction={handleClose}
            body={renderPopupBody}
            className="Popover-big-radius"
          >
            <Button outlineDanger small onClick={() => setShowConfirmDelete(!showConfirmDelete)}>
              {txt(msg.btnDelete)}
            </Button>
          </Popover>
        </div>
      )}
      <h3 className="mt0 mb0">{name}</h3>
      {orgWideCustomerDb && (
        <div><i className="far fa-location-dot mr1" />{locationNames.get(locationId)}</div>
      )}
      <div className="text-muted">{txt(msg.lblCustomerNo)} #{id}</div>
      {showSendSms && (
        <SendSmsModal
          customerId={id}
          phoneNumber={phoneNumber}
          onClose={() => setShowSendSms(false)}
          onSmsSent={refreshLogEntries}
        />
      )}
    </div>
  );
};

export default CustomerDetailHeader;
