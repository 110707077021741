import React, { Component } from 'react';
import { connect } from 'react-redux';
import { payment } from '@Utils/preference-keys';
import { getJsonPreferences, mergeJsonPreferences, getInitialValues } from '@State/preferences-actions';
import PaymentConfigForm from './payment-config-form';

class PaymentConfig extends Component {
  componentDidMount() {
    this.props.getPaymentConfig();
  }

  render() {
    const { initialValues, savePaymentConfig } = this.props;

    return (
      <div className="subnav-container">
        <PaymentConfigForm
          initialValues={initialValues}
          onSubmit={savePaymentConfig}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    initialValues: getInitialValues(state, payment)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPaymentConfig: () => dispatch(getJsonPreferences()),
    savePaymentConfig: (values) => dispatch(mergeJsonPreferences(values))
  };
};

const PaymentConfigContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentConfig);

export default PaymentConfigContainer;
