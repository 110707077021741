import React, { Component } from 'react';
import { range } from 'ramda';
import ModalDialog from '@Components/dialogs/modal-dialog';
import styled from 'styled-components';
import Text from '@Components/ui/styled/text-element';
import Numpad from '@Components/inputs/numpad';
import { DialogInputPin } from '@Components/dialogs/dialog-styles';

const OperatorLoginStyle = {
  width: '320px',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: '20px 15px',
  background: '#f5f6f7',
  borderRadius: '8px'
};

const OperatorLoginPinEntryIndicator = styled(DialogInputPin)`
  margin-bottom: 20px;
`;

class OperatorLogin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pincode: '',
      error: false,
      isShowLoading: false
    };
  }

  handleSetPinCode = pincode => {
    this.setState({ error: false });
    if (pincode.length <= 4) {
      this.setState({ pincode });
    }
    if (pincode.length === 4) {
      this.submit(pincode);
    }
  };

  submit = pincode => {
    this.setState({ isShowLoading: true });

    return this.props.onSubmit(pincode)
      .catch(() => {
        this.setState({
          pincode: '',
          error: true,
          isShowLoading: false
        }, () => {
          setTimeout(() => this.setState({ error: false, isShowLoading: false }), 500);
        });
      });
  };

  renderPinIndicator = () => {
    const { pincode } = this.state;

    return (
      <OperatorLoginPinEntryIndicator>
        {range(1, 5).map(idx => (
          <span
            key={`pin_${idx}`}
            className={pincode.length >= idx ? 'is-selected' : ''}
          />
        ))}
      </OperatorLoginPinEntryIndicator>
    );
  };

  renderNumpad = () => {
    const { pincode, error, isShowLoading } = this.state;

    return (
      <Numpad
        pinPad
        backspace={this.props.backspace}
        disabled={isShowLoading || error}
        onChange={this.handleSetPinCode}
        onEnter={() => this.submit(pincode)}
        input={pincode}
      />
    );
  };

  render() {
    const { error, isShowLoading } = this.state;
    const {
      isNotDialog, onCancel, hideCloseButton, closeOnClickOutside = false, leftBtn, title, loadingTitle
    } = this.props;

    const className = error ? 'animated shake' : '';
    const titleText = isShowLoading
      ? (loadingTitle || 'Loggar in...')
      : (title || 'Ange din pinkod');

    if (isNotDialog) {
      return (
        <div style={OperatorLoginStyle} className={className}>
          <Text fs={18} marginBottom>{titleText}</Text>
          {this.renderPinIndicator()}
          {this.renderNumpad()}
        </div>
      );
    }

    return (
      <ModalDialog
        leftBtn={leftBtn}
        hideCloseButton={hideCloseButton}
        title={titleText}
        onClose={onCancel}
        className={className}
        closeOnClickOutside={closeOnClickOutside}
      >
        {this.renderPinIndicator()}
        {this.renderNumpad()}
      </ModalDialog>
    );
  }
}

export default OperatorLogin;
