import moment from 'moment';
import { Component } from 'react';

class PaymentStatusUpdate extends Component {
  constructor(props) {
    super(props);

    this.timeout = 5;
  }

  componentDidMount() {
    this.startTimer();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const messageChanged = this.props.paymentMessage !== nextProps.paymentMessage;
    const statusChanged = this.props.transactionStatus !== nextProps.transactionStatus;

    if (messageChanged || statusChanged) {
      this.onUpdate();
    }
  }

  componentWillUnmount() {
    this.stopTimer();
  }

  startTimer = () => {
    this.lastUpdate = moment();
    this.checkInterval = setInterval(this.checkTimeout, 1000);
  };

  stopTimer = () => {
    clearInterval(this.checkInterval);
  }

  onUpdate = () => {
    this.lastUpdate = moment();
  }

  checkTimeout = () => {
    const { saleId, terminalId } = this.props;
    const timeoutLimit = moment().subtract(this.timeout, 'seconds');

    if (this.lastUpdate < timeoutLimit) {
      this.props.fetchPaymentStatus(saleId, terminalId)
        .then(this.onUpdate)
        .catch(this.stopTimer);
    }
  };

  render() {
    return null;
  }
}

export default PaymentStatusUpdate;
