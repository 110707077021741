import React from 'react';
import PropTypes from 'prop-types';
import { getDurationAndPrice } from '@Utils/service-util';
import { colors } from '@Components/ui/styled/variables';
import Label from '@Components/ui/label';

export default class ResourceServiceItem extends React.Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    serviceDuration: PropTypes.number.isRequired,
    afterTime: PropTypes.number.isRequired,
    webAllowBooking: PropTypes.bool.isRequired,
    webShowDuration: PropTypes.bool.isRequired,
    webShowPrice: PropTypes.bool.isRequired,
    price: PropTypes.number,
    priceFrom: PropTypes.bool.isRequired
  };

  selectService = (ev) => {
    this.props.selectService(this.props.id, ev.target.checked);
  };

  setCustomValues = (ev) => {
    ev.preventDefault();
    this.props.setCustomValues(this.props.id);
  };

  render() {
    const { active, name, customValues, webAllowBooking, addon } = this.props;
    const description = getDurationAndPrice(this.props);

    return (
      <tr>
        <td>
          <input type="checkbox" checked={active} onChange={this.selectService} />
        </td>
        <td>
          {name}<br />
          <span className="text-muted">
            {description}
          </span>
        </td>
        <td>
          {webAllowBooking && <Label bgColor={colors.brandBlue}>Webb</Label>}{' '}
          {addon && <Label bgColor={colors.primaryLight}>Tillägg</Label>}
        </td>
        <td className="text-right">
          {customValues && <i className="fa fa-exclamation-circle text-info" />}&nbsp;&nbsp;
          <a href="#" className={active ? '' : 'disabled'} onClick={this.setCustomValues}>Inställningar</a>
        </td>
      </tr>
    );
  }
}
