import moment from 'moment';
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getArticleName } from '@Utils/pos-utils';
import useVisibleProductGroups from '@Hooks/useVisibleProductGroups';
import { deleteStockReport, getStockReportItems } from '@State/report-actions';
import { Button, Panel } from '@Components/ui/styled/main';
import Loader from '@Components/ui/loader';
import { ReportTitle, ReportTitleButtons } from '@Components/pos/style';
import { reportingFormat } from '@Utils/format';
import { getSectionUrl, navigate } from '@Utils/navigate';
import PopoverDeleteButton from '@Components/ui/popover-delete-button';
import StockReportSummary from './stock-report-summary';

const StockReportItems = ({ routeParams, stockReportId }) => {
  const dispatch = useDispatch();
  const stockReports = useSelector(state => state.reportsViewState.get('stockReports'));
  const items = useSelector(state => state.reportsViewState.get('stockReportItems'));
  const productGroups = useSelector(state => state.productGroups);
  const visibleGroups = useVisibleProductGroups(productGroups, items);
  const stockReport = stockReports.find(r => r.get('id') == stockReportId);
  const { label, createdTs } = stockReport?.toJS() || {};

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (stockReportId) {
      loadStockReport();
    } else {
      setLoading(false);
    }
  }, [stockReportId]);

  const loadStockReport = () => {
    setLoading(true);
    dispatch(getStockReportItems(stockReportId))
      .finally(() => setLoading(false));
  };

  const onPrint = (ev) => {
    ev.preventDefault();
    ev.target.blur();
    window.print();
  };

  const onDeleteReport = useCallback(() => {
    return dispatch(deleteStockReport(stockReportId))
      .then(() => navigate(getSectionUrl(routeParams, 'reports/stock')));
  }, [stockReportId]);

  if (loading) {
    return <Loader />;
  }

  if (!items || items.isEmpty()) {
    return (
      <div className="alert alert-info">
        Rapporten innnehåller inga produkter. Klicka på "Skapa rapport" för att skapa en ny rapport.
      </div>
    );
  }

  return (
    <>
      <ReportTitle>
        <h3 className="mt0 mb0">{label}</h3>
        <ReportTitleButtons className="hidden-print">
          <Button small gray marginRight onClick={onPrint}>Skriv ut</Button>
          <PopoverDeleteButton onDelete={onDeleteReport} />
        </ReportTitleButtons>
      </ReportTitle>
      <p className="mb4">
        Skapad: {moment(createdTs).format('LL')}
      </p>
      <StockReportSummary products={items.toJS()} />
      <Panel>
        {visibleGroups.map(group => {
          let totalValue = 0;
          let totalQuantity = 0;
          const groupId = `group_${stockReportId}_${group.id}`;
          return (
            <table key={groupId} className="table table-condensed table-report">
              <thead>
                <tr>
                  <th>{group.name}</th>
                  <th className="col-xs-2 text-right">Antal</th>
                  <th className="col-xs-3 text-right">Inköpspris / st *</th>
                  <th className="col-xs-3 text-right">Anskaffningsvärde</th>
                </tr>
              </thead>
              <tbody>
                {items.filter(i => i.get('groupId') === group.id).map(item => {
                  const article = item.toJS();
                  const { stockQuantity, fifoAvgPrice, id } = article;
                  const itemId = `article_${stockReportId}_${id}`;
                  const quantity = stockQuantity || 0;
                  const value = parseInt(quantity) * parseFloat(fifoAvgPrice || 0);
                  totalQuantity += quantity;
                  totalValue += value;
                  return (
                    <tr key={itemId}>
                      <td>{getArticleName(article)}</td>
                      <td className="text-right">{quantity}</td>
                      <td className="text-right">{reportingFormat(fifoAvgPrice)}</td>
                      <td className="text-right">{reportingFormat(value)}</td>
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr>
                  <th>Summa</th>
                  <th className="text-right">{totalQuantity}</th>
                  <th />
                  <th className="text-right">{reportingFormat(totalValue)}</th>
                </tr>
              </tfoot>
            </table>
          );
        })}
      </Panel>
      <div className="text-muted mt1">
        * Beräknat snittvärde av inköpspris på lagersaldot, enligt FIFO principen
      </div>
    </>
  );
};

export default StockReportItems;
