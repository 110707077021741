import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSysAdmin } from '@State/selectors';
import { accounting, pos } from '@Utils/preference-keys';
import { setLastVisitedPage } from '@Utils/session';
import {
  connectExistingFortnoxToken, fetchFortnoxAuthUrl, fetchFortnoxSyncStatus, fetchFortnoxTokenStatus,
  fortnoxDisconnect, fortnoxPause, fortnoxRunSync, fortnoxStart, toggleFortnoxInvoice
} from '@State/fortnox-actions';
import { mergeOrgSettings } from '@State/pos-config-actions';
import { Button, Panel, Text } from '@Components/ui/styled/main';
import LabelSwitchInput from '@Components/ui/label-switch-input';
import ProgressButton from '@Components/ui/progress-button';
import Loader from '@Components/ui/loader';
import AutoSaveTextInput from '@Components/inputs/auto-save-text-input';
import { getPosOrgAccountingPrefs, getPosOrgInvoicePrefs } from '@State/pos-config-selectors';
import AccountingConfigStatus from './accounting-config-status';
import FortnoxVouchers from './fortnox-vouchers';
import FortnoxSyncStatus from './fortnox-sync-status';
import PosOrgFortnoxSyncModal from './pos-org-fortnox-sync-modal';
import PosOrgConfigToggle from './pos-org-config-toggle';
import PosOrgAccountingSyncCustomers from './pos-org-accounting-sync-customers';
import PosOrgInvoiceModal from './pos-org-invoice-modal';

const PosOrgAccountingFortnox = (props) => {
  const { posOrg } = props;
  const posOrgId = posOrg.get('id');

  const dispatch = useDispatch();
  const isSysAdmin = useSelector(getSysAdmin);

  const invoicePrefs = useSelector(state => getPosOrgInvoicePrefs(state, props));
  const accountingPrefs = useSelector(state => getPosOrgAccountingPrefs(state, props));
  const voucherSeries = accountingPrefs[accounting.voucherSeries];
  const accessToken = accountingPrefs[accounting.fortnoxAccessToken];
  const isEnabled = !!accountingPrefs[accounting.fortnoxSyncEnabled];
  const useFortnoxInvoice = !!invoicePrefs[pos.invoiceUseFortnox];

  const [loading, setLoading] = useState(false);
  const [tokenStatus, setTokenStatus] = useState(null);
  const [syncStatus, setSyncStatus] = useState(null);
  const [showConfig, setShowConfig] = useState(false);
  const [showVouchers, setShowVouchers] = useState(false);
  const [runFortnoxSync, setRunFortnoxSync] = useState(false);
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const hideInvoiceModal = () => setShowInvoiceModal(false);

  const validTokenStatus = ['TokenValid', 'LegacyTokenPresent'];
  const hasValidToken = validTokenStatus.includes(tokenStatus?.status);
  const isTokenExpired = tokenStatus?.status === 'TokenExpired';
  const isAuthenticated = hasValidToken || isTokenExpired;

  const fetchSyncStatus = useCallback(() => dispatch(fetchFortnoxSyncStatus(posOrgId))
    .then(res => setSyncStatus(res)), [posOrgId]);

  const fetchTokenStatus = useCallback(() => dispatch(fetchFortnoxTokenStatus(posOrgId))
    .then(res => setTokenStatus(res)), [posOrgId]);

  const connectExistingToken = useCallback(() => dispatch(connectExistingFortnoxToken(posOrgId))
    .then(res => setTokenStatus(res)), [posOrgId]);

  const handleSave = useCallback((values) => dispatch(mergeOrgSettings(posOrgId, values)), [posOrgId]);

  const handleStart = useCallback(() => {
    return dispatch(fortnoxStart(posOrgId))
      .then(() => fetchSyncStatus());
  }, [posOrgId]);

  const handlePause = useCallback(() => {
    return dispatch(fortnoxPause(posOrgId))
      .then(() => fetchSyncStatus());
  }, [posOrgId]);

  const handleDisconnect = useCallback(() => {
    return dispatch(fortnoxDisconnect(posOrgId))
      .then(() => fetchTokenStatus());
  }, [posOrgId]);

  const handleRunSync = useCallback((start, end) => {
    return dispatch(fortnoxRunSync(posOrgId, start, end))
      .then(() => fetchSyncStatus())
      .then(() => setRunFortnoxSync(false));
  }, [posOrgId]);

  const handleConnect = useCallback(() => {
    if (tokenStatus?.status === 'TokenDisconnected') {
      return connectExistingToken()
        .then(() => fetchSyncStatus());
    }
    return dispatch(fetchFortnoxAuthUrl(posOrgId))
      .then(({ linkUrl }) => {
        setLastVisitedPage(window.location.pathname);
        window.location = linkUrl;
      });
  }, [posOrgId, tokenStatus]);

  const handleExpiredToken = () => {
    return handleDisconnect().then(handleConnect);
  };

  const handleInvoiceToggle = () => {
    return dispatch(toggleFortnoxInvoice(posOrgId, !useFortnoxInvoice));
  };

  const onChangeFortnoxService = useCallback((ev) => {
    return dispatch(mergeOrgSettings(posOrgId, {
      [pos.invoiceFortnoxService]: ev.target.value
    }));
  }, [posOrgId]);

  useEffect(() => {
    setLoading(true);
    setSyncStatus(null);
    setTokenStatus(null);
    Promise.all([
      fetchSyncStatus(),
      fetchTokenStatus()
    ]).finally(() => setLoading(false));
  }, [posOrgId]);

  if (loading) {
    return (
      <Panel minHeight="80px" maxWidth="550px">
        <Loader />
      </Panel>
    );
  }

  return (
    <>
      {isAuthenticated ? (
        <>
          <Panel maxWidth="550px">
            <h5 className="mb0">
              <div className="pull-right">
                {hasValidToken ? (
                  <span><i className="fa fa-circle text-success" /> Aktiv</span>
                ) : (
                  <span><i className="fa fa-circle text-danger" /> Ej aktiv</span>
                )}
              </div>
              Koppling till Fortnox
            </h5>
            {isTokenExpired && (
              <div className="text-muted mt1">
                Fortnox-kopplingen har gått ut, du behöver göra om kopplingen.<br /><br />
                <ProgressButton small outlinePrimary onClick={handleExpiredToken}>
                  Koppla till Fortnox-konto
                </ProgressButton>
              </div>
            )}
          </Panel>
          <Panel maxWidth="550px">
            <div className="form-group select-text">
              <LabelSwitchInput
                noBorder
                label="Automatisk bokföring"
                id={accounting.fortnoxSyncEnabled}
                isOn={isEnabled}
                handleToggle={isEnabled ? handlePause : handleStart}
                description="Aktiverar automatisk bokföring med Fortnox"
              />
              <FortnoxSyncStatus syncStatus={syncStatus} /><br />
              <AutoSaveTextInput
                required
                label="Verifikationsserie"
                name={accounting.voucherSeries}
                initialValue={voucherSeries}
                onSave={handleSave}
                width={150}
              />
              {accessToken && <Text muted marginTop="10px" fs={13}>Access token: {accessToken}</Text>}
            </div>
          </Panel>
          <Panel maxWidth="550px">
            <LabelSwitchInput
              noBorder
              label="Faktura via Fortnox"
              id={pos.invoiceUseFortnox}
              isOn={useFortnoxInvoice}
              handleToggle={handleInvoiceToggle}
              description="Skapa och skicka fakturor med Fortnox"
              confirmDisable="Vill du avaktivera fakturering via Fortnox?"
            >
              <div className="form-group mb3">
                <label className="control-label">Tilläggstjänst</label>
                <div className="select-container">
                  <select
                    className="form-control"
                    onChange={onChangeFortnoxService}
                    value={posOrg.getIn(['prefs', pos.invoiceFortnoxService])}
                  >
                    <option value="NONE">Ingen</option>
                    <option value="SERVICE_FULL">Fakturaservice</option>
                  </select>
                </div>
              </div>
              <PosOrgConfigToggle
                isChild
                posOrg={posOrg}
                preference={pos.invoiceFortnoxBookAndSend}
                label="Bokför och skicka"
                description="Bokför och skicka fakturor direkt när de skapas"
              />
              <div className="mt4">
                <Button small outlinePrimary marginRight onClick={() => setShowInvoiceModal(true)}>
                  Fakturainställningar
                </Button>
                {showInvoiceModal && (
                  <PosOrgInvoiceModal
                    posOrg={posOrg}
                    onClose={hideInvoiceModal}
                    onSaved={hideInvoiceModal}
                  />
                )}
                {isSysAdmin && (
                  <PosOrgAccountingSyncCustomers posOrg={posOrg} />
                )}
              </div>
            </LabelSwitchInput>
          </Panel>
        </>
      ) : (
        <Panel maxWidth="550px">
          <Text fs={15} marginBottom>Aktivera Fortnox-integration för att synka med Fortnox</Text>
          <Text muted fs={13}>Du kommer att skickas till Fortnox för att logga in och godkänna att Cliento får åtkomst till ditt Fortnox-konto.</Text><br />
          <ProgressButton small outlinePrimary onClick={handleConnect}>Koppla till Fortnox-konto</ProgressButton>
        </Panel>
      )}
      <Panel maxWidth="550px">
        <Button small outlinePrimary marginRight onClick={() => setShowConfig(true)}>
          Kontoplan
        </Button>
        {showConfig && (
          <AccountingConfigStatus
            prefs={posOrg.get('prefs')?.toJS() || {}}
            onClose={() => setShowConfig(false)}
            posOrgId={posOrg.get('id')}
            isAuthenticated={isAuthenticated}
          />
        )}
        {isSysAdmin && isAuthenticated && (
          <Button small outlinePrimary marginRight onClick={() => setShowVouchers(true)}>
            Visa bokföring
          </Button>
        )}
        {showVouchers && (
          <FortnoxVouchers
            onClose={() => setShowVouchers(false)}
            posOrgId={posOrg.get('id')}
          />
        )}
        {isSysAdmin && isAuthenticated && (
          <Button small outlinePrimary marginRight onClick={() => setRunFortnoxSync(true)}>
            Kör bokföring
          </Button>
        )}
        {runFortnoxSync && (
          <PosOrgFortnoxSyncModal
            syncStatus={syncStatus}
            onRunSync={handleRunSync}
            onClose={() => setRunFortnoxSync(false)}
          />
        )}
        {isSysAdmin && isAuthenticated && <ProgressButton small outlineDanger onClick={handleDisconnect}>Koppla från</ProgressButton>}
      </Panel>
    </>
  );
};

export default PosOrgAccountingFortnox;
