import { defineMessages } from 'react-intl';

export default defineMessages({
  lblSmsReminders: {
    id: 'customers.overview.lblSmsReminders',
    defaultMessage: 'SMS reminders'
  },
  lblAllowMarketing: {
    id: 'customers.overview.lblAllowMarketing',
    defaultMessage: 'Allow marketing'
  },
  lblReviewReminders: {
    id: 'customers.overview.lblReviewReminders',
    defaultMessage: 'Allow reviews'
  },
  lblBlockWebBooking: {
    id: 'customers.overview.lblBlockWebBooking',
    defaultMessage: 'Block booking'
  }
});
