import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { deleteReleaseNoteImage, uploadReleaseNoteImage } from '@State/sysadmin-actions';
import Loader from '@Components/ui/loader';

const dropzoneOptions = {
  noKeyboard: true,
  accept: {
    'image/png': ['.png'],
    'image/jpeg': ['.jpg', '.jpeg']
  }
};

const ReleaseNotesImage = ({ id, imageUrl }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(!!imageUrl);
  }, [imageUrl]);

  const onDrop = (droppedFiles) => {
    if (droppedFiles.length > 0) {
      setLoading(true);
      dispatch(uploadReleaseNoteImage(id, droppedFiles[0]));
    }
  };

  const removeImage = (ev) => {
    ev.stopPropagation();
    dispatch(deleteReleaseNoteImage(id));
  };

  const {
    getRootProps, getInputProps, isDragActive
  } = useDropzone({ ...dropzoneOptions, onDrop });

  return (
    <div className="image-drag-container" {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive && (
        <div className="image-drag-overlay" />
      )}
      {loading && <Loader />}
      {imageUrl ? (
        <div>
          <img src={imageUrl} onLoad={() => setLoading(false)} />
          <button onClick={removeImage}>
            <i className="fa fa-times" />
          </button>
        </div>
      ) : (
        <div className="image-placeholder">
          <i className="fa fa-image mr1" />
          Lägg till bild
        </div>
      )}
    </div>
  );
};

export default ReleaseNotesImage;
