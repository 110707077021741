import React from 'react';
import ModalDialog from '@Components/dialogs/modal-dialog';
import SearchProduct from './search-product';

const ProductDeliveryModal = ({
  onClose, onSelect
}) => (
  <ModalDialog
    title="Inleverans"
    contentSize="small"
    hideCloseButton
    closeOnClickOutside={false}
    onClose={onClose}
    buttons={[{
      gray: true,
      name: 'Avsluta inleverans',
      onClick: onClose
    }]}
  >
    <SearchProduct onSelect={onSelect} />
  </ModalDialog>
);

export default ProductDeliveryModal;
