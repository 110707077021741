import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import JSONEditorContent from '@Components/ui/jsoneditor/jsoneditor-form';
import { saveCustomTextsPreferences } from '@State/preferences-actions';

const AdminConfigCustomTexts = () => {
  const dispatch = useDispatch();
  const customTexts = useSelector(state => state.preferencesViewState.get('customTextsPreferences')) || {};

  return (
    <JSONEditorContent
      title="Custom Texts"
      json={customTexts}
      onSubmit={(values) => dispatch(saveCustomTextsPreferences(values))}
    />
  );
};

export default AdminConfigCustomTexts;
