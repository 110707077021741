import React from 'react';
import moment from 'moment';
import CurrencyUtil from '@Utils/currency-util';
import ModalDialog from '@Components/dialogs/modal-dialog';
import ColumnListItem from '@Components/ui/column/column-list-item';
import AlertWithIcon from '@Components/ui/alert-with-icon';

const SelectParkedSaleModal = ({ parkedSales, isSaleEmpty, onSelect, onClose }) => {
  const receiptButtons = parkedSales.map((sale) => {
    const timeStamp = moment(sale.get('createdTs')).format('DD MMM HH:mm');
    const totalAmount = sale.get('paidAmount') + sale.get('remainingAmount');
    const customer = sale.get('customer');
    const customerName = customer
      ? customer.name || customer.get('name')
      : null;

    return (
      <ColumnListItem
        icon="fa fa-receipt"
        header={timeStamp}
        subText={customerName}
        subHeader={CurrencyUtil.accountCurrency(totalAmount)}
        onClick={() => onSelect(sale)}
      />
    );
  });

  return (
    <ModalDialog
      title="Hämta parkerat kvitto"
      onClose={onClose}
    >
      {!isSaleEmpty && (
        <AlertWithIcon>
          Pågående kvitto kommer att parkeras.
        </AlertWithIcon>
      )}
      {receiptButtons}
    </ModalDialog>
  );
};

export default SelectParkedSaleModal;
