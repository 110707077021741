import { useEffect } from 'react';
import usePrevious from '@Hooks/usePrevious';

export default (value, callback) => {
  const prevValue = usePrevious(value);
  useEffect(() => {
    if (value && prevValue && value !== prevValue) {
      callback(value);
    }
  }, [value]);
};
