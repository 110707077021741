import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getGroupUrl, getModeUrl, getResourceEntityType, getResourceUrl } from '@Utils/navigate';
import Segment, { SegmentItem } from '@Components/ui/tb-segment';
import { txt } from '@Utils/i18n-util';
import msg from './viewmode-switcher.msg';

class ViewModeSwitcher extends React.Component {
  static propTypes = {
    routeParams: PropTypes.object.isRequired,
    orderedGroups: PropTypes.object.isRequired
  };

  modeUrl(routeParams, orderedGroups, viewMode) {
    const { phoneMode, tabletMode } = this.props;
    if ((phoneMode || tabletMode) && viewMode === 'week') {
      return resourceUrl(routeParams, orderedGroups, viewMode);
    }
    return getModeUrl(viewMode, routeParams, true);
  }

  render() {
    const {
      routeParams, orderedGroups, phoneMode
    } = this.props;
    const { viewMode, entityType, entityId } = routeParams;
    // const group = entityType === 'group';
    // const myGroup = group && entityId === 0;
    const day = viewMode === 'day';
    const week = viewMode === 'week';

    const dayHref = this.modeUrl(routeParams, orderedGroups, 'day');
    const weekHref = this.modeUrl(routeParams, orderedGroups, 'week');
    // const groupHref = groupUrl(routeParams, orderedGroups);

    return (
      <Segment phoneMode={phoneMode}>
        <SegmentItem href={dayHref} active={day} phoneMode={phoneMode}>{txt(msg.day)}</SegmentItem>
        <SegmentItem href={weekHref} active={week} phoneMode={phoneMode}>{txt(msg.week)}</SegmentItem>
        {/*
        <SegmentItem href={groupHref} active={group && !myGroup} disabled={myGroup} phoneMode={phoneMode}>{txt(msg.group)}</SegmentItem>
        */}
      </Segment>
    );
  }
}

function resourceUrl(routeParams, orderedGroups, viewMode) {
  const { entityType, entityId } = routeParams;

  if (entityType === 'group') {
    let group = orderedGroups.find(g => g.get('id') === parseInt(entityId));
    if (group != null) {
      // If group has no reosources, find first group with reosources
      //
      if (group.get('resourceIds').size === 0) {
        group = orderedGroups.find(g => g.get('resourceIds').size > 0);
      }

      const resourceEntityType = getResourceEntityType(group.get('id'));
      return getResourceUrl(group.get('resourceIds').first(), resourceEntityType, viewMode, routeParams);
    }
    return '';
  }
  return getModeUrl(viewMode, routeParams);
}

function groupUrl(routeParams, orderedGroups) {
  const { entityType, entityId } = routeParams;

  if (entityType === 'group') {
    return getModeUrl('day', routeParams);
  }
  const group = orderedGroups.find(g => g.get('resourceIds').includes(parseInt(entityId)));
  if (group == null) return '';

  return getGroupUrl(group.get('id'), routeParams);
}

const mapStateToProps = (state) => {
  const { orderedGroups, mainViewState } = state;

  return {
    orderedGroups,
    phoneMode: mainViewState.get('phoneMode'),
    tabletMode: mainViewState.get('tabletMode')
  };
};

export default connect(mapStateToProps)(ViewModeSwitcher);
