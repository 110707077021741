import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Loader from '@Components/ui/loader';
import {
  getSysadminLocationHardwarePricing,
  getSysadminStateOrganizationById
} from '@Components/sysadmin/accounts/accounts-selector';

import { fetchLocationHardwarePricingApi, fetchOrganizationByIdApi, fetchSysadminCompanyInfo } from '@State/sysadmin-actions';
import AccountOverviewHardware
from '@Components/sysadmin/accounts/account-detail/account-overview/account-overview-hardware/account-overview-hardware';
import AccountOverviewCompany
from '@Components/sysadmin/accounts/account-detail/account-overview/account-overview-company';
import AccountOverviewPrice
from '@Components/sysadmin/accounts/account-detail/account-overview/account-overview-price';
import AccountOverviewContext
from '@Components/sysadmin/accounts/account-detail/account-overview/account-overview-context';
import AccountOverviewInvoice
from '@Components/sysadmin/accounts/account-detail/account-overview/account-overview-invoice';
import { useFetchAccount } from '../../useFetchAccount';

const AccountOverview = () => {
  const dispatch = useDispatch();

  const subscription = useSelector(state => getSysadminStateOrganizationById(state));
  const locationHardwarePricing = useSelector(state => getSysadminLocationHardwarePricing(state));
  const orgCtxName = subscription?.get('orgCtxName');

  const [id, loading] = useFetchAccount(async (orgId) => {
    await dispatch(fetchOrganizationByIdApi(orgId));
    await dispatch(fetchLocationHardwarePricingApi(orgId));
  });

  useEffect(() => {
    if (orgCtxName) {
      dispatch(fetchSysadminCompanyInfo(orgCtxName));
    }
  }, [orgCtxName]);

  if (loading && subscription.size === 0) {
    return <Loader />;
  }

  return (
    <div>
      <AccountOverviewContext.Provider value={{ id, subscription, locationHardwarePricing }}>
        <AccountOverviewCompany />
        <AccountOverviewPrice />
        <AccountOverviewInvoice />
        <AccountOverviewHardware />
      </AccountOverviewContext.Provider>
    </div>
  );
};

export default AccountOverview;
