import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

export const DraggableListContainer = ({ children, onDragEnd, style = {}, className, droppableId }) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={droppableId ?? className}>
        {(provided) => (
          <div
            className={className}
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={style}
          >
            {children}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export const DraggableListItem = ({
  children,
  handler,
  handlerStyle = {},
  handlerClassName,
  id,
  index,
  getItemStyle,
  style = {},
  className,
  isDragDisabled,
  onClick
}) => {
  return (
    <Draggable draggableId={id} index={index} isDragDisabled={isDragDisabled}>
      {(provided, snapshot) => (
        <div
          onClick={onClick}
          className={className}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...(handler ? {} : provided.dragHandleProps)}
          style={getItemStyle ? getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style
          ) : { ...style, ...provided.draggableProps.style }}
        >
          {handler && <div {...provided.dragHandleProps} className={handlerClassName} style={handlerStyle}>{handler}</div>}
          {children}
        </div>
      )}
    </Draggable>
  );
};
