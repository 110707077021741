import axios from 'axios';
import {
  axiosDefault, axiosErrorHandler, axiosFormData, checkStatusAxios, prefixUrl
} from '@Utils/ajax-util';
import { prefixApiUrl, prefixBookingUrl } from '@Utils/url-util';
import { getSysadminLocations } from '@Components/sysadmin/accounts/accounts-selector';

const defaultUrl = '/sysadmin/orgs/';

export const SYSTEM_ADMIN_ORGANIZATIONS_FETCHED = 'SYSTEM_ADMIN_ORGANIZATIONS_FETCHED';
export const SYSTEM_ADMIN_CONTROL_SORT = 'SYSTEM_ADMIN_CONTROL_SORT';
export const SYSTEM_ADMIN_CONTROL_FILTER_TYPE = 'SYSTEM_ADMIN_CONTROL_FILTER_TYPE';
export const SYSTEM_ADMIN_ORGANIZATION_ID_FETCHED = 'SYSTEM_ADMIN_ORGANIZATION_ID_FETCHED';
export const SYSTEM_ADMIN_ORGANIZATION_ID_CLEAR = 'SYSTEM_ADMIN_ORGANIZATION_ID_CLEAR';
export const SYSTEM_ADMIN_CONTACTS_FETCHED = 'SYSTEM_ADMIN_CONTACTS_FETCHED';

export const LOCATION_HARDWARE_PRICING_ID_FETCHED = 'LOCATION_HARDWARE_PRICING_ID_FETCHED';
export const LOCATION_HARDWARE_PRICING_ID_CREATED = 'LOCATION_HARDWARE_PRICING_ID_CREATED';
export const LOCATION_HARDWARE_PRICING_ID_UPDATED = 'LOCATION_HARDWARE_PRICING_ID_UPDATED';
export const LOCATION_HARDWARE_PRICING_ID_DELETED = 'LOCATION_HARDWARE_PRICING_ID_DELETED';

export const ACCOUNT_SMS_BILLING_UPDATED = 'ACCOUNT_SMS_BILLING_UPDATED';
export const ACCOUNT_SMS_ALLOWED_UPDATED = 'ACCOUNT_SMS_ALLOWED_UPDATED';
export const ACCOUNT_SMS_CAMPAIGN_UPDATED = 'ACCOUNT_SMS_CAMPAIGN_UPDATED';
export const ACCOUNT_SMS_LIMITS_UPDATED = 'ACCOUNT_SMS_LIMITS_UPDATED';
export const ACCOUNT_SMS_UPDATED = 'ACCOUNT_SMS_UPDATED';
export const ACCOUNT_EMAIL_CAMPAIGN_UPDATED = 'ACCOUNT_EMAIL_CAMPAIGN_UPDATED';

export const ACCOUNT_INVOICE_FETCHED = 'ACCOUNT_INVOICE_FETCHED';
export const ACCOUNT_INVOICE_DELETED = 'ACCOUNT_INVOICE_DELETED';
export const ACCOUNT_INVOICE_UPDATED = 'ACCOUNT_INVOICE_UPDATED';
export const ACCOUNT_INVOICE_CREATED = 'ACCOUNT_INVOICE_CREATED';

export const ACCOUNT_TYPE_UPDATED = 'ACCOUNT_TYPE_UPDATED';
export const ACCOUNT_TERMINATED_UPDATED = 'ACCOUNT_TERMINATED_UPDATED';
export const ACCOUNT_ACTIVATED_UPDATED = 'ACCOUNT_ACTIVATED_UPDATED';
export const ACCOUNT_PRICE_UPDATED = 'ACCOUNT_PRICE_UPDATED';
export const ACCOUNT_CONTRACT_UPDATED = 'ACCOUNT_CONTRACT_UPDATED';
export const ACCOUNT_PAID_UNTIL_UPDATED = 'ACCOUNT_PAID_UNTIL_UPDATED';
export const ACCOUNT_CREDIT_AMOUNT_UPDATED = 'ACCOUNT_CREDIT_AMOUNT_UPDATED';
export const ACCOUNT_TRIAL_DAYS_UPDATED = 'ACCOUNT_TRIAL_DAYS_UPDATED';
export const ACCOUNT_PRICE_VUNIT_UPDATED = 'ACCOUNT_PRICE_VUNIT_UPDATED';
export const ACCOUNT_PAYMENT_METHOD_UPDATED = 'ACCOUNT_PAYMENT_METHOD_UPDATED';
export const ACCOUNT_INVOICE_CUSTOMER_UPDATED = 'ACCOUNT_INVOICE_CUSTOMER_UPDATED';
export const ACCOUNT_FORTNOX_INVOICE_CREATED = 'ACCOUNT_FORTNOX_INVOICE_CREATED';
export const ACCOUNT_LOCATION_TERMINATED = 'ACCOUNT_LOCATION_TERMINATED';
export const ACCOUNT_LEAD_SENT = 'ACCOUNT_LEAD_SENT';
export const ACCOUNT_PREFS_UPDATED = 'ACCOUNT_PREFS_UPDATED';

export const LOCATIONS_FETCHED = 'LOCATIONS_FETCHED';
export const PRICE_PLANS_FETCHED = 'PRICE_PLANS_FETCHED';
export const RELEASE_NOTES_FETCHED = 'RELEASE_NOTES_FETCHED';
export const RELEASE_NOTE_CREATED = 'RELEASE_NOTE_CREATED';
export const RELEASE_NOTE_UPDATED = 'RELEASE_NOTE_UPDATED';
export const RELEASE_NOTE_DELETED = 'RELEASE_NOTE_DELETED';
export const RELEASE_NOTE_IMAGE_UPDATED = 'RELEASE_NOTE_IMAGE_UPDATED';

export const ACCOUNT_LOGS_FETCHED = 'ACCOUNT_LOGS_FETCHED';
export const ACCOUNT_COMMENT_SAVED = 'ACCOUNT_COMMENT_SAVED';
export const ACCOUNT_COMMENT_UPDATED = 'ACCOUNT_COMMENT_UPDATED';
export const ACCOUNT_COMMENT_DELETED = 'ACCOUNT_COMMENT_DELETED';

export const toggleSysadminControl = (actionType, value) => ({
  type: `SYSTEM_ADMIN_CONTROL_${actionType}`,
  value
});

export const fetchOrganizationInvoice = ({ id, fromDate, toDate }) => {
  return async (dispatch) => {
    const url = prefixUrl(`${defaultUrl}${id}/invoices/?fromDate=${fromDate}&toDate=${toDate}`);
    const config = axiosDefault();
    try {
      const response = await axios.get(url, config);
      dispatch(checkStatusAxios(response));
      dispatch({ type: ACCOUNT_INVOICE_FETCHED, invoice: response.data });
    } catch (e) {
      axiosErrorHandler(e, dispatch);
    }
  };
};

export const deleteInvoiceApi = ({ invoiceUUID, invoiceItemId }) => {
  return async (dispatch) => {
    const url = prefixUrl(`/invoices/${invoiceUUID}/invoice-item/${invoiceItemId}`);
    const config = axiosDefault();
    try {
      const response = await axios.delete(url, config);
      dispatch(checkStatusAxios(response));
      dispatch({ type: ACCOUNT_INVOICE_DELETED, data: { invoiceUUID, invoiceItemId } });
    } catch (e) {
      axiosErrorHandler(e, dispatch);
    }
  };
};

export const updateInvoiceItem = ({ invoiceUUID, invoiceItemId, ...body }) => {
  return async (dispatch) => {
    const url = prefixUrl(`/invoices/${invoiceUUID}/invoice-item/${invoiceItemId}`);
    const config = axiosDefault();

    try {
      const response = await axios.put(url, body, config);
      dispatch(checkStatusAxios(response));
      dispatch({
        type: ACCOUNT_INVOICE_UPDATED,
        data: {
          ...body, id: invoiceItemId, invoiceUUID, invoiceItemId
        }
      });
    } catch (e) {
      axiosErrorHandler(e, dispatch);
    }
  };
};

function orgInvoiceCustomerUpdated(invoiceCustomer, response) {
  return dispatch => {
    const { customerId, fortnoxCustomerNumber } = response.data;
    dispatch({
      type: ACCOUNT_INVOICE_CUSTOMER_UPDATED,
      invoiceCustomer: {
        id: customerId,
        fortnoxCustomerNumber,
        ...invoiceCustomer
      }
    });
  };
}

export const createOrgInvoiceCustomer = ({ orgId, ...invoiceCustomer }) => {
  return async (dispatch) => {
    const url = prefixUrl(`${defaultUrl}${orgId}/invoicecustomer/`);
    const config = axiosDefault();

    try {
      const response = await axios.post(url, invoiceCustomer, config);
      dispatch(checkStatusAxios(response));
      dispatch(orgInvoiceCustomerUpdated(invoiceCustomer, response));
    } catch (e) {
      axiosErrorHandler(e, dispatch);
    }
  };
};

export const updateOrgInvoiceCustomer = ({ orgId, ...invoiceCustomer }) => {
  return async (dispatch) => {
    const url = prefixUrl(`${defaultUrl}${orgId}/invoicecustomer/`);
    const config = axiosDefault();

    try {
      const response = await axios.put(url, invoiceCustomer, config);
      dispatch(checkStatusAxios(response));
      dispatch(orgInvoiceCustomerUpdated(invoiceCustomer, response));
    } catch (e) {
      axiosErrorHandler(e, dispatch);
    }
  };
};

export const updatePaymentMethod = ({ orgId, paymentMethod }) => {
  return async (dispatch) => {
    const url = prefixUrl(`${defaultUrl}${orgId}/payment-method`);
    const config = axiosDefault();

    try {
      const response = await axios.put(url, { paymentMethod }, config);
      dispatch(checkStatusAxios(response));
      dispatch({ type: ACCOUNT_PAYMENT_METHOD_UPDATED, paymentMethod });
    } catch (e) {
      axiosErrorHandler(e, dispatch);
    }
  };
};

export const updateContract = ({ orgId, ...contract }) => {
  return async (dispatch) => {
    const url = prefixUrl(`${defaultUrl}${orgId}/set-contract`);
    const config = axiosDefault();
    const { contractLength, contractStartDate, contractEndDate, ...pricing } = contract;
    const subscription = { contractLength, contractStartDate, contractEndDate };

    try {
      const response = await axios.put(url, contract, config);
      dispatch(checkStatusAxios(response));
      dispatch({ type: ACCOUNT_CONTRACT_UPDATED, orgId, subscription, pricing });
    } catch (e) {
      axiosErrorHandler(e, dispatch);
    }
  };
};

export const updatePricePlan = ({ orgId, pricePlan, ...pricing }) => {
  return async (dispatch) => {
    const url = prefixUrl(`${defaultUrl}${orgId}/set-pricing-and-plan`);
    const config = axiosDefault();
    const data = { plan: pricePlan, ...pricing };

    try {
      const response = await axios.put(url, data, config);
      dispatch(checkStatusAxios(response));
      dispatch({ type: ACCOUNT_PRICE_UPDATED, orgId, subscription: { pricePlan }, pricing });
    } catch (e) {
      axiosErrorHandler(e, dispatch);
    }
  };
};

export const updatePricePlanVunit = ({ orgId, ...pricing }) => {
  return async (dispatch) => {
    const url = prefixUrl(`/sysadmin/vunit-pricing/${orgId}`);
    const config = axiosDefault();

    try {
      const response = await axios.put(url, pricing, config);
      dispatch(checkStatusAxios(response));
      dispatch({ type: ACCOUNT_PRICE_VUNIT_UPDATED, orgId, pricing });
    } catch (e) {
      axiosErrorHandler(e, dispatch);
    }
  };
};

export const updatePaidUntil = ({ orgId, paidUntil }) => {
  return async (dispatch) => {
    const url = prefixUrl(`${defaultUrl}${orgId}/set-paid-until`);
    const config = axiosDefault();
    try {
      const response = await axios.put(url, { paidUntil }, config);
      dispatch(checkStatusAxios(response));
      dispatch({ type: ACCOUNT_PAID_UNTIL_UPDATED, data: { orgId, paidUntil } });
    } catch (e) {
      axiosErrorHandler(e, dispatch);
    }
  };
};

export const updateUnTerminateAccount = ({ orgId }) => {
  return async (dispatch) => {
    const url = prefixUrl(`${defaultUrl}${orgId}/un-terminate`);
    const config = axiosDefault();
    try {
      const response = await axios.put(url, {}, config);
      dispatch(checkStatusAxios(response));
      dispatch({ type: ACCOUNT_TERMINATED_UPDATED, data: false });
    } catch (e) {
      axiosErrorHandler(e, dispatch);
    }
  };
};

export const updateTerminateAccount = ({ orgId, when }) => {
  return async (dispatch, getState) => {
    const sysadminLocations = getSysadminLocations(getState());
    const orgCtxName = sysadminLocations.first().get('orgCtxName');
    const url = when === 'EndOfMonth'
      ? prefixUrl(`/powertools/terminate-subscription/${orgId}/${orgCtxName}`)
      : prefixUrl(`${defaultUrl}${orgId}/terminate`);
    const config = axiosDefault();
    try {
      const response = await axios.put(url, null, config);
      dispatch(checkStatusAxios(response));
      dispatch({ type: ACCOUNT_TERMINATED_UPDATED, data: true });
    } catch (e) {
      axiosErrorHandler(e, dispatch);
    }
  };
};

export const terminateLocation = (orgCtxName, ctxName) => {
  return async (dispatch) => {
    const url = prefixApiUrl(`/locations/${orgCtxName}/${ctxName}/location`);
    const config = axiosDefault();
    try {
      const response = await axios.delete(url, config);
      dispatch(checkStatusAxios(response));
      dispatch({ type: ACCOUNT_LOCATION_TERMINATED, orgCtxName, ctxName });
    } catch (e) {
      axiosErrorHandler(e, dispatch);
    }
  };
};

export const deleteOrganisation = (orgId, orgCtxName) => {
  return async (dispatch) => {
    const url = prefixApiUrl(`/powertools/delete-organisation/${orgId}/${orgCtxName}?deleteLocations=true`);
    const config = axiosDefault();
    try {
      const response = await axios.delete(url, config);
      dispatch(checkStatusAxios(response));
    } catch (e) {
      axiosErrorHandler(e, dispatch);
    }
  };
};

export const activateAccount = (orgId, pricePlan) => {
  return async (dispatch, getState) => {
    const sysadminLocations = getSysadminLocations(getState());
    const orgCtxName = sysadminLocations.first().get('orgCtxName');
    const url = prefixUrl(`/powertools/activate-subscription/${orgCtxName}`);
    const config = axiosDefault();
    try {
      const response = await axios.put(url, { pricePlan }, config);
      dispatch(checkStatusAxios(response));
      dispatch({ type: ACCOUNT_ACTIVATED_UPDATED, data: { orgCtxName } });
    } catch (e) {
      axiosErrorHandler(e, dispatch);
    }
  };
};

export const createInvoiceItem = ({ invoiceUUID, ...body }) => {
  return async (dispatch) => {
    const url = prefixUrl(`/invoices/${invoiceUUID}/invoice-item`);
    const config = axiosDefault();
    try {
      const response = await axios.post(url, body, config);
      dispatch(checkStatusAxios(response));
      dispatch({ type: ACCOUNT_INVOICE_CREATED, data: { ...body, id: response.data.id, invoiceUUID } });
    } catch (e) {
      axiosErrorHandler(e, dispatch);
    }
  };
};

export const updateSmsPricing = ({ orgId, ...pricing }) => {
  return async (dispatch) => {
    const url = prefixUrl(`${defaultUrl}${orgId}/set-sms-pricing`);
    const config = axiosDefault();
    try {
      const response = await axios.put(url, pricing, config);
      dispatch(checkStatusAxios(response));
      dispatch({ type: ACCOUNT_SMS_UPDATED, orgId, pricing });
    } catch (e) {
      axiosErrorHandler(e, dispatch);
    }
  };
};

export const updateSMSbilling = (orgId, enable) => {
  return async (dispatch) => {
    const smsState = enable ? 'enable' : 'disable';
    const url = prefixUrl(`${defaultUrl}${orgId}/smsbilling/${smsState}`);
    const config = axiosDefault();
    try {
      const response = await axios.put(url, null, config);
      dispatch(checkStatusAxios(response));
      dispatch({ type: ACCOUNT_SMS_BILLING_UPDATED, data: enable });
    } catch (e) {
      axiosErrorHandler(e, dispatch);
    }
  };
};

export const updateAllowSMS = (orgId, enable) => {
  return async (dispatch) => {
    const status = enable ? 'true' : 'false';
    const url = prefixUrl(`${defaultUrl}${orgId}/allow-sms/${status}`);
    const config = axiosDefault();
    try {
      const response = await axios.put(url, null, config);
      dispatch(checkStatusAxios(response));
      dispatch({ type: ACCOUNT_SMS_ALLOWED_UPDATED, data: enable });
    } catch (e) {
      axiosErrorHandler(e, dispatch);
    }
  };
};

export const updateAllowSMSCampaign = (orgId, enable) => {
  return async (dispatch) => {
    const status = enable ? 'true' : 'false';
    const url = prefixUrl(`${defaultUrl}${orgId}/allow-sms-campaign/${status}`);
    const config = axiosDefault();
    try {
      const response = await axios.put(url, null, config);
      dispatch(checkStatusAxios(response));
      dispatch({ type: ACCOUNT_SMS_CAMPAIGN_UPDATED, data: enable });
    } catch (e) {
      axiosErrorHandler(e, dispatch);
    }
  };
};

export const updateAllowEmailCampaign = (orgId, enable) => {
  return async (dispatch) => {
    const status = enable ? 'true' : 'false';
    const url = prefixUrl(`${defaultUrl}${orgId}/allow-email-campaign/${status}`);
    const config = axiosDefault();
    try {
      const response = await axios.put(url, null, config);
      dispatch(checkStatusAxios(response));
      dispatch({ type: ACCOUNT_EMAIL_CAMPAIGN_UPDATED, data: enable });
    } catch (e) {
      axiosErrorHandler(e, dispatch);
    }
  };
};

export const updateSmsSendLimits = ({ orgId, ...limits }) => {
  return async (dispatch) => {
    const url = prefixUrl(`${defaultUrl}${orgId}/set-sms-send-limits`);
    const config = axiosDefault();
    try {
      const response = await axios.put(url, limits, config);
      dispatch(checkStatusAxios(response));
      dispatch({ type: ACCOUNT_SMS_LIMITS_UPDATED, orgId, limits });
    } catch (e) {
      axiosErrorHandler(e, dispatch);
    }
  };
};

export const fetchOrganizationByIdApi = (orgId) => {
  return async (dispatch) => {
    const url = prefixUrl(`${defaultUrl}${orgId}`);
    const config = axiosDefault();
    dispatch({ type: SYSTEM_ADMIN_ORGANIZATION_ID_CLEAR });
    try {
      const response = await axios.get(url, config);
      dispatch(checkStatusAxios(response));
      dispatch({
        type: SYSTEM_ADMIN_ORGANIZATION_ID_FETCHED,
        subscription: response.data.subscription,
        invoiceCustomer: response.data.invoiceCustomer,
        signup: response.data.signup
      });
    } catch (e) {
      axiosErrorHandler(e, dispatch);
    }
  };
};

export const fetchLocationHardwarePricingApi = (orgId) => {
  return async (dispatch) => {
    const url = prefixUrl(`/sysadmin/invoiceitem-template/${orgId}/`);
    const config = axiosDefault();
    try {
      const response = await axios.get(url, config);
      dispatch(checkStatusAxios(response));
      dispatch({ type: LOCATION_HARDWARE_PRICING_ID_FETCHED, locationHardwarePricing: response?.data?.invoiceItemTemplate });
    } catch (e) {
      axiosErrorHandler(e, dispatch);
    }
  };
};

export function fetchSysadminCompanyInfo(orgCtxName) {
  return async (dispatch) => {
    const url = prefixUrl(`/organisations/${orgCtxName}/company-info/`);
    const config = axiosDefault();
    try {
      const response = await axios.get(url, config);
      dispatch(checkStatusAxios(response));
      dispatch({ type: SYSTEM_ADMIN_CONTACTS_FETCHED, contacts: response?.data?.contacts });
    } catch (e) {
      axiosErrorHandler(e, dispatch);
    }
  };
}

export const updateLocationHardwarePricingApi = ({ id, orgId, ...body }) => {
  return async (dispatch) => {
    const url = prefixUrl(`/sysadmin/invoiceitem-template/${orgId}/${id}`);
    const config = axiosDefault();

    try {
      const response = await axios.put(url, body, config);
      dispatch(checkStatusAxios(response));
      dispatch({ type: LOCATION_HARDWARE_PRICING_ID_UPDATED, data: { ...body, id } });
    } catch (e) {
      axiosErrorHandler(e, dispatch);
    }
  };
};

export const createLocationHardwarePricingApi = ({ orgId, ...body }) => {
  return async (dispatch) => {
    const url = prefixUrl(`/sysadmin/invoiceitem-template/${orgId}/`);
    const config = axiosDefault();

    try {
      const response = await axios.post(url, body, config);
      dispatch(checkStatusAxios(response));
      dispatch({ type: LOCATION_HARDWARE_PRICING_ID_CREATED, data: { ...body } });
    } catch (e) {
      axiosErrorHandler(e, dispatch);
    }
  };
};

export const sendAccountReportEmail = (body) => {
  return async (dispatch) => {
    const url = prefixUrl('/sysadmin/data-export/accounts-report/email');
    const config = axiosDefault();
    try {
      const response = await axios.post(url, body, config);
      dispatch(checkStatusAxios(response));
      return true;
    } catch (e) {
      axiosErrorHandler(e, dispatch);
    }
  };
};

export const uploadAccountReport = (body) => {
  return async (dispatch) => {
    const url = prefixUrl('/sysadmin/data-export/accounts-report/upload');
    const config = axiosDefault();
    try {
      const response = await axios.post(url, body, config);
      dispatch(checkStatusAxios(response));
      return true;
    } catch (e) {
      axiosErrorHandler(e, dispatch);
    }
  };
};

export const deleteLocationHardwarePricingApi = ({ orgId, itemTemplateId }) => {
  return async (dispatch) => {
    const url = prefixUrl(`/sysadmin/invoiceitem-template/${orgId}/${itemTemplateId}`);
    const config = axiosDefault();

    try {
      const response = await axios.delete(url, config);
      dispatch(checkStatusAxios(response));
      dispatch({ type: LOCATION_HARDWARE_PRICING_ID_DELETED, data: { itemTemplateId, orgId } });
    } catch (e) {
      axiosErrorHandler(e, dispatch);
    }
  };
};

export const fetchOrganizationsApi = ({ terminated = false } = {}) => {
  return async (dispatch) => {
    const url = !terminated
      ? prefixUrl(defaultUrl)
      : prefixUrl(`${defaultUrl}?terminated=${terminated}`);

    const config = axiosDefault();
    try {
      const response = await axios.get(url, config);
      dispatch(checkStatusAxios(response));
      dispatch({ type: SYSTEM_ADMIN_ORGANIZATIONS_FETCHED, organizations: response.data });
    } catch (e) {
      axiosErrorHandler(e, dispatch);
    }
  };
};

export const updateCreditAmount = ({ orgId, ...body }) => {
  return async (dispatch) => {
    const url = prefixUrl(`${defaultUrl}${orgId}/set-credit-amount`);
    const config = axiosDefault();
    try {
      const response = await axios.put(url, body, config);
      dispatch(checkStatusAxios(response));
      dispatch({ type: ACCOUNT_CREDIT_AMOUNT_UPDATED, data: body });
    } catch (e) {
      axiosErrorHandler(e, dispatch);
    }
  };
};

export const updateTrialDays = ({ orgId, ...body }) => {
  return async (dispatch) => {
    const url = prefixUrl(`${defaultUrl}${orgId}/set-trial-days`);
    const config = axiosDefault();
    try {
      const response = await axios.put(url, body, config);
      dispatch(checkStatusAxios(response));
      dispatch({ type: ACCOUNT_TRIAL_DAYS_UPDATED, data: { ...body, orgId } });
    } catch (e) {
      axiosErrorHandler(e, dispatch);
    }
  };
};

export const fetchLocationsByIdApi = (orgId) => {
  return async (dispatch) => {
    const url = prefixUrl(`${defaultUrl}${orgId}/locations`);
    const config = axiosDefault();
    try {
      const response = await axios.get(url, config);
      dispatch(checkStatusAxios(response));
      dispatch({ type: LOCATIONS_FETCHED, data: response.data });
    } catch (e) {
      axiosErrorHandler(e, dispatch);
    }
  };
};

export const createFortnoxInvoice = ({ orgId, periodKeys }) => {
  return async (dispatch) => {
    const url = prefixUrl(`${defaultUrl}${orgId}/invoice`);
    const config = axiosDefault();
    try {
      const response = await axios.post(url, { periodKeys }, config);
      dispatch(checkStatusAxios(response));
      const fortnoxInvoiceId = response.data?.invoice;
      dispatch({ type: ACCOUNT_FORTNOX_INVOICE_CREATED, periodKeys, fortnoxInvoiceId });
    } catch (e) {
      axiosErrorHandler(e, dispatch);
    }
  };
};

export const createAllFortnoxInvoices = () => {
  return async (dispatch) => {
    const url = prefixUrl(`${defaultUrl}invoice-all`);
    const config = axiosDefault();
    try {
      const response = await axios.post(url, null, config);
      dispatch(checkStatusAxios(response));
    } catch (e) {
      axiosErrorHandler(e, dispatch);
    }
  };
};

export const createNewYabieAccount = (data = {}) => {
  return async (dispatch) => {
    const url = prefixUrl('/signup/without-trial');
    const config = axiosDefault();
    try {
      const response = await axios.post(url, data, config);
      dispatch(checkStatusAxios(response));
      return response.data;
    } catch (e) {
      axiosErrorHandler(e, dispatch);
      throw e;
    }
  };
};

export const getNewAccountPricing = () => {
  return async (dispatch) => {
    const url = prefixUrl('/sysadmin/price-plans');
    const config = axiosDefault();
    try {
      const response = await axios.get(url, config);
      dispatch(checkStatusAxios(response));
      dispatch({ type: PRICE_PLANS_FETCHED, data: response.data ?? {} });
      return response.data;
    } catch (e) {
      axiosErrorHandler(e, dispatch);
      throw e;
    }
  };
};

export const clearFeatureCache = (booking = false) => {
  return async (dispatch) => {
    const path = '/features/reset-feature-cache';
    const url = booking ? prefixBookingUrl(path) : prefixApiUrl(path);
    const config = axiosDefault();
    try {
      const response = await axios.put(url, null, config);
      dispatch(checkStatusAxios(response));
    } catch (e) {
      axiosErrorHandler(e, dispatch);
    }
  };
};

export const refreshYabieContracts = () => {
  return async (dispatch) => {
    const url = prefixApiUrl('/powertools/refresh-yabie-contracts');
    const config = axiosDefault();
    try {
      const response = await axios.put(url, null, config);
      dispatch(checkStatusAxios(response));
    } catch (e) {
      axiosErrorHandler(e, dispatch);
    }
  };
};

export const updateAccountType = ({ orgId, accountType }) => {
  return async (dispatch) => {
    const url = prefixUrl(`/sysadmin/orgs/${orgId}/account-type`);
    const config = axiosDefault();
    try {
      const response = await axios.put(url, { accountType }, config);
      dispatch(checkStatusAxios(response));
      dispatch({ type: ACCOUNT_TYPE_UPDATED, data: { orgId, accountType } });
    } catch (e) {
      axiosErrorHandler(e, dispatch);
    }
  };
};

export const createSalesforceLead = (orgId) => {
  return async (dispatch) => {
    const url = prefixUrl(`/sysadmin/orgs/${orgId}/create-sf-lead`);
    const config = axiosDefault();
    try {
      const response = await axios.post(url, null, config);
      dispatch(checkStatusAxios(response));
      dispatch({ type: ACCOUNT_LEAD_SENT, orgId });
    } catch (e) {
      axiosErrorHandler(e, dispatch);
      throw e;
    }
  };
};

export function fetchAllReleaseNotes() {
  return async (dispatch) => {
    const url = prefixApiUrl('/release-notes/all/');
    const config = axiosDefault();
    try {
      const response = await axios.get(url, config);
      dispatch(checkStatusAxios(response));
      dispatch({ type: RELEASE_NOTES_FETCHED, ...response.data });
    } catch (e) {
      axiosErrorHandler(e, dispatch);
    }
  };
}

export function createReleaseNote(data) {
  return async (dispatch) => {
    const url = prefixApiUrl('/release-notes/');
    const config = axiosDefault();
    try {
      const response = await axios.post(url, data, config);
      dispatch(checkStatusAxios(response));
      const releaseNote = { ...data, id: response.data.id };
      dispatch({ type: RELEASE_NOTE_CREATED, releaseNote });
      return releaseNote;
    } catch (e) {
      axiosErrorHandler(e, dispatch);
      throw e;
    }
  };
}

export function updateReleaseNote(releaseNote) {
  return async (dispatch) => {
    const url = prefixApiUrl(`/release-notes/${releaseNote.id}`);
    const config = axiosDefault();
    try {
      const response = await axios.put(url, releaseNote, config);
      dispatch(checkStatusAxios(response));
      dispatch({ type: RELEASE_NOTE_UPDATED, releaseNote });
    } catch (e) {
      axiosErrorHandler(e, dispatch);
      throw e;
    }
  };
}

export function deleteReleaseNote(id) {
  return async (dispatch) => {
    const url = prefixApiUrl(`/release-notes/${id}`);
    const config = axiosDefault();
    try {
      const response = await axios.delete(url, config);
      dispatch(checkStatusAxios(response));
      dispatch({ type: RELEASE_NOTE_DELETED, id });
    } catch (e) {
      axiosErrorHandler(e, dispatch);
      throw e;
    }
  };
}

export function toggleReleaseNotePublished(id, published) {
  return async (dispatch, getState) => {
    const url = prefixApiUrl(published
      ? `/release-notes/${id}/publish`
      : `/release-notes/${id}/unpublish`);
    const { sysadminReleaseNotes } = getState();
    const releaseNote = sysadminReleaseNotes.find(r => r.get('id') === id);
    const config = axiosDefault();
    try {
      const response = await axios.put(url, null, config);
      dispatch(checkStatusAxios(response));
      dispatch({
        type: RELEASE_NOTE_UPDATED,
        releaseNote: releaseNote.set('published', published).toJS()
      });
    } catch (e) {
      axiosErrorHandler(e, dispatch);
      throw e;
    }
  };
}

export function uploadReleaseNoteImage(id, image) {
  return async (dispatch) => {
    const url = prefixApiUrl(`/release-notes/${id}/image`);
    const config = axiosFormData();
    const formData = new FormData();
    formData.append('file', image);

    try {
      const response = await axios.post(url, formData, config);
      dispatch(checkStatusAxios(response));
      dispatch({ type: RELEASE_NOTE_IMAGE_UPDATED, id, imageUrl: response.data.imageUrl });
    } catch (e) {
      axiosErrorHandler(e, dispatch);
      throw e;
    }
  };
}

export function deleteReleaseNoteImage(id) {
  return async (dispatch) => {
    const url = prefixApiUrl(`/release-notes/${id}/image`);
    const config = axiosDefault();

    try {
      dispatch({ type: RELEASE_NOTE_IMAGE_UPDATED, id, imageUrl: null });
      const response = await axios.delete(url, config);
      dispatch(checkStatusAxios(response));
    } catch (e) {
      axiosErrorHandler(e, dispatch);
      throw e;
    }
  };
}

export function fetchOrgLogEntries(id) {
  const url = prefixUrl(`${defaultUrl}${id}/logentries`);
  const config = axiosDefault();

  return async (dispatch) => {
    try {
      const response = await axios.get(url, config);
      dispatch(checkStatusAxios(response));
      dispatch({ type: ACCOUNT_LOGS_FETCHED, logentries: response.data.entries });
    } catch (e) {
      axiosErrorHandler(e, dispatch);
      throw e;
    }
  };
}

export function saveOrgComment(id, comment) {
  const url = prefixUrl(`${defaultUrl}${id}/logentries`);
  const config = axiosDefault();

  return async (dispatch) => {
    try {
      const response = await axios.post(url, JSON.stringify({ comment }), config);
      dispatch(checkStatusAxios(response));
      dispatch({ type: ACCOUNT_COMMENT_SAVED, orgId: id, result: { comment, ...response.data } });
    } catch (e) {
      axiosErrorHandler(e, dispatch);
      throw e;
    }
  };
}

export function updateOrgComment(id, commentId, comment) {
  const url = prefixUrl(`${defaultUrl}${id}/logentries/${commentId}`);
  const config = axiosDefault();

  return async (dispatch) => {
    try {
      const response = await axios.put(url, JSON.stringify({ comment }), config);
      dispatch(checkStatusAxios(response));
      dispatch({ type: ACCOUNT_COMMENT_UPDATED, id, commentId, comment });
    } catch (e) {
      axiosErrorHandler(e, dispatch);
      throw e;
    }
  };
}

export function deleteOrgComment(id, commentId) {
  const url = prefixUrl(`${defaultUrl}${id}/logentries/${commentId}`);
  const config = axiosDefault();

  return async (dispatch) => {
    try {
      const response = await axios.delete(url, config);
      dispatch(checkStatusAxios(response));
      dispatch({ type: ACCOUNT_COMMENT_DELETED, commentId });
    } catch (e) {
      axiosErrorHandler(e, dispatch);
      throw e;
    }
  };
}

export const mergeOrgPrefs = (orgId, prefs) => {
  return async (dispatch) => {
    const url = prefixUrl(`${defaultUrl}${orgId}/preferences/`);
    const config = axiosDefault();
    try {
      const response = await axios.patch(url, prefs, config);
      dispatch(checkStatusAxios(response));
      dispatch({ type: ACCOUNT_PREFS_UPDATED, prefs });
    } catch (e) {
      axiosErrorHandler(e, dispatch);
    }
  };
};
