import React, { useState } from 'react';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import omit from 'lodash/omit';

import FormButtonsFooter from '@Components/ui/form-buttons-footer';
import DatePickerLabelInput from '@Components/ui/date-picker/date-picker-label-input';
import SelectForm from '@Components/ui/select/select-form';
import { typeOptions } from '@Components/sysadmin/accounts/constants';
import SelectLocation from '@Components/sysadmin/accounts/select-location';

const optionsRequired = { required: true };
const optionsNumberRequired = { valueAsNumber: true, ...optionsRequired };

const AccountOverviewHardwareForm = ({ onSubmit, onClose, initialValues }) => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [startTime, setStartTime] = useState(moment(initialValues?.contractStartTime || undefined));
  const [submitting, setSubmitting] = useState(false);

  const onChangeTime = (value) => {
    setStartTime(moment(value));
  };

  const onSubmitData = (data) => {
    setSubmitting(true);
    onSubmit({ ...data, contractStartTime: moment(startTime).format('YYYY-MM-DD') }).finally(() => {
      onClose();
    });
  };

  const datePickerLabelInput = omit({ ...register('contractStartTime') }, 'ref');
  const locationId = omit({ ...register('locationId') }, 'ref');
  const chargeType = omit({ ...register('chargeType', { ...optionsRequired }) }, 'ref');

  return (
    <div>
      <form autoComplete="off" className="dialog" onSubmit={handleSubmit(onSubmitData)}>
        <div className="form-group">
          <div className="form-control-container">
            <SelectLocation
              {...locationId}
              defaultValue={initialValues.locationId}
            />
          </div>
        </div>
        <div className="form-group">
          <div className="form-control-container">
            <label className="control-label" htmlFor="price">
              Price
            </label>
            <input
              className="form-control"
              type="number"
              defaultValue={initialValues.price}
              {...register('price', optionsNumberRequired)}
            />
            {errors.price && <span className="alert-danger">Required</span>}
          </div>
        </div>
        <SelectForm
          {...chargeType}
          labelText="Charge type"
          options={typeOptions}
          defaultValue={initialValues.chargeType}
        />
        <div className="form-group">
          <div className="form-control-container">
            <DatePickerLabelInput
              {...datePickerLabelInput}
              label="Contract Start Time"
              date={startTime}
              handleChangeDate={onChangeTime}
            />
          </div>
        </div>
        <div className="form-group">
          <div className="form-control-container">
            <label className="control-label" htmlFor="contractLength">
              Contract Length
            </label>
            <input
              className="form-control"
              defaultValue={initialValues.contractLength}
              {...register('contractLength', optionsNumberRequired)}
            />
            {errors.contractLength && <span className="alert-danger">Required</span>}
          </div>
        </div>
        <div className="form-group">
          <div className="form-control-container">
            <label className="control-label" htmlFor="description">
              Description
            </label>
            <input
              className="form-control"
              defaultValue={initialValues.description}
              {...register('description', optionsRequired)}
            />
            {errors.description && <span className="alert-danger">Required</span>}
          </div>
        </div>
        <div className="form-group">
          <div className="form-control-container">
            <label className="control-label" htmlFor="units">
              units
            </label>
            <input
              className="form-control"
              defaultValue={initialValues.units}
              {...register('units', optionsNumberRequired)}
            />
            {errors.units && <span className="alert-danger">Required</span>}
          </div>
        </div>
        <FormButtonsFooter
          onClose={onClose}
          handleSubmit={handleSubmit}
          submitting={submitting}
          submitSucceeded
        />
      </form>
    </div>
  );
};

export default AccountOverviewHardwareForm;
