import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';

import ModalDialog from '@Components/dialogs/modal-dialog';
import { ModalContent } from '@Components/dialogs/dialog-styles';
import TextInput from '@Components/inputs/text-input';
import { DraggableListContainer, DraggableListItem } from '@Components/ui/draggable-list';
import CheckBox from '@Components/inputs/checkbox';
import SubmitButton from '@Components/ui/submit-button';
import { createValidator, required } from '@Utils/validation';
import { txt } from '@Utils/i18n-util';
import Popover from '@Components/ui/popover';
import Button from '@Components/ui/button';

import msg from '@Components/services/custom-fields-editor.msg';

const fieldTypes = ['date', 'text', 'select', 'checkbox'];

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const CustomFieldsForm = ({ onClose, initialValues, onRemove, submitting, submitSucceeded, handleSubmit, change }) => {
  const type = useSelector(state => selector(state, 'type'));
  const [options, setOptions] = useState(initialValues?.options ?? []);
  const [error, setError] = useState({});
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const addOption = useCallback(() => {
    setOptions(options => ([...options, `Alternativ ${options.length + 1}`]));
  }, []);

  const handleOptionChange = useCallback((e, i) => {
    e.preventDefault();
    e.stopPropagation();
    const value = e.currentTarget?.value ?? '';
    if (!value) setError(e => ({ ...e, [i]: true }));
    if (value && error[i]) setError(e => ({ ...e, [i]: false }));
    setOptions(options => options.map((o, index) => index === i ? value : o));
  }, [error]);

  const onOptionDelete = useCallback((i) => {
    setOptions(options => options.filter((o, index) => index !== i));
    if (error[i]) setError(e => ({ ...e, [i]: false }));
  }, [error]);

  const onDragEnd = useCallback((result) => {
    if (!result.destination) return;
    setOptions(options => reorder(options, result.source.index, result.destination.index));
  }, []);

  useEffect(() => {
    if (Object.values(error).every(v => !v)) {
      change('options', options);
    }
  }, [options, error]);

  useEffect(() => {
    if (type === 'select' && !options.length) {
      setOptions(['Alternativ 1']);
    }
  }, [type, options]);

  const getClassName = useCallback((i) => `option-row form-group ${!error[i] ? '' : 'has-error'}`, [error]);

  return (
    <ModalDialog contentSize="small" onClose={onClose} closeOnClickOutside={!showConfirmDelete} focusTrapPaused={showConfirmDelete}>
      <ModalContent>
        <form onSubmit={handleSubmit} className="edit-custom-field-form">
          <Field name="label" component={TextInput} label={txt(msg.label)} />
          <div className="form-group">
            <label htmlFor="type" className="control-label">{txt(msg.type)}</label>
            <div className="select-container">
              <Field
                id="type"
                name="type"
                component="select"
                className="form-control"
              >
                {fieldTypes.map(id => (<option key={id} label={txt(msg[id])} value={id}>{txt(msg[id])}</option>))}
              </Field>
            </div>
          </div>
          {type === 'select' && (
            <DraggableListContainer onDragEnd={onDragEnd} id="options-dropper" className="options-wrapper">
              <div className="options-header">
                <label className="control-label">
                  {txt(msg.options)}
                </label>
                <button
                  type="button"
                  className="btn btn-default"
                  onClick={addOption}
                >
                  {txt(msg.add)}
                </button>
              </div>
              <div className="option-col">
                {options?.map((option, i) => (
                  <DraggableListItem
                    key={i + option}
                    id={option}
                    isDragDisabled={Object.values(error).some(v => !!v)}
                    className={getClassName(i)}
                    index={i}
                    handler={<div className="drag-handler"><i className="fa-solid fa-bars" /></div>}
                  >
                    <div className="form-control-container">
                      <input
                        className="form-control"
                        type="text"
                        defaultValue={option}
                        onBlur={e => handleOptionChange(e, i)}
                      />
                    </div>
                    <div>
                      <button disabled={options.length < 2} onClick={() => onOptionDelete(i)}>
                        <i className="fa-solid fa-xmark" />
                      </button>
                    </div>
                  </DraggableListItem>
                ))}
              </div>
            </DraggableListContainer>
          )}
          {(type !== 'date' && type !== 'checkbox') && (
            <Field name="placeholder" component={TextInput} label={txt(msg.placeholder)} />
          )}
          <div className="form-group">
            <label>{txt(msg.helpText)}</label>
            <Field
              name="helpText"
              component="textarea"
              rows={3}
              className="form-control"
            />
          </div>
          {type !== 'checkbox' && (
            <Field name="required" component={CheckBox} label={txt(msg.required)} />
          )}
          <div className="buttons">
            <Popover
              isOpen={showConfirmDelete}
              body={(
                <div className="popover-delete">
                  <p className="title">Bekräfta borttagning</p>
                  <div className="flex-item row-button">
                    <Button small gray onClick={() => setShowConfirmDelete(false)}>
                      {txt(msg.cancel)}
                    </Button>
                    <Button small danger onClick={onRemove}>
                      {txt(msg.delete)}
                    </Button>
                  </div>
                </div>
              )}
              onOuterAction={() => setShowConfirmDelete(false)}
            >
              <button type="button" className="btn-delete" onClick={() => setShowConfirmDelete(true)}>
                {txt(msg.delete)}
              </button>
            </Popover>
            <div>
              <button type="button" className="btn" onClick={onClose}>
                {txt(msg.cancel)}
              </button> &nbsp;
              <SubmitButton submitSucceeded={submitSucceeded} submitting={submitting} />
            </div>
          </div>
        </form>
      </ModalContent>
    </ModalDialog>
  );
};

const selector = formValueSelector('edit-custom-field-form');

const validator = createValidator({
  label: [required],
  type: [required]
});

export default reduxForm({
  form: 'edit-custom-field-form',
  validate: validator
})(CustomFieldsForm);
