import React from 'react';
import { useDispatch } from 'react-redux';
import { setLocationSlug } from '@State/preferences-actions';
import AutoSaveTextInput from '@Components/inputs/auto-save-text-input';
import ModalDialog from '@Components/dialogs/modal-dialog';
import AlertWithIcon from '@Components/ui/alert-with-icon';

const SetSlugModal = ({ slug, onClose }) => {
  const dispatch = useDispatch();

  const setSlug = ({ slug }) => {
    return dispatch(setLocationSlug(slug));
  };

  return (
    <ModalDialog
      title="Ändra adress till bokning"
      hideCloseButton
      buttons={[{
        name: 'Stäng',
        gray: true,
        onClick: onClose
      }]}
    >
      <AlertWithIcon icon="fa fa-lightbulb">
        Tips: Om din salong ligger på Kungsholmen, skriv tex. salong-klippet-kungsholmen för att ranka extra bra på Google.
      </AlertWithIcon>
      <AutoSaveTextInput
        name="slug"
        label="Ange adress"
        helpText="Använd endast små bokstäver (a-z), siffror samt bindestreck."
        initialValue={slug}
        onSave={setSlug}
      />
    </ModalDialog>
  );
};

export default SetSlugModal;
