import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';

import TextInput from '@Components/inputs/text-input';
import Popover from '@Components/ui/popover';
import ConfirmBlock from '@Components/ui/confirm-block';

const StaffConfigNewUser = ({
  onSubmit, handleSubmit, isOpenPopover, onHidePopup, children
}) => {
  const refInput = useRef();
  const [userName, setUserName] = useState('');
  const [disableConfirm, setDisableConfirm] = useState(true);

  useEffect(() => {
    if (refInput.current && refInput.current.focus) {
      refInput.current.focus();
    }
    if (!isOpenPopover) {
      setUserName('');
    }
  }, [isOpenPopover]);

  const onClosePopup = () => {
    setUserName('');
    onHidePopup();
  };

  const onChange = (e) => {
    setUserName(e.target.value);
    setDisableConfirm(!e.target.value);
  };

  const onConfirm = () => {
    handleSubmit(onSubmit({ name: userName }));
  };

  const input = {
    ref: refInput,
    placeholder: 'Namn på användaren',
    value: userName,
    onChange
  };

  const renderBody = (
    <div className="Popover-dialog-sm staff-popup-form">
      <form onSubmit={handleSubmit}>
        <ConfirmBlock
          disableConfirm={disableConfirm}
          confirmText="Lägg till"
          onCancel={onClosePopup}
          onConfirm={onConfirm}
        >
          <TextInput
            meta={{}}
            label="Namn"
            input={input}
          />
        </ConfirmBlock>
      </form>
    </div>
  );

  return (
    <Popover
      className="Popover-big-radius"
      preferPlace="below"
      isOpen={isOpenPopover}
      onOuterAction={onClosePopup}
      body={renderBody}
    >
      {children}
    </Popover>
  );
};

StaffConfigNewUser.propTypes = {
  onSubmit: PropTypes.func,
  isOpenPopover: PropTypes.bool,
  onHidePopup: PropTypes.func
};

export default reduxForm({
  form: 'staff-config-popover'
})(StaffConfigNewUser);
