import React from 'react';
import { Field, reduxForm } from 'redux-form';

import { Flex } from '@Components/ui/styled/main';
import TextInput from '@Components/inputs/text-input';
import ModalDialog from '@Components/dialogs/modal-dialog';
import AlertWithIcon from '@Components/ui/alert-with-icon';

const PosOrgEditModal = ({ onClose, handleSubmit, submitting }) => (
  <ModalDialog
    contentSize="small"
    onClose={onClose}
    title="Ändra företagsuppgifter"
    buttons={[{
      name: 'Spara',
      primary: true,
      type: 'submit',
      loading: submitting,
      onClick: handleSubmit
    }]}
  >
    <form onSubmit={handleSubmit} className="dialog">
      <AlertWithIcon>
        OBS! Uppgifterna måste vara samma som är registrerade hos Skatteverket,
        enligt SKVFS 2014:9
      </AlertWithIcon>
      <AlertWithIcon warning>
        Kontakta support om du vill ändra uppgifterna hos Skatteverket
      </AlertWithIcon>
      <Field name="companyOrgNo" component={TextInput} label="Organisationsnr." disabled />
      <Field name="vatRegNo" component={TextInput} label="Momsreg.nr." />
      <Field name="companyName" component={TextInput} label="Företagsnamn" />
      <Field name="companyAddress.addressLine1" component={TextInput} label="Gatuadress" />
      <Flex>
        <Flex rightMargin>
          <Field name="companyAddress.postCode" type="number" component={TextInput} label="Postnummer" />
        </Flex>
        <Flex>
          <Field name="companyAddress.area" component={TextInput} label="Postort" />
        </Flex>
      </Flex>
    </form>
  </ModalDialog>
);

export default reduxForm({
  form: 'pos-org-form'
})(PosOrgEditModal);
