import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getSysAdmin } from '@State/selectors';
import { viewDateFromDate } from '@Utils/time-util';
import { logMeOut } from '@Utils/account';
import LabelSwitchInput from '@Components/ui/label-switch-input';

const LocationOption = ({
  orgCtxName, locCtxName, locName, orgName, resourceId, remember
}) => {
  const thisWeek = viewDateFromDate('week', moment());
  const today = moment().format('YYYY-MM-DD');

  const handleClick = () => {
    if (remember) {
      localStorage.setItem('defaultOrgLoc', `${orgCtxName}/${locCtxName}`);
    }
  };

  const selectionName = orgName !== locName
    ? `${orgName} (${locName})`
    : orgName;

  const url = resourceId == null ? `/${orgCtxName}/${locCtxName}/calendar/day/${today}/group/0`
    : `/${orgCtxName}/${locCtxName}/calendar/week/${thisWeek}/resource/${resourceId}`;

  return (
    <tr>
      <td><Link to={url} onClick={handleClick}>{selectionName}</Link></td>
    </tr>
  );
};

LocationOption.propTypes = {
  locName: PropTypes.string.isRequired,
  locCtxName: PropTypes.string.isRequired,
  orgName: PropTypes.string.isRequired,
  orgCtxName: PropTypes.string.isRequired,
  locationRole: PropTypes.string.isRequired,
  organisationRole: PropTypes.string.isRequired,
  orgLoc: PropTypes.string.isRequired,
  resourceId: PropTypes.number
};

const Locations = ({ locations }) => {
  const isSysAdmin = useSelector(getSysAdmin);

  const [filter, setFilter] = useState('');
  const [remember, setRemember] = useState(false);

  const handleToggle = (ev) => {
    setRemember(ev.target.checked);
  };

  const handleLogout = (ev) => {
    ev.preventDefault();
    logMeOut();
  };

  const handleChange = (ev) => {
    setFilter(ev.target.value);
  };

  const filterLocations = useCallback(({ locName, orgName }) => {
    const filters = filter.toLowerCase().split(' ');
    const locationName = `${locName} ${orgName}`.toLowerCase();
    return filters.every(f => locationName.includes(f));
  }, [filter]);

  if (locations.isEmpty()) {
    return (
      <div className="dialog-container">
        <div className="dialog-content location-selector">
          <h1 className="text-center">Behörighet saknas</h1>
          <p className="text-center">
            Ditt inlogg är inte kopplat till ett aktivt konto i Cliento.
          </p><br />
          <div className="text-right">
            <p>
              <a href="#" onClick={handleLogout}>Logga ut</a>
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="dialog-container location-selector">
      <div className="dialog-content location-selector">
        <h1>Välj konto</h1>

        <LabelSwitchInput
          noBorder
          label="Kom ihåg mitt val"
          id="remember"
          isOn={remember}
          handleToggle={handleToggle}
        /><br />

        {isSysAdmin && (
          <input
            type="text"
            className="form-control"
            placeholder="Sök konto"
            onChange={handleChange}
            autoFocus
          />
        )}

        <div className="locations">
          <table className="table">
            <tbody>
              {locations.filter(filterLocations).map(loc => (
                <LocationOption key={loc.orgLoc} {...loc} remember={remember} />
              ))}
            </tbody>
          </table>
        </div>

        <div className="text-right">
          <p>
            <a href="#" onClick={handleLogout}>Logga ut</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Locations;
