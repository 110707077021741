import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import CurrencyUtil from '@Utils/currency-util';
import { getTipEnabled } from '@State/pos-selectors';
import ModalDialog from '@Components/dialogs/modal-dialog';
import { DialogCenterRowWrap } from '@Components/dialogs/dialog-styles';
import Text from '@Components/ui/styled/text-element';
import SetAmountModal from '@Components/pos/payment/set-amount-modal';

const ExternalPayment = ({
  totalAmount, onSubmit, onClose, title, text, isRefund, submitText, disableChangeAmount
}) => {
  const tipEnabled = useSelector(getTipEnabled);
  const [amount, setAmount] = useState(totalAmount);
  const [showAmount, setShowAmount] = useState(null);

  const handleSetAmount = (newAmount) => {
    setAmount(showAmount === 'tip'
      ? totalAmount + newAmount
      : newAmount);
    setTimeout(() => setShowAmount(null), 100);
  };

  if (showAmount) {
    return (
      <SetAmountModal
        amountType={showAmount}
        totalAmount={totalAmount}
        onSubmit={handleSetAmount}
        onClose={() => setShowAmount(null)}
      />
    );
  }

  return (
    <ModalDialog
      title={title}
      onClose={onClose}
      closeOnClickOutside={false}
      onEnter={() => onSubmit(amount)}
      buttons={[{
        name: submitText || (isRefund ? 'Återbetalning klar' : 'Betalning mottagen'),
        primary: true,
        onClick: () => onSubmit(amount)
      }, !isRefund && !disableChangeAmount && {
        name: 'Dela upp betalning',
        gray: true,
        onClick: () => setShowAmount('split')
      }, !isRefund && !disableChangeAmount && tipEnabled && {
        name: 'Lägg till dricks',
        gray: true,
        onClick: () => setShowAmount('tip')
      }]}
    >
      <DialogCenterRowWrap>
        <Text marginBottom fs={30}>{CurrencyUtil.accountCurrency(amount, 2)}</Text>
        <Text muted>{text}</Text>
      </DialogCenterRowWrap>
    </ModalDialog>
  );
};

export default ExternalPayment;
