import React from 'react';
import { useSelector } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { maxValue, minValue, required } from '@Utils/validation';
import { getSalesTermsDescription } from '@Utils/users';
import TextInput from '@Components/inputs/text-input';
import FormButtonsFooter from '@Components/ui/form-buttons-footer';
import SelectField from '@Components/inputs/select-field';

const formSelector = formValueSelector('staff-sales-terms-form');

const StaffSalesTermsForm = ({
  pristine, submitting, submitSucceeded, handleSubmit, onClose
}) => {
  const salesTerms = useSelector(state => formSelector(state, 'type'));

  return (
    <form autoComplete="off" className="dialog" onSubmit={handleSubmit}>
      <Field
        name="type"
        component={SelectField}
        label="Villkor"
        validate={[required]}
      >
        <option value="">Välj villkor</option>
        {['Commission', 'NetMarkup'].map(type => (
          <option value={type}>{getSalesTermsDescription(type)}</option>
        ))}
      </Field>
      {salesTerms === 'Commission' && (
        <Field
          type="number"
          name="commissionPct"
          component={TextInput}
          label="Provision"
          unitLabel="%"
          validate={[required, minValue(0), maxValue(100)]}
        />
      )}
      {salesTerms === 'NetMarkup' && (
        <Field
          type="number"
          name="markupPct"
          component={TextInput}
          label="Påslag"
          unitLabel="%"
          validate={[required, minValue(0), maxValue(100)]}
        />
      )}
      <FormButtonsFooter
        onClose={onClose}
        pristine={pristine}
        submitting={submitting}
        submitSucceeded={submitSucceeded}
        handleSubmit={handleSubmit}
        submitText="Spara"
      />
    </form>
  );
};

export default reduxForm({
  form: 'staff-sales-terms-form'
})(StaffSalesTermsForm);
