import { defineMessages } from 'react-intl';

export default defineMessages({
  txtConfirmMove: {
    id: 'calendar.bkf.confirm-move.txtConfirmMove',
    defaultMessage: 'Confirm move to {destination}?'
  },
  txtConfirmCopy: {
    id: 'calendar.bkf.confirm-move.txtConfirmCopy',
    defaultMessage: 'Copy booking to {destination}?'
  },
  lblSendSMS: {
    id: 'calendar.bkf.confirm-move.lblSendSMS',
    defaultMessage: 'SMS confirmation to {phoneNumber}'
  },
  lblSendEmail: {
    id: 'calendar.bkf.confirm-move.lblSendEmail',
    defaultMessage: 'Email confirmation to {email}'
  },
  btnCancel: {
    id: 'calendar.bkf.confirm-move.btnCancel',
    defaultMessage: 'Cancel'
  },
  btnCopy: {
    id: 'calendar.bkf.confirm-move.btnCopy',
    defaultMessage: 'Copy'
  },
  btnMove: {
    id: 'calendar.bkf.confirm-move.btnMove',
    defaultMessage: 'Move'
  }
});
