import React from 'react';
import { getHistory } from '@Utils/navigate';
import TB from './tb-button';

const history = getHistory();

export default ({
  primary, style, onClick = history.goBack, iconStyle, buttonStyle
}) => (
  <TB
    style={style}
    primary={primary}
    iconStyle={iconStyle}
    buttonStyle={buttonStyle}
    icon="fa-chevron-left"
    onClick={onClick}
  />
);
