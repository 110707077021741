import React from 'react';
import { change, Field, formValueSelector, reduxForm } from 'redux-form';
import { minValue, required } from '@Utils/validation';
import ModalDialog from '@Components/dialogs/modal-dialog';
import FormButtonsFooter from '@Components/ui/form-buttons-footer';
import TextInput from '@Components/inputs/text-input';
import { useDispatch, useSelector } from 'react-redux';

const formName = 'edit-product-delivery-form';
const selector = formValueSelector(formName);

const EditProductDeliveryModal = ({ onClose, handleSubmit, submitting, initialValues: iv }) => {
  const dispatch = useDispatch();
  const remainingQuantity = useSelector(state => selector(state, 'remainingQuantity'));

  const soldQuantity = iv.deliveredQuantity - iv.remainingQuantity;
  const deliveredMinValue = Math.max(soldQuantity, 1);

  const onDeliveredChange = (ev) => {
    const remaining = parseInt(ev.target.value || 0) - soldQuantity;
    dispatch(change(formName, 'remainingQuantity', remaining));
  };

  return (
    <ModalDialog
      title="Korrigera leverans"
      hideCloseButton
      contentSize="small"
      closeOnClickOutside={false}
      onClose={onClose}
    >
      <form autoComplete="off" className="dialog" onSubmit={handleSubmit}>
        <Field
          type="number"
          name="deliveredQuantity"
          label="Levererat antal"
          component={TextInput}
          onChange={onDeliveredChange}
          validate={[required, minValue(deliveredMinValue)]}
        />
        <Field
          type="number"
          name="price"
          label="Pris / st (exkl. moms)"
          component={TextInput}
          validate={[required, minValue(0)]}
        />
        <div className="form-group">
          <label className="control-label">Antal kvar</label>
          <div className="form-control-container">
            <input type="number" className="form-control" value={remainingQuantity} readOnly disabled />
          </div>
          {remainingQuantity < 0 && <span className="help-block">Måste vara minst 0</span>}
        </div>
        <FormButtonsFooter
          alwaysEnabled
          onClose={onClose}
          submitting={submitting}
          handleSubmit={handleSubmit}
          submitText="Korrigera"
        />
      </form>
    </ModalDialog>
  );
};

export default reduxForm({
  form: formName
})(EditProductDeliveryModal);
