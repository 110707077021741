import React, { memo } from 'react';
import PropTypes from 'prop-types';

import StaffUserDetailHeader from '@Components/staff/staff-user-detail/staff-user-detail-header';
import StaffUserDetailAssignStatus from '@Components/staff/staff-user-detail/staff-user-detail-assign-status';
import StaffUserDetailEntries from '@Components/staff/staff-user-detail/staff-user-detail-entries';

const StaffUserDetail = ({ user }) => {
  const userId = user.get('id');
  const pno = user.get('pno');
  const name = user.get('name');
  const checkinTime = user.get('checkinTime');

  return (
    <div>
      <StaffUserDetailHeader
        pno={pno}
        name={name}
        userId={userId}
      />
      <StaffUserDetailAssignStatus
        disabledCheckIn={!pno}
        userId={userId}
        name={name}
        checkinTime={checkinTime}
      />
      <StaffUserDetailEntries userId={userId} />
    </div>
  );
};

StaffUserDetail.propTypes = {
  user: PropTypes.object
};

export default memo(StaffUserDetail);
