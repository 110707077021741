import React from 'react';
import moment from 'moment';
import { useForm, FormProvider } from 'react-hook-form';

import useValueChanged from '@Hooks/useValueChanged';
import FormButtonsFooter from '@Components/ui/form-buttons-footer';
import HookFormInput from '@Components/inputs/hook-form-input';

import { txt } from '@Utils/i18n-util';
import msg from './account-overview.msg';

const fields = [
  {
    id: 'contractLength',
    type: 'select',
    options: [
      { value: '0', title: 'Ingen bindningstid' },
      { value: '12', title: '12 månader' },
      { value: '24', title: '24 månader' },
      { value: '36', title: '36 månader' },
      { value: '48', title: '48 månader' },
      { value: '60', title: '60 månader' }
    ]
  },
  { id: 'contractStartDate', type: 'date' },
  { id: 'contractEndDate', type: 'date' },
  { id: 'externalInvoiceFixedPrice', type: 'number', unitLabel: 'kr' },
  { id: 'bookingIntroPrice', type: 'number', unitLabel: 'kr' },
  { id: 'bookingIntroPriceUntil', type: 'date' }
];

const AccountOverviewContractForm = ({ onClose, onSubmit, initialValues, loading }) => {
  const { register, ...methods } = useForm({ defaultValues: initialValues });
  const [contractLength, contractStartDate] = methods.watch(['contractLength', 'contractStartDate']);

  useValueChanged(contractLength, (value) => {
    if (contractStartDate) {
      methods.setValue('contractEndDate', moment(contractStartDate).add(value, 'months'));
    }
  });

  useValueChanged(contractStartDate, (value) => {
    if (contractLength) {
      methods.setValue('contractEndDate', moment(value).add(contractLength, 'months'));
    }
  });

  return (
    <FormProvider {...methods} register={register}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {fields.map(({ id, type, label, required, ...props }) => (
          <HookFormInput
            key={id}
            id={id}
            type={type}
            label={txt(msg[id])}
            initialValue={initialValues[id]}
            registerOptions={{ valueAsNumber: type === 'number', min: 0 }}
            {...props}
          />
        ))}
        <FormButtonsFooter
          onClose={onClose}
          handleSubmit={methods.handleSubmit}
          submitSucceeded={false}
          submitting={loading}
        />
      </form>
    </FormProvider>
  );
};

export default AccountOverviewContractForm;
