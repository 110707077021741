import React from 'react';
import TB from '@Components/ui/tb-button';

const CollapseToolbarButtons = ({ onExpandAll, onCollapseAll }) => (
  <>
    <button type="button" className="btn btn-header-toolbar" onClick={onCollapseAll}>
      <i className="fa fa-arrows-to-dotted-line" />
    </button>
    <button type="button" className="btn btn-header-toolbar" onClick={onExpandAll}>
      <i className="fa fa-arrows-from-dotted-line" />
    </button>
  </>
);

export default CollapseToolbarButtons;
