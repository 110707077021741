import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toggleProductsSortable, toggleProductsCollapsed } from '@State/admin-view-reducer';
import Popover from '@Components/ui/popover';
import { getSectionUrl, navigate } from '@Utils/navigate';
import CollapseToolbarButtons from '../../sortable-list/collapse-toolbar-buttons';

class ProductListTools extends Component {
  static propTypes = {
    onToggleSorting: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="columns-header-tools">
        <button type="button" className="btn btn-header-toolbar" onClick={this.handleToggleSortable}>
          <i className="fa fa-sort-amount-down" />
        </button>

        <CollapseToolbarButtons
          onExpandAll={() => this.props.onToggleCollapse([])}
          onCollapseAll={() => this.props.onToggleCollapse(this.props.groupIds)}
        />
      </div>
    );
  }

  handleToggleSortable = () => {
    this.props.onToggleSorting(!this.props.sortable);
  };
}

const mapStateToProps = (state) => {
  const { productGroups, adminViewState } = state;
  const groupIds = productGroups.map(item => item.get('id')).toJS();

  return {
    collapsedIds: adminViewState.get('productsCollapsedIds'),
    sortable: adminViewState.get('productsSortable'),
    stockTaking: adminViewState.get('productsStockTaking'),
    productGroups,
    groupIds
  };
};

const mapDispatchToProps = (dispatch, { routeParams }) => {
  return {
    onToggleCollapse: (collapsedIds) => {
      return dispatch(toggleProductsCollapsed(collapsedIds));
    },
    onToggleSorting: (sortable) => {
      return dispatch(toggleProductsSortable(sortable));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductListTools);
