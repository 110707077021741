import { createSelector } from 'reselect';
import { getFeatures } from '@State/selectors';
import { txt } from '@Utils/i18n-util';
import msg from './customer-tabs.msg';

export const getCustomerTabSections = createSelector(
  getFeatures,
  (locationFeatures) => {
    const { ShowPOS } = locationFeatures;

    const sections = [
      { navId: 'overview', name: txt(msg.overview) },
      { navId: 'bookings', name: txt(msg.bookings) },
      { navId: 'logs', name: txt(msg.logs) }
    ];

    if (ShowPOS) {
      sections.push({ navId: 'sales', name: txt(msg.payments) });
      sections.push({ navId: 'gift-cards', name: txt(msg.giftCards) });
    }

    return sections;
  }
);
