import React from 'react';

import CurrencyUtil from '@Utils/currency-util';
import { getDiscountDescription, getItemDescription } from '@Utils/pos-utils';
import { Text, Cell } from '@Components/ui/styled/main';
import { SaleItemStyled, SaleRow } from '@Components/customers/customer-styles';

const renderSaleItemRow = (quantity, description, itemType, value) => (
  <SaleRow>
    <Cell width="20px">{quantity}</Cell>
    <Cell>{getItemDescription(description, itemType)}</Cell>
    <Cell pullRight>{value}</Cell>
  </SaleRow>
);

const CustomerDetailSaleSpecificationItem = ({ item }) => {
  const {
    itemType, description, quantity, totalAmount, incVatAmount, discountType, discountAmount, discountValue
  } = item.toJS();
  return (
    <SaleItemStyled>
      <Text fs={13}>
        {renderSaleItemRow(quantity, description, itemType, CurrencyUtil.accountCurrency(totalAmount || incVatAmount, 2))}
      </Text>
      {discountAmount !== 0 && (
        <Text muted fs={11}>
          {renderSaleItemRow(
            null,
            getDiscountDescription(discountValue, discountType),
            itemType,
            CurrencyUtil.accountCurrency(discountAmount)
          )}
        </Text>
      )}
    </SaleItemStyled>
  );
};

export default CustomerDetailSaleSpecificationItem;
