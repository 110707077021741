import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { DAY_DATE_FULL } from '@Utils/time-constants';
import { getResourcePreference, mergeResourcePreferences } from '@State/preferences-actions';
import { fetchPublicHolidays } from '@State/schedule-actions';
import { web } from '@Utils/preference-keys';
import Loader from '@Components/ui/loader';
import { Panel } from '@Components/ui/styled/main';
import LabelSwitchInput from '@Components/ui/label-switch-input';

const ScheduleHolidays = ({ resourceId }) => {
  const dispatch = useDispatch();
  const holidays = useSelector(state => state.adminSchedules.get('holidays'));
  const loadedDate = holidays && !holidays.isEmpty() && moment(holidays.first().get('date'));

  const [isLoading, setIsLoading] = useState(false);
  const [year, setYear] = useState(moment());

  useEffect(() => {
    fetchHolidays(year);
  }, []);

  const fetchHolidays = (newYear) => {
    if (loadedDate && loadedDate.isSame(newYear, 'year')) {
      return;
    }
    setYear(newYear);
    setIsLoading(true);
    dispatch(fetchPublicHolidays(newYear.format('YYYY')))
      .then(() => setIsLoading(false));
  };

  const blockPublicHolidays = useSelector(state => getResourcePreference(state, web.blockPublicHolidays) || false);

  const toggleCloseHolidays = () => {
    return dispatch(mergeResourcePreferences(resourceId, {
      [web.blockPublicHolidays]: !blockPublicHolidays
    }));
  };

  const handlePreviousYear = (ev) => {
    ev.preventDefault();
    fetchHolidays(year.clone().subtract(1, 'year'));
  };

  const handleNextYear = (ev) => {
    ev.preventDefault();
    fetchHolidays(year.clone().add(1, 'year'));
  };

  return (
    <div className="schedule-content-holidays">
      {isLoading && <Loader />}
      <Panel>
        <div className="form-group">
          <LabelSwitchInput
            noBorder
            label="Stäng mitt schema på helgdagar"
            id="block-public-holidays"
            isOn={blockPublicHolidays}
            handleToggle={toggleCloseHolidays}
            description="Om du vill öppna en specifik dag går det att skapa ett undantag"
          />
        </div>
      </Panel>
      <table className="table table-striped">
        <thead>
          <tr>
            <th colSpan={2}>
              <div className="nav-header">
                <button className="btn btn-default" onClick={handlePreviousYear}><i className="fa fa-chevron-left" /></button>
                <span>
                  Helgdagar {year.format('YYYY')}
                </span>
                <button className="btn btn-default" onClick={handleNextYear}><i className="fa fa-chevron-right" /></button>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {holidays && holidays.sortBy(d => d.get('date')).map((holiday) => {
            return (
              <tr key={holiday.get('date')}>
                <td className="col-xs-3">
                  <strong>{moment(holiday.get('date')).format(DAY_DATE_FULL)}</strong>
                </td>
                <td className="col-xs-3 text-bold">
                  {holiday.get('name')}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ScheduleHolidays;
