import { Map } from 'immutable';
import {
  DELIVERIES_FETCHED, DELIVERY_FETCHED, DELIVERY_CREATED,
  DELIVERY_DELETED, DELIVERY_FINISHED, DELIVERY_ITEM_DELETED,
  DELIVERY_ITEM_ADDED, DELIVERY_ITEM_UPDATED
} from './delivery-actions';

export function deliveries(state = Map(), action = null) {
  switch (action.type) {
    case DELIVERIES_FETCHED:
      return state.clear().withMutations(map => {
        for (const item of action.items) {
          map.set(item.id, item);
        }
      });

    case DELIVERY_CREATED:
      return state.set(action.delivery.id, action.delivery);

    case DELIVERY_DELETED:
      return state.delete(action.deliveryId);

    case DELIVERY_FINISHED: {
      const { deliveryId, finishedTs } = action;
      return state.set(deliveryId, { ...state.get(deliveryId), finishedTs });
    }

    default:
      return state;
  }
}

export function deliveryItems(state = Map(), action = null) {
  switch (action.type) {
    case DELIVERY_FETCHED:
      return state.clear().withMutations(map => {
        for (const row of action.delivery.rows) {
          map.set(row.itemId, row);
        }
      });

    case DELIVERY_ITEM_ADDED: {
      const { itemId } = action.deliveryItem;
      return state.set(itemId, action.deliveryItem);
    }

    case DELIVERY_ITEM_UPDATED: {
      const { itemId, deliveredQuantity, price } = action.deliveryItem;
      return state.set(itemId, { ...state.get(itemId), deliveredQuantity, price });
    }

    case DELIVERY_ITEM_DELETED: {
      const { itemId } = action.deliveryItem;
      return state.delete(itemId);
    }

    default:
      return state;
  }
}
