import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrganizationsApi } from '@State/sysadmin-actions';
import {
  getSysadminAllOrganizations,
  getSysadminFilteredOrganizations,
  getSysadminStateSelectedOrganization
} from '@Components/sysadmin/accounts/accounts-selector';
import { getSysadminUrl, navigate } from '@Utils/navigate';

import AccountsList from '@Components/sysadmin/accounts/accounts-list/accounts-list';
import AccountsDetail from '@Components/sysadmin/accounts/account-detail/account-detail';
import AccountsContext from '@Components/sysadmin/accounts/accounts-context';
import AccountsFilter from '@Components/sysadmin/accounts/accounts-filter';
import ColumnSearch from '@Components/ui/column/column-search';
import Button from '@Components/ui/button';

const normalize = (value) => value?.toLocaleLowerCase().replace('-', '');

const Accounts = ({ match }) => {
  const dispatch = useDispatch();
  const routeParams = match.params;
  const id = +routeParams?.id;
  const [loading, setLoading] = useState(false);
  const phoneMode = useSelector(state => state.mainViewState.get('phoneMode'));
  const selectedOrg = useSelector(state => getSysadminStateSelectedOrganization(state, id));
  const allOrganizations = useSelector(state => getSysadminAllOrganizations(state));
  const organizations = useSelector(state => getSysadminFilteredOrganizations(state));
  const [accounts, setAccounts] = useState(organizations);
  const [searchText, setSearchText] = useState('');
  const [showSidebar, setShowSidebar] = useState(true);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await dispatch(fetchOrganizationsApi({ terminated: true }));
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    setAccounts(organizations);
  }, [organizations]);

  const filterOrgs = useCallback((org) => {
    const includesSearch = (value) => normalize(value)?.includes(normalize(searchText));

    return includesSearch(org.get('name'))
      || includesSearch(org.get('orgNo'))
      || org.get('staffEmails')?.toJS()?.some((email) => includesSearch(email))
      || org.get('orgs')?.some((org) => includesSearch(org.get('orgNo')));
  }, [searchText]);

  const handleSearch = useCallback((e) => {
    setSearchText(e.target.value);
  }, []);

  const handleFilter = useCallback(() => {
    setSearchText('');
  }, []);

  useEffect(() => {
    setAccounts(searchText !== ''
      ? allOrganizations?.filter(filterOrgs)
      : organizations);
  }, [searchText, filterOrgs]);

  const onSelectItem = useCallback((id) => {
    navigate(getSysadminUrl({ id, subsection: routeParams.tabId }));

    if (phoneMode) {
      setTimeout(() => setShowSidebar(false), 200);
    }
  }, [routeParams.tabId]);

  useEffect(() => {
    if (accounts && accounts.size > 0 && !id || selectedOrg && selectedOrg.get('id') !== id) {
      navigate(getSysadminUrl({ id: selectedOrg.get('id'), subsection: routeParams.tabId }));
    }
  }, [accounts, selectedOrg]);

  return (
    <AccountsContext.Provider value={{ routeParams }}>
      <div className="columns-wrapper">
        <div className="columns-container">
          {showSidebar && (
            <div className="columns-sidebar">
              <div className="columns-header mt1">
                <ColumnSearch
                  showSearchIcon
                  searchValue={searchText}
                  onSearch={handleSearch}
                  placeholder="Sök på namn, org.nr eller e-post"
                />
                <div className="columns-header-tools accounts">
                  <Button small gray onClick={() => setShowSidebar(false)}>
                    <i className="fa fa-sidebar" />
                  </Button>
                </div>
              </div>
              <AccountsFilter
                notInUse={!!searchText}
                onFilter={handleFilter}
              />
              <div className="columns-list">
                <AccountsList
                  loading={loading}
                  list={accounts}
                  onSelectItem={onSelectItem}
                  routeParamsId={id}
                  routeParamsTabId={routeParams.tabId}
                />
              </div>
            </div>
          )}
          <div className="columns-content">
            <div className="columns-content-container">
              <div className="columns-content-body small-padding select-text">
                <AccountsDetail
                  loading={loading}
                  phoneMode={phoneMode}
                  showSidebar={showSidebar}
                  onShowSidebar={() => setShowSidebar(true)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </AccountsContext.Provider>
  );
};
Accounts.propTypes = {
  match: PropTypes.object
};

export default Accounts;
