import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import CurrencyUtil from '@Utils/currency-util';
import ColumnListItem from '@Components/ui/column/column-list-item';

const ReportListItem = ({ report, isSelected, itemClick }) => {
  const {
    openedTs, closedTs, createdTs, netAmount, registerName
  } = report;
  const opened = moment(openedTs).format('HH:mm');
  const closed = moment(closedTs).format('HH:mm');
  const created = moment(createdTs).format('dddd D MMMM');

  return (
    <ColumnListItem
      selected={isSelected}
      onClick={() => itemClick(report)}
      subHeader={CurrencyUtil.accountCurrency(netAmount, 2)}
      subText={`${created} kl ${opened} - ${closed}`}
      header={registerName}
    />
  );
};

ReportListItem.propTypes = {
  report: PropTypes.object,
  isSelected: PropTypes.bool,
  itemClick: PropTypes.func
};

export default ReportListItem;
