import React from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import PosOrgEmpty from './pos-config/pos-org-empty';

const PosOrgRoute = ({ path, component }) => {
  const posOrgs = useSelector(state => state.posOrgs);

  return (
    <Route path={path} component={posOrgs.isEmpty() ? PosOrgEmpty : component} />
  );
};

export default PosOrgRoute;
