import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from '@State/reducers';

const middlewares = [
  thunkMiddleware
];

if (process.env.NODE_ENV !== 'production') {
  // const loggerMiddleware = createLogger();
  // middlewares.push(loggerMiddleware);
}

const createStoreWithMiddleware = applyMiddleware(
  ...middlewares
)(createStore);

export default function configureStore(initialState) {
  return createStoreWithMiddleware(rootReducer, initialState);
}
