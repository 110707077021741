import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useKeyPress from '@Hooks/useKeyPress';
import { fetchBookingEvents } from '@State/bkf/actions';
import { fetchCustomerEvents } from '@State/customer-actions';
import Modal from '@Components/ui/modal';
import { DialogTitleBtn } from '@Components/dialogs/dialog-styles';
import HistoryEvent from '@Components/dialogs/history-event';
import Button from '@Components/ui/button';
import Loader from '@Components/ui/loader';

const EventsModal = ({ bookingId = null, customerId = null, onClose }) => {
  const dispatch = useDispatch();

  const [events, setEvents] = useState(null);
  const [loading, setLoading] = useState(true);
  const hasEvents = events?.length > 0;

  useKeyPress(27, onClose);

  useEffect(() => {
    if (bookingId) {
      dispatch(fetchBookingEvents(bookingId))
        .then(events => setEvents(events))
        .finally(() => setLoading(false));
    }
    if (customerId) {
      dispatch(fetchCustomerEvents(customerId))
        .then(events => setEvents(events))
        .finally(() => setLoading(false));
    }
  }, [bookingId, customerId]);

  return (
    <Modal
      titleText="Historik"
      focusTrapPaused
      focusDialog
      underlayClickExits={false}
      mounted
      dialogClass="modal-dialog"
    >
      <div className="modal-content">
        <div className="modal-body">
          <div className="pull-right">
            <DialogTitleBtn onClick={onClose}>Stäng</DialogTitleBtn>
          </div>
          {bookingId && (
            <>
              <h4 className="mt0 mb0">Bokningshistorik</h4>
              <p className="text-muted">Bokningsnr. {bookingId}</p>
            </>
          )}
          {customerId && (
            <>
              <h4 className="mt0 mb0">Kundhistorik</h4>
              <p className="text-muted">Kundnr. {customerId}</p>
            </>
          )}
          {loading && <Loader />}
          {!loading && hasEvents && (
            events.map((item) => <HistoryEvent key={item.eventTs} {...item} />)
          )}
          {!loading && !hasEvents && (
            <p className="text-center mt4 mb4">
              Det finns ingen historik
            </p>
          )}
          <div className="text-right">
            <Button gray small onClick={onClose}>Stäng</Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EventsModal;
