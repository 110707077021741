import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCcuInfo, updateCcuInfo } from '@State/pos-config-actions';
import { formatOrgNo } from '@Utils/luhn-util';
import { getSysAdmin } from '@State/selectors';
import ModalDialog from '@Components/dialogs/modal-dialog';
import { Button, Flex } from '@Components/ui/styled/main';
import ProgressButton from '@Components/ui/progress-button';
import AlertWithIcon from '@Components/ui/alert-with-icon';
import Loader from '@Components/ui/loader';
import Panel from '@Components/ui/styled/panel';
import { PosFlexWrapText, GrayLabelText } from './style';

const Block = ({ title, children }) => (
  <PosFlexWrapText>
    <GrayLabelText>{title}</GrayLabelText>
    {children}
  </PosFlexWrapText>
);

const PosUnitCcuInfo = ({ posOrgId, posUnitId, onClose }) => {
  const dispatch = useDispatch();
  const isSysAdmin = useSelector(getSysAdmin);

  const posOrgs = useSelector(state => state.posOrgs);
  const posOrg = useMemo(() => posOrgs.find(o => o.get('id') === posOrgId), [posOrgId]);

  const posUnits = useSelector(state => state.posUnits);
  const posUnit = useMemo(() => posUnits.find(p => p.get('id') === posUnitId), [posUnitId]);

  const [loading, setLoading] = useState(false);
  const [ccuInfo, setCcuInfo] = useState(null);

  useEffect(() => {
    setLoading(true);
    dispatch(fetchCcuInfo(posOrgId, posUnitId))
      .then((info) => {
        setCcuInfo(info);
        setLoading(false);
      });
  }, [posOrgId]);

  const handleUpdate = () => {
    return dispatch(updateCcuInfo(posOrgId, posUnitId))
      .then(() => dispatch(fetchCcuInfo(posOrgId, posUnitId)))
      .then((info) => setCcuInfo(info));
  };

  const orgAddress = posOrg?.get('companyAddress').toObject();
  const unitPrefs = posUnit?.get('prefs').toObject();
  const companyInfo = ccuInfo?.Info.CompanyInfo;
  const storeInfo = ccuInfo?.Info.StoreInfo;
  const registerInfo = ccuInfo?.Info.RegisterInfo;

  return (
    <ModalDialog
      contentSize="xl"
      title="Info om kontrollenhet"
      closeButtonText="Stäng"
      onClose={onClose}
    >
      {loading ? (
        <Loader />
      ) : (
        <div className="select-text">
          {ccuInfo && (
            <>
              <AlertWithIcon>
                Använd denna information för att registrera kontrollenhet
                och kassaregister hos Skatteverkets via deras e-tjänst.
              </AlertWithIcon>
              <Panel>
                <Flex>
                  <Block title="Kontrollenhet">
                    <p>Central Control Unit</p>
                  </Block>
                  <Block title="Tillverkningsnummer">
                    <p>{ccuInfo.CCUID}</p>
                  </Block>
                  <Block title="Kontrollenhetens adress">
                    <p>Bahnhof AB</p>
                    <p>Renstiernas Gata 37</p>
                    <p>116 31 Stockholm</p>
                  </Block>
                </Flex>
              </Panel>
              <Panel>
                <Flex>
                  <Block title="Kassaregister">
                    <p>{registerInfo.RegisterModel}</p>
                  </Block>
                  <Block title="Tillverkningsnummer">
                    <p>{registerInfo.RegisterID}</p>
                  </Block>
                  <Block title="Kassaregistrets adress">
                    <p>{registerInfo.Company}</p>
                    <p>{registerInfo.Address}</p>
                    <p>{registerInfo.Zipcode} {registerInfo.City}</p>
                  </Block>
                </Flex>
              </Panel>
              <Panel>
                <Flex>
                  <Block title="Företag">
                    <p>{companyInfo.Company}</p>
                    <p>{formatOrgNo(companyInfo.OrganizationNumber)}</p>
                  </Block>
                  <Block title="Företagets adress">
                    <p>{companyInfo.Address}</p>
                    <p>{companyInfo.Zipcode} {companyInfo.City}</p>
                  </Block>
                  <Block title="Säljställets adress">
                    <p>{storeInfo.Address}</p>
                    <p>{storeInfo.Zipcode} {storeInfo.City}</p>
                  </Block>
                </Flex>
              </Panel>
            </>
          )}
          {isSysAdmin && (
            <>
              <h5 className="mt3">Information i Cliento</h5>
              <Panel>
                <Flex>
                  <Block title="Företag">
                    <p>{posOrg.get('companyName')}</p>
                    <p>{formatOrgNo(posOrg.get('companyOrgNo'))}</p>
                  </Block>
                  <Block title="Företagets adress">
                    <p>{orgAddress.addressLine1}</p>
                    <p>{orgAddress.postCode} {orgAddress.area}</p>
                  </Block>
                  <Block title="Säljställets adress">
                    <p>{unitPrefs.addressLine1}</p>
                    <p>{unitPrefs.addressPostCode} {unitPrefs.addressArea}</p>
                  </Block>
                </Flex>
              </Panel>
            </>
          )}
        </div>
      )}
      <Flex>
        <Flex>
          {!loading && isSysAdmin && (
            <ProgressButton gray small onClick={handleUpdate}>Uppdatera info i CCU</ProgressButton>
          )}
        </Flex>
        <Flex right>
          <Button gray small onClick={onClose}>Stäng</Button>
        </Flex>
      </Flex>
    </ModalDialog>
  );
};

export default PosUnitCcuInfo;
