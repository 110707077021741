import React from 'react';
import { fromJS } from 'immutable';
import InvoiceCustomerPanel from './invoice-customer-panel';
import InvoiceCustomerSearch from './invoice-customer-search';

const InvoiceCustomerSelect = ({
  posOrgId, selectedCustomer, onNewCustomer, onUpdateCustomer,
  onResetCustomer, onSelectCustomer, readonly = false
}) => {
  const handleCustomerSelected = (customer) => {
    onSelectCustomer(customer ? fromJS(customer) : null);
  };

  const handleNewCustomer = (ev) => {
    ev.preventDefault();
    onNewCustomer();
  };

  const handleResetCustomer = (ev) => {
    ev.preventDefault();
    onResetCustomer();
  };

  return (
    <div className="form-group">
      {!readonly && (
        <div className="pull-right">
          {selectedCustomer
            ? <a href="#" onClick={handleResetCustomer}>Byt mottagare</a>
            : <a href="#" onClick={handleNewCustomer}>Ny mottagare</a>}
        </div>
      )}
      <label className="control-label">Mottagare</label>
      {selectedCustomer ? (
        <InvoiceCustomerPanel
          customer={selectedCustomer.toJS()}
          onUpdateCustomer={onUpdateCustomer}
          readonly={readonly}
        />
      ) : (
        <InvoiceCustomerSearch
          posOrgId={posOrgId}
          onCustomerSelected={handleCustomerSelected}
        />
      )}
    </div>
  );
};

export default InvoiceCustomerSelect;
