import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedNumber } from 'react-intl';
import { Panel } from '@Components/ui/styled/main';

class SmsReport extends Component {
  render() {
    const { reportsViewState } = this.props;
    const smsReport = reportsViewState.get('sms');
    const sentSmsPerResource = smsReport ? smsReport.get('sentSmsPerResource') : null;

    return (
      <div>
        {sentSmsPerResource && !sentSmsPerResource.isEmpty() ? this.renderTable(smsReport) : this.renderNoData(sentSmsPerResource)}
      </div>
    );
  }

  renderTable(smsReport) {
    const { resourcesById } = this.props;
    const { sentSmsPerResource } = smsReport.toJS();

    let totalCount = 0;
    let totalCost = 0;
    const that = this;

    return (
      <Panel>
        <table className="table table-report">
          <thead>
            <tr>
              <th>Resurs</th>
              <th className="text-right">Antal</th>
              <th className="text-right">Kostnad</th>
            </tr>
          </thead>
          {
            _.map(sentSmsPerResource, (item, key) => {
              const resourceId = parseInt(key);
              const resource = resourcesById.get(resourceId);
              const resourceName = resource ? resource.name : 'Borttagen resurs';

              return (
                <tbody className="report-group" key={key}>
                  <tr>
                    <th>{resourceId === 0 ? 'Ingen resurs' : resourceName}</th>
                    <td />
                    <td />
                  </tr>
                  {
                    _.map(item, (count, tag) => {
                      const cost = count * that.smsTagCost(tag, smsReport);
                      totalCount += count;
                      totalCost += cost;
                      return (
                        <tr key={tag}>
                          <td>{that.smsTagString(tag)}</td>
                          <td className="text-right">{count}</td>
                          <td className="text-right"><FormattedNumber style="currency" currency="SEK" value={cost} /></td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              );
            })
          }
          <tfoot>
            <tr>
              <th>Totalt</th>
              <th className="text-right">{totalCount}</th>
              <th className="text-right"><FormattedNumber style="currency" currency="SEK" value={totalCost} /></th>
            </tr>
          </tfoot>
        </table>
      </Panel>
    );
  }

  renderNoData(sentSmsPerResource) {
    return sentSmsPerResource
      ? (
        <div className="alert alert-info">
          <i className="fa fa-info-circle" /> Det finns inga skickade SMS för vald period.
        </div>
      ) : null;
  }

  smsTagString(tag) {
    switch (tag) {
      case 'Confirm':
        return 'Bekräftelser';

      case 'Reminder':
        return 'Påminnelser';

      case 'Receipt':
        return 'Kvitton';

      case 'Bulk':
        return 'Utskick';

      case 'Pin':
        return 'Bekräftelse med SMS-kod';

      case 'Free':
        return 'Gratis SMS';

      default:
        return 'SMS';
    }
  }

  smsTagCost(tag, smsReport) {
    const { smsReminderPrice, smsPinPrice, smsBulkPrice } = smsReport.toJS();

    switch (tag) {
      case 'Confirm':
      case 'Reminder':
      case 'Receipt':
        return smsReminderPrice;

      case 'Bulk':
        return smsBulkPrice;

      case 'Pin':
        return smsPinPrice;

      default:
        return 0;
    }
  }
}

SmsReport.propTypes = {
  reportsViewState: PropTypes.object.isRequired,
  resourcesById: PropTypes.object.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const { reportsViewState, resourcesById } = state;

  return {
    reportsViewState,
    resourcesById
  };
};

export default connect(mapStateToProps)(SmsReport);
