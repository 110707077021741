export const FETCHED_SCHEDULES = 'FETCHED_SCHEDULES';
export const UPDATED_USE_LOCATION_SCHEDULE = 'UPDATED_USE_LOCATION_SCHEDULE';
export const FETCHED_ADMIN_SCHEDULE_RESOURCE = 'FETCHED_ADMIN_SCHEDULE_RESOURCE';

export const SET_SCHEDULE_BLOCKS = 'SET_SCHEDULE_BLOCKS';
export const SCHEDULE_SAVED = 'SCHEDULE_SAVED';
export const SCHEDULE_CREATED = 'SCHEDULE_CREATED';
export const FETCHED_SCHEDULE_EXCEPTIONS = 'FETCHED_SCHEDULE_EXCEPTIONS';
export const DELETED_SCHEDULE_EXCEPTION = 'DELETED_SCHEDULE_EXCEPTION';

export const FETCHED_PUBLIC_HOLIDAYS = 'FETCHED_PUBLIC_HOLIDAYS';
