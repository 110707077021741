import React from 'react';
import { txt } from '@Utils/i18n-util';
import msg from './send-receipt.msg';

const SendReceipt = ({
  onCancel, onConfirm, progress, customer
}) => {
  const { name, email } = customer;

  return (
    <form autoComplete="off" onSubmit={onConfirm} className={progress ? 'disabled' : ''}>
      <p>
        <strong>{txt(msg.lblSendReceiptTo)}</strong>
      </p>
      <div className="form-group">
        <input type="text" className="form-control" name="name" placeholder={txt(msg.placeholderName)} defaultValue={name} required />
      </div>
      <div className="form-group">
        <input type="email" className="form-control" name="email" placeholder={txt(msg.placeholderEmail)} defaultValue={email} required />
      </div>
      <div className="text-right">
        <button type="button" className="btn btn-default" tabIndex="4" onClick={onCancel}>{txt(msg.btnCancel)}</button> &nbsp;
        <button type="submit" className="btn-save" tabIndex="3">{progress ? txt(msg.btnSendProgress) : txt(msg.btnSend)}</button>
      </div>
    </form>
  );
};

export default SendReceipt;
