import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addQueuedBookingPayment, parkSale, resetQueuedBookingPayment } from '@State/pos-actions';
import ModalDialog from '@Components/dialogs/modal-dialog';
import { DialogContent } from '@Components/dialogs/dialog-styles';

const ConfirmAddBooking = () => {
  const dispatch = useDispatch();
  const [progress, setProgress] = useState(false);

  const handleAdd = () => {
    setProgress(true);
    dispatch(addQueuedBookingPayment())
      .catch(() => setProgress(false));
  };

  const handlePark = () => {
    setProgress(true);
    dispatch(parkSale())
      .then(() => dispatch(addQueuedBookingPayment()))
      .catch(() => setProgress(false));
  };

  const handleCancel = () => {
    dispatch(resetQueuedBookingPayment());
  };

  return (
    <ModalDialog
      title="Lägg till bokning"
      onClose={handleCancel}
      buttons={[{
        name: 'Lägg till',
        primary: true,
        onClick: handleAdd,
        disabled: progress
      }, {
        name: 'Parkera och skapa nytt',
        gray: true,
        onClick: handlePark,
        disabled: progress
      }]}
    >
      <DialogContent>
        Det finns redan ett påbörjat kvitto. Vill du lägga till
        bokningen till detta eller parkera kvittot?
      </DialogContent>
    </ModalDialog>
  );
};

export default ConfirmAddBooking;
