const s = require('underscore.string');
const PhoneNumber = require('./phone-number');

module.exports = {
  initialize(countryCode, nddPrefix, iddPrefix) {
    this.countryCode = countryCode;
    this.nddPrefix = nddPrefix;
    this.iddPrefix = iddPrefix;
  },

  isMobile(number) {
    const prefix = `(${this.countryCode}|${this.nddPrefix}|${this.iddPrefix})`;
    return number && number.match(new RegExp(`^\\+?${prefix}7[0-9]{8}$`));
  },

  parseNumber(n) {
    let number = this._normalize(n);

    if (this._isInternational(number)) {
      if (s.startsWith(number, this.iddPrefix)) {
        return new PhoneNumber(number.substring(2), '', '', true);
      }
      return new PhoneNumber(number, '', '', true);
    }
    if (s.startsWith(number, this.iddPrefix)) {
      number = number.substring(this.iddPrefix.length);
    }

    if (s.startsWith(number, this.nddPrefix)) {
      number = number.substring(this.nddPrefix.length);
    }

    if (s.startsWith(number, this.countryCode)) {
      number = number.substring(this.countryCode.length);
    }

    return new PhoneNumber(number, this.countryCode, this.nddPrefix, false);
  },

  _isInternational(normalizedNumber) {
    if (!s.startsWith(normalizedNumber, this.iddPrefix) && !s.startsWith(normalizedNumber, this.nddPrefix) && !s.startsWith(normalizedNumber, this.countryCode)) {
      return true;
    }

    return s.startsWith(normalizedNumber, this.iddPrefix) && !s.startsWith(normalizedNumber, this.iddPrefix + this.countryCode);
  },

  _normalize(n) {
    let number = s.strip(n);
    number = number.replace(/\+/g, this.iddPrefix);
    number = number.replace(/\D/g, '');

    return number;
  }
};
