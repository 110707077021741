import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import useFetch from '@Hooks/useFetch';
import { fetchCustomer } from '@State/customer-actions';
import customerIdContext from '@Components/customers/customer-id-context';

export default () => {
  const customerId = useContext(customerIdContext);
  const loadedCustomer = useSelector((state) => state.customerById);
  const customers = useSelector(({ customers }) => customers.get('customerEntries'));

  const selectedCustomer = useMemo(() => {
    if (loadedCustomer && loadedCustomer.get('id') === customerId) {
      return loadedCustomer.toJS();
    }
    return customers?.find(c => c.get('customerId') === customerId)?.toJS();
  }, [customerId, loadedCustomer, customers]);

  const { isLoading } = useFetch((customerId) => {
    if (customerId && selectedCustomer?.id !== customerId) {
      return fetchCustomer(customerId);
    }
  }, [customerId], [customerId]);

  return {
    customerId,
    selectedCustomer,
    isLoading
  };
};
