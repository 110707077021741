import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import { emailReplyToEnabled } from '@Components/campaign/campaign-helpers';
import { email } from '@Utils/preference-keys';
import { required } from '@Utils/validation';
import SubmitButton from '@Components/ui/submit-button';
import TextInputHorizontal from '@Components/inputs/text-input-horizontal';
import RichTextEditorField from '../../inputs/rich-text-editor';
import EmailIcsConfig from '../../inputs/email-ics-config';

class EmailConfigForm extends Component {
  state = {
    showReplyTo: emailReplyToEnabled(this.props.emailReplyTo)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!emailReplyToEnabled(this.props.emailReplyTo) && emailReplyToEnabled(nextProps.emailReplyTo)) {
      this.setState({ showReplyTo: true });
    }
  }

  toggleShowReplyTo = (ev) => {
    this.setState({ showReplyTo: ev.target.checked });

    if (!ev.target.checked) {
      this.props.setFormValue(email.replyTo, null);
    }
  };

  render() {
    const { showReplyTo } = this.state;
    const { handleSubmit, features } = this.props;

    return (
      <form autoComplete="off" className="form-horizontal" onSubmit={handleSubmit}>

        <div className="row">
          <div className="col-sm-offset-3 col-sm-9">
            <h3>Inställningar för mejlutskick</h3>
          </div>
        </div>

        {features.sender && (
          <div>
            <Field name={email.fromName} component={TextInputHorizontal} label="Avsändare" />

            <div className="form-group">
              <div className="col-sm-offset-3 col-sm-9">
                <div className="checkbox">
                  <label>
                    <input type="checkbox" checked={showReplyTo} onChange={this.toggleShowReplyTo} />
                    Tillåt att kunder svarar på mejlutskick
                  </label>
                </div>
              </div>
            </div>

            {showReplyTo && (
              <>
                <div className="row">
                  <div className="col-sm-offset-3 col-sm-9">
                    <p className="text-muted">
                      Ange en mejladress så att kunden kan svara på mejlutskick.
                    </p>
                  </div>
                </div>
                <Field name={email.replyTo} type="email" component={TextInputHorizontal} validate={[required]} />
              </>
            )}
            <hr />
          </div>
        )}

        {(features.emailOnBookOnline || features.emailOnCancelOnline) ? (
          <div className="form-group">
            <label className="col-sm-3 control-label">Bokningsbekräftelse</label>
            <div className="col-sm-9">
              {(features.emailOnBookOnline) ? (
                <div className="checkbox">
                  <label>
                    <Field name={email.onBookOnline} component="input" type="checkbox" />
                    Skicka mejl vid bokning online
                  </label>
                </div>
              ) : null }
              {(features.emailOnCancelOnline) ? (
                <div className="checkbox">
                  <label>
                    <Field name={email.onCancelOnline} component="input" type="checkbox" />
                    Skicka mejl vid avbokning online
                  </label>
                </div>
              ) : null }
            </div>
          </div>
        ) : null }

        {(features.defaultOptionEmailConfirm) ? (
          <div className="form-group">
            <div className="col-sm-offset-3 col-sm-9">
              <div className="checkbox">
                <label>
                  <Field name={email.defaultOptionEmailConfirm} component="input" type="checkbox" />
                  Kryssa i mejlbekräftelse vid bokning/avbokning i kalendern
                </label>
              </div>
            </div>
          </div>
        ) : null }

        {(features.emailConfirmExtraInfo) ? (
          <div>
            <hr />
            <div className="form-group">
              <label className="col-sm-3 control-label">Extra information vid bokning</label>
              <div className="col-sm-7">
                <Field name={email.confirmExtraInfo} component={RichTextEditorField} />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-offset-3 col-sm-6">
                <p className="text-muted">
                  Lägg till extra information i bokningsbekräftelse,
                  som till exempel öppettider eller avbokningsregler.
                </p>
              </div>
            </div>

            <hr />
            <div className="form-group">
              <label className="col-sm-3 control-label">Extra information vid avbokning</label>
              <div className="col-sm-7">
                <Field name={email.cancelExtraInfo} component={RichTextEditorField} />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-offset-3 col-sm-6">
                <p className="text-muted">
                  Lägg till extra information i avbokningsbekräftelse,
                  som till exempel information om återbetalning eller ombokning.
                </p>
              </div>
            </div>
          </div>
        ) : null }

        <hr />

        {(features.emailIcsConfirm) ? (
          <div>
            <div className="form-group">
              <div className="col-sm-offset-3 col-sm-9">
                <EmailIcsConfig {...this.props} />
              </div>
            </div>
            <hr />
          </div>
        ) : null }

        <div className="form-group">
          <div className="col-sm-offset-3 col-sm-5">
            <SubmitButton {...this.props} />
          </div>
        </div>
      </form>
    );
  }
}

const selector = formValueSelector('email-config');

const mapStateToProps = (state) => {
  return {
    emailReplyTo: selector(state, email.replyTo),
    includeIcs: selector(state, email.includeIcs),
    icsHeader: selector(state, email.icsHeader),
    icsLocation: selector(state, email.icsLocation),
    icsNotes: selector(state, email.icsNotes)
  };
};

const mapDispatchToProps = dispatch => ({
  setFormValue: (property, value) => {
    dispatch(change('email-config', property, value));
  }
});

export default reduxForm({
  form: 'email-config',
  enableReinitialize: true
})(connect(mapStateToProps, mapDispatchToProps)(EmailConfigForm));
