import React from 'react';
import { Cell, Text } from '@Components/ui/styled/main';
import { getIconByPayment, getPaymentMethodName } from '@Utils/pos-utils';

import CurrencyUtil from '@Utils/currency-util';
import { RowTransaction } from '@Components/customers/customer-styles';

const CustomerDetailTransactions = ({ items = [] }) => (
  <>
    {items.map(({
      paymentMethod, paymentMethodName, transactionType, transactionAmount, paymentId
    }) => (
      <RowTransaction key={paymentId}>
        <Cell width="35px"><i className={transactionType === 'Refund' ? 'fal fa-reply' : getIconByPayment(paymentMethod)} /></Cell>
        <Cell><Text fs={13}>{getPaymentMethodName(paymentMethodName || paymentMethod)}</Text></Cell>
        <Cell pullRight>
          <Text fs={13}>
            {CurrencyUtil.accountCurrency(transactionAmount)}
          </Text>
        </Cell>
      </RowTransaction>
    ))}
  </>
);

export default CustomerDetailTransactions;
