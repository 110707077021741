import { defineMessages } from 'react-intl';

export default defineMessages({
  lblToday: {
    id: 'customers.bookings.lblToday',
    defaultMessage: 'Today'
  },
  lblTomorrow: {
    id: 'customers.bookings.lblTomorrow',
    defaultMessage: 'Tomorrow'
  },
  lblAfterTomorrow: {
    id: 'customers.bookings.lblAfterTomorrow',
    defaultMessage: 'Day after tomorrow'
  },
  lblThisWeek: {
    id: 'customers.bookings.lblThisWeek',
    defaultMessage: 'This week'
  },
  lblNextWeek: {
    id: 'customers.bookings.lblNextWeek',
    defaultMessage: 'Next week'
  }
});
