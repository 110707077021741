import { defineMessages } from 'react-intl';

export default defineMessages({
  skipWeeks: {
    id: 'calendar.dateNavigator.skipWeeks',
    defaultMessage: 'Skip weeks forward'
  },
  week: {
    id: 'calendar.dateNavigator.week',
    defaultMessage: '+ {week} week'
  },
  weeks: {
    id: 'calendar.dateNavigator.weeks',
    defaultMessage: '+ {week} weeks'
  }
});
