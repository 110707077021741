import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { colors } from '@Components/ui/styled/variables';

const LabelStyled = styled.div`
  display: inline-block;
  background-color: ${p => p.bgColor || colors.grayBGLight};
  color: ${p => p.textColor || '#fff'};
  cursor: ${p => p?.onClick ? 'pointer' : ''};
  padding: 0 8px;
  border-radius: ${p => p.large ? '6px' : '5px'};
  height: ${p => p.large ? '22px' : '16px'};
  line-height: ${p => p.large ? '22px' : '15px'};
  font-size: ${p => p.large ? '14px' : '12px'};
  font-weight: ${p => p.large ? 600 : 500};
  text-align: center;
  vertical-align: middle;
  margin-top: -2px;
`;

const Label = ({ children, ...rest }) => (
  <LabelStyled {...rest}>
    {children}
  </LabelStyled>
);

Label.propTypes = {
  bgColor: PropTypes.string,
  textColor: PropTypes.string,
  children: PropTypes.any
};

export default Label;
