import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalDialog from '@Components/dialogs/modal-dialog';
import { Button, Flex } from '@Components/ui/styled/main';
import { fetchReleaseNotes } from '@State/users-actions';
import { setUserPreference } from '@State/user-actions';
import { getIsVersionMismatch } from '@State/selectors';
import { user } from '@Utils/preference-keys';
import Loader from '@Components/ui/loader';
import { DialogCloseX } from './dialog-styles';
import ReleaseNotesItem from './release-notes-item';

const PageLimit = 5;

const ReleaseNotesModal = ({ preview = null, onClose, initialCount = 1 }) => {
  const dispatch = useDispatch();
  const isVersionMismatch = useSelector(getIsVersionMismatch);

  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [releaseNotes, setReleaseNotes] = useState([]);
  const [loading, setLoading] = useState(!preview);

  const fetchData = async (initialLoad) => {
    const fetchCount = initialLoad ? initialCount : PageLimit;
    const response = await dispatch(fetchReleaseNotes(offset, fetchCount));

    setReleaseNotes(prev => prev.concat(response.releaseNotes));
    setOffset(offs => offs + fetchCount);

    const newCount = response.releaseNotes.length + releaseNotes.length;
    if (response.totalRows <= newCount) {
      setHasMore(false);
    }
  };

  useEffect(() => {
    if (preview) {
      return;
    }

    fetchData(true).finally(() => setLoading(false));

    return () => {
      dispatch(setUserPreference(false, {
        [user.releaseNotesDismissed]: new Date().toISOString()
      }));
    };
  }, []);

  const handleFetchMore = (e) => {
    e.preventDefault();
    fetchData();
  };

  const handleRefresh = (e) => {
    e.preventDefault();
    window.location.reload();
  };

  return (
    <ModalDialog
      contentSize="xl"
      onClose={onClose}
      largePadding
    >
      <DialogCloseX
        className="release-notes-modal-close"
        tabIndex={0}
        onClick={onClose}
      >
        <i className="fa fa-xmark" />
      </DialogCloseX>

      {loading ? <Loader /> : (
        <div className="release-notes-modal">
          {preview ? (
            <ReleaseNotesItem releaseNote={preview} />
          ) : releaseNotes.map((releaseNote, i) => (
            <div key={releaseNote.id}>
              {i !== 0 && <hr />}
              <ReleaseNotesItem releaseNote={releaseNote} />
            </div>
          ))}
          {!preview && (
            <Flex vCenter fullWidth className="mt3">
              {hasMore && (
                <a href="#" onClick={handleFetchMore}>
                  Visa tidigare uppdateringar
                  <i className="fa fa-angle-right ml1" />
                </a>
              )}
              {isVersionMismatch && (
                <Button
                  className="release-notes-modal-cta"
                  small
                  primary
                  onClick={handleRefresh}
                >
                  Uppdatera
                </Button>
              )}
            </Flex>
          )}
        </div>
      )}
    </ModalDialog>
  );
};

export default ReleaseNotesModal;
