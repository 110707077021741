import React from 'react';
import moment from 'moment';
import CurrencyUtil from '@Utils/currency-util';
import ModalDialog from '@Components/dialogs/modal-dialog';
import { Flex } from '@Components/ui/styled/main';
import Panel from '@Components/ui/styled/panel';
import InvoiceStatusForm from './invoice-status-form';

const InvoiceStatusModal = ({ invoice, onSubmit, onClose }) => {
  const initialValues = {
    status: invoice.get('status'),
    statusDate: moment().format('L')
  };

  const handleSubmit = (values) => {
    const statusDate = moment(values.statusDate, 'L').format('YYYY-MM-DD');
    return onSubmit({
      id: invoice.get('id'),
      status: values.status,
      paidDate: values.status === 'Paid' ? statusDate : null,
      annulledDate: values.status === 'Annulled' ? statusDate : null,
      debtCollectionDate: values.status === 'DebtCollection' ? statusDate : null
    });
  };

  return (
    <ModalDialog
      title="Ändra status"
      hideCloseButton
      contentSize="small"
      closeOnClickOutside={false}
      onClose={onClose}
    >
      <Panel>
        <Flex>
          <Flex>Fakturanr</Flex>
          <Flex right>{invoice.get('invoiceNumber')}</Flex>
        </Flex>
        <Flex>
          <Flex>Kund</Flex>
          <Flex right>{invoice.getIn(['buyer', 'customerName'])}</Flex>
        </Flex>
        <Flex>
          <Flex>Belopp</Flex>
          <Flex right>{CurrencyUtil.accountCurrency(invoice.get('incVatAmount'), 0)}</Flex>
        </Flex>
      </Panel>
      <InvoiceStatusForm
        initialValues={initialValues}
        onSubmit={handleSubmit}
        onClose={onClose}
      />
    </ModalDialog>
  );
};

export default InvoiceStatusModal;
