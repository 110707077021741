import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reportsQuerySelector } from '@Components/reports/reports-helpers';
import { Panel } from '@Components/ui/styled/main';
import AlertWithIcon from '@Components/ui/alert-with-icon';
import CommissionReportSummary from './commission-report-summary';
import CommissionReportItemRows from './commission-report-item-rows';

class CommissionReport extends Component {
  render() {
    const { reportsViewState, reportsQuery } = this.props;
    const { posOrg } = reportsQuery;
    const salesReport = reportsViewState.get('sales');
    const showReport = salesReport && !salesReport.isEmpty() && posOrg;

    const { salesRows, productSalesTerms } = showReport && salesReport?.toJS() || {};
    const productRows = salesRows?.filter(r => r.type === 'Product' && r.netQty !== 0);
    const commissionPct = productSalesTerms?.commissionPct;
    const hasProductRows = productRows && productRows.length > 0;

    return (
      <div>
        {hasProductRows
          ? this.renderTable(productRows, commissionPct)
          : this.renderNoData()}
      </div>
    );
  }

  renderTable(productRows, commissionPct) {
    const { reportsQuery } = this.props;
    const { user } = reportsQuery;
    return (
      <>
        {!user && (
          <AlertWithIcon icon="fa fa-info-circle">
            Visar total försäljning för aktuell period. Välj användare för att visa provision.
          </AlertWithIcon>
        )}
        <CommissionReportSummary productRows={productRows} commissionPct={commissionPct} />
        <h5 className="mt3">Försäljning per produkt</h5>
        <Panel>
          <CommissionReportItemRows productRows={productRows} commissionPct={commissionPct} />
        </Panel>
      </>
    );
  }

  renderNoData() {
    return (
      <div className="alert alert-info">
        <i className="fa fa-info-circle mr2" />
        Det finns ingen försäljning för vald period/användare
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { reportsViewState } = state;

  return {
    reportsViewState,
    reportsQuery: reportsQuerySelector(state, ownProps)
  };
};

export default connect(mapStateToProps)(CommissionReport);
