import React, { memo } from 'react';
import PropTypes from 'prop-types';
import StaffListItem from '@Components/staff/staff-list-item';
import Button from '@Components/ui/button';

const StaffList = ({ onRedirect, list }) => (
  <div className="columns-list">

    {list.map((item) => (
      <StaffListItem
        key={item.get('id')}
        item={item}
      />
    ))}
    <div className="columns-list-help">
      <p>Lägga till eller ändra i personallistan?</p>
      <Button small primary onClick={onRedirect}>
        Gå till personal
      </Button>
    </div>
  </div>
);

StaffList.propTypes = {
  list: PropTypes.object,
  onRedirect: PropTypes.func
};

export default memo(StaffList);
