import shallowEqual from 'fbjs/lib/shallowEqual';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import { calendar } from '@Utils/preference-keys';
import { clearAndCloseBKF } from '@State/bkf/actions';
import { getPermissions, routeParams } from '@State/selectors';
import { isEmbeddedInApp } from '@Utils/embedded-util';
import { removeWakeCallback, addWakeCallback } from '@Utils/wake-me-up';
import { getCalendarUrl, navigate } from '@Utils/navigate';
import { fetchViewData, storeViewState, toggleResourceListCollapsed, updateViewDimensions } from '@State/view-actions';
import { resetBookings } from '@State/booking-actions';
import { setUserPreference } from '@State/user-actions';

import CalendarSideBar from '@Components/calendar/calendar-sidebar';
import Grid from '@Components/calendar/grid/grid';
import SearchResults from '@Components/calendar/search-results';
import BookingModals from '@Components/calendar/booking/booking-modals';

const stylesCanvas = { top: 0, left: 0 };

class Calendar extends Component {
  static propTypes = {
    routeParams: PropTypes.object.isRequired,
    permissions: PropTypes.array.isRequired,
    storeViewState: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.reloadViewData = debounce(this.props.fetchLatestViewData, 500);
  }

  componentDidMount() {
    this.props.updateViewDimensions();
    const viewState = Calendar.getViewState(this.props);
    this.props.storeViewState(viewState);
  }

  UNSAFE_componentWillMount() {
    window.addEventListener('online', this.handleOnline);
    addWakeCallback(this.handleWake);
  }

  componentWillUnmount() {
    window.removeEventListener('online', this.handleOnline);
    removeWakeCallback(this.handleWake);
    // Clear booking form state on navigation away from calendar
    this.props.clearAndCloseBKF();
  }

  componentDidUpdate(prevProps) {
    if (this.props.confirmMoveEnabled !== prevProps.confirmMoveEnabled) {
      this.props.resetBookngs();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { routeParams, showSearch } = this.props;

    if (!shallowEqual(routeParams, nextProps.routeParams)) {
      const viewState = Calendar.getViewState(nextProps);
      // Clear booking form state on navigation within calendar
      setTimeout(() => {
        this.props.storeViewState(viewState);
        this.props.clearAndCloseBKF();
      });
    }
    if (showSearch !== nextProps.showSearch) {
      navigate(getCalendarUrl(routeParams.viewDate, routeParams, true));
      setTimeout(() => this.props.updateViewDimensions(), 300);
    }
  }

  handleOnline = () => {
    console.info("Browser reported 'online' will try to reload viewData");
    setTimeout(() => this.reloadViewData(this.props.routeParams), 500);
  };

  handleWake = () => {
    console.info('Awake from sleep detected - will try to reload viewData');

    setTimeout(() => {
      if (window.navigator.onLine) {
        this.reloadViewData(this.props.routeParams);
      } else {
        console.info('Browser not online as reported by window.navigator.onLine, skipping reloadViewData');
      }
    }, 500);
  };

  render() {
    return this.props.embeddedInApp ? this.renderMobileAppView() : this.renderFull();
  }

  renderFull() {
    const { routeParams, showSearch } = this.props;

    return (
      <>
        <BookingModals routeParams={routeParams} />
        <CalendarSideBar {...this.props} />
        <div id="canvas" className={showSearch ? 'show-search' : ''}>
          <Grid routeParams={routeParams} />
        </div>
        <SearchResults routeParams={routeParams} />
        <ReactTooltip
          effect="solid"
          place="bottom"
          className="web-indicator-tooltip"
          globalEventOff="mousedown touchstart"
        />
      </>
    );
  }

  renderMobileAppView() {
    const { routeParams, showSearch } = this.props;
    return (
      <div id="canvas" className={showSearch ? 'show-search' : ''} style={stylesCanvas}>
        <Grid routeParams={routeParams} />
      </div>
    );
  }

  static getViewState(props) {
    const {
      org, loc, viewDate, viewMode, entityType, entityId, search
    } = props.routeParams;

    return {
      org,
      loc,
      viewMode,
      viewDate,
      entityType,
      entityId,
      search
    };
  }
}

const mapStateToProps = (state, { match, location }) => {
  const rp = routeParams(match.params, location);
  const permissions = getPermissions(state, { routeParams: rp });
  const {
    bkf, bookingSearchResults, locationConfig, bookingsById, orderedGroups, userClientPreferences
  } = state;
  const dpPosition = locationConfig.get(calendar.dpPosition);
  const confirmMoveEnabled = locationConfig.get(calendar.confirmMoveEnabled);
  const embeddedInApp = isEmbeddedInApp(state);

  return {
    routeParams: rp,
    permissions,
    showSearch: bookingSearchResults.get('showSearch'),
    dpPosition,
    confirmMoveEnabled,
    bookingsById,
    embeddedInApp,
    orderedGroups,
    resourceListViewMode: userClientPreferences.getIn(['user', calendar.resourceListViewMode])
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    storeViewState: (lastView) => {
      dispatch(storeViewState(lastView));
    },
    updateViewDimensions: () => {
      dispatch(updateViewDimensions());
    },
    resetBookngs: () => {
      dispatch(resetBookings());
    },
    fetchLatestViewData: (rp) => {
      const {
        viewDate, viewMode, entityType, entityId
      } = rp;
      if (viewDate && viewMode && entityType) {
        console.info(`Refreshing view data for ${viewMode}, ${viewDate}, ${entityType}, ${entityId}`);
        dispatch(fetchViewData(rp));
      } else {
        console.warn(`NOT reloading view data, routeParams not complete: viewMode: ${viewMode}, viewDate: ${viewDate}, enitytType: ${entityType}, entityId: ${entityId}`);
      }
    },
    clearAndCloseBKF: () => {
      dispatch(clearAndCloseBKF());
    },
    toggleResourceListCollapsed: (collapsedIds) => {
      dispatch(toggleResourceListCollapsed(collapsedIds));
    },
    setResourceListViewMode: (viewMode) => {
      dispatch(setUserPreference(false, {
        [calendar.resourceListViewMode]: viewMode
      }));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
