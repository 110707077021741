import { useEffect, useState } from 'react';
import moment from 'moment';

export function useCurrentTime() {
  const getTime = () => moment().format('HH:mm');

  const [time, setTime] = useState(getTime());

  useEffect(() => {
    const interval = setInterval(() => {
      const current = getTime();
      if (current !== time) {
        setTime(current);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return time;
}
