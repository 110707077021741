import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { pos } from '@Utils/preference-keys';
import Flex from '@Components/ui/styled/flex-item';
import TextInput from '@Components/inputs/text-input';
import CheckBoxEl from '@Components/inputs/checkbox-styled';
import ModalDialog from '@Components/dialogs/modal-dialog';
import AlertWithIcon from '@Components/ui/alert-with-icon';
import Panel from '@Components/ui/styled/panel';

const PosUnitReceiptSettings = ({ onClose, handleSubmit, submitting }) => (
  <ModalDialog
    contentSize="medium"
    title="Kvittoinställningar"
    onClose={onClose}
    buttons={[{
      name: 'Spara',
      primary: true,
      type: 'submit',
      loading: submitting,
      onClick: handleSubmit
    }]}
  >
    <form onSubmit={handleSubmit}>
      <AlertWithIcon>
        OBS! Adressen på kvittot måste vara samma som säljställets adress,
        enligt SKVFS 2014:9
      </AlertWithIcon>
      <AlertWithIcon warning>
        Kontakta support om du vill ändra uppgifterna hos Skatteverket
      </AlertWithIcon>
      <Field
        name="addressLine1"
        component={TextInput}
        label="Gatuadress"
        placeholder="Gatuadress"
      />
      <Flex>
        <Flex rightMargin>
          <Field
            name="addressPostCode"
            type="number"
            component={TextInput}
            label="Postnummer"
            placeholder="Postnummer"
          />
        </Flex>
        <Flex>
          <Field
            name="addressArea"
            component={TextInput}
            label="Postort"
            placeholder="Stockholm"
          />
        </Flex>
      </Flex>
      <Field
        name="companyWebAddress"
        component={TextInput}
        label="Webbplats"
        placeholder="www.cliento.com"
      />
      <Flex>
        <Flex rightMargin>
          <Field
            name="companyEmail"
            component={TextInput}
            label="Mejl"
            placeholder="Din mejladress"
          />
        </Flex>
        <Flex>
          <Field
            name="companyPhoneNo"
            component={TextInput}
            label="Telefonnummer"
            placeholder="Ditt telefonnummer"
          />
        </Flex>
      </Flex>
      <div className="form-group">
        <label className="control-label">Mer information</label>
        <Field
          component="textarea"
          name="receiptMessage"
          rows={2}
          className="form-control"
        />
      </div>
      <Panel>
        <Field
          name={pos.showCashierNameOnReceipt}
          component={CheckBoxEl}
          label="Visa kassörens namn"
        />
        <Field
          name={pos.showCustomerNameOnReceipt}
          component={CheckBoxEl}
          label="Visa kundens namn"
        />
        <Field
          name={pos.showCustomerPnoOnReceipt}
          component={CheckBoxEl}
          label="Visa kundens personnummer"
        />
        <Field
          name={pos.showProductBrandOnReceipt}
          component={CheckBoxEl}
          label="Visa varumärke för produkter"
        />
        <Field
          name={pos.showVoucherCodeOnReceipt}
          component={CheckBoxEl}
          label="Visa kod vid köp av presentkort"
        />
        <Field
          name={pos.showVoucherCodeOnRedeemReceipt}
          component={CheckBoxEl}
          label="Visa kod vid inlösen av presentkort"
        />
        <Field
          name={pos.showVoucherBalanceOnReceipt}
          component={CheckBoxEl}
          label="Visa saldo vid inlösen av presentkort"
        />
        <Field
          name={pos.showUpcomingBookingsOnReceipt}
          component={CheckBoxEl}
          label="Visa kommande bokningar"
        />
      </Panel>
    </form>
  </ModalDialog>
);

export default reduxForm({
  form: 'pos-unit-receipt-settings'
})(PosUnitReceiptSettings);
