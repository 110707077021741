import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { pos as posKeys } from '@Utils/preference-keys';
import ProductListItem from '@Components/pos/products/product-list-item';
import { getVoucherTemplateProducts } from '@State/products-selectors';
import { isCashierAdmin } from '@Utils/pos-utils';
import { colors } from '@Components/ui/styled/variables';
import { getPosOrg, getPosOrgPrefs } from '@State/pos-selectors';
import CreateVoucherTemplateModal from '@Components/admin/voucher-templates/create-voucher-template-modal';
import GiftCardNotEnabled from '../dialogs/gift-card-not-enabled';
import ProductListItemNew from './product-list-new';

const defaultVoucher = {
  type: 'Voucher',
  name: 'Presentkort'
};

const VoucherProducts = ({ disabled, onGiftCardClick, onCancel }) => {
  const voucherProducts = useSelector(getVoucherTemplateProducts);
  const posUnit = useSelector(state => state.pos.get('posUnit'));
  const posOrg = useSelector(getPosOrg);
  const posOrgPrefs = useSelector(getPosOrgPrefs);
  const isAdmin = isCashierAdmin(posUnit);

  const [showCreate, setShowCreate] = useState(false);
  const [enabled, setEnabled] = useState(posOrgPrefs[posKeys.paymentMethodGiftCard]);

  if (!enabled) {
    return (
      <GiftCardNotEnabled
        posOrg={posOrg}
        posUnit={posUnit}
        posOrgPrefs={posOrgPrefs}
        onCreateGiftCard={() => setEnabled(true)}
        onClose={onCancel}
      />
    );
  }

  return (
    <>
      <ProductListItem
        item={defaultVoucher}
        isDisabled={disabled}
        onProductClick={onGiftCardClick}
        groupColor={colors.brandPink}
      />
      {voucherProducts?.map((item) => (
        <ProductListItem
          key={item.id}
          item={item}
          isDisabled={disabled}
          onProductClick={onGiftCardClick}
          groupColor={colors.brandPink}
        />
      ))}
      {isAdmin && (
        <ProductListItemNew
          isDisabled={disabled}
          onClick={() => setShowCreate(true)}
          text="Lägg till mall"
        />
      )}
      {showCreate && (
        <CreateVoucherTemplateModal
          onClose={() => setShowCreate(false)}
        />
      )}
    </>
  );
};

export default VoucherProducts;
