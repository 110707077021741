import { defineMessages } from 'react-intl';

export default defineMessages({
  hdrCustomers: {
    id: 'customers.list.hdrCustomers',
    defaultMessage: 'Customers'
  },
  lblCount: {
    id: 'customers.list.lblCount',
    defaultMessage: 'Count: {count}'
  },
  lblSearch: {
    id: 'customers.list.lblSearch',
    defaultMessage: 'Find customers...'
  },
  txtNoCustomers: {
    id: 'customers.list.txtNoCustomers',
    defaultMessage: 'No customers found'
  }
});
