import Immutable, { fromJS } from 'immutable';
import {
  CREATE_CAMPAIGN, DELETE_CAMPAIGN,
  GET_CAMPAIGNS, UPDATE_CAMPAIGN
} from './campaign-actions';
import { CLEAR_LOCATION_STATE } from './account-actions';

export function campaignViewState(
  state = Immutable.Map({
    recipients: 0
  }),
  action = null
) {
  switch (action.type) {
    case CLEAR_LOCATION_STATE:
      return state.clear();

    case GET_CAMPAIGNS:
      return state.merge(action.state);

    case CREATE_CAMPAIGN: {
      const newCampaigns = [];
      newCampaigns.push(action.campaign);
      state.get('campaigns').forEach(campaign => {
        newCampaigns.push(campaign.toJS());
      });
      return state.merge({ campaigns: newCampaigns });
    }

    case UPDATE_CAMPAIGN: {
      const campaigns = state.get('campaigns');
      const index = campaigns.findIndex(campaign => {
        return +campaign.get('id') === +action.campaign.id;
      });
      const campaign = campaigns.get(index)?.toJS();
      return state.setIn(['campaigns', index], fromJS({
        ...campaign,
        ...action.campaign,
        type: action.campaign.campaignType ?? campaign.type,
        name: action.campaign.campaignName ?? campaign.name
      }));
    }

    case DELETE_CAMPAIGN: {
      const campaigns = state.get('campaigns');
      const index = campaigns.findIndex(campaign => {
        return campaign.get('id') === +action.campaignId;
      });
      const newCampaigns = campaigns.delete(index);
      return state.merge({ campaigns: newCampaigns });
    }

    default:
      return state;
  }
}
