import React from 'react';
import ModalDialog from '@Components/dialogs/modal-dialog';
import { CenterBlocks, Text } from '@Components/ui/styled/main';
import { DialogAlertContainer, DialogAlertIcon } from '@Components/dialogs/dialog-styles';

const DialogAlert = ({
  title = null, text, icon = null, success = false, warning = false,
  buttons = null, onClose, closeButtonText = 'Stäng'
}) => (
  <ModalDialog
    title={title}
    hideCloseButton
    closeOnClickOutside={false}
    onClose={onClose}
    buttons={buttons || (onClose && [{
      gray: true,
      name: closeButtonText,
      onClick: onClose
    }])}
  >
    <CenterBlocks tabIndex={0}>
      <DialogAlertContainer>
        {icon && <DialogAlertIcon success={success} warning={warning} className={icon} />}
        <Text fs={15}>
          {text}
        </Text>
      </DialogAlertContainer>
    </CenterBlocks>
  </ModalDialog>
);

export default DialogAlert;
