import React, { Component } from 'react';
import CompanyInfoForm from './company-info-form';

const stylesWidth33 = { width: '33%' };
const stylesWidth66 = { width: '66%' };
const stylesText = { marginBottom: 20 };

class CompanyInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showForm: false
    };
  }

  showForm = (ev) => {
    ev.preventDefault();
    this.setState({ showForm: true });
  };

  hideForm = () => {
    this.setState({ showForm: false });
  };

  onSubmit = (data) => {
    return this.props.onSubmit(data)
      .then(() => this.hideForm());
  };

  render() {
    const { showForm } = this.state;
    const { companyInfo } = this.props;

    return (
      <div>
        <h4>Företagsuppgifter</h4>
        <p className="text-muted" style={stylesText}>
          Namn och organisationsnummer på den (juridiska) person som är betalningsansvarig för avtalet med Cliento AB.
          <br />
          Uppgifterna visas även för dina kunder under "Information om personuppgiftsbehandling", som finns tillgänglig vid onlinebokning och i mejlbekräftelser.
        </p>
        {companyInfo && showForm && (
          <CompanyInfoForm initialValues={companyInfo} onSubmit={this.onSubmit} onCancel={this.hideForm} />
        )}
        {companyInfo && !showForm && (
          <table className="table table-no-border">
            <tbody>
              <tr>
                <td style={stylesWidth33}>Företagsnamn</td>
                <td style={stylesWidth66}><strong>{companyInfo.orgName}</strong></td>
              </tr>
              <tr>
                <td>Organisationsnr</td>
                <td><strong>{companyInfo.companyRegNo}</strong></td>
              </tr>
              <tr>
                <td />
                <td><a href="#" onClick={this.showForm}>Ändra uppgifter</a></td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
    );
  }
}

export default CompanyInfo;
