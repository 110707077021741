import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';

import { navigate, getGroupUrl, getResourceUrl } from '@Utils/navigate';
import { toggleGridScrollability } from '@State/view-actions';
import { getCalendarTitle } from '@State/selectors';
import ResourceSelector from '../resource-selector/resource-selector';
import { classes } from '../../ui/utils';

class MobileResourceSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.routeParams, this.props.routeParams) && this.state.open) {
      setTimeout(() => this.hideSelector(), 100);
    }
  }

  showSelector = (ev) => {
    ev.preventDefault();
    this.props.toggleScrollability(false);
    this.setState({ open: true });
  };

  hideSelector = (ev) => {
    if (ev) {
      ev.preventDefault();
    }
    this.props.toggleScrollability(true);
    this.setState({ open: false });
  };

  render() {
    const { open } = this.state;
    const { dateLabel, isiPadMode } = this.props;
    const classListHide = classes({
      'mobile-resource-selector-backdrop': true,
      show: open
    });
    const classList = classes({
      'mobile-resource-selector': true,
      show: open
    });

    // show list of resources for dropdown
    if (isiPadMode) {
      return (
        <>
          {this.resourceSelector()}
        </>
      );
    }
    return (
      <>
        <div className={classListHide} role="presentation" onClick={this.hideSelector} />
        <div className={classList}>
          <a href="#" className="mobile-resource-selector-close" onClick={this.hideSelector}>
            <i className="far fa-times" />
          </a>
          {this.resourceSelector()}
        </div>
        {this.renderCalendarTitle(dateLabel)}
      </>
    );
  }

  renderCalendarTitle(subTitle) {
    const { calendarTitle } = this.props;

    return (
      <a href="#" onClick={this.showSelector} className="mobile-resource-header">
        <i className="far fa-bars" />
        <p className="mobile-resource-title">
          {calendarTitle}
          {subTitle && <span className="mobile-resource-sub-title">{subTitle}</span>}
        </p>
      </a>
    );
  }

  resourceSelector() {
    return (
      <ResourceSelector
        onOptionClick={this.props.onClick}
        routeParams={this.props.routeParams}
        resourceTargetUrlResolver={this.resolveResourceTargetUrl}
        groupTargetUrlResolver={this.resolveGroupTargetUrl}
      />
    );
  }

  resolveResourceTargetUrl = (resourceId, resourceEntityType) => {
    return getResourceUrl(resourceId, resourceEntityType, 'week', this.props.routeParams);
  };

  resolveGroupTargetUrl = (groupId) => {
    return getGroupUrl(groupId, this.props.routeParams);
  };
}

MobileResourceSelector.propTypes = {
  tabletMode: PropTypes.bool.isRequired,
  phoneMode: PropTypes.bool.isRequired,
  resourcesById: PropTypes.object.isRequired,
  orderedGroups: PropTypes.object.isRequired,
  routeParams: PropTypes.object.isRequired,
  changeGroup: PropTypes.func.isRequired,
  changeResource: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const { mainViewState, orderedGroups, resourcesById } = state;
  const {
    viewDate, viewMode, entityType, entityId
  } = ownProps.routeParams;

  return {
    viewDate,
    viewMode,
    entityType,
    entityId,
    phoneMode: mainViewState.get('phoneMode'),
    tabletMode: mainViewState.get('tabletMode'),
    resourcesById,
    orderedGroups,
    calendarTitle: getCalendarTitle(state, ownProps)
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    changeGroup: (groupId) => {
      navigate(getGroupUrl(groupId, ownProps.routeParams));
    },
    changeResource: (resId, resourceEntityType) => {
      const { routeParams } = ownProps;
      navigate(getResourceUrl(resId, resourceEntityType, routeParams.viewMode, routeParams));
    },
    toggleScrollability: (scrollable) => {
      dispatch(toggleGridScrollability(scrollable));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileResourceSelector);
