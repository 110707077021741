import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CurrencyUtil from '@Utils/currency-util';
import { getArticleName } from '@Utils/pos-utils';
import { fetchDelivery, updateDeliveryRow, setDeliveryFinished, deleteDelivery, deleteDeliveryRow } from '@State/delivery-actions';
import { getAllProducts, getStockedProductGroups } from '@State/products-selectors';
import { getDefaultDateLabel } from '@Components/reports/reports-helpers';
import { Button } from '@Components/ui/styled/main';
import Loader from '@Components/ui/loader';
import { ReportTitle, ReportTitleButtons } from '@Components/pos/style';
import FinishDelivery from '@Components/inventory/delivery/finish-delivery';
import { getSectionUrl, navigate } from '@Utils/navigate';
import PopoverDeleteButton from '@Components/ui/popover-delete-button';
import { toggleProductsDelivery } from '@State/admin-view-reducer';
import NoContent from '@Components/ui/no-content';

const DeliveryItems = ({ routeParams, deliveryId }) => {
  const dispatch = useDispatch();
  const delivery = useSelector(state => state.deliveries.get(deliveryId));
  const items = useSelector(state => state.deliveryItems);
  const productGroups = useSelector(getStockedProductGroups);
  const products = useSelector(getAllProducts);
  const { label, createdTs, finishedTs, createdBy } = delivery || {};

  const [loading, setLoading] = useState(true);
  const [showFinish, setShowFinish] = useState(false);

  useEffect(() => {
    if (deliveryId) {
      loadDelivery();
    } else {
      setLoading(false);
    }
  }, [deliveryId]);

  const loadDelivery = () => {
    setLoading(true);
    dispatch(fetchDelivery(deliveryId))
      .finally(() => setLoading(false));
  };

  const onToggleAdd = () => {
    dispatch(toggleProductsDelivery(true));
  };

  const onPrint = (ev) => {
    ev.preventDefault();
    ev.target.blur();
    window.print();
  };

  const onSetPrice = (ev, item) => {
    const price = parseFloat(ev.target.value);

    if (!isNaN(price) && price !== item.price) {
      dispatch(updateDeliveryRow({ ...item, price }));
    }
  };

  const onSetQuantity = (ev, item) => {
    const quantity = parseInt(ev.target.value);

    if (!isNaN(quantity) && quantity !== item.deliveredQuantity) {
      dispatch(updateDeliveryRow({ ...item, deliveredQuantity: quantity, remainingQuantity: quantity }));
    }
  };

  const onFinish = (ev) => {
    ev.preventDefault();
    ev.target.blur();
    setShowFinish(true);
  };

  const onSetFinished = () => {
    return dispatch(setDeliveryFinished(deliveryId));
  };

  const onDelete = () => {
    return dispatch(deleteDelivery(deliveryId))
      .then(() => navigate(getSectionUrl(routeParams, 'admin/delivery')));
  };

  const onDeleteItem = (item) => {
    return dispatch(deleteDeliveryRow(item));
  };

  if (loading) {
    return <Loader />;
  }

  if (!delivery) {
    return (
      <NoContent icon="fa fa-truck">
        Det finns inga tidigare inleveranser.<br />
        Klicka på "Ny inleverans" för att skapa en inleverans.
      </NoContent>
    );
  }

  return (
    <>
      {showFinish && (
        <FinishDelivery
          delivery={delivery}
          itemCount={items.size}
          onClose={() => setShowFinish(false)}
          onSubmit={() => onSetFinished()}
        />
      )}
      <ReportTitle>
        <h3 className="mt0 mb0">{label || getDefaultDateLabel('Inleverans')}</h3>
        {finishedTs ? (
          <ReportTitleButtons className="hidden-print">
            <Button small gray onClick={onPrint}>Skriv ut</Button>
          </ReportTitleButtons>
        ) : (
          <ReportTitleButtons className="hidden-print">
            <Button small marginRight gray onClick={onToggleAdd}>Lägg till artiklar</Button>
            <Button small marginRight gray onClick={onPrint}>Skriv ut</Button>
            <Button small marginRight primary onClick={onFinish}>Klarmarkera</Button>
            <PopoverDeleteButton onDelete={onDelete} />
          </ReportTitleButtons>
        )}
      </ReportTitle>
      <p className="mb4">
        Skapad {moment(createdTs).format('LL')} av {createdBy}<br />
        {finishedTs && `Klar ${moment(finishedTs).format('LL')}`}
      </p>
      {items.isEmpty() && (
        <div className="alert alert-info">
          Det finns inga artiklar i denna inleverans. Klicka på "Lägg till artiklar" för att lägga till artiklar.
        </div>
      )}
      {productGroups.map(group => {
        const showGroup = group.get('articleIds').some(id => items.get(id));
        const groupId = `group_${deliveryId}_${group.get('id')}`;
        return showGroup && (
          <table key={groupId} className="table table-condensed table-product-form">
            <thead>
              <tr>
                <th>{group.get('name')}</th>
                <th className="col-xs-2 text-right">Pris / st (ex. moms)</th>
                <th className="col-xs-2 text-right">Levererat antal</th>
                {!finishedTs && <th />}
              </tr>
            </thead>
            <tbody>
              {group.get('articleIds').map(articleId => {
                const item = items.get(articleId);
                const product = products.get(articleId);
                const itemId = `article_${articleId}`;
                return item && (
                  <tr key={itemId}>
                    <td>{getArticleName(product)}</td>
                    <td className="col-xs-2 text-right" key={`${itemId}_p_${item.price}`}>
                      {finishedTs ? (
                        CurrencyUtil.accountCurrency(item.price)
                      ) : (
                        <input
                          type="text"
                          name={`price${itemId}`}
                          className="form-control"
                          defaultValue={item.price}
                          onBlur={ev => onSetPrice(ev, item)}
                        />
                      )}
                    </td>
                    <td className="col-xs-2 text-right" key={`${itemId}_q_${item.deliveredQuantity}`}>
                      {finishedTs ? (
                        item.deliveredQuantity
                      ) : (
                        <input
                          type="text"
                          name={`quantity${itemId}`}
                          className="form-control"
                          defaultValue={item.deliveredQuantity}
                          onBlur={ev => onSetQuantity(ev, item)}
                        />
                      )}
                    </td>
                    {!finishedTs && (
                      <td className="col-xs-2 text-right">
                        <PopoverDeleteButton gray onDelete={() => onDeleteItem(item)} />
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        );
      })}
    </>
  );
};

export default DeliveryItems;
