import React from 'react';
import styled from 'styled-components';
import isNull from 'lodash/isNull';
import { colors, fsw } from '@Components/ui/styled/variables';
import LabelTooltip from './label-tooltip';

const FormGroupWrapper = styled.div`
  margin-bottom: 15px;
  :last-child{
    margin-bottom: 0px;
  }
`;

const FormGroupLabel = styled.div`
  color: ${p => p.color || colors.textMuted};
  font-weight: ${fsw.medium};
  font-size: 12px;
  margin-bottom: 2px;
`;

const FormGroup = ({
  wrapperStyle = null, labelColor = null, labelText, text, tooltip
}) => {
  const textValue = isNull(text) ? '-' : text;
  return (
    <FormGroupWrapper style={wrapperStyle}>
      {labelText && (
        <FormGroupLabel color={labelColor}>
          {labelText}
          {tooltip && <LabelTooltip text={tooltip} />}
        </FormGroupLabel>
      )}
      {textValue}
    </FormGroupWrapper>
  );
};

export default FormGroup;
