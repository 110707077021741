const TIMEOUT = 60000;

let lastTime = Date.now();
const callbacks = [];

setInterval(() => {
  const currentTime = Date.now();
  const sleepTime = currentTime - lastTime;
  if (sleepTime > (TIMEOUT)) {
    console.log(`Wake from sleep detected, sleepTime: ${sleepTime} - calling callback`);
    callbacks.forEach(callback => callback());
  }
  lastTime = currentTime;
}, 10000);

export function addWakeCallback(callback) {
  callbacks.push(callback);
}

export function removeWakeCallback(callback) {
  const index = callbacks.indexOf(callback);
  callbacks.splice(index, 1);
}
