import React from 'react';
import ModalDialog from '@Components/dialogs/modal-dialog';
import JSONEditorForm from './jsoneditor-form';

const JsonEditorModal = ({ title, json, onSave, onClose }) => {
  return (
    <ModalDialog
      focusTrapPaused
      contentSize="full"
      title="Redigera JSON"
      closeButtonText="Stäng"
      closeOnClickOutside={false}
      onClose={onClose}
    >
      <JSONEditorForm title={title} json={json} onSubmit={onSave} />
    </ModalDialog>
  );
};

export default JsonEditorModal;
