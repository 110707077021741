import { defineMessages } from 'react-intl';

export default defineMessages({
  text: {
    id: 'calendar.scheduleEditBanner.text',
    defaultMessage: 'Schedule mode - Block or open the schedule by marking time below'
  },
  compactText: {
    id: 'calendar.scheduleEditBanner.compactText',
    defaultMessage: 'Schedule mode - Block or open the schedule by marking time below'
  },
  doneBtnLabel: {
    id: 'calendar.scheduleEditBanner.doneBtnLabel',
    defaultMessage: 'Done'
  }
});
