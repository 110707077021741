import moment from 'moment';
import React from 'react';
import { Field, reduxForm } from 'redux-form';

import ModalDialog from '@Components/dialogs/modal-dialog';
import FormButtonsFooter from '@Components/ui/form-buttons-footer';
import DatePickerField from '@Components/ui/date-picker/date-picker-field';
import AlertWithIcon from '@Components/ui/alert-with-icon';

const PosUnitSkvReportModal = ({ onClose, handleSubmit, submitting }) => {
  return (
    <ModalDialog
      title="Journalminne"
      contentSize="small"
      hideCloseButton
      closeOnClickOutside={false}
      onClose={onClose}
    >
      <form autoComplete="off" onSubmit={handleSubmit}>
        <AlertWithIcon>
          Ladda ner journalminne enligt SKVFS 2021:16.
        </AlertWithIcon>
        <div className="row">
          <div className="col-xs-6">
            <Field
              name="start"
              label="Från och med"
              component={DatePickerField}
            />
          </div>
          <div className="col-xs-6">
            <Field
              name="end"
              label="Till och med"
              component={DatePickerField}
            />
          </div>
        </div>
        <FormButtonsFooter
          alwaysEnabled
          onClose={onClose}
          submitting={submitting}
          handleSubmit={handleSubmit}
          submitText="Ladda ner fil"
        />
      </form>
    </ModalDialog>
  );
};

export default reduxForm({
  form: 'pos-unit-skv-report-modal',
  initialValues: {
    start: moment().subtract(1, 'month'),
    end: moment()
  }
})(PosUnitSkvReportModal);
