import React from 'react';
import PropTypes from 'prop-types';
import { Column, Label } from '@Components/admin/json-prefs/admin-config-style';
import { Text, Flex } from '@Components/ui/styled/main';

const AdminLocationsConfigurations = ({
  id, organisationId, stableId, orgCtxName, ctxName
}) => (
  <Flex>
    <Column>
      <Column>
        <Label>Location ID</Label>
        <Text fs={15}>{id}</Text>
      </Column>
      <Column>
        <Label>Organisation ID</Label>
        <Text fs={15}>{organisationId}</Text>
      </Column>
      <Column>
        <Label>Stable ID</Label>
        <Text fs={15}>{stableId}</Text>
      </Column>
      <Column>
        <Label>Org/Loc</Label>
        <Text fs={15}>{orgCtxName} / {ctxName}</Text>
      </Column>
    </Column>
  </Flex>
);

AdminLocationsConfigurations.propTypes = {
  id: PropTypes.number,
  organisationId: PropTypes.number,
  stableId: PropTypes.string,
  orgCtxName: PropTypes.string,
  ctxName: PropTypes.string
};

export default AdminLocationsConfigurations;
