import React from 'react';
import { Field } from 'redux-form';
import SelectField from '@Components/inputs/select-field';
import { getUserRoleDescription } from '@Utils/users';

const getUserRoleOption = (roleName) => {
  const { title, description } = getUserRoleDescription(roleName);
  return (
    <option value={roleName}>
      {title} - {description}
    </option>
  );
};

const StaffRoleFormRow = ({ onChange, disabled }) => (
  <Field name="userRole" disabled={disabled} component={SelectField} onChange={onChange} label="Roll">
    <option value="" style={{ display: 'none' }}>Välj roll</option>
    {getUserRoleOption('Administrator')}
    {getUserRoleOption('FrontOfficeUser')}
    {getUserRoleOption('FrontOfficeUserWithReports')}
    {getUserRoleOption('UnprivilegedUser')}
    {getUserRoleOption('UnprivilegedUserWithReports')}
  </Field>
);

export default StaffRoleFormRow;
