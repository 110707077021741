import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getResourcePreferences, replaceResourcePreferences } from '@State/preferences-actions';
import { updateResource, updateResourceAttribs } from '@State/resource-actions';
import PrefsAttribsJsonEditor from '@Components/resources/services/prefs-attribs-json-editor';
import Button from '@Components/ui/button';

import NoContent from '@Components/ui/no-content';
import ResourceSubNav from './resource-subnav';
import ResourceSettings from './settings/resource-settings';
import ResourceServices from './services/resource-services';
import IcalModal from './ical-modal';

class ResourceContent extends Component {
  static propTypes = {
    routeParams: PropTypes.object.isRequired,
    resource: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      showIcalModal: false
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.routeParams.entityId !== this.props.routeParams.entityId) {
      this.props.getResourcePreferences(this.props.routeParams.entityId);
    }
  }

  componentDidMount() {
    this.props.getResourcePreferences(this.props.routeParams.entityId);
  }

  toggleShowIcalModal = (showIcalModal) => {
    this.setState({ showIcalModal });
  };

  render() {
    const { resource, routeParams } = this.props;
    const { showIcalModal } = this.state;

    return (
      <div className="columns-content">
        <div className="columns-content-container">
          <div className="columns-content-body">
            {resource && (
              <div className="tabs-container">
                <ResourceSubNav routeParams={routeParams} />
                <div className="button-container">
                  <Button small gray onClick={() => this.toggleShowIcalModal(true)}>
                    Synkronisera med kalender
                  </Button>
                </div>
              </div>
            )}
            {this.renderSubsection()}
            {showIcalModal && (
              <IcalModal
                resourceId={this.props.routeParams.entityId}
                onClose={() => this.toggleShowIcalModal(false)}
              />
            )}
          </div>
        </div>
      </div>
    );
  }

  renderSubsection() {
    const {
      resourcePreferences, routeParams, resource, updateResourceAttribs, saveResourcePreferences, orderedGroups
    } = this.props;
    const { tab } = routeParams;

    if (!resource) {
      return (
        <NoContent icon="fa fa-calendar-alt">
          {orderedGroups.isEmpty() ? (
            <>
              Det finns inga resurser eller grupper.<br />
              Klicka på "Lägg till grupp" för att skapa en grupp.
            </>
          ) : (
            <>
              Det finns inga resurser.<br />
              Klicka på "Lägg till resurs" för att skapa en resurs.
            </>
          )}
        </NoContent>
      );
    }

    switch (tab) {
      case 'settings':
        return <ResourceSettings routeParams={routeParams} />;
      case 'services':
        return <ResourceServices routeParams={routeParams} />;
      case 'prefs':
        return (
          <PrefsAttribsJsonEditor
            attributes={resource.attributes}
            prefs={resourcePreferences && resourcePreferences.toJS && resourcePreferences.toJS() || {}}
            onUpdateAttr={updateResourceAttribs}
            onUpdatePrefs={saveResourcePreferences}
            routeParams={routeParams}
          />
        );
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const { resourcesById, orderedGroups, preferencesViewState } = state;
  return {
    orderedGroups,
    resource: resourcesById.get(ownProps.routeParams.entityId),
    resourcePreferences: preferencesViewState.get('resourcePreferences')
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  getResourcePreferences: (resourceId) => {
    dispatch(getResourcePreferences(resourceId));
  },
  saveResourcePreferences: (data) => {
    return dispatch(replaceResourcePreferences(ownProps.routeParams.entityId, data));
  },
  updateResource: (data) => {
    return dispatch(updateResource(ownProps.routeParams.entityId, data));
  },
  updateResourceAttribs: (data) => {
    return dispatch(updateResourceAttribs(ownProps.routeParams.entityId, data));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ResourceContent);
