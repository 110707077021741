import React from 'react';
import PropTypes from 'prop-types';
import CalcDisplay from '@Components/ui/calc-display';

const QuantityCalcDisplay = ({ quantity, onChange, ...props }) => {
  const increaseQuantity = (ev) => {
    ev.stopPropagation();
    onChange(quantity + 1);
  };

  const decreaseQuantity = (ev) => {
    ev.stopPropagation();
    if (quantity > 1) {
      onChange(quantity - 1);
    }
  };

  const calcButtons = [
    {
      onClick: decreaseQuantity,
      icon: 'fa fa-minus',
      disabled: quantity <= 1
    },
    {
      onClick: increaseQuantity,
      icon: 'fa fa-plus'
    }
  ];

  return (
    <CalcDisplay
      label="Antal"
      buttons={calcButtons}
      {...props}
    >
      {quantity}
    </CalcDisplay>
  );
};

QuantityCalcDisplay.propTypes = {
  quantity: PropTypes.number,
  onChange: PropTypes.func
};

export default QuantityCalcDisplay;
