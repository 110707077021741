import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import Button from '@Components/ui/button';
import Header, { Toolbar } from '../nav/header';
import CampaignModal from './form/campaign-modal';

const CampaignHeader = () => {
  const routeParams = useParams();
  const [showModal, setShowModal] = useState(false);

  const onNewCampaign = useCallback(() => {
    setShowModal(true);
  }, []);

  const onHideModal = useCallback(() => {
    setShowModal(false);
  }, []);

  return (
    <Header
      routeParams={routeParams}
      className="admin-header campaign-header"
    >
      <Toolbar>
        <Button primary small onClick={onNewCampaign}>
          Nytt utskick
        </Button>
        {showModal && (
          <CampaignModal isNew onClose={onHideModal} routeParams={routeParams} />
        )}
      </Toolbar>
    </Header>
  );
};

export default CampaignHeader;
