import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import moment from 'moment';
import useValueChanged from '@Hooks/useValueChanged';
import { getCampaignFilterTitle, getCampaignPayload, getDateFilterType } from '@Components/campaign/campaign-helpers';
import ModalSelector from '@Components/reports/data-export/data-export-modal-selector';
import { txt } from '@Utils/i18n-util';
import msg from '@Components/reports/data-export/data-export.msg';
import DatePickerCalendarInput from '@Components/ui/date-picker/date-picker-calendar-input';
import HookFormInput from '@Components/inputs/hook-form-input';
import PanelRadio from '@Components/inputs/panel-radio';
import Row from '@Components/ui/styled/row';
import { fetchCampaignEstimateForSearch } from '@State/campaign-actions';
import CampaignRecipientsModal from '@Components/campaign/campaign-recipients-modal';
import Button from '@Components/ui/button';

const dateFilterTypeOptions = [
  { value: 'CustomerCreatedTime', selector: true, label: 'Nya kunder', description: 'Kunder som tillkommit under vald period', audienceTypes: ['NewCustomers'] },
  { value: 'StartTime', selector: true, label: 'Datum för bokning', description: 'Kunder som har en bokning under vald period', audienceTypes: ['Booked'] },
  { value: 'BookingCreatedTime', selector: true, label: 'Bokning skapad', description: 'Kunder som har gjort en bokning under vald period', audienceTypes: ['Booked'] },
  { value: 'ReceiptCreatedTime', selector: true, label: 'Toppkunder', description: 'Kunder som spenderat över angett belopp under valt period', audienceTypes: ['TopSpenders'] }
];

const resourceFilterTypeOptions = [
  { value: 'All', label: 'Alla kunder', audienceTypes: null },
  { value: 'Owned', selector: true, label: 'Kunder som tillhör resurs', description: 'Kunder som är kopplade till resursen på kundkortet', audienceTypes: null },
  { value: 'Booked', selector: true, label: 'Kunder bokade hos resurs', description: 'Kunder som varit inbokade hos resurs under vald period', audienceTypes: ['Booked', 'NewCustomers', 'TopSpenders'] }
];

const serviceFilterTypeOptions = [
  { value: 'All', label: 'Alla kunder' },
  { value: 'Booked', selector: true, label: 'Kunder som bokat tjänst', description: 'Kunder som varit inbokade på en eller flera tjänster' }
];

const CampaignFilter = ({ setBlockModal, initialCount }) => {
  const dispatch = useDispatch();
  const resources = useSelector(state => state.resourcesById.valueSeq().toJS());
  const resourceGroups = useSelector(state => state.orderedGroups.toJS());
  const services = useSelector(state => state.servicesById.valueSeq().toJS());
  const serviceGroups = useSelector(state => state.orderedServiceGroups.toJS());
  const [recipientCount, setRecipientCount] = useState(initialCount);
  const [showRecipients, setShowRecipients] = useState(false);
  const { watch, setValue, register, unregister, getValues } = useFormContext();

  const [
    id, resourceIds, serviceIds, fromDate, toDate, campaignType, audienceType,
    dateFilterType, resourceFilterType, serviceFilterType, cost
  ] = watch([
    'id', 'resourceIds', 'serviceIds', 'fromDate', 'toDate', 'campaignType', 'audienceType',
    'dateFilterType', 'resourceFilterType', 'serviceFilterType', 'cost'
  ]);

  const getSearchParams = () => {
    const { searchParams } = getCampaignPayload({
      resourceIds,
      serviceIds,
      fromDate,
      toDate,
      audienceType,
      dateFilterType,
      resourceFilterType,
      serviceFilterType,
      cost
    });
    return searchParams;
  };

  const refreshRecipients = async () => {
    const res = await dispatch(fetchCampaignEstimateForSearch({
      type: campaignType,
      searchParams: getSearchParams()
    }));
    setRecipientCount(res?.recipientCount);
  };

  useEffect(() => {
    if (id) {
      refreshRecipients();
    }
  }, [id]);

  useEffect(() => {
    return () => {
      const { cost } = getValues();

      if (!cost) unregister('cost');
    };
  }, []);

  useValueChanged(dateFilterType, refreshRecipients);
  useValueChanged(resourceFilterType, refreshRecipients);
  useValueChanged(serviceFilterType, refreshRecipients);
  useValueChanged(fromDate, refreshRecipients);
  useValueChanged(toDate, refreshRecipients);
  useValueChanged(resourceIds, refreshRecipients);
  useValueChanged(serviceIds, refreshRecipients);

  const handleStartDateChange = (date) => {
    setValue('fromDate', moment(date));
    if (toDate.isBefore(date)) {
      setValue('toDate', moment(date));
    }
  };

  const handleEndDateChange = (date) => {
    setValue('toDate', moment(date));
  };

  const handleResourceChange = (values) => {
    setValue('resourceIds', values);
  };

  const handleServiceChange = (values) => {
    setValue('serviceIds', values);
  };

  const handleCostChange = () => {
    refreshRecipients();
  };

  return (
    <>
      {!isNaN(recipientCount) && (
        <div className="pull-right">
          <Button tiny gray disabled={!recipientCount} onClick={() => setShowRecipients(true)}>
            Visa mottagare ({recipientCount} st)
          </Button>
        </div>
      )}
      <h4>{getCampaignFilterTitle(campaignType, audienceType)}</h4>
      {audienceType !== 'All' && (
        <>
          {dateFilterTypeOptions.filter(o => o.audienceTypes.includes(audienceType)).map(item => (
            <PanelRadio
              key={item.value}
              input={{ ...register('dateFilterType') }}
              {...item}
            >
              {item.selector && (
                <Row>
                  <div className="mr2">
                    <DatePickerCalendarInput
                      date={fromDate}
                      onChange={handleStartDateChange}
                    />
                  </div>
                  <div>
                    <DatePickerCalendarInput
                      date={toDate}
                      minDate={fromDate.toDate()}
                      onChange={handleEndDateChange}
                    />
                  </div>
                </Row>
              )}
              {audienceType === 'TopSpenders' && (
                <HookFormInput
                  id="cost"
                  type="number"
                  className="mt2"
                  label="Spenderat mer än"
                  unitLabel="kr"
                  width={120}
                  onBlur={handleCostChange}
                  registerOptions={{ required: true }}
                />
              )}
            </PanelRadio>
          ))}
        </>
      )}
      {audienceType !== 'All' && (
        <h4 className="mt4">Urval per resurs</h4>
      )}
      {resourceFilterTypeOptions.filter(o => !o.audienceTypes || o.audienceTypes.includes(audienceType)).map(item => (
        <PanelRadio
          key={item.value}
          input={{ ...register('resourceFilterType') }}
          {...item}
        >
          {item.selector && (
            <ModalSelector
              showLabel={false}
              title="Välj resurser"
              onModal={setBlockModal}
              items={resources}
              values={resourceIds}
              groups={resourceGroups}
              groupKey="resourceIds"
              onChange={handleResourceChange}
              getItemTitle={(resource) => resource?.name ?? 'Borttagen'}
              placeholder={txt(msg.selectResources)}
            />
          )}
        </PanelRadio>
      ))}
      {audienceType !== 'All' && (
        <>
          <h4 className="mt4">Urval per tjänst</h4>
          {serviceFilterTypeOptions.map(item => (
            <PanelRadio
              key={item.value}
              input={{ ...register('serviceFilterType') }}
              {...item}
            >
              {item.selector && (
                <ModalSelector
                  showLabel={false}
                  title="Välj tjänster"
                  items={services}
                  values={serviceIds}
                  onModal={setBlockModal}
                  groups={serviceGroups}
                  groupKey="serviceIds"
                  onChange={handleServiceChange}
                  getItemTitle={(service) => service?.name ?? 'Borttagen'}
                  placeholder={txt(msg.selectServices)}
                />
              )}
            </PanelRadio>
          ))}
        </>
      )}
      {showRecipients && (
        <CampaignRecipientsModal
          type={campaignType}
          searchParams={getSearchParams()}
          onClose={() => setShowRecipients(false)}
        />
      )}
    </>
  );
};

export default CampaignFilter;
