import React from 'react';
import { reportingFormat } from '@Utils/format';
import { getPaymentMethodName } from '@Utils/pos-utils';

const ReportPaymentSummary = ({ paymentsSummary }) => {
  let totalPurchasesAmount = 0;
  let totalRefundsAmount = 0;
  return (
    <table className="table table-condensed table-report">
      <thead>
        <tr>
          <th>Betalsätt</th>
          <th className="col-xs-3 text-right">Köp</th>
          <th className="col-xs-3 text-right">Återköp</th>
        </tr>
      </thead>
      <tbody>
        {paymentsSummary && Object.keys(paymentsSummary).map((key) => {
          const { paymentCount, paymentMethodName, purchasesAmount, refundsAmount } = paymentsSummary[key];
          totalPurchasesAmount += parseInt(purchasesAmount || 0);
          totalRefundsAmount += parseInt(refundsAmount || 0);
          return (
            <tr key={key}>
              <td>
                {getPaymentMethodName(paymentMethodName || key)} (
                {paymentCount}
                )
              </td>
              <td className="col-xs-3 text-right">{reportingFormat(purchasesAmount)}</td>
              <td className="col-xs-3 text-right">{reportingFormat(refundsAmount)}</td>
            </tr>
          );
        })}
      </tbody>
      <tfoot>
        <tr>
          <th>Summa</th>
          <th className="col-xs-3 text-right">{reportingFormat(totalPurchasesAmount)}</th>
          <th className="col-xs-3 text-right">{reportingFormat(totalRefundsAmount)}</th>
        </tr>
      </tfoot>
    </table>
  );
};

export default ReportPaymentSummary;
