import { Map } from 'immutable';
import {
  STOCK_TAKES_FETCHED, STOCK_TAKE_FETCHED, STOCK_TAKE_CREATED,
  STOCK_TAKE_DELETED, STOCK_TAKE_UPDATED, STOCK_TAKE_FINISHED,
  STOCK_TAKE_ITEMS_TOGGLE, STOCK_TAKE_ITEM_QUANTITY
} from './stock-taking-actions';

export function stockTakes(state = Map(), action = null) {
  switch (action.type) {
    case STOCK_TAKES_FETCHED:
      return state.clear().withMutations(map => {
        for (const item of action.items) {
          map.set(item.id, item);
        }
      });

    case STOCK_TAKE_CREATED:
      return state.set(action.stockTake.id, action.stockTake);

    case STOCK_TAKE_UPDATED:
      return state.set(action.stockTake.id, action.stockTake);

    case STOCK_TAKE_DELETED:
      return state.delete(action.stockTakeId);

    case STOCK_TAKE_FETCHED: {
      const { id, items } = action.stockTake;
      return state.set(id, { ...state.get(id), itemCount: items.length });
    }

    case STOCK_TAKE_FINISHED: {
      const { stockTakeId, finishedTs } = action;
      return state.set(stockTakeId, { ...state.get(stockTakeId), finishedTs });
    }

    default:
      return state;
  }
}

export function stockTakeItems(state = Map(), action = null) {
  switch (action.type) {
    case STOCK_TAKE_FETCHED:
      return state.clear().withMutations(map => {
        for (const item of action.stockTake.items) {
          map.set(item.itemId, item);
        }
      });

    case STOCK_TAKE_ITEMS_TOGGLE: {
      const { ids, include } = action;
      return state.withMutations(map => {
        for (const id of ids) {
          if (include) {
            map.set(id, { itemId: id });
          } else {
            map.delete(id);
          }
        }
      });
    }

    case STOCK_TAKE_ITEM_QUANTITY: {
      const { itemId, quantity } = action;
      return state.set(itemId, { ...state.get(itemId), countedUnits: quantity });
    }

    default:
      return state;
  }
}
