import React from 'react';
import PropTypes from 'prop-types';
import { DialogButtonsHorizontal, DialogButton } from '@Components/dialogs/dialog-styles';
import ColorPicker from '../inputs/color-picker';

export default class EditGroupForm extends React.Component {
  static propTypes = {
    onClosePopover: PropTypes.func.isRequired,
    onEditGroup: PropTypes.func.isRequired
  };

  state = {
    name: this.props.name,
    color: this.props.color,
    checkboxes: this.props.checkboxes,
    progress: false
  };

  componentDidMount() {
    this.input.focus();
  }

  handleSave = ev => {
    ev.preventDefault();
    ev.stopPropagation();
    this.setState({ progress: true });
    const { name, color, checkboxes } = this.state;
    const checkboxValues = {};
    checkboxes?.forEach(({ name, checked }) => {
      checkboxValues[name] = !!checked;
    });
    this.props.onEditGroup({ name, color, ...checkboxValues })
      .catch(() => this.setState({ progress: false }));
  };

  colorChange = color => this.setState({ color });

  checkboxChange = ev => {
    const { name, checked } = ev.target;
    this.setState(({ checkboxes }) => ({
      checkboxes: checkboxes.map(checkbox => ({
        ...checkbox,
        checked: checkbox.name === name ? checked : checkbox.checked
      }))
    }));
  };

  render() {
    const { name, checkboxes, progress } = this.state;
    const { onClosePopover, colorPickerState, handleColorPickerOpen, handleColorPickerClose, color } = this.props;
    const disabled = !name || progress;

    return (
      <div
        className="Popover-dialog-sm"
        onClick={ev => ev.stopPropagation()}
      >
        <form autoComplete="off" onSubmit={this.handleSave}>
          <div className="form-group">
            <label className="control-label">Namn</label>
            <input
              type="text"
              className="form-control"
              placeholder="Ange namn på gruppen"
              ref={(ref) => { this.input = ref; }}
              onChange={ev => this.setState({ name: ev.target.value })}
              value={name}
            />
          </div>
          {checkboxes?.map(({ name, label, checked = false }) => (
            <div key={name} className="form-group">
              <div className="checkbox popover-checkbox">
                <label>
                  <input type="checkbox" name={name} checked={checked} onChange={this.checkboxChange} />
                  {label}
                </label>
              </div>
            </div>
          ))}
          {color !== undefined && (
            <ColorPicker
              input={{ value: this.state.color, onChange: this.colorChange }}
              colorPickerState={colorPickerState}
              handleOpenPopover={handleColorPickerOpen}
              handleClosePopover={handleColorPickerClose}
            />
          )}

          <DialogButtonsHorizontal>
            <DialogButton small gray onClick={onClosePopover}>Avbryt</DialogButton>
            <DialogButton small primary type="submit" disabled={disabled}>Spara</DialogButton>
          </DialogButtonsHorizontal>
        </form>
      </div>
    );
  }
}
