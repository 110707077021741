import React, { useEffect, useState } from 'react';
import useIsMounted from '@Hooks/useIsMounted';

const AutoSaveTextInput = ({
  name, type = 'text', label, initialValue, onSave, width = null,
  placeholder = '', helpText = '', disabled = false, required = false
}) => {
  const isMounted = useIsMounted();
  const [value, setValue] = useState(initialValue || '');
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => { setValue(initialValue || ''); }, [initialValue]);

  const handleChange = (ev) => {
    setValue(ev.target.value);
    setError(required && !ev.target.value);
  };

  const handleState = (method) => {
    if (isMounted()) {
      method();
    }
  };

  const handleBlur = async () => {
    if (required && !value) {
      setValue(initialValue);
      setError(!initialValue);
    } else if (value !== initialValue) {
      setSaving(true);
      try {
        await onSave({ [name]: value });
        handleState(() => setSaved(true));
        setTimeout(() => {
          handleState(() => setSaved(false));
        }, 1500);
      } catch (error) {
        handleState(() => setError(error));
      } finally {
        handleState(() => setSaving(false));
      }
    }
  };

  return (
    <div className={error ? 'form-group has-error' : 'form-group'}>
      {label && <label className="control-label" htmlFor={name}>{label}</label>}
      <div className="form-control-container" style={{ width }}>
        {(saving || saved) && (
          <div className="form-control-icon">
            {saving && <i className="fa fa-fw fa-spinner fa-spin" />}
            {saved && <i className="fa fa-fw fa-check text-success" />}
          </div>
        )}
        <input
          id={name}
          value={value}
          type={type || 'text'}
          className="form-control"
          placeholder={placeholder}
          disabled={disabled}
          autoComplete="off"
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
      {error && <span className="help-block">{error}</span>}
      {helpText && <span className="form-control-description">{helpText}</span>}
    </div>
  );
};

export default AutoSaveTextInput;
