import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import moment from 'moment';

import {
  getAvailableReports,
  getDateTypeDescription,
  getDataExportFilter,
  ReportingDate,
  getDataExportList,
  sortDataExportGroups
} from '@Components/reports/reports-helpers';
import DateRangePicker from '@Components/reports/date-range-picker';
import { DATE_PICKER_FORMAT } from '@Utils/time-constants';
import { getSectionUrl } from '@Utils/navigate';
import { getRouteParams } from '@State/selectors';
import ModalSelector from '@Components/reports/data-export/data-export-modal-selector';
import { txt } from '@Utils/i18n-util';
import { downloadDataExportExcel, onDataReportValuesChange } from '@State/report-actions';
import DataExportPreview from '@Components/reports/data-export/data-export-preview';
import Button from '@Components/ui/button';
import ConfirmCsvDownload from './confirm-csv-download';

import msg from './data-export.msg';

function convertFieldToObj(name) {
  return {
    name,
    id: name,
    header: name
  };
}

const DataExport = (props) => {
  const { subsection, ...routeParams } = useSelector(state => getRouteParams(state, props));
  const availableReports = useSelector(getAvailableReports);
  const reportDataFields = useSelector(state => state.reportsData.get('reportDataFields'));
  const {
    locationIds,
    resourceIds,
    serviceIds,
    fields,
    customFields,
    dateFilter,
    start,
    end
  } = useSelector(getDataExportFilter);
  const {
    customFieldsList,
    locationsList,
    resourceGroupsList,
    resourcesList,
    serviceGroupsList,
    servicesList
  } = useSelector(getDataExportList);

  const dispatch = useDispatch();
  const currentReport = useMemo(() => availableReports.find(report => report.id === subsection), [availableReports, subsection]);
  const reportId = useMemo(() => currentReport.id, [currentReport, availableReports]);

  const [loading, setLoading] = useState(false);
  const [preview, setPreview] = useState(false);
  const [fileType, setFileType] = useState('Excel');
  const [errorMessage, setErrorMessage] = useState('');
  const [excelError, setExcelError] = useState(false);

  const onDownloadCsv = (ev) => {
    onDownloadReport(ev, 'CSV');
  };

  const getNavigateUrl = useCallback((reportId, updateQuery) => {
    const baseUrl = getSectionUrl(routeParams, 'reports', reportId);
    const currentQuery = queryString.parse(props.location.search);
    const query = queryString.stringify({ ...currentQuery, ...updateQuery });

    return `${baseUrl}?${query}`;
  }, [reportId, props.location]);

  const handleSelectChange = useCallback((ev) => {
    dispatch(onDataReportValuesChange({ dateFilter: ev.target.value }));
  }, []);

  const handleDatesChange = useCallback(({ startDate, endDate }) => {
    dispatch(onDataReportValuesChange({
      start: startDate.format(DATE_PICKER_FORMAT),
      end: endDate.format(DATE_PICKER_FORMAT)
    }));
  }, [getNavigateUrl, reportId]);

  const dataFieldsList = useMemo(() => reportDataFields.map(convertFieldToObj), [reportDataFields]);

  const handleValuesChange = useCallback((key, values) => {
    dispatch(onDataReportValuesChange({ [key]: values }));
  }, []);

  const onPreviewReport = useCallback(() => {
    if (!fields?.length) {
      setErrorMessage('No fields selected');
      setTimeout(() => setErrorMessage(''), 3000);
      return;
    }
    setPreview(true);
  }, [fields]);

  const onDownloadReport = useCallback((ev, overrideFileType) => {
    if (!fields?.length) {
      setErrorMessage('No fields selected');
      setTimeout(() => setErrorMessage(''), 3000);
      return;
    }
    setLoading(true);
    dispatch(downloadDataExportExcel(overrideFileType || fileType))
      .then(() => setTimeout(() => {
        setLoading(false);
        setExcelError(false);
      }, 5000))
      .catch(() => {
        setExcelError(true);
        setLoading(false);
      });
  }, [fields, fileType]);

  return (
    <>
      {preview && <DataExportPreview onClose={() => setPreview(false)} />}
      <div className="form-group">
        <label className="control-label">{txt(msg.dateInterval)}</label>
        <div>
          <DateRangePicker
            startDate={moment(start)}
            endDate={moment(end)}
            onDatesChange={handleDatesChange}
          />
        </div>
      </div>
      <div>
        <div className="form-group" style={{ maxWidth: '273px' }}>
          <label className="control-label">{txt(msg.reportingDate)}</label>
          <div className="select-container">
            <select className="form-control" onChange={handleSelectChange} value={dateFilter}>
              {[ReportingDate.Created, ReportingDate.Start].map(type => (
                <option
                  key={type}
                  value={type}
                >
                  {getDateTypeDescription(type)}
                </option>
              ))}
            </select>
          </div>
        </div>
        {locationsList.length > 1 && (
          <ModalSelector
            title={txt(msg.locations)}
            items={locationsList}
            values={locationIds}
            onChange={(values) => handleValuesChange('locationIds', values)}
            getItemTitle={(location) => location?.name ?? 'noname'}
            placeholder={txt(msg.selectLocations)}
          />
        )}
        <ModalSelector
          title={txt(msg.resources)}
          items={resourcesList}
          values={resourceIds}
          groups={resourceGroupsList}
          sortGroups={sortDataExportGroups}
          groupKey="entityIds"
          onChange={(values) => handleValuesChange('resourceIds', values)}
          getItemTitle={(resource) => resource?.name ?? 'noname'}
          placeholder={txt(msg.selectResources)}
        />
        <ModalSelector
          title={txt(msg.services)}
          items={servicesList}
          values={serviceIds}
          groups={serviceGroupsList}
          sortGroups={sortDataExportGroups}
          groupKey="entityIds"
          onChange={(values) => handleValuesChange('serviceIds', values)}
          getItemTitle={(service) => service?.name ?? 'noname'}
          placeholder={txt(msg.selectServices)}
        />
        <ModalSelector
          title={txt(msg.dataToExport)}
          items={dataFieldsList ?? []}
          values={fields}
          onChange={(values) => handleValuesChange('fields', values)}
          getItemTitle={({ name }) => msg[name] ? txt(msg[name]) : name ?? 'noname'}
          placeholder={txt(msg.dataToExportPlaceholder)}
          error={errorMessage}
        />
        <ModalSelector
          title={txt(msg.customFields)}
          items={customFieldsList ?? []}
          values={customFields}
          onChange={(values) => handleValuesChange('customFields', values)}
          getItemTitle={(dataField) => dataField?.field ?? 'noname'}
          placeholder={txt(msg.customFieldsPlaceholder)}
        />
        <div className="form-group">
          <label className="control-label">{txt(msg.fileFormat)}</label><br />
          <div className="radio-inline">
            <label>
              <input type="radio" checked={fileType === 'Excel'} onChange={() => setFileType('Excel')} />
              Excel
            </label>
          </div> &nbsp;
          <div className="radio-inline">
            <label>
              <input type="radio" checked={fileType === 'CSV'} onChange={() => setFileType('CSV')} />
              CSV
            </label>
          </div>
        </div>
        <div className="data-report-actions-row">
          <Button small gray onClick={onPreviewReport}>{txt(msg.preview)}</Button>
          <Button small primary onClick={onDownloadReport} loading={loading}>{txt(msg.download)}</Button>
        </div>
        {excelError && (
          <ConfirmCsvDownload
            loading={loading}
            onDownloadCsv={onDownloadCsv}
            onClose={() => setExcelError(false)}
          />
        )}
      </div>
    </>
  );
};

export default DataExport;
