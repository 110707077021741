import moment from 'moment';
import React, { useState, useEffect } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import sv from 'date-fns/locale/sv';

registerLocale('sv', sv);

const styleHeader = {
  margin: 10,
  display: 'flex',
  justifyContent: 'center'
};

// month view = 0
// months view = 1
const renderMonthHeader = ({
  date, decreaseMonth, increaseMonth, handleClickHeader
}) => (
  <>
    <span className="react-datepicker__navigation react-datepicker__navigation--previous" onClick={decreaseMonth} />
    <div onClick={handleClickHeader}>
      <span className="react-datepicker__current-month">{moment(date).format('MMMM')}</span>
      {'  '}
      <span className="react-datepicker__current-year">{moment(date).format('YYYY')}</span>
    </div>
    <span className="react-datepicker__navigation react-datepicker__navigation--next" onClick={increaseMonth} />
  </>
);

const renderMonthsHeader = ({
  date, decreaseYear, increaseYear, handleClickHeader
}) => (
  <>
    <span className="react-datepicker__navigation react-datepicker__navigation--previous" onClick={decreaseYear} />
    <div onClick={handleClickHeader} className="react-datepicker__current-year bold">
      {moment(date).format('YYYY')}
    </div>
    <span className="react-datepicker__navigation react-datepicker__navigation--next" onClick={increaseYear} />
  </>
);

const DatePickerCalendar = ({ onChangeDate, openToDate, ...props }) => {
  const [openTo, setOpenTo] = useState(openToDate);
  const [showCalendar, setShowCalendar] = useState(0);

  useEffect(() => {
    setOpenTo(openToDate);
    setShowCalendar(0);
  }, [openToDate]);

  const handleClickHeader = () => {
    if (showCalendar < 1) {
      setShowCalendar(showCalendar => ++showCalendar);
    }
  };

  const handleSelectDate = (date) => {
    if (showCalendar === 0) {
      onChangeDate(date);
    } else {
      setOpenTo(date);
      setShowCalendar(showCalendar => --showCalendar);
    }
  };

  const renderHeader = (props) => {
    switch (showCalendar) {
      case 0:
        return renderMonthHeader(props);
      case 1:
        return renderMonthsHeader(props);
    }
  };

  return (
    <DatePicker
      selected={props.selected}
      style={props.style}
      highlightDates={props.highlightDates}
      openToDate={openTo}
      renderCustomHeader={(datePickerData) => (
        <div style={styleHeader}>
          {renderHeader({ ...datePickerData, handleClickHeader })}
        </div>
      )}
      dateFormat="MM"
      showMonthYearPicker={showCalendar === 1}
      showWeekNumbers={showCalendar === 0}
      inline
      previousMonthButtonLabel=""
      nextMonthButtonLabel=""
      fixedHeight
      locale={moment.locale()}
      onChange={handleSelectDate}
    />
  );
};

export default DatePickerCalendar;
