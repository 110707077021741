import moment from 'moment';
import React, { Component } from 'react';
import { getSectionUrl, navigate } from '@Utils/navigate';
import ColumnListItem from '@Components/ui/column/column-list-item';
import { getDefaultDateLabel } from '@Components/reports/reports-helpers';

class DeliveryList extends Component {
  onItemClick = item => {
    navigate(getSectionUrl(this.props.routeParams, 'admin/delivery', item.id));
  };

  render() {
    const { routeParams } = this.props;
    const currentId = parseInt(routeParams.id);

    return (
      <>
        <div className="columns-header">
          <h1>Inleverans</h1>
        </div>
        <div className="columns-list">
          {this.props.deliveries.valueSeq()
            .sortBy(delivery => delivery.id).reverse()
            .map(delivery => {
              const { id, label, createdTs, finishedTs } = delivery;

              return (
                <div key={id}>
                  <ColumnListItem
                    onClick={() => this.onItemClick(delivery)}
                    selected={id === currentId}
                    header={label || getDefaultDateLabel('Inleverans', createdTs)}
                  >
                    {finishedTs ? (
                      <>
                        <i className="far fa-circle-check text-success" />
                        {moment(finishedTs).format('LL')}
                      </>
                    ) : 'Pågående'}
                  </ColumnListItem>
                </div>
              );
            })}
        </div>
      </>
    );
  }
}

export default DeliveryList;
