import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { getUserRoleDescription } from '@Utils/users';
import ColumnListItem from '@Components/ui/column/column-list-item';

const StaffConfigList = ({
  usersList, currentId, onItemClick
}) => (
  <>
    <br />
    {usersList.valueSeq()
      .map(user => {
        const {
          userRole, organisationRole, id, inviteId, name, loginType, username
        } = user.toJS();
        const { title = '' } = getUserRoleDescription(userRole, organisationRole) || {};
        const onClickHandler = () => onItemClick(id || inviteId);
        return (
          <div key={id}>
            <ColumnListItem
              onClick={onClickHandler}
              selected={id === currentId}
              header={name || username}
              icon="fa-solid fa-person-simple"
              subHeader={title}
            >
              {name && loginType !== 'None' && username}
            </ColumnListItem>
          </div>
        );
      })}
  </>
);

StaffConfigList.propTypes = {
  currentId: PropTypes.number,
  usersList: PropTypes.object,
  onItemClick: PropTypes.func
};

export default memo(StaffConfigList);
