import { defineMessages } from 'react-intl';

export default defineMessages({
  lblNotes: {
    id: 'customers.notes.lblNotes',
    defaultMessage: 'Notes'
  },
  lblAddNote: {
    id: 'customers.notes.lblAddNote',
    defaultMessage: 'Add note'
  }
});
