import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popover from '@Components/ui/popover';
import { DialogConfirmText, DialogButtonsHorizontal, DialogButton } from '@Components/dialogs/dialog-styles';

const ConfirmPopover = ({
  text, onClose, onConfirm, confirmText, children, ...props
}) => {
  const [progress, setProgress] = useState(false);

  const handleConfirm = async () => {
    setProgress(true);
    try {
      await onConfirm();
    } catch {
      setProgress(false);
    }
  };

  return (
    <Popover
      preferPlace="above"
      className="Popover-big-radius"
      onOuterAction={onClose}
      {...props}
      body={(
        <div className="Popover-dialog">
          <DialogConfirmText>
            {text}
          </DialogConfirmText>
          <DialogButtonsHorizontal>
            <DialogButton small gray onClick={onClose}>Avbryt</DialogButton>
            <DialogButton small primary onClick={handleConfirm} loading={progress}>{confirmText}</DialogButton>
          </DialogButtonsHorizontal>
        </div>
      )}
    >
      {children}
    </Popover>
  );
};

ConfirmPopover.propTypes = {
  onClose: PropTypes.func
};

export default ConfirmPopover;
