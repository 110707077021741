import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { getSectionUrl, navigate } from '@Utils/navigate';
import {
  fetchUsers, fetchUserPosRoles, fetchUserPosRole, updateUser, fetchApiKeys
} from '@State/users-actions';
import { fetchPosOrgs } from '@State/pos-config-actions';
import { getCurrentUser } from '@Components/admin/staff-config/staff-config-selectors';
import { isUserAdministrator, isUserOwner } from '@Utils/users';
import { getCurrentLocation, getSysAdmin } from '@State/selectors';

import Loader from '@Components/ui/loader';
import StaffConfigForm from '@Components/admin/staff-config/staff-config-form';
import StaffConfigHeader from '@Components/admin/staff-config/staff-config-header';
import StaffConfigList from '@Components/admin/staff-config/staff-config-list';

const StaffConfig = (props) => {
  const dispatch = useDispatch();
  const routeParams = props?.match?.params;
  const currentId = routeParams?.id;

  const fetchData = async () => {
    await dispatch(fetchUsers());
    await dispatch(fetchApiKeys());
    await dispatch(fetchPosOrgs());
    await dispatch(fetchUserPosRoles());
    if (currentId) {
      await dispatch(fetchUserPosRole(+currentId));
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (currentId) {
      dispatch(fetchUserPosRole(+currentId));
    }
  }, [routeParams?.id]);

  const [loading, setLoading] = useState(true);
  const userState = useSelector(state => getCurrentUser(state, props));
  const locationUser = useSelector(state => getCurrentLocation(state, { routeParams }));
  const usersById = useSelector(state => state.usersById);
  const user = usersById.get(+currentId);

  const [currentUser, setCurrentUser] = useState(user);

  const isOwner = isUserOwner(user);
  const isAdministrator = isUserAdministrator(user);
  const isSysAdmin = useSelector(getSysAdmin);
  const loggedInUser = useSelector(state => state.authState.get('username'));
  const isLoggedInUser = user && user.get('username') === loggedInUser;
  const canChangeOwner = isSysAdmin || isUserOwner(locationUser);

  useEffect(() => {
    if (user) {
      setCurrentUser(user);
    }
  }, [user, userState]);

  const onItemClick = useCallback(
    id => navigate(getSectionUrl(routeParams, 'admin/users', id)),
    [routeParams]
  );

  const handleUpdateUser = useCallback((user) => dispatch(updateUser(user)), []);

  if (loading && usersById.isEmpty()) {
    return <Loader />;
  }

  const usersList = usersById.sort((a, b) => {
    const firstName = a.get('name') || a.get('username');
    const secondName = b.get('name') || b.get('username');
    if (firstName?.localeCompare) {
      return firstName.localeCompare(secondName, 'sv');
    }
    return false;
  });

  if (!usersById.isEmpty() && !currentId) {
    const id = usersList.first().get('id');
    const url = getSectionUrl(routeParams, 'admin/users', id);
    return (
      <Redirect to={url} />
    );
  }

  if (!currentUser) {
    return <Loader />;
  }
  const { name, loginType, username } = currentUser.toJS();
  const userEmail = loginType !== 'None' ? username : '';

  return (
    <div className="columns-container">
      <div className="columns-sidebar">
        <div className="columns-header">
          <h1>Personal</h1>
        </div>
        <div className="columns-list">
          <StaffConfigList
            onItemClick={onItemClick}
            currentId={parseInt(currentId)}
            usersList={usersList}
          />
        </div>
      </div>
      <div className="columns-content">
        <div className="columns-content-container width-small">
          <div className="columns-content-body with-padding">
            <StaffConfigHeader
              routeParams={routeParams}
              dispatch={dispatch}
              id={+currentId}
              name={name}
              userEmail={userEmail}
              isLoggedInUser={isLoggedInUser}
              canChangeOwner={canChangeOwner}
              isOwner={isOwner}
            />
            {currentUser.size > 0 && (
              <StaffConfigForm
                user={currentUser.toJS()}
                dispatch={dispatch}
                handleUpdateUser={handleUpdateUser}
                isLoggedInUser={isLoggedInUser}
                isAdministrator={isAdministrator}
                isOwner={isOwner}
                isSysAdmin={isSysAdmin}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaffConfig;
