import React from 'react';
import moment from 'moment';
import { classes } from '@Components/ui/utils';
import Text from '@Components/ui/styled/text-element';
import DatePickerCalendarInput from '@Components/ui/date-picker/date-picker-calendar-input';
import TimePickerInput from '@Components/inputs/time-picker-input';

const DateTimePicker = ({
  minDate, maxDate, date, onChangeDate,
  onTimeInputChange, loading, labelName, isHasError, time
}) => {
  const onChangeDay = (date) => onChangeDate(date);
  const onTimeChange = e => onTimeInputChange(e.target.value);

  const classList = classes({
    row: true,
    'time-picker-input-wrap': true,
    'time-picker-input-wrap-row': true,
    'has-error': isHasError
  });

  return (
    <div className={classList}>
      {labelName && (
      <div className="col-sm-12">
        <Text bold fs="13">{labelName}</Text>
      </div>
      )}
      <div className="col-sm-right col-sm-7">
        <DatePickerCalendarInput
          minDate={minDate}
          maxDate={maxDate}
          disabled={loading}
          iconClass="far fa-clock"
          date={moment(date)}
          onChange={onChangeDay}
        />
      </div>
      <div className="col-sm-left col-sm-5">
        <TimePickerInput
          time={time}
          onChange={onTimeChange}
        />
      </div>
    </div>
  );
};

export default DateTimePicker;
