import React from 'react';
import { useDispatch } from 'react-redux';
import { ecom } from '@Utils/preference-keys';
import { mergePosUnitPrefs } from '@State/pos-config-actions';
import ModalDialog from '@Components/dialogs/modal-dialog';
import Panel from '@Components/ui/styled/panel';
import PosUnitConfigToggle from './pos-unit-config-toggle';
import PosUnitStripeSettings from './pos-unit-stripe-settings';
import PosUnitSwishSettings from './pos-unit-swish-settings';
import PosUnitKlarnaSettings from './pos-unit-klarna-settings';

const PosUnitEcomSettings = ({ posUnit, onClose }) => {
  const dispatch = useDispatch();

  const handleSave = (values) => {
    return dispatch(mergePosUnitPrefs(posUnit.get('id'), values));
  };

  return (
    <ModalDialog
      contentSize="medium"
      title="Inställningar för onlinebetalning"
      onClose={onClose}
      hideCloseButton
      buttons={[{
        name: 'Stäng',
        primary: true,
        type: 'submit',
        onClick: onClose
      }]}
    >
      <Panel>
        <PosUnitConfigToggle
          posUnit={posUnit}
          preference={ecom.stripeEnabled}
          label="Kort"
          description="Kortbetalning via Stripe"
        >
          <PosUnitStripeSettings posUnit={posUnit} />
        </PosUnitConfigToggle>
      </Panel>
      <Panel>
        <PosUnitConfigToggle
          posUnit={posUnit}
          preference={ecom.swishEnabled}
          label="Swish"
          description="Betala direkt med Swish"
        >
          <PosUnitSwishSettings posUnit={posUnit} onSave={handleSave} />
        </PosUnitConfigToggle>
      </Panel>
      <Panel>
        <PosUnitConfigToggle
          posUnit={posUnit}
          preference={ecom.klarnaEnabled}
          label="Klarna"
          description="Faktura eller delbetalning med Klarna"
        >
          <PosUnitKlarnaSettings posUnit={posUnit} onSave={handleSave} />
        </PosUnitConfigToggle>
      </Panel>
    </ModalDialog>
  );
};

export default PosUnitEcomSettings;
