import { List, Map, fromJS } from 'immutable';
import { CLEAR_LOCATION_STATE } from '@State/account-actions';
import {
  POS_ORGS_FETCHED, POS_CONTACTS_FETCHED, POS_ORG_UNITS_FETCHED, POS_ORG_DELETED, POS_TERMINAL_BUSY,
  POS_TERMINAL_REFDATA_FETCHED, POS_TERMINALS_BY_UNIT_FETCHED, POS_TERMINAL_UPDATED, POS_TERMINAL_READY,
  POS_TERMINAL_ASSOCIATED, POS_TERMINAL_DISASSOCIATED, POS_PRINTERS_FETCHED, POS_CREATE_ORG,
  POS_ORG_UPDATED, POS_UNIT_CREATED, POS_UNIT_UPDATED, POS_UNIT_DELETED, POS_CONTACT_UPDATED,
  POS_PRINTER_PROGRESS, POS_PRINTER_DONE, POS_PRINTER_QUEUED, POS_PRINTER_STATUS, POS_ALL_UNITS_FETCHED
} from './pos-config-actions';

function posItemUpdated(state, action, prop) {
  const index = state.findIndex(item => item.get('id') === action[prop].id);
  return index !== -1
    ? state.set(index, state.get(index).merge(action[prop]))
    : state;
}

function posItemDeleted(state, action) {
  const index = state.findIndex(item => item.get('id') === action.id);
  return index !== -1
    ? state.delete(index)
    : state;
}

export function posOrgs(state = List(), action = null) {
  switch (action.type) {
    case POS_ORGS_FETCHED:
      return fromJS(action.posOrgs);

    case POS_CREATE_ORG:
      return state.push(fromJS(action.org));

    case POS_ORG_UPDATED:
      return posItemUpdated(state, action, 'org');

    case POS_ORG_DELETED:
      return posItemDeleted(state, action);

    case CLEAR_LOCATION_STATE:
      return state.clear();

    default:
      return state;
  }
}

export function posContacts(state = Map(), action = null) {
  switch (action.type) {
    case POS_CONTACTS_FETCHED:
      return fromJS(action.contacts);

    case POS_CONTACT_UPDATED:
      return state.set(action.contactType, fromJS(action.contact));

    case CLEAR_LOCATION_STATE:
      return state.clear();

    default:
      return state;
  }
}

export function posUnits(state = List(), action = null) {
  switch (action.type) {
    case POS_ORG_UNITS_FETCHED:
      return fromJS(action.units);

    case POS_UNIT_CREATED:
      return state.push(fromJS(action.unit));

    case POS_UNIT_UPDATED:
      return posItemUpdated(state, action, 'unit');

    case POS_UNIT_DELETED:
      return posItemDeleted(state, action);

    case CLEAR_LOCATION_STATE:
      return state.clear();

    default:
      return state;
  }
}

function getTerminalStatus(terminal, connectionStatus, deviceStatus) {
  return {
    ...terminal.get('terminalStatus').toObject(),
    connectionStatus,
    deviceStatus
  };
}

export function posTerminals(state = List(), action = null) {
  switch (action.type) {
    case POS_TERMINALS_BY_UNIT_FETCHED:
      return fromJS(action.terminals);

    case POS_TERMINAL_UPDATED: {
      const index = state.findIndex(t => t.get('id') === action.terminal.id);
      if (index !== -1) {
        return state.set(index, state.get(index).merge(action.terminal));
      }
      return state;
    }

    case POS_TERMINAL_BUSY: {
      const index = state.findIndex(t => t.get('id') === action.terminalId);
      if (index !== -1) {
        const terminal = state.get(index);
        const terminalStatus = getTerminalStatus(terminal, 'InProgress', 'Busy');
        return state.set(index, terminal.merge({ terminalStatus }));
      }
      return state;
    }

    case POS_TERMINAL_READY: {
      const index = state.findIndex(t => t.get('id') === action.terminalId);
      if (index !== -1) {
        const terminal = state.get(index);
        const terminalStatus = getTerminalStatus(terminal, 'Online', 'Ready');
        return state.set(index, terminal.merge({ terminalStatus }));
      }
      return state;
    }

    case CLEAR_LOCATION_STATE:
      return state.clear();

    default:
      return state;
  }
}

export function allTerminals(state = List(), action = null) {
  switch (action.type) {
    case POS_TERMINAL_REFDATA_FETCHED:
      return fromJS(action.terminals);

    case POS_TERMINAL_UPDATED:
      return posItemUpdated(state, action, 'terminal');

    case CLEAR_LOCATION_STATE:
      return state.clear();

    default:
      return state;
  }
}

export function allOnpremPosUnits(state = List(), action = null) {
  switch (action.type) {
    case POS_TERMINAL_REFDATA_FETCHED:
      return fromJS(action.vunits);

    case POS_TERMINAL_ASSOCIATED:
    case POS_TERMINAL_DISASSOCIATED: {
      const { vunitId, terminalId, merchId } = action;
      const index = state.findIndex(t => t.get('id') === parseInt(vunitId));
      if (index !== -1) {
        return state.set(index, state.get(index).merge({
          terminalId: terminalId ? parseInt(terminalId) : null,
          merchId: merchId || null
        }));
      }
      return state;
    }

    case CLEAR_LOCATION_STATE:
      return state.clear();

    default:
      return state;
  }
}

export function allPosUnits(state = List(), action = null) {
  switch (action.type) {
    case POS_ALL_UNITS_FETCHED:
      return fromJS(action.vunits);

    case POS_UNIT_CREATED:
      return state.push(fromJS(action.unit));

    case POS_UNIT_UPDATED:
      return posItemUpdated(state, action, 'unit');

    case POS_UNIT_DELETED:
      return posItemDeleted(state, action);

    case CLEAR_LOCATION_STATE:
      return state.clear();

    default:
      return state;
  }
}

function setPrinterStatus(state, macAddress, status) {
  const index = state.findIndex(p => p.get('macAddress') === macAddress);
  if (index === -1) {
    return state;
  }
  const currentStatus = state.getIn([index, 'printerStatus']);
  return state.setIn([index, 'printerStatus'], fromJS({ ...currentStatus?.toJS(), ...status }));
}

export function printers(state = List(), action = null) {
  switch (action.type) {
    case POS_PRINTERS_FETCHED:
      return fromJS(action.printers);

    case POS_PRINTER_QUEUED:
      return setPrinterStatus(state, action.macAddress, { printing: true });

    case POS_PRINTER_PROGRESS:
      return setPrinterStatus(state, action.macAddress, { ...action.status, printing: true });

    case POS_PRINTER_DONE:
      return setPrinterStatus(state, action.macAddress, { ...action.status, printing: false });

    case POS_PRINTER_STATUS:
      return setPrinterStatus(state, action.macAddress, action.status);

    case CLEAR_LOCATION_STATE:
      return state.clear();

    default:
      return state;
  }
}
