import axios from 'axios';
import { web } from '@Utils/preference-keys';
import { getWebPaymentEnabled } from '@State/selectors';
import {
  axiosDefault,
  axiosErrorHandler,
  checkStatusAxios,
  prefixUrl
} from '@Utils/ajax-util';

export const SERVICES_LOADED = 'SERVICES_LOADED';
export const SERVICE_RESOURCE_MAPPINGS_LOADED = 'SERVICE_RESOURCE_MAPPINGS_LOADED';
export const SERVICE_RESOURCE_MAPPING_ADDED = 'SERVICE_RESOURCE_MAPPING_ADDED';
export const SERVICE_RESOURCE_MAPPING_DELETED = 'SERVICE_RESOURCE_MAPPING_DELETED';
export const SERVICE_RESOURCE_MAPPINGS_SET = 'SERVICE_RESOURCE_MAPPINGS_SET';
export const SERVICE_ADDON_MAPPINGS_LOADED = 'SERVICE_ADDON_MAPPINGS_LOADED';
export const SERVICE_ADDON_MAPPING_ADDED = 'SERVICE_ADDON_MAPPING_ADDED';
export const SERVICE_ADDON_MAPPING_DELETED = 'SERVICE_ADDON_MAPPING_DELETED';

export const SERVICE_GROUP_ADDED = 'SERVICE_GROUP_ADDED';
export const SERVICE_GROUP_UPDATED = 'SERVICE_GROUP_UPDATED';
export const SERVICE_GROUP_DELETED = 'SERVICE_GROUP_DELETED';
export const SERVICE_ADDED = 'SERVICE_ADDED';
export const SERVICE_UPDATED = 'SERVICE_UPDATED';
export const SERVICE_PREFS_UPDATED = 'SERVICE_PREFS_UPDATED';
export const SERVICE_ATTRIBS_UPDATED = 'SERVICE_ATTRIBS_UPDATED';
export const SERVICE_CUSTOM_FIELDS_UPDATED = 'SERVICE_CUSTOM_FIELDS_UPDATED';
export const SERVICE_MULTIVAT_UPDATED = 'SERVICE_MULTIVAT_UPDATED';
export const SERVICE_MULTIRESOURCE_UPDATED = 'SERVICE_MULTIRESOURCE_UPDATED';
export const SERVICE_DELETED = 'SERVICE_DELETED';
export const CUSTOM_VALUES_UPDATED = 'CUSTOM_VALUES_UPDATED';
export const SERVICE_MOVED = 'SERVICE_MOVED';
export const SERVICE_GROUP_MOVED = 'SERVICE_GROUP_MOVED';

export const serviceMappingValues = [
  'serviceDuration', 'afterTime', 'price', 'priceFrom',
  'webAllowBooking', 'webShowDuration', 'webShowPrice'
];

export const isServiceMappingDiff = (values, mappings, prop) => {
  return mappings.find((mapping) => {
    if (!mapping.get('customValues')) {
      return false;
    }
    return String(mapping.get(prop)) !== String(values[prop]);
  });
};

export function fetchServices() {
  return (dispatch) => {
    const url = prefixUrl('/services/');
    const config = axiosDefault();

    return axios.get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then((services) => {
        dispatch({ type: SERVICES_LOADED, services });
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function fetchAllResourceMappings() {
  return (dispatch) => {
    const url = prefixUrl('/services/resource-mappings');
    const config = axiosDefault();

    return axios.get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then((responseData) => {
        dispatch({ type: SERVICE_RESOURCE_MAPPINGS_LOADED, ...responseData });
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function fetchAllAddonMappings() {
  return (dispatch) => {
    const url = prefixUrl('/services/addon-mappings');
    const config = axiosDefault();

    return axios.get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then((responseData) => {
        dispatch({ type: SERVICE_ADDON_MAPPINGS_LOADED, ...responseData });
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function addServiceGroup(group) {
  return (dispatch) => {
    const url = prefixUrl('/servicegroup/');
    const config = axiosDefault();

    return axios.post(url, group, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then((group) => {
        dispatch({ type: SERVICE_GROUP_ADDED, group });
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function updateServiceGroup(group) {
  return (dispatch) => {
    const url = prefixUrl(`/servicegroup/${group.id}`);
    const config = axiosDefault();

    return axios.put(url, group, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then(() => {
        dispatch({ type: SERVICE_GROUP_UPDATED, group });
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function deleteServiceGroup(groupId) {
  return (dispatch) => {
    const url = prefixUrl(`/servicegroup/${groupId}`);
    const config = axiosDefault();

    return axios.delete(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => {
        dispatch({ type: SERVICE_GROUP_DELETED, groupId });
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function addService(service) {
  return (dispatch, getState) => {
    const groupId = parseInt(service.groupId);
    const url = service.addon
      ? prefixUrl('/services/addon')
      : prefixUrl(`/services/group/${groupId}`);
    const config = axiosDefault();
    const paymentEnabled = getWebPaymentEnabled(getState());
    const prefs = paymentEnabled ? {
      [web.paymentRequired]: true
    } : null;

    return axios.post(url, service, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then((response) => {
        if (paymentEnabled && !service.addon) {
          dispatch(updateServicePrefs(response.id, prefs));
        }
        const newService = { ...service, ...response, prefs };
        dispatch({
          type: SERVICE_ADDED,
          service: newService,
          groupId: response.groupId || groupId
        });
        return newService;
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function duplicateService(serviceId, name) {
  const url = prefixUrl(`/services/${serviceId}/duplicate`);

  return (dispatch, getState) => {
    const { servicesById } = getState();
    const service = servicesById.get(serviceId);
    const config = axiosDefault();

    return axios.post(url, { name }, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then((response) => {
        const { srvId, groupId } = response;
        const newService = { ...service, id: srvId, name };
        dispatch({ type: SERVICE_ADDED, service: newService, groupId });
        return newService;
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

function hasPaymentPrefsChanged(prevPrefs, service) {
  const prevPaymentRequired = prevPrefs && prevPrefs[web.paymentRequired];
  const prevAllowPayOnSite = prevPrefs && prevPrefs[web.allowPayOnSite];
  const prevAllowDiscountVoucher = prevPrefs && prevPrefs[web.allowDiscountVoucher];
  const prevAutoSelectFirstAvailableSlot = prevPrefs && prevPrefs[web.autoSelectFirstAvailableSlot];

  return service.webPaymentRequired !== prevPaymentRequired
    || service.webAllowPayOnSite !== prevAllowPayOnSite
    || service.webAllowDiscountVoucher !== prevAllowDiscountVoucher
    || service.webAutoSelectFirstAvailableSlot !== prevAutoSelectFirstAvailableSlot;
}

function afterUpdateServiceActions(service) {
  return (dispatch, getState) => {
    const state = getState();
    const paymentEnabled = getWebPaymentEnabled(state);
    const prev = state.servicesById.get(parseInt(service.id));
    const actions = [];

    const paymentPrefsChanged = paymentEnabled && hasPaymentPrefsChanged(prev.prefs, service);
    const colorwayChanged = service.colorway !== prev.prefs?.colorway;
    if (paymentPrefsChanged || colorwayChanged) {
      const prefs = {
        ...service.prefs,
        [web.paymentRequired]: service.webPaymentRequired,
        [web.allowPayOnSite]: service.webAllowPayOnSite,
        [web.allowDiscountVoucher]: service.webAllowDiscountVoucher,
        [web.autoSelectFirstAvailableSlot]: service.webAutoSelectFirstAvailableSlot,
        colorway: service.colorway || null
      };
      actions.push(dispatch(updateServicePrefs(service.id, prefs)));
    }

    if (service.multiVat) {
      actions.push(dispatch(updateServiceVatConfig(service.id, service.multiVatRows)));
    } else if (prev.multiVat) {
      actions.push(dispatch(deleteServiceVatConfig(service.id)));
    }

    return actions;
  };
}

export function updateService(service) {
  return (dispatch, getState) => {
    const url = prefixUrl(`/services/${service.id}`);
    const config = axiosDefault();
    const { servicesById } = getState();
    const oldService = servicesById.get(parseInt(service.id));

    if (service.multiVat) {
      service.price = service.multiVatRows.reduce((a, b) => a + parseInt(b.price || 0), 0);
      service.priceFrom = false;
    }

    return axios.put(url, service, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then(() => {
        const { multiResource, multiResourceRules } = oldService;
        const newService = { ...service, multiResource, multiResourceRules };
        dispatch({ type: SERVICE_UPDATED, service: newService });
        return Promise.all(
          dispatch(afterUpdateServiceActions(service))
        );
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function deleteService(serviceId) {
  return (dispatch) => {
    const url = prefixUrl(`/services/${serviceId}`);
    const config = axiosDefault();

    return axios.delete(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => {
        dispatch({ type: SERVICE_DELETED, serviceId });
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function addServiceResourceMapping(serviceId, resourceId) {
  return (dispatch, getState) => {
    const srv = getState().servicesById.get(serviceId);
    const {
      serviceDuration, afterTime, price, webAllowBooking, webShowDuration, webShowPrice, priceFrom
    } = srv;
    const mapping = {
      serviceId, customValues: false, serviceDuration, afterTime, price, webAllowBooking, webShowDuration, webShowPrice, priceFrom
    };

    const url = prefixUrl(`/services/${serviceId}/resource-mapping/${resourceId}`);
    const config = axiosDefault();

    return axios.post(url, null, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => {
        dispatch({
          type: SERVICE_RESOURCE_MAPPING_ADDED, serviceId, resourceId, mapping
        });
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function deleteServiceResourceMapping(serviceId, resourceId) {
  return (dispatch) => {
    const url = prefixUrl(`/services/${serviceId}/resource-mapping/${resourceId}`);
    const config = axiosDefault();

    return axios.delete(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => {
        dispatch({ type: SERVICE_RESOURCE_MAPPING_DELETED, serviceId, resourceId });
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function setServiceResourceMappings(serviceId, resourceIds) {
  return (dispatch, getState) => {
    const srv = getState().servicesById.get(serviceId);
    const {
      serviceDuration, afterTime, price, webAllowBooking, webShowDuration, webShowPrice, priceFrom
    } = srv;
    const mapping = {
      serviceId, customValues: false, serviceDuration, afterTime, price, webAllowBooking, webShowDuration, webShowPrice, priceFrom
    };

    const url = prefixUrl(`/services/${serviceId}/resource-mapping/`);
    const config = axiosDefault();

    return axios.put(url, { resourceIds }, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => {
        dispatch({
          type: SERVICE_RESOURCE_MAPPINGS_SET, serviceId, resourceIds, mapping
        });
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function updateCustomValues(serviceId, resourceId, customValues) {
  return (dispatch) => {
    const mapping = { serviceId, customValues: true, ...customValues };

    const url = prefixUrl(`/services/resource/${resourceId}/customvalues/${serviceId}`);
    const config = axiosDefault();

    return axios.put(url, customValues, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then(() => {
        dispatch({
          type: CUSTOM_VALUES_UPDATED, serviceId, resourceId, mapping
        });
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function deleteCustomValues(serviceId, resourceId) {
  return (dispatch, getState) => {
    const srv = getState().servicesById.get(serviceId);
    const {
      serviceDuration, afterTime, price, webAllowBooking, webShowDuration, webShowPrice, priceFrom
    } = srv;
    const mapping = {
      serviceId, customValues: false, serviceDuration, afterTime, price, webAllowBooking, webShowDuration, webShowPrice, priceFrom
    };

    const url = prefixUrl(`/services/resource/${resourceId}/customvalues/${serviceId}`);
    const config = axiosDefault();

    return axios.delete(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then(() => {
        dispatch({
          type: CUSTOM_VALUES_UPDATED, serviceId, resourceId, mapping
        });
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function addServiceAddonMapping(serviceId, addonServiceId) {
  return (dispatch) => {
    const url = prefixUrl(`/services/${serviceId}/addon-mapping/${addonServiceId}`);
    const config = axiosDefault();

    return axios.post(url, null, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => {
        dispatch({ type: SERVICE_ADDON_MAPPING_ADDED, serviceId, addonServiceId });
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function deleteServiceAddonMapping(serviceId, addonServiceId) {
  return (dispatch) => {
    const url = prefixUrl(`/services/${serviceId}/addon-mapping/${addonServiceId}`);
    const config = axiosDefault();

    return axios.delete(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => {
        dispatch({ type: SERVICE_ADDON_MAPPING_DELETED, serviceId, addonServiceId });
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function moveGroup(moveAction) {
  return (dispatch) => {
    const url = prefixUrl('/services/group/move');
    const config = axiosDefault();

    // Apply the change locally first (will revert the change if a network error occurs
    //
    dispatch({ type: SERVICE_GROUP_MOVED, moveAction });

    const { groupId, srcPos: srcIdx, destPos: dstIdx } = moveAction;
    const body = {
      groupId, srcIdx, dstIdx
    };

    return axios.put(url, body, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .catch((error) => {
        // Revert the change
        //
        const { groupId, srcPos, destPos } = moveAction;
        const m = {
          srcPos: destPos,
          destPos: srcPos,
          groupId
        };

        dispatch({ type: SERVICE_GROUP_MOVED, m });
        axiosErrorHandler(error, dispatch);
      });
  };
}

export function moveService(moveAction) {
  return (dispatch) => {
    const url = prefixUrl('/services/move');
    const config = axiosDefault();

    // Apply the change locally first (will revert the change if a network error occurs
    //
    dispatch({ type: SERVICE_MOVED, moveAction });

    const {
      srcGrpId, destGrpId: dstGrpId, srcPos: srcIdx, destPos: dstIdx, itemId
    } = moveAction;
    const body = {
      serviceId: itemId, srcGrpId, dstGrpId, srcIdx, dstIdx
    };

    return axios.put(url, body, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .catch((error) => {
        // Revert the change
        //
        const {
          srcGrpId, destGrpId, srcPos, destPos, serviceId
        } = moveAction;

        const m = {
          srcGrpId: destGrpId,
          destGrpId: srcGrpId,
          srcPos: destPos,
          destPos: srcPos,
          serviceId
        };

        dispatch({ type: SERVICE_MOVED, m });
        axiosErrorHandler(error, dispatch);
      });
  };
}

export function updateServicePrefs(serviceId, prefs) {
  return (dispatch) => {
    const url = prefixUrl(`/services/${serviceId}/preferences`);
    const config = axiosDefault();
    const srvId = parseInt(serviceId);

    return axios.put(url, prefs, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => {
        dispatch({ type: SERVICE_PREFS_UPDATED, srvId, prefs });
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function updateServiceAttribs(serviceId, attributes) {
  return (dispatch) => {
    const url = prefixUrl(`/services/${serviceId}/attribs`);
    const config = axiosDefault();
    const srvId = parseInt(serviceId);

    return axios.put(url, attributes, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => {
        dispatch({ type: SERVICE_ATTRIBS_UPDATED, srvId, attributes });
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function updateServiceCustomFields(serviceId, customFields) {
  return (dispatch) => {
    const url = prefixUrl(`/services/${serviceId}/custom-fields`);
    const config = axiosDefault();
    const srvId = parseInt(serviceId);

    return axios.put(url, customFields, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => {
        dispatch({ type: SERVICE_CUSTOM_FIELDS_UPDATED, srvId, customFields });
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function updateServiceVatConfig(serviceId, rows) {
  return (dispatch) => {
    const url = prefixUrl(`/services/${serviceId}/multi-vat`);
    const config = axiosDefault();
    const srvId = parseInt(serviceId);
    const multiVatRows = rows.filter(row => row && row.description);

    return axios.put(url, multiVatRows, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => {
        dispatch({ type: SERVICE_MULTIVAT_UPDATED, srvId, multiVatRows, multiVat: true });
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function deleteServiceVatConfig(serviceId) {
  return (dispatch) => {
    const url = prefixUrl(`/services/${serviceId}/multi-vat`);
    const config = axiosDefault();
    const srvId = parseInt(serviceId);

    return axios.delete(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => {
        dispatch({ type: SERVICE_MULTIVAT_UPDATED, srvId, multiVatRows: null, multiVat: false });
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function updateMultiResourceRules(serviceId, multiResourceRules) {
  return (dispatch) => {
    const url = prefixUrl(`/services/${serviceId}/multi-resource-rules`);
    const config = axiosDefault();
    const srvId = parseInt(serviceId);

    return axios.put(url, multiResourceRules, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => {
        dispatch({ type: SERVICE_MULTIRESOURCE_UPDATED, srvId, multiResourceRules, multiResource: true });
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function deleteMultiResourceRules(serviceId) {
  return (dispatch) => {
    const url = prefixUrl(`/services/${serviceId}/multi-resource-rules`);
    const config = axiosDefault();
    const srvId = parseInt(serviceId);

    return axios.delete(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => {
        dispatch({ type: SERVICE_MULTIRESOURCE_UPDATED, srvId, multiResourceRules: null, multiResource: false });
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}
