import styled from 'styled-components';
import { colors } from './styled/variables';

const FilterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  label {
    display: block;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    margin-top: -10px;
    color: ${colors.textMuted};
  }

  .form-group {
    margin-right: 12px;
    margin-bottom: 20px;
  }

  .form-control {
    display: inline-block;
    width: 190px;
    font-size: 13px;
    height: 30px;
    padding-top: 6px;
    color: #000;
    border-color: #ccc;
  }
`;

export default FilterContainer;
