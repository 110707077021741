import moment from 'moment';
import CurrencyUtil from '@Utils/currency-util';

function getDefaultGiftCardDescription(amount) {
  return `Presentkort (värde ${CurrencyUtil.accountCurrency(amount, 0)})`;
}

export function getGiftCardFromTemplate(voucherCode, amount, voucherTemplate) {
  const validityPeriod = moment.duration(voucherTemplate.validityPeriod);
  return {
    ...voucherTemplate,
    code: voucherCode,
    noRedeemAfterTs: moment().add(validityPeriod).startOf('day'),
    initialValue: amount
  };
}

export function getGiftCardWithDefaults(voucherCode, amount, config) {
  return {
    description: getDefaultGiftCardDescription(amount),
    code: voucherCode,
    initialValue: amount,
    noRedeemAfterTs: moment(config.expiryDate),
    ...config
  };
}

export function getGiftCardPayload(giftCard) {
  const {
    description, comment, beneficiary, accountingType, vatPct,
    code, initialValue, noRedeemAfterTs, paymentMethodName
  } = giftCard;
  return {
    description,
    comment,
    beneficiary,
    voucherCode: code,
    amount: initialValue,
    expiryDate: moment(noRedeemAfterTs).format('YYYY-MM-DD'),
    accountingType,
    paymentMethodName,
    vatPct: vatPct || 0
  };
}
