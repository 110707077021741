import React, { useState } from 'react';
import useIsMounted from '@Hooks/useIsMounted';
import ModalDialog from '@Components/dialogs/modal-dialog';
import { DialogAlertContainer, DialogAlertIcon } from '@Components/dialogs/dialog-styles';
import CenterBlocks from './styled/center-blocks';
import { Text } from './styled/main';

const ConfirmActionModal = ({
  confirmMessage, successMessage = '', confirmButtonText = 'OK', onCancel, onConfirm
}) => {
  const isMounted = useIsMounted();
  const [progress, setProgress] = useState(false);
  const [completed, setCompleted] = useState(false);

  const handleConfirm = async () => {
    setProgress(true);
    try {
      await onConfirm();
      if (isMounted()) {
        setCompleted(true);
      }
    } finally {
      if (isMounted()) {
        setProgress(false);
      }
    }
  };

  return (
    <ModalDialog
      onClose={onCancel}
      onEnter={handleConfirm}
      hideCloseButton
      buttons={[!completed && {
        name: confirmButtonText,
        primary: true,
        loading: progress,
        onClick: handleConfirm
      }, {
        name: completed ? 'Stäng' : 'Avbryt',
        gray: true,
        disabled: progress,
        onClick: onCancel
      }]}
    >
      <CenterBlocks tabIndex={0}>
        <DialogAlertContainer>
          <DialogAlertIcon success={completed} className={completed ? 'fa fa-circle-check' : 'fa fa-circle-question'} />
          <Text fs={15}>
            {completed ? successMessage : confirmMessage}
          </Text>
        </DialogAlertContainer>
      </CenterBlocks>
    </ModalDialog>
  );
};

export default ConfirmActionModal;
