import React, { Component } from 'react';
import { connect } from 'react-redux';
import StaffReportList from './stock-report-list';

class StockReportSidebar extends Component {
  render() {
    const { reportsViewState, routeParams } = this.props;
    const stockReports = reportsViewState.get('stockReports');

    return (
      <div className="columns-sidebar">
        <StaffReportList
          routeParams={routeParams}
          stockReports={stockReports}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { reportsViewState } = state;

  return {
    reportsViewState,
    routeParams: ownProps.match.params
  };
};

export default connect(mapStateToProps)(StockReportSidebar);
