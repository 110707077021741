export const maxLength = 10;
export const maxDiscount = 10000;

export const convertToFloat = amount => parseFloat(amount / 100);
export const convertToString = amount => Math.round(amount * 100).toString();

export const isValidInput = (value, maxLength, maxValue) => {
  const intValue = parseInt(value && value !== '' ? value : 0);
  return String(value).length <= maxLength && (!maxValue || intValue <= maxValue);
};

export const getChange = (cash, amount) => convertToFloat(cash) - Math.round(amount);
