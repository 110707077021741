import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { getPosEnabledUsers, getAutoLoginOperator } from '@State/pos-selectors';
import { BlockStyledPos, BlockButton, BlockGrid } from '@Components/pos/style';

class SelectPosUser extends PureComponent {
  componentDidMount() {
    const { autoLoginOperator } = this.props;

    if (autoLoginOperator) {
      this.props.onSelect(autoLoginOperator.get('id'));
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { autoLoginOperator } = this.props;

    if (!autoLoginOperator && nextProps.autoLoginOperator) {
      this.props.onSelect(nextProps.autoLoginOperator.get('id'));
    }
  }

  render() {
    const { users, autoLoginOperator } = this.props;

    if (users.isEmpty() || autoLoginOperator) {
      return null;
    }

    return (
      <div className="pos">
        <BlockStyledPos>
          <h3>Välj kassör</h3>
          <br />
          <BlockGrid columns={users.size > 6 ? 2 : 1}>
            {users.map((user, index) => {
              const handleOnSelect = () => this.props.onSelect(user.get('id'));

              return (
                <BlockButton
                  key={index}
                  onClick={handleOnSelect}
                >
                  <h5>{user.get('name') || user.get('username')}</h5>
                </BlockButton>
              );
            })}
          </BlockGrid>
        </BlockStyledPos>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    users: getPosEnabledUsers(state),
    autoLoginOperator: getAutoLoginOperator(state)
  };
};

export default connect(mapStateToProps)(SelectPosUser);
