import { defineMessages } from 'react-intl';

export default defineMessages({

  lblSendConfirmationTo: {
    id: 'calendar.bkf.send-confirmation.lblSendConfirmationTo',
    defaultMessage: 'Send booking confirmation to:'
  },
  lblUnableToSend: {
    id: 'calendar.bkf.send-confirmation.lblUnableToSend',
    defaultMessage: 'Add mobile phone or email to send a booking confirmation'
  },
  btnCancel: {
    id: 'calendar.bkf.send-confirmation.btnCancel',
    defaultMessage: 'Cancel'
  },
  btnSend: {
    id: 'calendar.bkf.send-confirmation.btnSend',
    defaultMessage: 'Send'
  },
  btnSendProgress: {
    id: 'calendar.bkf.send-confirmation.btnSendProgress',
    defaultMessage: 'Sending...'
  }
});
