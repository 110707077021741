import { defineMessages } from 'react-intl';

export default defineMessages({
  importFrom: {
    id: 'services.customFields.importFrom',
    defaultMessage: 'Importera från'
  },
  ownFields: {
    id: 'services.customFields.ownFields',
    defaultMessage: 'Egna fält'
  },
  description: {
    id: 'services.customFields.description',
    defaultMessage: 'Use "Own fields" to add fields to the booking with e.g. date or booking reference'
  },
  reorderDescription: {
    id: 'services.customFields.reorderDescription',
    defaultMessage: 'Drag and drop to reorder the list'
  },
  saving: {
    id: 'services.customFields.saving',
    defaultMessage: 'Saving...'
  },
  saved: {
    id: 'services.customFields.saved',
    defaultMessage: 'Saved!'
  },
  add: {
    id: 'services.customFields.add',
    defaultMessage: 'Add'
  },
  addField: {
    id: 'services.customFields.addField',
    defaultMessage: 'Add field'
  },
  createNewButton: {
    id: 'services.customFields.createNewButton',
    defaultMessage: 'Create new field'
  },
  addFieldText: {
    id: 'services.customFields.addFieldText',
    defaultMessage: 'Create a new field or use an existing field below'
  },
  copy: {
    id: 'services.customFields.copy',
    defaultMessage: 'Copy'
  },
  copyFrom: {
    id: 'services.customFields.copyFrom',
    defaultMessage: 'Copy from'
  },
  copyFieldsFromService: {
    id: 'services.customFields.copyFieldsFromService',
    defaultMessage: 'Copy fields from service'
  },
  copyFieldsFromText: {
    id: 'services.customFields.copyFieldsFromText',
    defaultMessage: 'Copy fields from service'
  },
  btnSubmit: {
    id: 'services.customFields.btnSubmit',
    defaultMessage: 'Submit'
  },
  type: {
    id: 'services.customFields.type',
    defaultMessage: 'Type'
  },
  label: {
    id: 'services.customFields.label',
    defaultMessage: 'Label'
  },
  helpText: {
    id: 'services.customFields.helpText',
    defaultMessage: 'Help text'
  },
  placeholder: {
    id: 'services.customFields.placeholder',
    defaultMessage: 'Placeholder'
  },
  options: {
    id: 'services.customFields.options',
    defaultMessage: 'Options'
  },
  required: {
    id: 'services.customFields.required',
    defaultMessage: 'Required'
  },
  delete: {
    id: 'services.customFields.delete',
    defaultMessage: 'Delete'
  },
  cancel: {
    id: 'services.customFields.cancel',
    defaultMessage: 'Cancel'
  },
  date: {
    id: 'services.customFields.date',
    defaultMessage: 'Date field'
  },
  text: {
    id: 'services.customFields.text',
    defaultMessage: 'Text field'
  },
  select: {
    id: 'services.customFields.select',
    defaultMessage: 'Multiple options'
  },
  checkbox: {
    id: 'services.customFields.checkbox',
    defaultMessage: 'Checkbox'
  }
});
