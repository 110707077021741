import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex: ${props => props.flex !== undefined ? props.flex : 1};
  justify-content: center;
  align-items: center;
  height: 100%;

  > div {
    position: relative;
  }
`;
