import React, { memo } from 'react';
import PropTypes from 'prop-types';
import ColumnListItem from '@Components/ui/column/column-list-item';

const PartnerConfigList = ({ partnersById, currentId, onItemClick }) => (
  <>
    {partnersById.valueSeq().map(partner => {
      const { id, name } = partner.toObject();
      return (
        <div key={id}>
          <ColumnListItem
            onClick={() => onItemClick(id)}
            selected={id === currentId}
            header={name}
            icon="fa fa-yin-yang"
          />
        </div>
      );
    })}
  </>
);

PartnerConfigList.propTypes = {
  currentId: PropTypes.number,
  partnersById: PropTypes.object,
  onItemClick: PropTypes.func
};

export default memo(PartnerConfigList);
