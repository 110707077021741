import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createCustomer } from '@State/customer-actions';
import { getCurrentLocation } from '@State/selectors';
import CustomerInformationForm from '@Components/customers/customer-detail/overview/customer-information-form';
import ModalDialog from '@Components/dialogs/modal-dialog';
import { formatPhoneNumberE164 } from '@Utils/phone-util';
import { txt } from '@Utils/i18n-util';
import msg from './customer-header.msg';

const AddCustomerModal = ({ routeParams, onClose, onCreated }) => {
  const dispatch = useDispatch();
  const currentLocation = useSelector(state => getCurrentLocation(state, { routeParams }));

  const onCreate = async (values) => {
    const customer = {
      ...values,
      locationId: currentLocation?.locationId,
      phoneNumber: formatPhoneNumberE164(values?.phoneNumber),
      otherPhoneNumber: formatPhoneNumberE164(values?.otherPhoneNumber)
    };
    const customerId = await dispatch(createCustomer(customer));
    onCreated({ customerId, ...customer });
  };

  return (
    <ModalDialog
      contentSize="medium"
      title={txt(msg.addDialogTitle)}
      onClose={onClose}
    >
      <CustomerInformationForm
        onSubmit={onCreate}
        onClose={onClose}
      />
    </ModalDialog>
  );
};

export default AddCustomerModal;
