import React, { useEffect } from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { useSelector } from 'react-redux';
import { pos } from '@Utils/preference-keys';
import { getVatRates } from '@State/selectors';
import SelectField from '@Components/inputs/select-field';
import PeriodInput from '@Components/inputs/period-input';

const selector = formValueSelector('pos-org-gift-card-form');

const PosOrgGiftCardForm = ({ initialValues, handleSubmit }) => {
  const vatRates = useSelector(getVatRates);
  const voucherType = useSelector(state => selector(state, pos.defaultVoucherType));
  const vatPct = useSelector(state => selector(state, pos.defaultSinglePurposeVoucherVatPct));
  const validity = useSelector(state => selector(state, pos.defaultVoucherValidity));
  const showVatPct = voucherType === 'SinglePurpose';

  useEffect(() => {
    if (initialValues[pos.defaultVoucherType] !== voucherType
        || initialValues[pos.defaultSinglePurposeVoucherVatPct] !== vatPct
        || initialValues[pos.defaultVoucherValidity] !== validity) {
      handleSubmit();
    }
  }, [initialValues, voucherType, vatPct, validity]);

  return (
    <form onSubmit={handleSubmit}>
      <Field name={pos.defaultVoucherType} component={SelectField} label="Presentkortstyp">
        <option value="SinglePurpose">Enfunktionsvoucher</option>
        <option value="MultiPurpose">Flerfunktionsvoucher</option>
      </Field>
      {showVatPct && (
        <Field name={pos.defaultSinglePurposeVoucherVatPct} component={SelectField} label="Momssats">
          {vatRates.map(vat => <option key={vat} value={vat}>{`${vat}%`}</option>)}
        </Field>
      )}
      <Field name={pos.defaultVoucherValidity} component={PeriodInput} label="Giltighetstid" vertical />
    </form>
  );
};

export default reduxForm({
  form: 'pos-org-gift-card-form',
  enableReinitialize: true
})(PosOrgGiftCardForm);
