import { defineMessages } from 'react-intl';

export default defineMessages({
  noLogsTitle: {
    id: 'customers.logs.noLogsTitle',
    defaultMessage: 'No logs'
  },
  noLogsText: {
    id: 'customers.logs.noLogsText',
    defaultMessage: 'There are no logs for this customer'
  }
});
