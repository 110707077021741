import { defineMessages } from 'react-intl';

export default defineMessages({
  txtExistingBookingChanged: {
    id: 'calendar.bkf.confirmation.txtExistingBookingChanged',
    defaultMessage: 'Booking changed, send booking confirmation to customer?'
  },
  txtNewBookingChanged: {
    id: 'calendar.bkf.confirmation.txtNewBookingChanged',
    defaultMessage: 'Send booking confirmation to customer?'
  },
  lblSendSms: {
    id: 'calendar.bkf.confirmation.lblSendSms',
    defaultMessage: 'Send SMS to {mobile}'
  },
  lblSendSmsNotAvailable: {
    id: 'calendar.bkf.confirmation.lblSendSmsNotAvailable',
    defaultMessage: 'SMS - Add mobile phone to enable'
  },
  lblSendEmail: {
    id: 'calendar.bkf.confirmation.lblSendEmail',
    defaultMessage: 'Send email to {email}'
  },
  lblSendEmailNotAvailable: {
    id: 'calendar.bkf.confirmation.lblSendEmailNotAvailable',
    defaultMessage: 'Email - Add email to enable'
  }
});
