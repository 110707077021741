import { Map, List, fromJS } from 'immutable';
import {
  PRODUCTS_FETCHED,
  PRODUCT_CREATED,
  PRODUCT_UPDATED,
  PRODUCT_DELETED,
  PRODUCT_MOVED,
  PRODUCT_GROUPS_FETCHED,
  PRODUCT_GROUP_CREATED,
  PRODUCT_GROUP_DELETED,
  PRODUCT_GROUP_UPDATED,
  PRODUCT_GROUP_MOVED,
  PRODUCT_SOLD
} from './products-actions';
import { doMoveGroup, doMoveItem } from './action-helpers';

export function products(state = Map(), action = null) {
  switch (action.type) {
    case PRODUCTS_FETCHED:
      return state.clear().withMutations(map => {
        for (const product of action.products.items) {
          map.set(product.id, product);
        }
      });

    case PRODUCT_CREATED:
      return state.set(action.product.id, action.product);

    case PRODUCT_UPDATED:
      return state.set(action.product.id, action.product);

    case PRODUCT_DELETED:
      return state.delete(action.productId);

    case PRODUCT_SOLD: {
      const { id, quantity } = action;
      const product = state.get(id);
      if (!product || !product.stocked) {
        return state;
      }
      const stockQuantity = Math.max(product.stockQuantity - quantity, 0);
      return state.set(id, { ...product, stockQuantity });
    }

    default:
      return state;
  }
}

export function productGroups(state = List(), action = null) {
  switch (action.type) {
    case PRODUCT_CREATED: {
      const index = state.findIndex(
        g => g.get('id') === parseInt(action.product.groupId)
      );
      const articleIds = state
        .getIn([index, 'articleIds'])
        .unshift(action.product.id);
      return state.setIn([index, 'articleIds'], articleIds);
    }

    case PRODUCT_GROUPS_FETCHED:
      return fromJS(action.groups.groups);

    case PRODUCT_GROUP_CREATED:
      return state.unshift(fromJS(action.group));

    case PRODUCT_GROUP_UPDATED: {
      const { id } = action.group;
      const index = state.findIndex(g => g.get('id') === id);
      return state.set(index, state.get(index).merge(action.group));
    }

    case PRODUCT_GROUP_DELETED: {
      const index = state.findIndex(g => g.get('id') === action.groupId);
      return state.delete(index);
    }

    case PRODUCT_DELETED: {
      const { productId } = action;

      let productIndex = -1;
      const groupIndex = state.findIndex(g => {
        productIndex = g.get('articleIds').indexOf(productId);
        return productIndex !== -1;
      });

      const productIds = state
        .getIn([groupIndex, 'articleIds'])
        .delete(productIndex);
      return state.setIn([groupIndex, 'articleIds'], productIds);
    }

    case PRODUCT_GROUP_MOVED:
      return doMoveGroup(state, action.moveAction);
    case PRODUCT_MOVED:
      return doMoveItem(state, action.moveAction, 'articleIds');

    default:
      return state;
  }
}
