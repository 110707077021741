import React, { Component } from 'react';
import ButtonDropdown from '@Components/ui/button-dropdown';
import { DropdownHeader, DropDownItem } from '@Components/ui/drop-down/dropdown';
import Button from '@Components/ui/button';
import { txt } from '@Utils/i18n-util';
import msg from './resource-selector-toolbar.msg';

export default class ResourceSelectorToolbar extends Component {
  onKeyDown = (ev) => {
    if (ev.keyCode === 27) {
      this.props.onFilterChange(null);
    }
  };

  onExpandAll = (ev) => {
    this.props.onToggleCollapse([]);
  };

  onCollapseAll = (ev) => {
    const { groups } = this.props;
    const groupIds = groups.map(g => g.get('id')).toArray();
    this.props.onToggleCollapse(groupIds);
  };

  onSetCompactView = (ev) => {
    ev.preventDefault();
    this.props.onSetViewMode('Compact');
  };

  onSetNormalView = (ev) => {
    ev.preventDefault();
    this.props.onSetViewMode('Normal');
  };

  render() {
    const { dpPosition, filter, onFilterChange, resourceListViewMode } = this.props;
    const isCompact = resourceListViewMode === 'Compact';
    const className = dpPosition === 'top'
      ? 'resource-selector-toolbar below'
      : 'resource-selector-toolbar';

    return (
      <div className={className}>
        <div className="form-control-container">
          <i className="fa fa-search" />
          <input
            type="text"
            placeholder={txt(msg.lblFilter)}
            onChange={onFilterChange}
            onKeyDown={this.onKeyDown}
            value={filter}
            ref={(ref) => { this.input = ref; }}
          />
        </div>
        <div className="resource-selector-buttons">
          <Button white small onClick={this.onCollapseAll} title={txt(msg.btnCollapse)}><i className="far fa-arrows-to-dotted-line" /></Button>
          <Button white small onClick={this.onExpandAll} title={txt(msg.btnExpand)}><i className="far fa-arrows-from-dotted-line" /></Button>
          <ButtonDropdown small icon="far fa-cog">
            <DropdownHeader>{txt(msg.lblSize)}</DropdownHeader>
            <DropDownItem onClick={this.onSetNormalView} icon="fa-grid-2" checked={!isCompact}>{txt(msg.lblNormal)}</DropDownItem>
            <DropDownItem onClick={this.onSetCompactView} icon="fa-grid" checked={isCompact}>{txt(msg.lblCompact)}</DropDownItem>
          </ButtonDropdown>
        </div>
      </div>
    );
  }
}
