import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchCampaignStatus } from '@State/campaign-actions';

const CampaignStatusUpdate = ({ campaignId }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const interval = setInterval(checkTimeout, 2000);

    return () => clearInterval(interval);
  }, [campaignId]);

  const checkTimeout = useCallback(() => {
    dispatch(fetchCampaignStatus(campaignId));
  }, [campaignId]);

  return null;
};

export default CampaignStatusUpdate;
