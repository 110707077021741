import { colors } from '@Components/ui/styled/variables';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 2px 0;
`;

const Label = styled.div`
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: ${colors.textMuted};
  vertical-align: top;
  text-align: right;
  margin-right: 16px;
  width: 110px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Content = styled.div`
  flex: 1;
`;

const LabelTextGroup = ({ label, children, className = null }) => {
  return (
    <Container className={className}>
      <Label>{label}</Label>
      <Content>{children}</Content>
    </Container>
  );
};

export default LabelTextGroup;
