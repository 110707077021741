import { defineMessages } from 'react-intl';

export default defineMessages({
  duration: {
    id: 'calendar.bkf.info.duration',
    defaultMessage: 'Duration'
  },
  unitMinutes: {
    id: 'calendar.bkf.info.unitMinutes',
    defaultMessage: 'min'
  },
  aftertime: {
    id: 'calendar.bkf.info.aftertime',
    defaultMessage: 'Pause after'
  },
  closeBtnLabel: {
    id: 'calendar.bkf.info.closeBtnLabel',
    defaultMessage: 'Close'
  },
  editBtnLabel: {
    id: 'calendar.bkf.info.editBtnLabel',
    defaultMessage: 'Change'
  },
  hideDetailsBtnLabel: {
    id: 'calendar.bkf.info.hideDetailsBtnLabel',
    defaultMessage: 'Hide deatils'
  },
  showDetailsBtnLabel: {
    id: 'calendar.bkf.info.showDetailsBtnLabel',
    defaultMessage: 'Show details'
  },
  showHistoryBtnLabel: {
    id: 'calendar.bkf.info.showHistoryBtnLabel',
    defaultMessage: 'Show history'
  }
});
