import axios from 'axios';
import {
  axiosDefault,
  axiosErrorHandler,
  checkStatusAxios,
  prefixUrl
} from '@Utils/ajax-util';
import { deleteDeliveryRow, updateDeliveryRow } from './delivery-actions';

export const PRODUCT_CREATED = 'PRODUCT_CREATED';
export const PRODUCT_UPDATED = 'PRODUCT_UPDATED';
export const PRODUCT_DELETED = 'PRODUCT_DELETED';
export const PRODUCT_MOVED = 'PRODUCT_MOVED';
export const PRODUCT_GROUP_CREATED = 'PRODUCT_GROUP_CREATED';
export const PRODUCTS_FETCHED = 'PRODUCTS_FETCHED';
export const PRODUCT_GROUPS_FETCHED = 'PRODUCT_GROUPS_FETCHED';
export const PRODUCT_GROUP_UPDATED = 'PRODUCT_GROUP_UPDATED';
export const PRODUCT_GROUP_DELETED = 'PRODUCT_GROUP_DELETED';
export const PRODUCT_GROUP_MOVED = 'PRODUCT_GROUP_MOVED';
export const PRODUCT_USE_BY_RESOURCE = 'PRODUCT_USE_BY_RESOURCE';
export const PRODUCT_SOLD = 'PRODUCT_SOLD';

export function fetchProducts() {
  return dispatch => {
    const url = prefixUrl('/inventory/items/?includeHidden=true');
    const config = axiosDefault();

    return axios
      .get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then(products => dispatch({ type: PRODUCTS_FETCHED, products }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function fetchProductGroups() {
  return dispatch => {
    const url = prefixUrl('/inventory/groups/?includeHidden=true');
    const config = axiosDefault();

    return axios
      .get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then(groups => dispatch({ type: PRODUCT_GROUPS_FETCHED, groups }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function createProduct(product, supplierProduct) {
  return dispatch => {
    const { groupId } = product;
    const { id: supplierItemId } = supplierProduct || {};
    const url = prefixUrl(`/inventory/group/${groupId}/items/`);
    const config = axiosDefault();

    return axios
      .post(url, { ...product, supplierItemId }, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then(({ id }) => {
        const EAN = product.EAN ? [product.EAN] : [];
        const newProduct = {
          ...product, id, groupId, EAN, ...mapSupplierProduct(supplierProduct)
        };
        dispatch({ type: PRODUCT_CREATED, product: newProduct });
        return newProduct;
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function updateProduct(product) {
  return dispatch => {
    const url = prefixUrl(`/inventory/items/${product.id}`);
    const config = axiosDefault();

    if (!product.id) {
      return Promise.resolve();
    }

    return axios
      .put(url, product, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then(() => dispatch({ type: PRODUCT_UPDATED, product }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function addProductBarcode(productId, barcode) {
  return (dispatch, getState) => {
    const url = prefixUrl(`/inventory/items/${productId}/EAN`);
    const config = axiosDefault();
    const { products } = getState();
    const product = products.get(productId);
    const newProduct = {
      ...product,
      EAN: [...product.EAN, barcode]
    };

    return axios
      .post(url, { EAN: barcode }, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => dispatch({ type: PRODUCT_UPDATED, product: newProduct }))
      .catch(error => {
        if (error.response?.status === 400) {
          if (error.response.data?.code === 10) {
            throw new Error('Streckkoden finns redan');
          }
        }
        throw error;
      });
  };
}

export function deleteProductBarcode(productId, barcode) {
  return (dispatch, getState) => {
    const url = prefixUrl(`/inventory/items/${productId}/EAN/${barcode}`);
    const config = axiosDefault();
    const { products } = getState();
    const product = products.get(productId);
    const newProduct = {
      ...product,
      EAN: product.EAN.filter(ean => ean !== barcode)
    };

    return axios
      .delete(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => dispatch({ type: PRODUCT_UPDATED, product: newProduct }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

function valueOrDefault(value, defaultValue) {
  return value !== null && value !== undefined ? value : defaultValue;
}

function updateProductStockInfo(product, data = {}) {
  const { stocked = true, stockQuantity, latestPriceIn, fifoAvgPrice } = data;
  return {
    type: PRODUCT_UPDATED,
    product: {
      ...product,
      stocked,
      stockQuantity: valueOrDefault(stockQuantity, product.stockQuantity),
      latestPriceIn: valueOrDefault(latestPriceIn, product.latestPriceIn),
      fifoAvgPrice: valueOrDefault(fifoAvgPrice, product.fifoAvgPrice)
    }
  };
}

export function setProductStocked(productId, stocked) {
  return (dispatch, getState) => {
    const url = prefixUrl(`/inventory/items/${productId}/stocked`);
    const config = axiosDefault();

    const { products } = getState();
    const product = products.get(productId);

    return axios
      .post(url, { stocked }, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => dispatch(updateProductStockInfo(product, { stocked })))
      .catch((error) => {
        axiosErrorHandler(error, dispatch);
        throw error;
      });
  };
}

export function adhocProductDelivery(product, deliveredQuantity, priceIn = 0) {
  return dispatch => {
    const url = prefixUrl(`/inventory/delivery/items/${product.id}/adhoc-delivery`);
    const config = axiosDefault();

    return axios
      .post(url, { deliveredQuantity, priceIn }, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then(data => dispatch(updateProductStockInfo(product, data)))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function productOutDelivery(product, quantity) {
  return dispatch => {
    const url = prefixUrl(`/inventory/delivery/items/${product.id}/out-delivery`);
    const config = axiosDefault();

    return axios
      .post(url, { quantity }, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then(data => dispatch(updateProductStockInfo(product, data)))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function fetchProductDeliveries(productId) {
  return dispatch => {
    const url = prefixUrl(`/inventory/delivery/items/${productId}`);
    const config = axiosDefault();

    return axios
      .get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function updateProductDelivery(delivery) {
  return (dispatch, getState) => {
    const { itemId } = delivery;
    const { products } = getState();
    const product = products.get(itemId);

    return dispatch(updateDeliveryRow(delivery))
      .then(data => dispatch(updateProductStockInfo(product, data)));
  };
}

export function deleteProductDelivery(delivery) {
  return (dispatch, getState) => {
    const { itemId } = delivery;
    const { products } = getState();
    const product = products.get(itemId);

    return dispatch(deleteDeliveryRow(delivery))
      .then(data => dispatch(updateProductStockInfo(product, data)));
  };
}

export function deleteProduct(productId) {
  return dispatch => {
    const url = prefixUrl(`/inventory/items/${productId}`);
    const config = axiosDefault();

    return axios
      .delete(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => dispatch({ type: PRODUCT_DELETED, productId }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function createProductGroup(group) {
  return dispatch => {
    const url = prefixUrl('/inventory/groups/');
    const config = axiosDefault();

    return axios
      .post(url, group, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then(({ id }) => {
        dispatch({ type: PRODUCT_GROUP_CREATED, group: { ...group, id, articleIds: [] } });
        return id;
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function updateProductGroup(group) {
  return dispatch => {
    const url = prefixUrl(`/inventory/groups/${group.id}`);
    const config = axiosDefault();

    return axios
      .put(url, group, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then(() => {
        dispatch({ type: PRODUCT_GROUP_UPDATED, group });
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function deleteProductGroup(groupId) {
  return dispatch => {
    const url = prefixUrl(`/inventory/groups/${groupId}`);
    const config = axiosDefault();

    return axios
      .delete(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => {
        dispatch({ type: PRODUCT_GROUP_DELETED, groupId });
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function moveGroup(moveAction) {
  return dispatch => {
    const url = prefixUrl('/inventory/groups/move');
    const config = axiosDefault();

    // Apply the change locally first (will revert the change if a network error occurs
    dispatch({ type: PRODUCT_GROUP_MOVED, moveAction });

    const { groupId, srcPos: srcIdx, destPos: dstIdx } = moveAction;
    const body = {
      groupId,
      srcIdx,
      dstIdx
    };

    return axios
      .put(url, body, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .catch(error => {
        // Revert the change
        const { groupId, srcPos, destPos } = moveAction;
        const m = {
          srcPos: destPos,
          destPos: srcPos,
          groupId
        };

        dispatch({ type: PRODUCT_GROUP_MOVED, m });
        axiosErrorHandler(error, dispatch);
      });
  };
}

export function moveProduct(moveAction) {
  return dispatch => {
    const url = prefixUrl('/inventory/items/move');
    const config = axiosDefault();

    // Apply the change locally first (will revert the change if a network error occurs
    dispatch({ type: PRODUCT_MOVED, moveAction });

    const {
      srcGrpId,
      destGrpId: dstGrpId,
      srcPos: srcIdx,
      destPos: dstIdx,
      itemId
    } = moveAction;
    const body = {
      articleId: itemId,
      srcGrpId,
      dstGrpId,
      srcIdx,
      dstIdx
    };

    return axios
      .put(url, body, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .catch(error => {
        // Revert the change
        const {
          srcGrpId, destGrpId, srcPos, destPos, articleId
        } = moveAction;

        const m = {
          srcGrpId: destGrpId,
          destGrpId: srcGrpId,
          srcPos: destPos,
          destPos: srcPos,
          articleId
        };

        dispatch({ type: PRODUCT_MOVED, m });
        axiosErrorHandler(error, dispatch);
      });
  };
}

export function lookupSupplierProduct(barcode) {
  return dispatch => {
    const url = prefixUrl(`/supplier-catalog/lookup?ean=${encodeURIComponent(barcode)}`);
    const config = axiosDefault();

    return axios
      .get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then(({ result }) => result?.length > 0 ? result[0] : null)
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

const mapSupplierProduct = (supplierProduct) => {
  if (!supplierProduct) {
    return null;
  }
  const { supplierKey, brand, series, productName } = supplierProduct;
  return {
    supplier: supplierKey,
    supplierBrand: brand,
    supplierSeries: series,
    supplierProductName: productName
  };
};

export function setProductSupplierProduct(productId, supplierProduct) {
  return (dispatch, getState) => {
    const { id } = supplierProduct;
    const url = prefixUrl(`/inventory/items/${productId}/supplier/${id}`);
    const config = axiosDefault();
    const { products } = getState();
    const product = products.get(productId);
    const newProduct = {
      ...product,
      ...mapSupplierProduct(supplierProduct)
    };

    return axios
      .post(url, null, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then(() => dispatch({ type: PRODUCT_UPDATED, product: newProduct }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}
