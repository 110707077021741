import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loginUrl } from '@Utils/config';
import { config } from '@Utils/preference-keys';
import { setSessionOrgLoc } from '@Utils/session';
import { fetchServices } from '@State/services-actions';
import { fetchLocationConfig, clearLocationState, fetchLocationOptions } from '@State/account-actions';
import { fetchGroupsAndResources } from '@State/resource-actions';
import { tryExistingAccessToken } from '@Login/actions';
import AccountTerminatedAlert from '@Components/dialogs/account-terminated-alert';
import JavascriptErrorAlert from '@Components/dialogs/javascript-error-alert';
import AuthRequired from '@Components/dialogs/AuthRequired';
import Loader from '@Components/ui/loader';
import App from './app';

class LocationConfigEnforcer extends Component {
  constructor(props) {
    super(props);

    const { org, loc } = props.match.params;
    setSessionOrgLoc(org, loc);

    this.state = {
      loaded: false,
      error: null
    };
  }

  componentDidMount() {
    // Remove static loader
    document.body.removeAttribute('style');

    // Authenticate if not
    if (!this.props.authenticated && !this.props.authenticating) {
      this.props.tryExistingAccessToken()
        .then(() => this.fetchConfig(this.props))
        .catch((error) => this.setState({ error }));
    } else {
      this.fetchConfig(this.props);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.orgLoc !== nextProps.orgLoc) {
      if (nextProps.orgLoc) {
        const { org, loc } = nextProps.match.params;
        setSessionOrgLoc(org, loc);

        this.props.clearLocationState();
        this.fetchConfig(nextProps);
      }
    }
  }

  fetchConfig = (props) => {
    const { orgLoc, isSysadmin } = props;
    if (!isSysadmin && orgLoc) {
      this.setState({ loaded: false });
      this.props.fetchConfig()
        .then(() => this.setState({ loaded: true }))
        .catch(error => this.setState({ error }));
    } else {
      this.setState({ loaded: true });
    }
  };

  render() {
    if (this.props.noAuthToken) {
      if (this.props.match.url === '/') {
        window.location.href = loginUrl();
        return null;
      }
      return <AuthRequired />;
    }

    if (this.state.error) {
      return <JavascriptErrorAlert networkError error={this.state.error} />;
    }

    if (this.props.accountStatus === 'Terminated') {
      return <AccountTerminatedAlert />;
    }

    return this.props.authenticated && (this.state.loaded || !this.props.orgLoc)
      ? <App {...this.props} />
      : <Loader solid />;
  }
}

const mapStateToProps = (state, ownProps) => {
  const isSysadmin = ownProps.match.url.startsWith('/sysadmin');
  const { org, loc } = ownProps.match.params;

  return {
    accountStatus: state.appState.get('accountStatus'),
    noAuthToken: state.authState.get('noAuthToken'),
    authenticated: state.authState.get('authenticated'),
    isSysadmin,
    orgLoc: org !== undefined && loc !== undefined ? `${org}/${loc}` : undefined,
    locationRole: state.locationConfig.get(config.locationRole),
    locale: state.locationConfig.get(config.locale)
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    tryExistingAccessToken: () => {
      return dispatch(tryExistingAccessToken());
    },
    clearLocationState: () => {
      dispatch(clearLocationState());
    },
    fetchConfig: () => {
      return Promise.all([
        dispatch(fetchLocationConfig()),
        dispatch(fetchLocationOptions()),
        dispatch(fetchGroupsAndResources()),
        dispatch(fetchServices())
      ]);
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationConfigEnforcer);
