import React, { useEffect, useMemo, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import FormButtonsFooter from '@Components/ui/form-buttons-footer';
import HookFormInput from '@Components/inputs/hook-form-input';
import { useDispatch } from 'react-redux';
import { getNewAccountPricing } from '@State/sysadmin-actions';

const AccountActivateForm = ({ onClose, onSubmit, loading }) => {
  const dispatch = useDispatch();
  const methods = useForm();
  const [pricePlans, setPricePlans] = useState([]);

  const planOptions = useMemo(() => pricePlans.map(({ planKey, planName }) => ({
    value: planKey,
    title: planName
  })), [pricePlans]);

  useEffect(() => {
    dispatch(getNewAccountPricing())
      .then((res) => setPricePlans(res.active))
      .catch(console.error);
  }, []);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <HookFormInput
          id="pricePlan"
          label="Prisplan"
          type="select"
          options={planOptions}
          registerOptions={{ required: true }}
        />
        <FormButtonsFooter
          onClose={onClose}
          handleSubmit={methods.handleSubmit}
          submitting={loading}
        />
      </form>
    </FormProvider>
  );
};

export default AccountActivateForm;
