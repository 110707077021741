import PropTypes from 'prop-types';
import React, { Component } from 'react';
import zxcvbn from 'zxcvbn';

export default class PasswordStrength extends Component {
  constructor(props) {
    super(props);
    this.state = this.makeInitialState(props);
  }

  makeInitialState(props) {
    return {
      passwordStrength: 0
    };
  }

  render() {
    const { newPassword } = this.props;

    return (
      <div style={newPassword.length == 0 ? { display: 'none' } : {}}>
        <p className="text-muted small">
          Tips! Välj ett långt lösenord med 8 tecken eller mer. Blanda små/stora bokstäver med siffror och specialtecken (till exempel '#', '@' och '!') för ännu högre säkerhet.
          <br />
        </p>
        <div className="progress">
          <div className={this.getProgressClass()} style={this.getProgressStyle()}>
            {this.getProgressText()}
          </div>
        </div>
      </div>
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.newPassword != nextProps.newPassword) {
      const passwordStrength = zxcvbn(nextProps.newPassword);
      this.setState({
        passwordStrength: passwordStrength.score
      });
    }
  }

  getProgressStyle() {
    const { passwordStrength } = this.state;
    return {
      width: `${(parseInt(passwordStrength) + 1) * 20}%`
    };
  }

  getProgressClass() {
    const { passwordStrength } = this.state;
    switch (passwordStrength) {
      case 2:
        return 'progress-bar progress-bar-warning';
      case 3:
      case 4:
        return 'progress-bar progress-bar-success';
      default:
        return 'progress-bar progress-bar-danger';
    }
  }

  getProgressText() {
    const { passwordStrength } = this.state;
    switch (passwordStrength) {
      case 1:
        return 'Svagt';
      case 2:
        return 'Medium';
      case 3:
        return 'Starkt';
      case 4:
        return 'Mycket starkt';
      default:
        return 'Mycket svagt';
    }
  }
}

PasswordStrength.propTypes = {
  newPassword: PropTypes.string.isRequired
};
