import React, {
  memo, useContext, useEffect, useState
} from 'react';
import PropTypes from 'prop-types';
import { getDiffTimeStaffList } from '@Utils/time-util';
import { getStaffUrl, navigate } from '@Utils/navigate';
import ColumnListItem from '@Components/ui/column/column-list-item';
import StaffContext from '@Components/staff/staff-context';

const StaffListItem = ({ item }) => {
  const { routeParams } = useContext(StaffContext);
  const {
    id, name, pno, checkinTime
  } = item?.toJS();

  const isValidTime = checkinTime !== null && checkinTime !== '-';
  const timeDurationValue = isValidTime ? checkinTime : '-';
  const [timeDuration, setTimeDuration] = useState(getDiffTimeStaffList(timeDurationValue));

  useEffect(() => {
    let interval;
    if (isValidTime) {
      interval = setInterval(() => {
        setTimeDuration(getDiffTimeStaffList(checkinTime));
      }, 1000);
    } else {
      setTimeDuration('-');
    }
    return () => clearInterval(interval);
  }, [checkinTime]);

  const onClickHandler = () => navigate(getStaffUrl(routeParams, id));

  return (
    <ColumnListItem
      key={id}
      onClick={onClickHandler}
      selected={id === +routeParams.id}
      header={name}
      icon="fa-solid fa-person-simple"
      subHeader={timeDuration}
    >
      {pno}
    </ColumnListItem>
  );
};

StaffListItem.propTypes = {
  item: PropTypes.object,
  routeParams: PropTypes.object
};

export default memo(StaffListItem);
