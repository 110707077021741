import moment from 'moment';
import _ from 'lodash';
import { getTimeByFormat } from '@Utils/time-util';
import { YEAR_MONTH_DAY_H_M_S, DATE_PICKER_FORMAT } from '@Utils/time-constants';
import { txt } from '@Utils/i18n-util';
import msg from './customer-util.msg';

export const getFutureSortedBookings = bookings => getSortedBookings(bookings, true).reverse();

export const getSortedBookings = (bookings = [], isFuture) => {
  const currentMoment = moment();
  let newBookings = [];
  if (bookings && _.isArray(bookings)) {
    newBookings = bookings.filter((book) => {
      if (isFuture) {
        return moment(book.startTime).isAfter(currentMoment);
      }
      return moment(book.startTime).isBefore(currentMoment);
    }).sort((a, b) => moment(b.startTime) - moment(a.startTime));
  }
  return newBookings;
};

// return array in format
// [{'feb 2021', [all events in period] }]
export const groupEventsByMonth = data => _.chain(data)
  .groupBy((event) => moment(event?.startTime || event?.createdTs).startOf('month'))
  .toPairs()
  .sort((a, b) => moment(b[0]) - moment(a[0]))
  .orderBy((event) => moment(event[0], 'YYYYMMDD').format('YYYYMMDD'), ['asc'])
  .value();

export const sortEventByTimeAndReverse = (events = []) => _.chain(events)
  .sortBy(event => getTimeByFormat(event?.startTime || event?.createdTs || event?.created || event?.createdTs, YEAR_MONTH_DAY_H_M_S))
  .reverse()
  .value();

export const getGenderText = (gender) => {
  switch (gender) {
    case 'Male':
      return txt(msg.genderMaleLabel);
    case 'Female':
      return txt(msg.genderFemaleLabel);
    default:
      return txt(msg.genderDefault);
  }
};

export const groupBookingsFuture = (events) => {
  const today = [];
  const tomorrow = [];
  const dayAfterTomorrow = [];
  const thisWeek = [];
  const nextWeek = [];
  const months = [];

  const todayTime = moment().format(DATE_PICKER_FORMAT);
  const tomorrowTime = moment().add(1, 'day').format(DATE_PICKER_FORMAT);
  const dayAfterTomorrowTime = moment().add(2, 'day').format(DATE_PICKER_FORMAT);
  const nextWeekTime = moment().startOf('isoWeek').add(1, 'week');

  // eslint-disable-next-line array-callback-return
  events.length > 0 && events.map((event, index) => {
    const startTime = moment(event?.startTime);
    if (startTime.isSame(todayTime, 'day')) { // same day
      today.push(event);
      return false;
    } if (moment(event?.startTime, DATE_PICKER_FORMAT).isSame(tomorrowTime, 'day')) { // tomorrow
      tomorrow.push(event);
      return false;
    } if (startTime.isSame(dayAfterTomorrowTime, 'day')) { // dayAfterTomorrow
      dayAfterTomorrow.push(event);
      return false;
    } if (startTime.isBefore(nextWeekTime) && startTime.isAfter(moment())) { // this week
      thisWeek.push(event);
      return false;
    } if (startTime.isSame(nextWeekTime, 'week')) { // next week
      nextWeek.push(event);
      return false;
    }
    months.push(event);
  });

  return {
    today,
    tomorrow,
    dayAfterTomorrow,
    thisWeek,
    nextWeek,
    months: groupEventsByMonth(months) // other month
  };
};

export const getChannel = (channel) => {
  switch (channel) {
    case 'Web':
      return txt(msg.channelWebLabel);
    case 'Cal':
      return txt(msg.channelCalLabel);
  }
};

export const getSmsType = (type) => {
  switch (type) {
    case 'PreReminder':
      return txt(msg.smsPreReminderLabel);
    case 'PostReminder1':
      return txt(msg.smsPostReminder1Label);
    case 'PostReminder2':
      return txt(msg.smsPostReminder2Label);
    default:
      return '';
  }
};

export const getSmsSent = (status) => {
  switch (status) {
    case 'SENT':
    case 'DELIVERED':
      return true;
    default:
      return false;
  }
};

export const getSmsStatus = (status) => {
  switch (status) {
    case 'PENDING':
      return '';
    case 'SENT':
      return txt(msg.smsStatusSENT);
    case 'DELIVERED':
      return txt(msg.smsStatusDELIVERED);
    case 'DELIVERY_FAILED':
      return txt(msg.smsStatusDELIVERY_FAILED);
    case 'DELIVERY_UNKNOWN':
      return txt(msg.smsStatusDELIVERY_UNKNOWN);
    case 'SEND_REJECTED':
      return txt(msg.smsStatusSEND_REJECTED);
  }
};
