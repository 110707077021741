import React, { useState, useRef, useEffect } from 'react';
import useOnClickOutside from '@Hooks/useOnClickOutside';
import DropDown from '@Components/ui/drop-down/dropdown';
import { TB } from '@Components/ui/styled/main';

const TBDropdown = ({
  children, label, icon, style, labelStyle, phoneMode,
  tabletMode, primary, small, open, setOpen
}) => {
  const ref = useRef();
  const [localOpen, setLocalOpen] = useState(open);

  useEffect(() => setLocalOpen(open), [open]);

  const handleOpen = () => {
    setLocalOpen(true);

    if (setOpen) {
      setOpen(true);
    }
  };
  const handleClose = () => {
    setLocalOpen(false);

    if (setOpen) {
      setOpen(false);
    }
  };

  useOnClickOutside(ref, handleClose);

  return (
    <TB
      primary={primary}
      tabletMode={tabletMode}
      phoneMode={phoneMode}
      label={label}
      style={style}
      labelStyle={labelStyle}
      icon={icon}
      dropDown
      onClick={handleOpen}
      ref={ref}
    >
      {localOpen && children && (
        <DropDown light small={small} onClose={handleClose}>
          {children}
        </DropDown>
      )}
    </TB>
  );
};

export default TBDropdown;
