import { fetchMyBusinessInfo } from '@State/mybusiness-actions';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Loader from '@Components/ui/loader';

const MyBusinessInfo = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [business, setBusiness] = useState(null);

  const {
    title, primaryPhone, webSiteUri, profileDescription,
    addressLine1, postalCode, locality
  } = business || {};

  useEffect(() => {
    setLoading(true);
    dispatch(fetchMyBusinessInfo())
      .then((response) => setBusiness(response))
      .finally(() => setLoading(false));
  }, []);

  return loading ? <Loader /> : (
    <>
      <h4>{title}</h4>
      <p className="mb0"><i className="fa fa-home mr1" />{addressLine1}, {postalCode} {locality}</p>
      <p className="mb0"><i className="fa fa-phone mr1" />{primaryPhone}</p>
      <p><i className="fa fa-globe mr1" />{webSiteUri}</p>
      <p>{profileDescription}</p>
    </>
  );
};

export default MyBusinessInfo;
