import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pos } from '@Utils/preference-keys';
import { formatSwishNumber, getUseSwishIntegration } from '@Utils/pos-utils';
import { mergeOrgSettings } from '@State/pos-config-actions';
import { getPosOrgSwishPrefs } from '@State/pos-config-selectors';
import AutoSaveTextInput from '@Components/inputs/auto-save-text-input';
import AlertWithIcon from '@Components/ui/alert-with-icon';
import Button from '@Components/ui/button';
import PosOrgConfigToggle from './pos-org-config-toggle';
import PosOrgSwishModal from './pos-org-swish-modal';

const PosOrgPaymentSwish = (props) => {
  const { posOrg } = props;
  const dispatch = useDispatch();
  const handleSave = useCallback((values) => {
    return dispatch(mergeOrgSettings(posOrg.get('id'), values));
  }, [posOrg]);

  const [showSwishModal, setShowSwishModal] = useState(false);
  const swishPrefs = useSelector(state => getPosOrgSwishPrefs(state, props));
  const { [pos.swishPayeeAlias]: swishPayeeAlias } = swishPrefs || {};
  const useSwishIntegration = getUseSwishIntegration(swishPrefs);

  return (
    <PosOrgConfigToggle
      posOrg={posOrg}
      preference={pos.paymentMethodSwish}
      label="Swish"
      description="Visa Swish som betalsätt i kassan. Kräver eget avtal med Swish"
    >
      {!useSwishIntegration && (
        <AutoSaveTextInput
          label="Swish-nummer för QR-kod"
          helpText="Ange ditt Swish-nummer för att automatiskt skapa en QR-kod med förifyllda värden i kassan som kunden kan skanna för att betala."
          name={pos.swishPayeeAlias}
          initialValue={swishPayeeAlias}
          onSave={handleSave}
          width={200}
        />
      )}
      {useSwishIntegration ? (
        <div className="form-group">
          <h5 className="mb1">
            <div className="pull-right">
              <span><i className="fa fa-circle text-success" /> Aktiv</span>
            </div>
            Integration med Swish Handel
          </h5>
          <p className="text-muted mb2">
            Integration är aktiv med Swish-nummer {formatSwishNumber(swishPayeeAlias)}
          </p>
          <Button small outlinePrimary onClick={() => setShowSwishModal(true)}>
            Hantera integration
          </Button>
        </div>
      ) : (
        <AlertWithIcon>
          <h5 className="mb1">
            Integration med Swish Handel
          </h5>
          <p className="mb2">
            Aktivera integration med Swish Handel för att kunna
            skicka betalningar direkt till kundens Swish-app.
          </p>
          <Button small white onClick={() => setShowSwishModal(true)}>
            Läs mer och aktivera
          </Button>
        </AlertWithIcon>
      )}
      {showSwishModal && (
        <PosOrgSwishModal
          posOrg={posOrg}
          onClose={() => setShowSwishModal(false)}
        />
      )}
    </PosOrgConfigToggle>
  );
};

export default PosOrgPaymentSwish;
