import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { convertToString, convertToFloat } from '@Utils/numpad-util';
import { numberFormat } from '@Utils/format';
import CalcDisplay from '@Components/ui/calc-display';

const DiscountCalcDisplay = ({
  discountValue, discountType, onChangeType, ...props
}) => {
  const setPercentageType = (ev) => {
    ev.stopPropagation();
    onChangeType('Percentage');
  };
  const setAmountType = (ev) => {
    ev.stopPropagation();
    onChangeType('Amount');
  };

  const calcButtons = [{
    onClick: setPercentageType,
    text: '%',
    className: discountType === 'Percentage' ? 'selected' : ''
  }, {
    onClick: setAmountType,
    text: 'kr',
    className: discountType === 'Amount' ? 'selected' : ''
  }];

  return (
    <CalcDisplay
      {...props}
      label={discountType === 'Amount' ? 'Rabatt (inkl. moms)' : 'Rabatt'}
      buttons={calcButtons}
    >
      {numberFormat(convertToFloat(discountValue))}
    </CalcDisplay>
  );
};

DiscountCalcDisplay.propTypes = {
  discountType: PropTypes.string,
  discountValue: PropTypes.string
};

export default DiscountCalcDisplay;
