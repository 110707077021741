import React from 'react';
import { BlockStyled } from '@Components/pos/style';
import OperatorLogin from '@Components/pos/operator-login';

const styleTopPos = { position: 'relative', top: '-20px' };
const PosLogin = ({ onSubmit }) => (
  <div className="pos">
    <div className="pos-login">
      <div className="pos unavailable" style={styleTopPos}>
        <BlockStyled>
          <OperatorLogin isNotDialog onSubmit={onSubmit} />
        </BlockStyled>
      </div>
    </div>
  </div>
);

export default PosLogin;
