import React from 'react';
import { useFormContext } from 'react-hook-form';

import HookFormInput from '@Components/inputs/hook-form-input';
import Button from '@Components/ui/button';
import SubmitButton from '@Components/ui/submit-button';
import RichTextEditorField from '@Components/inputs/rich-text-editor';
import Label from '@Components/ui/label';
import { ReleaseNoteLabels, getReleaseNoteColor } from './release-notes-helpers';

const ReleaseNotesForm = ({ onSubmit, onClose }) => {
  const { watch, setValue, handleSubmit, formState: { isSubmitting } } = useFormContext();
  const [label, text] = watch(['label', 'text']);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-group">
        {Object.keys(ReleaseNoteLabels).map((key) => {
          return (
            <Label
              key={key}
              className="mr1 mt0"
              style={{ opacity: label === key ? 1 : 0.3 }}
              onClick={() => setValue('label', key)}
              bgColor={getReleaseNoteColor(key)}
            >
              {ReleaseNoteLabels[key]}
            </Label>
          );
        })}
      </div>
      <HookFormInput
        label="Datum"
        id="date"
        type="date"
        registerOptions={{ required: true }}
      />
      <HookFormInput
        label="Rubrik"
        id="title"
        type="text"
        registerOptions={{ required: true }}
      />
      <RichTextEditorField
        meta={{ pristine: false }}
        input={{ value: text, onChange: val => setValue('text', val) }}
        editorClassName="text-editor-fixed"
      />
      <div className="text-right mt4">
        <Button gray small marginRight onClick={onClose} type="button">
          Avbryt
        </Button>
        <SubmitButton primary small type="submit" submitting={isSubmitting}>
          Spara
        </SubmitButton>
      </div>
    </form>
  );
};

export default ReleaseNotesForm;
