import React from 'react';
import moment from 'moment';
import { fromJS } from 'immutable';

import { DATE_PICKER_FORMAT } from '@Utils/time-constants';
import { colors } from '@Components/ui/styled/variables';

export const ACTIVE = '';
export const UNPAID = 'unpaid';
export const TERMINATED = 'terminated';
export const INVOICE = 'invoice';
export const TRIAL = 'trial';
export const TEST = 'Test';

export const isFilteredActive = filterType => filterType === ACTIVE;
export const isFilteredTrial = filterType => filterType === TRIAL;
export const isFilteredUnpaid = filterType => filterType === UNPAID;
export const isFilteredTerminated = filterType => filterType === TERMINATED;
export const isFilteredInvoice = filterType => filterType === INVOICE;
export const isFilteredTest = filterType => filterType === TEST;

export const accountsRadioFields = {
  Aktiva: ACTIVE,
  Trial: TRIAL,
  Obetalda: UNPAID,
  Faktura: INVOICE,
  Test: TEST
};

const getLabelByDate = ({ date, trialDays }) => {
  const timeMoment = trialDays
    ? moment(date).add(trialDays + 1, 'days')
    : moment(date);
  const days = timeMoment.diff(moment(), 'days');
  const time = timeMoment.format(DATE_PICKER_FORMAT);
  const labelText = days > 0 ? `${days} dagar kvar` : time;
  return {
    isLabelColorGreen: days > 0,
    labelText,
    time
  };
};

const getTrialLabel = ({ date, trialDays }) => ({
  ...getLabelByDate({ date, trialDays }),
  color: colors.labelTrial,
  text: 'Trial'
});

const getTerminatedLabel = ({ date, trial }) => ({
  ...getLabelByDate({ date }),
  color: colors.labelTerminated,
  text: 'Avslutat',
  ...(trial && {
    subLabel: 'Trial',
    subLabelColor: colors.labelTrial
  })
});

const getUnpaidLabel = ({ date }) => {
  const { time } = getLabelByDate({ date });
  return {
    labelText: time,
    color: colors.labelUnpaid,
    text: moment().isAfter(date) && 'Obetalt'
  };
};

const getInvoiceFailedLabel = ({ date }) => {
  const { time } = getLabelByDate({ date });
  return {
    labelText: time,
    color: colors.labelUnpaid,
    text: 'Fakturafel'
  };
};

export const addTrialEndTs = (org) => org.set(
  'trialEndTs',
  moment(org.get('trialStartTs')).add(org.get('trialDays'), 'days').format()
);

export const getSysAdminSortLabelByFilter = filterType => {
  switch (filterType) {
    case 'trial': {
      return 'Days left';
    }
    case 'unpaid': {
      return 'Paid until';
    }
    case 'terminated': {
      return 'Terminated';
    }
    default: return 'Created';
  }
};

export const getSysAdminSortFieldByFilter = filterType => {
  switch (filterType) {
    case 'trial': {
      return 'trialEndTs';
    }
    case 'unpaid': {
      return 'paidUntilTs';
    }
    case 'terminated': {
      return 'terminatedTs';
    }
    default:
      return 'activationTime';
  }
};

export const getSysAdminLabelProps = ({
  paidUntilTs, trial, unpaid, trialDays, trialStartTs,
  terminatedTs, invoiceFailedTs
}) => {
  if (terminatedTs) {
    return getTerminatedLabel({ date: terminatedTs, trial });
  }
  if (trial) {
    return getTrialLabel({ date: trialStartTs, trialDays });
  }
  if (invoiceFailedTs) {
    return getInvoiceFailedLabel({ date: invoiceFailedTs });
  }
  if (unpaid) {
    return getUnpaidLabel({ date: paidUntilTs });
  }
  return {};
};

export const getSelectedOrganization = ({ organizations, id }) => {
  if (organizations.size > 0) {
    if (id) {
      const findItem = organizations.find((org) => org.get('id') === +id);
      return findItem || organizations.first();
    }
    return organizations.first();
  }
  return null;
};

export const filterOrganizations = ({ orgs, filterType }) => orgs.filter((org) => {
  const value = org.get(filterType);
  const trial = org.get('trial');
  const terminated = org.get('terminated');
  const orgAccountType = org.get('orgAccountType');
  const invoice = org.get('paymentMethod') === 'Invoice';
  const isTrialTerminatedFalse = trial === false && terminated === false;

  if (isFilteredTest(filterType) && isFilteredTest(orgAccountType)) return true;
  if (!isFilteredTest(filterType) && isFilteredTest(orgAccountType)) return false;
  if (isFilteredActive(filterType) && org.get('activationTime') && isTrialTerminatedFalse) return true;
  if (isFilteredTrial(filterType) && value && terminated === false) return true;
  if (isFilteredUnpaid(filterType) && value && isTrialTerminatedFalse) return true;
  if (isFilteredInvoice(filterType) && invoice && isTrialTerminatedFalse) return true;

  return isFilteredTerminated(filterType) && value;
});

export const sortOrganizations = ({ orgs, sort, field }) => orgs.sort((a, b) => {
  const aTime = moment(a.get(field));
  const bTime = moment(b.get(field));
  return sort ? aTime - bTime : bTime - aTime;
});

export const getOrganizations = (organizations, sysadminControl) => {
  const { filterType, sort } = sysadminControl.toJS();
  const field = getSysAdminSortFieldByFilter(filterType);
  let orgsFiltered = filterOrganizations({ orgs: organizations, filterType });

  if (isFilteredTrial(filterType)) {
    // need mutate object to get date trialStartTs + trialDays;
    orgsFiltered = orgsFiltered.map((org) => addTrialEndTs(org));
  }
  const newOrgs = sortOrganizations({ orgs: orgsFiltered, sort, field });
  return fromJS(newOrgs);
};

export const isContractOngoing = (startDate, endDate) => {
  return startDate && endDate
    && !moment(startDate).isSame(endDate, 'month')
    && moment().isBefore(endDate);
};
