import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { useDispatch } from 'react-redux';

import { classes } from '@Components/ui/utils';
import Popover from '@Components/ui/popover';
import TimePickerRow from '@Components/inputs/time-picker-row';

import { DialogButtonsHorizontal, DialogButton } from '@Components/dialogs/dialog-styles';
import {
  getDate, getTimeHours, getTimeISO, isTheSameDay
} from '@Utils/time-util';
import { DEFAULT_TIME_START, DEFAULT_TIME_END } from '@Utils/time-constants';
import DatePickerCalendarInput from '@Components/ui/date-picker/date-picker-calendar-input';
import DateTimePicker from '@Components/inputs/date-time-picker';

import useKeyPress from '@Hooks/useKeyPress';
import ConfirmSignature from '@Components/ui/confirm-signature';

import { updateEntry, fetchStaffEntries, createEntry } from '@State/staff-actions';

const StaffEditEntryPopup = ({
  preferPlace = 'row', isToday, isNew,
  isEntryHasDifferentDay, showDelete, date, isHideDatePicker = false,
  isShowPopover, start, end, children, userId, id, handleDelete, handleClose
}) => {
  const dispatch = useDispatch();
  const [startDay, setStartDay] = useState(start);
  const [endDay, setEndDay] = useState(end);
  const [startTime, setStartTime] = useState({ time: start ? getTimeHours(start) : DEFAULT_TIME_START });
  const [endTime, setEndTime] = useState({ time: end ? getTimeHours(end) : DEFAULT_TIME_END });

  const [selectedDay, setSelectedDay] = useState(date);
  const [loading, setLoading] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showSignature, setShowSignature] = useState(false);
  const [isEndLessThanStart, setIsEndLessThanStart] = useState(false);

  const hideSignature = () => setShowSignature(false);
  const hideShowConfirmDelete = () => setShowConfirmDelete(false);
  const showShowConfirmDelete = () => setShowConfirmDelete(true);

  useKeyPress(27, handleClose);

  useEffect(() => {
    if (isToday) {
      setSelectedDay(moment(getDate()));
    }
    setShowConfirmDelete(false);
    setShowSignature(false);
    setStartTime({ time: start ? getTimeHours(start) : DEFAULT_TIME_START });
    setEndTime({ time: end ? getTimeHours(end) : DEFAULT_TIME_END });
  }, [isShowPopover]);

  const onChangeDay = (date) => setSelectedDay(date);
  const onChangeStartDay = (date) => {
    setStartDay(getDate(date));
    validate(isTheSameDay(getDate(date), getDate(endDay)));
  };
  const onChangeEndDay = (date) => {
    setEndDay(getDate(date));
    validate(isTheSameDay(getDate(date), getDate(startDay)));
  };
  const onChangeTime = (isStartTime, newTime) => {
    isStartTime ? setStartTime({ time: newTime }) : setEndTime({ time: newTime });
    const isInValidTime = isStartTime ? newTime >= endTime.time : newTime < startTime.time;
    validate(isTheSameDay(startDay, endDay) && isInValidTime);
  };

  const validate = (isInValidTime) => {
    setIsEndLessThanStart(false);
    if (isInValidTime) {
      setIsEndLessThanStart(true);
    }
  };

  const onNext = () => setShowSignature(true);

  const onSave = async (signature) => {
    if (showConfirmDelete) {
      return handleDelete(signature);
    }
    setLoading(true);
    try {
      const getCheckinTime = () => {
        const date = isNew ? getDate(selectedDay) : getDate(startDay);
        return getTimeISO(`${date} ${startTime.time}`);
      };
      const getCheckoutTime = () => {
        const date = isNew ? getDate(selectedDay) : getDate(endDay);
        return getTimeISO(`${date} ${endTime.time}`);
      };

      const checkinTime = getCheckinTime();
      const checkoutTime = getCheckoutTime();
      const newEntry = {
        userId, checkinTime, checkoutTime, signature
      };

      isNew
        ? await dispatch(createEntry(newEntry))
        : await dispatch(updateEntry({ entryId: id, ...newEntry }));

      await dispatch(fetchStaffEntries(userId));
    } finally {
      setLoading(false);
    }
    return handleClose();
  };

  const classesLabel = classes({
    'left-position': true,
    'control-label': true,
    'text-danger': true,
    'inline-block': true,
    disabled: loading
  });

  const classesPopup = classes({
    'Popover-dialog': true,
    'Popover-dialog-big': isEntryHasDifferentDay
  });

  const hideDialogs = () => {
    hideShowConfirmDelete();
    hideSignature();
  };

  const onTimeInputChangeStart = (start) => onChangeTime(true, start);
  const onTimeInputChangeEnd = (end) => onChangeTime(false, end);

  const getBody = () => {
    if (showSignature || showConfirmDelete) {
      return (
        <ConfirmSignature
          confirmText={showConfirmDelete ? 'Ta bort' : 'Spara'}
          onConfirm={onSave}
          onHidePopup={hideDialogs}
        />
      );
    }

    return (
      <>
        {!isHideDatePicker && (
        <>
          <DatePickerCalendarInput
            maxDate={new Date()}
            disabled={loading}
            datePickerIcons="far fa-clock"
            date={moment(selectedDay)}
            onChange={onChangeDay}
          />
          <div className="time-picker-input-wrap-row" />
        </>
        )}
        { isEntryHasDifferentDay
          ? (
            <>
              <DateTimePicker
                labelName="Start"
                maxDate={new Date(endDay)}
                time={startTime.time}
                isHasError={isEndLessThanStart}
                onTimeInputChange={onTimeInputChangeStart}
                onChangeDate={onChangeStartDay}
                date={startDay}
              />
              <DateTimePicker
                labelName="Slut"
                minDate={new Date(startDay)}
                time={endTime.time}
                isHasError={isEndLessThanStart}
                onTimeInputChange={onTimeInputChangeEnd}
                onChangeDate={onChangeEndDay}
                date={endDay}
              />
            </>
          )
          : (
            <TimePickerRow
              disabled={loading}
              timers={[startTime, endTime]}
              isAddGrayWrap={false}
              onChange={onChangeTime}
            />
          )}

        {showDelete && <span className={classesLabel} onClick={showShowConfirmDelete}>Ta bort</span>}
        <DialogButtonsHorizontal>
          <DialogButton disabled={loading} small gray onClick={handleClose}>Avbryt</DialogButton>
          <DialogButton disabled={loading} loading={loading} small primary onClick={onNext}>Nästa</DialogButton>
        </DialogButtonsHorizontal>
      </>
    );
  };

  const renderBody = () => (
    <div className={classesPopup}>
      {getBody()}
    </div>
  );

  return (
    <Popover
      isOpen={isShowPopover}
      preferPlace={preferPlace || 'row'}
      className="Popover-big-radius"
      onOuterAction={handleClose}
      body={renderBody()}
    >
      {children}
    </Popover>
  );
};

StaffEditEntryPopup.propTypes = {
  isHideDatePicker: PropTypes.bool,
  showDelete: PropTypes.bool,
  handleDelete: PropTypes.func,
  block: PropTypes.object,
  handleClose: PropTypes.func
};

export default StaffEditEntryPopup;
