import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import s from 'underscore.string';
import debounce from 'lodash/debounce';
import Autosuggest from 'react-autosuggest';
import Highlighter from 'react-highlight-words';
import { fetchSuggestions } from '@State/bkf/actions';
import { CUSTOMER_SEARCH_DEBOUNCE } from '@Utils/constants';
import { getOrgWideCustomerDb } from '@State/selectors';

import PhoneUtil from '@Utils/phone-util';
import { txt } from '@Utils/i18n-util';
import Bouncefix from './bouncefix';
import msg from './search-customer.msg';

class SearchCustomer extends Component {
  static propTypes = {
    onCancel: PropTypes.func,
    onSelected: PropTypes.func.isRequired,
    deviceType: PropTypes.string,
    deviceOs: PropTypes.string,
    externalKeyboard: PropTypes.bool,
    scope: PropTypes.array.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      value: props.value || '',
      suggestions: [],
      isLoading: false,
      inputFocused: true
    };

    this.scopeDescription = this.getScopeDescription(props.scope);
    this.includeVehicle = props.scope.indexOf('Vehicle') !== -1;
    this.includeCompany = props.scope.indexOf('Company') !== -1;
    this.includeCustomer = props.scope.indexOf('Customer') !== -1;

    this.debouncedFetchSuggestions = debounce(this.fetchSuggestions, CUSTOMER_SEARCH_DEBOUNCE);
  }

  getScopeDescription = (scope) => {
    if (scope.length === 1 && scope[0] === 'Vehicle') {
      return txt(msg.lblVehicle);
    }
    if (scope.length === 1 && scope[0] === 'Company') {
      return txt(msg.lblCompany);
    }
    return txt(msg.lblCustomer);
  };

  getScopeIcon = (scope) => {
    if (scope === 'Vehicle') {
      return 'fa fa-fw fa-car text-muted';
    }
    if (scope === 'Company') {
      return 'fa fa-fw fa-building text-muted';
    }
    return 'fa fa-fw fa-user text-muted';
  };

  componentDidMount() {
    if (this.searchField) {
      const input = ReactDOM.findDOMNode(this.searchField);
      input.value = ''; // Hack to set focus to the end
      input.focus();
    }
  }

  onSuggestionsFetchRequested = ({ value, reason }) => {
    if (!value || reason === 'suggestion-selected') {
      return;
    }
    if (value.length <= 2) {
      this.onSuggestionsClearRequested();
      return;
    }

    this.debouncedFetchSuggestions(value);
  };

  fetchSuggestions = (value) => {
    const { scope } = this.props;
    this.setState({ isLoading: true });
    return fetchSuggestions(value, scope, scope.length === 1)
      .then((suggestions) => {
        const items = [];
        if (this.includeVehicle) {
          items.push({ scope: 'Vehicle' });
        }
        if (this.includeCompany) {
          items.push({ scope: 'Company' });
        }
        if (this.includeCustomer) {
          items.push({ scope: 'Customer' });
        }
        this.setState({ isLoading: false, suggestions: items.concat(suggestions) });
      });
  };

  onSuggestionsClearRequested = () => {
    this.setState({ suggestions: [], suggestionSelected: false });
  };

  onSuggestionHighlighted = ({ suggestion }) => {
    this.highlightedSuggestion = suggestion;
  };

  onSuggestionSelected = (event, { suggestion }) => {
    event.preventDefault();
    event.stopPropagation();
    this.selectCustomer(suggestion);
  };

  firstLetterUpperCase = (value) => {
    if (value === value.toLowerCase()) {
      return s.titleize(value);
    }
    return value;
  };

  selectCustomer = (suggestion) => {
    if (!suggestion) {
      return;
    }
    if (suggestion.type) {
      this.props.onSelected(this.mapSuggestion(suggestion));
    } else {
      const newSuggestion = this.createSuggestion(suggestion.scope, this.state.value);
      this.props.onSelected(this.mapSuggestion(newSuggestion));
    }
  };

  mapSuggestion = ({
    customerId, name, phoneNumber, otherPhoneNumber, pno, email, companyId,
    orgNo, orgName, vehicleRegNo, vehicleDescription, vehicleSearchResult
  }) => {
    const customer = customerId || name || phoneNumber || pno ? {
      customerId, name, email, phoneNumber, otherPhoneNumber, pno
    } : null;

    const company = companyId || orgNo || orgName ? {
      companyId, orgNo, orgName
    } : null;

    const vehicle = vehicleRegNo || vehicleDescription ? {
      vehicleRegNo, vehicleDescription, vehicleAttributes: vehicleSearchResult?.attribs
    } : null;

    return {
      customer: this.includeCustomer ? customer : null,
      company: this.includeCompany ? company : null,
      vehicle: this.includeVehicle ? vehicle : null
    };
  };

  createSuggestion = (scope, value) => {
    if (value && value.length > 0) {
      const isDigits = value.match(/^\d+$/);
      const isRegNo = value.match(/^[a-z]{3}[ ]*[0-9]{3}$/i);

      if (scope === 'Vehicle') {
        return { vehicleRegNo: value.toUpperCase() };
      }
      if (scope === 'Company') {
        return isDigits
          ? { orgNo: value }
          : { orgName: this.firstLetterUpperCase(value) };
      }
      return isDigits
        ? { phoneNumber: value }
        : { name: this.firstLetterUpperCase(value) };
    }
  };

  onChange = (event, { newValue }) => {
    this.setState({ value: newValue });
  };

  handleKeyDown = (ev) => {
    if (ev.keyCode === 9 || ev.keyCode === 13) {
      ev.preventDefault();
      this.selectCustomer(this.highlightedSuggestion);
    }
  };

  handleCancel = (ev) => {
    ev.preventDefault();
    this.props.onCancel();
  };

  renderVehicle = (search, vehicleRegNo, vehicleDescription) => {
    const icon = <i className={this.getScopeIcon('Vehicle')} />;
    const empty = <i className="fa fa-fw" />;

    return this.includeVehicle && (vehicleRegNo || vehicleDescription) && (
      <section>
        <div>
          {icon} <strong><Highlighter searchWords={search} textToHighlight={vehicleRegNo} autoEscape /></strong>
        </div>
        {vehicleDescription && (
        <div>
          {empty} <Highlighter searchWords={search} textToHighlight={vehicleDescription} autoEscape />
        </div>
        )}
      </section>
    );
  };

  renderCompany = (search, orgName, orgNo) => {
    const icon = <i className={this.getScopeIcon('Company')} />;
    const empty = <i className="fa fa-fw" />;

    return this.includeCompany && (orgName || orgNo) && (
      <section>
        <div>
          {icon} <strong><Highlighter searchWords={search} textToHighlight={orgName} autoEscape /></strong>
        </div>
        {orgNo && (
        <div>
          {empty} <Highlighter searchWords={search} textToHighlight={orgNo} autoEscape />
        </div>
        )}
      </section>
    );
  };

  renderCustomer = (search, name, phoneNumbers, email) => {
    const icon = <i className={this.getScopeIcon('Customer')} />;
    const empty = <i className="fa fa-fw" />;

    return this.includeCustomer && (name || phoneNumbers.length > 0 || email) && (
      <section>
        <div>
          {icon} <strong><Highlighter searchWords={search} textToHighlight={name} autoEscape /></strong>
        </div>
        {phoneNumbers.length > 0 && (
        <div>
          {empty} <Highlighter searchWords={search} textToHighlight={phoneNumbers.join(' / ')} autoEscape />
        </div>
        )}
        {email && (
        <div>
          {empty} <Highlighter searchWords={search} textToHighlight={email} autoEscape />
        </div>
        )}
      </section>
    );
  };

  renderLatestBooking = (latestBookingTs) => {
    return latestBookingTs && (
      <section className="text-muted">
        <i className="fa fa-fw" /> <small><em>{txt(msg.lblLatestBooking)} {moment(latestBookingTs).format('LLL')}</em></small>
      </section>
    );
  };

  renderVehicleRegistryInfo = (type) => {
    return this.includeVehicle && type === 'VehicleRegistry' && (
      <section className="text-muted">
        <i className="fa fa-fw" /> <small><em>{txt(msg.lblVehicleInfoSource)}</em></small>
      </section>
    );
  };

  renderLocationInfo = (locationId) => {
    return this.props.orgWideCustomerDb && (
      <section className="text-muted">
        <i className="fa fa-fw" /> <small><em><i className="far fa-location-dot mr1" />{this.props.locationNames.get(locationId)}</em></small>
      </section>
    );
  };

  renderCreateItem = (scope) => {
    return (
      <div className="booking-form-suggestion new-item">
        <section>
          <i className={this.getScopeIcon(scope)} /> <strong>{txt(msg.btnAddItem, { description: this.getScopeDescription([scope]) })}</strong>
        </section>
      </div>
    );
  };

  renderSuggestion = ({
    scope, type, orgNo, orgName, name, phoneNumber, otherPhoneNumber, email,
    vehicleRegNo, vehicleDescription, latestBookingTs, locationId
  }) => {
    const phoneNumbers = [];
    if (phoneNumber) {
      phoneNumbers.push(PhoneUtil.formatPhoneNumber(phoneNumber));
    }
    if (otherPhoneNumber) {
      phoneNumbers.push(PhoneUtil.formatPhoneNumber(otherPhoneNumber));
    }

    const search = [this.state.value];
    return type ? (
      <div className="booking-form-suggestion">
        {this.renderVehicle(search, vehicleRegNo, vehicleDescription)}
        {this.renderCompany(search, orgName, orgNo)}
        {this.renderCustomer(search, name, phoneNumbers, email)}
        {this.renderLocationInfo(locationId)}
        {this.renderLatestBooking(latestBookingTs)}
        {this.renderVehicleRegistryInfo(type)}
      </div>
    ) : this.renderCreateItem(scope);
  };

  renderInputComponent = (inputProps) => {
    return (
      <input
        {...inputProps}
        className="form-control"
        onFocus={(ev) => { inputProps.onFocus(ev); this.setState({ inputFocused: true }); }}
        /* setTimeout required on iOS, otherwise the 'select' event is ignored */
        onBlur={() => { setTimeout(() => { this.setState({ inputFocused: false }); }, 10); }}
        /* Important! Dont remove inputProps.ref call, subtle bug will be happening... */
        ref={(ref) => { inputProps.ref(ref); this.searchField = ref; }}
      />
    );
  };

  render() {
    const { height, maxHeight, noChrome, disabled } = this.props;
    const { value, suggestions } = this.state;
    const iosWithOSKOpen = this.props.deviceOs === 'iOS' && !this.props.externalKeyboard && this.state.inputFocused !== false;
    const inputProps = {
      placeholder: txt(msg.placeholderAddItem, { description: this.scopeDescription }),
      onKeyDown: this.handleKeyDown,
      onChange: this.onChange,
      value
    };

    const theme = {
      suggestionsList: 'booking-form-suggestions',
      suggestionHighlighted: 'highlighted',
      suggestionsContainer: iosWithOSKOpen ? 'booking-form-suggestions-container-ios' : 'booking-form-suggestions-container'
    };

    return (
      <div className={noChrome ? '' : 'booking-form'}>
        {!noChrome && (
          <div className="booking-form-header">
            <div className="cancel">
              <a href="#" onClick={this.handleCancel}>
                <i className="fa fa-chevron-left" /> {txt(msg.btnBack)}
              </a>
            </div>
            <h4 className="title">
              {txt(msg.lblTitle, { description: this.scopeDescription })}
            </h4>
            <div className="save" />
          </div>
        )}
        <Bouncefix className={disabled ? 'booking-form-body disabled' : 'booking-form-body'} style={{ height, maxHeight }}>
          <Autosuggest
            theme={theme}
            suggestions={suggestions}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            onSuggestionHighlighted={this.onSuggestionHighlighted}
            onSuggestionSelected={this.onSuggestionSelected}
            renderInputComponent={this.renderInputComponent}
            renderSuggestion={this.renderSuggestion}
            getSuggestionValue={() => value}
            alwaysRenderSuggestions
            shouldRenderSuggestions={() => true}
            focusInputOnSuggestionClick={false}
            inputProps={inputProps}
          />
        </Bouncefix>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    orgWideCustomerDb: getOrgWideCustomerDb(state),
    locationNames: state.locationNames
  };
};

export default connect(mapStateToProps)(SearchCustomer);
