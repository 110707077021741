import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createInvoiceCustomer, updateInvoiceCustomer } from '@State/invoice-actions';
import ModalDialog from '@Components/dialogs/modal-dialog';
import Button from '@Components/ui/button';
import InvoiceCustomerForm, { defaultValues } from './invoice-customer-form';
import InvoiceCustomerSelect from './invoice-customer-select';

const InvoiceCustomerModal = ({ invoiceCustomer, onSubmit, onClose }) => {
  const dispatch = useDispatch();
  const posOrgs = useSelector(state => state.posOrgs);

  const [posOrgId, setPosOrgId] = useState(invoiceCustomer?.get('posOrgId'));
  const [selectedCustomer, setSelectedCustomer] = useState(invoiceCustomer);
  const handleSelectCustomer = (customer) => setSelectedCustomer(customer);
  const handleResetCustomer = () => setSelectedCustomer(null);

  const [showNewCustomer, setShowNewCustomer] = useState(false);
  const handleNewCustomer = () => setShowNewCustomer(true);
  const handleCloseNewCustomer = () => setShowNewCustomer(false);

  const [showUpdateCustomer, setShowUpdateCustomer] = useState(null);
  const handleUpdateCustomer = (customer) => setShowUpdateCustomer(customer);
  const handleCloseUpdateCustomer = () => setShowUpdateCustomer(null);

  useEffect(() => {
    if (!posOrgId && posOrgs.size === 1) {
      setPosOrgId(posOrgs.first().get('id'));
    }
  }, [posOrgs, posOrgId]);

  const handleSubmit = () => {
    return onSubmit(selectedCustomer?.get('id'));
  };

  const onCreateCustomer = (values) => {
    return dispatch(createInvoiceCustomer(posOrgId, values))
      .then(customer => {
        setSelectedCustomer(customer);
        handleCloseNewCustomer();
      });
  };

  const onUpdateCustomer = (values) => {
    return dispatch(updateInvoiceCustomer(posOrgId, values))
      .then(customer => {
        setSelectedCustomer(customer);
        handleCloseUpdateCustomer();
      });
  };

  if (posOrgs.isEmpty()) {
    return null;
  }

  if (posOrgs.size > 1 && !posOrgId) {
    return (
      <ModalDialog
        onClose={onClose}
        contentSize="small"
        title="Välj företag"
        closeOnClickOutside={false}
        buttons={[
          ...posOrgs.map((posOrg) => ({
            gray: true,
            name: posOrg.get('companyName'),
            onClick: () => setPosOrgId(posOrg.get('id'))
          }))
        ]}
      />
    );
  }

  const posOrg = posOrgs.find(p => p.get('id') === posOrgId);

  if (showNewCustomer) {
    return (
      <ModalDialog
        title="Ny mottagare"
        hideCloseButton
        contentSize="large"
        closeOnClickOutside={false}
        onClose={handleCloseNewCustomer}
      >
        <InvoiceCustomerForm
          posOrg={posOrg}
          initialValues={defaultValues}
          onSubmit={onCreateCustomer}
          onClose={handleCloseNewCustomer}
        />
      </ModalDialog>
    );
  }

  if (showUpdateCustomer) {
    return (
      <ModalDialog
        title="Uppdatera mottagare"
        hideCloseButton
        contentSize="large"
        closeOnClickOutside={false}
        onClose={handleCloseUpdateCustomer}
      >
        <InvoiceCustomerForm
          posOrg={posOrg}
          initialValues={showUpdateCustomer}
          onSubmit={onUpdateCustomer}
          onClose={handleCloseUpdateCustomer}
        />
      </ModalDialog>
    );
  }

  return (
    <ModalDialog
      title="Fakturamottagare"
      hideCloseButton
      contentSize="large"
      closeOnClickOutside={false}
      onClose={onClose}
    >
      <InvoiceCustomerSelect
        posOrgId={posOrgId}
        selectedCustomer={selectedCustomer}
        onSelectCustomer={handleSelectCustomer}
        onNewCustomer={handleNewCustomer}
        onResetCustomer={handleResetCustomer}
        onUpdateCustomer={handleUpdateCustomer}
      />
      <div className="text-right">
        <Button gray small onClick={onClose}>Avbryt</Button> &nbsp;
        <Button primary small onClick={handleSubmit}>Spara</Button>
      </div>
    </ModalDialog>
  );
};

export default InvoiceCustomerModal;
