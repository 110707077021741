import React, { Component, Fragment } from 'react';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

class ContactForm extends Component {
  render() {
    const {
      isOptional, useContactInfo, handleSubmit, submitting, onCancel
    } = this.props;
    const showForm = !isOptional || useContactInfo === 'true';

    return (
      <form autoComplete="off" onSubmit={handleSubmit} className="form-horizontal">
        {isOptional && (
          <div className="form-group">
            <div className="col-xs-offset-3 col-xs-9">
              <div className="radio">
                <label>
                  <Field name="useContactInfo" value="false" component="input" type="radio" />
                  Samma som kontoägare
                </label>
              </div>
              <div className="radio">
                <label>
                  <Field name="useContactInfo" value="true" component="input" type="radio" />
                  Annan kontakt
                </label>
              </div>
            </div>
          </div>
        )}
        {showForm && (
          <>
            <div className="form-group">
              <label className="control-label col-xs-3">Kontaktperson</label>
              <div className="col-xs-5">
                <Field name="name" component="input" type="text" className="form-control" required />
              </div>
            </div>
            <div className="form-group">
              <label className="control-label col-xs-3">Mejl</label>
              <div className="col-xs-5">
                <Field name="email" component="input" type="email" className="form-control" required />
              </div>
            </div>
            <div className="form-group">
              <label className="control-label col-xs-3">Telefon</label>
              <div className="col-xs-5">
                <Field name="phone" component="input" type="phone" className="form-control" required />
              </div>
            </div>
          </>
        )}
        <div className="form-group">
          <div className="col-xs-offset-3 col-xs-9">
            <button type="submit" className="btn-save" disabled={submitting}>Spara</button> &nbsp;
            <button type="button" className="btn btn-default" disabled={submitting} onClick={onCancel}>Avbryt</button>
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state, { form }) => {
  const selector = formValueSelector(form);

  return {
    useContactInfo: selector(state, 'useContactInfo')
  };
};

export default reduxForm()(connect(mapStateToProps)(ContactForm));
