import React from 'react';
import PropTypes from 'prop-types';
import TB from '@Components/ui/tb-button';
import {
  buttonStyle, labelStyle, iconStyle, wrapperStyle
} from '@Components/pos/style';

const Lock = ({ onClick, isUnlocked = false, style }) => (
  <TB
    iconStyle={{ ...iconStyle }}
    style={{ ...wrapperStyle, ...style }}
    buttonStyle={buttonStyle}
    labelStyle={labelStyle}
    label={isUnlocked ? 'Lås' : 'Lås upp'}
    icon={isUnlocked ? 'fa fa-lock-alt' : 'fa fa-unlock-alt'}
    onClick={onClick}
  />
);

Lock.propTypes = {
  onClick: PropTypes.func,
  isUnlocked: PropTypes.bool,
  style: PropTypes.object
};

export default Lock;
