import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getArticleName } from '@Utils/pos-utils';
import { fetchStockTake, toggleStockTakeItems, setStockTakeItemQuantity, setStockTakeFinished, deleteStockTake } from '@State/stock-taking-actions';
import { getStockedProductGroups, getStockedProducts } from '@State/products-selectors';
import { createStockReport } from '@State/report-actions';
import { Button } from '@Components/ui/styled/main';
import Loader from '@Components/ui/loader';
import { ReportTitle, ReportTitleButtons } from '@Components/pos/style';
import FinishStockTake from '@Components/inventory/stock-taking/finish-stock-take';
import { getSectionUrl, navigate } from '@Utils/navigate';
import PopoverDeleteButton from '@Components/ui/popover-delete-button';
import NoContent from '@Components/ui/no-content';

const StockTakingItems = ({ routeParams, stockTakeId }) => {
  const dispatch = useDispatch();
  const stockTake = useSelector(state => state.stockTakes.get(stockTakeId));
  const items = useSelector(state => state.stockTakeItems);
  const productGroups = useSelector(getStockedProductGroups);
  const products = useSelector(getStockedProducts);
  const { label, createdTs, finishedTs } = stockTake || {};

  const [loading, setLoading] = useState(true);
  const [changeItems, setChangeItems] = useState(false);
  const [showFinish, setShowFinish] = useState(false);
  const [stockReportId, setStockReportId] = useState(null);

  useEffect(() => {
    if (stockTakeId) {
      loadStockTake();
    } else {
      setLoading(false);
    }
  }, [stockTakeId]);

  const loadStockTake = () => {
    setLoading(true);
    setChangeItems(false);
    dispatch(fetchStockTake(stockTakeId))
      .finally(() => setLoading(false));
  };

  const onPrint = (ev) => {
    ev.preventDefault();
    ev.target.blur();
    window.print();
  };

  const onToggleChangeItems = (ev) => {
    ev.preventDefault();
    ev.target.blur();
    if (changeItems) {
      loadStockTake();
    } else {
      setChangeItems(true);
    }
  };

  const onToggleGroup = (ev, group) => {
    const itemIds = group.get('articleIds')
      .filter(id => products.get(id) && !!items.get(id) !== ev.target.checked)
      .toArray();
    dispatch(toggleStockTakeItems(stockTakeId, itemIds, ev.target.checked));
  };

  const onToggleItem = (ev, itemId) => {
    dispatch(toggleStockTakeItems(stockTakeId, [itemId], ev.target.checked));
  };

  const onSetQuantity = (ev, item) => {
    const quantity = parseInt(ev.target.value);

    if (!isNaN(quantity) && quantity !== item.countedUnits) {
      dispatch(setStockTakeItemQuantity(stockTakeId, item.itemId, quantity));
    }
  };

  const onFinish = (ev) => {
    ev.preventDefault();
    ev.target.blur();
    setShowFinish(true);
  };

  const onSetFinished = (ev) => {
    return dispatch(setStockTakeFinished(stockTakeId))
      .then(() => dispatch(createStockReport({ label })))
      .then(id => setStockReportId(id));
  };

  const onCloseFinished = () => {
    setShowFinish(false);
    setStockReportId(null);
  };

  const onDelete = (ev) => {
    return dispatch(deleteStockTake(stockTakeId))
      .then(() => navigate(getSectionUrl(routeParams, 'admin/stock-taking')));
  };

  if (loading) {
    return <Loader />;
  }

  if (!stockTake) {
    return (
      <NoContent icon="fa fa-box-open">
        Det finns inga tidigare inventeringar.<br />
        Klicka på "Ny inventering" för att skapa en inventering.
      </NoContent>
    );
  }

  return (
    <>
      {showFinish && (
        <FinishStockTake
          routeParams={routeParams}
          stockTake={stockTake}
          stockReportId={stockReportId}
          onClose={onCloseFinished}
          onSubmit={onSetFinished}
        />
      )}
      <ReportTitle>
        <h3 className="mt0 mb0">{label}</h3>
        {finishedTs ? (
          <ReportTitleButtons className="hidden-print">
            <Button small gray onClick={onPrint}>Skriv ut</Button>
          </ReportTitleButtons>
        ) : (
          <ReportTitleButtons className="hidden-print">
            {changeItems
              ? <Button small primary onClick={onToggleChangeItems}>Avsluta ändring</Button>
              : <Button small marginRight gray onClick={onToggleChangeItems}>Lägg till/ta bort artiklar</Button>}
            {!changeItems && (
              <>
                <Button small marginRight gray onClick={onPrint}>Skriv ut</Button>
                <Button small marginRight primary onClick={onFinish}>Klarmarkera</Button>
                <PopoverDeleteButton onDelete={onDelete} />
              </>
            )}
          </ReportTitleButtons>
        )}
      </ReportTitle>
      <p className="mb4">
        Skapad: {moment(createdTs).format('LL')}<br />
        {finishedTs && `Klar: ${moment(finishedTs).format('LL')}`}
      </p>
      {items.isEmpty() && (
        <div className="alert alert-info">
          Det finns inga artiklar i denna inventering. Klicka på "Lägg till/ta bort artiklar" för att lägga till artiklar.
        </div>
      )}
      {changeItems ? (
        productGroups.map(group => {
          const checked = group.get('articleIds').every(id => !products.get(id) || items.get(id));
          const groupId = `group_${stockTakeId}_${group.get('id')}`;
          return (
            <table key={groupId} className="table table-condensed table-product-form">
              <thead>
                <tr>
                  <th className="col-xs-1">
                    <input
                      id={groupId}
                      type="checkbox"
                      onChange={ev => onToggleGroup(ev, group)}
                      checked={checked}
                    />
                  </th>
                  <th><label htmlFor={groupId}>{group.get('name')}</label></th>
                </tr>
              </thead>
              <tbody>
                {group.get('articleIds').map(articleId => {
                  const item = products.get(articleId);
                  const itemId = `article_${stockTakeId}_${articleId}`;
                  return item ? (
                    <tr key={itemId}>
                      <td>
                        <input
                          id={itemId}
                          type="checkbox"
                          name={itemId}
                          onChange={ev => onToggleItem(ev, articleId)}
                          checked={!!items.get(articleId)}
                        />
                      </td>
                      <td>
                        <label htmlFor={itemId}>{getArticleName(item)}</label>
                      </td>
                    </tr>
                  ) : null;
                })}
              </tbody>
            </table>
          );
        })
      ) : (
        productGroups.map(group => {
          const showGroup = group.get('articleIds').some(id => items.get(id));
          const groupId = `group_${stockTakeId}_${group.get('id')}`;
          return showGroup && (
            <table key={groupId} className="table table-condensed table-product-form">
              <thead>
                <tr>
                  <th>{group.get('name')}</th>
                  <th className="col-xs-2 text-right">Förväntat antal</th>
                  <th className="col-xs-2 text-right">Räknat antal</th>
                </tr>
              </thead>
              <tbody>
                {group.get('articleIds').map(articleId => {
                  const item = items.get(articleId);
                  const itemId = `article_${stockTakeId}_${item?.itemId}`;
                  return item && (
                    <tr key={itemId}>
                      <td>{getArticleName(item)}</td>
                      <td className="col-xs-2 text-right">
                        {item.expectedUnits}
                      </td>
                      <td className="col-xs-2 text-right">
                        {finishedTs ? (
                          item.countedUnits
                        ) : (
                          <input
                            type="text"
                            name={itemId}
                            className="form-control"
                            defaultValue={item.countedUnits}
                            onBlur={ev => onSetQuantity(ev, item)}
                          />
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          );
        })
      )}
    </>
  );
};

export default StockTakingItems;
