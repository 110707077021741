import React from 'react';
import PropTypes from 'prop-types';

const ColumnSearch = ({
  searchValue, onSearch, placeholder = 'Sök kund', showSearchIcon
}) => (
  <div className="columns-filter search-wrapper">
    {showSearchIcon && <span className="input-group-addon"><i className="far fa-search" /></span>}
    <input
      value={searchValue}
      type="text"
      className="form-control"
      placeholder={placeholder}
      onChange={onSearch}
      autoComplete="off"
    />
  </div>
);

ColumnSearch.propTypes = {
  searchValue: PropTypes.string,
  onSearch: PropTypes.func,
  showSearchIcon: PropTypes.bool
};

export default ColumnSearch;
