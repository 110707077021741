import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { formatDate } from '@Utils/date-util';
import { MONTH_DAY_YEAR_SMALL_FORMAT } from '@Utils/time-constants';
import { classes } from '@Components/ui/utils';
import { colors } from '@Components/ui/styled/variables';
import { deleteSchedule } from '@State/schedule-actions';
import ScheduleTimelinePopover from '@Components/schedule/schedule-timeline-popover';

const styleOrangeCircle = {
  width: '6px',
  height: '6px',
  backgroundColor: colors.orange,
  borderRadius: '50%',
  position: 'absolute',
  top: '10px',
  left: '12px'
};

const ScheduleTimelineItem = ({
  isPast, handleToggleModalCopySchedule, resourceId, isShowArrow, onClick, id, validFrom, validUntil, isActive, isNowSchedule
}) => {
  const dispatch = useDispatch();

  const classList = classes({
    'schedule-timeline-item': true,
    'schedule-timeline-item-past': isPast,
    active: isActive
  });

  const classListWrap = classes({
    'flex-item': true,
    'schedule-timeline-item-wrap': true
  });

  const schedules = useSelector(state => state.adminSchedules.get('schedules'));
  const currentSchedule = schedules.find((schedule) => schedule.key === id);
  const enableDeleteSchedule = schedules && schedules.length > 1 && currentSchedule && schedules.findIndex((sc) => sc.key === currentSchedule.key) >= 0 && !!currentSchedule.validUntil;
  const [isOpenDropDown, setIsOpenDropDown] = useState(false);

  const handleConfirmDelete = () => {
    dispatch(deleteSchedule(currentSchedule.key, resourceId));
    hidePopover();
  };

  const showPopover = () => setIsOpenDropDown(true);
  const hidePopover = () => setIsOpenDropDown(false);

  const onClickByItem = () => {
    if (isActive) {
      showPopover();
    } else {
      onClick(id);
    }
  };

  return (
    <>
      <div className={classListWrap}>
        <div className="flex-item">
          <div onClick={onClickByItem} className={classList}>
            {isNowSchedule && <span style={styleOrangeCircle} />}
            <span className="text-nowrap">
              {formatDate(validFrom, MONTH_DAY_YEAR_SMALL_FORMAT)} - {validUntil ? formatDate(validUntil, MONTH_DAY_YEAR_SMALL_FORMAT) : 'tills vidare'}
            </span>
            <ScheduleTimelinePopover
              preferPlace="below"
              isOpen={isOpenDropDown}
              onOuterAction={hidePopover}
              enableDelete={enableDeleteSchedule}
              onConfirmDelete={handleConfirmDelete}
              onDuplicateSchedule={handleToggleModalCopySchedule}
            >
              <div className={isActive ? 'dropdown-active-icon' : 'dropdown-inactive-icon'}>
                <i className="fa fa-chevron-down" />
              </div>
            </ScheduleTimelinePopover>
          </div>
          {isShowArrow && (
          <div className="schedule-header-arrow">
            <i className="fas fa-chevron-double-right" />
          </div>
          )}
        </div>
      </div>
    </>
  );
};

ScheduleTimelineItem.propTypes = {
  onClick: PropTypes.func,
  getItemWidth: PropTypes.func,
  id: PropTypes.string,
  name: PropTypes.string,
  isActive: PropTypes.bool,
  isNowSchedule: PropTypes.bool
};

export default ScheduleTimelineItem;
