export const SET_CF_STATE = 'SET_CF_STATE';
export const OPEN_TAB = 'OPEN_TAB';

export function openCustomerTab(state) {
  return {
    type: OPEN_TAB,
    ...state
  };
}
export function setCustomerFormState(state) {
  return {
    type: SET_CF_STATE,
    ...state
  };
}
