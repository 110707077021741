import React from 'react';
import { pos as posKeys } from '@Utils/preference-keys';
import { isCashierAdmin } from '@Utils/pos-utils';
import ModalDialog from '@Components/dialogs/modal-dialog';
import { Text } from '@Components/ui/styled/main';
import { DialogButton, DialogButtonsVertical } from '@Components/dialogs/dialog-styles';
import PosOrgPaymentGiftCard from '@Components/admin/pos-config/pos-org-payment-gift-card';

const GiftCardNotEnabled = ({
  posOrg, posUnit, posOrgPrefs, onCreateGiftCard, onClose
}) => {
  const isActivated = posOrgPrefs[posKeys.paymentMethodGiftCard];
  const isAdmin = isCashierAdmin(posUnit);

  return (
    <ModalDialog
      contentSize="medium"
      onClose={onClose}
      closeOnClickOutside={false}
      title="Aktivera presentkort"
      closeButtonText="Stäng"
    >
      {isAdmin ? (
        <PosOrgPaymentGiftCard posOrg={posOrg} />
      ) : (
        <Text marginBottom>
          För att aktivera försäljning och inlösen av presentkort
          måste du vara administratör för kassan.
        </Text>
      )}
      {isActivated ? (
        <DialogButtonsVertical>
          <DialogButton primary onClick={onCreateGiftCard}>Klar</DialogButton>
        </DialogButtonsVertical>
      ) : (
        <DialogButtonsVertical>
          <DialogButton primary onClick={onClose}>Stäng</DialogButton>
        </DialogButtonsVertical>
      )}
    </ModalDialog>
  );
};

export default GiftCardNotEnabled;
