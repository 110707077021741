import styled from 'styled-components';

export default styled.section`
  display: grid;
  grid-gap: 15px;
`;

export const GridRow = styled.div`
  display: grid;
  grid-template-columns: ${p => `repeat(${p?.columns || 4}, 1fr)`};
  > div {
    margin-bottom: 0;
  }
  cursor: ${p => p.onClick ? 'pointer' : 'default'};
`;
export const GridCell = styled.div`
  grid-column: ${p => `auto / span ${p?.size || 1}`};
  grid-row: ${p => `auto / span ${p?.size || 1}`};
`;
