import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { setUserPno } from '@State/users-actions';
import { Panel } from '@Components/ui/styled/main';
import LabelSwitchInput from '@Components/ui/label-switch-input';
import AutoSaveTextInput from '@Components/inputs/auto-save-text-input';

const StaffJournal = ({
  dispatch, id, pnoState, isOn, onChangeToggle
}) => {
  const handleSave = useCallback(({ pno }) => {
    return dispatch(setUserPno(id, pno));
  }, [id]);

  return (
    <Panel>
      <div className="form-group">
        <LabelSwitchInput
          noBorder
          label="Visa i personalliggare"
          isOn={isOn}
          id="showInStaffJournal"
          handleToggle={onChangeToggle}
        />
      </div>
      {isOn && (
        <AutoSaveTextInput
          name="pno"
          label="Personnummer"
          placeholder="YYMMDD-NNNN"
          initialValue={pnoState}
          onSave={handleSave}
        />
      )}
    </Panel>
  );
};

StaffJournal.propTypes = {
  id: PropTypes.number,
  pnoState: PropTypes.string,
  isOn: PropTypes.bool,
  onChangeToggle: PropTypes.func
};

export default memo(StaffJournal);
