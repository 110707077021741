import axios from 'axios';
import {
  axiosDefault, axiosErrorHandler, checkStatusAxios, prefixUrl
} from '@Utils/ajax-util';
import { mybusiness } from '@Utils/preference-keys';
import { updateLocalPreferences } from './preferences-actions';

export function fetchMyBusinessAuthUrl() {
  return dispatch => {
    const url = prefixUrl('/my-business/auth/link');
    const config = axiosDefault();

    return axios
      .get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function revokeMyBusinessToken() {
  return dispatch => {
    const url = prefixUrl('/my-business/revoke');
    const config = axiosDefault();
    const prefs = { [mybusiness.placeId]: null };

    return axios
      .post(url, null, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => dispatch(updateLocalPreferences(prefs)))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function fetchMyBusinessLocations() {
  return dispatch => {
    const url = prefixUrl('/my-business/business-information/location');
    const config = axiosDefault();

    return axios
      .get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function saveMyBusinessPlaceId(placeId) {
  return dispatch => {
    const url = prefixUrl(`/my-business/business-information/location/${placeId}`);
    const config = axiosDefault();
    const prefs = { [mybusiness.placeId]: placeId };

    return axios
      .post(url, null, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => dispatch(updateLocalPreferences(prefs)))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function fetchMyBusinessInfo() {
  return dispatch => {
    const url = prefixUrl('/my-business/business-information');
    const config = axiosDefault();

    return axios
      .get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}
