import React, { Component } from 'react';
import Modal from '@Components/ui/modal';

class JavascriptErrorAlert extends Component {
  refresh = (e) => {
    e.preventDefault();
    window.location.reload();
  };

  render() {
    const { error, networkError } = this.props;
    const statusCode = error?.response?.status;
    const corsError = networkError && !error?.response;
    const isMaintenance = corsError || statusCode === 502 || statusCode === 503;

    return (
      <Modal
        titleText="Fel"
        underlayClickExits={false}
        mounted
        onExit={() => {}}
        underlayClass="dialog-container"
        dialogClass="dialog-content"
      >
        {isMaintenance ? (
          <h2>
            <i className="far fa-exclamation-circle" /> &nbsp;
            Underhåll pågår
          </h2>
        ) : (
          <h2>
            <i className="far fa-exclamation-triangle" /> &nbsp;
            Ett oväntat fel uppstod
          </h2>
        )}
        <hr />

        {isMaintenance ? (
          <p>
            Just nu gör vi uppdateringar och underhåll av Cliento.<br /><br />
            Försök igen om en stund.
          </p>
        ) : (
          <p>
            Ladda om sidan och försök igen.<br /><br />
            Kontakta support om problemet kvarstår.
          </p>
        )}
        <hr />

        <a href="#" onClick={this.refresh} className="button arrow">Ladda om</a>
      </Modal>
    );
  }
}

export default JavascriptErrorAlert;
