import React from 'react';
import { useDropzone } from 'react-dropzone';

const dropzoneOptions = {
  noKeyboard: true,
  accept: {
    'text/csv': ['.csv']
  }
};

const FileUploadField = ({ value, onChange }) => {
  const onDrop = (droppedFiles) => {
    if (droppedFiles.length > 0) {
      onChange(droppedFiles[0]);
    }
  };

  const {
    getRootProps, getInputProps, isDragActive
  } = useDropzone({ ...dropzoneOptions, onDrop });

  return (
    <div className="file-drag-container" {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive && (
        <div className="file-drag-overlay" />
      )}
      {value ? (
        <div className="file-placeholder">
          <i className="far fa-file-alt mr1" />
          {value.name}
        </div>
      ) : (
        <div className="file-placeholder text-muted">
          <i className="far fa-file-alt mr1" />
          Välj fil
        </div>
      )}
    </div>
  );
};

export default FileUploadField;
