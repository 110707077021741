import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getTimeStaffCreated } from '@Utils/time-util';
import { getOrgWideCustomerDb } from '@State/selectors';
import { fetchCustomerReceipt } from '@State/customer-actions';

import { TitleBlock, PanelWrapper, RowFormGroups } from '@Components/customers/customer-styles';
import { Column, FormGroup } from '@Components/ui/styled/main';
import CustomerDetailSaleSpecification from '@Components/customers/customer-detail/sales/customer-detail-sale-specification';
import CustomerDetailTransactions from '@Components/customers/customer-detail/sales/customer-detail-transactions';
import Loader from '@Components/ui/loader';

const CustomerDetailReceipt = ({ selectedSale }) => {
  const dispatch = useDispatch();
  const [receipt, setReceipt] = useState(null);
  const [loading, setLoading] = useState(false);

  const orgWideCustomerDb = useSelector(getOrgWideCustomerDb);
  const locationNames = useSelector(state => state.locationNames);

  useEffect(() => {
    setLoading(true);
    dispatch(fetchCustomerReceipt(selectedSale?.saleReceiptId))
      .then((receipt) => setReceipt(receipt))
      .finally(() => setLoading(false));
  }, [selectedSale]);

  if (loading) {
    return <Loader topPosition />;
  }

  const {
    receiptNumber, cashierName, createdTs, transactions, amount, locationId, vunitName
  } = receipt?.toJS() || {};
  const isEcom = vunitName === 'Ecommerce';

  return (
    <>
      <TitleBlock>{isEcom ? 'Onlinebetalning' : 'Kassakvitto'}</TitleBlock>
      <PanelWrapper defaultCursor>
        {orgWideCustomerDb && (
          <RowFormGroups>
            <FormGroup
              labelText="Plats"
              text={locationNames.get(locationId) || '-'}
            />
          </RowFormGroups>
        )}
        <RowFormGroups>
          <Column size="50">
            <FormGroup
              labelText="Kassör"
              text={cashierName || '-'}
            />
          </Column>
          <Column size="50">
            <FormGroup
              labelText="Kvitto #"
              text={receiptNumber || '-'}
            />
          </Column>
        </RowFormGroups>
        <RowFormGroups>
          <FormGroup
            labelText="Datum"
            text={getTimeStaffCreated(createdTs) || '-'}
          />
        </RowFormGroups>
      </PanelWrapper>
      <TitleBlock>Specifikation</TitleBlock>
      <PanelWrapper defaultCursor>
        <CustomerDetailSaleSpecification
          items={receipt?.get('items')}
          paidAmount={amount}
        />
      </PanelWrapper>
      <TitleBlock>Transaktioner</TitleBlock>
      <PanelWrapper defaultCursor>
        <CustomerDetailTransactions items={transactions} />
      </PanelWrapper>
    </>
  );
};

export default CustomerDetailReceipt;
