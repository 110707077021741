import { Map } from 'immutable';
import {
  RESET_FINDTIME,
  ADD_FINDTIME_SERVICE,
  REMOVE_FINDTIME_SERVICE,
  SET_FINDTIME_SERVICES,
  ADD_FINDTIME_RESOURCE,
  REMOVE_FINDTIME_RESOURCE,
  SET_FINDTIME_RESOURCES,
  SET_FINDTIME_SLOTS,
  RESET_FINDTIME_SLOTS
} from '@State/find-time/constants';

import { CLEAR_LOCATION_STATE } from '@State/account-actions';
import { CLEAR_BOOKINGS } from '@State/booking-actions';
import { RECEIVE_VIEWDATA } from '@State/view-actions';

export function findTime(state = Map(), action = null) {
  switch (action.type) {
    case CLEAR_LOCATION_STATE:
    case RESET_FINDTIME:
      return state.clear();

    case ADD_FINDTIME_SERVICE:
      return state.setIn(['services', action.service.id], action.service);

    case REMOVE_FINDTIME_SERVICE:
      return state.deleteIn(['services', action.service.id]);

    case SET_FINDTIME_SERVICES:
      return state.withMutations((map) => {
        map.delete('services');
        action.services?.forEach(service => {
          map.setIn(['services', service.id], service);
        });
      });

    case ADD_FINDTIME_RESOURCE:
      return state.setIn(['resources', action.resource.id], action.resource);

    case REMOVE_FINDTIME_RESOURCE:
      return state.deleteIn(['resources', action.resource.id]);

    case SET_FINDTIME_RESOURCES:
      return state.withMutations((map) => {
        map.delete('resources');
        action.resources?.forEach(resource => {
          map.setIn(['resources', resource.id], resource);
        });
      });

    case SET_FINDTIME_SLOTS:
      return state.set('showInCalendar', action.showInCalendar)
        .set('slots', action.slots);

    case RESET_FINDTIME_SLOTS:
      return state.delete('slots').delete('showInCalendar');

    case CLEAR_BOOKINGS:
    case RECEIVE_VIEWDATA:
      return state.delete('slots');

    default:
      return state;
  }
}
