import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createSalesforceLead } from '@State/sysadmin-actions';
import ConfirmPopover from '@Components/ui/confirm-popover';
import Button from '@Components/ui/button';

const AccountHeaderBtnLead = ({ id }) => {
  const dispatch = useDispatch();
  const [showPopover, setShowPopover] = useState(false);

  const onSendLead = useCallback(() => {
    return dispatch(createSalesforceLead(id))
      .then(() => setShowPopover(false));
  }, [id]);

  return (
    <ConfirmPopover
      text="Skicka lead till Salesforce?"
      confirmText="Skicka lead"
      onClose={() => setShowPopover(false)}
      onConfirm={onSendLead}
      isOpen={showPopover}
    >
      <Button small gray marginRight onClick={() => setShowPopover(true)}>Skicka lead</Button>
    </ConfirmPopover>
  );
};

export default AccountHeaderBtnLead;
