import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import TextInput from '@Components/inputs/text-input';
import ModalDialog from '@Components/dialogs/modal-dialog';

class AddPrinterModal extends PureComponent {
  render() {
    const { handleSubmit, submitting, onClose } = this.props;

    return (
      <ModalDialog
        title="Lägg till skrivare"
        onClose={onClose}
        buttons={[{
          name: 'Lägg till skrivare',
          primary: true,
          type: 'submit',
          loading: submitting,
          onClick: handleSubmit
        }]}
      >
        <form onSubmit={handleSubmit}>
          <Field name="macAddress" component={TextInput} label="MAC-address" />
        </form>
      </ModalDialog>
    );
  }
}

export default reduxForm({
  form: 'add-printer-form'
})(AddPrinterModal);
