import React, { useCallback, useMemo, useState } from 'react';
import uniqBy from 'lodash/uniqBy';
import { useSelector } from 'react-redux';

import { Button } from '@Components/ui/styled/main';
import ModalDialog from '@Components/dialogs/modal-dialog';
import { txt } from '@Utils/i18n-util';
import { getFilteredServices } from '@Utils/filter-selectors';

import msg from '@Components/services/custom-fields-editor.msg';

const ModalList = ({ onClose, children, title, description }) => {
  return (
    <ModalDialog
      hideCloseButton
      onClose={onClose}
      contentSize="medium"
    >
      <div className="services-dropdown-modal-list">
        <h4>{title}</h4>
        <div className="alert alert-info">
          {description}
        </div>
        {children}
        <button type="button" className="btn" onClick={onClose}>
          {txt(msg.cancel)}
        </button>
      </div>
    </ModalDialog>
  );
};

export const ServicesDropDown = ({ onClick, disabled, success, filter = () => true, routeParams }) => {
  const services = useSelector((state) => getFilteredServices(state, { routeParams }));
  const [modal, setModal] = useState(false);

  const handleClick = useCallback((service) => {
    onClick && onClick(service);
    setModal(false);
  }, [onClick]);

  return (
    <>
      {modal && (
        <ModalList
          onClose={() => setModal(false)}
          title={txt(msg.copyFieldsFromService)}
          description={txt(msg.copyFieldsFromText)}
        >
          <h5>Tjänster:</h5>
          <ul>
            {Array.from(services.values()).filter(filter).map((service) => (
              <li key={service.id} onClick={() => handleClick(service)}>
                <span
                  className="columns-list-color-dot"
                  style={service.prefs?.colorway && { background: service.prefs?.colorway }}
                />
                {service.name}
                <span>{txt(msg.copy)}</span>
              </li>
            ))}
          </ul>
        </ModalList>
      )}
      <Button small gray success={success} disabled={disabled} onClick={() => setModal(true)}>
        {`${txt(msg.copyFrom)}...`}
      </Button>
    </>
  );
};

export const FieldsDropDown = ({ onClick, disabled, filter = () => true, routeParams }) => {
  const services = useSelector((state) => getFilteredServices(state, { routeParams }));
  const [modal, setModal] = useState(false);
  const customFields = useMemo(() => {
    return uniqBy(Array
      .from(services.values())
      .reduce((prev, { customFields = [] }) => [...prev, ...customFields], [])
      .filter(filter), 'key');
  }, [services, filter]);

  const addNew = useCallback((e, field) => {
    e.preventDefault();
    if (!field) return;
    const newField = {
      helpText: '',
      key: 'new-field',
      label: '',
      placeholder: '',
      required: false,
      type: 'text',
      ...field
    };
    onClick && onClick(newField, !field.key);
    setModal(false);
  }, [customFields]);

  return (
    <>
      {modal && (
        <ModalList
          onClose={() => setModal(false)}
          title={txt(msg.addField)}
          description={txt(msg.addFieldText)}
        >
          <button type="button" className="btn btn-cta-secondary new-field" onClick={(e) => addNew(e, {})}>
            {txt(msg.createNewButton)}
          </button>
          <h5>Lägg till ett tidigare fält:</h5>
          <ul>
            {customFields.map((field) => (
              <li key={field.key} onClick={(e) => addNew(e, field)}>
                {field.label}
                <span>{txt(msg.add)}</span>
              </li>
            ))}
          </ul>
        </ModalList>
      )}
      <Button small gray disabled={disabled} onClick={() => setModal(true)}>
        {txt(msg.addField)}
      </Button>
    </>
  );
};
