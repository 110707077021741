import React from 'react';
import CurrencyUtil from '@Utils/currency-util';
import { txt } from '@Utils/i18n-util';
import msg from './booking-service.msg';

const BookingServiceItem = ({
  id, name, duration, serviceDuration, price, priceFrom, serviceColors
}) => {
  const color = serviceColors ? serviceColors[id] : null;
  const hasDuration = serviceDuration || duration;
  const durationStr = hasDuration ? txt(msg.lblDuration, { duration: serviceDuration || duration }) : null;
  const priceStr = price > 0 ? (priceFrom ? txt(msg.lblPriceFrom) : '') + CurrencyUtil.accountCurrency(price, 0) : null;

  return (
    <div key={id} className="service-item">
      <h4>
        {color && <span className="color-dot" style={{ background: color }} />}
        {name}
      </h4>
      {[durationStr, priceStr].filter(s => s).join(', ')}
    </div>
  );
};

export default BookingServiceItem;
