import moment from 'moment';
import queryString from 'query-string';
import {
  fetchGet,
  checkStatus,
  prefixUrl,
  fetchErrorHandler,
  fetchPost,
  axiosErrorHandler, axiosDefault, fetchDelete, fetchPut
} from '@Utils/ajax-util';
import { getCustomersExportPayload, getDataExportPayload } from '@Components/reports/reports-helpers';
import { batch } from 'react-redux';
import axios from 'axios';

export const GET_BOOKING_LIST = 'GET_BOOKING_LIST';
export const GET_GROUP_BOOKING_LIST = 'GET_GROUP_BOOKING_LIST';
export const GET_BOOKING_REPORT = 'GET_BOOKING_REPORT';
export const GET_SMS_REPORT = 'GET_SMS_REPORT';
export const GET_SALES_REPORT = 'GET_SALES_REPORT';
export const GET_DEFERRED_SALES_REPORT = 'GET_DEFERRED_SALES_REPORT';
export const GET_PRE_PAYMENT_REPORT = 'GET_PRE_PAYMENT_REPORT';
export const GET_GIFT_CARD_REPORT = 'GET_GIFT_CARD_REPORT';
export const GET_REPORT_DATA_FIELDS = 'GET_REPORT_DATA_FIELDS';
export const GET_STAFF_REPORT = 'GET_STAFF_REPORT';
export const GET_STAFF_REPORTS = 'GET_STAFF_REPORTS';
export const SET_STAFF_REPORT_APPROVED = 'SET_STAFF_REPORT_APPROVED';
export const SET_STAFF_REPORT_SUMMARY = 'SET_STAFF_REPORT_SUMMARY';
export const CREATE_STOCK_REPORT = 'CREATE_STOCK_REPORT';
export const DELETE_STOCK_REPORT = 'DELETE_STOCK_REPORT';
export const GET_STOCK_REPORTS = 'GET_STOCK_REPORTS';
export const GET_STOCK_REPORT_ITEMS = 'GET_STOCK_REPORT_ITEMS';
export const GET_REPORT_DATA_OPTIONS = 'GET_REPORT_DATA_OPTIONS';
export const SET_DATA_REPORT_VALUES = 'SET_DATA_PREVIEW_REPORT_VALUES';
export const SET_DATA_EXPORT_REPORT_PREVIEW = 'SET_DATA_EXPORT_REPORT_PREVIEW';
export const SET_DATA_REPORT_PREVIEW_STATUS = 'SET_DATA_REPORT_PREVIEW_STATUS';
export const ABORT_DATA_REPORT_PREVIEW = 'ABORT_DATA_REPORT_PREVIEW';

function getBookingListSuccess(bookingList) {
  return {
    type: GET_BOOKING_LIST,
    state: { bookingList }
  };
}

function getBookingReportSuccess(bookingReport) {
  return {
    type: GET_BOOKING_REPORT,
    state: { bookingReport }
  };
}

function getSmsReportSuccess(sms) {
  return {
    type: GET_SMS_REPORT,
    state: { sms }
  };
}

function getStaffReportSuccess(staff) {
  return {
    type: GET_STAFF_REPORT,
    state: { staff }
  };
}

function getSalesReportSuccess(sales) {
  return {
    type: GET_SALES_REPORT,
    state: { sales }
  };
}

function getDeferredSalesReportSuccess(deferredSales) {
  return {
    type: GET_DEFERRED_SALES_REPORT,
    state: { deferredSales }
  };
}

function getPrePaymentReportSuccess(prePayments) {
  return {
    type: GET_PRE_PAYMENT_REPORT,
    state: { prePayments }
  };
}

function getGiftCardReportSuccess(giftCards) {
  return {
    type: GET_GIFT_CARD_REPORT,
    state: { giftCards }
  };
}

function getStaffReportsSuccess(staffReports) {
  return {
    type: GET_STAFF_REPORTS,
    state: { staffReports }
  };
}

function createStockReportSuccess(report) {
  return {
    type: CREATE_STOCK_REPORT,
    state: { report }
  };
}

function getStockReportsSuccess(stockReports) {
  return {
    type: GET_STOCK_REPORTS,
    state: { stockReports }
  };
}

function getStockReportItemsSuccess(stockReportItems) {
  return {
    type: GET_STOCK_REPORT_ITEMS,
    state: { stockReportItems }
  };
}

function deleteStockReportSuccess(reportId) {
  return {
    type: DELETE_STOCK_REPORT,
    state: { reportId }
  };
}

function getDataExportReportSuccess(payload) {
  return {
    type: SET_DATA_EXPORT_REPORT_PREVIEW,
    payload
  };
}

function setDataReportPreviewStatus(payload) {
  return {
    type: SET_DATA_REPORT_PREVIEW_STATUS,
    payload
  };
}

export function abortDataReportPreview() {
  return {
    type: ABORT_DATA_REPORT_PREVIEW
  };
}

export function onDataReportValuesChange(payload) {
  return {
    type: SET_DATA_REPORT_VALUES,
    payload
  };
}

export function getBookingList(start, end, groupId, resourceId) {
  const url = resourceId
    ? prefixUrl(`/reports/bookings/${resourceId}/${start}/${end}/`)
    : prefixUrl(`/reports/bookings/all/${start}/${end}/`);

  return (dispatch) => {
    return fetch(url, fetchGet())
      .then(res => dispatch(checkStatus(res)))
      .then(res => res.json())
      .then(res => dispatch(getBookingListSuccess(res)))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function getBookingReport(start, end, groupId, resourceId, reportingDate) {
  const query = queryString.stringify({
    resId: resourceId,
    groupId,
    start,
    end,
    reportingDate
  });
  const url = prefixUrl(`/reports/bookings/by-service/?${query}`);
  return (dispatch) => {
    return fetch(url, fetchGet())
      .then(res => dispatch(checkStatus(res)))
      .then(res => res.json())
      .then(res => dispatch(getBookingReportSuccess(res)))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function getSmsReport(start, end) {
  const url = prefixUrl(`/reports/sms/${start}/${end}/`);
  return (dispatch) => {
    return fetch(url, fetchGet())
      .then(res => dispatch(checkStatus(res)))
      .then(res => res.json())
      .then(res => dispatch(getSmsReportSuccess(res)))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

function getSalesReportUrl(context, posOrgId, start, end, userId) {
  const userIdQuery = userId ? `&userId=${userId}` : '';
  return prefixUrl(`/pos/reports/sales/org/${posOrgId}/${context}?start=${start}&end=${end}${userIdQuery}`);
}

export function getSalesReport(posOrgId, start, end, userId) {
  const url = getSalesReportUrl('summary', posOrgId, start, end, userId);
  return (dispatch) => {
    return fetch(url, fetchGet())
      .then(res => dispatch(checkStatus(res)))
      .then(res => res.json())
      .then(res => dispatch(getSalesReportSuccess(res)))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function emailSalesReport(email, posOrgId, start, end, userId) {
  const url = getSalesReportUrl('summary/excel/email', posOrgId, start, end, userId);
  return (dispatch) => {
    return fetch(url, fetchPost({ email }))
      .then(res => dispatch(checkStatus(res)))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function downloadSalesReportExcel(posOrgId, start, end, userId) {
  const url = getSalesReportUrl('summary/excel', posOrgId, start, end, userId);
  return (dispatch) => {
    return fetch(url, fetchPost())
      .then(res => dispatch(checkStatus(res)))
      .then(res => res.json())
      .then(({ url }) => window.open(url, '_self'))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function downloadSalesReportSie(posOrgId, start, end, userId) {
  const url = getSalesReportUrl('sie', posOrgId, start, end, userId);
  return (dispatch) => {
    return fetch(url, fetchPost())
      .then(res => dispatch(checkStatus(res)))
      .then(res => res.json())
      .then(({ url }) => window.open(url, '_self'))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function getDeferredSalesReport(start, end) {
  const query = queryString.stringify({
    start,
    end
  });
  const url = prefixUrl(`/reports/deferred-sales?${query}`);
  return (dispatch) => {
    return fetch(url, fetchGet())
      .then(res => dispatch(checkStatus(res)))
      .then(res => res.json())
      .then(res => dispatch(getDeferredSalesReportSuccess(res)))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function downloadDeferredSalesReportExcel(start, end) {
  const query = queryString.stringify({
    start,
    end
  });
  const url = prefixUrl(`/reports/deferred-sales/report-url?${query}`);
  return (dispatch) => {
    return fetch(url, fetchGet())
      .then(res => dispatch(checkStatus(res)))
      .then(res => res.json())
      .then(({ url }) => window.open(url, '_self'))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function getPrePaymentsReport(start, end) {
  const query = queryString.stringify({
    start,
    end
  });
  const url = prefixUrl(`/reports/payments/pre-payments?${query}`);
  return (dispatch) => {
    return fetch(url, fetchGet())
      .then(res => dispatch(checkStatus(res)))
      .then(res => res.json())
      .then(res => dispatch(getPrePaymentReportSuccess(res)))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function downloadPrePaymentsReportExcel(start, end) {
  const query = queryString.stringify({
    start,
    end
  });
  const url = prefixUrl(`/reports/payments/pre-payments/report-url?${query}`);
  return (dispatch) => {
    return fetch(url, fetchGet())
      .then(res => dispatch(checkStatus(res)))
      .then(res => res.json())
      .then(({ url }) => window.open(url, '_self'))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function getGiftCardReport(posOrgId, date) {
  const url = prefixUrl(`/voucher/org/${posOrgId}/giftcards/report?reportDate=${date}`);
  return (dispatch) => {
    return fetch(url, fetchGet())
      .then(res => dispatch(checkStatus(res)))
      .then(res => res.json())
      .then(res => dispatch(getGiftCardReportSuccess(res)))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function getGiftCardRedeems(posOrgId, start, end) {
  const query = queryString.stringify({
    start,
    end
  });
  const url = prefixUrl(`/voucher/org/${posOrgId}/giftcards/redeemed?${query}`);
  return (dispatch) => {
    return fetch(url, fetchGet())
      .then(res => dispatch(checkStatus(res)))
      .then(res => res.json())
      .then(res => dispatch(getGiftCardReportSuccess(res)))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function getReportDataFields() {
  const url = prefixUrl('/data-export/booking/data-fields/');
  return (dispatch) => {
    return fetch(url, fetchGet())
      .then(res => res.json())
      .then(({ dataFields }) => {
        dispatch({ type: GET_REPORT_DATA_FIELDS, payload: dataFields ?? [] });
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function getReportDataOptions() {
  const url = prefixUrl('/data-export/booking/ref-data/');
  return (dispatch) => {
    return fetch(url, fetchGet())
      .then(res => res.json())
      .then((res) => {
        dispatch({ type: GET_REPORT_DATA_OPTIONS, payload: res });
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function getReportDataPreview() {
  const url = prefixUrl('/data-export/booking/preview/');
  return (dispatch, getState) => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const payload = getDataExportPayload(getState());
    const config = axiosDefault({ cancelToken: source.token });

    batch(() => {
      dispatch(getDataExportReportSuccess([]));
      dispatch(setDataReportPreviewStatus({ reportPreviewPending: true, source }));
    });

    return axios
      .post(url, payload, config)
      .then(res => res.data ?? {})
      .then(({ result }) => dispatch(getDataExportReportSuccess(result)))
      .catch(error => axiosErrorHandler(error, dispatch))
      .finally(() => dispatch(setDataReportPreviewStatus({ reportPreviewPending: false, source: null })));
  };
}

export function downloadDataExportExcel(fileType) {
  const url = prefixUrl('/data-export/booking/report-url');
  return (dispatch, getState) => {
    const payload = getDataExportPayload(getState());
    return fetch(url, fetchPost({ ...payload, csv: fileType === 'CSV' }))
      .then(res => dispatch(checkStatus(res)))
      .then(res => res.json())
      .then(({ url }) => window.open(url, '_self'))
      .catch(error => {
        if (error?.response?.status === 403) {
          throw error;
        }
        dispatch(fetchErrorHandler(error));
      });
  };
}

export function getCustomerReportPreview() {
  const url = prefixUrl('/data-export/customer/preview/');
  return (dispatch, getState) => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const payload = getCustomersExportPayload(getState());
    const config = axiosDefault({ cancelToken: source.token });
    batch(() => {
      dispatch(getDataExportReportSuccess([]));
      dispatch(setDataReportPreviewStatus({ reportPreviewPending: true, source }));
    });

    return axios
      .post(url, { ...payload, limit: 100 }, config)
      .then(res => res.data ?? {})
      .then(({ result }) => dispatch(getDataExportReportSuccess(result)))
      .catch(error => axiosErrorHandler(error, dispatch))
      .finally(() => dispatch(setDataReportPreviewStatus({ reportPreviewPending: false, source: null })));
  };
}

export function downloadCustomerReportExcel(fileType) {
  const url = prefixUrl('/data-export/customer/report-url');
  return (dispatch, getState) => {
    const payload = getCustomersExportPayload(getState());
    return fetch(url, fetchPost({ ...payload, csv: fileType === 'CSV' }))
      .then(res => dispatch(checkStatus(res)))
      .then(res => res.json())
      .then(({ url }) => window.open(url, '_self'))
      .catch(error => {
        if (error?.response?.status === 403) {
          throw error;
        }
        dispatch(fetchErrorHandler(error));
      });
  };
}

export function downloadStaffReportPdf(reportId) {
  const url = prefixUrl(`/reports/staff-hours/reports/${reportId}/pdf`);
  return (dispatch) => {
    return fetch(url, fetchPost())
      .then(res => dispatch(checkStatus(res)))
      .then(res => res.json())
      .then(({ url }) => window.open(url, '_self'))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function getStaffReport(start, end) {
  const query = queryString.stringify({
    start,
    end
  });
  const url = prefixUrl(`/reports/staff-hours/snapshot-data/?${query}`);
  return (dispatch) => {
    return fetch(url, fetchGet())
      .then(res => dispatch(checkStatus(res)))
      .then(res => res.json())
      .then(res => dispatch(getStaffReportSuccess(res)))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function createStaffReport(report) {
  const url = prefixUrl('/reports/staff-hours/create-report');
  return (dispatch) => {
    return fetch(url, fetchPost(report))
      .then(res => dispatch(checkStatus(res)))
      .then(res => res.json())
      .then(res => {
        dispatch(getStaffReports());
        return res.reportId;
      })
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function getStaffReports() {
  const query = queryString.stringify({
    start: moment().subtract(2, 'y').startOf('M').format('YYYY-MM-DD'),
    end: moment().endOf('M').format('YYYY-MM-DD')
  });
  const url = prefixUrl(`/reports/staff-hours/reports/?${query}`);
  return (dispatch) => {
    return fetch(url, fetchGet())
      .then(res => dispatch(checkStatus(res)))
      .then(res => res.json())
      .then(({ reports }) => dispatch(getStaffReportsSuccess(reports)))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function getStaffReportData(reportId, rowId) {
  const url = prefixUrl(`/reports/staff-hours/reports/${reportId}/row/${rowId}/data`);
  return (dispatch) => {
    return fetch(url, fetchGet())
      .then(res => dispatch(checkStatus(res)))
      .then(res => res.json())
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function updateStaffReportData(reportId, rowId, data) {
  const url = prefixUrl(`/reports/staff-hours/reports/${reportId}/row/${rowId}/update`);
  return (dispatch) => {
    return fetch(url, fetchPost(data))
      .then(res => dispatch(checkStatus(res)))
      .then(res => res.json())
      .then(({ summary }) => dispatch({ type: SET_STAFF_REPORT_SUMMARY, reportId, rowId, summary }))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function approveStaffReportData(reportId, rowId, approve) {
  const verb = approve ? 'approve' : 'unapprove';
  const approvedTs = approve ? moment().toISOString() : null;
  const url = prefixUrl(`/reports/staff-hours/reports/${reportId}/row/${rowId}/${verb}`);
  return (dispatch) => {
    return fetch(url, fetchPost())
      .then(res => dispatch(checkStatus(res)))
      .then(() => dispatch({ type: SET_STAFF_REPORT_APPROVED, reportId, rowId, approvedTs }))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function deleteStaffReport(reportId) {
  const url = prefixUrl(`/reports/staff-hours/reports/${reportId}`);
  return (dispatch) => {
    return fetch(url, fetchDelete())
      .then(res => dispatch(checkStatus(res)))
      .then(res => dispatch(getStaffReports()))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function createStockReport(report) {
  const url = prefixUrl('/inventory/reports/create');
  return (dispatch) => {
    return fetch(url, fetchPost(report))
      .then(res => dispatch(checkStatus(res)))
      .then(res => res.json())
      .then(res => {
        dispatch(createStockReportSuccess(res));
        return res.id;
      })
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function getStockReports() {
  const url = prefixUrl('/inventory/reports/');
  return (dispatch) => {
    return fetch(url, fetchGet())
      .then(res => dispatch(checkStatus(res)))
      .then(res => res.json())
      .then(({ items }) => dispatch(getStockReportsSuccess(items)))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function getStockReportItems(reportId) {
  const url = prefixUrl(`/inventory/reports/${reportId}/items/`);
  return (dispatch) => {
    return fetch(url, fetchGet())
      .then(res => dispatch(checkStatus(res)))
      .then(res => res.json())
      .then(({ items }) => dispatch(getStockReportItemsSuccess(items)))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function deleteStockReport(reportId) {
  const url = prefixUrl(`/inventory/reports/${reportId}`);
  return (dispatch) => {
    return fetch(url, fetchDelete())
      .then(res => dispatch(checkStatus(res)))
      .then(() => dispatch(deleteStockReportSuccess(reportId)))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}
