import React, { useState } from 'react';
import styled from 'styled-components';
import { Field, Form } from 'react-final-form';
import { composeValidators, maxValue, minValue, required } from '@Utils/validation';
import FormButtonsFooter from '@Components/ui/form-buttons-footer';
import DatePickerField from '@Components/ui/date-picker/date-picker-field';
import TextInput from '@Components/inputs/text-input';
import ConfirmPopover from '@Components/ui/confirm-popover';
import Button from '@Components/ui/button';

const FormButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const GiftCardEditForm = ({ onSubmit, onClose, onVoid, giftCard, initialValues }) => {
  const { id, code, initialValue } = giftCard;
  const [showConfirmVoid, setShowConfirmVoid] = useState(false);

  return (
    <Form onSubmit={onSubmit} initialValues={initialValues}>
      {({ handleSubmit, submitting, submitSucceeded }) => (
        <form autoComplete="off" className="dialog" onSubmit={handleSubmit}>
          <Field
            name="remaining"
            component={TextInput}
            label="Värde kvar"
            validate={composeValidators(required, minValue(0), maxValue(initialValue))}
          />
          <Field
            name="noRedeemAfter"
            component={DatePickerField}
            label="Giltigt t.o.m."
          />
          <FormButtonsContainer>
            {onVoid ? (
              <ConfirmPopover
                text={`Makulera presentkort med kod ${code}?`}
                confirmText="Makulera"
                onClose={() => setShowConfirmVoid(false)}
                onConfirm={() => onVoid(id)}
                isOpen={showConfirmVoid}
              >
                <Button small danger onClick={() => setShowConfirmVoid(true)}>Makulera</Button>
              </ConfirmPopover>
            ) : <div />}
            <FormButtonsFooter
              onClose={onClose}
              submitting={submitting}
              submitSucceeded={submitSucceeded}
              handleSubmit={handleSubmit}
              submitText="Spara"
            />
          </FormButtonsContainer>
        </form>
      )}
    </Form>
  );
};

export default GiftCardEditForm;
