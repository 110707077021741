import { createSelector } from 'reselect';

export const AppVersionEditSchedule = '1.1.4';
export const AppVersionMultiResource = '1.2.0';

function compareVersions(a, b) {
  // 0: version strings are equal
  // 1: version a is greater than b
  // -1: version b is greater than a
  return a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' });
}

export function isMinVersion(embedVersion, version) {
  return embedVersion && compareVersions(embedVersion, version) !== -1;
}

export const isEmbeddedOrWrapped = state => !!state.appState.get('embedMode');
export const isEmbeddedInExternalPos = state => state.appState.get('embedMode') === 'externalPos';
export const isEmbeddedInApp = state => state.appState.get('embedMode') === 'calendar';
export const isWrappedInApp = state => state.appState.get('embedMode') === 'full';
export const getEmbedVersion = state => state.appState.get('embedVersion');
export const getShowMainNav = state => state.appState.get('mainNav') !== 'false';

const getEmbedTopIndent = state => state.appState.get('embedTopIndent');
const getEmbedBottomIndent = state => state.appState.get('embedBottomIndent');
const getGridClientHeight = state => state.gridViewState.get('gridClientHeight');

const defaultTopIndent = 90;
const defaultBottomIndent = 90;
const columnHeaderHeight = 35;

export const getGridContainerStyle = createSelector(
  getGridClientHeight, isEmbeddedInApp, getEmbedTopIndent, getEmbedBottomIndent,
  (height, embeddedInApp, embedTopIndent, embedBottomIndent) => {
    const embeddedStyle = {
      paddingTop: `${parseInt(embedTopIndent || defaultTopIndent) + columnHeaderHeight}px`,
      paddingBottom: `${embedBottomIndent || defaultBottomIndent}px`
    };
    return embeddedInApp ? { height, ...embeddedStyle } : { height };
  }
);

export const getGridHeaderContainerStyle = createSelector(
  isEmbeddedInApp, getEmbedTopIndent,
  (embeddedInApp, embedTopIndent) => {
    return embeddedInApp ? {
      position: 'absolute',
      width: '100%',
      top: `${embedTopIndent || defaultTopIndent}px`,
      zIndex: 99999,
      background: 'rgba(255, 255, 255, 0.7)',
      WebkitBackdropFilter: 'blur(6px)',
      backdropFilter: 'blur(6px)'
    } : null;
  }
);

export const getColumnHeadersStyle = createSelector(
  isEmbeddedInApp,
  (embeddedInApp) => {
    return embeddedInApp ? {
      background: 'transparent'
    } : null;
  }
);

export const getClipboardBannerStyle = createSelector(
  state => state.bookingsClipboard.isEmpty(),
  isEmbeddedInApp,
  (hideClipboard, embeddedInApp) => {
    return embeddedInApp ? {
      height: hideClipboard ? 0 : undefined,
      background: 'transparent'
    } : null;
  }
);
