import { createSelector } from 'reselect';
import {
  hasPermission, CHANGE_LOCATION_SERVICES, CHANGE_RESOURCES, CHANGE_LOCATION_SETTINGS, POS_ADMIN, ADMINISTRATE_LOC_USERS
} from '@Utils/permissions';
import {
  getFeatures, getPermissions, getTrialStatus, getAccountStatus, getSysAdmin, getWebPaymentEnabled, getChangeJsonPrefsPermission
} from '@State/selectors';
import { actionRequired } from '@Utils/account';

export const getAdminNavSections = createSelector(
  getPermissions,
  getTrialStatus,
  getAccountStatus,
  getFeatures,
  getWebPaymentEnabled,
  getSysAdmin,
  getChangeJsonPrefsPermission,
  (permissions, trialStatus, accountStatus, features, paymentEnabled, isSysAdmin, changeJsonPrefs) => {
    const sections = [];
    const hasPosPermission = hasPermission(permissions, POS_ADMIN);
    const hasSettingsPermission = hasPermission(permissions, CHANGE_LOCATION_SETTINGS);

    if (features.ShowServicesSettings && hasPermission(permissions, CHANGE_LOCATION_SERVICES)) {
      sections.push({
        id: 'services', group: 'Bokning', name: 'Tjänster', icon: 'fa-cut', helpText: 'Behandlingar, priser m.m.'
      });
      sections.push({
        id: 'addon-services', group: 'Bokning', name: 'Tilläggstjänster', icon: 'fa-cart-plus'
      });
    }
    if (features.ShowResourcesSettings && hasPermission(permissions, CHANGE_RESOURCES)) {
      sections.push({
        id: 'resources', group: 'Bokning', name: 'Resurser', icon: 'fa-calendar-alt', helpText: 'Personer, utrustning, rum'
      });
    }
    if (features.ShowUserSettings && hasPermission(permissions, ADMINISTRATE_LOC_USERS)) {
      sections.push({
        id: 'users', group: 'Bokning', name: 'Personal', icon: 'fa-users', helpText: 'Inloggning & behörigheter'
      });
    }
    if (features.ShowResourcesSettings && hasPermission(permissions, CHANGE_RESOURCES)) {
      sections.push({
        id: 'schedules', group: 'Bokning', name: 'Scheman', icon: 'fa-calendar-week'
      });
    }

    if (features.ShowBookingSettings && hasSettingsPermission) {
      sections.push({
        id: 'booking-info', group: 'Onlinebokning', name: 'Bokningssida / Widget', icon: 'fa-globe', news: true
      });
      sections.push({
        id: 'company-info', group: 'Onlinebokning', name: 'Kontaktuppgifter', icon: 'fa-home'
      });
      sections.push({
        id: 'web-booking', group: 'Onlinebokning', name: 'Inställningar', icon: 'fa-wrench'
      });
    }
    if (paymentEnabled && hasSettingsPermission) {
      sections.push({
        id: 'payment', group: 'Onlinebokning', name: 'Betalning', icon: 'fa-credit-card', helpText: 'Inställningar för kvitto m.m.'
      });
    }
    if (paymentEnabled && features.EnableDiscountVouchers && hasSettingsPermission) {
      sections.push({
        id: 'vouchers', group: 'Onlinebokning', name: 'Rabattkoder', icon: 'fa-tag'
      });
    }

    if (features.ShowSMSSettings && hasSettingsPermission) {
      sections.push({
        id: 'sms', group: 'Kommunikation', name: 'SMS', icon: 'fa-mobile-alt', helpText: 'Påminnelser, bekräftelser'
      });
    }
    if (features.ShowEmailSettings && hasSettingsPermission) {
      sections.push({
        id: 'email', group: 'Kommunikation', name: 'Mejl', icon: 'fa-envelope'
      });
    }
    if (features.ShowNotificationSettings && hasSettingsPermission) {
      sections.push({
        id: 'notifications', group: 'Kommunikation', name: 'Notiser', icon: 'fa-bell'
      });
    }

    if ((features.EnablePOS || paymentEnabled) && hasSettingsPermission) {
      sections.push({
        id: 'pos', group: 'Kassa', name: 'Kassaregister', icon: 'fa-cash-register'
      });
    }
    if (features.EnablePOS && hasSettingsPermission) {
      sections.push({
        id: 'pos-config', group: 'Kassa', name: 'Inställningar', icon: 'fa-wrench'
      });
    }
    if (features.EnablePOS && hasPosPermission) {
      sections.push({
        id: 'gift-cards', group: 'Kassa', name: 'Presentkort', icon: 'fa-gift'
      });
      sections.push({
        id: 'voucher-templates', group: 'Kassa', name: 'Presentkortsmallar', icon: 'fa-gifts'
      });
      sections.push({
        id: 'invoices', group: 'Kassa', name: 'Fakturor', icon: 'fa-file-alt'
      });
      if (isSysAdmin) {
        sections.push({
          id: 'printers', group: 'Kassa', name: 'Skrivare', icon: 'fa-print'
        });
        sections.push({
          id: 'terminals', group: 'Kassa', name: 'Terminaler', icon: 'fa-calculator'
        });
      }
      sections.push({
        id: 'products', group: 'Lager', name: 'Produkter', icon: 'fa-cubes'
      });
      sections.push({
        id: 'delivery', group: 'Lager', name: 'Inleverans', icon: 'fa-truck'
      });
      sections.push({
        id: 'stock-taking', group: 'Lager', name: 'Inventering', icon: 'fa-box-open'
      });
    }

    if (features.ShowPayments && hasSettingsPermission) {
      const notification = actionRequired(trialStatus, accountStatus) ? '!' : null;
      sections.push({
        id: 'subscription', group: 'Konto', name: 'Abonnemang', icon: 'fa-cloud', notification, helpText: 'Kvitton, betalning m.m.'
      });
    }
    if (features.ShowAddons && hasSettingsPermission) {
      sections.push({
        id: 'features', group: 'Konto', name: 'Tillval', icon: 'fa-puzzle-piece'
      });
    }
    if (features.ShowGeneralSettings && hasSettingsPermission) {
      sections.push({
        id: 'storage', group: 'Konto', name: 'Personuppgifter', icon: 'fa-save', helpText: 'GDPR och personuppgiftshantering'
      });
      sections.push({
        id: 'contacts', group: 'Konto', name: 'Kontakter & villkor', icon: 'fa-asterisk'
      });
    }

    if (changeJsonPrefs) {
      sections.push({
        id: 'json-prefs', group: 'System Admin', name: 'Avancerat', icon: 'fa-brackets-curly', helpText: 'Tidszon, Locale, Features, Prefs m.m.'
      });
    }

    if (isSysAdmin) {
      sections.push({
        id: 'partners', group: 'System Admin', name: 'Partners', icon: 'fa-hands-helping'
      });
    }

    return sections;
  }
);
