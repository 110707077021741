import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { colors } from '@Components/ui/styled/variables';

const StyledBlock = styled.div`
  line-height: 20px;
  padding: 8px 15px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  background-color: ${colors.brandBlue};

  ${props => props.warning && css`
    background-color: ${colors.bgWarning};
    color: #000;
  `}
  ${props => props.danger && css`
    background-color: ${colors.danger};
    color: #fff;
  `}
`;

const NotificationBanner = ({ text, ...props }) => (
  <StyledBlock {...props}>{text}</StyledBlock>
);

NotificationBanner.propTypes = {
  text: PropTypes.string
};

export default NotificationBanner;
