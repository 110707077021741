import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPosOrg } from '@State/pos-selectors';
import { getInvoiceUseFortnoxService } from '@Utils/invoice-util';
import { refreshInvoiceStatus } from '@State/invoice-actions';
import ModalDialog from '@Components/dialogs/modal-dialog';
import AlertWithIcon from '@Components/ui/alert-with-icon';
import InvoiceSummaryPanel from './invoice-summary-panel';

function getTitle(invoiceSent, invoiceNumber) {
  const verb = invoiceSent ? 'skickad' : 'skapad';

  return invoiceNumber
    ? `Faktura ${invoiceNumber} ${verb}`
    : `Faktura ${verb}`;
}

const InvoiceReceiptModal = ({
  totalAmount, grossAmount, saleItems, invoiceId, invoiceUrl, onSubmit, invoice
}) => {
  const dispatch = useDispatch();
  const posOrg = useSelector(getPosOrg);
  const invoiceMethod = useSelector(state => state.posSale.get('invoiceMethod'));
  const invoiceWarnings = useSelector(state => state.posSale.get('invoiceWarnings'));
  const invoiceNumber = useSelector(state => state.posSale.get('invoiceNumber'));
  const useFortnoxService = useSelector(getInvoiceUseFortnoxService);
  const waitingForFortnox = useFortnoxService && !invoiceWarnings && invoiceId && !invoiceUrl;
  const hasInvoiceWarnings = invoiceWarnings?.size > 0;

  const useEFaktura = invoiceMethod === 'EFaktura';
  const useEmail = invoiceMethod === 'Email' && invoice.emailTo?.email;
  const invoiceSent = !hasInvoiceWarnings && (useEFaktura || useEmail);

  useEffect(() => {
    if (waitingForFortnox) {
      const interval = setInterval(() => {
        dispatch(refreshInvoiceStatus(posOrg.get('id'), invoiceId));
      }, 3000);

      return () => clearInterval(interval);
    }
  }, [waitingForFortnox]);

  return (
    <ModalDialog
      title={getTitle(invoiceSent, invoiceNumber)}
      contentSize="medium"
      className="dialog"
      hideCloseButton
      buttons={[waitingForFortnox && {
        name: 'Väntar på Fortnox...',
        gray: true,
        disabled: true
      }, invoiceUrl && {
        name: 'Ladda ner faktura',
        gray: true,
        onClick: () => window.open(invoiceUrl)
      }, {
        name: 'Klar',
        primary: true,
        onClick: () => onSubmit()
      }]}
    >
      {useEFaktura && !hasInvoiceWarnings && (
        <AlertWithIcon icon="fa fa-info-circle">
          Fakturan skickas som E-faktura.
        </AlertWithIcon>
      )}
      {useEmail && !hasInvoiceWarnings && (
        <AlertWithIcon icon="far fa-envelope">
          Fakturan skickas med e-post till {invoice.emailTo?.email}.
        </AlertWithIcon>
      )}
      {hasInvoiceWarnings && (
        <AlertWithIcon warning icon="fa fa-exclamation-triangle">
          <p>Fakturan har skapats men kunde inte skickas.</p>
          <ul>
            {invoiceWarnings.valueSeq().map((warning) => (
              <li>{warning}</li>
            ))}
          </ul>
        </AlertWithIcon>
      )}
      <InvoiceSummaryPanel
        grossAmount={grossAmount}
        totalAmount={totalAmount}
        saleItems={saleItems}
      />
    </ModalDialog>
  );
};

export default InvoiceReceiptModal;
