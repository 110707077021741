import React from 'react';
import queryString from 'query-string';
import { getSectionUrl, navigate } from '@Utils/navigate';
import DialogAlert from './dialog-alert';

const StripeAuth = ({ location, match }) => {
  const { posOrgId, status, msg } = queryString.parse(location.search);

  const onClose = () => {
    navigate(getSectionUrl(match.params, 'admin/pos', posOrgId ? `${posOrgId}/settings` : null));
  };

  return status === 'success' ? (
    <DialogAlert
      success
      icon="fa fa-check-circle"
      text="Koppling till Stripe aktiverad"
      onClose={onClose}
      closeButtonText="OK"
    />
  ) : (
    <DialogAlert
      warning
      icon="fa fa-exclamation-triangle"
      text={(
        <div>
          <p>Koppling till Stripe kunde inte aktiveras</p>
          <small className="text-danger">{msg}</small>
        </div>
      )}
      onClose={onClose}
      closeButtonText="OK"
    />
  );
};

export default StripeAuth;
