import React, { useCallback, useEffect, useState } from 'react';
import useIsMounted from '@Hooks/useIsMounted';
import { Button } from '@Components/ui/styled/main';
import ModalDialog from '@Components/dialogs/modal-dialog';
import AlertWithIcon from '@Components/ui/alert-with-icon';
import Label from '@Components/ui/label';

const ButtonModalForm = ({
  dialogProps = {},
  label = '',
  link = null,
  btnText = null,
  showModal = false,
  onShowModal = null,
  hideModalOnSubmit = true,
  onSubmit,
  children,
  onClose = null,
  marginRight = true,
  gray = true,
  small = true,
  ...rest
}) => {
  const isMounted = useIsMounted();
  const [showModalState, setShowModalState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setShowModalState(showModal);
  }, [showModal]);

  const onHideModal = useCallback(() => {
    if (onClose) onClose();
    setShowModalState(false);
    setError(null);
  }, [onClose]);

  const handleShowModal = useCallback((ev) => {
    ev.preventDefault();
    onShowModal && onShowModal();
    setShowModalState(true);
  }, [onShowModal]);

  const onSubmitForm = useCallback(async (values) => {
    if (!onSubmit) {
      onHideModal();
      return;
    }
    setLoading(true);
    setError(null);
    return onSubmit(values)
      .then(() => {
        if (hideModalOnSubmit && isMounted()) {
          onHideModal();
        }
      })
      .catch((error) => {
        setError(error.message);
        console.error(error);
      })
      .finally(() => {
        if (isMounted()) {
          setLoading(false);
        }
      });
  }, [onSubmit]);

  return (
    <>
      {showModalState && (
        <ModalDialog onClose={onHideModal} {...dialogProps}>
          {error && <AlertWithIcon error>{error}</AlertWithIcon>}
          <div>
            {typeof children === 'function'
              ? children({ onClose: onHideModal, onSubmit: onSubmitForm, loading })
              : React.cloneElement(children, ({ onClose: onHideModal, onSubmit: onSubmitForm, loading }))}
          </div>
        </ModalDialog>
      )}
      {label && (
        <Label onClick={handleShowModal} {...rest} bgColor={label}>{btnText}</Label>
      )}
      {link && (
        <a href="#" onClick={handleShowModal} {...rest}>{btnText}</a>
      )}
      {!label && !link ? (
        <Button marginRight={marginRight} gray={gray} small={small} onClick={handleShowModal} {...rest}>
          {btnText}
        </Button>
      ) : null}
    </>
  );
};

export default ButtonModalForm;
