import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LabelSwitchInput from '@Components/ui/label-switch-input';
import { toggleMultiResourceMode } from '@State/view-actions';
import { getSingleResourceModeUrl } from '@State/calendar-selectors';
import { navigate } from '@Utils/navigate';
import InfoDialogButton from '@Components/dialogs/info-dialog-button';

const InfoDialogContent = () => (
  <>
    <p>
      Välj flera resurser genom att klicka på respektive resurs, eller klicka på gruppnamnet för att välja alla resurser i gruppen.
    </p>
    <p>
      I dagvy visas varje resurs i en egen kolumn, i veckovy visas alla bokningar i samma kalender.
    </p>
    <p>
      För musen över en bokning för att visa vilken/vilka resurser den hör till.
    </p>
  </>
);

const MultiResourceToggle = ({ routeParams }) => {
  const dispatch = useDispatch();
  const { viewMode, entityType } = routeParams;
  const multiResourceMode = useSelector(state => state.calendarViewState.get('multiResourceMode'));
  const singleResourceModeUrl = useSelector(state => getSingleResourceModeUrl(state, { routeParams }));

  useEffect(() => {
    if (viewMode === 'week' && entityType === 'group' && !multiResourceMode) {
      dispatch(toggleMultiResourceMode(true));
    }
  }, [viewMode, entityType]);

  const handleToggle = useCallback(() => {
    if (multiResourceMode && singleResourceModeUrl) {
      navigate(singleResourceModeUrl);
    }
    dispatch(toggleMultiResourceMode(!multiResourceMode));
  }, [multiResourceMode, singleResourceModeUrl]);

  return (
    <div className="multi-resource-toggle">
      <LabelSwitchInput
        noBorder
        labelClass="text-muted"
        label={(
          <>
            Välj flera resurser
            <InfoDialogButton title="Multiresursvy">
              <InfoDialogContent />
            </InfoDialogButton>
          </>
        )}
        id="multiResourceMode"
        isOn={multiResourceMode}
        handleToggle={handleToggle}
      />
    </div>
  );
};

export default MultiResourceToggle;
