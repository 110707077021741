import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { fetchResourceServices } from '@State/bkf/actions';
import SelectField from '@Components/inputs/select-field';
import DatePickerField from '@Components/ui/date-picker/date-picker-field';
import TextInput from '@Components/inputs/text-input';
import { txt } from '@Utils/i18n-util';
import { styleHiddenInput } from './style';
import msg from './booking-custom-fields.msg';

function getCustomFieldsOptions(services, key) {
  let options = null;
  services?.every(service => {
    const customFields = service.customFields?.find(field => field.key === key);
    if (customFields?.options?.length > 0) {
      options = customFields.options;
      return false;
    }
    return true;
  });
  return options;
}

class BookingCustomFields extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editCustomFields: false
    };
  }

  focusHiddenField = () => {
    if (this.hidden) {
      const hidden = ReactDOM.findDOMNode(this.hidden);
      hidden.style.visibility = 'visible';
      hidden.focus();
      hidden.style.visibility = 'hidden';
    }
  };

  handleClick = (ev) => {
    ev.preventDefault();
    this.focusHiddenField();
    this.setState({ editCustomFields: true });

    if (!this.props.resourceServices) {
      this.props.fetchResourceServices(this.props.resourceId);
    }
  };

  handleClose = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    this.setState({ editCustomFields: false });
  };

  renderHiddenInput() {
    return <input ref={(ref) => { this.hidden = ref; }} style={styleHiddenInput} />;
  }

  getValueText = (value) => {
    if (value === true || value === 'true') {
      return <i className="fas fa-square-check text-highlight" />;
    }
    if (!value) {
      return '';
    }
    return value;
  };

  getCustomField = (key, type, label) => {
    if (type === 'checkbox') {
      return (
        <div className="form-group">
          <div className="checkbox">
            <label>
              <Field name={key} component="input" type="checkbox" />
              {label}
            </label>
          </div>
        </div>
      );
    }
    if (type === 'date') {
      return (
        <Field name={key} label={label} component={DatePickerField} />
      );
    }
    if (type === 'select') {
      const options = getCustomFieldsOptions(this.props.resourceServices, key);

      if (options) {
        return (
          <Field name={key} label={label} component={SelectField}>
            {options.map((option) => {
              return <option key={option} value={option}>{option}</option>;
            })}
          </Field>
        );
      }
    }
    return (
      <Field name={key} label={label} component={TextInput} type="text" />
    );
  };

  render() {
    const { editCustomFields } = this.state;
    const { customFields } = this.props;

    if (!customFields || customFields.length === 0) {
      return null;
    }

    if (editCustomFields) {
      return (
        <div className="booking-form-block form">
          {customFields.map(({ key, type, label }) => {
            return (
              <div className="row tight" key={key}>
                {this.getCustomField(key, type, label)}
              </div>
            );
          })}
          <div className="text-right">
            <button className="btn-label" tabIndex={-1} onClick={this.handleClose}>{txt(msg.btnClose)}</button>
          </div>
        </div>
      );
    }

    return (
      <div className="booking-form-block" onClick={this.handleClick}>
        {customFields.map(({ key, label }, index) => {
          return (
            <div className={`${index % 2 === 0 ? '' : 'odd'} custom-field`} key={key}>
              <span className="custom-field-label text-muted">{label}</span>
              <span className="custom-field-value">{this.getValueText(this.props[key])}</span>
            </div>
          );
        })}
        {this.renderHiddenInput()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { bkf, resourceServices } = state;
  const resourceId = bkf.get('resourceId');
  const customFields = bkf.get('customFields');
  const customValues = bkf.get('customValues');

  return {
    id: bkf.get('id'),
    resourceId,
    resourceServices: resourceServices.get(resourceId),
    customFields,
    ...customValues,
    initialValues: customValues,
    ...getFormValues('bkf-custom-fields')(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchResourceServices: (resId) => {
      return dispatch(fetchResourceServices(resId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'bkf-custom-fields',
  destroyOnUnmount: false
})(BookingCustomFields));
