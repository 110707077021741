import React, { useState } from 'react';
import ModalDialog from '@Components/dialogs/modal-dialog';
import DialogLoader from '@Components/dialogs/dialog-loader';
import SelectParkedSaleModal from './select-parked-sale-modal';

const SaleParkingModal = ({ parkedSales, isSaleEmpty, onPark, onUnpark, onClose }) => {
  const [state, setState] = useState('');
  const [progress, setProgress] = useState(false);

  const showParkedSales = () => setState('parkedSales');
  const onCancel = () => setState('');

  const handleUnpark = (sale) => {
    setProgress(true);
    return onUnpark(sale)
      .catch(() => setProgress(false));
  };

  const handlePark = () => {
    setProgress(true);
    return onPark()
      .catch(() => setProgress(false));
  };

  if (progress) {
    return (
      <DialogLoader
        title={state === 'parkedSales' ? 'Hämtar kvitto...' : 'Parkerar kvitto...'}
      />
    );
  }

  if (state === 'parkedSales') {
    return (
      <SelectParkedSaleModal
        isSaleEmpty={isSaleEmpty}
        parkedSales={parkedSales}
        onSelect={handleUnpark}
        onClose={onCancel}
      />
    );
  }

  return (
    <ModalDialog
      title="Parkera kvitto?"
      onClose={onClose}
      buttons={[{
        name: 'Parkera',
        primary: true,
        onClick: handlePark,
        disabled: isSaleEmpty
      }, {
        name: 'Hämta parkerat',
        gray: true,
        onClick: showParkedSales,
        disabled: parkedSales.isEmpty()
      }]}
    />
  );
};

export default SaleParkingModal;
