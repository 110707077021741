import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSysAdmin } from '@State/selectors';
import { deletePrinter, mergePosUnitPrefs } from '@State/pos-config-actions';
import { Button } from '@Components/ui/styled/main';
import ProgressButton from '@Components/ui/progress-button';
import Popover from '@Components/ui/popover';
import { pos } from '@Utils/preference-keys';

const PrinterTools = ({ printer, vunitId }) => {
  const dispatch = useDispatch();
  const isSysAdmin = useSelector(getSysAdmin);
  const [showTools, setShowTools] = useState(false);

  const handleDisassociate = () => {
    return dispatch(mergePosUnitPrefs(vunitId, {
      [pos.defaultPrinterId]: null
    }));
  };

  const handleDelete = () => dispatch(deletePrinter(printer.get('macAddress')));

  const toolsPopoverBody = (
    <div className="Popover-dialog-sm button-wrapper">
      <div className="menu-button-wrapper">
        {vunitId && <ProgressButton small block danger onClick={handleDisassociate}>Koppla bort från kassa</ProgressButton>}
        {!vunitId && <ProgressButton small block danger onClick={handleDelete}>Ta bort skrivare</ProgressButton>}
      </div>
    </div>
  );

  return isSysAdmin ? (
    <Popover
      preferPlace="below"
      isOpen={showTools}
      onOuterAction={() => setShowTools(false)}
      body={toolsPopoverBody}
      className="Popover-big-radius"
    >
      <Button outlinePrimary small marginLeft onClick={() => setShowTools(!showTools)}>
        <i className="far fa-cog" />
      </Button>
    </Popover>
  ) : null;
};

export default PrinterTools;
