import {
  checkStatus, fetchErrorHandler, fetchGet, fetchPut, fetchPost, prefixUrl
} from '@Utils/ajax-util';

export const ADMIN_CONFIG_LOADED = 'ADMIN_CONFIG_LOADED';
export const PARTNERS_LOADED = 'PARTNERS_LOADED';
export const PARTNER_PREFS_UPDATED = 'PARTNER_PREFS_UPDATED';
export const PARTNER_CUSTOM_TEXTS_UPDATED = 'PARTNER_CUSTOM_TEXTS_UPDATED';

function adminConfigLoaded(config) {
  return {
    type: ADMIN_CONFIG_LOADED,
    config
  };
}

export function getAdminConfig() {
  const url = prefixUrl('/location/admin-configuration');

  return (dispatch) => {
    return fetch(url, fetchGet())
      .then(res => dispatch(checkStatus(res)))
      .then(res => res.json())
      .then(res => dispatch(adminConfigLoaded(res)))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function fetchPartners() {
  const url = prefixUrl('/partners/');

  return (dispatch) => {
    return fetch(url, fetchGet())
      .then(res => dispatch(checkStatus(res)))
      .then(res => res.json())
      .then((partners) => dispatch({ type: PARTNERS_LOADED, partners }))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function replacePartnerPreferences(id, prefs) {
  return (dispatch) => {
    return fetch(prefixUrl(`/partners/${id}/prefs/`), fetchPut(prefs))
      .then(res => dispatch(checkStatus(res)))
      .then(res => dispatch({ type: PARTNER_PREFS_UPDATED, id, prefs }))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function replacePartnerCustomTexts(id, customTexts) {
  return (dispatch) => {
    return fetch(prefixUrl(`/partners/${id}/custom-texts/`), fetchPut(customTexts))
      .then(res => dispatch(checkStatus(res)))
      .then(res => dispatch({ type: PARTNER_CUSTOM_TEXTS_UPDATED, id, customTexts }))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function restoreAutoDeleted() {
  const url = prefixUrl('/storage/restore-auto-deleted');

  return (dispatch) => {
    return fetch(url, fetchPost())
      .then(res => dispatch(checkStatus(res)))
      .then(res => res.json())
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function fetchNotificationSubscriptions() {
  const url = prefixUrl('/notifications/all-subscriptions/');

  return (dispatch) => {
    return fetch(url, fetchGet())
      .then(res => dispatch(checkStatus(res)))
      .then(res => res.json())
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}
