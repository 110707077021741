import React from 'react';
import Panel from '@Components/ui/styled/panel';
import Button from '@Components/ui/button';
import { Flex } from '@Components/ui/styled/main';

const InvoiceCustomerPanel = ({ customer, onUpdateCustomer, readonly }) => {
  const {
    customerName, orgOrPno, companyVatNo, fortnoxCustomerNumber,
    address1, address2, postCode, area, email
  } = customer;
  return (
    <Panel>
      <Flex fullWidth>
        <div>
          <div>{customerName}</div>
          <div>{address1}</div>
          <div>{address2}</div>
          <div>{postCode} {area}</div>
        </div>
        <div>
          <div>{orgOrPno}</div>
          <div>{companyVatNo}</div>
          <div>{email}</div>
        </div>
        <div>
          {fortnoxCustomerNumber && (
            <div className="mb2">
              {fortnoxCustomerNumber}
              <img src="/fortnox-icon.png" className="invoice-provider right" />
            </div>
          )}
          {!readonly && (
            <Button small white onClick={() => onUpdateCustomer(customer)}>Ändra</Button>
          )}
        </div>
      </Flex>
    </Panel>
  );
};

export default InvoiceCustomerPanel;
