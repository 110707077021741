import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toggleProductsCollapsed } from '@State/admin-view-reducer';
import { moveProduct, moveGroup } from '@State/products-actions';
import CurrencyUtil from '@Utils/currency-util';
import { getFilteredProductGroups, getFilteredProducts } from '@Utils/filter-selectors';
import { getSectionUrl, navigate } from '@Utils/navigate';
import SortableList from '../../sortable-list/sortable-list';

class ProductList extends Component {
  onItemClick = item => {
    navigate(getSectionUrl(this.props.routeParams, 'admin/products', item.id));
  };

  itemContent = item => {
    return (
      <div>
        {item.color && <span className="columns-list-color-dot" style={{ background: item.color }} />}
        <span className="list-item-header">
          {item.brand && `${item.brand}, `}
          {item.name}
        </span>
        <br />
        <span className="list-item-subtext">
          {CurrencyUtil.accountCurrency(item.priceOut, 2)}
        </span>
      </div>
    );
  };

  render() {
    return (
      <SortableList
        onChangeCollapsedIds={this.props.onToggleCollapse}
        collapsedIds={this.props.collapsedIds}
        groups={this.props.filteredProductGroups}
        groupItemSelector="articleIds"
        items={this.props.filteredProducts}
        itemContent={this.itemContent}
        selectedId={this.props.selectedId}
        onItemClick={this.onItemClick}
        updateGroup={this.props.onUpdateGroup}
        deleteGroup={this.props.onDeleteGroup}
        moveGroup={this.props.moveGroup}
        moveItem={this.props.moveProduct}
        sortable={this.props.sortable}
        groupCheckboxes={[{
          name: 'showInPos',
          label: 'Visa i kassan'
        }]}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { adminViewState } = state;

  return {
    collapsedIds: adminViewState.get('productsCollapsedIds'),
    filteredProducts: getFilteredProducts(state, ownProps),
    filteredProductGroups: getFilteredProductGroups(state, ownProps)
  };
};

const mapDispatchToProps = dispatch => ({
  onToggleCollapse: (collapsedIds) => {
    return dispatch(toggleProductsCollapsed(collapsedIds));
  },
  moveProduct: moveAction => dispatch(moveProduct(moveAction)),
  moveGroup: moveAction => dispatch(moveGroup(moveAction))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductList);
