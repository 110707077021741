import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import ModalDialog from '@Components/dialogs/modal-dialog';
import { approveStaffReportData, getStaffReportData, updateStaffReportData } from '@State/report-actions';
import { fetchPublicHolidaysIfNeeded } from '@State/schedule-actions';
import Button from '@Components/ui/button';
import Loader from '@Components/ui/loader';
import StaffReportDatasheet from './staff-report-datasheet';
import { getDateText } from '../reports-helpers';

const StaffReportDataModal = ({ report, row, data, onClose }) => {
  const dispatch = useDispatch();
  const holidays = useSelector(state => state.adminSchedules.get('holidays'));
  const [saveStatus, setSaveStatus] = useState('');
  const [entries, setEntries] = useState(data);
  const { reportId, header, startDate, endDate } = report;
  const readOnly = Boolean(!row || row.approvedTs);

  useEffect(() => {
    const year = startDate.substring(0, 4);
    dispatch(fetchPublicHolidaysIfNeeded(year));

    if (row) {
      dispatch(getStaffReportData(reportId, row.id))
        .then(json => setEntries(json.entries));
    }
  }, []);

  const handleSave = (data) => {
    setSaveStatus('Sparar...');
    dispatch(updateStaffReportData(reportId, row.id, { entries: data }))
      .then(() => {
        setEntries(data);
        setSaveStatus('Sparat!');
        setTimeout(() => setSaveStatus(''), 3000);
      });
  };

  const handleApprove = () => dispatch(approveStaffReportData(reportId, row.id, true));
  const handleUnapprove = () => dispatch(approveStaffReportData(reportId, row.id, false));

  return (
    <ModalDialog
      focusTrapPaused
      contentSize="full"
      closeOnClickOutside={false}
    >
      {!entries && <Loader />}
      <div>
        <div className="pull-right">
          <span className="text-muted mr3">
            {row?.approvedTs ? (
              <>
                <i className="far fa-check-circle text-success" />{' '}
                Godkänd
              </>
            ) : saveStatus}
          </span>
          {row && (
            <>
              {readOnly
                ? <Button gray small marginRight onClick={handleUnapprove}>Lås upp</Button>
                : <Button primary small marginRight onClick={handleApprove}>Godkänn</Button>}
            </>
          )}
          <Button gray small onClick={onClose}>Stäng</Button>
        </div>
        <h3 className="mt0">
          {row ? row.name : header}<br />
          <small>{getDateText(moment(startDate), moment(endDate))}</small>
        </h3>
      </div>
      <StaffReportDatasheet
        readOnly={readOnly}
        startDate={startDate}
        endDate={endDate}
        holidays={holidays}
        saveEntries={handleSave}
        entries={entries}
      />
    </ModalDialog>
  );
};

export default StaffReportDataModal;
