import { defineMessages } from 'react-intl';

export default defineMessages({
  lblSettings: {
    id: 'customers.detail.lblSettings',
    defaultMessage: 'Settings'
  },
  lblHistory: {
    id: 'customers.detail.lblHistory',
    defaultMessage: 'History'
  },
  lblUpcomingBookings: {
    id: 'customers.detail.lblUpcomingBookings',
    defaultMessage: 'Upcoming bookings'
  },
  lblCreated: {
    id: 'customers.detail.lblCreated',
    defaultMessage: 'Created'
  },
  lblUpdated: {
    id: 'customers.detail.lblUpdated',
    defaultMessage: 'Updated'
  },
  noEventsTitle: {
    id: 'customers.detail.noEventsTitle',
    defaultMessage: 'No events'
  },
  noEventsText: {
    id: 'customers.detail.noEventsText',
    defaultMessage: 'There are no bookings, logs or purchases'
  }
});
