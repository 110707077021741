import React from 'react';
import CurrencyUtil from '@Utils/currency-util';
import Panel from '@Components/ui/styled/panel';
import { Flex } from '@Components/ui/styled/main';
import { getDiscountAmount } from '@Utils/pos-utils';
import { getSaleItemAmount } from '@State/pos-selectors';

const InvoiceSummaryPanel = ({ saleItems, totalAmount, grossAmount }) => {
  return (
    <Panel>
      {saleItems.map(item => {
        const amount = item.get('itemType') === 'Discount'
          ? -getDiscountAmount(item.get('discountType'), item.get('discountValue'), grossAmount)
          : getSaleItemAmount(item.toJS());
        return (
          <Flex key={item.get('id')}>
            <Flex>{item.get('description')}</Flex>
            <Flex right>{CurrencyUtil.accountCurrency(amount, 2)}</Flex>
          </Flex>
        );
      })}
      <Flex>
        <Flex><strong>Att betala</strong></Flex>
        <Flex right><strong>{CurrencyUtil.accountCurrency(totalAmount, 2)}</strong></Flex>
      </Flex>
    </Panel>
  );
};

export default InvoiceSummaryPanel;
