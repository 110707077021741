import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { getSysAdmin } from '@State/selectors';
import UndoButton from './undo-button';
import ScheduleToggle from './schedule-toggle';
import CalendarViewSettings from './calendar-view-settings';
import FindTimeButton from './find-time-button';

const CalendarHeaderToolbarButtons = ({ routeParams }) => {
  const isSysAdmin = useSelector(getSysAdmin);
  return (
    <>
      {isSysAdmin && false && <FindTimeButton routeParams={routeParams} />}
      <UndoButton />
      <ScheduleToggle routeParams={routeParams} />
      <CalendarViewSettings />
    </>
  );
};

export default memo(CalendarHeaderToolbarButtons);
