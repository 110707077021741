import React, { Component } from 'react';
import { connect } from 'react-redux';
import { config, customer, web } from '@Utils/preference-keys';
import { getFeatures } from '@State/selectors';
import { mapPrefsToForm, mapFormToPrefs } from '@Utils/web-booking';
import { getJsonPreferences, mergeJsonPreferences, getInitialValues } from '@State/preferences-actions';
import WebBookingForm from './web-booking-form';

class WebBooking extends Component {
  componentDidMount() {
    this.props.getWebBookingConfig();
  }

  render() {
    const {
      smsPinPrice, features, initialValues, saveWebBookingConfig
    } = this.props;

    return (
      <div className="subnav-container">
        <WebBookingForm
          initialValues={initialValues}
          onSubmit={saveWebBookingConfig}
          smsPinPrice={smsPinPrice}
          features={features}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { locationConfig } = state;

  const smsPinPrice = locationConfig.get(config.smsPrices).find((smsPrice) => {
    return smsPrice.get('priceType') == 'Pin';
  });

  const keys = { ...web, ...customer };
  const initialValues = getInitialValues(state, keys);
  if (initialValues) {
    mapPrefsToForm(initialValues);
  }

  return {
    initialValues,
    features: getFeatures(state),
    smsPinPrice: smsPinPrice ? smsPinPrice.get('price') : null
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getWebBookingConfig: () => {
      dispatch(getJsonPreferences());
    },
    saveWebBookingConfig: (values) => {
      mapFormToPrefs(values);
      return dispatch(mergeJsonPreferences(values));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WebBooking);
