import React, { useState } from 'react';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { useSelector } from 'react-redux';
import { createValidator, required } from '@Utils/validation';
import Flex from '@Components/ui/styled/flex-item';
import TextInput from '@Components/inputs/text-input';
import ModalDialog from '@Components/dialogs/modal-dialog';
import CreatePosUnitType from './create-pos-unit-type';

const CreatePosUnit = ({
  onClose, handleSubmit, submitting
}) => {
  const formValues = useSelector(getFormValues('create-pos-unit'));
  const [termsAccepted, setTermsAccepted] = useState(false);

  return (
    <ModalDialog
      title="Nytt kassaregister"
      contentSize="medium"
      onClose={onClose}
      buttons={[{
        name: 'Skapa kassaregister',
        primary: true,
        type: 'submit',
        disabled: !termsAccepted,
        loading: submitting,
        onClick: handleSubmit
      }]}
    >
      <form onSubmit={handleSubmit} autoComplete="off" className="dialog">
        <Flex>
          <Field
            name="addressLine1"
            component={TextInput}
            label="Säljställets adress"
            placeholder="Gatuadress"
          />
        </Flex>
        <Flex>
          <Flex rightMargin>
            <Field
              name="postCode"
              type="number"
              component={TextInput}
              label="Postnummer"
              placeholder="Postnummer"
            />
          </Flex>
          <Flex>
            <Field
              name="area"
              component={TextInput}
              label="Postort"
              placeholder="Postort"
            />
          </Flex>
        </Flex>
        <br />
        <CreatePosUnitType
          vunitType={formValues?.vunitType}
          termsAccepted={termsAccepted}
          setTermsAccepted={() => setTermsAccepted(!termsAccepted)}
        />
      </form>
    </ModalDialog>
  );
};

const validator = createValidator({
  addressLine1: [required],
  postCode: [required],
  area: [required]
});

export default reduxForm({
  form: 'create-pos-unit',
  validate: validator
})(CreatePosUnit);
