import { defineMessages } from 'react-intl';

export default defineMessages({
  lblOwnedByLocation: {
    id: 'customers.overview.lblOwnedByLocation',
    defaultMessage: 'Customer belongs to the account'
  },
  lblAssociatedToResource: {
    id: 'customers.overview.lblAssociatedToResource',
    defaultMessage: 'Customer belongs to a resource'
  },
  lblSelectResource: {
    id: 'customers.overview.lblSelectResource',
    defaultMessage: 'Select resource'
  }
});
