import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { getNavSections, sysadminNavSections } from './nav-selectors';
import SectionItem from './section-item';

const Sections = ({ sections, routeParams }) => {
  return (
    <ul>
      {sections.map(section => (
        <SectionItem key={section.id} {...routeParams} {...section} />
      ))}
    </ul>
  );
};

Sections.propTypes = {
  sections: PropTypes.array.isRequired,
  routeParams: PropTypes.object.isRequired
};

const mapStateToProps = (state, ownProps) => ({
  sections: ownProps.isSysadmin
    ? sysadminNavSections
    : getNavSections(state, ownProps)
});

export default connect(mapStateToProps)(Sections);
