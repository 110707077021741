import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import { Field, reduxForm } from 'redux-form';
import TextareaAutosize from 'react-textarea-autosize';

import FormButtonsFooter from '@Components/ui/form-buttons-footer';
import { txt } from '@Utils/i18n-util';
import msg from './customer-notes.msg';

class CustomerNotesForm extends PureComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.focusEditField();
  }

  componentDidUpdate(prevProps) {
    this.focusEditField();
  }

  focusEditField = () => {
    if (this.notes) {
      const notes = ReactDOM.findDOMNode(this.notes);
      notes.focus();
      notes.setSelectionRange(notes.value.length, notes.value.length);
    }
  };

  textArea = props => (
    <TextareaAutosize
      {...props.input}
      minRows={5}
      maxRows={20}
      className="form-control"
    />
  );

  render() {
    const {
      handleSubmit, pristine, submitting, submitSucceeded, onClose
    } = this.props;
    return (
      <div>
        <form autoComplete="off" className="dialog" onSubmit={handleSubmit}>
          <div className="form-group">
            <label className="control-label">
              {txt(msg.lblNotes)}
            </label>
            <Field
              component={this.textArea}
              className="form-control text-wrap"
              name="notes"
              ref={(ref) => { this.notes = ref; }}
            />
          </div>
          <FormButtonsFooter
            pristine={pristine}
            submitting={submitting}
            submitSucceeded={submitSucceeded}
            onClose={onClose}
            handleSubmit={handleSubmit}
          />
        </form>
      </div>
    );
  }
}

export default reduxForm({
  form: 'customer-detail-change-notes'
})(CustomerNotesForm);
