import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { change, Field } from 'redux-form';
import { date, required, trimString } from '@Utils/validation';
import TextInput from '@Components/inputs/text-input';
import SelectField from '@Components/inputs/select-field';
import { getVatRates } from '@State/selectors';

const Required = () => <span className="text-danger">*</span>;

const GiftCardFormRows = ({ fields, defaultValues }) => {
  const dispatch = useDispatch();
  const vatRates = useSelector(getVatRates);

  const onAddRow = (ev) => {
    ev.preventDefault();
    ev.target.blur();
    fields.push({ ...defaultValues });
  };

  const onDeleteRow = (ev, index) => {
    ev.preventDefault();
    fields.remove(index);
  };

  const typeChanged = (ev, value) => {
    const member = ev.target.name.split('.');
    if (value === 'MultiPurpose') {
      dispatch(change('gift-card-form', `${member[0]}.vatPct`, '0'));
    }
  };

  return (
    <>
      <table className="table-form full-width">
        <tbody>
          <tr>
            <th className="width-sm">Nummer/kod <Required /></th>
            <th className="width-md">Beskrivning <Required /></th>
            <th className="width-md">Detaljer</th>
            <th className="width-md">Förmånstagare</th>
            <th className="width-sm">Värde <Required /></th>
            <th className="width-sm">Värde kvar <Required /></th>
            <th className="width-sm">Sålt datum <Required /></th>
            <th className="width-sm">Giltigt t.o.m. <Required /></th>
            <th className="width-sm">Typ <Required /></th>
            <th className="width-xs">Moms <Required /></th>
          </tr>
          {fields.map((member, index, array) => (
            <tr key={index}>
              <td className="width-sm">
                <Field type="text" name={`${member}.code`} component={TextInput} validate={required} normalize={trimString} />
              </td>
              <td className="width-md">
                <Field type="text" name={`${member}.description`} component={TextInput} validate={required} />
              </td>
              <td className="width-md">
                <Field type="text" name={`${member}.comment`} component={TextInput} />
              </td>
              <td className="width-md">
                <Field type="text" name={`${member}.beneficiary`} component={TextInput} />
              </td>
              <td className="width-sm">
                <Field type="number" name={`${member}.initialValue`} component={TextInput} unitLabel="kr" validate={required} />
              </td>
              <td className="width-sm">
                <Field type="number" name={`${member}.remainingValue`} component={TextInput} unitLabel="kr" validate={required} />
              </td>
              <td className="width-sm">
                <Field type="text" name={`${member}.soldDate`} component={TextInput} placeholder="yyyy-mm-dd" validate={[required, date]} />
              </td>
              <td className="width-sm">
                <Field type="text" name={`${member}.noRedeemAfterDate`} component={TextInput} placeholder="yyyy-mm-dd" validate={[required, date]} />
              </td>
              <td className="width-sm">
                <Field name={`${member}.accountingType`} component={SelectField} validate={required} onChange={typeChanged}>
                  <option value="SinglePurpose">Enfunktion</option>
                  <option value="MultiPurpose">Flerfunktion</option>
                </Field>
              </td>
              <td className="width-xs">
                <Field
                  name={`${member}.vatPct`}
                  component={SelectField}
                  disabled={array.get(index).accountingType === 'MultiPurpose'}
                  validate={required}
                >
                  {vatRates.map(vat => <option key={vat} value={vat}>{`${vat}%`}</option>)}
                </Field>
              </td>
              <td className="width-xxs text-right">
                {index > 0 && (
                  <p className="form-control-static">
                    <a href="#" className="text-muted" onClick={(ev) => onDeleteRow(ev, index)}>
                      <i className="fa fa-times-circle" />
                    </a>
                  </p>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pull-right">
        <Required /><span className="text-muted"> = obligatoriskt fält</span>
      </div>
      <a href="#" onClick={onAddRow}>
        + Lägg till rad
      </a>
    </>
  );
};

export default GiftCardFormRows;
