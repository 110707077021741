import React, { useContext, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { updateContract, updatePricePlan, updatePricePlanVunit, updateSmsPricing } from '@State/sysadmin-actions';
import ButtonModalForm from '@Components/dialogs/button-modal-form';
import AccountOverviewContext from './account-overview-context';
import AccountOverviewPriceVunitForm from './account-overview-price-vunit-form';
import AccountOverviewPriceForm from './account-overview-price-form';
import AccountOverviewBlock from './account-overview-block';
import AccountPrefsSmsForm from '../account-prefs/account-prefs-sms-form';
import AccountOverviewContractForm from './account-overview-contract-form';

const dateFormat = (string) => moment(string).format('YYYY-MM-DD');

const priceFormat = (string) => `${string} kr`;
const daysFormat = (string) => `${string} mån`;
const getResources = (data = {}) => `${data.currentResources ?? 0} / ${data.billedResources ?? 0}`;
const getLocations = (data = {}) => `${data.currentLocations ?? 0} / ${data.billedLocations ?? 0}`;

const infoFields = [
  [
    { prop: 'pricePlan', title: 'Prisplan' },
    { prop: 'fixedPrice', title: 'Pris/mån (Cliento)', format: priceFormat }
  ],
  [
    { prop: 'fixedPriceMaxResources', title: 'fixedPriceMaxResources' },
    { prop: 'fixedPriceMaxLocations', title: 'fixedPriceMaxLocations' },
    { prop: 'fixedPriceMaxVunits', title: 'fixedPriceMaxVunits' }
  ],
  [
    { prop: 'billedResources', title: 'billedResources', getProp: getResources },
    { prop: 'billedLocations', title: 'billedLocations', getProp: getLocations }
  ]
];

const contractFields = [
  [
    { prop: 'contractLength', title: 'Bindningstid', format: daysFormat },
    { prop: 'contractStartDate', title: 'contractStartDate', format: dateFormat },
    { prop: 'contractEndDate', title: 'contractEndDate', format: dateFormat }
  ],
  [
    { prop: 'externalInvoiceFixedPrice', title: 'externalInvoiceFixedPrice', format: priceFormat },
    { prop: 'bookingIntroPrice', title: 'bookingIntroPrice', format: priceFormat },
    { prop: 'bookingIntroPriceUntil', title: 'bookingIntroPriceUntil', format: dateFormat }
  ]
];

const priceFields = [
  [
    { prop: 'perResourcePrice', title: 'perResourcePrice', format: priceFormat },
    { prop: 'perLocationPrice', title: 'perLocationPrice', format: priceFormat }
  ],
  [
    { prop: 'perInitialVunitPrice', title: 'perInitialVunitPrice', format: priceFormat },
    { prop: 'perExtraVunitPrice', title: 'perExtraVunitPrice', format: priceFormat }
  ],
  [
    { prop: 'smsReminderPrice', title: 'smsReminderPrice', format: priceFormat },
    { prop: 'smsPinPrice', title: 'smsPinPrice', format: priceFormat },
    { prop: 'smsBulkPrice', title: 'smsBulkPrice', format: priceFormat }
  ]
];

const utmFields = [
  [
    { prop: 'utm_source', title: 'Source' },
    { prop: 'utm_medium', title: 'Medium' },
    { prop: 'utm_campaign', title: 'Campaign' }
  ],
  [
    { prop: 'utm_id', title: 'Id' },
    { prop: 'utm_term', title: 'Term' },
    { prop: 'utm_content', title: 'Content' }
  ]
];

const AccountOverviewPrice = () => {
  const { id, subscription } = useContext(AccountOverviewContext);
  const dispatch = useDispatch();
  const utmTags = subscription.getIn(['signup', 'utmTags']);
  const hasUtmTags = utmTags?.valueSeq().some(value => value !== null);
  const pricing = subscription.get('subscriptionPricing')?.toJS() || {};

  const initialValues = useMemo(() => ({
    pricePlan: subscription.get('pricePlan'),
    fixedPrice: pricing.fixedPrice,
    perResourcePrice: pricing.perResourcePrice,
    perLocationPrice: pricing.perLocationPrice,
    fixedPriceMaxResources: pricing.fixedPriceMaxResources,
    fixedPriceMaxLocations: pricing.fixedPriceMaxLocations,
    fixedPriceMaxVunits: pricing.fixedPriceMaxVunits
  }), [subscription]);

  const initialValuesContract = useMemo(() => ({
    contractLength: subscription.get('contractLength'),
    contractStartDate: subscription.get('contractStartDate'),
    contractEndDate: subscription.get('contractEndDate'),
    externalInvoiceFixedPrice: pricing.externalInvoiceFixedPrice,
    bookingIntroPrice: pricing.bookingIntroPrice,
    bookingIntroPriceUntil: pricing.bookingIntroPriceUntil
  }), [subscription]);

  const initialValuesVunit = useMemo(() => ({
    perInitialVunitPrice: pricing.perInitialVunitPrice,
    perExtraVunitPrice: pricing.perExtraVunitPrice
  }), [subscription]);

  const initialValuesSms = useMemo(() => ({
    smsReminderPrice: pricing.smsReminderPrice,
    smsPinPrice: pricing.smsPinPrice,
    smsBulkPrice: pricing.smsBulkPrice
  }), [subscription]);

  const initialValuesCombined = useMemo(() => ({
    ...initialValues,
    ...initialValuesContract,
    ...initialValuesVunit,
    ...initialValuesSms
  }), [subscription]);

  const onSubmitContract = (values) => {
    return dispatch(updateContract({ orgId: id, ...values }));
  };

  const onSubmitPrice = (values) => {
    return dispatch(updatePricePlan({ orgId: id, ...values }));
  };

  const onSubmitPriceVunit = (values) => {
    return dispatch(updatePricePlanVunit({ orgId: id, ...values }));
  };

  const onSubmitSmsPricing = (values) => {
    return dispatch(updateSmsPricing({ orgId: id, ...values }));
  };

  return (
    <>
      <AccountOverviewBlock
        data={initialValuesContract}
        title="Kontrakt"
        columnsQuan={3}
        columns={contractFields}
        button={(
          <ButtonModalForm link btnText="Ändra kontrakt" onSubmit={onSubmitContract}>
            <AccountOverviewContractForm initialValues={initialValuesContract} />
          </ButtonModalForm>
        )}
      />
      <AccountOverviewBlock
        data={initialValues}
        title="Abonnemang"
        columnsQuan={3}
        columns={infoFields}
        button={(
          <ButtonModalForm link btnText="Ändra abonnemang" onSubmit={onSubmitPrice}>
            <AccountOverviewPriceForm initialValues={initialValues} />
          </ButtonModalForm>
        )}
      />
      <AccountOverviewBlock
        data={initialValuesCombined}
        title="Priser"
        columnsQuan={3}
        columns={priceFields}
        button={(
          <div>
            <ButtonModalForm link btnText="Ändra priser för kassa" onSubmit={onSubmitPriceVunit}>
              <AccountOverviewPriceVunitForm initialValues={initialValuesVunit} />
            </ButtonModalForm>
            <span className="text-muted mr1 ml1">|</span>
            <ButtonModalForm
              marginRight
              link
              onSubmit={onSubmitSmsPricing}
              btnText="Ändra SMS-priser"
            >
              <AccountPrefsSmsForm initialValues={initialValuesSms} />
            </ButtonModalForm>
          </div>
        )}
      />

      {hasUtmTags && (
        <AccountOverviewBlock
          title="UTM tags"
          columnsQuan={3}
          columns={utmFields}
          data={utmTags}
        />
      )}
    </>
  );
};

export default AccountOverviewPrice;
