import React, { useCallback } from 'react';
import { txt } from '@Utils/i18n-util';
import msg from './booking-form.msg';

const ScheduleSegmentItem = ({ currentType, type, setType, children }) => (
  <li className={currentType === type ? 'is-active' : ''}>
    <a href="#" onClick={ev => setType(ev, type)}>{children}</a>
  </li>
);

const ScheduleSegment = ({ currentType, onTypeChange }) => {
  const setType = useCallback((ev, typeString) => {
    ev.target.blur();
    ev.preventDefault();
    onTypeChange(typeString);
  }, []);

  return (
    <div className="booking-form-segment">
      <ul>
        <ScheduleSegmentItem currentType={currentType} setType={setType} type="close">
          {txt(msg.closeSchedule)}
        </ScheduleSegmentItem>
        <ScheduleSegmentItem currentType={currentType} setType={setType} type="open">
          {txt(msg.openSchedule)}
        </ScheduleSegmentItem>
      </ul>
    </div>
  );
};

export default ScheduleSegment;
