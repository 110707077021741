import { List, fromJS } from 'immutable';
import { CLEAR_LOCATION_STATE } from '@State/account-actions';
import { VOUCHERS_FETCHED } from './voucher-actions';

export function vouchers(state = List(), action = null) {
  switch (action.type) {
    case VOUCHERS_FETCHED:
      return fromJS(action.vouchers);

    case CLEAR_LOCATION_STATE:
      return state.clear();

    default:
      return state;
  }
}
