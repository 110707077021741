import React from 'react';
import { dateFormat, reportingCount, reportingFormat } from '@Utils/format';
import { Button, Row, Panel, Text } from '@Components/ui/styled/main';

import SalesReportVoucherRows from '@Components/reports/sales/sales-report-voucher-rows';
import SalesReportCategoryRows from '@Components/reports/sales/sales-report-category-rows';
import SalesReportItemRows from '@Components/reports/sales/sales-report-item-rows';
import SalesReportSummary from '@Components/reports/sales/sales-report-summary-total';
import SalesReportVatSummary from '@Components/reports/sales/sales-report-vat-summary';
import SalesReportPaymentSummary from '@Components/reports/sales/sales-report-payment-summary';
import SalesReportCashLedger from '@Components/reports/sales/sales-report-cash-ledger';

import {
  FlexOne,
  ReportTitle,
  ReportTitleButtons,
  ReportTableRow,
  PanelWrapper,
  GrayText,
  ReportTextLabel
} from '@Components/pos/style';

const Report = ({ report, sendReport, sending }) => {
  const {
    createdTs, openedTs, closedTs, sequenceNo, reportData, createdBy,
    countedCashAmount, withdrawnCashAmount, comment
  } = report.toJS();
  const {
    totalSalesAmount, receiptCount, receiptCopyCount, receiptCopyAmount,
    vunitState, grandTotals, saleCount, refundCount, paymentsSummary,
    vatSummary, cashSummary
  } = reportData || {};

  const onPrint = (ev) => {
    ev.preventDefault();
    ev.target.blur();
    window.print();
  };

  return (
    <>
      <ReportTitle>
        <h3 className="mt0 mb0">{sequenceNo ? `Z-rapport #${sequenceNo}` : 'X-rapport'}</h3>
        <ReportTitleButtons className="hidden-print">
          <Button onClick={sendReport} disabled={sending} gray small marginRight>
            {sending ? 'Mejlar...' : 'Mejla'}
          </Button>
          <Button onClick={onPrint} gray small>
            Skriv ut
          </Button>
        </ReportTitleButtons>
      </ReportTitle>
      <Text bold>{dateFormat(createdTs)}</Text>
      <Text bold muted>Kassa öppnad: {dateFormat(openedTs)}</Text>
      <Text bold muted>Kassa stängd: {sequenceNo ? dateFormat(closedTs) : '-'}</Text>
      <Text bold muted>{sequenceNo ? `Skapad av: ${createdBy}` : null}</Text>
      <PanelWrapper>
        <SalesReportSummary {...reportData} />
        {(saleCount > 0 || refundCount > 0) && (
          <>
            <h5 className="mt3">Försäljning per betalsätt</h5>
            <Panel>
              <SalesReportPaymentSummary paymentsSummary={paymentsSummary} />
            </Panel>
            <h5 className="mt3">Försäljning per momssats</h5>
            <Panel>
              <SalesReportVatSummary {...vatSummary} />
            </Panel>
            <h5 className="mt3">Försäljning per kategori</h5>
            <Panel>
              <SalesReportCategoryRows {...reportData} />
            </Panel>
            <h5 className="mt3">Presentkort</h5>
            <Panel>
              <SalesReportVoucherRows {...reportData} />
            </Panel>
            <h5 className="mt3">Försäljning per produkt</h5>
            <Panel>
              <SalesReportItemRows {...reportData} />
            </Panel>
          </>
        )}

        {cashSummary?.ledger?.length > 0 && (
          <>
            <h5 className="mt3">Växelkassa</h5>
            <Panel>
              <SalesReportCashLedger {...cashSummary} />
            </Panel>
          </>
        )}

        <Panel>
          <Row>
            <FlexOne marginRight="97px">
              <ReportTableRow size="50%">
                <div>
                  Kvitton ({receiptCount})
                </div>
                <div>{reportingFormat(totalSalesAmount)}</div>
              </ReportTableRow>
              <ReportTableRow size="50%">
                <div>
                  Kvittokopior ({receiptCopyCount})
                </div>
                <div>{reportingFormat(receiptCopyAmount)}</div>
              </ReportTableRow>
              <ReportTableRow size="50%">
                <div>Antal lådöppningar</div>
                <div>{reportingCount(vunitState.cashDrawerOpenCount)}</div>
              </ReportTableRow>
              {vunitState && (
                <>
                  <ReportTableRow size="50%">
                    <div>Kontanter vid öppning</div>
                    <div>{reportingFormat(vunitState.openingCashBalance)}</div>
                  </ReportTableRow>
                  {!sequenceNo ? (
                    <ReportTableRow size="50%">
                      <div>Kontanter just nu</div>
                      <div>{reportingFormat(vunitState.currentCashBalance)}</div>
                    </ReportTableRow>
                  ) : (
                    <>
                      {countedCashAmount ? (
                        <ReportTableRow size="50%">
                          <div>Kontanter vid stängning</div>
                          <div>{reportingFormat(countedCashAmount)}</div>
                        </ReportTableRow>
                      ) : null}
                      {withdrawnCashAmount ? (
                        <ReportTableRow size="50%">
                          <div>Uttag från kassalåda</div>
                          <div>{reportingFormat(withdrawnCashAmount)}</div>
                        </ReportTableRow>
                      ) : null}
                    </>
                  )}
                </>
              )}
            </FlexOne>
            {grandTotals && (
            <FlexOne>
              <ReportTableRow size="50%">
                <div>Summa försäljning</div>
                <div>{reportingFormat(grandTotals.totalSales)}</div>
              </ReportTableRow>
              <ReportTableRow size="50%">
                <div>Summa returer</div>
                <div>{reportingFormat(grandTotals.totalReturns)}</div>
              </ReportTableRow>
              <ReportTableRow size="50%">
                <div>Summa nettoförsäljning</div>
                <div>{reportingFormat(grandTotals.totalNet)}</div>
              </ReportTableRow>
            </FlexOne>
            )}
          </Row>
        </Panel>
        {comment && (
          <Panel>
            <ReportTextLabel>
              <span>Kommentar</span>
              <Text fs={14}>{comment}</Text>
            </ReportTextLabel>
          </Panel>
        )}
      </PanelWrapper>
    </>
  );
};

export default Report;
