import { Component } from 'react';
import { connect } from 'react-redux';
import { resetHighlightedBookings, updateHighlightedBookings } from '@State/view-actions';

class ChipHoverTracker extends Component {
  constructor(props) {
    super(props);
    this.timer = null;
  }

  componentDidMount() {
    window.addEventListener('mousemove', this.onMouseMove);
  }

  componentWillUnmount() {
    window.removeEventListener('mousemove', this.onMouseMove);

    this.props.resetHighlightedBookings();
  }

  onMouseMove = (ev) => {
    clearTimeout(this.timer);
    this.timer = setTimeout(this.onMouseStopped, 50);
  };

  onMouseStopped = () => {
    this.props.updateHighlightedBookings();
  };

  render() {
    return null;
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateHighlightedBookings: () => {
      dispatch(updateHighlightedBookings());
    },
    resetHighlightedBookings: () => {
      dispatch(resetHighlightedBookings());
    }
  };
};

export default connect(null, mapDispatchToProps)(ChipHoverTracker);
