import React, { useState } from 'react';
import ModalDialog from '@Components/dialogs/modal-dialog';
import styled from 'styled-components';

const InfoContainer = styled.div`
  font-size: 15px;
  padding: 15px 20px 40px;

  p {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const InfoDialogButton = ({ title, children }) => {
  const [showDialog, setShowDialog] = useState(false);

  const handleClick = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    ev.target.blur();
    setShowDialog(true);
  };

  const handleClose = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    setShowDialog(false);
  };

  return (
    <>
      <a href="#" className="info-dialog-button" onClick={handleClick}>
        <i className="fa fa-circle-info" />
      </a>
      {showDialog && (
        <ModalDialog
          title={title}
          contentSize="large"
          onClose={handleClose}
          largePadding
          closeButtonX
        >
          <InfoContainer tabIndex={0}>
            {children}
          </InfoContainer>
        </ModalDialog>
      )}
    </>
  );
};

export default InfoDialogButton;
