import React from 'react';
import groupBy from 'lodash/groupBy';
import { getBookingReportColumns, statusText } from '@Components/reports/reports-helpers';

const BookingReportStatuses = ({ bookings }) => {
  const bookingsByStatus = groupBy(bookings, 'status');
  const statuses = ['Booked', 'Show', 'NoShow', 'Cancelled'];
  const totalColumns = getBookingReportColumns(bookings);

  return (
    <table className="table table-condensed table-report">
      <thead>
        <tr>
          <th className="col-xs-6">Status</th>
          <th className="col-xs-3 text-right">Antal</th>
          <th className="col-xs-3 text-right">Värde</th>
        </tr>
      </thead>
      <tbody>
        {statuses.map((key) => {
          const columns = getBookingReportColumns(bookingsByStatus[key]);
          return (
            <tr key={key}>
              <td className="child">{statusText(key)}</td>
              <td className="text-right">{columns.totalCount}</td>
              <td className="text-right">{columns.totalRevenue}</td>
            </tr>
          );
        })}
      </tbody>
      <tfoot>
        <tr>
          <th>Summa</th>
          <th className="text-right">{totalColumns.totalCount}</th>
          <th className="text-right">{totalColumns.totalRevenue}</th>
        </tr>
      </tfoot>
    </table>
  );
};

export default BookingReportStatuses;
