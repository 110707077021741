import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { orderBy } from 'lodash';
import { fetchFortnoxVouchers, fetchFortnoxVoucher, deleteFortnoxVoucher } from '@State/fortnox-actions';
import ModalDialog from '@Components/dialogs/modal-dialog';
import { Button } from '@Components/ui/styled/main';
import Loader from '@Components/ui/loader';

function getVoucherTitle(voucher) {
  const { VoucherSeries, VoucherNumber, TransactionDate } = voucher;
  return `${VoucherSeries} ${VoucherNumber} - ${TransactionDate}`;
}

const FortnoxVouchers = ({ posOrgId, onClose }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [vouchers, setVouchers] = useState([]);
  const [voucher, setVoucher] = useState(null);

  useEffect(() => {
    setLoading(true);
    fetchVouchers();
  }, [posOrgId]);

  const fetchVouchers = () => {
    dispatch(fetchFortnoxVouchers(posOrgId))
      .then((response) => {
        setVouchers(orderBy(response, ['VoucherNumber'], ['desc']));
        setLoading(false);
      });
  };

  const fetchVoucher = (yearId, voucherSeries, voucherNumber) => {
    setLoading(true);
    dispatch(fetchFortnoxVoucher(posOrgId, yearId, voucherSeries, voucherNumber))
      .then((response) => {
        setVoucher(response);
        setLoading(false);
      });
  };

  const handleVoucher = (ev, item) => {
    ev.preventDefault();
    const { Year, VoucherSeries, VoucherNumber } = item;
    fetchVoucher(Year, VoucherSeries, VoucherNumber);
  };

  const deleteVoucher = (yearId, voucherSeries, voucherNumber, transactionDate) => {
    setLoading(true);
    dispatch(deleteFortnoxVoucher(posOrgId, yearId, voucherSeries, voucherNumber, transactionDate))
      .then(() => fetchVouchers());
  };

  const handleDelete = (ev, item) => {
    ev.preventDefault();
    const { Year, VoucherSeries, TransactionDate, VoucherNumber } = item;
    deleteVoucher(Year, VoucherSeries, VoucherNumber, TransactionDate);
  };

  const handleBack = () => {
    setVoucher(null);
  };

  return (
    <ModalDialog
      contentSize="large"
      title={voucher ? getVoucherTitle(voucher) : 'Fortnox bokföring'}
      closeButtonText="Stäng"
      onClose={onClose}
      onBack={voucher ? handleBack : null}
      closeOnClickOutside={false}
    >
      {loading && <Loader />}
      {voucher ? (
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Konto</th>
              <th>Beskrivning</th>
              <th className="text-right">Debet</th>
              <th className="text-right">Kredit</th>
            </tr>
          </thead>
          <tbody>
            {voucher.VoucherRows && voucher.VoucherRows.map((item) => {
              const { Account, Debit, Credit, Description } = item;
              return (
                <tr key={Account}>
                  <td><strong>{Account}</strong></td>
                  <td>{Description}</td>
                  <td className="text-right">{Debit}</td>
                  <td className="text-right">{Credit}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <table className="table table-striped">
          <thead>
            <tr>
              <th>#</th>
              <th>Datum</th>
              <th>Beskrivning</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {vouchers && vouchers.map((item, index) => {
              const { VoucherSeries, TransactionDate, Description, VoucherNumber } = item;
              return (
                <tr key={VoucherNumber}>
                  <td>
                    <a href="#" onClick={(ev) => handleVoucher(ev, item)}>
                      <strong>{VoucherSeries} {VoucherNumber}</strong>
                    </a>
                  </td>
                  <td>{TransactionDate}</td>
                  <td>{Description}</td>
                  <td className="text-right">
                    {index === 0 && (
                      <a href="#" onClick={(ev) => handleDelete(ev, item)}>
                        <i className="fa fa-times-circle text-danger" />
                      </a>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
      <div className="text-right">
        <Button gray small onClick={onClose}>Stäng</Button>
      </div>
    </ModalDialog>
  );
};

export default FortnoxVouchers;
