import React from 'react';
import PropTypes from 'prop-types';
import ModalDialog from '@Components/dialogs/modal-dialog';
import { DialogContent } from '@Components/dialogs/dialog-styles';

const DialogNotification = ({ title, children, onClose }) => (
  <ModalDialog
    title={title}
    closeButtonText="Stäng"
    onClose={onClose}
    buttons={[{
      primary: true,
      name: 'OK',
      onClick: onClose
    }]}
  >
    <DialogContent>
      {children}
    </DialogContent>
  </ModalDialog>
);

DialogNotification.propTypes = {
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

export default DialogNotification;
