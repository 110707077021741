import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CurrencyUtil from '@Utils/currency-util';
import { pos } from '@Utils/preference-keys';
import { showPaymentModal } from '@State/pos-actions';
import { PaymentModal } from '@Utils/pos-utils';
import {
  getPosOrgPrefs, getSaleCustomer, getSaleCustomerGiftCards,
  getSaleItems, getShowGiftCardInfo, hasSaleVatError
} from '@State/pos-selectors';
import { fetchCustomerGiftCards } from '@State/customer-actions';
import AlertWithIcon from '@Components/ui/alert-with-icon';
import Button from '@Components/ui/button';

const PosSaleCustomerGiftCard = () => {
  const dispatch = useDispatch();
  const items = useSelector(getSaleItems);
  const vatError = useSelector(hasSaleVatError);
  const customer = useSelector(getSaleCustomer);
  const posOrgPrefs = useSelector(getPosOrgPrefs);
  const giftCards = useSelector(getSaleCustomerGiftCards);
  const showGiftCardInfo = useSelector(getShowGiftCardInfo);
  const saleId = useSelector(state => state.posSale.get('id'));
  const giftCardEnabled = posOrgPrefs[pos.paymentMethodGiftCard];
  const clickable = !items.isEmpty() && !vatError;

  const giftCardSum = useMemo(() => {
    return giftCards?.reduce((sum, card) => sum + card.remainingValue, 0);
  }, [giftCards]);

  useEffect(() => {
    if (giftCardEnabled && customer) {
      dispatch(fetchCustomerGiftCards(customer.customerId, saleId));
    }
  }, [giftCardEnabled, customer]);

  const handleClick = (ev) => {
    ev.stopPropagation();
    dispatch(showPaymentModal(PaymentModal.GiftCard));
  };

  return showGiftCardInfo ? (
    <AlertWithIcon
      small
      success
      icon="fa fa-gift-card"
      onClick={clickable ? handleClick : null}
      button={(
        <Button tiny white onClick={clickable ? handleClick : null}>
          {CurrencyUtil.accountCurrency(giftCardSum, 2)}
        </Button>
      )}
    >
      Kunden har presentkort
    </AlertWithIcon>
  ) : null;
};

export default PosSaleCustomerGiftCard;
