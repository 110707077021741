import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setUserPreference } from '@State/user-actions';
import { bookingColumnsSelector, getUserClientPreferences } from '@Components/reports/reports-helpers';
import { reports } from '@Utils/preference-keys';

import { DropDownItem, DropdownHeader, DropDownDivider } from '@Components/ui/drop-down/dropdown';
import ButtonDropdown from '@Components/ui/button-dropdown';

const ReportsSettings = () => {
  const dispatch = useDispatch();
  const columnSettings = useSelector(bookingColumnsSelector);
  const prefs = useSelector(getUserClientPreferences);

  const toggleHideCancelledBookings = () => {
    dispatch(setUserPreference(false, {
      [reports.hideCancelledBookings]: !prefs[reports.hideCancelledBookings]
    }));
  };

  const toggleHideReservations = () => {
    dispatch(setUserPreference(false, {
      [reports.hideReservations]: !prefs[reports.hideReservations]
    }));
  };

  const handleToggleColumn = (ev, id) => {
    ev.preventDefault();
    ev.stopPropagation();

    const reportsColumnSettings = columnSettings.map((item) => {
      return item.id === id ? { ...item, checked: !item.checked } : item;
    });
    dispatch(setUserPreference(false, {
      [reports.columnSettings]: reportsColumnSettings
    }));
  };

  return (
    <ButtonDropdown icon="fa fa-cog">
      <DropdownHeader>Kolumner</DropdownHeader>
      {columnSettings.map(({ id, name, checked }) => (
        <DropDownItem key={id} onClick={(e) => handleToggleColumn(e, id)} checked={checked}>{name}</DropDownItem>
      ))}
      <DropDownDivider />
      <DropDownItem onClick={toggleHideCancelledBookings} checked={prefs[reports.hideCancelledBookings]}>
        Dölj avbokade bokningar
      </DropDownItem>
      <DropDownItem onClick={toggleHideReservations} checked={prefs[reports.hideReservations]}>
        Dölj blockerad tid
      </DropDownItem>
    </ButtonDropdown>
  );
};

export default ReportsSettings;
