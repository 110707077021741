import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { maxValue, minValue, required } from '@Utils/validation';
import TextInput from '@Components/inputs/text-input';
import FormButtonsFooter from '@Components/ui/form-buttons-footer';
import DatePickerField from '@Components/ui/date-picker/date-picker-field';

class BookingPaymentForm extends PureComponent {
  render() {
    const { handleSubmit, submitting, submitSucceeded, amountRemaining, onClose } = this.props;
    return (
      <div>
        <form autoComplete="off" className="dialog" onSubmit={handleSubmit}>
          <Field name="paymentDate" label="Inbetalningsdatum" component={DatePickerField} validate={required} />
          <Field type="number" name="paymentAmount" label="Belopp" component={TextInput} validate={[required, minValue(1), maxValue(amountRemaining)]} />
          <Field type="text" name="source" label="Källa" labelHint="(valfri)" component={TextInput} placeholder="Tex. kreditgivare eller bank" />
          <Field type="text" name="providerRef" label="Referens" labelHint="(valfri)" component={TextInput} />
          <FormButtonsFooter
            submitting={submitting}
            submitSucceeded={submitSucceeded}
            onClose={onClose}
            handleSubmit={handleSubmit}
            submitText="Registrera"
          />
        </form>
      </div>
    );
  }
}

const selector = formValueSelector('booking-payment-form');

const mapStateToProps = (state) => {
  return {
    message: selector(state, 'message')
  };
};

export default reduxForm({
  form: 'booking-payment-form'
})(connect(mapStateToProps)(BookingPaymentForm));
