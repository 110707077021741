import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createVoucherTemplate } from '@State/voucher-template-actions';
import { getVoucherTemplateDefaultValues } from '@State/selectors';
import ModalDialog from '@Components/dialogs/modal-dialog';
import VoucherTemplateForm from './voucher-template-form';

const CreateVoucherTemplateModal = ({ onClose }) => {
  const dispatch = useDispatch();
  const defaultValues = useSelector(getVoucherTemplateDefaultValues);

  const handleSubmit = (values) => {
    return dispatch(createVoucherTemplate(values))
      .then(onClose);
  };

  return (
    <ModalDialog
      title="Lägg till presentkortsmall"
      contentSize="large"
      onClose={onClose}
      hideCloseButton
    >
      <VoucherTemplateForm
        initialValues={defaultValues}
        onSubmit={handleSubmit}
        onClose={onClose}
      />
    </ModalDialog>
  );
};

export default CreateVoucherTemplateModal;
