import { defineMessages } from 'react-intl';

export default defineMessages({
  newAccountHeader: {
    id: 'sysadmin.newAccount.newAccountHeader',
    defaultMessage: 'Create New account'
  },
  newAccountCreated: {
    id: 'sysadmin.newAccount.newAccountCreated',
    defaultMessage: 'Account created successfully'
  },
  newAccount: {
    id: 'sysadmin.newAccount.newAccount',
    defaultMessage: 'Create account'
  },
  goBack: {
    id: 'sysadmin.newAccount.goBack',
    defaultMessage: 'Go back'
  },
  edit: {
    id: 'sysadmin.newAccount.edit',
    defaultMessage: 'Change'
  },
  fill: {
    id: 'sysadmin.newAccount.fill',
    defaultMessage: 'Enter details'
  },
  fillPos: {
    id: 'sysadmin.newAccount.fillPos',
    defaultMessage: 'Add cash register'
  },
  org: {
    id: 'sysadmin.newAccount.org',
    defaultMessage: 'Company details'
  },
  owner: {
    id: 'sysadmin.newAccount.owner',
    defaultMessage: 'Contact details'
  },
  invoice: {
    id: 'sysadmin.newAccount.invoice',
    defaultMessage: 'Invoice details'
  },
  booking: {
    id: 'sysadmin.newAccount.booking',
    defaultMessage: 'Booking'
  },
  contract: {
    id: 'sysadmin.newAccount.contract',
    defaultMessage: 'Contract'
  },
  pos: {
    id: 'sysadmin.newAccount.pos',
    defaultMessage: 'Cash register'
  },
  pricing: {
    id: 'sysadmin.newAccount.pricing',
    defaultMessage: 'Abonnemang'
  },
  orgNameLabel: {
    id: 'sysadmin.newAccount.orgNameLabel',
    defaultMessage: 'Company name'
  },
  orgNumberLabel: {
    id: 'sysadmin.newAccount.orgNumberLabel',
    defaultMessage: 'Organisational number'
  },
  locNameDisplayLabel: {
    id: 'sysadmin.newAccount.locNameDisplayLabel',
    defaultMessage: 'Location name'
  },
  locNameLabel: {
    id: 'sysadmin.newAccount.locNameLabel',
    defaultMessage: 'Location name (e.g. Hair Salon)'
  },
  companyAddressLabel: {
    id: 'sysadmin.newAccount.companyAddressLabel',
    defaultMessage: 'Company address'
  },
  ownerNameLabel: {
    id: 'sysadmin.newAccount.ownerNameLabel',
    defaultMessage: 'Customer first and last name'
  },
  ownerEmailLabel: {
    id: 'sysadmin.newAccount.ownerEmailLabel',
    defaultMessage: 'E-mail'
  },
  invoiceIntervalLabel: {
    id: 'sysadmin.newAccount.invoiceIntervalLabel',
    defaultMessage: 'Invoice interval'
  },
  invoiceIntervalMonthly: {
    id: 'sysadmin.newAccount.invoiceIntervalMonthly',
    defaultMessage: 'Monthly'
  },
  invoiceIntervalQuaterly: {
    id: 'sysadmin.newAccount.invoiceIntervalQuaterly',
    defaultMessage: 'Quarterly'
  },
  invoiceIntervalManual: {
    id: 'sysadmin.newAccount.invoiceIntervalManual',
    defaultMessage: 'Manual'
  },
  invoiceMethodLabel: {
    id: 'sysadmin.newAccount.invoiceMethodLabel',
    defaultMessage: 'Invoice method'
  },
  invoiceMethodEmail: {
    id: 'sysadmin.newAccount.invoiceMethodEmail',
    defaultMessage: 'E-mail'
  },
  invoiceMethodPost: {
    id: 'sysadmin.newAccount.invoiceMethodPost',
    defaultMessage: 'Mail'
  },
  vatTypeLabel: {
    id: 'sysadmin.newAccount.vatTypeLabel',
    defaultMessage: 'VAT type'
  },
  vatTypeSEVAT: {
    id: 'sysadmin.newAccount.vatTypeSEVAT',
    defaultMessage: 'Sweden'
  },
  vatTypeEUVAT: {
    id: 'sysadmin.newAccount.vatTypeEUVAT',
    defaultMessage: 'Other EU country'
  },
  vatTypeEXPORT: {
    id: 'sysadmin.newAccount.vatTypeEXPORT',
    defaultMessage: 'Other countries (incl Åland)'
  },
  companyVatNoLabel: {
    id: 'sysadmin.newAccount.companyVatNoLabel',
    defaultMessage: 'Company VAT number'
  },
  invoiceEmailLabel: {
    id: 'sysadmin.newAccount.invoiceEmailLabel',
    defaultMessage: 'Invoice e-mail'
  },
  invoiceAddressLabel: {
    id: 'sysadmin.newAccount.invoiceAddressLabel',
    defaultMessage: 'Invoice address'
  },
  contractLengthLabel: {
    id: 'sysadmin.newAccount.contractLengthLabel',
    defaultMessage: 'Contract length'
  },
  fixedPriceLabel: {
    id: 'sysadmin.newAccount.fixedPriceLabel',
    defaultMessage: 'Pris/mån (Cliento)'
  },
  fixedPriceMaxResourcesLabel: {
    id: 'sysadmin.newAccount.fixedPriceMaxResourcesLabel',
    defaultMessage: 'Included resources'
  },
  fixedPriceMaxLocationsLabel: {
    id: 'sysadmin.newAccount.fixedPriceMaxLocationsLabel',
    defaultMessage: 'Included locations'
  },
  perResourcePriceLabel: {
    id: 'sysadmin.newAccount.perResourcePriceLabel',
    defaultMessage: 'Price per resurce'
  },
  perLocationPriceLabel: {
    id: 'sysadmin.newAccount.perLocationPriceLabel',
    defaultMessage: 'Price per location'
  },
  smsReminderPriceLabel: {
    id: 'sysadmin.newAccount.smsReminderPriceLabel',
    defaultMessage: 'SMS reminder price'
  },
  smsPinPriceLabel: {
    id: 'sysadmin.newAccount.smsPinPriceLabel',
    defaultMessage: 'SMS pin price'
  },
  smsBulkPriceLabel: {
    id: 'sysadmin.newAccount.smsBulkPriceLabel',
    defaultMessage: 'SMS bulk price'
  },
  vunitTypeLabel: {
    id: 'sysadmin.newAccount.vunitTypeLabel',
    defaultMessage: 'Type of cash register'
  },
  vunitTypeOnprem: {
    id: 'sysadmin.newAccount.vunitTypeOnprem',
    defaultMessage: 'With CCU'
  },
  vunitTypeOnpremNoCCU: {
    id: 'sysadmin.newAccount.vunitTypeOnpremNoCCU',
    defaultMessage: 'Without CCU'
  },
  vunitTypeOnpremDemo: {
    id: 'sysadmin.newAccount.vunitTypeOnpremDemo',
    defaultMessage: 'TEST - For evaluation and demo'
  },
  vunitTypeEcom: {
    id: 'sysadmin.newAccount.vunitTypeEcom',
    defaultMessage: 'E-commerce'
  },
  fixedPriceMaxVunitsLabel: {
    id: 'sysadmin.newAccount.fixedPriceMaxVunitsLabel',
    defaultMessage: 'Max number of cash registers'
  },
  perInitialVunitPriceLabel: {
    id: 'sysadmin.newAccount.perInitialVunitPriceLabel',
    defaultMessage: 'Initial CCU price'
  },
  perExtraVunitPriceLabel: {
    id: 'sysadmin.newAccount.perExtraVunitPriceLabel',
    defaultMessage: 'Extra CCU price'
  },
  posAddressLabel: {
    id: 'sysadmin.newAccount.posAddressLabel',
    defaultMessage: 'Address'
  },
  pricePlanLabel: {
    id: 'sysadmin.newAccount.pricePlanLabel',
    defaultMessage: 'Prisplan'
  },
  contractStartDateLabel: {
    id: 'sysadmin.newAccount.contractStartDateLabel',
    defaultMessage: 'Contract start date'
  },
  contractEndDateLabel: {
    id: 'sysadmin.newAccount.contractEndDateLabel',
    defaultMessage: 'Contract end date'
  },
  externalInvoiceFixedPriceLabel: {
    id: 'sysadmin.newAccount.externalInvoiceFixedPriceLabel',
    defaultMessage: 'External invoice fixed price'
  },
  bookingIntroPriceLabel: {
    id: 'sysadmin.newAccount.bookingIntroPriceLabel',
    defaultMessage: 'Booking intro price'
  },
  bookingIntroPriceUntilLabel: {
    id: 'sysadmin.newAccount.bookingIntroPriceUntilLabel',
    defaultMessage: 'Booking intro price until'
  }
});
