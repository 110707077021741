import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@Components/ui/button';
import ModalDialog from '@Components/dialogs/modal-dialog';
import StaffAssignCashierRolesOrg from '@Components/admin/staff-config/staff-assign-cashier-roles-org';
import { setUserPosRole, deleteUserPosRole } from '@State/users-actions';
import { ModalContent, ModalFooter } from '@Components/dialogs/dialog-styles';

const StaffAssignCashierRoles = ({ id, name, onClose }) => {
  const dispatch = useDispatch();
  const posOrgs = useSelector(state => state.posOrgs);
  const usersById = useSelector(state => state.usersById);
  const user = usersById.get(id);
  const posRoles = user.get('posRoles');

  const handlerChangeToggle = (posOrgId, cashierRole, deleteUserPos) => {
    if (deleteUserPos) {
      return dispatch(deleteUserPosRole(id, posOrgId));
    }
    dispatch(setUserPosRole(id, posOrgId, cashierRole));
  };

  const getPosRole = (posOrg) => {
    const posRoleDefault = {
      cashierRole: '',
      posOrgId: posOrg.get('id')
    };
    let posRole;
    if (posRoles?.size > 0) {
      posRole = posRoles.find(p => p.get('posOrgId') === posOrg.get('id'));
    }
    return posRole ? posRole.toJS() : posRoleDefault;
  };

  return (
    <ModalDialog
      contentSize="medium"
      title={`Rättigheter för ${name}`}
      hideCloseButton
      onClose={onClose}
    >
      <ModalContent>
        {posOrgs.map((posOrg) => (
          <StaffAssignCashierRolesOrg
            key={posOrg.get('id')}
            onChangeToggle={handlerChangeToggle}
            posRole={getPosRole(posOrg)}
            posOrg={posOrg}
          />
        ))}
      </ModalContent>
      <ModalFooter>
        <Button gray small onClick={onClose}>Stäng</Button>
      </ModalFooter>
    </ModalDialog>
  );
};

StaffAssignCashierRoles.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  onClose: PropTypes.func
};

export default StaffAssignCashierRoles;
