import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import JSONEditorForm from '@Components/ui/jsoneditor/jsoneditor-form';

const PrefsAttribsJsonEditor = ({
  routeParams, onUpdatePrefs, onUpdateAttr, attributes, prefs
}) => (
  <>
    <JSONEditorForm
      id={routeParams.id}
      title="Prefs"
      json={prefs}
      onSubmit={onUpdatePrefs}
    />
    <br />
    <JSONEditorForm
      id={routeParams.id}
      title="Attributes"
      json={attributes}
      onSubmit={onUpdateAttr}
    />
  </>
);

PrefsAttribsJsonEditor.propTypes = {
  routeParams: PropTypes.object,
  onUpdatePrefs: PropTypes.func,
  onUpdateAttr: PropTypes.func,
  attributes: PropTypes.object,
  prefs: PropTypes.object
};

export default PrefsAttribsJsonEditor;
