import outdatedBrowser from 'outdated-browser-rework';
import '@Utils/outdated-browser/style.less';

outdatedBrowser({
  browserSupport: {
    Chrome: 60, // Includes Chrome for mobile devices
    Edge: 40,
    Safari: 10,
    'Mobile Safari': { major: 10, minor: 1 },
    Firefox: 60,
    Opera: false,
    Vivaldi: false,
    // You could specify minor version too for those browsers that need it.
    Yandex: false,
    // You could specify a version here if you still support IE in 2017.
    // You could also instead seriously consider what you're doing with your time and budget
    IE: false
  },
  requireChromeOnAndroid: true,
  isUnknownBrowserOK: true,
  messages: {
    en: {
      outOfDate: 'Din webbläsare är för gammal',
      unsupported: 'Din webblälsare stöds inte',
      update: {
        web: 'Uppdatera din webbläsare för att använda Cliento. Vi rekommenderar Chrome, Firefox, Safari eller Edge',
        googlePlay: 'Vänligen installera Chrome från Google Play',
        appStore: 'Vänligen uppdatera iOS'
      },
      // You can set the URL to null if you do not want a clickable link or provide
      // your own markup in the `update.web` message.
      url: null,
      callToAction: 'Update my browser now',
      close: 'Stäng'
    }
  }
});
