import React, { useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';

import { updateOrgInvoiceCustomer } from '@State/sysadmin-actions';
import AccountOverviewContext
  from '@Components/sysadmin/accounts/account-detail/account-overview/account-overview-context';
import AccountOverviewBlock
  from '@Components/sysadmin/accounts/account-detail/account-overview/account-overview-block';
import ButtonModalForm from '@Components/dialogs/button-modal-form';

import AccountInvoiceCustomerForm from '../account-invoice-customer-form';

const getAddress = (invoiceCustomer) => invoiceCustomer ? (
  <div>
    <div>{invoiceCustomer.address1 ?? ''}</div>
    {invoiceCustomer.address2 && <div>{invoiceCustomer.address2}</div>}
    {invoiceCustomer.postCode} {invoiceCustomer.area} {invoiceCustomer.countryCode && invoiceCustomer.countryCode}
  </div>
) : '-';

const invoiceFields = [
  [
    { prop: 'invoiceInterval', title: 'Fakturaintervall' },
    { prop: 'invoiceMethod', title: 'Fakturaleverans' },
    { prop: 'fortnoxCustomerNumber', title: 'Fortnox kund id' }
  ],
  [
    { prop: 'customerRef', title: 'Kund ref' },
    { prop: 'invoiceAddress', getProp: (d) => getAddress(d.toJS()), title: 'Fakturaadress' },
    { prop: 'invoiceEmail', title: 'E-post för faktura' }
  ],
  [
    { prop: 'companyName', title: 'Företagsnamn' },
    { prop: 'companyRegNo', title: 'Org.nummer' },
    { prop: 'companyVatNo', getProp: (d) => `${d.get('companyVatNo')} (${d.get('vatType')})`, title: 'Momsreg. nummer (typ)' }
  ]
];

const AccountOverviewInvoice = () => {
  const dispatch = useDispatch();
  const { id, subscription } = useContext(AccountOverviewContext);
  const invoiceCustomer = subscription.get('invoiceCustomer');

  const onSubmitInvoiceCustomer = useCallback((customer) => {
    return dispatch(updateOrgInvoiceCustomer({ orgId: id, ...customer }));
  }, []);

  return (
    <>
      {invoiceCustomer && (
        <AccountOverviewBlock
          data={invoiceCustomer}
          title="Fakturauppgifter"
          columnsQuan={3}
          columns={invoiceFields}
          button={(
            <ButtonModalForm
              link
              onSubmit={onSubmitInvoiceCustomer}
              btnText="Update invoice customer"
            >
              <AccountInvoiceCustomerForm initialValues={invoiceCustomer.toJS()} />
            </ButtonModalForm>
          )}
        />
      )}
    </>
  );
};

export default AccountOverviewInvoice;
