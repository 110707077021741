import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { mergeOrgSettings } from '@State/pos-config-actions';
import { Panel } from '@Components/ui/styled/main';
import LabelSwitchInput from '@Components/ui/label-switch-input';

const PosOrgConfigToggle = ({
  posOrg, preference, label, description, children = null,
  onActivate = null, isChild = false, ...prefs
}) => {
  const dispatch = useDispatch();
  const onToggle = useCallback(() => {
    const newValue = !posOrg.getIn(['prefs', preference]);
    return dispatch(mergeOrgSettings(posOrg.get('id'), {
      [preference]: newValue
    })).then(() => {
      if (onActivate && newValue) {
        onActivate();
      }
    });
  }, [preference, posOrg]);

  const isOn = !!posOrg.getIn(['prefs', preference]);

  const formGroup = (
    <div className="form-group">
      <LabelSwitchInput
        noBorder
        label={label}
        id={preference}
        isOn={isOn}
        handleToggle={onToggle}
        description={description}
        {...prefs}
      />
    </div>
  );

  return isChild ? (
    formGroup
  ) : (
    <Panel maxWidth="550px">
      {formGroup}
      {isOn && children}
    </Panel>
  );
};

export default PosOrgConfigToggle;
