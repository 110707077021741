import React, {
  useCallback, memo
} from 'react';
import { connect, useSelector } from 'react-redux';
import { reduxForm } from 'redux-form';

import { staffJournalToggle, updateUserPosRoleToggle, setUserLogin } from '@State/users-actions';
import StaffLoginEnabled from '@Components/admin/staff-config/staff-login-enabled';
import StaffJournal from '@Components/admin/staff-config/staff-journal';
import StaffPosRoles from '@Components/admin/staff-config/staff-pos-roles';
import StaffApiKeys from '@Components/admin/staff-config/staff-apikeys';
import { getFeatures } from '@State/selectors';
import StaffResources from './staff-resources';
import StaffEmployment from './staff-employment';
import StaffSalesTerms from './staff-sales-terms';

const StaffConfigForm = ({
  handleUpdateUser, dispatch, user, isLoggedInUser, isAdministrator, isOwner, isSysAdmin, initialValues
}) => {
  const { id } = user;

  const { EnablePOS } = getFeatures(useSelector(state => state));

  const onChangeSelect = useCallback((e) => {
    const newUser = { ...user, [e.target.name]: e.target.value };
    return handleUpdateUser(newUser);
  }, [user]);

  const onChangeToggle = useCallback(({ target: { name, checked } }) => {
    const newUser = { ...user, [name]: !!checked };

    switch (name) {
      case 'loginEnabled':
        return dispatch(setUserLogin(id, checked));
      case 'posRoleEnabled':
        return dispatch(updateUserPosRoleToggle(id, checked));
      case 'showInStaffJournal':
        return onToggleStaffJournal(checked);
      default:
        return handleUpdateUser(newUser);
    }
  }, [id]);

  const onToggleStaffJournal = (checked) => {
    return dispatch(staffJournalToggle(checked, id));
  };

  const handleSubmit = (e) => {
    // if user pressed enter need to stop it
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <StaffResources userId={id} />
      <StaffLoginEnabled
        id={id}
        user={user}
        onChange={onChangeSelect}
        dispatch={dispatch}
        isOn={!!user.loginEnabled}
        loginType={user.loginType}
        onChangeToggle={onChangeToggle}
        isLoggedInUser={isLoggedInUser}
        isAdministrator={isAdministrator}
        isOwner={isOwner}
        isSysAdmin={isSysAdmin}
        resourceId={initialValues.resourceId}
      />
      <StaffJournal
        dispatch={dispatch}
        id={id}
        isOn={!!user.showInStaffJournal}
        pnoState={user.pno}
        onChangeToggle={onChangeToggle}
      />
      {EnablePOS && (
        <StaffPosRoles
          dispatch={dispatch}
          id={id}
          name={user.name}
          operatorPin={user.operatorPin}
          posRoles={user.posRoles}
          isOn={user.posRoleEnabled || false}
          onChangeToggle={onChangeToggle}
        />
      )}
      <StaffEmployment user={user} />
      <StaffSalesTerms user={user} />
      <StaffApiKeys user={user} />
    </form>
  );
};

const StaffConfigFormRedux = reduxForm({
  form: 'staff-config-form',
  enableReinitialize: true
})(StaffConfigForm);

export default connect((state, { user }) => {
  const resources = user?.associatedResources;
  const resourceId = resources?.length > 0 ? resources[0].resourceId : null;
  return {
    initialValues: { ...user, resourceId }
  };
})(memo(StaffConfigFormRedux));
