import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { sms } from '@Utils/preference-keys';
import { formatPhoneNumber, isMobile } from '@Utils/phone-util';
import { getSmsEnabled } from '@State/selectors';
import { sendSms } from '@State/customer-actions';
import ModalDialog from '@Components/dialogs/modal-dialog';
import SendSmsForm from '@Components/customers/customer-detail/send-sms/send-sms-form';
import DialogAlert from '@Components/dialogs/dialog-alert';
import { txt } from '@Utils/i18n-util';
import msg from './send-sms-modal.msg';

const SendSmsModal = ({ customerId, phoneNumber, onClose, onSmsSent }) => {
  const dispatch = useDispatch();
  const sender = useSelector(state => {
    const smsSender = state.locationConfig.get(sms.smsSender);
    return isMobile(smsSender) ? formatPhoneNumber(smsSender) : smsSender;
  });
  const smsEnabled = useSelector(state => getSmsEnabled(state));

  const [error, setError] = useState(null);
  const [smsSent, setSmsSent] = useState(false);

  const onSubmit = (values) => {
    return dispatch(sendSms(customerId, values))
      .then(() => {
        setSmsSent(true);
        if (onSmsSent) {
          onSmsSent();
        }
      }).catch((error) => {
        setError(error);
        throw error;
      });
  };

  if (!smsEnabled) {
    return (
      <DialogAlert
        warning
        title={txt(msg.sendSmsTitle)}
        icon="fa fa-exclamation-triangle"
        text={txt(msg.smsNotEnabledText)}
        onClose={onClose}
      />
    );
  }

  if (smsSent) {
    return (
      <DialogAlert
        success
        title={txt(msg.sendSmsTitle)}
        icon="fa fa-check-circle"
        text={txt(msg.smsSentText)}
        onClose={onClose}
      />
    );
  }

  return (
    <ModalDialog
      hideCloseButton
      contentSize="medium"
      title={txt(msg.sendSmsTitle)}
      closeButtonText={txt(msg.btnClose)}
      closeOnClickOutside={false}
      onClose={onClose}
    >
      <SendSmsForm
        onClose={onClose}
        onSubmit={onSubmit}
        submitError={error}
        initialValues={{
          sender,
          msisdn: formatPhoneNumber(phoneNumber)
        }}
      />
    </ModalDialog>
  );
};

export default SendSmsModal;
