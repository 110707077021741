import axios from 'axios';
import {
  axiosDefault, axiosErrorHandler, checkStatusAxios, prefixUrl
} from '@Utils/ajax-util';

export const POS_GIFT_CARDS_FETCHED = 'POS_GIFT_CARDS_FETCHED';
export const POS_GIFT_CARD_CREATED = 'POS_GIFT_CARD_CREATED';
export const POS_GIFT_CARD_UPDATED = 'POS_GIFT_CARD_UPDATED';
export const POS_GIFT_CARD_VOIDED = 'POS_GIFT_CARD_VOIDED';

export function fetchGiftCards(posOrgId) {
  return dispatch => {
    const url = prefixUrl(`/voucher/org/${posOrgId}/giftcards/`);
    const config = axiosDefault();

    return axios
      .get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then((giftCards) => dispatch({ type: POS_GIFT_CARDS_FETCHED, giftCards }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function fetchGiftCardById(id) {
  return dispatch => {
    const url = prefixUrl(`/voucher/${id}`);
    const config = axiosDefault();

    return axios
      .get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data);
  };
}

export function fetchGiftCardByCode(code) {
  return dispatch => {
    const url = prefixUrl('/voucher/bycode');
    const config = axiosDefault();

    return axios
      .post(url, { code }, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data);
  };
}

export function createGiftCard(vunitId, data) {
  return (dispatch) => {
    const url = prefixUrl(`/voucher/vunit/${vunitId}/giftcards/create`);
    const config = axiosDefault();

    return axios
      .post(url, data, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then((giftCard) => {
        dispatch({ type: POS_GIFT_CARD_CREATED, giftCard });
        return giftCard;
      })
      .catch(error => {
        axiosErrorHandler(error, dispatch);
        throw error;
      });
  };
}

export function importGiftCards(posOrgId, vouchers) {
  return (dispatch) => {
    const url = prefixUrl(`/voucher/org/${posOrgId}/import-vouchers`);
    const config = axiosDefault();

    return axios
      .post(url, { vouchers }, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .catch(error => {
        axiosErrorHandler(error, dispatch);
        throw error;
      });
  };
}

export function voidGiftCard(id) {
  return (dispatch) => {
    const url = prefixUrl(`/voucher/${id}/void`);
    const config = axiosDefault();

    return axios
      .put(url, null, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => dispatch({ type: POS_GIFT_CARD_VOIDED, id }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function updateGiftCard(id, values) {
  return (dispatch) => {
    const url = prefixUrl(`/voucher/${id}`);
    const config = axiosDefault();

    return axios
      .put(url, values, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then((giftCard) => {
        dispatch({ type: POS_GIFT_CARD_UPDATED, giftCard });
        return giftCard;
      })
      .catch(error => {
        axiosErrorHandler(error, dispatch);
        throw error;
      });
  };
}

export function generateGiftCardCode() {
  return dispatch => {
    const url = prefixUrl('/voucher/generate-code');
    const config = axiosDefault();

    return axios
      .get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function validateGiftCardCode(code) {
  return dispatch => {
    const url = prefixUrl(`/voucher/validate-code?code=${encodeURIComponent(code)}`);
    const config = axiosDefault();

    return axios
      .get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function getGiftCardConfig(vunitId) {
  return dispatch => {
    const url = prefixUrl(`/voucher/vunit/${vunitId}/config`);
    const config = axiosDefault();

    return axios
      .get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}
