import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  addTerminalAssociation, reconcileTerminal, updateTerminal
} from '@State/pos-config-actions';
import { getSysAdmin } from '@State/selectors';
import {
  POSTitle, ButtonWrap, GrayText, PosContentWrap,
  PosConfigCell, PosConfigRow
} from '@Components/admin/pos-config/style';
import PosTerminalStatus from '@Components/admin/pos-config/pos-terminal-status';
import ProgressButton from '@Components/ui/progress-button';
import { Button, Panel } from '@Components/ui/styled/main';
import TerminalForm from '@Components/admin/pos-config/terminal-form';
import TerminalAssociateForm from '@Components/admin/pos-config/terminal-associate-form';
import TerminalTools from './terminal-tools';

class TerminalPanel extends PureComponent {
  state = {
    editForm: false,
    associateForm: false
  };

  showEditForm = () => this.setState({ editForm: true });

  showAssociateForm = () => this.setState({ associateForm: true });

  hideForm = (ev) => {
    ev && ev.preventDefault();
    this.setState({ editForm: false, associateForm: false });
  };

  editTerminal = (values) => {
    return this.props.updateTerminal(values)
      .then(() => this.hideForm());
  };

  associateTerminal = (values) => {
    return this.props.addTerminalAssociation(values)
      .then(() => this.hideForm());
  };

  render() {
    const {
      terminal, posUnit, reconcileTerminal,
      allTerminals, allOnpremPosUnits
    } = this.props;
    const { id, name, vendorId, vendorDeviceString } = terminal.toJS();
    const { editForm, associateForm } = this.state;
    const vunitId = posUnit?.get('id');
    const merchantId = posUnit?.get('merchId');

    const handleReconcile = () => reconcileTerminal(id, vunitId);

    return (
      <>
        <Panel>
          <PosConfigRow>
            <PosConfigCell>
              <POSTitle alignItemsCenter>
                <PosTerminalStatus terminal={terminal} />
                {name || vendorDeviceString || 'Okänd terminal'}
              </POSTitle>
              <PosContentWrap className="select-text">
                <p>
                  Vendor ID / Terminal ID: {vendorId}
                  {merchantId && (
                    <div className="text-muted">
                      Merchant ID / Bax ID: {merchantId}
                    </div>
                  )}
                </p>
                {posUnit ? (
                  <div>
                    <div className="label-block label-gray">
                      {posUnit.get('posOrgName')} - {posUnit.get('name')}
                    </div>
                  </div>
                ) : (
                  <div className="select-text">
                    {allOnpremPosUnits.filter(p => p.get('terminalId') === id).map(posUnit => (
                      <div key={posUnit.get('id')} className="label-block label-gray">
                        {posUnit.get('posOrgName')} - {posUnit.get('name')}
                        {posUnit.get('merchId') && ` (BAX: ${posUnit.get('merchId')})`}
                      </div>
                    ))}
                  </div>
                )}
              </PosContentWrap>
            </PosConfigCell>
            <PosConfigCell>
              <ButtonWrap>
                {vunitId && <ProgressButton small outlinePrimary onClick={handleReconcile}>Dagsavslut</ProgressButton>}
                <Button small marginLeft outlinePrimary onClick={this.showEditForm}>Inställningar</Button>
                {!vunitId && <Button small marginLeft outlinePrimary onClick={this.showAssociateForm}>Koppla till kassa</Button>}
                <TerminalTools terminalId={terminal.get('id')} vunitId={vunitId} />
              </ButtonWrap>
            </PosConfigCell>
          </PosConfigRow>
        </Panel>
        {editForm && (
          <TerminalForm
            initialValues={terminal.toJS()}
            onSubmit={this.editTerminal}
            onClose={this.hideForm}
          />
        )}
        {associateForm && (
          <TerminalAssociateForm
            onSubmit={this.associateTerminal}
            onClose={this.hideForm}
            terminals={allTerminals}
            posUnits={allOnpremPosUnits}
            initialValues={{ terminalId: id, vunitId }}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { allTerminals, allOnpremPosUnits } = state;

  return {
    allTerminals,
    allOnpremPosUnits,
    isSysAdmin: getSysAdmin(state)
  };
};

const mapDispatchToProps = dispatch => ({
  updateTerminal: values => dispatch(updateTerminal(values)),
  reconcileTerminal: (terminalId, vunitId) => dispatch(reconcileTerminal(terminalId, vunitId)),
  addTerminalAssociation: (data) => dispatch(addTerminalAssociation(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(TerminalPanel);
