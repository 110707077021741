import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchUserNotifications, setUserNotifications } from '@State/user-actions';
import ModalDialog from '@Components/dialogs/modal-dialog';
import Button from '@Components/ui/button';
import { ModalContent, ModalFooter } from '@Components/dialogs/dialog-styles';
import Notifications from '@Components/user-config/notifications';
import AlertWithIcon from '@Components/ui/alert-with-icon';
import { notification } from '@Utils/preference-keys';

const StaffUserNotifications = ({ user, onClose }) => {
  const dispatch = useDispatch();
  const [notifications, setNotifications] = useState(null);

  useEffect(() => {
    dispatch(fetchUserNotifications(user.get('loginId')))
      .then((notifications) => setNotifications(notifications));
  }, []);

  const onChangeNotification = (key, value) => {
    const newNotifications = {
      ...notifications,
      [key]: value
    };
    dispatch(setUserNotifications(user.get('loginId'), newNotifications));
    setNotifications(newNotifications);
  };

  return (
    <ModalDialog
      contentSize="large"
      title="Notiser"
      closeButtonText="Stäng"
      onClose={onClose}
    >
      <ModalContent>
        <AlertWithIcon icon="fa fa-info-circle">
          Notiser skickas endast för de resurser som är kopplade till användaren.
        </AlertWithIcon>
        <Notifications
          title="Mejlnotiser"
          username={user.get('username')}
          keys={Object.values(notification.email)}
          prefs={notifications}
          onChange={onChangeNotification}
        />
        <Notifications
          title="Pushnotiser"
          keys={Object.values(notification.push)}
          prefs={notifications}
          onChange={onChangeNotification}
        />
      </ModalContent>
      <ModalFooter>
        <Button gray small onClick={onClose}>Stäng</Button>
      </ModalFooter>
    </ModalDialog>
  );
};

export default StaffUserNotifications;
