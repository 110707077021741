import { defineMessages } from 'react-intl';

export default defineMessages({
  customerDetails: {
    id: 'customers.form.tabs.customerDetails',
    defaultMessage: 'Customer details'
  },
  address: {
    id: 'customers.form.tabs.address',
    defaultMessage: 'Address'
  }
});
