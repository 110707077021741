import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getCustomerTabSections } from '@Components/customers/customer-detail/selectors';

const CustomerModalHeader = ({ selectedTab, setSelectedTab }) => {
  const sections = useSelector(getCustomerTabSections);

  const onSelect = (e, tab) => {
    e.preventDefault();
    setSelectedTab(tab);
  };

  return (
    <ul className="tabs__list">
      {sections.map((item, index) => {
        return (
          <li
            key={index}
            onClick={e => onSelect(e, item.navId)}
            className={selectedTab === item.navId ? 'tabs__item tabs__item--active' : 'tabs__item'}
          >
            <a href={`#${item.navId}`}>{item.name}</a>
          </li>
        );
      })}
    </ul>
  );
};

CustomerModalHeader.propTypes = {
  selectedTab: PropTypes.string,
  setSelectedTab: PropTypes.func
};

export default memo(CustomerModalHeader);
