import React, { Component } from 'react';

class BookingAllDay extends Component {
  handleAllDayClick = (ev) => {
    ev.preventDefault();
    ev.target.blur();
    this.props.onAllDay();
  };

  render() {
    const { isSchedule, isAllDay } = this.props;

    return (
      <a href="#" onClick={this.handleAllDayClick} className={isAllDay ? 'disabled' : ''}>
        <i className="fa fa-fw fa-arrows-to-dotted-line" />{' '}
        {isSchedule ? 'Stäng hela dagen' : 'Blockera hela dagen'}
      </a>
    );
  }
}

export default BookingAllDay;
