import { createSelector } from 'reselect';

const getUsersById = state => state.usersById;
const getInvitesById = state => state.invitesById;
const getResourcesById = state => state.resourcesById;
const getCurrentId = (state, props) => props.id || props.match.params.id;

export const getCurrentUser = createSelector(
  getCurrentId,
  getUsersById,
  getInvitesById,
  (id, usersById, invitesById) => {
    if (/^\d+$/.test(id)) {
      return usersById.get(parseInt(id));
    }
    if (id) {
      return invitesById.get(id);
    }
    return null;
  }
);

export function hasAssociatedResource(user, resourceId) {
  return user?.get('associatedResources')
    .find(r => r.get('resourceId') === resourceId) != null;
}

export const getAvailableResources = createSelector(
  getCurrentUser,
  getUsersById,
  getInvitesById,
  getResourcesById,
  (user, usersById, invitesById, resourcesById) => {
    return resourcesById.filter((resource, resourceId) => {
      return hasAssociatedResource(user, resourceId)
        || (usersById.every(user => !hasAssociatedResource(user, resourceId))
        && invitesById.every(user => !hasAssociatedResource(user, resourceId)));
    });
  }
);

export function hasUserGroupAccess(user, groupId) {
  return user?.get('entityAcls')
    .find(acl => groupId && acl.get('entity') === 'Grp' && acl.get('entityId') === groupId) != null;
}

export function hasUserResourceAccess(user, resourceId) {
  return user?.get('entityAcls')
    .find(acl => resourceId && acl.get('entity') === 'Res' && acl.get('entityId') === resourceId) != null;
}
