import { web } from '@Utils/preference-keys';

export const CUSTOM = 'CUSTOM';
export const INTERVAL = 'INTERVAL';
export const BEFORE_AFTER = 'BEFORE_AFTER';
export const FIRST_AVAILABLE = 'FIRST_AVAILABLE';

export const intervalOptions = [180, 120, 90, 75, 60, 45, 40, 30, 15, 10, 5];

function hasValue(value) {
  return value !== null && value !== undefined;
}

function getValue(value) {
  if (value === 'true' || value === 'false') {
    return value === 'true';
  }
  return value;
}

function extractSlotFinderParams(data, params) {
  data.interval = 90;
  data.breakPoints = '00:00';
  data.excludeLastInDay = false;
  data.useIntervalFallback = true;
  data.intervalFallbackThreshold = 6;

  if (params) {
    for (const param of params.split(';')) {
      const items = param.split('=');
      data[items[0]] = getValue(items[1]);
    }
  }
  if (intervalOptions.indexOf(parseInt(data.interval)) === -1) {
    data.customInterval = data.interval;
    data.interval = CUSTOM;
  }
  return data;
}

function getInterval(data) {
  const interval = data.interval === CUSTOM ? data.customInterval : data.interval;
  return Math.abs(parseInt(interval || 0));
}

function getSlotFinderParams(data, slotFinderStrategy) {
  let params = '';
  switch (slotFinderStrategy) {
    case INTERVAL:
      params = `interval=${getInterval(data)}`;
      break;
    case BEFORE_AFTER:
      params = `excludeLastInDay=${data.excludeLastInDay};useIntervalFallback=${data.useIntervalFallback
      };interval=${getInterval(data)};intervalFallbackThreshold=${data.intervalFallbackThreshold}`;
      break;
    case FIRST_AVAILABLE:
      params = `findGaps=true;breakPoints=${data.breakPoints ?? '00:00'}`;
      break;
  }
  delete data.interval;
  delete data.customInterval;
  delete data.breakPoints;
  delete data.excludeLastInDay;
  delete data.useIntervalFallback;
  delete data.intervalFallbackThreshold;
  return params;
}

export function mapPrefsToForm(values) {
  extractSlotFinderParams(values, values[web.slotFinderParams]);

  if (hasValue(values[web.cancelUpToMinutes])) {
    values.cancelUpToHours = values[web.cancelUpToMinutes] / 60;
  }
}

export function mapFormToPrefs(values) {
  values[web.slotFinderParams] = getSlotFinderParams(values, values[web.slotFinderStrategy]);

  if (hasValue(values.cancelUpToHours)) {
    values[web.cancelUpToMinutes] = values.cancelUpToHours * 60;
    delete values.cancelUpToHours;
  }
}
