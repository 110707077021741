import React from 'react';
import has from 'lodash/has';
import groupBy from 'lodash/groupBy';
import { reportingCount, reportingFormat } from '@Utils/format';
import { getTotal } from '@Components/reports/reports-helpers';

const renderSaleCategoryRow = (description, items) => {
  const saleQty = getTotal(items, 'saleQty');
  const refundQty = getTotal(items, 'refundQty');
  const refundAmount = getTotal(items, 'refundAmount');
  const discountAmount = getTotal(items, 'discountAmount');
  const netAmountExVat = getTotal(items, 'netAmountExVat');
  const netAmount = getTotal(items, 'netAmount');

  return (
    <tr>
      <td>{description}</td>
      <td className="text-right">{reportingCount(saleQty)}</td>
      <td className="text-right">{reportingCount(refundQty)}</td>
      <td className="text-right">{reportingFormat(refundAmount)}</td>
      <td className="text-right">{reportingFormat(discountAmount)}</td>
      <td className="text-right">{reportingFormat(netAmountExVat)}</td>
      <td className="text-right">{reportingFormat(netAmount)}</td>
    </tr>
  );
};

const SalesReportCategoryRows = ({ salesRows }) => {
  const groupedItems = groupBy(salesRows, 'type');
  return (
    <table className="table table-condensed table-report">
      <thead>
        <tr>
          <th className="col-xs-4">Beskrivning</th>
          <th className="text-right">Antal köp</th>
          <th className="text-right">Antal återköp</th>
          <th className="text-right">Återköpt</th>
          <th className="text-right">Rabatter</th>
          <th className="text-right">Exkl. moms</th>
          <th className="text-right">Inkl. moms</th>
        </tr>
      </thead>
      <tbody>
        {has(groupedItems, 'Service') && renderSaleCategoryRow('Tjänster', groupedItems.Service)}
        {has(groupedItems, 'Product') && renderSaleCategoryRow('Produkter', groupedItems.Product)}
        {has(groupedItems, 'VoucherSale') && renderSaleCategoryRow('Presentkort', groupedItems.VoucherSale)}
      </tbody>
      <tfoot>
        <tr>
          <th>Summa</th>
          <th className="text-right">{reportingCount(getTotal(salesRows, 'saleQty'))}</th>
          <th className="text-right">{reportingCount(getTotal(salesRows, 'refundQty'))}</th>
          <th className="text-right">{reportingFormat(getTotal(salesRows, 'refundAmount'))}</th>
          <th className="text-right">{reportingFormat(getTotal(salesRows, 'discountAmount'))}</th>
          <th className="text-right">{reportingFormat(getTotal(salesRows, 'netAmountExVat'))}</th>
          <th className="text-right">{reportingFormat(getTotal(salesRows, 'netAmount'))}</th>
        </tr>
      </tfoot>
    </table>
  );
};

export default SalesReportCategoryRows;
