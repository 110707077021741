import moment from 'moment';
import React, { useState } from 'react';
import Popover from '@Components/ui/popover';
import TimePickerRow from '@Components/inputs/time-picker-row';
import { DialogButtonsHorizontal, DialogButton } from '@Components/dialogs/dialog-styles';
import { getTimeHours } from '@Utils/time-util';
import Button from '@Components/ui/button';
import useKeyPress from '@Hooks/useKeyPress';

const OngoingExtendPopover = ({ start, end, onSubmit }) => {
  const [loading, setLoading] = useState(false);
  const [showPopover, setShowPopover] = useState(false);
  const [startTime, setStartTime] = useState({ time: getTimeHours(start) });
  const [endTime, setEndTime] = useState({ time: getTimeHours(end) });

  useKeyPress(27, () => setShowPopover(false));
  useKeyPress(13, () => handleSubmit());

  const onChangeTime = (isStartTime, newTime) => {
    if (isStartTime) {
      setStartTime({ time: newTime });
    } else {
      setEndTime({ time: newTime });
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    const startMoment = moment(`${start.format('YYYY-MM-DD')}T${startTime.time}`);
    const endMoment = moment(`${end.format('YYYY-MM-DD')}T${endTime.time}`);
    return onSubmit(startMoment, endMoment)
      .finally(() => {
        setLoading(false);
        setShowPopover(false);
      });
  };

  const getBody = () => {
    return (
      <div className="Popover-dialog">
        <TimePickerRow
          disabled={loading}
          timers={[startTime, endTime]}
          isAddGrayWrap={false}
          onChange={onChangeTime}
        />
        <DialogButtonsHorizontal>
          <DialogButton disabled={loading} small gray onClick={() => setShowPopover(false)}>Avbryt</DialogButton>
          <DialogButton loading={loading} small primary onClick={handleSubmit}>Förläng</DialogButton>
        </DialogButtonsHorizontal>
      </div>
    );
  };

  return (
    <Popover
      isOpen={showPopover}
      preferPlace="row"
      className="Popover-big-radius"
      onOuterAction={() => setShowPopover(false)}
      body={getBody()}
    >
      <Button tiny white onClick={() => setShowPopover(true)}>
        Förläng
      </Button>
    </Popover>
  );
};

export default OngoingExtendPopover;
