import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createCustomer } from '@State/customer-actions';
import { EXPORT_CUSTOMERS, hasPermission } from '@Utils/permissions';
import { getPermissions, getRouteParams } from '@State/selectors';
import CustomerExportDialog from '@Components/customers/customer-export-dialog';
import AddCustomerModal from '@Components/customers/add-customer-modal';
import Header, { Toolbar } from '@Components/nav/header';
import { getCustomersUrl, navigate } from '@Utils/navigate';
import { txt } from '@Utils/i18n-util';
import msg from './customer-header.msg';

class CustomerHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showExport: false,
      showNewCustomerForm: false
    };
  }

  onAddCustomer = (ev) => {
    ev.target.blur();
    ev.preventDefault();
    this.setState({ showNewCustomerForm: true });
  };

  exportCustomers = (ev) => {
    ev.target.blur();
    ev.preventDefault();
    this.setState({ showExport: true });
  };

  onHideExport = () => {
    this.setState({ showExport: false });
  };

  onHideNewCustomerForm = () => {
    this.setState({ showNewCustomerForm: false });
  };

  onNewCustomerCreated = async ({ customerId }) => {
    navigate(getCustomersUrl(this.props.routeParams, customerId));
    this.onHideNewCustomerForm();
  }

  render() {
    const { showExport, showNewCustomerForm } = this.state;
    const { routeParams, permissions, totalCount } = this.props;
    const canExport = hasPermission(permissions, EXPORT_CUSTOMERS);

    return (
      <>
        <Header
          routeParams={routeParams}
          className="admin-header customers-header"
          title="Kundregister"
        >
          <Toolbar>
            <button type="button" className="btn btn-cta" onClick={this.onAddCustomer}>{txt(msg.addBtnLabel)}</button>

            {canExport && (
              <>
                <button type="button" className="btn btn-cta-secondary" onClick={this.exportCustomers}>{txt(msg.downloadBtnLabel)}</button>
              </>
            )}
          </Toolbar>
        </Header>
        {showExport && <CustomerExportDialog onClose={this.onHideExport} />}
        {showNewCustomerForm && (
          <AddCustomerModal
            routeParams={routeParams}
            onCreated={this.onNewCustomerCreated}
            onClose={this.onHideNewCustomerForm}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    routeParams: getRouteParams(state, ownProps),
    permissions: getPermissions(state, ownProps)
  };
};

const mapDispatchToProps = (dispatch) => ({
  createNewCustomer: (values) => dispatch(createCustomer(values))
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerHeader);
