import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import DDModern, {
  DDStyleBody, DDTextTitle, DDWrapper, DDList, DDListItem, TextEllipsisStyledItem
} from '@Components/ui/drop-down/drop-down-modern';
import { DDModernStyled, ListStyle } from '@Components/admin/json-prefs/admin-config-style';
import { setLocationConfig } from '@State/account-actions';
import { config } from '@Utils/preference-keys';

const AdminConfigI18n = ({ supportedTimeZones, supportedLocales, supportedRegions, supportedCurrencies }) => {
  const dispatch = useDispatch();
  const locationConfig = useSelector(state => state.locationConfig);
  const timeZone = locationConfig.get(config.timeZone);
  const locale = locationConfig.get(config.locale);
  const region = locationConfig.get(config.region);
  const currency = locationConfig.get(config.currency);

  const onSelectTimeZone = useCallback((timeZone) => {
    dispatch(setLocationConfig({ timeZone }));
  }, []);
  const onSelectLocale = useCallback((locale) => {
    dispatch(setLocationConfig({ locale }));
  }, []);
  const onSelectRegion = useCallback((region) => {
    dispatch(setLocationConfig({ region }));
  }, []);
  const onSelectCurrency = useCallback((currency) => {
    dispatch(setLocationConfig({ currency }));
  }, []);

  return (
    <ListStyle>
      <DDStyleBody>
        <DDTextTitle>Tidszon</DDTextTitle>
        <DDModernStyled listTitle={supportedTimeZones[timeZone] || timeZone}>
          <DDWrapper>
            <DDList>
              {Object.entries(supportedTimeZones).map(([key, value]) => (
                <DDListItem key={key} onClick={() => onSelectTimeZone(key)}>
                  <TextEllipsisStyledItem width={240}>{value}</TextEllipsisStyledItem>
                </DDListItem>
              ))}
            </DDList>
          </DDWrapper>
        </DDModernStyled>
      </DDStyleBody>

      <DDStyleBody>
        <DDTextTitle>Språk</DDTextTitle>
        <DDModernStyled listTitle={supportedLocales[locale] || locale}>
          <DDWrapper>
            <DDList>
              {Object.entries(supportedLocales).map(([key, value]) => (
                <DDListItem key={key} onClick={() => onSelectLocale(key)}>
                  <TextEllipsisStyledItem width={240}>{value}</TextEllipsisStyledItem>
                </DDListItem>
              ))}
            </DDList>
          </DDWrapper>
        </DDModernStyled>
      </DDStyleBody>

      <DDStyleBody>
        <DDTextTitle>Region</DDTextTitle>
        <DDModern listTitle={supportedRegions[region] || region}>
          <DDWrapper>
            <DDList>
              {Object.entries(supportedRegions).map(([key, value]) => (
                <DDListItem key={key} onClick={() => onSelectRegion(key)}>
                  <TextEllipsisStyledItem width={240}>{value}</TextEllipsisStyledItem>
                </DDListItem>
              ))}
            </DDList>
          </DDWrapper>
        </DDModern>
      </DDStyleBody>

      <DDStyleBody>
        <DDTextTitle>Valuta</DDTextTitle>
        <DDModern listTitle={supportedCurrencies[currency] || currency}>
          <DDWrapper>
            <DDList>
              {Object.entries(supportedCurrencies).map(([key, value]) => (
                <DDListItem key={key} onClick={() => onSelectCurrency(key)}>
                  <TextEllipsisStyledItem width={240}>{value}</TextEllipsisStyledItem>
                </DDListItem>
              ))}
            </DDList>
          </DDWrapper>
        </DDModern>
      </DDStyleBody>

      <p className="mt2">
        OBS! Ändringar ovan kräver omladdning av sidan.
      </p>
    </ListStyle>
  );
};

AdminConfigI18n.propTypes = {
  supportedTimeZones: PropTypes.object,
  supportedLocales: PropTypes.object,
  supportedRegions: PropTypes.object
};

export default AdminConfigI18n;
