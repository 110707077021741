export function getElementHeight(selector) {
  const element = document.querySelector(selector);
  return element ? element.offsetHeight : 0;
}

export function getElementDimensions(embeddedInApp) {
  const gridEl = document.getElementById('grid');
  const gridClientWidth = gridEl != null ? gridEl.clientWidth : 0;

  const windowHeight = window.innerHeight;
  const headerHeight = getElementHeight('.section-header');
  const bannerHeight = getElementHeight('#gridbanner');
  const clipboardHeight = getElementHeight('#clipboardbanner');
  const columnsHeight = getElementHeight('#columncontainer');

  const gridClientHeight = windowHeight - headerHeight - bannerHeight - clipboardHeight - columnsHeight;

  return {
    gridClientWidth,
    gridClientHeight: embeddedInApp ? windowHeight : gridClientHeight
  };
}

export function getViewDimensions() {
  return {
    phoneMode: window.matchMedia('(max-width: 767px)').matches,
    tabletMode: window.matchMedia('(min-width: 768px) and (max-width: 1000px)').matches,
    orientation: window.matchMedia('(orientation: portrait)').matches ? 'portrait' : 'landscape'
  };
}
