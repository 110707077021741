import { Panel } from '@Components/ui/styled/main';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import PasswordPolicy from './password-policy';
import PasswordStrength from './password-strength';

export default class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = this.makeInitialState(props);
  }

  render() {
    const {
      showForm, wrongPassword, passwordPolicy, isChangingPassword
    } = this.props;
    const {
      currentPassword, newPassword, validationError, changePasswordSuccess, isValidating, showPassword
    } = this.state;

    const wrongPasswordText = isValidating && wrongPassword ? 'Du har angett felaktigt nuvarande lösenord.' : '';
    const passwordPolicyText = isValidating && validationError ? 'Lösenordet uppfyller inte lösenordspolicyn.' : '';

    const currentPasswordClass = isValidating && wrongPassword ? 'form-group has-error' : 'form-group';
    const newPasswordClass = isValidating && validationError ? 'form-group has-error' : 'form-group';

    const isButtonDisabled = (isChangingPassword || currentPassword.length === 0 || newPassword.length === 0);
    const submitClass = isButtonDisabled ? 'btn btn-save disabled' : 'btn btn-save';
    const submitText = isChangingPassword ? 'Byter lösenord...' : 'Byt lösenord';

    return (
      <Panel>
        <h5>Byt lösenord</h5>
        <div className="row">
          <div className="col-xs-7">
            {changePasswordSuccess && (
              <p className="text-success lead">
                <i className="fa fa-check" /> Ditt lösenord har nu ändrats!
              </p>
            )}
            {showForm
              ? (
                <form autoComplete="off" onSubmit={this.handleSubmit}>
                  <PasswordPolicy passwordPolicy={passwordPolicy} validationError={validationError} isValidating={isValidating} />
                  <div className={newPasswordClass}>
                    {newPassword && (
                    <a href="#" className="form-control-button" onClick={this.toggleShowPassword}>
                      <i className={showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'} />
                    </a>
                    )}
                    <input autoComplete="new-password" type={showPassword ? 'text' : 'password'} className="form-control" placeholder="Välj nytt lösenord" value={newPassword} onChange={this.handleNewPassword} ref={(ref) => { this.newPassword = ref; }} />
                    <span className="help-block">{passwordPolicyText}</span>
                  </div>
                  {!passwordPolicy && <PasswordStrength newPassword={newPassword} />}
                  <p className="text-muted small">Ange ditt nuvarande lösenord för att bekräfta lösenordsbytet</p>
                  <div className={currentPasswordClass}>
                    <input autoComplete="off" type="password" className="form-control" placeholder="Nuvarande lösenord" value={currentPassword} onChange={this.handleCurrentPassword} />
                    <span className="help-block">{wrongPasswordText}</span>
                  </div>
                  <div className="pull-right">
                    <button
                      type="button"
                      className="btn btn-default"
                      onClick={this.handleResetFields}
                    >
                      Avbryt
                    </button> &nbsp;
                    <button type="submit" className={submitClass}>{submitText}</button>
                  </div>

                </form>
              )
              : (
                <>
                  <p className="text-muted mb3">
                    Alla andra enheter loggas ut om du byter lösenord
                  </p>
                  <a href="#" className="btn btn-default" onClick={this.handleShowForm}>Byt lösenord</a>
                </>
              )}
          </div>
        </div>
      </Panel>
    );
  }

  handleResetFields = () => {
    this.setState({
      currentPassword: '',
      newPassword: ''
    });
    this.props.onChangeShowForm(false);
  }

  handleShowForm = (ev) => {
    ev.preventDefault();
    this.setState({
      changePasswordSuccess: false
    });
    this.props.onChangeShowForm(true);
    this.validatePassword();
  };

  toggleShowPassword = (ev) => {
    ev.preventDefault();
    this.setState(({ showPassword }) => ({
      showPassword: !showPassword
    }), () => this.newPassword.focus());
  };

  handleCurrentPassword = (ev) => {
    this.setState({
      currentPassword: ev.target.value
    });
  };

  handleNewPassword = (ev) => {
    this.setState({
      newPassword: ev.target.value
    });
    this.validatePassword(ev.target.value);
  };

  validatePassword = (password) => {
    const { passwordPolicy } = this.props;
    if (!passwordPolicy) {
      return true;
    }

    const errors = [];
    const {
      minLength, caps, digits, specials
    } = passwordPolicy;

    if (minLength && !this.validate(password, /./g, minLength)) {
      errors.push('minLength');
    }
    if (caps && !this.validate(password, /[A-ZÅÄÖ]/g, caps)) {
      errors.push('caps');
    }
    if (digits && !this.validate(password, /[0-9]/g, digits)) {
      errors.push('digits');
    }
    if (specials && !this.validate(password, /[^0-9 a-zåäö]/gi, specials)) {
      errors.push('specials');
    }

    this.setState({ validationError: errors.length > 0 ? errors : null });
    return errors.length === 0;
  }

  validate = (password, regexp, count) => {
    const match = password && password.match(regexp);
    return match && match.length >= count;
  };

  handleSubmit = (ev) => {
    ev.preventDefault();

    const { onChangePassword } = this.props;
    const { currentPassword, newPassword } = this.state;

    this.setState({ isValidating: true });

    if (!this.validatePassword(newPassword)) {
      return;
    }

    onChangePassword({
      current: currentPassword,
      new: newPassword
    });
  };

  makeInitialState(props) {
    return {
      showPassword: false,
      newPassword: '',
      currentPassword: '',
      validationError: null,
      changePasswordSuccess: false,
      isValidating: false
    };
  }

  UNSAFE_componentWillUpdate(nextProps) {
    const { changePasswordSuccess } = this.props;

    if (!changePasswordSuccess && nextProps.changePasswordSuccess) {
      const state = this.makeInitialState(this.props);
      this.props.onChangeShowForm(false);
      state.changePasswordSuccess = true;
      this.setState(state);
    }
  }
}

ChangePassword.propTypes = {
  isChangingPassword: PropTypes.bool.isRequired,
  wrongPassword: PropTypes.bool.isRequired,
  changePasswordSuccess: PropTypes.bool.isRequired,
  onChangePassword: PropTypes.func.isRequired,
  onChangeShowForm: PropTypes.func.isRequired
};
