import React, { useState, useEffect } from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { navigate, getSectionUrl } from '@Utils/navigate';
import { openDrawer, fetchXReportSummary } from '@State/pos-actions';
import { closePosUnit } from '@State/pos-config-actions';

import { getCashEnabled, getShowOpenDrawer } from '@State/pos-selectors';
import { isCashierAdmin, isPosUnitClosed, isPosUnitOpenCurrentDevice } from '@Utils/pos-utils';
import TextInput from '@Components/inputs/text-input';
import CurrencyUtil from '@Utils/currency-util';
import DialogLoader from '@Components/dialogs/dialog-loader';
import ModalDialog from '@Components/dialogs/modal-dialog';
import { DialogContent, DialogReceiptContentWrap } from '@Components/dialogs/dialog-styles';
import { Flex, Text } from '@Components/ui/styled/main';

const selector = formValueSelector('dialog-pos-close');

const DialogPosClose = ({ routeParams, handleSubmit, onClose }) => {
  const dispatch = useDispatch();

  const saleId = useSelector(state => state.posSale.get('id'));
  const posUnit = useSelector(state => state.pos.get('posUnit'));
  const isLoading = useSelector(state => state.pos.get('progress'));
  const cashEnabled = useSelector(state => getCashEnabled(state));
  const showOpenDrawer = useSelector(state => getShowOpenDrawer(state));
  const countedCashAmount = useSelector(state => parseInt(selector(state, 'countedCashAmount') || 0));
  const withdrawnCashAmount = useSelector(state => parseInt(selector(state, 'withdrawnCashAmount') || 0));

  const [reportSummary, setReportSummary] = useState();
  const [closeResult, setCloseResult] = useState();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  if (!posUnit) {
    return null;
  }

  useEffect(() => {
    if (!reportSummary) {
      dispatch(fetchXReportSummary()).then((summary) => {
        setReportSummary(summary);
      });
    }
  }, []);

  const posUnitId = posUnit.get('vunitId');
  const isOpenCurrentDevice = isPosUnitOpenCurrentDevice(posUnit);
  const { report } = closeResult || {};

  const openCashDrawer = () => {
    setIsDrawerOpen(true);
    dispatch(openDrawer());
    setTimeout(() => setIsDrawerOpen(false), 5000);
  };

  const handleClosePos = ({ countedCashAmount, withdrawnCashAmount, comment }) => {
    const values = {
      countedCashAmount: countedCashAmount || 0,
      withdrawnCashAmount: withdrawnCashAmount || 0,
      comment: comment || ''
    };
    return dispatch(closePosUnit(posUnitId, values, !isOpenCurrentDevice)).then((result) => {
      setCloseResult(result);
    });
  };

  const showReport = (ev) => {
    onClose && onClose(ev);
    navigate(getSectionUrl(routeParams, 'pos', `reports/${report.sequenceNo}`));
  };

  if (isLoading) {
    return <DialogLoader title="Stänger kassa..." />;
  }

  if (saleId) {
    return (
      <ModalDialog
        title="Dagsavslut"
        onClose={onClose}
        buttons={[{
          name: 'OK',
          primary: true,
          onClick: onClose
        }]}
      >
        <DialogContent>
          Det finns ett påbörjat kvitto.
          Avsluta kvittot innan du stänger kassan.
        </DialogContent>
      </ModalDialog>
    );
  }

  const totalSales = reportSummary?.transactionTypeSummary?.Sale?.total || 0;
  const totalRefunds = Math.abs(reportSummary?.transactionTypeSummary?.Refund?.total || 0);
  const expectedCashAmount = reportSummary?.vunitState?.currentCashBalance || 0;
  const difference = countedCashAmount ? countedCashAmount - expectedCashAmount : 0;
  const remainingAmount = countedCashAmount - withdrawnCashAmount;

  if (closeResult || isPosUnitClosed(posUnit)) {
    const totalSalesAndRefunds = report
      ? report.totalSales - Math.abs(report.totalRefunds)
      : totalSales - totalRefunds;
    const currentCashBalance = report
      ? report.reportData?.vunitState?.currentCashBalance
      : countedCashAmount - withdrawnCashAmount;

    return (
      <ModalDialog
        contentSize="large"
        title="Kassan är stängd"
        closeButtonText="Stäng"
        onClose={onClose}
        buttons={[isCashierAdmin(posUnit) && {
          name: 'Visa rapport',
          gray: true,
          onClick: showReport
        }, {
          name: 'OK',
          primary: true,
          onClick: onClose
        }]}
      >
        <DialogReceiptContentWrap>
          <Flex>
            <Flex>Försäljning</Flex>
            <Flex right>{CurrencyUtil.accountCurrency(totalSalesAndRefunds, 2)}</Flex>
          </Flex>
          {cashEnabled && (
            <>
              <Flex>
                <Flex>Uttag från kassalåda</Flex>
                <Flex right>{CurrencyUtil.accountCurrency(withdrawnCashAmount, 2)}</Flex>
              </Flex>
              <Flex>
                <Flex>Kontanter kvar i kassan</Flex>
                <Flex right>{CurrencyUtil.accountCurrency(currentCashBalance, 2)}</Flex>
              </Flex>
            </>
          )}
        </DialogReceiptContentWrap>
      </ModalDialog>
    );
  }

  return (
    <ModalDialog
      contentSize="large"
      onClose={onClose}
      title="Dagsavslut"
      buttons={[showOpenDrawer && {
        name: 'Öppna kassalåda',
        gray: true,
        disabled: !isOpenCurrentDevice || isDrawerOpen,
        onClick: openCashDrawer
      }, {
        name: 'Stäng kassa',
        danger: true,
        onClick: handleSubmit(handleClosePos)
      }]}
    >
      <form onSubmit={handleClosePos} autoComplete="off" className="dialog">
        <DialogReceiptContentWrap>
          <Flex>
            <Flex>Försäljning</Flex>
            <Flex right>{CurrencyUtil.accountCurrency(totalSales - totalRefunds, 2)}</Flex>
          </Flex>
          {cashEnabled && (
            <>
              <Flex>
                <Flex>Förväntade kontanter i kassan</Flex>
                <Flex right>{CurrencyUtil.accountCurrency(expectedCashAmount, 2)}</Flex>
              </Flex>
              <Flex>
                <Flex>Räknade kontanter</Flex>
                <Flex right>{countedCashAmount ? CurrencyUtil.accountCurrency(countedCashAmount, 2) : '-'}</Flex>
              </Flex>
              <Flex>
                <Flex><Text danger={difference !== 0}>Differens</Text></Flex>
                <Flex right>
                  <Text danger={difference !== 0}>{difference !== 0 ? CurrencyUtil.accountCurrency(difference, 2) : '-'}</Text>
                </Flex>
              </Flex>
              <Flex>
                <Flex><Text danger={remainingAmount < 0}>Kvar i kassan</Text></Flex>
                <Flex right>
                  <Text danger={remainingAmount < 0}>{countedCashAmount ? CurrencyUtil.accountCurrency(remainingAmount, 2) : '-'}</Text>
                </Flex>
              </Flex>
            </>
          )}
        </DialogReceiptContentWrap>
        {cashEnabled && (
          <>
            <Flex>
              <Flex rightMargin>
                <Field type="number" name="countedCashAmount" component={TextInput} label="Räknat belopp" placeholder="-" />
              </Flex>
              <Flex>
                <Field type="number" name="withdrawnCashAmount" component={TextInput} label="Uttag från kassalåda" placeholder="-" />
              </Flex>
            </Flex>
            <Flex>
              <div className="form-group">
                <label className="control-label">Kommentar</label>
                <Field component="textarea" name="comment" rows={2} className="form-control" placeholder="Anledning till differens eller uttag" />
              </div>
            </Flex>
          </>
        )}
      </form>
    </ModalDialog>
  );
};

export default reduxForm({
  form: 'dialog-pos-close'
})(DialogPosClose);
