import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { terminateLocation } from '@State/sysadmin-actions';

import ConfirmPopover from '@Components/ui/confirm-popover';
import AccountOverviewBlock from '../account-overview/account-overview-block';
import AccountImportCustomers from './account-import-customers';
import AccountImportArticles from './account-import-articles';
import AccountImportReviews from './account-import-reviews';

const fields = [[
  { prop: 'organisationId', title: 'Org Id' },
  { prop: 'id', title: 'Location Id' },
  { prop: 'stableId', title: 'Stable Id' }
], [
  { prop: 'orgCtxName', title: 'Org slug' },
  { prop: 'ctxName', title: 'Locaction slug' },
  { prop: 'slug', title: 'Slug' }
], [
  { prop: 'timezone', title: 'Timezone' },
  { title: 'Region / Locale', getProp: (l) => `${l.get('region')} / ${l.get('locale')}` },
  { prop: 'currency', title: 'Currency' }
]];

const AccountLocationDetails = ({ location, showTerminate }) => {
  const dispatch = useDispatch();
  const [showConfirm, setShowConfirm] = useState(false);

  const onTerminate = (ev) => {
    ev.preventDefault();
    setShowConfirm(true);
  };

  const onCancel = () => setShowConfirm(false);

  const onConfirm = () => {
    return dispatch(terminateLocation(location.get('orgCtxName'), location.get('ctxName')));
  };

  return (
    <>
      <AccountOverviewBlock
        title={location.get('name')}
        columnsQuan={3}
        columns={fields}
        data={location}
        button={showTerminate && (
          <ConfirmPopover
            text={`Terminate ${location.get('name')}?`}
            confirmText="Terminate"
            onClose={onCancel}
            onConfirm={onConfirm}
            isOpen={showConfirm}
          >
            <a href="#" className="text-danger" onClick={onTerminate}>Terminate</a>
          </ConfirmPopover>
        )}
      />
      <div className="mt1 mb3">
        <AccountImportCustomers location={location} />
        <AccountImportArticles location={location} />
        <AccountImportReviews location={location} />
      </div>
    </>
  );
};

export default AccountLocationDetails;
