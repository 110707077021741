import PropTypes from 'prop-types';
import React, { Component } from 'react';
import SortableListBanner from '../sortable-list/sortable-list-banner';
import ResourceList from './resource-list';
import ResourceListTools from './resource-list-tools';

export default class ResourcesSidebar extends Component {
  static propTypes = {
    routeParams: PropTypes.object.isRequired,
    onToggleSorting: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      filter: ''
    };
  }

  filterChange = ev => {
    this.setState({ filter: ev.target.value });
  };

  handleToggleSorting = ev => {
    this.props.onToggleSorting(!this.props.sortable);
  };

  render() {
    const { routeParams, sortable } = this.props;
    return (
      <div className="columns-sidebar">
        <div className="columns-header">
          <h1>Resurser</h1>
          <ResourceListTools routeParams={this.props.routeParams} />
        </div>
        {sortable ? this.renderSortable() : this.renderNonSortable()}
        <ResourceList
          routeParams={routeParams}
          filter={this.state.filter}
          sortable={sortable}
        />
      </div>
    );
  }

  renderNonSortable() {
    return (
      <div className="columns-header">
        <div className="columns-filter">
          <input
            type="text"
            className="form-control"
            placeholder="Filter"
            onChange={this.filterChange}
          />
        </div>
      </div>
    );
  }

  renderSortable() {
    return <SortableListBanner onToggleSorting={this.handleToggleSorting} />;
  }
}
