import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setUndoableBooking, undoMove } from '@State/booking-actions';
import { setUserPreference } from '@State/user-actions';
import { calendar, user } from '@Utils/preference-keys';
import TB from '@Components/ui/tb-button';
import Popover from '@Components/ui/popover';
import { txt } from '@Utils/i18n-util';
import msg from './undo-button.msg';

class UndoButton extends Component {
  static propTypes = {
    hideHelpTipFromStart: PropTypes.bool.isRequired,
    onUndoLastMove: PropTypes.func.isRequired,
    onToggleHelpTip: PropTypes.func.isRequired,
    onClearUndoable: PropTypes.func.isRequired,
    phoneMode: PropTypes.bool.isRequired
  };

  constructor(props) {
    super(props);
    this.state = this.makeInitialState(props);
  }

  makeInitialState(props) {
    return {
      hideHelpTipChecked: props.hideHelpTipFromStart,
      showHelpTip: false
    };
  }

  render() {
    const { undoableBooking, confirmMoveEnabled, phoneMode } = this.props;
    const { showHelpTip } = this.state;

    if (confirmMoveEnabled) {
      return null;
    }

    return (
      <Popover className="helpTip" isOpen={showHelpTip} preferPlace="below" body={this.popoverContent()} onOuterAction={this.closePopover}>
        <TB
          label={!phoneMode && txt(msg.btnLabel)}
          icon="fa-undo"
          onClick={this.undo}
          active={undoableBooking != null}
          disabled={undoableBooking == null}
          phoneMode={phoneMode}
        />
      </Popover>
    );
  }

  popoverContent() {
    const iconClasses = 'fa fa-fw fa-undo';
    const iconStyles = { color: '#39f' };
    return (
      <div className="Popover-content medium">
        <a href="#" onClick={this.closePopover} className="Popover-close"><i className="far fa-lg fa-times" /></a>
        {txt(msg.bookingChanged, { icon: <i style={iconStyles} className={iconClasses} /> })}
        <br />
        <a className="checkbox" href="#" onClick={this.toggleHelpTip}>
          {this.state.hideHelpTipChecked ? <i className="far fa-fw fa-check-square" /> : <i className="far fa-fw fa-square" />}
          {txt(msg.dontShowAgain)}
        </a>
      </div>
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const { undoableBooking } = this.props;

    if (undoableBooking != null) {
      this.clearTimerIfSet();
      this.currentTimer = setTimeout(this.removeUndoable, 5000);
    }
  }

  componentWillUnmount() {
    this.clearTimerIfSet();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { hideHelpTipFromStart, undoableBooking } = this.props;
    const undoableBookingChanged = nextProps.undoableBooking != null && !nextProps.undoableBooking.equals(undoableBooking);
    const showHelpTip = nextProps.undoableBooking != null && !hideHelpTipFromStart;

    if (undoableBookingChanged) {
      this.setState({ showHelpTip });
    }
  }

  clearTimerIfSet() {
    if (this.currentTimer) {
      clearTimeout(this.currentTimer);
    }
  }

  removeUndoable = () => {
    this.clearTimerIfSet();
    this.closePopover();
  };

  closePopover = (ev) => {
    if (ev) {
      ev.preventDefault();
    }
    this.setState({ showHelpTip: false });
  };

  undo = (ev) => {
    if (this.props.undoableBooking) {
      this.clearTimerIfSet();
      this.closePopover();
      this.props.onUndoLastMove();
    }

    if (ev) {
      ev.preventDefault();
      ev.currentTarget.blur();
    }
  };

  toggleHelpTip = (ev) => {
    ev.preventDefault();
    const newState = !this.state.hideHelpTipChecked;
    this.props.onToggleHelpTip(newState);
    this.setState({ hideHelpTipChecked: newState });
  };
}

const mapStateToProps = (state) => {
  return {
    phoneMode: state.mainViewState.get('phoneMode'),
    undoableBooking: state.gridViewState.get('undoableBooking'),
    confirmMoveEnabled: state.locationConfig.get(calendar.confirmMoveEnabled),
    hideHelpTipFromStart: state.userClientPreferences.getIn(['user', user.hideUndoMoveHelpTip]) === true
  };
};

const mapDispatchToProps = (dispatch, { match }) => {
  return {

    onClearUndoable: () => {
      dispatch(setUndoableBooking(null));
    },

    onUndoLastMove: () => {
      dispatch(undoMove(match.params));
    },

    onToggleHelpTip: (value) => {
      dispatch(setUserPreference(false, { [user.hideUndoMoveHelpTip]: value }));
    }
  };
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(UndoButton));
