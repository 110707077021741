import React from 'react';
import { pos } from '@Utils/preference-keys';
import ModalDialog from '@Components/dialogs/modal-dialog';
import Panel from '@Components/ui/styled/panel';
import PosUnitConfigToggle from './pos-unit-config-toggle';
import PosUnitAutoClose from './pos-unit-auto-close';

const PosUnitSettings = ({ posUnit, onClose }) => (
  <ModalDialog
    contentSize="medium"
    title="Inställningar för kassa"
    hideCloseButton
    focusTrapPaused
    closeOnClickOutside={false}
    onClose={onClose}
    buttons={[{
      name: 'Stäng',
      primary: true,
      type: 'submit',
      onClick: onClose
    }]}
  >
    <Panel>
      <PosUnitConfigToggle
        posUnit={posUnit}
        preference={pos.alwaysPrintReceipt}
        label="Skriv alltid ut kvitto"
        description="Skriv ut kvitto automatiskt vid köp och återköp"
      />
      <PosUnitConfigToggle
        posUnit={posUnit}
        preference={pos.alwaysOpenDrawer}
        label="Öppna alltid kassalåda"
        description="Öppna kassalådan vid köp med andra betalmedel än kontant (gäller ej köp med integrerad kortmaskin)"
      />
    </Panel>
    <Panel>
      <PosUnitAutoClose posUnit={posUnit} />
    </Panel>
    <Panel>
      <PosUnitConfigToggle
        posUnit={posUnit}
        preference={pos.autoEmailZReport}
        label="Mejla Z-rapporter vid dagsavslut"
        description="Skickas till kontoägare samt eventuell redovisningskontakt"
      />
      <PosUnitConfigToggle
        posUnit={posUnit}
        preference={pos.autoPrintZReport}
        label="Skriv ut dagsavslut från kortterminal"
        description="Skriv ut dagsavslut från kortterminalen vid dagsavslut i kassan"
      />
    </Panel>
  </ModalDialog>
);

export default PosUnitSettings;
