import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { mergePosUnitPrefs } from '@State/pos-config-actions';
import LabelSwitchInput from '@Components/ui/label-switch-input';

const PosUnitConfigToggle = ({
  posUnit, preference, label, description, children = null
}) => {
  const isOn = !!posUnit.getIn(['prefs', preference]);

  const dispatch = useDispatch();
  const onToggle = useCallback(() => {
    return dispatch(mergePosUnitPrefs(posUnit.get('id'), {
      [preference]: !isOn
    }));
  }, [preference, posUnit]);

  return (
    <>
      <div className="form-group">
        <LabelSwitchInput
          noBorder
          label={label}
          id={preference}
          isOn={isOn}
          handleToggle={onToggle}
          description={description}
        />
      </div>
      {isOn && !!children ? children : null}
    </>
  );
};

export default PosUnitConfigToggle;
