import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  DialogButton,
  DialogButtonsHorizontal,
  DialogConfirmText
} from '@Components/dialogs/dialog-styles';
import { txt } from '@Utils/i18n-util';
import msg from './confirm-block.msg';

const ConfirmBlock = ({
  title,
  cancelText = txt(msg.btnCancel),
  confirmText = txt(msg.btnDelete),
  onCancel,
  onConfirm,
  children,
  disabled,
  disableConfirm,
  confirmDanger
}) => {
  let mounted = true;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    return () => {
      mounted = false;
    };
  }, []);

  const handleConfirm = async (e) => {
    if (e) e.preventDefault();
    setLoading(true);
    try {
      await onConfirm(e);
    } finally {
      if (mounted) {
        setLoading(false);
      }
    }
  };

  return (
    <div>
      {title && <DialogConfirmText>{title}</DialogConfirmText>}
      {children}
      <DialogButtonsHorizontal>
        <DialogButton disabled={disabled || loading} small gray onClick={onCancel}>
          {cancelText}
        </DialogButton>
        <DialogButton
          type="submit"
          disabled={disableConfirm || disabled || loading}
          loading={loading}
          small
          primary={!confirmDanger}
          deny={confirmDanger}
          onClick={handleConfirm}
        >
          {confirmText}
        </DialogButton>
      </DialogButtonsHorizontal>
    </div>
  );
};

ConfirmBlock.propTypes = {
  title: PropTypes.string,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func
};

export default ConfirmBlock;
