import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { classes } from '@Components/ui/utils';
import TimePickerInput from './time-picker-input';

const TimePickerRow = ({
  disabled, index, onRemove, timers: [start, end], isAddGrayWrap, onChange
}) => {
  const [startTime, setStartTime] = useState(start.time);
  const [endTime, setEndTime] = useState(end.time);
  const [isEndLessThanStart, setIsEndLessThanStart] = useState(false);

  const handleInputChange = (isStartTime, newTime) => handleChange(isStartTime, newTime);

  const onTimeInputChangeStart = (e, start) => handleInputChange(true, start);
  const onTimeInputChangeEnd = (e, end) => handleInputChange(false, end);

  const handleChange = (isStartTime, newTime) => {
    setIsEndLessThanStart(false);
    if (isStartTime) {
      newTime >= endTime && setIsEndLessThanStart(true);
      setStartTime(newTime);
    } else {
      newTime < startTime && setIsEndLessThanStart(true);
      setEndTime(newTime);
    }
    onChange(isStartTime, newTime);
  };

  const getTimers = useCallback(() => {
    return [{
      labelName: start.labelName,
      time: startTime,
      timeConfig: start.timeConfig,
      isHasError: isEndLessThanStart,
      onTimeInputChange: onTimeInputChangeStart
    },
    {
      labelName: end.labelName,
      onLabelClick: end.onLabelClick,
      removeText: end.removeText,
      time: endTime,
      timeConfig: end.timeConfig,
      isHasError: isEndLessThanStart,
      onTimeInputChange: onTimeInputChangeEnd
    }];
  }, [isEndLessThanStart]);

  const timers = getTimers();

  const classListRow = classes({
    row: true,
    'time-picker-input-wrap': true,
    'time-picker-input-wrap-row-gray': isAddGrayWrap,
    'time-picker-input-wrap-row': !isAddGrayWrap
  });

  return (
    <div className={classListRow} key={index}>
      {timers.map(({
        labelName, removeText, timeConfig, time, isHasError,
        onTimeInputChange
      }, index) => (
        <div className="col-sm col-sm-6" key={index}>
          <div className={`has-feedback ${isHasError && 'has-error'}`}>
            {labelName && <label className="text-muted-label control-label">{labelName}</label>}
            {removeText && <label className="text-danger control-label" onClick={onRemove}>{removeText}</label>}
            <TimePickerInput
              time={time}
              timeConfig={timeConfig}
              disabled={disabled}
              onChange={onTimeInputChange}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

TimePickerRow.propTypes = {
  disabled: PropTypes.bool,
  onRemove: PropTypes.func,
  onChange: PropTypes.func,
  isAddGrayWrap: PropTypes.bool
};

export default TimePickerRow;
