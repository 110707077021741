import React, { useState } from 'react';
import DialogAlert from '@Components/dialogs/dialog-alert';

const FinishDelivery = ({ itemCount, onClose, onSubmit }) => {
  const [progress, setProgress] = useState(false);
  const [saved, setSaved] = useState(false);

  const handleSubmit = () => {
    setProgress(true);
    return onSubmit().then(() => {
      setSaved(true);
    }).catch(() => {
      setProgress(false);
    });
  };

  if (saved) {
    return (
      <DialogAlert
        success
        title="Klarmarkera inleverans"
        icon="fa fa-check-circle"
        text="Inleveransen har klarmarkerats"
        onClose={onClose}
        closeButtonText="Stäng"
      />
    );
  }

  return (
    <DialogAlert
      title="Klarmarkera inleverans"
      icon="fa fa-truck"
      text={`Klarmarkera inleverans och uppdatera lagersaldo för ${itemCount} artiklar?`}
      buttons={[{
        name: 'Uppdatera lagersaldo',
        primary: true,
        onClick: handleSubmit,
        loading: progress
      }, {
        name: 'Avbryt',
        gray: true,
        onClick: onClose
      }]}
    />
  );
};

export default FinishDelivery;
