import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { storage } from '@Utils/preference-keys';
import { getRouteParams, getSysAdmin } from '@State/selectors';
import { restoreAutoDeleted } from '@State/admin-config-actions';
import { getJsonPreferences, mergeJsonPreferences, getInitialValues } from '@State/preferences-actions';
import StorageConfigForm from './storage-config-form';

class StorageConfig extends Component {
  componentDidMount() {
    this.props.getStorageConfig();
  }

  restoreAutoDeleted = () => {
    return this.props.restoreAutoDeleted()
      // eslint-disable-next-line no-alert
      .then((res) => alert(`Innehållet har återställts!\n${JSON.stringify(res)}`));
  };

  render() {
    const {
      isSysAdmin, routeParams, initialValues, saveStorageConfig, storageSettingsAccepted
    } = this.props;

    return (
      <div className="subnav-container">
        <StorageConfigForm
          routeParams={routeParams}
          initialValues={initialValues}
          onSubmit={saveStorageConfig}
          isSysAdmin={isSysAdmin}
          restoreAutoDeleted={this.restoreAutoDeleted}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    isSysAdmin: getSysAdmin(state),
    routeParams: getRouteParams(state, props),
    initialValues: getInitialValues(state, storage)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getStorageConfig: () => {
      dispatch(getJsonPreferences());
    },
    saveStorageConfig: (values) => {
      values[storage.settingsAccepted] = moment().toISOString();
      return dispatch(mergeJsonPreferences(values));
    },
    restoreAutoDeleted: () => {
      return dispatch(restoreAutoDeleted());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StorageConfig);
