import React, { PureComponent } from 'react';
import { classes } from '@Components/ui/utils';
import '@Components/ui/select/select-styles.less';
import SelectDropDown from '@Components/ui/select/select-drop-down';

class Select extends PureComponent {
  onOptionClick = () => {
    const { onChange } = this.props;
    const { dropdown } = this.refs;
    onChange && onChange();
    dropdown.setState({ opened: false });
  };

  render() {
    const {
      value,
      label,
      children,
      ...props
    } = this.props;

    const classList = classes({
      'ui-select': true
    });

    return (
      <div className={classList} {...props}>
        <SelectDropDown
          label={label}
          buttonContent={value}
          inline={false}
          name="ui-select"
          ref="dropdown"
        >
          {React.cloneElement(children, {
            onClick: this.onOptionClick
          })}
        </SelectDropDown>
      </div>
    );
  }
}

export default Select;
