import { List, fromJS } from 'immutable';
import { CLEAR_LOCATION_STATE } from '@State/account-actions';
import {
  POS_GIFT_CARDS_FETCHED, POS_GIFT_CARD_UPDATED, POS_GIFT_CARD_VOIDED
} from './gift-card-actions';

export function giftCards(state = List(), action = null) {
  switch (action.type) {
    case POS_GIFT_CARDS_FETCHED:
      return fromJS(action.giftCards);

    case POS_GIFT_CARD_UPDATED:
      return state.set(action.giftCard.id, fromJS(action.giftCard));

    case POS_GIFT_CARD_VOIDED:
      return state.setIn([action.id, 'voucherStatus'], 'Void');

    case CLEAR_LOCATION_STATE:
      return state.clear();

    default:
      return state;
  }
}
