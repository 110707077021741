import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { printTestPage } from '@State/pos-config-actions';
import { PosConfigRow, PosConfigCell, POSTitle, ButtonWrap } from '@Components/admin/pos-config/style';
import { Button, Panel } from '@Components/ui/styled/main';
import PosPrinterStatus from '@Components/admin/pos-config/pos-printer-status';
import PrinterTools from './printer-tools';

const PrinterPanel = ({ printer, posUnit }) => {
  const dispatch = useDispatch();
  const [progressId, setProgressId] = useState(null);

  const handlePrintTestPage = (printerId) => {
    setProgressId(printerId);
    dispatch(printTestPage(printerId))
      .then(() => setProgressId(null));
  };

  const {
    id: printerId, modelName, macAddress, fwVersion, printerStatus
  } = printer.toJS();
  const {
    statusCode, statusMessage, printing, lastSeenTs
  } = printerStatus || {};

  const progress = printerId === progressId || printing;
  const hasError = !(statusCode >= 200 && statusCode < 300);

  return (
    <Panel>
      <PosConfigRow>
        <PosConfigCell>
          <POSTitle>
            <PosPrinterStatus
              progress={progress}
              printer={printer}
            />
            {modelName || 'Okänd skrivare'}{' '}
            {fwVersion && <small>v{fwVersion}</small>}
          </POSTitle>
          {statusMessage && hasError && <p>{statusMessage}</p>}
          <div className="text-muted select-text">
            MAC-adress: {macAddress}<br />
            Senast sedd: {lastSeenTs ? moment(lastSeenTs).fromNow() : '-'}
          </div>
          {posUnit && (
            <div className="mt1">
              <div className="label-block label-gray">
                {posUnit.get('posOrgName')} - {posUnit.get('name')}
              </div>
            </div>
          )}
        </PosConfigCell>
        <PosConfigCell>
          <ButtonWrap>
            <Button small outlinePrimary onClick={() => handlePrintTestPage(printerId)} disabled={progress || hasError}>
              {progress ? 'Skriver ut testsida...' : 'Testutskrift'}
            </Button>
            <PrinterTools printer={printer} vunitId={posUnit?.get('id')} />
          </ButtonWrap>
        </PosConfigCell>
      </PosConfigRow>
    </Panel>
  );
};

export default PrinterPanel;
