import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalDialog from '@Components/dialogs/modal-dialog';
import Panel from '@Components/ui/styled/panel';
import Button from '@Components/ui/button';
import { deleteMultiResourceRules, updateMultiResourceRules } from '@State/services-actions';
import ModalSelector from '@Components/reports/data-export/data-export-modal-selector';
import { Flex } from '@Components/ui/styled/main';
import ProgressButton from '@Components/ui/progress-button';

const newRule = {
  primaryRule: false,
  requiredResources: 1,
  resourceIds: []
};
const getGefaultRules = (resourceIds) => ([{
  primaryRule: true,
  requiredResources: 1,
  resourceIds
}]);

const MultiResourceModal = ({ service, onClose, selectedResourceIds, setServiceResourceMappings }) => {
  const dispatch = useDispatch();
  const resources = useSelector(state => state.resourcesById.valueSeq().toJS());
  const resourceGroups = useSelector(state => state.orderedGroups.toJS());

  const { id, multiResourceRules } = service;
  const defaultRules = getGefaultRules(selectedResourceIds);
  const [rules, setRules] = useState(multiResourceRules || defaultRules);
  const primaryRule = rules.find(r => r.primaryRule) || rules[0];

  const handleAdd = () => setRules([...rules, newRule]);

  const handleDelete = (index) => {
    const newRules = [...rules];
    newRules.splice(index, 1);
    setRules(newRules);
  };

  const handleUpdate = (index, resourceIds) => {
    const newRules = [...rules];
    newRules.splice(index, 1, { ...rules[index], resourceIds });
    setRules(newRules);
  };

  const handleSetQuantity = (ev) => {
    const newRules = [...rules];
    newRules.splice(0, 1, { ...rules[0], requiredResources: ev.target.value });
    setRules(newRules);
  };

  const saveServiceMappings = () => {
    if (selectedResourceIds === primaryRule.resourceIds) {
      return Promise.resolve();
    }
    return setServiceResourceMappings(id, primaryRule.resourceIds);
  };

  const handleSave = () => {
    return saveServiceMappings()
      .then(() => {
        return rules.length > 1 || rules[0].requiredResources > 1
          ? dispatch(updateMultiResourceRules(id, rules))
          : dispatch(deleteMultiResourceRules(id));
      })
      .then(onClose);
  };

  return (
    <ModalDialog
      title="Välj resurser för tjänsten"
      contentSize="large"
      closeOnClickOutside={false}
      onClose={onClose}
      focusTrapPaused
    >
      {rules.map((rule, index) => {
        return (
          <Panel key={index}>
            {!rule.primaryRule && (
              <div className="pull-right">
                <Button tiny white onClick={() => handleDelete(index)}>Ta bort</Button>
              </div>
            )}
            <ModalSelector
              title={rule.primaryRule ? 'Primära resurser' : 'Fler resurser'}
              items={resources}
              values={rule.resourceIds}
              groups={resourceGroups}
              groupKey="resourceIds"
              onChange={(resourceIds) => handleUpdate(index, resourceIds)}
              getItemTitle={(resource) => resource?.name}
              placeholder="Välj resurser"
            />
            {rule.primaryRule && (
              <div className="form-group">
                <label className="control-label">Hur många krävs?</label>
                <div className="select-container" style={{ width: 100 }}>
                  <select className="form-control" value={rule.requiredResources} onChange={handleSetQuantity}>
                    {rule.resourceIds.map((_, index) => {
                      const value = index + 1;
                      return (
                        <option key={value} value={value}>{value}</option>
                      );
                    })}
                  </select>
                </div>
              </div>
            )}
          </Panel>
        );
      })}
      <Flex>
        <Flex>
          <Button small gray onClick={handleAdd}>Lägg till rum/utrustning</Button>
        </Flex>
        <Flex right>
          <ProgressButton small primary onClick={handleSave}>Spara resurser</ProgressButton>
        </Flex>
      </Flex>
    </ModalDialog>
  );
};

export default MultiResourceModal;
