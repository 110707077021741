import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  createLocationHardwarePricingApi,
  deleteLocationHardwarePricingApi,
  fetchLocationsByIdApi,
  updateLocationHardwarePricingApi
} from '@State/sysadmin-actions';

import { Button, Flex, Row } from '@Components/ui/styled/main';
import { PanelBody, PanelDefault } from '@Components/ui/styled/panel';
import Table, {
  TableBody, TableHead, TableRow, TableTh
} from '@Components/ui/styled/table';
import ModalDialog from '@Components/dialogs/modal-dialog';
import AccountOverviewHardwareForm
  from '@Components/sysadmin/accounts/account-detail/account-overview/account-overview-hardware/account-overview-hardware-form';
import AccountOverviewHardwareTableItem
  from '@Components/sysadmin/accounts/account-detail/account-overview/account-overview-hardware/account-overview-hardware-table-item';
import { Title } from '@Components/sysadmin/accounts/accounts-styles';
import AccountOverviewContext
  from '@Components/sysadmin/accounts/account-detail/account-overview/account-overview-context';

const defaultItem = {
  locationId: null,
  price: null,
  chargeType: '',
  contractStartTime: null,
  contractLength: null,
  description: '',
  units: null
};

const AccountOverviewHardware = () => {
  const dispatch = useDispatch();
  const { locationHardwarePricing = [], id } = useContext(AccountOverviewContext);
  const [selectedItem, setSelectedItem] = useState(null);

  const openModalChangeInfo = (item) => setSelectedItem({ ...item.toJS() });
  const hideModalChangeInfo = () => setSelectedItem(null);
  const handleAddNewItem = () => setSelectedItem(defaultItem);

  useEffect(() => {
    (async function fetchLocations() {
      await dispatch(fetchLocationsByIdApi(id));
    }());
  }, [id]);

  const onSubmitHardwarePricing = async (values) => {
    const body = {
      ...selectedItem,
      ...values,
      type: values.type || selectedItem.type,
      contractStartTime: values.contractStartTime,
      chargeType: values.chargeType,
      contractLength: values.contractLength || 0
    };

    const params = { orgId: id, ...body };
    if (selectedItem?.id) {
      await dispatch(updateLocationHardwarePricingApi({ id: selectedItem.id, ...params }));
    } else {
      await dispatch(createLocationHardwarePricingApi(params));
    }
  };

  const onConfirmDeleteHardware = (itemTemplateId) => {
    dispatch(deleteLocationHardwarePricingApi({ orgId: id, itemTemplateId }));
  };

  return (
    <>
      {selectedItem
      && (
      <ModalDialog onClose={hideModalChangeInfo}>
        <AccountOverviewHardwareForm
          onClose={hideModalChangeInfo}
          onSubmit={onSubmitHardwarePricing}
          initialValues={selectedItem}
        />
      </ModalDialog>
      )}
      <Row>
        <Title muted>Invoice templates</Title>
        <Flex right>
          <p>
            <Button marginRight gray small bold onClick={handleAddNewItem}>
              Add template row
            </Button>
          </p>
        </Flex>
      </Row>
      <PanelDefault>
        <PanelBody>
          <Table>
            <TableHead>
              <TableRow>
                <TableTh>Charge type</TableTh>
                <TableTh>Description</TableTh>
                <TableTh>Contract start date</TableTh>
                <TableTh>Contract length</TableTh>
                <TableTh width="20px">Units</TableTh>
                <TableTh right>Price</TableTh>
                <TableTh right />
              </TableRow>
            </TableHead>
            <TableBody>
              {locationHardwarePricing?.map((item, index) => {
                const onClickByLocation = () => openModalChangeInfo(item);
                return (
                  <TableRow key={item.get('id') || index} onClick={onClickByLocation}>
                    <AccountOverviewHardwareTableItem onConfirm={onConfirmDeleteHardware} item={item} />
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </PanelBody>
      </PanelDefault>
    </>
  );
};

export default AccountOverviewHardware;
