import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { required } from '@Utils/validation';
import FormButtonsFooter from '@Components/ui/form-buttons-footer';
import SelectField from '@Components/inputs/select-field';
import DatePickerField from '@Components/ui/date-picker/date-picker-field';

const InvoiceStatusForm = ({ submitting, submitSucceeded, handleSubmit, onClose }) => {
  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <Field
        name="status"
        component={SelectField}
        label="Status"
        validate={[required]}
      >
        <option value="Unpaid">Ej betald</option>
        <option value="Paid">Betald</option>
        <option value="Annulled">Makulerad</option>
        <option value="DebtCollection">Inkasso</option>
      </Field>
      <Field
        name="statusDate"
        component={DatePickerField}
        label="Datum"
      />
      <FormButtonsFooter
        onClose={onClose}
        submitting={submitting}
        submitSucceeded={submitSucceeded}
        handleSubmit={handleSubmit}
        submitText="Spara"
      />
    </form>
  );
};

export default reduxForm({
  form: 'invoice-status-form'
})(InvoiceStatusForm);
