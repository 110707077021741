import { calcTimeFromXYCoord } from '@Utils/time-util';

export default class CoordHelper {
  constructor(props) {
    this.gridProps = props.gridProps;
    this.marginPx = props.gridProps.chipIndentPixels;
  }

  get columnWidth() {
    return this.gridProps.columnWidth;
  }

  get rowHeight() {
    return this.gridProps.pixelsPerRow;
  }

  get draggingWidth() {
    return this.columnWidth - this.marginPx * 2;
  }

  get hourHeight() {
    return this.gridProps.rowsPerHour * this.rowHeight;
  }

  leftPct(x) {
    return x / this.gridProps.gridClientWidth * 100;
  }

  widthPct(width) {
    return width / this.gridProps.gridClientWidth * 100;
  }

  columnIdx(xOrEvent) {
    const x = xOrEvent.clientX ? xOrEvent.clientX : xOrEvent;
    return Math.floor(x / this.columnWidth);
  }

  snapToGridY(yOrEvent) {
    const rh = this.rowHeight;
    const y = yOrEvent.clientY ? yOrEvent.clientY : yOrEvent;
    return Math.floor(y / rh) * rh;
  }

  snapToGridX(xOrEvent) {
    return Math.round(this.columnIdx(xOrEvent) * this.columnWidth);
  }

  withoutMargin(y) {
    return y - this.marginPx;
  }

  withYMargin(y) {
    return y + this.marginPx / 2;
  }

  withXMargin(x) {
    return x + this.marginPx;
  }

  calcTopHeight(previousHeightDelta, otop, newH) {
    let top,
      height;

    // Handle reservation made dragging vertically down to up
    //
    if (previousHeightDelta <= 0) {
      height = -newH;
      top = otop - (-newH);
    } else {
      height = newH;
      top = otop;
    }

    // Don't allow reservation less than the row height
    //
    if (height < this.rowHeight) {
      height = this.rowHeight;
    }

    return {
      top,
      height
    };
  }

  timeFor(newX, newY) {
    return calcTimeFromXYCoord(this.gridProps, newX, newY, this.gridProps.viewDateStartEnd.start);
  }
}
