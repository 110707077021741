import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { calculateGridPixelsPerRow } from './grid-state-helper';

class RowRulers extends Component {
  render() {
    const { pixelsPerRow, rowsPerHour } = this.props;
    const rowRulerCount = rowsPerHour * 24;
    const rowRulers = [];

    for (let i = 0; i < rowRulerCount; i++) {
      rowRulers.push({ id: `r${i}` });
    }
    return (
      <div>
        {rowRulers.map((row, i) => (
          <div
            id={row.id}
            key={row.id}
            className={i % rowsPerHour === 0 ? 'time-block hrule hruleeven' : ' time-block hrule hruleodd'}
            style={{ top: i * pixelsPerRow }}
          />
        ))}
      </div>
    );
  }
}

RowRulers.propTypes = {
  pixelsPerRow: PropTypes.number.isRequired,
  rowsPerHour: PropTypes.number.isRequired
};

const mapStateToProps = (state) => {
  const rowsPerHour = state.gridViewState.get('rowsPerHour'),
    gridSize = state.gridViewState.get('gridSize');
  const pixelsPerRow = calculateGridPixelsPerRow(rowsPerHour, gridSize);

  return {
    pixelsPerRow,
    rowsPerHour
  };
};

const RowRulersContainer = connect(
  mapStateToProps
)(RowRulers);

export default RowRulersContainer;
