import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { getTotal, reportsQuerySelector } from '@Components/reports/reports-helpers';
import { reportingCount, reportingFormat } from '@Utils/format';
import { Panel } from '@Components/ui/styled/main';

class DeferredSalesReport extends Component {
  render() {
    const { reportsViewState } = this.props;
    const deferredSales = reportsViewState.get('deferredSales');
    const showReport = deferredSales && !deferredSales.isEmpty() && !deferredSales.get('rows').isEmpty();

    return (
      <div>
        {showReport ? this.renderTable(deferredSales) : this.renderNoData()}
      </div>
    );
  }

  renderTable(deferredSales) {
    const groupedRows = deferredSales.get('rows').groupBy(s => s.get('vatPct'));

    return groupedRows.entrySeq().map(([vatPct, groupRows], index) => {
      const rows = groupRows.toJS();
      return (
        <Fragment key={vatPct}>
          <h5 className={index === 0 ? 'mt0' : 'mt3'}>{`${parseInt(vatPct)}% moms`}</h5>
          <Panel>
            <table className="table table-condensed table-report">
              <thead>
                <tr>
                  <th>Tjänst</th>
                  <th>KS</th>
                  <th className="text-right">Sålt antal</th>
                  <th className="text-right">Rapp. antal</th>
                  <th className="text-right">Show</th>
                  <th className="text-right">No show</th>
                  <th className="text-right">Exkl. moms</th>
                  <th className="text-right">Moms</th>
                </tr>
              </thead>
              <tbody>
                {rows.map(({
                  service, soldQuantity, reportingQuantity, show, noShow,
                  exVatAmount, vatAmount, costCenter
                }) => (
                  <tr>
                    <td>{service}</td>
                    <td>{costCenter}</td>
                    <td className="text-right">{reportingCount(soldQuantity)}</td>
                    <td className="text-right">{reportingCount(reportingQuantity)}</td>
                    <td className="text-right">{reportingCount(show)}</td>
                    <td className="text-right">{reportingCount(noShow)}</td>
                    <td className="text-right">{reportingFormat(exVatAmount)}</td>
                    <td className="text-right">{reportingFormat(vatAmount)}</td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <th>Summa</th>
                  <th />
                  <th className="text-right">{reportingCount(getTotal(rows, 'soldQuantity'))}</th>
                  <th className="text-right">{reportingCount(getTotal(rows, 'reportingQuantity'))}</th>
                  <th className="text-right">{reportingCount(getTotal(rows, 'show'))}</th>
                  <th className="text-right">{reportingCount(getTotal(rows, 'noShow'))}</th>
                  <th className="text-right">{reportingFormat(getTotal(rows, 'exVatAmount'))}</th>
                  <th className="text-right">{reportingFormat(getTotal(rows, 'vatAmount'))}</th>
                </tr>
              </tfoot>
            </table>
          </Panel>
        </Fragment>
      );
    });
  }

  renderNoData() {
    return (
      <div className="alert alert-info">
        <i className="fa fa-info-circle" /> Det finns ingen bokförd försäljning för vald period.
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { reportsViewState } = state;

  return {
    reportsViewState,
    reportsQuery: reportsQuerySelector(state, ownProps)
  };
};

export default connect(mapStateToProps)(DeferredSalesReport);
