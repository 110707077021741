import React from 'react';
import styled, { css } from 'styled-components';
import { colors } from '@Components/ui/styled/variables';

const StatusIcon = styled.i`
  margin-right: 6px;
  vertical-align: baseline;

  ${props => props.small && css`
    font-size: 11px;
  `}
  ${props => props.error && css`
    color: ${colors.danger};
  `}
  ${props => props.warning && css`
    color: ${colors.bgWarning};
  `}
  ${props => props.success && css`
    color: ${colors.greenCircle};
  `}
  ${props => props.progress && css`
    color: ${colors.gray};
  `}
  ${props => props.offline && css`
    color: ${colors.grayCircle};
  `}
`;

const PosPrinterStatus = ({ printer, progress, ...props }) => {
  const printerStatus = printer && printer.get('printerStatus');
  const { statusCode, statusMessage, printing } = printerStatus ? printerStatus.toObject() : {};
  const message = String(statusMessage).trim();

  if (!statusCode) {
    return <span><StatusIcon offline {...props} className="fa fa-fw fa-circle" /></span>;
  }

  if (statusCode > 200 && statusCode < 300) {
    return <span><StatusIcon warning {...props} className="fa fa-fw fa-circle" /></span>;
  }

  if (statusCode === '200') {
    return progress || printing
      ? <span><StatusIcon progress {...props} className="fa fa-fw fa-circle-notch fa-spin" /></span>
      : <span><StatusIcon success {...props} className="fa fa-fw fa-circle" /></span>;
  }
  return <span><StatusIcon error {...props} className="fa fa-fw fa-circle" title={message} /></span>;
};

PosPrinterStatus.propTypes = {

};

export default PosPrinterStatus;
