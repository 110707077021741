import React, { Component } from 'react';
import { connect } from 'react-redux';
import { email, web } from '@Utils/preference-keys';
import { getFeatures } from '@State/selectors';
import { getLongestName, templatePreviewValues } from '@Utils/templates';
import { getInitialValues, getJsonPreferences, mergeJsonPreferences } from '@State/preferences-actions';
import EmailConfigForm from './email-config-form';

class EmailConfig extends Component {
  componentDidMount() {
    this.props.getEmailConfig();
  }

  render() {
    const {
      initialValues, features, previewValues, saveEmailConfig
    } = this.props;

    return (
      <div className="subnav-container">
        <EmailConfigForm
          initialValues={initialValues}
          previewValues={previewValues}
          onSubmit={saveEmailConfig}
          features={features}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { locationConfig, resourcesById, servicesById } = state;

  const resourceName = getLongestName(resourcesById, 'webName');
  const serviceName = getLongestName(servicesById);
  const previewValues = templatePreviewValues({
    resourceName, serviceName, locationConfig
  });

  return {
    initialValues: getInitialValues(state, email),
    features: getFeatures(state),
    previewValues
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getEmailConfig: () => {
      dispatch(getJsonPreferences());
    },
    saveEmailConfig: (values) => {
      return dispatch(mergeJsonPreferences(values));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailConfig);
