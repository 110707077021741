import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import ReactDataSheet from 'react-datasheet';

import ModalDialog from '@Components/dialogs/modal-dialog';
import { abortDataReportPreview, getCustomerReportPreview } from '@State/report-actions';
import { ModalContent } from '@Components/dialogs/dialog-styles';
import { getDateText, getCustomersExportPayload } from '@Components/reports/reports-helpers';
import Loader from '@Components/ui/loader';
import Button from '@Components/ui/button';
import { txt } from '@Utils/i18n-util';

import msg from '@Components/reports/data-export/data-export.msg';

const timeCols = ['created'];

function getGrid(data = []) {
  const res = [];
  if (!data[0]) return res;

  const keys = Object.keys(data[0]) ?? [];
  data.forEach(item => {
    const row = [];
    keys.forEach((field) => {
      let value = item[field];
      if (timeCols.includes(field) && value) {
        value = moment(value).format('YYYY-MM-DD HH:mm');
      }
      row.push({ readOnly: true, value, overflow: 'clip', className: 'text-left' });
    });
    res.push(row);
  });
  return [
    keys.map((field) => ({
      readOnly: true,
      className: 'col-header',
      value: field,
      width: timeCols.includes(field) ? '120px' : '100px'
    })),
    ...res
  ];
}

const CustomerPreview = ({ onClose }) => {
  const data = useSelector(state => state.reportsData.get('reportPreview'));
  const { start, end } = useSelector(getCustomersExportPayload);
  const status = useSelector(state => state.reportsData.get('reportPreviewStatus'));
  const dispatch = useDispatch();

  const [grid, setGrid] = useState([]);

  useEffect(() => {
    dispatch(getCustomerReportPreview());
  }, []);

  const handleClose = useCallback(() => {
    dispatch(abortDataReportPreview());
    onClose();
  }, [onClose]);

  useEffect(() => {
    setGrid(getGrid(data));
  }, [data]);

  return (
    <ModalDialog
      onClose={onClose}
      focusTrapPaused
      contentSize="full"
      closeOnClickOutside={false}
    >
      <div>
        <div className="pull-right">
          <Button gray small onClick={handleClose} loading={false}>{txt(msg.btnClose)}</Button>
        </div>
        <h3 className="mt0">
          {txt(msg.reportPreview)}<br />
          <small>{getDateText(moment(start), moment(end))}</small>
        </h3>
      </div>
      <ModalContent>
        <div className="data-report-preview-table">
          {status?.reportPreviewPending && <Loader />}
          {!status?.reportPreviewPending && !grid.length
            ? <div>{txt(msg.noDataFound)}</div>
            : null}
          {!status?.reportPreviewPending && grid.length ? (
            <div className={`data-table ${grid.length < 99 ? 'long' : ''}`}>
              <ReactDataSheet
                overflow="clip"
                data={grid}
                valueRenderer={cell => cell.value}
                isCellNavigable={(cell) => cell.className !== 'header'}
              />
            </div>
          ) : null}
          {grid.length >= 99 && (
            <div className="show-max-rows">
              <i className="fa fa-circle-info" />
              {txt(msg.rowsLimit)}
            </div>
          )}
        </div>
      </ModalContent>
    </ModalDialog>
  );
};

export default CustomerPreview;
