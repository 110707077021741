import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { classes } from '@Components/ui/utils';
import { colors } from '@Components/ui/styled/variables';
import { isDark } from '@Utils/colors';

const getStyleTapGroups = (isDisabled, isSelected, color = '#ccc') => ({
  cursor: isDisabled ? 'default' : 'pointer',
  border: isSelected ? '3px solid #fff' : color,
  backgroundColor: `${color}`,
  color: isDark(color) ? '#fff' : `${colors.textColor}`,
  opacity: isDisabled ? 0.5 : 1
});

const ProductGroupsItem = ({
  isSelected, onSelectGroup, isDisabled, group
}) => {
  const { id, name, color } = group.toJS();
  const classList = classes({
    'pos__product-group': true,
    'pos__product-group--selected': isSelected && !isDisabled
  });

  const handleOnSelect = () => onSelectGroup(group);
  const styleTap = getStyleTapGroups(isDisabled, isSelected, color);

  return (
    <button
      key={id}
      disabled={isDisabled}
      component="button"
      className={classList}
      onClick={handleOnSelect}
      style={styleTap}
    >
      <p>
        {id === 'favs' && <i className="fa fa-star" />}
        {name}
      </p>
    </button>
  );
};

ProductGroupsItem.propTypes = {
  onSelectGroup: PropTypes.func,
  isDisabled: PropTypes.bool,
  group: PropTypes.object
};

export default memo(ProductGroupsItem);
