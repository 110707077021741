import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getRouteParams } from '@State/selectors';
import { fetchGroupsAndResources } from '@State/resource-actions';
import { fetchServices } from '@State/services-actions';
import { fetchCampaigns } from '@State/campaign-actions';
import { CampaignList } from '@Components/campaign/campaign-list';
import CampaignOverview from '@Components/campaign/campaign-overview';
import Loader from '@Components/ui/loader';

import '../styles/campaign.less';

class Campaign extends Component {
  state = {
    loading: true
  };

  componentDidMount() {
    const {
      resourcesById, servicesById, fetchCampaigns,
      fetchGroupsAndResources, fetchServices
    } = this.props;
    Promise.all([
      fetchCampaigns(),
      resourcesById.isEmpty() && fetchGroupsAndResources(),
      servicesById.isEmpty() && fetchServices()
    ]).finally(() => this.setState({ loading: false }));
  }

  render() {
    const { loading } = this.state;
    const { routeParams } = this.props;

    if (loading) {
      return <Loader />;
    }

    return (
      <div className="columns-wrapper">
        <div className="columns-container">
          <div className="columns-sidebar">
            <CampaignList routeParams={routeParams} />
          </div>
          <div className="columns-content">
            <div className="columns-content-container width-medium">
              <div className="columns-content-body with-padding">
                <CampaignOverview routeParams={routeParams} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { mainViewState, campaignViewState, resourcesById, servicesById } = state;

  return {
    mainViewState,
    campaignViewState,
    resourcesById,
    servicesById,
    routeParams: getRouteParams(state, ownProps)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchGroupsAndResources: () => dispatch(fetchGroupsAndResources()),
    fetchServices: () => dispatch(fetchServices()),
    fetchCampaigns: () => dispatch(fetchCampaigns())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Campaign);
