import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addServiceAddonMapping, deleteServiceAddonMapping } from '@State/services-actions';
import { getAddonServices } from '@State/calendar-selectors';
import { getDurationAndPrice } from '@Utils/service-util';
import AlertWithIcon from '@Components/ui/alert-with-icon';

class ServiceAddonMappings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      progress: false,
      saved: false
    };
  }

  selectService = (ev, addonServiceId) => {
    if (ev.target.checked) {
      this.addMapping(addonServiceId);
    } else {
      this.deleteMapping(addonServiceId);
    }
  };

  addMapping = (addonServiceId) => {
    this.setState({ loading: true, saved: false });
    return this.props.addServiceAddonMapping(this.props.serviceId, addonServiceId)
      .then(() => this.setState({ loading: false, saved: true }, this.resetSaved));
  };

  deleteMapping = (addonServiceId) => {
    this.setState({ loading: true, saved: false });
    return this.props.deleteServiceAddonMapping(this.props.serviceId, addonServiceId)
      .then(() => this.setState({ loading: false, saved: true }, this.resetSaved));
  };

  resetSaved = () => {
    setTimeout(() => { this.setState({ saved: false }); }, 2000);
  };

  render() {
    const { loading, saved } = this.state;
    const { addonServices } = this.props;

    return (
      <div className="addon-services-container">
        <AlertWithIcon>
          Välj tilläggstjänster som kan bokas med denna tjänst
        </AlertWithIcon>
        <table className="table table-striped">
          <tbody>
            {addonServices.map(this.renderMapping)}
          </tbody>
        </table>
        {loading && <strong className="text-muted">Sparar...</strong>}
        {saved && <strong className="text-success">Sparat!</strong>}
      </div>
    );
  }

  renderMapping = (service) => {
    const { loading } = this.state;
    const isMapped = this.isAddonServiceMapped(service.id);
    const inputId = `service-${service.id}`;

    return (
      <tr key={service.id}>
        <td>
          <input
            id={inputId}
            type="checkbox"
            value="true"
            checked={isMapped}
            disabled={loading}
            onChange={ev => this.selectService(ev, service.id)}
          />
        </td>
        <td>{service.name}</td>
        <td className="text-muted">{getDurationAndPrice(service)}</td>
      </tr>
    );
  }

  isAddonServiceMapped(addonServiceId) {
    const { addonServiceMappingsById, serviceId } = this.props;
    const serviceMapping = addonServiceMappingsById.get(serviceId);
    return serviceMapping?.includes(addonServiceId) || false;
  }
}

const mapStateToProps = (state) => {
  const { addonServiceMappingsById } = state;

  return {
    addonServices: getAddonServices(state),
    addonServiceMappingsById
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addServiceAddonMapping: (serviceId, addonServiceId) => {
      return dispatch(addServiceAddonMapping(serviceId, addonServiceId));
    },
    deleteServiceAddonMapping: (serviceId, addonServiceId) => {
      return dispatch(deleteServiceAddonMapping(serviceId, addonServiceId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceAddonMappings);
