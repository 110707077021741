import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getSysAdmin } from '@State/selectors';
import Popover from '@Components/ui/popover';
import { setLocationFeature } from '@State/features-actions';
import SysadminLabel from '@Components/ui/sysadmin-label';

class Feature extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
  };

  state = {
    progress: false,
    showConfirm: false
  };

  showConfirm = (ev) => {
    ev.preventDefault();
    this.setState({ showConfirm: true, progress: false });
  };

  hideConfirm = (ev) => {
    ev.preventDefault();
    this.setState({ showConfirm: false });
  };

  enableFeature = (ev) => {
    ev.preventDefault();
    return this.setLocationFeature(true);
  };

  disableFeature = (ev) => {
    ev.preventDefault();
    return this.setLocationFeature(false);
  };

  setLocationFeature = (enabled) => {
    this.setState({ progress: true });
    return this.props.setLocationFeature(enabled)
      .then(() => this.setState({ showConfirm: false, progress: false }))
      .catch(() => this.setState({ progress: false }));
  };

  render() {
    const { showConfirm, progress } = this.state;
    const {
      name, icon, image, title, description, children,
      enabled, canEnable, usePopover, sysAdminOnly
    } = this.props;
    const popoverContent = enabled ? this.disablePopoverContent() : this.enablePopoverContent();

    return (
      <div className="feature">
        <Popover key={`${name}${enabled}`} isOpen={showConfirm} body={popoverContent} onOuterAction={this.hideConfirm}>
          {enabled
            ? (
              <div className="pull-right text-center">
                <div className="label label-success">
                  <i className="fa fa-check" /> Aktiverad
                </div>
                {canEnable && <a href="#" className="text-danger" onClick={usePopover ? this.showConfirm : this.disableFeature}>Stäng av</a>}
              </div>
            )
            : (
              <div className="pull-right">
                <button className="btn-save" onClick={usePopover ? this.showConfirm : this.enableFeature} disabled={progress || !canEnable}>Aktivera</button>
              </div>
            )}
        </Popover>
        <h4>
          {icon && <i className={icon} />}
          {image && <img src={image} alt={title} />}
          {title}
          {sysAdminOnly && <SysadminLabel />}
        </h4>
        <p>{description}</p>
        {enabled && children}
      </div>
    );
  }

  enablePopoverContent() {
    return (
      <div className="Popover-content-small">
        <a href="#" onClick={this.hideConfirm} className="Popover-close"><i className="fa fa-lg fa-times" /></a>
        Vill du aktivera funktionen <strong>{this.props.title}</strong>?
        Du kan när som helst stänga av det igen.
        <br />
        <br />
        <button className="btn-save btn-block" onClick={this.enableFeature} disabled={this.state.progress}>Aktivera</button>
      </div>
    );
  }

  disablePopoverContent() {
    return (
      <div className="Popover-content-small">
        <a href="#" onClick={this.hideConfirm} className="Popover-close"><i className="fa fa-lg fa-times" /></a>
        Vill du stänga av funktionen <strong>{this.props.title}</strong>?
        Du kan när som helst aktivera det igen.
        <br />
        <br />
        <button className="btn-delete btn-block" onClick={this.disableFeature} disabled={this.state.progress}>Stäng av</button>
      </div>
    );
  }
}

const mapStateToProps = (state, { name, dependency, sysAdminRequired, disabled }) => {
  const { locationFeatures } = state;
  const isSysAdmin = getSysAdmin(state);
  const hasRequiredDependency = !dependency || locationFeatures.get(dependency);
  const hasRequiredPermission = !sysAdminRequired || isSysAdmin;

  return {
    enabled: locationFeatures.get(name),
    canEnable: hasRequiredDependency && hasRequiredPermission && !disabled
  };
};

const mapDispatchToProps = (dispatch, { name }) => {
  return {
    setLocationFeature: (enabled) => {
      return dispatch(setLocationFeature(name, enabled));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Feature);
