import React from 'react';
import queryString from 'query-string';
import { getSectionUrl, navigate } from '@Utils/navigate';
import DialogAlert from './dialog-alert';

const FortnoxAuth = ({ location, match }) => {
  const { posOrgId, status } = queryString.parse(location.search);

  const onClose = () => {
    const isSysadmin = match.params.org === 'sysadmin';
    const posOrgUrl = getSectionUrl(match.params, 'admin', `pos/${posOrgId}/accounting`);
    navigate(isSysadmin ? '/sysadmin' : posOrgUrl);
  };

  return status === 'success' ? (
    <DialogAlert
      success
      icon="fa fa-check-circle"
      text="Koppling till Fortnox aktiverad"
      onClose={onClose}
      closeButtonText="OK"
    />
  ) : (
    <DialogAlert
      warning
      icon="fa fa-exclamation-triangle"
      text={(
        <div>
          Koppling till Fortnox kunde inte aktiveras.<br /><br />

          <small>
            Säkerställ att du har tillgång till <a href="https://support.fortnox.se/komigangguide-tjanster/kom-igang-med-partner-appar" target="_blank">Integrationer</a> i Fortnox,
            samt att användarkontot har rättigheten <a href="https://support.fortnox.se/administration/vad-ar-programadministrator-och-systemadministrator" target="_blank">Systemadministratör</a>.
          </small>
        </div>
      )}
      onClose={onClose}
      closeButtonText="OK"
    />
  );
};

export default FortnoxAuth;
