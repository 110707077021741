import React from 'react';
import PropTypes from 'prop-types';

export default class SortableListBanner extends React.Component {
  static propTypes = {
    onToggleSorting: PropTypes.func.isRequired
  };

  render() {
    return (
      <div className="columns-header sortable-list-banner">
        <div className="pull-right">
          <button className="btn btn-label-success" onClick={this.props.onToggleSorting}>Klar</button>
        </div>
        Dra och släpp för att ändra ordning i listan
      </div>
    );
  }
}
