import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getSysadminLocations, getSysadminStateOrganizationById
} from '@Components/sysadmin/accounts/accounts-selector';
import { fetchLocationsByIdApi } from '@State/sysadmin-actions';

import Loader from '@Components/ui/loader';
import AccountLocationDetails from './account-location-details';
import { useFetchAccount } from '../../useFetchAccount';

const AccountLocations = () => {
  const dispatch = useDispatch();

  const subscription = useSelector(state => getSysadminStateOrganizationById(state));
  const locations = useSelector(state => getSysadminLocations(state));

  const [id, loading] = useFetchAccount(async (orgId) => {
    await dispatch(fetchLocationsByIdApi(orgId));
  });

  if (loading && subscription.size === 0) {
    return <Loader />;
  }

  return (
    <div>
      {locations?.map((location) => (
        <AccountLocationDetails
          key={location.get('id')}
          location={location}
          showTerminate={locations.size > 1}
        />
      ))}
    </div>
  );
};

export default AccountLocations;
