import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setCustomerInvoiceCustomer } from '@State/customer-actions';
import { fetchInvoiceCustomer } from '@State/invoice-actions';
import { Column, FormGroup, Text } from '@Components/ui/styled/main';
import {
  PanelWrapper, TitleBlock, StyledLink, RowFormGroups
} from '@Components/customers/customer-styles';
import InvoiceCustomerModal from '@Components/pos/dialogs/invoice-customer-modal';
import { txt } from '@Utils/i18n-util';
import msg from './customer-information.msg';

const CustomerInvoiceCustomer = ({ selectedCustomer }) => {
  const dispatch = useDispatch();
  const { id, invoiceCustomerId } = selectedCustomer || {};
  const [invoiceCustomer, setInvoiceCustomer] = useState(null);

  useEffect(() => {
    if (invoiceCustomerId) {
      dispatch(fetchInvoiceCustomer(invoiceCustomerId))
        .then((res) => setInvoiceCustomer(res));
    } else {
      setInvoiceCustomer(null);
    }
  }, [invoiceCustomerId]);

  const [showInvoiceCustomerModal, setShowInvoiceCustomerModal] = useState(false);
  const hideInvoiceCustomerModal = () => setShowInvoiceCustomerModal(false);

  const handleSetInvoiceCustomer = (newInvoiceCustomerId) => {
    return dispatch(setCustomerInvoiceCustomer(id, newInvoiceCustomerId))
      .then(hideInvoiceCustomerModal);
  };

  return (
    <>
      <TitleBlock>{txt(msg.lblInvoiceCustomer)}</TitleBlock>
      <PanelWrapper onClick={() => setShowInvoiceCustomerModal(true)}>
        {invoiceCustomer ? (
          <>
            <RowFormGroups>
              <Column>
                <FormGroup
                  labelText={txt(msg.lblName)}
                  text={invoiceCustomer.get('customerName')}
                />
              </Column>
              {invoiceCustomer.get('fortnoxCustomerNumber') && (
                <div>
                  {invoiceCustomer.get('fortnoxCustomerNumber')}
                  <img src="/fortnox-icon.png" className="invoice-provider right" />
                </div>
              )}
            </RowFormGroups>
            {invoiceCustomer.get('email') && (
              <RowFormGroups>
                <FormGroup
                  labelText={txt(msg.lblEmail)}
                  text={invoiceCustomer.get('email')}
                />
              </RowFormGroups>
            )}
            <RowFormGroups>
              <FormGroup
                labelText={txt(msg.lblAddress)}
                text={(
                  <>
                    <Text>{invoiceCustomer.get('address1')}</Text>
                    <Text>{invoiceCustomer.get('postCode')} {invoiceCustomer.get('area')}</Text>
                  </>
                )}
              />
            </RowFormGroups>
          </>
        ) : (
          <StyledLink>{txt(msg.lblAddInvoiceCustomer)}</StyledLink>
        )}
      </PanelWrapper>
      {showInvoiceCustomerModal && (
        <InvoiceCustomerModal
          invoiceCustomer={invoiceCustomer}
          onSubmit={handleSetInvoiceCustomer}
          onClose={hideInvoiceCustomerModal}
        />
      )}
    </>
  );
};

export default CustomerInvoiceCustomer;
