import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pos } from '@Utils/preference-keys';
import { createExtPosUnitIfNeeded, fetchPosUnitsByOrgId, mergeOrgSettings } from '@State/pos-config-actions';
import ModalDialog from '@Components/dialogs/modal-dialog';
import AlertWithIcon from '@Components/ui/alert-with-icon';
import { getPosOrgInvoicePrefs } from '@State/pos-config-selectors';
import { getInvoicePrefsValidation, getInvoiceUseEmailConfig } from '@Utils/invoice-util';
import PosOrgInvoiceForm from './pos-org-invoice-form';

const PosOrgInvoiceModal = ({ posOrg, onClose, onSaved }) => {
  const dispatch = useDispatch();
  const billingContact = useSelector(state => state.posContacts.get('Billing'));
  const invoicePrefs = useSelector(state => getPosOrgInvoicePrefs(state, { posOrg }));
  const { useFortnox, hasDueDays, hasAccountNumber } = getInvoicePrefsValidation(invoicePrefs);
  const useEmailConfig = getInvoiceUseEmailConfig(invoicePrefs);
  const [hasAccountError, setHasAccountError] = useState(false);

  useEffect(() => {
    dispatch(fetchPosUnitsByOrgId(posOrg.get('id')));
  }, [posOrg]);

  const handleSubmit = (values) => {
    if (!useFortnox && !values[pos.invoiceBGNumber] && !values[pos.invoicePGNumber]) {
      setHasAccountError(true);
      return false;
    }
    return dispatch(mergeOrgSettings(posOrg.get('id'), values))
      .then(() => dispatch(createExtPosUnitIfNeeded(posOrg)))
      .then(() => onSaved());
  };

  return (
    <ModalDialog
      contentSize={useEmailConfig ? 'large' : 'small'}
      title="Fakturainställningar"
      hideCloseButton
    >
      {!useFortnox && !hasAccountNumber && (
        <AlertWithIcon warning={hasAccountError} icon="fa fa-info-circle">
          Innan du kan skapa en faktura behöver du ange minst ett kontonummer för betalning.
        </AlertWithIcon>
      )}
      {useFortnox && !hasDueDays && (
        <AlertWithIcon icon="fa fa-info-circle">
          Innan du kan skapa en faktura behöver du ange betalningsvillkor.
        </AlertWithIcon>
      )}
      <PosOrgInvoiceForm
        posOrgId={posOrg.get('id')}
        invoicePrefs={invoicePrefs}
        billingContact={billingContact}
        initialValues={invoicePrefs}
        onSubmit={handleSubmit}
        onClose={onClose}
      />
    </ModalDialog>
  );
};

export default PosOrgInvoiceModal;
