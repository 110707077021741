import React, { useEffect } from 'react';

export default function useKeyPress(targetKey, handler, dependencies = []) {
  function downHandler({ keyCode }) {
    if (keyCode === targetKey) {
      handler(dependencies);
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', downHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, dependencies);
}
