import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import Loader from '@Components/ui/loader';
import {
  fetchStockTakes,
  deleteStockTake,
  createStockTake
} from '@State/stock-taking-actions';
import { fetchProductGroups, fetchProducts } from '@State/products-actions';
import { getSectionUrl, navigate } from '@Utils/navigate';
import StockTakingList from './stock-taking-list';
import StockTakingItems from './stock-taking-items';

class StockTaking extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      filter: '',
      showAddStockTake: null
    };
  }

  componentDidMount() {
    this.props.loadData().then(() => this.setState({ loading: false }));
  }

  showAddStockTake = (barcode) => {
    this.setState({ showAddStockTake: barcode });
  };

  hideAddStockTake = () => {
    this.setState({ showAddStockTake: null });
  };

  addStockTake = ({ name }) => {
    return this.props.createStockTake({ name })
      .then(() => this.hideAddStockTake());
  };

  render() {
    const { loading } = this.state;
    const {
      stockTakes,
      stockTakeId,
      routeParams
    } = this.props;

    if (loading) {
      return <Loader />;
    }

    if (!stockTakeId && !stockTakes.isEmpty()) {
      return (
        <Redirect
          to={getSectionUrl(
            routeParams,
            'admin/stock-taking',
            stockTakes.first().id
          )}
        />
      );
    }

    return (
      <div className="columns-container">
        <div className="columns-sidebar">
          <StockTakingList
            stockTakes={stockTakes}
            routeParams={routeParams}
            selectedId={stockTakeId}
          />
        </div>
        <div className="columns-content">
          <div className="columns-content-container width-large">
            <div className="columns-content-body with-padding select-text">
              <StockTakingItems
                routeParams={routeParams}
                stockTakeId={stockTakeId}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { stockTakes, stockTake } = state;
  const routeParams = ownProps.match.params;
  const stockTakeId = parseInt(ownProps.match.params.id);

  return {
    stockTakeId,
    stockTake,
    routeParams,
    stockTakes
  };
};

const mapDispatchToProps = (dispatch, { match }) => ({
  loadData: () => Promise.all([
    dispatch(fetchProducts()),
    dispatch(fetchProductGroups()),
    dispatch(fetchStockTakes())
  ]),
  deleteStockTake: stockTakeId => dispatch(deleteStockTake(stockTakeId)),
  createStockTake: (stockTake) => dispatch(createStockTake(stockTake))
    .then(id => navigate(getSectionUrl(match.params, 'admin/stock-taking', id)))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StockTaking);
