import { defineMessages } from 'react-intl';

export default defineMessages({
  btnChange: {
    id: 'calendar.findTime.service.btnChange',
    defaultMessage: 'Change'
  },
  btnSelectService: {
    id: 'calendar.findTime.service.btnSelectService',
    defaultMessage: 'Select services'
  }
});
