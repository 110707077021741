import { defineMessages } from 'react-intl';

export default defineMessages({
  lblFilter: {
    id: 'calendar.resource.toolbar.lblFilter',
    defaultMessage: 'Filter'
  },
  btnCollapse: {
    id: 'calendar.resource.toolbar.btnCollapse',
    defaultMessage: 'Collapse'
  },
  btnExpand: {
    id: 'calendar.resource.toolbar.btnExpand',
    defaultMessage: 'Expand'
  },
  lblSize: {
    id: 'calendar.resource.toolbar.lblSize',
    defaultMessage: 'Size'
  },
  lblNormal: {
    id: 'calendar.resource.toolbar.lblNormal',
    defaultMessage: 'Normal'
  },
  lblCompact: {
    id: 'calendar.resource.toolbar.lblCompact',
    defaultMessage: 'Compact'
  }
});
