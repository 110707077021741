import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pos } from '@Utils/preference-keys';
import { mergeOrgSettings } from '@State/pos-config-actions';
import { getPosOrgKlarnaPrefs } from '@State/pos-config-selectors';
import AutoSaveTextInput from '@Components/inputs/auto-save-text-input';
import PosOrgConfigToggle from './pos-org-config-toggle';

const PosOrgPaymentKlarna = (props) => {
  const { posOrg } = props;
  const dispatch = useDispatch();
  const handleSave = useCallback((values) => {
    return dispatch(mergeOrgSettings(posOrg.get('id'), values));
  }, [posOrg]);

  const klarnaPrefs = useSelector(state => getPosOrgKlarnaPrefs(state, props));
  const {
    [pos.klarnaUsername]: klarnaUsername, [pos.klarnaPassword]: klarnaPassword
  } = klarnaPrefs || {};

  return (
    <PosOrgConfigToggle
      posOrg={posOrg}
      preference={pos.paymentMethodKlarna}
      label="Klarna"
      description="Visa Klarna som betalsätt i kassan. Kräver eget avtal med Klarna"
    >
      <AutoSaveTextInput
        label="API-nyckel"
        name={pos.klarnaUsername}
        initialValue={klarnaUsername}
        onSave={handleSave}
        width={200}
      />
      <AutoSaveTextInput
        type="password"
        label="Lösenord"
        name={pos.klarnaPassword}
        initialValue={klarnaPassword}
        onSave={handleSave}
        width={200}
      />
    </PosOrgConfigToggle>
  );
};

export default PosOrgPaymentKlarna;
