import React, { Component } from 'react';
import { connect } from 'react-redux';
import { company } from '@Utils/preference-keys';
import {
  getJsonPreferences, mergeJsonPreferences, getInitialValues, updateContactDetails
} from '@State/preferences-actions';
import CompanyInfoForm from './company-info-form';
import MyBusinessConfig from './my-business-config';

class CompanyInfo extends Component {
  componentDidMount() {
    this.props.getCompanyInfo();
  }

  onSubmit = (values) => {
    return this.props.saveCompanyInfo(values, this.props.enableMicrosite);
  };

  render() {
    const { initialValues, enableMicrosite } = this.props;

    return (
      <div className="subnav-container">
        <CompanyInfoForm
          enableMicrosite={enableMicrosite}
          initialValues={initialValues}
          onSubmit={this.onSubmit}
        />
        {/*
        <br /><br />
        <div className="row">
          <div className="col-sm-offset-3 col-sm-6">
            <MyBusinessConfig />
          </div>
        </div>
        */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    enableMicrosite: state.locationFeatures.get('EnableMicrosite'),
    initialValues: getInitialValues(state, company)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCompanyInfo: () => {
      dispatch(getJsonPreferences());
    },
    saveCompanyInfo: (values, enableMicrosite) => {
      return enableMicrosite
        ? dispatch(updateContactDetails(values))
        : dispatch(mergeJsonPreferences(values));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyInfo);
