import React from 'react';

const NoContent = ({ icon, children }) => {
  return (
    <div className="no-content-container">
      {icon && <i className={icon} />}
      <p>
        {children}
      </p>
    </div>
  );
};

export default NoContent;
