import axios from 'axios';
import {
  axiosDefault, axiosErrorHandler, checkStatusAxios, prefixUrl
} from '@Utils/ajax-util';
import { pos } from '@Utils/preference-keys';
import { fetchPosOrg, mergeOrgSettings, orgSettingsUpdated } from './pos-config-actions';

const getFortnoxUrl = (orgId, path) => prefixUrl(`/pos/fortnox/org/${orgId}/${path}`);
const getFortnoxAuthUrl = (orgId, path) => prefixUrl(`/fortnox/auth/pos/org/${orgId}/${path}`);

export function fetchFortnoxAuthUrl(orgId) {
  return dispatch => {
    const url = orgId
      ? getFortnoxAuthUrl(orgId, 'link')
      : prefixUrl('/fortnox/auth/system/link');
    const config = axiosDefault();

    return axios.get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function fetchFortnoxTokenStatus(orgId) {
  return dispatch => {
    const url = getFortnoxAuthUrl(orgId, 'token-status');
    const config = axiosDefault();

    return axios.get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function fetchFortnoxSyncStatus(orgId) {
  return dispatch => {
    const url = getFortnoxUrl(orgId, 'sync-status');
    const config = axiosDefault();

    return axios.get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function connectExistingFortnoxToken(orgId) {
  return dispatch => {
    const url = getFortnoxAuthUrl(orgId, 'connect-existing');
    const config = axiosDefault();

    return axios.post(url, null, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function fetchFortnoxConfigStatus(orgId) {
  return dispatch => {
    const url = getFortnoxUrl(orgId, 'config-status');
    const config = axiosDefault();

    return axios.get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data.statuses)
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function fetchFortnoxVouchers(orgId) {
  return dispatch => {
    const url = getFortnoxUrl(orgId, 'vouchers/');
    const config = axiosDefault();

    return axios.get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function fetchFortnoxVoucher(orgId, yearId, voucherSeries, voucherNumber) {
  return dispatch => {
    const url = getFortnoxUrl(orgId, `vouchers/${yearId}/${voucherSeries}/${voucherNumber}`);
    const config = axiosDefault();

    return axios.get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function deleteFortnoxVoucher(orgId, yearId, voucherSeries, voucherNumber, transactionDate) {
  return dispatch => {
    const url = getFortnoxUrl(orgId, `vouchers/${yearId}/${voucherSeries}/${voucherNumber}/${transactionDate}`);
    const config = axiosDefault();

    return axios.delete(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

function fortnoxPost(orgId, path, body = null, reload = true) {
  return (dispatch) => {
    const url = getFortnoxUrl(orgId, path);
    const config = axiosDefault();

    return axios.post(url, body, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => {
        if (reload) {
          return dispatch(fetchPosOrg(orgId));
        }
      })
      .catch(error => {
        axiosErrorHandler(error, dispatch);
        throw error;
      });
  };
}

export function fortnoxConnect(orgId, { apiKey, accessToken }) {
  return fortnoxPost(orgId, 'connect', { apiKey, accessToken });
}

export function fortnoxStart(orgId) {
  return fortnoxPost(orgId, 'start');
}

export function fortnoxPause(orgId) {
  return fortnoxPost(orgId, 'pause');
}

export function fortnoxDisconnect(orgId) {
  return fortnoxPost(orgId, 'disconnect');
}

export function toggleFortnoxInvoice(orgId, enable) {
  return (dispatch) => {
    return dispatch(fortnoxPost(orgId, 'invoice', { enable }, false))
      .then(() => {
        dispatch(orgSettingsUpdated(orgId, {
          [pos.invoiceUseFortnox]: enable
        }));
        if (enable) {
          dispatch(mergeOrgSettings(orgId, {
            [pos.paymentMethodFortnoxInvoice]: true
          }));
        }
      });
  };
}

export function fortnoxRunSync(orgId, start, end) {
  return (dispatch) => {
    const url = getFortnoxUrl(orgId, `vouchers/export?start=${start}&end=${end}`);
    const config = axiosDefault();

    return axios.post(url, null, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .catch(error => {
        axiosErrorHandler(error, dispatch);
        throw error;
      });
  };
}

export function syncFortnoxCustomers(orgId) {
  return (dispatch) => {
    const url = getFortnoxUrl(orgId, 'sync-customers');
    const config = axiosDefault();

    return axios.post(url, null, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .catch(error => {
        axiosErrorHandler(error, dispatch);
        throw error;
      });
  };
}

export function fetchFortnoxCustomerCount(orgId) {
  return (dispatch) => {
    const url = getFortnoxUrl(orgId, 'customers/count');
    const config = axiosDefault();

    return axios.get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data.count)
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function fetchFortnoxTermsOfPayments(orgId) {
  return (dispatch) => {
    const url = getFortnoxUrl(orgId, 'termsofpayments');
    const config = axiosDefault();

    return axios.get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data.TermsOfPayments)
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function refreshFortnoxTokens() {
  return (dispatch) => {
    const url = prefixUrl('/fortnox/refresh-tokens');
    const config = axiosDefault();

    return axios.post(url, null, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}
