import React from 'react';
import { reportingFormat, reportingCount } from '@Utils/format';
import { Row, Panel } from '@Components/ui/styled/main';
import { FlexOne, ReportTextLabel } from '@Components/pos/style';
import { getTotal } from '../reports-helpers';

const CommissionReportSummary = ({ productRows, commissionPct }) => {
  const netQty = getTotal(productRows, 'netQty');
  const netAmountExVat = getTotal(productRows, 'netAmountExVat');
  const netPriceIn = getTotal(productRows, 'netPriceIn');
  const netMargin = getTotal(productRows, 'netMargin');
  const commission = getTotal(productRows, 'commission');

  return (
    <Panel>
      <Row>
        <FlexOne>
          <ReportTextLabel>
            <span>Sålda produkter</span>
            <p className="mb0">{reportingCount(netQty)}</p>
          </ReportTextLabel>
        </FlexOne>
        <FlexOne>
          <ReportTextLabel>
            <span>Exkl. moms</span>
            <p className="mb0">{reportingFormat(netAmountExVat)}</p>
          </ReportTextLabel>
        </FlexOne>
        <FlexOne>
          <ReportTextLabel>
            <span>Inköpspris</span>
            <p className="mb0">{reportingFormat(netPriceIn)}</p>
          </ReportTextLabel>
        </FlexOne>
        <FlexOne>
          <ReportTextLabel>
            <span>Vinst</span>
            <p className="mb0">{reportingFormat(netMargin)}</p>
          </ReportTextLabel>
        </FlexOne>
        <FlexOne>
          <ReportTextLabel>
            <span>
              Provision
              {commissionPct ? ` (${commissionPct}%)` : null}
            </span>
            <p className="mb0">{reportingFormat(commission)}</p>
          </ReportTextLabel>
        </FlexOne>
      </Row>
    </Panel>
  );
};

export default CommissionReportSummary;
