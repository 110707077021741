import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import 'react-dates/initialize';
import { DateRangePicker, isSameDay } from 'react-dates';

export const Wrapper = styled.div`
  display: flex;
`;

function getPreset(text, unit, add = 0) {
  return {
    text,
    start: moment().add(add, unit).startOf(unit),
    end: moment().add(add, unit).endOf(unit)
  };
}

function getDiff(startDate, endDate) {
  const lastOfMonth = endDate.clone().endOf('month');
  if (startDate.date() === 1 && endDate.isSame(lastOfMonth, 'day')) {
    return {
      diff: endDate.diff(startDate, 'month') + 1,
      period: 'month'
    };
  }
  return {
    diff: endDate.diff(startDate, 'day') + 1,
    period: 'day'
  };
}

class DateRangePickerWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      focusedInput: null,
      startDate: props.startDate,
      endDate: props.endDate
    };
  }

  componentDidMount() {
    this.presets = [
      getPreset('Idag', 'day'),
      getPreset('Igår', 'day', -1),
      getPreset('Imorgon', 'day', 1),
      getPreset('Denna vecka', 'week'),
      getPreset('Förra veckan', 'week', -1),
      getPreset('Denna månad', 'month'),
      getPreset('Förra månaden', 'month', -1)
    ];
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { startDate, endDate } = nextProps;
    const startDateChanged = !startDate?.isSame(this.props.startDate);
    const endDateChanged = !endDate?.isSame(this.props.endDate);

    if (startDateChanged || endDateChanged) {
      this.setState({
        startDate: startDateChanged ? startDate : this.state.startDate,
        endDate: endDateChanged ? endDate : this.state.endDate
      });
    }
  }

  onDatesChange = ({ startDate, endDate, close = false }) => {
    const { focusedInput } = this.state;
    this.setState({ startDate, endDate });

    if (startDate && endDate) {
      this.props.onDatesChange({ startDate, endDate });
    }

    if (close || (startDate && endDate && focusedInput === 'endDate')) {
      setTimeout(() => this.setState({ focusedInput: null }), 100);
    }
  }

  onNavigatePrevious = (ev) => {
    ev.preventDefault();
    const { startDate, endDate } = this.state;
    const { diff, period } = getDiff(startDate, endDate);
    this.onDatesChange({
      startDate: startDate.clone().subtract(diff, period),
      endDate: endDate.clone().subtract(diff, period).endOf(period),
      close: true
    });
  };

  onNavigateNext = (ev) => {
    ev.preventDefault();
    const { startDate, endDate } = this.state;
    const { diff, period } = getDiff(startDate, endDate);
    this.onDatesChange({
      startDate: startDate.clone().add(diff, period),
      endDate: endDate.clone().add(diff, period).endOf(period),
      close: true
    });
  };

  onFocusChange = (focusedInput) => {
    this.setState({ focusedInput });
  }

  renderDatePresets = () => {
    const { startDate, endDate } = this.state;

    return (
      <div className="PresetDateRangePicker_panel">
        {this.presets.map(({ text, start, end }) => {
          const isSelected = isSameDay(start, startDate) && isSameDay(end, endDate);
          const className = isSelected
            ? 'PresetDateRangePicker_button PresetDateRangePicker_button__selected'
            : 'PresetDateRangePicker_button';
          const onClick = () => this.onDatesChange({ startDate: start, endDate: end, close: true });

          return (
            <button key={text} type="button" className={className} onClick={onClick}>
              {text}
            </button>
          );
        })}
      </div>
    );
  }

  render() {
    const { focusedInput, startDate, endDate } = this.state;
    const { showButtons = true } = this.props;
    return (
      <Wrapper>
        {showButtons && (
          <button className="btn btn-default" onClick={this.onNavigatePrevious}>
            <i className="fa fa-chevron-left" />
          </button>
        )}
        <DateRangePicker
          small
          minimumNights={0}
          keepOpenOnDateSelect
          hideKeyboardShortcutsPanel
          isOutsideRange={() => false}
          startDateId="date-range-start"
          endDateId="date-range-end"
          renderCalendarInfo={this.renderDatePresets}
          onDatesChange={this.onDatesChange}
          onFocusChange={this.onFocusChange}
          focusedInput={focusedInput}
          startDate={startDate}
          endDate={endDate}
        />
        {showButtons && (
          <button className="btn btn-default" onClick={this.onNavigateNext}>
            <i className="fa fa-chevron-right" />
          </button>
        )}
      </Wrapper>
    );
  }
}

export default DateRangePickerWrapper;
