import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CurrencyUtil from '@Utils/currency-util';
import { fetchCampaignEstimate } from '@State/campaign-actions';
import AlertWithIcon from '@Components/ui/alert-with-icon';
import Loader from '@Components/ui/loader';
import CheckBoxEl from '@Components/inputs/checkbox-styled';
import { Panel } from '@Components/ui/styled/main';
import { colors } from '@Components/ui/styled/variables';
import Label from '@Components/ui/label';
import CampaignContentPanel from '../campaign-content-panel';
import LabelTextGroup from '../label-text-group';

const CampaignConfirm = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const { register, watch } = useFormContext();
  const [sendAtTime, sendDate, sendTime] = watch(['sendAtTime', 'sendDate', 'sendTime']);
  const campaign = useSelector(state => state?.campaignViewState?.get('campaigns')?.find(it => +it.get('id') === +id));
  const deliveryTime = moment(`${sendDate?.format('YYYY-MM-DD')}T${sendTime}`);

  const dispatch = useDispatch();
  const [estimate, setEstimate] = useState(null);

  useEffect(() => {
    setLoading(true);
    dispatch(fetchCampaignEstimate(id))
      .then((res) => setEstimate(res))
      .finally(() => setLoading(false));
  }, [id]);

  const { recipientCount, costEstimate, limitExceeded } = estimate ?? {};
  const formattedCost = CurrencyUtil.accountCurrency(costEstimate || 0, 2);
  const isSms = campaign.get('campaignType') === 'Sms';

  return (
    <>
      <h4>Bekräfta utskick</h4>
      {loading && <Loader />}
      {!loading && recipientCount === 0 ? (
        <AlertWithIcon warning icon="fa fa-exclamation-triangle">
          Urvalet har inga mottagare. Justera urvalet för att kunna skicka meddelandet.
        </AlertWithIcon>
      ) : (
        <>
          <Panel>
            <LabelTextGroup label="Tid för utskick">
              {sendAtTime === 'now' ? 'Skickas direkt' : deliveryTime.format('LLLL')}
            </LabelTextGroup>
            <LabelTextGroup label="Mottagare">
              {recipientCount || campaign.get('recipientCount')} st
            </LabelTextGroup>
            {campaign.get('campaignType') === 'Sms' && (
              <LabelTextGroup label="Total kostnad">
                <Label bgColor={colors.bgSuccess}>
                  {formattedCost}
                </Label>
              </LabelTextGroup>
            )}
          </Panel>
          <CampaignContentPanel campaign={campaign} />
          {limitExceeded && (
            <AlertWithIcon warning icon="fa fa-exclamation-triangle">
              Utskicket kan inte skickas då det överskrider gränsen för antal skickade SMS.
              Kontakta support om du vill öka gränsen.
            </AlertWithIcon>
          )}
          {!limitExceeded && (
            <CheckBoxEl
              disabled={loading}
              label={isSms
                ? `Jag godkänner att SMS skickas till valda kunder enligt ovan, samt att mitt konto debiteras ${formattedCost}`
                : 'Jag godkänner att e-post skickas till valda kunder enligt ovan'}
              input={{ ...register('accepted') }}
            />
          )}
        </>
      )}
    </>
  );
};

export default CampaignConfirm;
