import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reportsQuerySelector } from '@Components/reports/reports-helpers';
import { Panel, Text } from '@Components/ui/styled/main';
import BookingReportStatuses from '@Components/reports/booking/booking-report-statuses';
import BookingReportChannels from '@Components/reports/booking/booking-report-channels';
import BookingReportServices from '@Components/reports/booking/booking-report-services';

class BookingReport extends Component {
  render() {
    const { reportsViewState } = this.props;
    const bookingReport = reportsViewState.get('bookingReport');
    const showReport = bookingReport && !bookingReport.get('services')?.isEmpty();

    return (
      <div>
        {showReport ? this.renderTable(bookingReport.toJS()) : this.renderNoData()}
      </div>
    );
  }

  renderTable(bookingReport) {
    return (
      <>
        <h5 className="mt0">Bokningar per status</h5>
        <Panel>
          <BookingReportStatuses {...bookingReport} />
        </Panel>
        <h5 className="mt3">Bokningar per källa</h5>
        <Panel>
          <BookingReportChannels {...bookingReport} />
        </Panel>
        <h5 className="mt3">Bokade tjänster</h5>
        <Panel>
          <BookingReportServices {...bookingReport} />
        </Panel>
        <Text marginTop="20px" fs={13} muted>
          <div>
            <sup>1</sup> Avser utebliven intäkt för avbokningar och no show
          </div>
        </Text>
      </>
    );
  }

  renderNoData() {
    return (
      <div className="alert alert-info">
        <i className="fa fa-info-circle" /> Det finns inga bokningar för vald period.
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { reportsViewState } = state;

  return {
    reportsViewState,
    reportsQuery: reportsQuerySelector(state, ownProps)
  };
};

export default connect(mapStateToProps)(BookingReport);
