import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getSysAdmin } from '@State/selectors';
import { formatOrgNo } from '@Utils/luhn-util';
import {
  GrayLabelText, PosOrgWrapItem, PosFlexWrapText, FlexParagraph, ButtonWrap
} from '@Components/admin/pos-config/style';
import { Button, Panel } from '@Components/ui/styled/main';
import PosOrgUnits from '@Components/admin/pos-config/pos-org-units';
import PopoverDeleteButton from '@Components/ui/popover-delete-button';

const PosOrgSettings = ({
  posOrg, onDeleteOrg, onShowEditOrg, onShowEditContacts, posUnits, posContacts, routeParams
}) => {
  const Owner = posContacts?.get('Owner');
  const Billing = posContacts?.get('Billing');
  const isSysAdmin = useSelector(getSysAdmin);
  const showPosUnits = isSysAdmin || !posUnits?.isEmpty();

  return (
    <>
      <GrayLabelText>Företagsuppgifter</GrayLabelText>
      <Panel className="select-text">
        <PosOrgWrapItem>
          <PosFlexWrapText>
            <GrayLabelText>Företag</GrayLabelText>
            <p>{posOrg.get('companyName')}</p>
            <p>{posOrg.getIn(['companyAddress', 'addressLine1'])}</p>
            <p>
              {posOrg.getIn(['companyAddress', 'postCode'])} {posOrg.getIn(['companyAddress', 'area'])}
            </p>
          </PosFlexWrapText>
          <PosFlexWrapText>
            <GrayLabelText>Org. nummer</GrayLabelText>
            <p>{formatOrgNo(posOrg.get('companyOrgNo'))}</p>
          </PosFlexWrapText>
          <ButtonWrap style={{ marginTop: '5px' }}>
            <Button small outlinePrimary marginLeft marginBottom onClick={onShowEditOrg}>Ändra uppgifter</Button>
            <Button small outlinePrimary marginLeft marginBottom onClick={onShowEditContacts}>Ändra kontakter</Button>
            {posUnits?.isEmpty() && (
              <PopoverDeleteButton marginLeft onDelete={() => onDeleteOrg(posOrg.get('id'))} />
            )}
          </ButtonWrap>
        </PosOrgWrapItem>
        <PosOrgWrapItem lastChild>
          {Owner && (
          <PosFlexWrapText>
            <GrayLabelText>Kontoägare</GrayLabelText>
            <FlexParagraph>{Owner.get('name')}</FlexParagraph>
            <FlexParagraph>{Owner.get('email')}</FlexParagraph>
            <FlexParagraph>{Owner.get('phone')}</FlexParagraph>
          </PosFlexWrapText>
          )}
          {Billing && (
          <PosFlexWrapText>
            <GrayLabelText>Redovisning</GrayLabelText>
            <FlexParagraph>{Billing.get('name')}</FlexParagraph>
            <FlexParagraph>{Billing.get('email')}</FlexParagraph>
            <FlexParagraph>{Billing.get('phone')}</FlexParagraph>
          </PosFlexWrapText>
          )}
          <PosFlexWrapText />
        </PosOrgWrapItem>
      </Panel>
      {showPosUnits && (
        <>
          <GrayLabelText>Kassaregister</GrayLabelText>
          <PosOrgUnits
            posOrg={posOrg}
            posOrgId={posOrg.get('id')}
            routeParams={routeParams}
            posUnits={posUnits}
          />
        </>
      )}
    </>
  );
};

PosOrgSettings.propTypes = {
  posOrg: PropTypes.object,
  onShowEditOrg: PropTypes.func,
  onShowEditContacts: PropTypes.func,
  posUnits: PropTypes.object,
  routeParams: PropTypes.object
};

export default PosOrgSettings;
