import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchPosOrgs } from '@State/pos-config-actions';
import { fetchCashiers } from '@State/users-actions';
import {
  addProduct, addService, loginOperator, fetchAvailablePosUnits,
  fetchState, setPosUnit, findReceipt, addGiftCard
} from '@State/pos-actions';
import { getFeatures } from '@State/selectors';
import {
  getSalePartialPaid, getSaleItems, hasOpenPosUnit, getPosOrgPrefs, getPosOrg, getSaleCustomer, getAvailablePosUnits, getPosUnitPrefs
} from '@State/pos-selectors';
import { pos as posKeys } from '@Utils/preference-keys';

import Loader from '@Components/ui/loader';
import Products from '@Components/pos/products';
import PosSale from '@Components/pos/sale/pos-sale';
import PosGetStarted from '@Components/pos/pos-get-started';
import PosLogin from '@Components/pos/pos-login';
import SelectPosUser from '@Components/pos/select-pos-user';
import SelectPosUnit from '@Components/pos/select-pos-unit';
import PosUnitNotAvailable from '@Components/pos/pos-unit-not-available';
import Receipts from '@Components/pos/receipts/receipts';
import Reports from '@Components/pos/reports/reports';
import NotificationBanner from '@Components/ui/notification-banner';
import DialogCreateGiftCard from '@Components/pos/dialogs/dialog-create-gift-card';
import CustomerIdContext from '@Components/customers/customer-id-context';

class Pos extends Component {
  state = {
    loading: this.props.enablePos,
    showGiftCardModal: false,
    voucherTemplateId: null
  };

  componentDidMount() {
    const {
      enablePos, hasOpenPosUnit, fetchState
    } = this.props;
    if (enablePos) {
      Promise.all([
        this.props.loadData(),
        hasOpenPosUnit && fetchState()
      ]).then(() => this.setState({ loading: false }));
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.hasOpenPosUnit && this.props.hasOpenPosUnit) {
      this.props.fetchState();
    }
  }

  handleProductClick = item => this.props.addProduct(item);

  handleServiceClick = item => this.props.addService(item);

  handleGiftCardClick = ({ id }) => this.setState({ showGiftCardModal: true, voucherTemplateId: id });

  handleCloseGiftCard = () => this.setState({ showGiftCardModal: false, voucherTemplateId: null });

  handleAddGiftCard = (giftCard) => {
    return this.props.addGiftCard(giftCard)
      .then(this.handleCloseGiftCard);
  };

  render() {
    const { loading, showGiftCardModal, voucherTemplateId } = this.state;
    const {
      partialPaid, hasOpenPosUnit, enablePos, routeParams, operator, posOrg,
      posOrgs, posUnits, posUnit, cashiersById, loginOperator, setPosUnit,
      saleItems, saleId, useOperatorPinCode, posOrgPrefs, posUnitPrefs, customer
    } = this.props;

    const posUnitNotSelected = posUnits && !posUnit;

    if (loading && posOrgs.isEmpty()) {
      return <Loader />;
    }
    if (!enablePos || posOrgs.isEmpty()) {
      return <PosGetStarted routeParams={routeParams} />;
    }
    if (!operator && useOperatorPinCode) {
      return <PosLogin onSubmit={pin => loginOperator({ pin })} />;
    }
    if (!operator && !useOperatorPinCode) {
      return <SelectPosUser users={cashiersById} onSelect={userId => loginOperator({ userId })} />;
    }
    if (posUnitNotSelected) {
      return <SelectPosUnit posUnits={posUnits} onSelect={setPosUnit} />;
    }
    if (!posUnit) {
      return null;
    }

    if (location.pathname.includes('pos/reports')) {
      return <Reports routeParams={routeParams} />;
    }
    if (location.pathname.includes('pos/receipts')) {
      return <Receipts routeParams={routeParams} />;
    }

    if (!hasOpenPosUnit) {
      return <PosUnitNotAvailable posUnit={posUnit} routeParams={routeParams} />;
    }

    return (
      <div className="pos">
        {partialPaid && <NotificationBanner text="Betalning påbörjad, ändringar ej möjligt" />}
        <div className="pos-content">
          <PosSale routeParams={routeParams} />
          <div className="pos-products">
            <Products
              posUnit={posUnit}
              posUnitPrefs={posUnitPrefs}
              saleId={saleId}
              saleItems={saleItems}
              disabled={partialPaid}
              routeParams={routeParams}
              onProductClick={this.handleProductClick}
              onServiceClick={this.handleServiceClick}
              onGiftCardClick={this.handleGiftCardClick}
            />
          </div>
        </div>
        {showGiftCardModal && (
          <CustomerIdContext.Provider value={customer?.customerId}>
            <DialogCreateGiftCard
              posOrg={posOrg}
              posUnit={posUnit}
              posOrgPrefs={posOrgPrefs}
              voucherTemplateId={voucherTemplateId}
              onSubmit={this.handleAddGiftCard}
              onClose={this.handleCloseGiftCard}
            />
          </CustomerIdContext.Provider>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { posOrgs, pos, posSale, cashiersById, locationConfig } = state;
  const features = getFeatures(state);

  return {
    posOrgs,
    customer: getSaleCustomer(state),
    hasOpenPosUnit: hasOpenPosUnit(state),
    saleItems: getSaleItems(state),
    saleId: posSale.get('id'),
    enablePos: features.EnablePOS,
    routeParams: ownProps.match.params,
    partialPaid: getSalePartialPaid(state),
    posUnits: getAvailablePosUnits(state),
    posOrg: getPosOrg(state),
    posOrgPrefs: getPosOrgPrefs(state),
    posUnitPrefs: getPosUnitPrefs(state),
    operator: pos.get('operator'),
    posUnit: pos.get('posUnit'),
    useOperatorPinCode: locationConfig.get(posKeys.useOperatorPinCode),
    cashiersById
  };
};

const mapDispatchToProps = dispatch => ({
  loadData: () => Promise.all([
    dispatch(fetchPosOrgs()),
    dispatch(fetchAvailablePosUnits()),
    dispatch(fetchCashiers())
  ]),
  fetchState: () => dispatch(fetchState()),
  findReceipt: barcode => dispatch(findReceipt(barcode)),
  addProduct: product => dispatch(addProduct(product)),
  addService: service => dispatch(addService(service)),
  addGiftCard: giftCard => dispatch(addGiftCard(giftCard)),
  loginOperator: data => dispatch(loginOperator(data)),
  setPosUnit: posUnit => dispatch(setPosUnit(posUnit))
});

export default connect(mapStateToProps, mapDispatchToProps)(Pos);
