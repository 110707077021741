import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { fetchLocationOptions } from '@State/account-actions';
import { getLastVisitedPage, setLastVisitedPage } from '@Utils/session';
import Loader from '@Components/ui/loader';
import Locations from './Locations';

class LocationSelection extends Component {
  state = {
    loading: true
  };

  componentDidMount() {
    this.props.onLoad()
      .then(() => this.setState({ loading: false }));
  }

  render() {
    if (this.state.loading) {
      return <Loader solid />;
    }

    const lastVisitedPage = getLastVisitedPage();
    if (lastVisitedPage) {
      setLastVisitedPage(null);
      return <Redirect to={lastVisitedPage} />;
    }

    if (this.props.locations.size === 1) {
      const location = this.props.locations.valueSeq().first();
      return <Redirect to={`/${location.orgCtxName}/${location.locCtxName}/calendar/`} />;
    }

    const defaultOrgLoc = localStorage.getItem('defaultOrgLoc');
    if (defaultOrgLoc) {
      return <Redirect to={`/${defaultOrgLoc}/calendar/`} />;
    }

    const sortedOptions = this.props.locations.valueSeq().sort((a, b) => {
      const aStr = (a.orgName + a.locName);
      const bStr = (b.orgName + b.locName);

      return aStr.localeCompare(bStr, 'sv');
    });

    return <Locations locations={sortedOptions} />;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    locations: state.locationOptions
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onLoad: () => {
      return dispatch(fetchLocationOptions());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationSelection);
