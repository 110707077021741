import moment from 'moment';
import { getFirstSchedule } from '@Utils/schedule-utils';
import { calcWeekViewTimeOffsetForDay, pixelMultiplier } from '@Utils/time-util';

export default class GridScroller {
  constructor(gridProps, schedulesByResource, bookingsById, routeParams) {
    this.gridProps = gridProps;
    this.schedulesByResource = schedulesByResource;
    this.bookingsById = bookingsById;
    this.routeParams = routeParams;
  }

  calcYForTime(moment) {
    const { gridScrollHeight } = this.gridProps;
    const secondsPassed = (moment.hour() * 3600) + (moment.minute() * 60) + moment.second();
    const secondsInDay = 24 * 60 * 60;
    const pctOfDay = secondsPassed / secondsInDay;
    return pctOfDay * gridScrollHeight;
  }

  scrollToTime(hour, minute, smooth) {
    const { pixelsPerRow, rowsPerHour } = this.gridProps;
    const lineTop = this.calcYForTime(moment().hours(hour).minutes(minute));
    this.scrollGridTo(lineTop - (pixelsPerRow * rowsPerHour), smooth);
  }

  scrollToMoment(moment, smooth) {
    const { pixelsPerRow, rowsPerHour } = this.gridProps;
    const lineTop = this.calcYForTime(moment);
    this.scrollGridTo(lineTop - (pixelsPerRow * rowsPerHour), smooth);
  }

  scrollGridTo(position, smooth) {
    const behavior = smooth ? 'smooth' : 'auto';
    document.getElementById('gridcontainer').scrollTo({
      top: position,
      behavior
    });
  }

  firstSchedule() {
    return getFirstSchedule(this.schedulesByResource);
  }

  firstBooking() {
    const { bookingsById } = this;
    if (bookingsById.size === 0) {
      return null;
    }
    return moment(bookingsById.sortBy(b => b.startTime.hour() * 60 + b.startTime.minutes()).first().startTime);
  }

  scrollToFirstElement(smooth) {
    const { gridProps, bookingsById, routeParams: { bookingId } } = this;
    const {
      rowsPerHour, pixelsPerRow, viewDateStartEnd, isWeekView, gridScrollHeight
    } = gridProps;
    const multiplier = pixelMultiplier(rowsPerHour);
    const hihglighted = bookingsById.get(bookingId);

    if (hihglighted) {
      this.scrollToMoment(hihglighted.startTime, smooth);
      return;
    }

    const firstSchedule = this.firstSchedule();
    const firstBooking = this.firstBooking();
    if (firstBooking === null && firstSchedule === null) {
      return;
    }

    let firstTime;

    if (firstSchedule === null) {
      firstTime = firstBooking;
    } else if (firstBooking === null) {
      firstTime = firstSchedule;
    } else {
      firstBooking.dayOfYear(firstSchedule.dayOfYear());
      firstTime = firstBooking.valueOf() > firstSchedule.valueOf() ? firstSchedule : firstBooking;
    }

    const hourHeight = rowsPerHour * pixelsPerRow;
    const compTime = calcWeekViewTimeOffsetForDay(viewDateStartEnd.start) * 1000;
    const minToPixelFactor = (hourHeight) / 60;
    const startPx = (((firstTime.valueOf() - firstTime.startOf('day').valueOf() + compTime) / (1000 * 60)) * minToPixelFactor);
    const scrollTo = startPx - (pixelsPerRow * multiplier);

    this.scrollGridTo(scrollTo, smooth);
  }
}
