import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  deleteUser, updateUserName, makeOwner, removeOwner
} from '@State/users-actions';
import Popover from '@Components/ui/popover';
import ConfirmBlock from '@Components/ui/confirm-block';
import {
  TextEllipsis, Button, Flex, Text, Column
} from '@Components/ui/styled/main';
import { getSectionUrl, navigate } from '@Utils/navigate';
import TextInputCustom from '@Components/inputs/text-input-custom';
import SetPasswordModal from './set-password/set-password-modal';

const StaffConfigHeader = ({
  routeParams, dispatch, id, name, userEmail, isLoggedInUser, canChangeOwner, isOwner
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [showConfirmMakeOwner, setShowConfirmMakeOwner] = useState(false);
  const [showConfirmRemoveOwner, setShowConfirmRemoveOwner] = useState(false);
  const [showConfirmDeleteUser, setShowConfirmDeleteUser] = useState(false);
  const [showSetPassword, setShowSetPassword] = useState(false);

  const showButtons = !showConfirmMakeOwner && !showConfirmRemoveOwner && !showConfirmDeleteUser;
  const mounted = true;

  const onShowSetPassword = () => {
    setShowSetPassword(true);
    handleClose();
  };

  const handleClose = () => {
    if (mounted) {
      setShowConfirmMakeOwner(false);
      setShowConfirmRemoveOwner(false);
      setShowConfirmDeleteUser(false);
      setShowMenu(false);
    }
  };

  const handleDeleteUser = async () => {
    await dispatch(deleteUser(id));
    handleClose();
    return navigate(getSectionUrl(routeParams, 'admin/users'));
  };

  const handleMakeOwner = async () => {
    await dispatch(makeOwner(id));
    handleClose();
  };

  const handleRemoveOwner = async () => {
    await dispatch(removeOwner(id));
    handleClose();
  };

  const handleSaveName = (newValue) => dispatch(updateUserName(id, newValue));

  const renderPopupBody = (
    <div>
      {showConfirmMakeOwner && (
        <div className="Popover-dialog-sm button-wrapper">
          <ConfirmBlock
            confirmText="OK"
            title={`Gör ${name || userEmail} till kontoägare?`}
            onCancel={() => setShowConfirmMakeOwner(false)}
            onConfirm={handleMakeOwner}
          />
        </div>
      )}
      {showConfirmRemoveOwner && (
        <div className="Popover-dialog-sm button-wrapper">
          <ConfirmBlock
            confirmText="OK"
            title={`Ta bort ${name || userEmail} som kontoägare?`}
            onCancel={() => setShowConfirmRemoveOwner(false)}
            onConfirm={handleRemoveOwner}
          />
        </div>
      )}
      {showConfirmDeleteUser && (
        <div className="Popover-dialog-sm button-wrapper">
          <ConfirmBlock
            title={`Radera användare ${name || userEmail}?`}
            onCancel={() => setShowConfirmDeleteUser(false)}
            onConfirm={handleDeleteUser}
            confirmDanger
          />
        </div>
      )}
      {showButtons && (
        <div className="Popover-dialog-sm button-wrapper">
          <Column className="menu-button-wrapper">
            {canChangeOwner && isOwner && (
              <Button small gray block disabled={isLoggedInUser} onClick={() => setShowConfirmRemoveOwner(true)}>
                Ta bort som kontoägare
              </Button>
            )}
            {canChangeOwner && !isOwner && (
              <Button small gray block disabled={isLoggedInUser} onClick={() => setShowConfirmMakeOwner(true)}>
                Gör till kontoägare
              </Button>
            )}
            {canChangeOwner && !isLoggedInUser && (
              <Button small gray block onClick={onShowSetPassword}>
                Byt lösenord
              </Button>
            )}
            <Button small danger block disabled={isLoggedInUser || isOwner} onClick={() => setShowConfirmDeleteUser(true)}>
              Radera
            </Button>
          </Column>
        </div>
      )}
    </div>
  );

  return (
    <div>
      <div className="user-header">
        <Flex>
          <div className="header-title">
            <TextInputCustom
              value={name}
              onSave={handleSaveName}
            />
            <Text fs={13}>
              <TextEllipsis width={220} className="select-all">{userEmail}</TextEllipsis>
            </Text>
          </div>
          <Flex right>
            <Popover
              preferPlace="below"
              isOpen={showMenu}
              onOuterAction={handleClose}
              body={renderPopupBody}
              className="Popover-big-radius"
            >
              <Button gray small onClick={() => setShowMenu(!showMenu)}>
                Alternativ
                <i className="far fa-chevron-down" />
              </Button>
            </Popover>
          </Flex>
        </Flex>
      </div>
      {showSetPassword && (
        <SetPasswordModal
          userId={id}
          onClose={() => setShowSetPassword(false)}
        />
      )}
    </div>
  );
};

StaffConfigHeader.propTypes = {
  name: PropTypes.string,
  userEmail: PropTypes.string,
  id: PropTypes.number
};

export default StaffConfigHeader;
