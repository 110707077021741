import styled, { css } from 'styled-components';

export default styled.div`
  display: flex;
  align-items: ${props => props.vCenter ? 'center' : 'flex-start'};
  justify-content: ${props => props.center ? 'center' : 'flex-start'};
  flex: ${props => props.size ? props.size : 1};
  ${props => props.fullWidth && css`
    justify-content: space-between;
  `};
  ${props => props.rightMargin && css`
    margin-right: 15px;
  `};
  ${props => props.right && css`
    justify-content: flex-end;
    text-align: right;
  `};
`;
