import React from 'react';

const CheckBoxChild = ({ input, children, meta: { warning } }) => (
  <label>
    <input type="checkbox" checked={input.value} {...input} />
    {' '}{children}{' '}
    {warning && <i className="fa fa-fw fa-info-circle text-highlight-warn" title={warning} />}
  </label>
);

export default CheckBoxChild;
