import { defineMessages } from 'react-intl';

export default defineMessages({
  showLabel: {
    id: 'calendar.bkf.flags.showLabel',
    defaultMessage: 'Show'
  },
  noShowLabel: {
    id: 'calendar.bkf.flags.noShowLabel',
    defaultMessage: 'No Show'
  },
  askedForLabel: {
    id: 'calendar.bkf.flags.askedForLabel',
    defaultMessage: 'Booked person'
  },
  dropInLabel: {
    id: 'calendar.bkf.flags.dropInLabel',
    defaultMessage: 'Drop in'
  }
});
