import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getSectionUrl, navigate } from '@Utils/navigate';
import ColumnListItem from '@Components/ui/column/column-list-item';
import Label from '@Components/ui/label';
import Button from '@Components/ui/button';
import ReleaseNoteEditModal from './release-notes-edit-modal';
import { ReleaseNoteLabels, getReleaseNoteColor } from './release-notes-helpers';

export const ReleaseNotesList = ({ routeParams }) => {
  const releaseNotes = useSelector(state => state.sysadminReleaseNotes);
  const { id: routeId } = useParams();
  const [showModal, setShowModal] = useState(false);

  const onSelect = useCallback((listId) => {
    if (+routeId !== +listId) {
      navigate(`${getSectionUrl(routeParams, 'release-notes')}${listId}`);
    }
  }, [routeId, routeParams]);

  useEffect(() => {
    const noSelected = !routeId && releaseNotes;
    const selectedNotExisting = routeId && releaseNotes && !releaseNotes.find(c => c.get('id') === +routeId);

    if (noSelected || selectedNotExisting) {
      const firstId = releaseNotes.first()?.get('id');
      if (firstId && firstId !== +routeId) {
        navigate(`${getSectionUrl(routeParams, 'release-notes')}${firstId}`);
      }
    }
  }, [routeId, releaseNotes]);

  return (
    <>
      <div className="columns-header">
        <h1>Release notes</h1>
        <div className="columns-header-tools">
          <Button small gray onClick={() => setShowModal(true)}>
            Skapa ny
          </Button>
        </div>
      </div>
      <div className="columns-list">
        <br />
        {releaseNotes && !releaseNotes.isEmpty() ? releaseNotes.sortBy(r => r.get('date')).reverse().toJS().map(
          (releaseNote) => {
            const { id, title, label, date, published } = releaseNote;

            return (
              <ColumnListItem
                key={id}
                onClick={() => onSelect(id)}
                selected={+routeId === +id}
                header={title}
                subHeader={(
                  <Label bgColor={getReleaseNoteColor(label)}>
                    {ReleaseNoteLabels[label] || label}
                  </Label>
                )}
              >
                <i className={published ? 'far fa-check' : 'far fa-cancel'} />
                {date}
              </ColumnListItem>
            );
          }
        ) : (
          <div className="columns-list-help">
            <em>Det finns inga release notes</em>
          </div>
        )}
      </div>
      {showModal && (
        <ReleaseNoteEditModal
          isNew
          routeParams={routeParams}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  );
};
