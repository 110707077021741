import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';

import TextInput from '@Components/inputs/text-input';
import ModalDialog from '@Components/dialogs/modal-dialog';

const TerminalForm = ({ handleSubmit, onClose, submitting }) => (
  <ModalDialog
    title="Kortterminal"
    onClose={onClose}
    buttons={[{
      name: 'Spara',
      primary: true,
      type: 'submit',
      loading: submitting,
      onClick: handleSubmit
    }]}
  >
    <form onSubmit={handleSubmit} className="dialog">
      <Field name="name" component={TextInput} label="Namn" />
      <Field name="vendorId" component={TextInput} label="Vendor ID" disabled />
      <Field name="vendorDeviceString" component={TextInput} label="Beskrivning" disabled />
    </form>
  </ModalDialog>
);

export default reduxForm({
  form: 'terminal-form'
})(TerminalForm);
