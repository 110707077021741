import styled, { css } from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-grow: 1;

  ${p => p.scrollContainer && css`
    margin-left: -20px;
    margin-right: -20px;
  `}
`;
