export const normalizeOrgNoSE = (value) => {
  const digits = value.replace(/[^\d]/g, '');
  if (digits.length <= 6) {
    return digits;
  }
  return `${digits.slice(0, 6)}-${digits.slice(6, 10)}`;
};

export const normalizeOrgNoFI = (value) => {
  const digits = value.replace(/[^\d]/g, '');
  if (digits.length <= 7) {
    return digits;
  }
  return `${digits.slice(0, 7)}-${digits.slice(7, 8)}`;
};
