import React from 'react';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import CurrencyUtil from '@Utils/currency-util';
import { getSmsEnabled, getSmsCampaignEnabled, getEmailCampaignEnabled } from '@State/selectors';
import AlertWithIcon from '@Components/ui/alert-with-icon';
import PanelRadio from '@Components/inputs/panel-radio';
import { getAudienceTypeName, getSmsCampaignPrice } from '../campaign-helpers';

const getAudienceTypeOptions = (showTopSpenders) => [
  { value: 'All', description: 'Samtliga kunder eller kunder som hör till en specifik resurs' },
  { value: 'Booked', description: 'Kunder med bokningar under en viss period' },
  { value: 'NewCustomers', description: 'Kunder som tillkommit under en viss period' },
  showTopSpenders
    ? { value: 'TopSpenders', description: 'Kunder som spenderat över valt belopp under en viss period' }
    : null
].filter(o => o);

const CampaignType = ({ isNew = false }) => {
  const { register } = useFormContext();
  const smsEnabled = useSelector(getSmsEnabled);
  const smsCampaignEnabled = useSelector(getSmsCampaignEnabled);
  const emailCampaignEnabled = useSelector(getEmailCampaignEnabled);
  const smsCampaignPrice = useSelector(getSmsCampaignPrice);
  const showTopSpenders = useSelector(state => state.locationConfig.get('vunits') > 0);

  return (
    <div>
      <h4>{isNew ? 'Skapa nytt utskick' : 'Välj typ av utskick'}</h4>
      {!smsCampaignEnabled && (
        <AlertWithIcon warning>
          {smsEnabled
            ? 'SMS-utskick ej tillgängligt - kontakta support för aktivering.'
            : 'SMS-utskick ej tillgängligt - SMS är inte aktiverat på kontot.'}
        </AlertWithIcon>
      )}
      {!emailCampaignEnabled && (
        <AlertWithIcon warning>
          Mejlutskick ej tillgängligt - kontakta support för aktivering.
        </AlertWithIcon>
      )}
      <PanelRadio
        input={{ ...register('campaignType') }}
        value="Sms"
        label="SMS-utskick"
        disabled={!smsCampaignEnabled}
        description={`Hög leveranssäkerhet. De flesta läser ett SMS direkt. Kostnad för SMS tillkommer (${CurrencyUtil.currency(smsCampaignPrice)}/st).`}
      />
      <PanelRadio
        input={{ ...register('campaignType') }}
        value="Email"
        label="Mejlutskick"
        disabled={!emailCampaignEnabled}
        description="Skicka e-post till dina kunder. Kostar inget extra, men det finns risk att det hamnar i skräpposten."
      />
      <h4 className="mt4">Välj mottagare</h4>
      {getAudienceTypeOptions(showTopSpenders).map(item => (
        <PanelRadio
          key={item.value}
          input={{ ...register('audienceType') }}
          label={getAudienceTypeName(item.value)}
          {...item}
        />
      ))}
    </div>
  );
};

export default CampaignType;
