import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setPosUnitAutoClose } from '@State/pos-config-actions';
import { Button } from '@Components/ui/styled/main';
import LabelSwitchInput from '@Components/ui/label-switch-input';
import Popover from '@Components/ui/popover';
import { DialogButtonsHorizontal } from '@Components/dialogs/dialog-styles';
import TimePickerInput from '@Components/inputs/time-picker-input';

const defaultTime = '20:00';

function roundTime(time) {
  const parts = String(time).split(':');
  return time[4] !== '0' ? `${parts[0]}:${parts[1][0]}0` : time;
}

function hourAndMinute(time) {
  const parts = String(time).split(':');
  return parts.length >= 2 ? `${parts[0]}:${parts[1]}` : time;
}

const PosUnitAutoClose = ({ posUnit }) => {
  const enabled = posUnit?.get('autoCloseEnabled') || false;
  const closeTime = posUnit?.get('autoCloseTime') || defaultTime;

  const dispatch = useDispatch();
  const [showPopover, setShowPopover] = useState(false);
  const [popoverTime, setPopoverTime] = useState(closeTime);
  const [saving, setSaving] = useState(false);

  const onChangeTime = (ev, time) => {
    setPopoverTime(time);
  };
  const hidePopover = () => setShowPopover(false);

  const onToggle = useCallback(() => {
    const { id, posOrgId, autoCloseEnabled } = posUnit.toJS();
    return dispatch(setPosUnitAutoClose(posOrgId, id, !autoCloseEnabled, closeTime));
  }, [posUnit]);

  const onSetTime = useCallback((ev) => {
    setSaving(true);
    ev.preventDefault();
    const { id, posOrgId } = posUnit.toJS();
    return dispatch(setPosUnitAutoClose(posOrgId, id, true, roundTime(popoverTime)))
      .then(hidePopover)
      .finally(() => setSaving(false));
  }, [posUnit, popoverTime]);

  const renderPopoverBody = () => (
    <div className="Popover-dialog-sm">
      <form onSubmit={onSetTime}>
        <div className="form-group mb5">
          <label className="control-label">Tid för dagsavslut</label>
          <TimePickerInput
            time={popoverTime}
            onChange={onChangeTime}
          />
          {popoverTime[4] !== '0' && (
            <span className="form-control-description">
              Tiden kommer avrundas till {roundTime(popoverTime)}
            </span>
          )}
        </div>
        <DialogButtonsHorizontal>
          <Button small gray onClick={hidePopover}>Avbryt</Button>
          <Button type="submit" loading={saving} small primary onClick={onSetTime}>Spara</Button>
        </DialogButtonsHorizontal>
      </form>
    </div>
  );

  return (
    <div className="form-group">
      <LabelSwitchInput
        noBorder
        label="Automatiskt dagsavslut"
        id="auto-close"
        isOn={enabled}
        handleToggle={onToggle}
        description="Gör automatiskt dagsavlut i kassan vid en tidpunkt, om det inte redan är gjort"
      />
      {enabled && (
        <div className="mt2">
          Tid för dagsavslut:{' '}
          <Popover
            isOpen={showPopover}
            className="Popover-big-radius"
            onOuterAction={hidePopover}
            body={renderPopoverBody()}
          >
            <Button tiny gray onClick={() => setShowPopover(true)}>
              {hourAndMinute(closeTime)}
            </Button>
          </Popover>
        </div>
      )}
    </div>
  );
};

export default PosUnitAutoClose;
