import React from 'react';
import NavTabsLink from '@Components/ui/nav-tabs-link';

const sections = [
  { navId: 'general', name: 'Allmänt' },
  { navId: 'prefs', name: 'Prefs' },
  { navId: 'templates', name: 'Templates' },
  { navId: 'private-prefs', name: 'Private Prefs' },
  { navId: 'custom-texts', name: 'Custom Texts' }
];

const JSONNav = ({ routeParams }) => (
  <NavTabsLink
    subUrl="admin/json-prefs"
    routeParams={routeParams}
    sections={sections}
    withoutId
  />
);

export default JSONNav;
