import React, { Component } from 'react';
import { connect } from 'react-redux';
import AriaModal from 'react-aria-modal';
import { toggleGridScrollability } from '@State/view-actions';

class Modal extends Component {
  render() {
    const { ...props } = this.props;

    return (
      <AriaModal {...props}>
        {this.props.children}
      </AriaModal>
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.mounted !== prevProps.mounted) {
      this.props.toggleScrollability(!this.props.mounted);
    }
  }

  componentWillUnmount() {
    this.props.toggleScrollability(true);
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    toggleScrollability: (scrollable) => {
      dispatch(toggleGridScrollability(scrollable));
    }
  };
};

export default connect(null, mapDispatchToProps)(Modal);
