import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getRouteParams } from '@State/selectors';
import { fetchAllReleaseNotes } from '@State/sysadmin-actions';
import { ReleaseNotesList } from '@Components/sysadmin/release-notes/release-notes-list';
import ReleaseNotesOverview from '@Components/sysadmin/release-notes/release-notes-overview';
import Loader from '@Components/ui/loader';

import '../../../styles/release-notes.less';

class ReleaseNotes extends Component {
  state = {
    loading: true
  };

  componentDidMount() {
    this.props.fetchReleaseNotes()
      .finally(() => this.setState({ loading: false }));
  }

  render() {
    const { loading } = this.state;
    const { routeParams } = this.props;

    if (loading) {
      return <Loader />;
    }

    return (
      <div className="columns-wrapper">
        <div className="columns-container">
          <div className="columns-sidebar">
            <ReleaseNotesList routeParams={routeParams} />
          </div>
          <div className="columns-content">
            <div className="columns-content-container width-medium">
              <div className="columns-content-body with-padding">
                <ReleaseNotesOverview routeParams={routeParams} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { sysadminReleaseNotes } = state;

  return {
    sysadminReleaseNotes,
    routeParams: getRouteParams(state, ownProps)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchReleaseNotes: () => dispatch(fetchAllReleaseNotes())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReleaseNotes);
