import PropTypes from 'prop-types';
import React, { Component } from 'react';

class TextInputHorizontal extends Component {
  render() {
    const {
      input, label, placeholder, unitLabel, helpText, width, disabled, meta: { touched, error }
    } = this.props;
    const styleFormContainer = { width };
    return (
      <div className={touched && error ? 'form-group has-error' : 'form-group'}>
        <label className="col-sm-3 control-label">{label}</label>
        <div className="col-sm-5">
          <div className="form-control-container" style={styleFormContainer}>
            {unitLabel && <label className="form-control-label">{unitLabel}</label>}
            <input type="text" className="form-control" placeholder={placeholder} disabled={disabled} {...input} />
          </div>
          {touched && error && <span className="help-block">{error}</span>}
          {helpText && <span className="form-control-description">{helpText}</span>}
        </div>
      </div>
    );
  }
}

TextInputHorizontal.propTypes = {
  label: PropTypes.string.isRequired,
  helpText: PropTypes.string
};

export default TextInputHorizontal;
