import Cookies from 'universal-cookie';
import { authCookieDomain, authCookieName } from './config';

const cookies = new Cookies();

function setCookie(name, value) {
  const domain = authCookieDomain();
  const secure = !!domain;

  if (value) {
    cookies.set(name, value, {
      expires: new Date(2034, 7, 26),
      domain,
      secure,
      path: '/'
    });
  } else {
    cookies.remove(name, {
      domain,
      secure,
      path: '/'
    });
  }
}

export function getAuthTokenCookie() {
  return cookies.get(authCookieName());
}

export function setAuthTokenCookie(token) {
  setCookie(authCookieName(), token);
}

export function clearAuthTokenCookie() {
  setCookie(authCookieName(), null);
}

export function getAllCookies() {
  return cookies.getAll();
}
