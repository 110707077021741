export const UNPRIVILEGED_ACCESS = 'UNPRIVILEGED_ACCESS';
export const VIEW_ALL_BOOKINGS = 'VIEW_ALL_BOOKINGS';
export const CHANGE_ALL_BOOKINGS = 'CHANGE_ALL_BOOKINGS';
export const VIEW_ALL_CUSTOMERS = 'VIEW_ALL_CUSTOMERS';
export const CHANGE_ALL_CUSTOMERS = 'CHANGE_ALL_CUSTOMERS';
export const EXPORT_CUSTOMERS = 'EXPORT_CUSTOMERS';
export const CHANGE_LOCATION_SCHEDULE = 'CHANGE_LOCATION_SCHEDULE';
export const CHANGE_ALL_SCHEDULES = 'CHANGE_ALL_SCHEDULES';
export const CHANGE_LOCATION_SERVICES = 'CHANGE_LOCATION_SERVICES';
export const CHANGE_LOCATION_PRODUCTS = 'CHANGE_LOCATION_PRODUCTS';
export const CHANGE_GROUPS = 'CHANGE_GROUPS';
export const CHANGE_RESOURCES = 'CHANGE_RESOURCES';
export const CREATE_LOCATION = 'CREATE_LOCATION';
export const CREATE_ORGANISATION = 'CREATE_ORGANISATION';
export const CHANGE_LOCATION_SETTINGS = 'CHANGE_LOCATION_SETTINGS';
export const CHANGE_JSON_PREFS = 'CHANGE_JSON_PREFS';
export const ADMINISTRATE_LOC_USERS = 'ADMINISTRATE_LOC_USERS';
export const POS_ADMIN = 'POS_ADMIN';
export const SHOW_REPORTS = 'SHOW_REPORTS';
export const CREATE_STAFF_REPORT = 'CREATE_STAFF_REPORT';

export function defaultLocation(locationOptions) {
  if (locationOptions === undefined || locationOptions[0] === undefined) {
    console.error('No locations available for current user');
    return undefined;
  }
  return locationOptions[0];
}

export function currentLocation(locationOptions, org, loc) {
  const orgLocSlug = `${org}/${loc}`;
  return locationOptions.find(option => option.orgLoc == orgLocSlug);
}

export function orgLocIsValid(locationOptions, org, loc) {
  if (locationOptions == undefined) { return false; }
  const orgLocSlug = `${org}/${loc}`;
  return locationOptions.find(option => option.orgLoc == orgLocSlug) !== undefined;
}

export function hasPermission(permissions, perm) {
  return permissions && permissions.indexOf(perm) > -1;
}
