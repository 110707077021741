import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { deleteOrganisation } from '@State/sysadmin-actions';
import PopoverDeleteButton from '@Components/ui/popover-delete-button';

const AccountHeaderBtnDelete = ({ orgId, orgCtxName }) => {
  const dispatch = useDispatch();

  const onDelete = () => {
    return dispatch(deleteOrganisation(orgId, orgCtxName));
  };

  return (
    <PopoverDeleteButton
      onDelete={onDelete}
      confirmText="Vill du ta bort kontot?"
    />
  );
};

AccountHeaderBtnDelete.propTypes = {
  id: PropTypes.string
};

export default AccountHeaderBtnDelete;
