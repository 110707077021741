import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import SelectForm from '@Components/ui/select/select-form';
import { getSysadminLocations } from '@Components/sysadmin/accounts/accounts-selector';

const SelectLocation = (props) => {
  const sysadminLocations = useSelector(state => getSysadminLocations(state));
  const getLocationOptions = () => [
    { text: 'No location', value: '' },
    ...sysadminLocations.map((v) => ({
      value: +v.get('id'),
      text: `${v.get('name')} + (${v.get('orgCtxName')}/${v.get('ctxName')})`
    }))
  ];

  return (
    <SelectForm
      labelText="Location id"
      options={getLocationOptions()}
      {...props}
    />
  );
};

SelectLocation.propTypes = {
  onChangeSelect: PropTypes.func
};

export default SelectLocation;
