const PhoneNumberParser = require('./phone-number-parser');

module.exports = {

  initialize() {
  },

  isMobile(phNo) {
    return phNo && PhoneNumberParser.isMobile(phNo);
  },

  formatPhoneNumber(phNo) {
    if (!phNo) {
      return '';
    }

    return PhoneNumberParser.parseNumber(phNo).localized();
  },

  formatPhoneNumberE164(phNo) {
    if (!phNo) {
      return '';
    }

    return PhoneNumberParser.parseNumber(phNo).e164formatted();
  }
};
