import { defineMessages } from 'react-intl';

export default defineMessages({
  lblAddComment: {
    id: 'customers.logs.lblAddComment',
    defaultMessage: 'Add comment...'
  },
  btnSave: {
    id: 'customers.logs.btnSave',
    defaultMessage: 'Save'
  },
  btnDelete: {
    id: 'customers.logs.btnDelete',
    defaultMessage: 'Delete'
  },
  confirmDelete: {
    id: 'customers.logs.confirmDelete',
    defaultMessage: 'Delete comment? It will be permenently deleted'
  }
});
