import React, { useState, useEffect } from 'react';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { fetchXReportSummary, withdrawCash, depositCash } from '@State/pos-actions';

import TextInput from '@Components/inputs/text-input';
import CurrencyUtil from '@Utils/currency-util';
import DialogLoader from '@Components/dialogs/dialog-loader';
import ModalDialog from '@Components/dialogs/modal-dialog';
import { DialogReceiptContentWrap } from '@Components/dialogs/dialog-styles';
import { Flex, Text } from '@Components/ui/styled/main';

const selector = formValueSelector('dialog-pos-deposit-withdraw');

const DialogPosDepositWithdraw = ({ handleSubmit, onClose }) => {
  const dispatch = useDispatch();

  const [isDeposit, setIsDeposit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [reportSummary, setReportSummary] = useState();
  const cashAmount = useSelector(state => selector(state, 'cashAmount'));

  useEffect(() => {
    if (!reportSummary) {
      dispatch(fetchXReportSummary()).then((summary) => {
        setReportSummary(summary);
      });
    }
  }, []);

  const handleDepositWithdrawal = ({ cashAmount, comment }) => {
    setIsLoading(true);
    return dispatch(isDeposit
      ? depositCash(Math.abs(cashAmount), comment)
      : withdrawCash(Math.abs(cashAmount) * -1, comment))
      .then(() => onClose())
      .catch(() => setIsLoading(false));
  };

  const onSetDeposit = (ev, value) => {
    ev.preventDefault();
    setIsDeposit(value);
  };

  if (isLoading) {
    return <DialogLoader title={isDeposit ? 'Registrerar insättning...' : 'Registrerar uttag...'} />;
  }

  const expectedCashAmount = reportSummary?.vunitState?.currentCashBalance || 0;
  const changeAmount = Math.abs(cashAmount) * (isDeposit ? 1 : -1);
  const remainingAmount = expectedCashAmount + changeAmount;

  return (
    <ModalDialog
      contentSize="medium"
      onClose={onClose}
      title="Växelkassa"
      closeOnClickOutside={false}
      buttons={[{
        name: isDeposit ? 'Registrera insättning' : 'Registrera uttag',
        primary: true,
        onClick: handleSubmit(handleDepositWithdrawal),
        disabled: remainingAmount < 0
      }, {
        name: 'Avbryt',
        gray: true,
        onClick: onClose
      }]}
    >
      <form onSubmit={handleSubmit(handleDepositWithdrawal)} autoComplete="off" className="dialog">
        <div className="pos-segment dialog">
          <ul>
            <li className={!isDeposit ? 'is-active' : ''}>
              <a href="#" onClick={ev => onSetDeposit(ev, false)}>Uttag</a>
            </li>
            <li className={isDeposit ? 'is-active' : ''}>
              <a href="#" onClick={ev => onSetDeposit(ev, true)}>Insättning</a>
            </li>
          </ul>
        </div>
        <DialogReceiptContentWrap>
          <Flex>
            <Flex>Just nu i kassan</Flex>
            <Flex right>{CurrencyUtil.accountCurrency(expectedCashAmount, 2)}</Flex>
          </Flex>
          <Flex>
            <Flex>Uttag/insättning</Flex>
            <Flex right>
              <Text>{changeAmount ? CurrencyUtil.accountCurrency(changeAmount, 2) : '-'}</Text>
            </Flex>
          </Flex>
          <Flex>
            <Flex><Text danger={remainingAmount < 0}>Nytt belopp i kassan</Text></Flex>
            <Flex right>
              <Text danger={remainingAmount < 0}>{CurrencyUtil.accountCurrency(remainingAmount, 2)}</Text>
            </Flex>
          </Flex>
        </DialogReceiptContentWrap>
        <Flex>
          <Flex>
            <Field type="number" name="cashAmount" component={TextInput} label="Belopp" placeholder="Belopp" autoFocus />
          </Flex>
        </Flex>
        <Flex>
          <div className="form-group">
            <label className="control-label">Kommentar</label>
            <Field component="textarea" name="comment" rows={2} className="form-control" placeholder="Anledning till uttag eller insättning" />
          </div>
        </Flex>
      </form>
    </ModalDialog>
  );
};

export default reduxForm({
  form: 'dialog-pos-deposit-withdraw'
})(DialogPosDepositWithdraw);
