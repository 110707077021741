import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { reduxForm, getFormValues } from 'redux-form';
import { getSysAdmin } from '@State/selectors';

import Flex from '@Components/ui/styled/flex-item';
import ModalDialog from '@Components/dialogs/modal-dialog';
import {
  DialogTitleRow, BtnBack, BodyWrap, DialogTitleBtn, DialogTitle
} from '@Components/dialogs/dialog-styles';
import CreatePosUnitType from './create-pos-unit-type';

const CreatePosOrgConfirm = ({
  handleSubmit, onSubmit, submitting, onHide, onPrev
}) => {
  const isSysAdmin = useSelector(getSysAdmin);
  const [termsAccepted, setTermsAccepted] = useState(!isSysAdmin);

  const {
    companyName = '',
    companyOrgNo = '',
    companyAddressLine1 = '',
    companyPostCode = '',
    companyArea = '',
    contactName = '',
    contactEmail = '',
    posAddressLine1 = '',
    posPostCode = '',
    posArea = '',
    vunitType
  } = useSelector(state => getFormValues('create-pos-org')(state));

  const renderAddress = () => {
    if (posAddressLine1) {
      return (
        <div>
          <p>{posAddressLine1}</p>
          <p>
            {posPostCode} {posArea}
          </p>
        </div>
      );
    }
    return (
      <div>
        <p>{companyAddressLine1}</p>
        <p>
          {companyPostCode} {companyArea}
        </p>
      </div>
    );
  };

  const buttonsAction = [{
    name: 'Skapa kassaregister',
    primary: true,
    type: 'submit',
    disabled: !termsAccepted,
    loading: submitting,
    onClick: handleSubmit(onSubmit)
  }];

  return (
    <ModalDialog
      contentSize="medium"
      onClose={onHide}
      buttons={buttonsAction}
    >
      <form onSubmit={handleSubmit} autoComplete="off" className="dialog">
        <DialogTitle>
          <BtnBack onClick={onPrev}><i className="fas fa-chevron-left" /></BtnBack>
          <DialogTitleRow leftBtn center>Bekräfta</DialogTitleRow>
          <DialogTitleBtn onClick={onHide}>Avbryt</DialogTitleBtn>
        </DialogTitle>
        <BodyWrap>
          <Flex>
            <div className="text-block f2">
              <span>Företag</span>
              <p>{companyName}</p>
              <p>
                {companyAddressLine1}
                <p>
                  {companyPostCode} {companyArea}
                </p>
              </p>
            </div>
            <div className="text-block">
              <span>Org. nummer</span>
              <p>{companyOrgNo}</p>
            </div>
          </Flex>
          <Flex>
            <div className="text-block">
              <span>
                Kontoägare
              </span>
              <p>{contactName}</p>
              <p>{contactEmail}</p>
            </div>
          </Flex>
          <Flex>
            <div className="text-block">
              <span>Adress där kassaregistret används</span>
              {renderAddress()}
            </div>
          </Flex>
        </BodyWrap>

        <CreatePosUnitType
          vunitType={vunitType}
          termsAccepted={termsAccepted}
          setTermsAccepted={() => setTermsAccepted(!termsAccepted)}
        />
      </form>
    </ModalDialog>
  );
};

export default reduxForm({
  form: 'create-pos-org',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(CreatePosOrgConfirm);
