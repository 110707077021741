import React from 'react';
import { useSelector } from 'react-redux';
import { getFeatures } from '@State/selectors';
import PosOrgAccountingFortnox from '@Components/admin/pos-config/pos-org-accounting-fortnox';
import PosOrgDeferredSalesToggle from './pos-org-deferred-sales-toggle';

const PosOrgAccounting = ({ posOrg }) => {
  const { EnableDeferredSales } = useSelector(getFeatures);

  return (
    <>
      {EnableDeferredSales && (
        <PosOrgDeferredSalesToggle />
      )}
      <PosOrgAccountingFortnox posOrg={posOrg} />
    </>
  );
};

export default PosOrgAccounting;
