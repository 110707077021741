import Handlebars from 'handlebars/dist/cjs/handlebars';

let _templates = null;

export function initTemplates(templates) {
  _templates = {};

  Object.keys(templates).forEach((key) => {
    _templates[key] = Handlebars.compile(templates[key]);
  });
}

export function renderTemplate(templateId, props) {
  const template = _templates && _templates[templateId];

  if (template && typeof template === 'function') {
    return template(props);
  }
  return null;
}
