import React, { PureComponent } from 'react';
import Button from '@Components/ui/button';
import { classes } from '@Components/ui/utils';
import '@Components/ui/select/select-drop-down-styles.less';

const MARGIN = 0;

class SelectDropDown extends PureComponent {
  state = { opened: false };

  componentDidMount() {
    window.addEventListener('click', this.windowClickHandler);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.windowClickHandler);
  }

  clickHandler = () => {
    const { disabled, manual } = this.props;
    if (disabled || manual) return;
    this.toggleOpenState();
  };

  windowClickHandler = (event) => {
    const { disabled } = this.props;
    const { opened } = this.state;
    if (disabled || !opened) return;
    const { button, dropdown } = this.refs;
    let parent = event.target;
    while (parent) {
      if (parent.isSameNode(dropdown) || parent.isSameNode(button)) return;
      parent = parent.parentNode;
    }
    this.toggleOpenState(false);
  };

  toggleOpenState(opened = !this.state.opened) {
    const { inline = true } = this.props;
    const { button } = this.refs;
    const { innerHeight: windowHeight } = window;
    this.setState({ opened }, () => {
      if (inline || !opened) return;
      const {
        top,
        bottom,
        height
      } = button.getBoundingClientRect();

      const onTop = top > (windowHeight + height) / 2;
      this.setState({
        dropDownStyle: {
          zIndex: 1000,
          left: 0,
          top: 0,
          bottom: onTop && windowHeight - top,
          maxHeight: (onTop ? top : windowHeight - bottom) - MARGIN
        }
      });
    });
  }

  render() {
    const {
      children,
      label,
      buttonContent,
      className,
      inline = true,
      button,
      name,
      disabled,
      closeDelay,
      ...rest
    } = this.props;
    const { opened, dropDownStyle } = this.state;
    const classList = classes({
      'ui-dropdown': true,
      'ui-dropdown-opened': opened,
      [className]: className,
      disabled
    });

    const dropDown = children ? (
      <div className={name ? `${name}_dropdown` : ''}>
        <div
          className="ui-dropdown-content"
          ref="dropdown"
          style={inline ? null : dropDownStyle}
        >
          {children}
        </div>
      </div>
    ) : null;

    return (
      <div className={classList} {...rest}>
        <div className="ui-dropdown-button-container" ref="button">
          <div onClick={this.clickHandler}>
            {button ? (
              React.cloneElement(button, { disabled })
            ) : (
              <Button className="ui-dropdown-button" focus={opened} disabled={disabled}>
                <div className="ui-ellipsis">{buttonContent}</div>
                <i className="fa fa-chevron-down" />
              </Button>
            )}
          </div>
          {dropDown && opened && dropDown}
        </div>
      </div>
    );
  }
}

export default SelectDropDown;
