import { useMemo } from 'react';

function deletedGroup(id) {
  return {
    id,
    name: 'Borttagen grupp'
  };
}

export default (productGroups, items) => {
  return useMemo(() => {
    if (!productGroups || !items) {
      return [];
    }

    const groupIds = items.map(i => i.get('groupId')).toSet();

    return [
      // Filter out groups that are not in the articles
      ...productGroups.filter(g => groupIds.includes(g.get('id'))).map(g => g.toJS()),

      // Add groups that are in the articles but not in the product groups
      ...groupIds.filter(id => !productGroups.some(g => g.get('id') === id)).map(id => deletedGroup(id))
    ];
  }, [productGroups, items]);
};
