import React, { Component } from 'react';
import { connect } from 'react-redux';
import Popover from '@Components/ui/popover';
import { createStockReport } from '@State/report-actions';
import { getSectionUrl, navigate } from '@Utils/navigate';
import AddItemForm from '@Components/sortable-list/add-item-form';
import { getStockReportDefaultLabel } from '../reports-helpers';

class StockReportToolbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addStockReport: false
    };
  }

  render() {
    return (
      <div className="columns-toolbar">
        {this.renderAddStockReportButton()}
      </div>
    );
  }

  renderAddStockReportButton() {
    return (
      <Popover
        isOpen={this.state.addStockReport}
        body={this.addStockReportContent()}
        preferPlace="below"
        className="Popover-big-radius"
        onOuterAction={this.handleCloseStockReportPopover}
      >
        <button type="button" className="btn btn-cta" onClick={this.handleOpenStockReportPopover}>
          Skapa rapport
        </button>
      </Popover>
    );
  }

  handleOpenStockReportPopover = () => {
    this.setState({ addStockReport: true });
  };

  handleCloseStockReportPopover = () => {
    this.setState({ addStockReport: false });
  };

  handleAddStockReport = (group, label) => {
    return this.props
      .onAddStockReport({ label })
      .then(() => this.handleCloseStockReportPopover());
  };

  addStockReportContent() {
    return (
      <AddItemForm
        onClosePopover={this.handleCloseStockReportPopover}
        onAddItem={this.handleAddStockReport}
        placeholder="Ange namn på rapporten"
        name={getStockReportDefaultLabel()}
        infoText="Skapa lagerrapport från nuvarande lagersaldo"
        submitText="Skapa"
      />
    );
  }
}

const mapDispatchToProps = (dispatch, { routeParams }) => {
  return {
    onAddStockReport: (report) => {
      return dispatch(createStockReport(report))
        .then(id => navigate(getSectionUrl(routeParams, 'reports/stock', id)));
    }
  };
};

export default connect(null, mapDispatchToProps)(StockReportToolbar);
