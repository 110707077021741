import React from 'react';
import moment from 'moment';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { getSectionUrl, navigate } from '@Utils/navigate';
import { getAvailableReports, getDateTypeDescription, ReportingDate, reportsQuerySelector } from '@Components/reports/reports-helpers';
import { getRouteParams } from '@State/selectors';
import FilterContainer from '@Components/ui/filter-container';
import DateRangePicker from '@Components/reports/date-range-picker';
import { DATE_PICKER_FORMAT } from '@Utils/time-constants';
import { getAdminResourceGroups } from '@Utils/filter-selectors';
import DatePickerCalendarInput from '@Components/ui/date-picker/date-picker-calendar-input';

const ReportsFilter = (props) => {
  const availableReports = useSelector(getAvailableReports);
  const { phoneMode } = useSelector(state => state.mainViewState);
  const resourcesById = useSelector(state => state.resourcesById);
  const filteredGroups = useSelector(getAdminResourceGroups);
  const routeParams = useSelector(state => getRouteParams(state, props));
  const posOrgs = useSelector(state => state.posOrgs);
  const cashiersById = useSelector(state => state.cashiersById);
  const { subsection, id } = props.match.params;

  const currentReport = availableReports.find(report => report.id === routeParams.subsection);
  const { resource, group, user, posOrg, dateType, startDate, endDate } = useSelector(state => reportsQuerySelector(state, props));

  const getNavigateUrl = (updateQuery) => {
    const baseUrl = getSectionUrl(routeParams, `reports/${subsection}`, id);
    const currentQuery = queryString.parse(props.location.search);
    const query = queryString.stringify({ ...currentQuery, ...updateQuery });

    return `${baseUrl}?${query}`;
  };

  const getDateTypeOption = (type) => (
    <option value={getNavigateUrl({ dateType: type })} selected={dateType === type}>
      {getDateTypeDescription(type)}
    </option>
  );

  const handleDatesChange = ({ startDate, endDate }) => {
    const query = {
      startDate: startDate.format(DATE_PICKER_FORMAT),
      endDate: endDate.format(DATE_PICKER_FORMAT)
    };
    navigate(getNavigateUrl(query));
  };

  const handleDateChange = (date) => {
    const query = {
      startDate: moment(date).format(DATE_PICKER_FORMAT)
    };
    navigate(getNavigateUrl(query));
  };

  const handleSelectChange = (ev) => {
    navigate(ev.target.value);
  };

  const currentTab = currentReport?.tabs?.find(t => t.navId === id || !t.navId && !id);
  const report = { ...currentReport, ...currentTab };
  const {
    hideFilter, singleDate, showDateType, showResources, showAll,
    showGroups, showPosOrgs, showPosUsers
  } = report;

  if (hideFilter) {
    return null;
  }

  if (showResources && !showGroups && !showAll && !resource) {
    const first = resourcesById.valueSeq().sortBy(r => r.name).first();
    if (first) {
      return (
        <Redirect to={getNavigateUrl({ resourceId: first.id, groupId: undefined })} />
      );
    }
  }

  return (
    <FilterContainer>
      {singleDate ? (
        <div className="form-group">
          <label>Datum</label>
          <DatePickerCalendarInput
            date={startDate}
            onChange={handleDateChange}
          />
        </div>
      ) : (
        <div className="form-group">
          <label>Datumintervall</label>
          <DateRangePicker
            phoneMode={phoneMode}
            startDate={startDate}
            endDate={endDate}
            onDatesChange={handleDatesChange}
          />
        </div>
      )}
      {showResources && (
        <div className="form-group">
          <label>Resurs</label>
          <div className="select-container">
            <select className="form-control" onChange={handleSelectChange}>
              {showAll && (
                <option
                  value={getNavigateUrl({ groupId: undefined, resourceId: undefined })}
                  selected={!resource && !group}
                >
                  Alla
                </option>
              )}
              {showGroups && (
                <optgroup label="Grupp">
                  {filteredGroups.sortBy(g => g.get('name')).map((item) => {
                    const { id, name } = item.toObject();
                    const url = getNavigateUrl({ groupId: id, resourceId: undefined });
                    return <option key={item.get('id')} value={url} selected={group?.id === id}>{name}</option>;
                  })}
                </optgroup>
              )}
              <optgroup label="Resurs">
                {resourcesById.valueSeq().sortBy(r => r.name).map(({ id, name }) => {
                  const url = getNavigateUrl({ resourceId: id, groupId: undefined });
                  return <option key={id} value={url} selected={resource?.id === id}>{name}</option>;
                })}
              </optgroup>
            </select>
          </div>
        </div>
      )}
      {showDateType && (
        <div className="form-group">
          <label>Rapportdatum</label>
          <div className="select-container">
            <select className="form-control" onChange={handleSelectChange}>
              {getDateTypeOption(ReportingDate.Created)}
              {getDateTypeOption(ReportingDate.Start)}
            </select>
          </div>
        </div>
      )}
      {showPosOrgs && (
        <div className="form-group">
          <label>Företag</label>
          <div className="select-container">
            <select className="form-control" onChange={handleSelectChange}>
              {posOrgs.sortBy(o => o.get('companyName')).map((item) => {
                const { id, companyName } = item.toObject();
                const url = getNavigateUrl({ posOrgId: id });
                return <option key={id} value={url} selected={posOrg?.get('id') === id}>{companyName}</option>;
              })}
            </select>
          </div>
        </div>
      )}
      {showPosUsers && (
        <div className="form-group">
          <label>Användare</label>
          <div className="select-container">
            <select className="form-control" onChange={handleSelectChange}>
              <option value={getNavigateUrl({ userId: undefined })} selected={!user}>
                Alla användare
              </option>
              {cashiersById.valueSeq().sortBy(u => u.get('name')).map((item) => {
                const { id, name } = item.toObject();
                const url = getNavigateUrl({ userId: id });
                return <option key={id} value={url} selected={user?.get('id') === id}>{name}</option>;
              })}
            </select>
          </div>
        </div>
      )}
    </FilterContainer>
  );
};

export default ReportsFilter;
