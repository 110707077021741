import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useIsMounted from '@Hooks/useIsMounted';

export default (actions, actionsDepends, depends, callbacks) => {
  const dispatch = useDispatch();
  const isMounted = useIsMounted();

  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const result = await dispatch(actions(...actionsDepends));
        callbacks && callbacks();
        if (isMounted()) {
          setData(result);
        }
      } catch (error) {
        if (isMounted()) {
          setIsError(true);
        }
      }
      if (isMounted()) {
        setIsLoading(false);
      }
    };

    fetchData();
  }, depends);
  return {
    data, isLoading, isError, setIsLoading
  };
};
