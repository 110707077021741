import React from 'react';
import groupBy from 'lodash/groupBy';
import { channelText, getBookingReportColumns } from '@Components/reports/reports-helpers';

const BookingReportChannels = ({ bookings }) => {
  const bookingsByChannel = groupBy(bookings, 'channel');
  const channels = Object.keys(bookingsByChannel).sort();
  const totalColumns = getBookingReportColumns(bookings);

  return (
    <table className="table table-condensed table-report">
      <thead>
        <tr>
          <th className="col-xs-3">Källa</th>
          <th className="text-right">Antal</th>
          <th className="text-right">Show</th>
          <th className="text-right">No show</th>
          <th className="text-right">Avbokade</th>
          <th className="text-right">Värde</th>
          <th className="text-right">Uteblivet <sup>1</sup></th>
          <th className="text-right">Netto</th>
        </tr>
      </thead>
      <tbody>
        {channels.map((key) => {
          const columns = getBookingReportColumns(bookingsByChannel[key]);
          return (
            <tr key={key}>
              <td className="child">{channelText(key)}</td>
              <td className="text-right">{columns.totalCount}</td>
              <td className="text-right">{columns.showCount}</td>
              <td className="text-right">{columns.noShowCount}</td>
              <td className="text-right">{columns.cancelledCount}</td>
              <td className="text-right">{columns.totalRevenue}</td>
              <td className="text-right">{columns.noRevenue}</td>
              <td className="text-right">{columns.revenue}</td>
            </tr>
          );
        })}
      </tbody>
      <tfoot>
        <tr>
          <th>Summa</th>
          <th className="text-right">{totalColumns.totalCount}</th>
          <th className="text-right">{totalColumns.showCount}</th>
          <th className="text-right">{totalColumns.noShowCount}</th>
          <th className="text-right">{totalColumns.cancelledCount}</th>
          <th className="text-right">{totalColumns.totalRevenue}</th>
          <th className="text-right">{totalColumns.noRevenue}</th>
          <th className="text-right">{totalColumns.revenue}</th>
        </tr>
      </tfoot>
    </table>
  );
};

export default BookingReportChannels;
