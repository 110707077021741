import React, { useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';

import { config } from '@Utils/preference-keys';
import { mergeOrgPrefs } from '@State/sysadmin-actions';

import AccountOverviewContext from '../account-overview/account-overview-context';
import AccountOverviewBlock from '../account-overview/account-overview-block';

const AccountPrefsGeneral = () => {
  const dispatch = useDispatch();
  const { id, subscription } = useContext(AccountOverviewContext);

  const onChangeOrgWideCustomerDb = useCallback((e) => {
    dispatch(mergeOrgPrefs(id, { [config.orgWideCustomerDb]: e.target.checked }));
  }, [id]);

  const prefsFields = [
    [
      {
        prop: 'orgWideCustomerDb',
        title: 'Delat kundregister',
        tooltip: 'Dela kunder och historik mellan samtliga konton i organisationen',
        handleToggle: onChangeOrgWideCustomerDb
      }
    ]
  ];

  return (
    <AccountOverviewBlock
      title="Allmänt"
      columnsQuan={3}
      columns={prefsFields}
      data={subscription.get('prefs')}
    />
  );
};

export default AccountPrefsGeneral;
