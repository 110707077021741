import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEmbeddedInApp } from '@Utils/embedded-util';
import { toggleScheduleEditMode } from '@State/view-actions';
import { txt } from '@Utils/i18n-util';
import msg from './schedule-edit-banner.msg';

class ScheduleEditBanner extends Component {
  static propTypes = {
    scheduleEditMode: PropTypes.bool.isRequired,
    phoneMode: PropTypes.bool.isRequired,
    toggleScheduleEditMode: PropTypes.func.isRequired
  };

  componentDidUpdate(prevProps) {
    if (prevProps.scheduleEditMode !== this.props.scheduleEditMode) {
      this.props.updateViewDimensions();
    }
  }

  render() {
    const { scheduleEditMode, phoneMode } = this.props;

    const style = {
      fontSize: phoneMode ? '0.8em' : '1.0em'
    };

    const text = phoneMode ? txt(msg.compactText) : txt(msg.text);

    return (
      scheduleEditMode
        ? (
          <div id="gridbanner">
            {phoneMode ? <span className="text-muted" style={style}>{text}</span> : (
              <span style={style}>
                {text}
              </span>
            )}

            {phoneMode ? '' : <button className="btn-save" onClick={this.toggleScheduleEditMode}>{txt(msg.doneBtnLabel)}</button>}
          </div>
        ) : <div />
    );
  }

  toggleScheduleEditMode = (ev) => {
    this.props.toggleScheduleEditMode(false);
  };
}

const mapStateToProps = (state) => {
  const embeddedInApp = isEmbeddedInApp(state);
  return {
    scheduleEditMode: !embeddedInApp && state.gridViewState.get('scheduleEditMode'),
    phoneMode: state.mainViewState.get('phoneMode')
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleScheduleEditMode: (state) => {
      dispatch(toggleScheduleEditMode(state));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleEditBanner);
