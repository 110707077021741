import { notification } from './preference-keys';

export function getNotificationDescription(key) {
  switch (key) {
    case notification.email.bookingCreateCal:
    case notification.push.bookingCreateCal:
      return 'Bokning skapad i kalendern';

    case notification.email.bookingCancelCal:
    case notification.push.bookingCancelCal:
      return 'Avbokning i kalendern';

    case notification.email.bookingConfirmWeb:
    case notification.push.bookingConfirmWeb:
      return 'Bokning online av kund';

    case notification.email.bookingCancelWeb:
    case notification.push.bookingCancelWeb:
      return 'Avbokning online av kund';

    case notification.email.bookingReviewPosted:
    case notification.push.bookingReviewPosted:
      return 'Omdöme postat av kund';

    default:
      return key;
  }
}

const bookingEmailPrefsWeb = {
  [notification.email.bookingConfirmWeb]: true,
  [notification.email.bookingCancelWeb]: true
};

const bookingEmailPrefsAll = {
  ...bookingEmailPrefsWeb,
  [notification.email.bookingCreateCal]: true,
  [notification.email.bookingCancelCal]: true
};

function getPrefsForOption(option) {
  switch (option) {
    case 'All':
      return bookingEmailPrefsAll;
    case 'Web':
      return bookingEmailPrefsWeb;
    default:
      return null;
  }
}

function getOptionForPrefs(prefs) {
  if (prefs && prefs[notification.email.bookingCreateCal]) {
    return 'All';
  }
  if (prefs && prefs[notification.email.bookingConfirmWeb]) {
    return 'Web';
  }
  return 'None';
}

export function notificationPrefsToForm(json) {
  return {
    email: json.email,
    bookingEmailPreference: getOptionForPrefs(json.prefs)
  };
}

export function notificationFormToPrefs(values) {
  return {
    email: values.email,
    prefs: getPrefsForOption(values.bookingEmailPreference)
  };
}
