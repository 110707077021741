import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import FormButtonsFooter from '@Components/ui/form-buttons-footer';
import { normalizeNumber } from '@Utils/format';

const AccountCreditAmountForm = ({ onClose, onSubmit, loading, initialValues = {} }) => {
  const { register, handleSubmit } = useForm({ defaultValues: initialValues });
  const [sumAmount, setSumAmount] = useState(initialValues.creditAmount);
  const [amountInput, setAmountInput] = useState(0);

  const onHandleSubmit = () => onSubmit({ amount: sumAmount });

  useEffect(() => {
    setSumAmount(initialValues.creditAmount);
  }, [initialValues]);

  const onChangeAmount = (e) => {
    const value = e.target.value.replace('e', '');
    if (value === '' || value === '-') {
      setAmountInput(value);
      setSumAmount(initialValues.creditAmount);
      return false;
    }
    const newValue = normalizeNumber(value);
    setAmountInput(newValue);
    setSumAmount(initialValues.creditAmount + newValue);
  };

  const amountRegister = { ...register('amount', { value: amountInput, valueAsNumber: true }) };

  return (
    <form onSubmit={handleSubmit(onHandleSubmit)}>
      <div className="form-group">
        <div className="form-control-container">
          <p>
            <b>Current credit amount:</b> {initialValues.creditAmount}
          </p>
          <p>
            <b>Sum credit amount:</b> {sumAmount && +sumAmount.toFixed(2)}
          </p>
          <label className="control-label" htmlFor="creditAmount">
            Add amount
          </label>
          <input
            className="form-control"
            {...amountRegister}
            onChange={(e) => {
              onChangeAmount(e);
              amountRegister.onChange(e);
            }}
          />
        </div>
      </div>
      <FormButtonsFooter
        submitText={initialValues.creditAmount === 0 ? `Set credit amount ( ${sumAmount} )` : 'Set credit amount'}
        onClose={onClose}
        handleSubmit={handleSubmit}
        submitSucceeded={false}
        submitting={loading}
      />
    </form>
  );
};

export default AccountCreditAmountForm;
