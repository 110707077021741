import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { refreshInvoiceStatus } from '@State/invoice-actions';
import useIsMounted from '@Hooks/useIsMounted';
import { classes } from '@Components/ui/utils';

const PosInvoiceUpdateStatus = ({ posOrgId, invoiceId, className = null }) => {
  const dispatch = useDispatch();
  const isMounted = useIsMounted();

  const [updating, setUpdating] = useState(false);
  const [done, setDone] = useState(false);

  const handleInvoiceStatus = (ev) => {
    ev.target.blur();
    ev.preventDefault();
    ev.stopPropagation();
    setUpdating(true);
    Promise.all([
      dispatch(refreshInvoiceStatus(posOrgId, invoiceId))
    ]).then(() => {
      if (isMounted()) {
        setDone(true);
        setUpdating(false);
        setTimeout(() => {
          if (isMounted()) {
            setDone(false);
          }
        }, 1500);
      }
    });
  };

  const iconClass = classes({
    fa: true,
    'fa-refresh': !done,
    'fa-check text-success': done,
    'fa-spin': updating
  });

  const linkClass = classes({
    'text-muted': true,
    disabled: updating
  });

  return (
    <span className={className}>
      <a href="#" className={linkClass} onClick={handleInvoiceStatus}>
        <i className={iconClass} />
      </a>
    </span>
  );
};

export default PosInvoiceUpdateStatus;
