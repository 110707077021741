import { defineMessages } from 'react-intl';

export default defineMessages({
  text: {
    id: 'calendar.multiResourceBanner.text',
    defaultMessage: 'Showing combined week view of multiple resources'
  },
  compactText: {
    id: 'calendar.multiResourceBanner.compactText',
    defaultMessage: 'Combined week view, multiple resources'
  }
});
