import { connect, useDispatch, useSelector } from 'react-redux';
import React, { useState } from 'react';
import { config } from '@Utils/preference-keys';
import { webBookingUrl, widgetBaseUrl } from '@Utils/config';
import { setLocationSlug, toggleSearchEngine } from '@State/preferences-actions';
import { setLocationFeature } from '@State/features-actions';
import Button from '@Components/ui/button';
import Panel from '@Components/ui/styled/panel';
import LabelSwitchInput from '@Components/ui/label-switch-input';
import SetSlugModal from './set-slug-modal';

const WebBookingInfo = ({ slug, stableId }) => {
  const dispatch = useDispatch();
  const [showSlug, setShowSlug] = useState(false);

  const enableMicrosite = useSelector(state => state.locationFeatures.get('EnableMicrosite'));
  const enableReviews = useSelector(state => state.locationFeatures.get('EnableReviews'));
  const searchEngineEnabled = useSelector(state => state.locationConfig.get(config.searchEngineEnabled));

  const bookingUrl = `${webBookingUrl()}${slug}/`;
  const widgetUrl = `${widgetBaseUrl()}cliento.js`;
  const docsUrl = 'https://developers.cliento.com/docs/widget/';

  const toggleMicrosite = () => {
    if (enableMicrosite && enableReviews) {
      toggleReviews();
    }
    return dispatch(setLocationFeature('EnableMicrosite', !enableMicrosite));
  };

  const togglePublished = () => {
    return dispatch(toggleSearchEngine(!searchEngineEnabled));
  };

  const toggleReviews = () => {
    return dispatch(setLocationFeature('EnableReviews', !enableReviews));
  };

  return (
    <div className="subnav-container">
      <h3 className="mb3">Onlinebokning</h3>
      <div className="row">
        <div className="col-xs-7">
          <Panel>
            <LabelSwitchInput
              large
              noBorder
              label="Bokningssida på cliento.com"
              isOn={enableMicrosite}
              id="enableMicrosite"
              handleToggle={toggleMicrosite}
              description="Skapa en egen hemsida hos Cliento med bokning, omdömen, öppettider m.m. Sidan rankar högt på Google och hjälper dig hitta nya kunder."
              confirmDisable="Vill du verkligen stänga av bokningssidan?"
            />
          </Panel>
          <Panel disabled={!enableMicrosite}>
            <LabelSwitchInput
              noBorder
              label="Visa bokningssida i sökmotorer"
              isOn={searchEngineEnabled}
              id="searchEngineEnabled"
              handleToggle={togglePublished}
              description="Din bokningssida visas och rankar högt på Google och andra sökmotorer. Det kan ta några dagar innan ändringar syns i sökresultaten."
            />
            <br />
            <LabelSwitchInput
              news
              noBorder
              label="Ta emot och visa omdömen"
              isOn={enableReviews}
              id="enableReviews"
              handleToggle={toggleReviews}
              description={(
                <div>
                  Låt dina kunder lämna ett omdöme efter besöket. Omdömen och totalt betyg visas på bokningssidan samt i sökresultat.<br /><br />
                  <i className="far fa-exclamation-circle mr1" />
                  För att omdömesförfrågan ska skickas behöver bokningen markeras som "Show".
                </div>
              )}
            />
          </Panel>
          <Panel disabled={!enableMicrosite}>
            <h5 className="mt1">Länk för knapp på hemsida, Instagram eller Facebook-sida</h5>
            <div className="form-group">
              <pre>{bookingUrl}</pre>
            </div>
            <Button small white marginRight onClick={() => setShowSlug(true)}>
              <i className="fa fa-pen-to-square mr1" />
              Ändra adress
            </Button>
            <Button small white marginRight onClick={() => window.open(bookingUrl)}>
              <i className="fa fa-arrow-up-right-from-square mr1" />
              Öppna bokningssida
            </Button>
            {showSlug && (
              <SetSlugModal onClose={() => setShowSlug(false)} slug={slug} />
            )}
          </Panel>
        </div>
        <div className="col-xs-5">
          <img src="/booking-microsite.png" className="web-booking-image" alt="Bokningssida på cliento.com" />
        </div>
      </div>
      <hr className="mt2 mb4" />
      <div className="row">
        <div className="col-xs-7">
          <Panel>
            <h5 className="mt1 mb0">Widget: Bädda in bokning på din hemsida</h5>
            <p className="mt1">
              Lägg in bokningen på din egen hemsida för att få full kontroll över utseende m.m.
            </p>
            <hr className="mt3 mb3" />
            <p className="text-muted">Kopiera denna kod och klistra in på er hemsida där bokningen ska visas</p>
            <pre>
              {'<script>'}
              <br />
              {'(function(c,l,i,e,n,t,o){if(c.cbk){return;}c.cbk=function(){'}
              <br />
              {'c.cbk.p.push(arguments);};c.cbk.p=[];e=l.createElement("script");e.async=1;'}
              <br />
              e.src=i;l.head.appendChild(e);n=l.createElement("div");n.id="cliento-booking";
              <br />
              t=l.getElementsByTagName("script");o=t[t.length-1];o.parentNode.insertBefore(n,o);
              <br />
              {`})(window,document,"${widgetUrl}");`}
              <br />
              {`cbk("id", "${stableId}");`}
              <br />
              {'</script>'}
            </pre>
            <Button small white onClick={() => window.open(docsUrl)}>
              <i className="fa fa-arrow-up-right-from-square mr1" />
              Dokumentation för utvecklare
            </Button>
          </Panel>
        </div>
        <div className="col-xs-5">
          <img src="/booking-widget.png" className="web-booking-image" alt="Bädda in bokning på din hemsida" />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { locationConfig, locationFeatures } = state;

  return {
    locationFeatures,
    slug: locationConfig.get(config.slug),
    stableId: locationConfig.get(config.stableId)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLocationSlug: (slug) => {
      return dispatch(setLocationSlug(slug));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WebBookingInfo);
