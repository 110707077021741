import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { colors } from '@Components/ui/styled/variables';
import { getOrgLocCampaignUrl, navigate } from '@Utils/navigate';
import ColumnListItem from '@Components/ui/column/column-list-item';
import Label from '@Components/ui/label';
import {
  getCampaignStatusIcon, getCampaignStatusLabel, getCampaignTitle,
  getCampaignTypeName
} from './campaign-helpers';

export const CampaignList = ({ routeParams }) => {
  const campaigns = useSelector(state => state.campaignViewState.get('campaigns'));
  const { id: routeId } = useParams();

  const onSelect = useCallback((listId) => {
    if (+routeId !== +listId) {
      navigate(`${getOrgLocCampaignUrl(routeParams)}/${listId}`);
    }
  }, [routeId, routeParams]);

  useEffect(() => {
    const noSelected = !routeId && campaigns;
    const selectedNotExisting = routeId && campaigns && !campaigns.find(c => c.get('id') === +routeId);

    if (noSelected || selectedNotExisting) {
      const firstId = campaigns.first()?.get('id');
      if (firstId && firstId !== +routeId) {
        navigate(`${getOrgLocCampaignUrl(routeParams)}/${firstId}`);
      }
    }
  }, [routeId, campaigns]);

  return (
    <>
      <div className="columns-header">
        <h1>Tidigare utskick</h1>
      </div>
      <div className="columns-list">
        <br />
        {campaigns && !campaigns.isEmpty() ? campaigns.toJS().map(
          (campaign) => {
            const { id, name, deliveryTime, type, status } = campaign;
            const isDraft = status === 'DRAFT';
            const isProcessing = status === 'PROCESSING';
            const iconClass = getCampaignStatusIcon(status);

            return (
              <ColumnListItem
                key={id}
                onClick={() => onSelect(id)}
                selected={+routeId === +id}
                header={name || getCampaignTitle(campaign, 30)}
                subHeader={(
                  <Label bgColor={type === 'Sms' ? colors.bgSuccess : colors.labelTrial}>
                    {getCampaignTypeName(type)}
                  </Label>
                )}
              >
                {!isDraft ? (
                  <>
                    <i className={iconClass} />
                    {getCampaignStatusLabel(status)}{' '}
                    {!isProcessing && moment(deliveryTime).format('LL')}
                  </>
                ) : <i>Utkast</i>}
              </ColumnListItem>
            );
          }
        ) : (
          <div className="columns-list-help">
            <em>Det finns inga tidigare utskick</em>
          </div>
        )}
      </div>
    </>
  );
};
