import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';
import { CUSTOMER_SEARCH_DEBOUNCE } from '@Utils/constants';
import { searchInvoiceCustomers } from '@State/invoice-actions';

class InvoiceCustomerSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      suggestions: []
    };

    this.debounceSearchCustomers = debounce(this.searchCustomers, CUSTOMER_SEARCH_DEBOUNCE);
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue
    });
  };

  getSuggestionValue = suggestion => suggestion.customerName;

  renderSuggestion = ({
    customerName, orgOrPno, fortnoxCustomerNumber, address1, area
  }) => (
    <div className="form-suggestion">
      <div>
        {fortnoxCustomerNumber && (
          <div className="pull-right">
            {fortnoxCustomerNumber}
            <img src="/fortnox-icon.png" className="invoice-provider right" />
          </div>
        )}
        <strong>{customerName}</strong>, {orgOrPno}
      </div>
      <div>
        {address1}, {area}
      </div>
    </div>
  );

  searchCustomers = (value) => {
    return this.props.searchInvoiceCustomers(this.props.posOrgId, value)
      .then((customers) => this.setState({ suggestions: customers }));
  }

  onSuggestionsFetchRequested = ({ value }) => {
    return this.debounceSearchCustomers(value);
  };

  onSuggestionsClearRequested = () => {
    this.setState({ suggestions: [] });
  };

  onSuggestionSelected = (event, { suggestion }) => {
    this.props.onCustomerSelected(suggestion);
    this.setState({ value: '' });
  };

  renderInputComponent = (inputProps) => (
    <input type="text" {...inputProps} />
  );

  render() {
    const { value, suggestions } = this.state;

    const inputProps = {
      value,
      autoFocus: true,
      placeholder: 'Sök mottagare...',
      className: 'form-control',
      onChange: this.onChange
    };

    return (
      <Autosuggest
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        onSuggestionSelected={this.onSuggestionSelected}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        focusInputOnSuggestionClick={false}
        suggestions={suggestions}
        inputProps={inputProps}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    searchInvoiceCustomers: (posOrgId, value) => {
      return dispatch(searchInvoiceCustomers(posOrgId, value));
    }
  };
};

export default connect(null, mapDispatchToProps)(InvoiceCustomerSearch);
