import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { debounce } from 'lodash';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import { createValidator, required, trimString } from '@Utils/validation';
import TextInput from '@Components/inputs/text-input';
import ModalDialog from '@Components/dialogs/modal-dialog';
import { Button } from '@Components/ui/styled/main';
import { generateGiftCardCode } from '@State/gift-card-actions';

const formName = 'gift-card-enter-code';
const selector = formValueSelector(formName);

export const GenerateContainer = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 100;
`;

const GiftCardEnterCode = ({
  title, initialValues, validation, onValidate, handleSubmit, submitting, invalid, onClose, onSubmit
}) => {
  const dispatch = useDispatch();
  const debounceValidateCode = useCallback(debounce(onValidate, 200), [onValidate]);
  const voucherCodeValid = validation && validation.available;
  const formVoucherCode = useState(state => selector(state, 'voucherCode'));
  const voucherCodeMeta = {
    touched: formVoucherCode && formVoucherCode.length > 0,
    error: validation && !validation.available ? 'Koden är redan använd' : undefined
  };
  const submitDisabled = invalid || !voucherCodeValid;

  const handleGenerateCode = () => {
    return dispatch(generateGiftCardCode())
      .then(({ voucherCode }) => {
        dispatch(change(formName, 'voucherCode', voucherCode));
        onValidate(voucherCode);
      });
  };

  const handleCodeChange = (ev) => {
    debounceValidateCode(trimString(ev.target.value));
  };

  const onEnter = (values) => {
    if (!submitDisabled) {
      return onSubmit(values);
    }
  };

  useEffect(() => {
    if (!validation && initialValues && initialValues.voucherCode) {
      onValidate(initialValues.voucherCode);
    }
  }, [validation, initialValues]);

  return (
    <ModalDialog
      onClose={onClose}
      title={title}
      hideCloseButton
      buttons={[{
        name: 'Fortsätt',
        primary: true,
        onClick: handleSubmit,
        loading: submitting,
        disabled: submitDisabled
      }, {
        name: 'Avbryt',
        gray: true,
        onClick: onClose
      }]}
    >
      <form onSubmit={handleSubmit(onEnter)} autoComplete="off" className="dialog">
        <GenerateContainer>
          <button type="button" className="btn-input" onClick={handleGenerateCode}>Generera</button>
        </GenerateContainer>
        <Field
          type="text"
          name="voucherCode"
          component={TextInput}
          placeholder="Scanna eller skriv in kod"
          normalize={trimString}
          onChange={handleCodeChange}
          meta={voucherCodeMeta}
          autoFocus
        />
      </form>
    </ModalDialog>
  );
};

const validator = createValidator({
  voucherCode: [required]
});

export default reduxForm({
  form: formName,
  validate: validator
})(GiftCardEnterCode);
