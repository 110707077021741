import React from 'react';
import { classes } from '@Components/ui/utils';

const ProductListItemNew = ({ onClick, isDisabled, text }) => {
  const classList = classes({
    pos__list__products__new: true,
    disabled: isDisabled
  });

  return (
    <button
      disabled={isDisabled}
      className={classList}
      onClick={!isDisabled ? onClick : null}
    >
      <div>
        <i className="fa fa-plus" />
        {text}
      </div>
    </button>
  );
};

export default ProductListItemNew;
