import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { RingLoader } from 'halogenium';

export default class SubmitNotice extends Component {
  static propTypes = {
    submitting: PropTypes.bool.isRequired,
    submitSucceeded: PropTypes.bool.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      saved: false
    };

    this.timer = null;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.timer && this.props.submitting) {
      clearTimeout(this.timer);
    }

    if (
      this.props.submitting
      && !nextProps.submitting
      && nextProps.submitSucceeded
    ) {
      this.setState({ saved: true }, this.reset);
    }
  }

  reset = () => {
    this.timer = setTimeout(() => {
      this.setState({ saved: false });
    }, 3000);
  };

  render() {
    const { saved } = this.state;
    const { submitting } = this.props;
    const baseContentClass = 'notice';
    let noticeClass;
    if (submitting) {
      noticeClass = `${baseContentClass} ${baseContentClass}--info`;
    } else if (!submitting && saved) {
      noticeClass = `${baseContentClass} ${baseContentClass}--success`;
    } else {
      noticeClass = baseContentClass;
    }

    return (
      <div className={noticeClass}>
        <div className="notice__content saving">
          <RingLoader color="#fff" size="15px" /> Sparar
        </div>
        <div className="notice__content saved">
          <i className="fa fa-check" /> Sparat
        </div>
      </div>
    );
  }
}
