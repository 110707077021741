import { defineMessages } from 'react-intl';

export default defineMessages({
  overviewOrgName: {
    id: 'sysadmin.overview.overviewOrgName',
    defaultMessage: 'Företagsnamn'
  },
  overviewCompanyRegNo: {
    id: 'sysadmin.overview.overviewCompanyRegNo',
    defaultMessage: 'Organisationsnummer'
  },
  overviewTrialDays: {
    id: 'sysadmin.overview.overviewTrialDays',
    defaultMessage: 'Prövoperiod'
  },
  overviewTrialEnd: {
    id: 'sysadmin.overview.overviewTrialEnd',
    defaultMessage: 'Pröva på t.om:'
  },
  overviewSmsBillingEnabled: {
    id: 'sysadmin.overview.smsBillingEnabled',
    defaultMessage: 'SMS billing enabled'
  },
  overviewAllowSms: {
    id: 'sysadmin.overview.allowSms',
    defaultMessage: 'SMS allowed'
  },
  overviewAllowSmsCampaign: {
    id: 'sysadmin.overview.allowSmsCampaign',
    defaultMessage: 'SMS campaign allowed'
  },
  overviewSmsLimitIndividual: {
    id: 'sysadmin.overview.overviewSmsLimitIndividual',
    defaultMessage: 'Sent SMS'
  },
  overviewSmsLimitBulk: {
    id: 'sysadmin.overview.overviewSmsLimitBulk',
    defaultMessage: 'Sent campaign SMS'
  },
  overviewAllowEmailCampaign: {
    id: 'sysadmin.overview.allowEmailCampaign',
    defaultMessage: 'Email campaign allowed'
  },
  pricePlan: {
    id: 'sysadmin.overview.pricePlan',
    defaultMessage: 'Prisplan'
  },
  fixedPrice: {
    id: 'sysadmin.overview.fixedPrice',
    defaultMessage: 'Pris/mån (Cliento)'
  },
  contractLength: {
    id: 'sysadmin.overview.contractLength',
    defaultMessage: 'Bindningstid'
  },
  fixedPriceMaxResources: {
    id: 'sysadmin.overview.fixedPriceMaxResources',
    defaultMessage: 'Inkluderade resurser'
  },
  fixedPriceMaxLocations: {
    id: 'sysadmin.overview.fixedPriceMaxLocations',
    defaultMessage: 'Inkluderade locations'
  },
  fixedPriceMaxVunits: {
    id: 'sysadmin.overview.fixedPriceMaxVunits',
    defaultMessage: 'Inkluderade kassor'
  },
  billedResources: {
    id: 'sysadmin.overview.billedResources',
    defaultMessage: 'Resurser'
  },
  billedLocations: {
    id: 'sysadmin.overview.billedLocations',
    defaultMessage: 'Locations'
  },
  contractStartDate: {
    id: 'sysadmin.overview.contractStartDate',
    defaultMessage: 'Kontrakt start'
  },
  contractEndDate: {
    id: 'sysadmin.overview.contractEndDate',
    defaultMessage: 'Kontrakt slut'
  },
  bookingIntroPrice: {
    id: 'sysadmin.overview.bookingIntroPrice',
    defaultMessage: 'Introduktionspris'
  },
  bookingIntroPriceUntil: {
    id: 'sysadmin.overview.bookingIntroPriceUntil',
    defaultMessage: 'Introduktionspris t.om.'
  },
  externalInvoiceFixedPrice: {
    id: 'sysadmin.overview.externalInvoiceFixedPrice',
    defaultMessage: 'Pris/mån (Yabie-kontrakt)'
  },
  perResourcePrice: {
    id: 'sysadmin.overview.perResourcePrice',
    defaultMessage: 'Extra resurs'
  },
  perLocationPrice: {
    id: 'sysadmin.overview.perLocationPrice',
    defaultMessage: 'Extra location'
  },
  perInitialVunitPrice: {
    id: 'sysadmin.overview.perInitialVunitPrice',
    defaultMessage: 'Kassaregister'
  },
  perExtraVunitPrice: {
    id: 'sysadmin.overview.perExtraVunitPrice',
    defaultMessage: 'Extra kassaregister'
  },
  smsReminderPrice: {
    id: 'sysadmin.overview.smsReminderPrice',
    defaultMessage: 'SMS reminder price'
  },
  smsPinPrice: {
    id: 'sysadmin.overview.smsPinPrice',
    defaultMessage: 'SMS pin price'
  },
  smsBulkPrice: {
    id: 'sysadmin.overview.smsBulkPrice',
    defaultMessage: 'SMS bulk price'
  }
});
