import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { setLastVisitedPage } from '@Utils/session';
import { fetchFortnoxAuthUrl, refreshFortnoxTokens } from '@State/fortnox-actions';
import { clearFeatureCache, createAllFortnoxInvoices, refreshYabieContracts } from '@State/sysadmin-actions';
import ProgressButton from '@Components/ui/progress-button';
import ConfirmActionModal from '@Components/ui/confirm-action-modal';
import Button from '@Components/ui/button';
import AccountReport from './account-report';

const Tools = () => {
  const dispatch = useDispatch();
  const [showCreateInvoices, setShowCreateInvoices] = useState(false);

  const onConnectFortnox = () => dispatch(fetchFortnoxAuthUrl())
    .then(({ linkUrl }) => {
      setLastVisitedPage(window.location.pathname);
      window.location = linkUrl;
    });

  const onClearFeatureCache = () => {
    return Promise.all([
      dispatch(clearFeatureCache(false)),
      dispatch(clearFeatureCache(true))
    ]);
  };

  const onRefreshYabieContracts = () => dispatch(refreshYabieContracts());

  return (
    <div className="columns-wrapper">
      <div className="columns-container">
        <div className="columns-content">
          <div className="columns-content-container width-large">
            <div className="columns-content-body with-padding">
              <div>
                <h5>Fakturor</h5>
                <p>
                  <Button gray small onClick={() => setShowCreateInvoices(true)}>
                    Skapa alla fakturor
                  </Button>
                  {showCreateInvoices && (
                    <ConfirmActionModal
                      onConfirm={() => dispatch(createAllFortnoxInvoices())}
                      onCancel={() => setShowCreateInvoices(false)}
                      confirmMessage="Vill du skapa fakturor i Fortnox för alla fakturakonton?"
                      successMessage="Fakturor skapade"
                      confirmButtonText="Skapa fakturor"
                    />
                  )}
                </p>
              </div>
              <div>
                <h5>Rapporter</h5>
                <p>
                  <AccountReport upload />
                </p>
                <p>
                  <AccountReport />
                </p>
              </div>
              <div>
                <h5>Cache</h5>
                <p>
                  <ProgressButton gray small onClick={onClearFeatureCache}>
                    Rensa feature cache
                  </ProgressButton>
                </p>
                <p>
                  <ProgressButton gray small onClick={onRefreshYabieContracts}>
                    Återskapa Yabie invoice items
                  </ProgressButton>
                </p>
              </div>
              <div>
                <h5>Fortnox</h5>
                <p>
                  <ProgressButton gray small onClick={onConnectFortnox}>
                    Koppla Fortnox-konto
                  </ProgressButton>
                </p>
                <p>
                  <ProgressButton gray small onClick={() => dispatch(refreshFortnoxTokens())}>
                    Uppdatera Fortnox-tokens
                  </ProgressButton>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tools;
