import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import { Button, Flex, Row } from '@Components/ui/styled/main';
import AccountsContext from '@Components/sysadmin/accounts/accounts-context';
import AccountHeaderBtnTerminate from '@Components/sysadmin/accounts/account-detail/account-header/account-header-btn-terminate';
import AccountHeaderBtnInvoiceCustomer from '@Components/sysadmin/accounts/account-detail/account-header/account-header-btn-invoice-customer';
import AccountHeaderBtnRedirect from '@Components/sysadmin/accounts/account-detail/account-header/account-header-btn-redirect';

import {
  getSysadminLocations,
  getSysadminStateOrganizationById,
  getSysadminStateSelectedOrganization
} from '@Components/sysadmin/accounts/accounts-selector';
import { webBookingUrl } from '@Utils/config';
import AccountsHeaderLabels from './accounts-header-labels';
import AccountHeaderBtnActivate from './account-header-btn-activate';
import AccountHeaderBtnDelete from './account-header-btn-delete';
import AccountHeaderBtnLead from './account-header-btn-lead';

const AccountsHeader = ({ loading, phoneMode, showSidebar, onShowSidebar }) => {
  const { routeParams } = useContext(AccountsContext);
  const selectedOrg = useSelector(state => getSysadminStateSelectedOrganization(state, routeParams.id));
  const subscription = useSelector(state => getSysadminStateOrganizationById(state));
  const locations = useSelector(state => getSysadminLocations(state));
  const location = locations?.first();

  const name = selectedOrg && selectedOrg.get('name') || '';

  const trial = subscription.get('trial');
  const terminated = subscription.get('terminated');
  const invoiceCustomer = subscription.get('invoiceCustomer');
  const sfLeadCreated = subscription.getIn(['signup', 'sfLeadCreatedTs']);

  const getURLCalendar = (location) => `/${location.get('orgCtxName')}/${location.get('ctxName')}/calendar/`;
  const getURLOrgAdmin = (location) => `/organisations/${location.get('orgCtxName')}/subscription/`;
  const getURLWebbooking = (location) => `${webBookingUrl()}${location.get('slug')}/`;

  return (
    <div>
      <h3 className="mt0 mb2">
        {!showSidebar && (
          <Button small gray onClick={onShowSidebar} className="mr2">
            <i className="fa fa-sidebar" />
          </Button>
        )}
        {name}
        <AccountsHeaderLabels org={selectedOrg} />
      </h3>
      <Row className="mb2">
        {!loading && (
          <Flex>
            <AccountHeaderBtnRedirect
              buttonText={!phoneMode ? 'Bokningssida' : null}
              getHREF={getURLWebbooking}
              iconClass="fas fa-globe"
            />
            <AccountHeaderBtnRedirect
              buttonText={!phoneMode ? 'Kalender' : null}
              getHREF={getURLCalendar}
              iconClass="far fa-calendar-alt"
            />
            <AccountHeaderBtnRedirect
              buttonText={!phoneMode ? 'Org. admin' : null}
              getHREF={getURLOrgAdmin}
              iconClass="fas fa-cloud"
            />
          </Flex>
        )}
        <Flex right>
          {!sfLeadCreated && !terminated && trial && (
            <AccountHeaderBtnLead id={routeParams.id} />
          )}
          {trial && !terminated && (
            <AccountHeaderBtnActivate id={routeParams.id} />
          )}
          {!invoiceCustomer && !terminated && (
            <AccountHeaderBtnInvoiceCustomer subscription={subscription} id={routeParams.id} />
          )}
          <AccountHeaderBtnTerminate terminated={terminated} id={routeParams.id} />
          {terminated && (
            <AccountHeaderBtnDelete
              orgId={location?.get('organisationId')}
              orgCtxName={location?.get('orgCtxName')}
            />
          )}
        </Flex>
      </Row>
    </div>
  );
};

export default AccountsHeader;
