import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import JSONEditorContent from '@Components/ui/jsoneditor/jsoneditor-form';
import Loader from '@Components/ui/loader';
import { savePrivatePreferences } from '@State/preferences-actions';

const AdminConfigPrivatePrefs = () => {
  const dispatch = useDispatch();
  const privatePreferences = useSelector(state => state.preferencesViewState.get('privatePreferences'));
  if (!privatePreferences) {
    return (<Loader />);
  }
  return (
    <JSONEditorContent
      title="Private Prefs"
      json={privatePreferences}
      onSubmit={(values) => dispatch(savePrivatePreferences(values))}
    />
  );
};

export default AdminConfigPrivatePrefs;
