const colorAlts = {
  brandProPurple: '#0d042f',
  brandPurple: '#310035'
};

export const colors = {

  /* NEW BRAND COLORS HERE */
  brandPink: '#F85FA4',
  brandDarkPurple: colorAlts.brandProPurple,
  brandPurple: colorAlts.brandPurple,
  brandBlue: '#3e4798',
  brandBlueLight: '#5E55FC',

  textColor: colorAlts.brandProPurple,
  textColorLight: '#fff',
  textColorDark: '#17001a',
  linkColor: '#0969da',

  textMuted: '#858585',
  mutedPrimary: `${colorAlts.brandProPurple}AA`,

  darkGray: '#343434',
  darkGray80: '#5d5d5d',
  darkGray60: '#858585',
  darkGray40: '#aeaeae',

  softGray: '#d6d6d6',
  softGray80: '#dedede',
  softGray60: '#e6e6e6',
  softGray40: '#efefef',
  softGray20: '#f7f7f7',
  softGray10: '#fbfbfb',

  /* PREVIOUS COLORS HERE */

  grayDarkCool: '#262C34',

  gray: '#4D535E',
  grayBGLight: '#8C9097',
  grayBGLightText: '#ccc',
  grayLight: '#dedede',
  grayLighter: '#e7e7e7',
  grayBorder: '#EAEDf1',
  grayBorderBoxShadowFocus: '#d3d3d3',
  grayLightest: '#f7f7f7',
  grayLightBorder: '#f0f0f0',
  grayCircle: '#bebebe',
  lightGrayColor: '#616161',

  formLabel: '#8592B1',

  primary: '#3e4798',
  primaryLight: '#6FAEFF',
  primaryDark: '#2C4F6B',
  greenCircle: '#31BA14',

  danger: '#E00000',
  orange: '#FC6248',
  eventWarning: '#F7DD51',
  bhGreenLight: '#8EE380',
  redLight: '#DA554E',
  blueTime: '#0762B1',
  bgDialogSecondary: '#E9E9E9',
  bgWarning: '#FFCB58',
  bgYellowLight: '#F4EAD2',
  bgSuccess: '#39cd60',
  bgPrimaryDark: '#044161',
  bgGrayLight: 'rgba(0, 0, 0, 0.03)',
  bgLightGreen: '#cfffc0',
  alertSuccess: '#EBFFD0',
  panelGrayBg: '#dedede',
  defaultBtnBg: '#efefef',
  defaultBtnColor: '#333',
  colorGrayLabel: '#A7A7A7',
  formTitleColor: 'rgba(0, 0, 0, 0.7)',

  notesBg: '#FBE8E4',
  logsBg: '#FFDC20',
  bookingBg: 'rgb(177,211,244)',
  salesBg: '#96E231',
  smsBg: '#93ccd1',

  titleBookingGreen: 'rgb(84,127,38)',
  titleBookingRed: 'rgb(191,59,59)',
  labelTrial: '#20AED2',
  labelUnpaid: '#D22020',
  labelTest: '#FF9800',
  labelTerminated: '#999',
  labelSuccessText: '#5EC328'
};

export const fsw = {
  bold: 600,
  medium: 500,
  book: 400,
  light: 300
};

export const fs = {
  s: '13px',
  l: '15px',
  xl: '17px'
};

export const screens = {
  xs: '600px',
  sm: '600px',
  phone: '767px',
  md: '1000px',
  lg: '1200px'
};

export default {
  cl: colors,
  fs,
  fsw
};
