import Webcam from 'react-webcam';
import React, { useState } from 'react';
import Button from '@Components/ui/button';
import ModalDialog from '@Components/dialogs/modal-dialog';

const WebcamModal = ({ onClose, onSubmit }) => {
  const webcamRef = React.useRef(null);
  const [image, setImage] = useState(null);

  const captureImage = () => {
    setImage(webcamRef.current.getScreenshot());
  };

  const submitImage = () => {
    onSubmit(image);
    onClose();
  };

  if (image) {
    return (
      <ModalDialog
        contentSize="xl"
        closeButtonText="Stäng"
        onClose={onClose}
        title="Ta bild"
      >
        <img src={image} />
        <div className="mt4 text-center">
          <Button large primary marginRight onClick={submitImage}>
            Använd bild
          </Button>
          <Button large gray onClick={() => setImage(null)}>
            Ta ny bild
          </Button>
        </div>
      </ModalDialog>
    );
  }

  return (
    <ModalDialog
      contentSize="xl"
      closeButtonText="Stäng"
      onClose={onClose}
      title="Ta bild"
    >
      <Webcam
        audio={false}
        ref={webcamRef}
        forceScreenshotSourceSize
        screenshotFormat="image/jpeg"
        videoConstraints={{
          facingMode: 'environment'
        }}
      />
      <div className="mt4 text-center">
        <Button large gray onClick={captureImage}>
          <i className="far fa-camera mr1" />
          Ta bild
        </Button>
      </div>
    </ModalDialog>
  );
};

export default WebcamModal;
