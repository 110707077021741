import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { groupBy } from 'ramda';
import NavItem from './sub-nav-item';

const SubNav = ({ routeParams, sections, query = '' }) => {
  const groups = groupBy(s => s.group, sections);
  return (
    <ul className="subnav-nav">
      {Object.keys(groups).map(name => (
        <Fragment key={name}>
          <li className="subnav-nav-heading">
            {name}
          </li>
          {groups[name].map(section => (
            <NavItem key={section.id} {...section} {...routeParams} query={query} />
          ))}
        </Fragment>
      ))}
    </ul>
  );
};

SubNav.propTypes = {
  routeParams: PropTypes.object.isRequired,
  sections: PropTypes.array.isRequired
};

export default SubNav;
