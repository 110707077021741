import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import ButtonModalForm from '@Components/dialogs/button-modal-form';
import HookFormInput from '@Components/inputs/hook-form-input';
import DialogAlert from '@Components/dialogs/dialog-alert';
import { importBokadirektReviews } from '@State/import-actions';
import FormButtonsFooter from '@Components/ui/form-buttons-footer';

const exampleUrl = 'https://www.bokadirekt.se/places/salongen-12345';
const urlPattern = /^https:\/\/www\.bokadirekt\.se\/places\/.+-\d+$/;

const urlValidation = {
  required: { value: true, message: 'Måste fyllas i' },
  pattern: {
    value: urlPattern,
    message: 'Inte en giltig Bokadirekt-adress'
  }
};

const AccountImportReviews = ({ location }) => {
  const dispatch = useDispatch();
  const [importSuccess, setImportSuccess] = useState(null);
  const methods = useForm();

  const onImportReviews = ({ url }) => {
    return dispatch(importBokadirektReviews(location.get('orgCtxName'), location.get('ctxName'), url))
      .then((data) => {
        setImportSuccess(data);
        methods.reset();
      });
  };

  return (
    <>
      {importSuccess && (
        <DialogAlert
          success
          title=""
          icon="fa fa-check-circle"
          text={(
            <div>
              {importSuccess.importedReviews || '-'} omdömen importerades<br /><br />
              <strong>Betyg: {importSuccess.ratings?.stats?.score || '-'}</strong>
            </div>
          )}
          onClose={() => setImportSuccess(null)}
          closeButtonText="Stäng"
        />
      )}
      <ButtonModalForm
        dialogProps={{
          title: 'Importera omdömen',
          contentSize: 'large'
        }}
        onClose={() => methods.reset()}
        onSubmit={onImportReviews}
        btnText="Importera omdömen"
      >
        {({ onClose, onSubmit, loading }) => (
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <HookFormInput
                id="url"
                type="text"
                label="Adress till sida på Bokadirekt"
                helpText={`Exempel: ${exampleUrl}`}
                registerOptions={urlValidation}
              />
              <FormButtonsFooter
                submitText="Importera omdömen"
                submitting={loading}
                onClose={onClose}
              />
            </form>
          </FormProvider>
        )}
      </ButtonModalForm>
    </>
  );
};

export default AccountImportReviews;
