import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchState, resetChecksumMismatch } from '@State/pos-actions';
import DialogAlert from '@Components/dialogs/dialog-alert';

const ChecksumMismatchError = () => {
  const dispatch = useDispatch();
  const [progress, setProgress] = useState(false);
  const [reloaded, setReloaded] = useState(false);

  const handleReset = async () => {
    try {
      setProgress(true);
      await dispatch(fetchState());
      setReloaded(true);
    } finally {
      setProgress(false);
    }
  };

  const handleClose = () => {
    dispatch(resetChecksumMismatch());
  };

  return (
    <DialogAlert
      buttons={[{
        name: reloaded ? 'OK' : 'Uppdatera',
        primary: true,
        loading: progress,
        onClick: reloaded ? handleClose : handleReset
      }]}
      icon={reloaded ? 'fa fa-check-circle' : 'fa fa-exclamation-triangle'}
      warning={!reloaded}
      success={reloaded}
      text={reloaded ? (
        <div>
          Uppdatering klar. Kontrollera antal och pris
          på inslagna produkter innan du genomför köpet.
        </div>
      ) : (
        <div>
          Inslagna produkter är inte i synk med servern.
          Uppdatera från servern innan köpet kan genomföras.
        </div>
      )}
    />
  );
};

export default ChecksumMismatchError;
