import React, { useEffect, useRef, useState } from 'react';
import TextInput from '@Components/inputs/text-input';
import ConfirmBlock from '@Components/ui/confirm-block';

const ConfirmSignature = ({ confirmText = 'Spara', onConfirm, onHidePopup }) => {
  const [signature, setSignature] = useState('');
  const [disableConfirm, setDisableConfirm] = useState(true);
  const refInput = useRef();

  const onChange = (e) => {
    setSignature(e.target.value);
    setDisableConfirm(!e.target.value);
  };

  const onConfirmSignature = (e) => {
    if (e) e.preventDefault();
    if (signature) {
      onConfirm(signature);
    }
  };

  const input = {
    placeholder: 'Initialer eller namn',
    value: signature,
    onChange,
    ref: refInput
  };

  useEffect(() => {
    refInput.current.focus();
  }, []);

  return (
    <form onSubmit={onConfirmSignature}>
      <ConfirmBlock
        disableConfirm={disableConfirm}
        confirmText={confirmText}
        onCancel={onHidePopup}
        onConfirm={onConfirmSignature}
      >
        <TextInput
          meta={{}}
          label="Vem ändrar?"
          input={input}
        />
      </ConfirmBlock>
    </form>

  );
};

ConfirmSignature.propTypes = {
};

export default ConfirmSignature;
