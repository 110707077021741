import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getTimeString } from '@Utils/time-util';
import { saveUserEmployment } from '@State/users-actions';
import { getEmploymentTypeDescription } from '@Utils/users';
import { Panel, Button, FormGroup } from '@Components/ui/styled/main';
import CurrencyUtil from '@Utils/currency-util';
import StaffEmploymentModal from './staff-employment-modal';

const StaffEmployment = ({ user }) => {
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);

  const onSaveEmployment = (values) => {
    return dispatch(saveUserEmployment(user.id, values))
      .finally(() => setShowModal(false));
  };

  const {
    employmentType, workMinutesPerDay, workDaysPerWeek,
    lunchMinutesPerDay, monthlyRent, rentVatPct
  } = user?.employment || {};

  const rentString = useMemo(() => {
    const rentCurrency = CurrencyUtil.accountCurrency(monthlyRent, 0);
    if (monthlyRent && rentVatPct > 0) {
      return `${rentCurrency} (+ ${rentVatPct}% moms)`;
    }
    return monthlyRent ? rentCurrency : '-';
  }, [monthlyRent, rentVatPct]);

  return (
    <Panel>
      <div className="form-group">
        <p className="mb0">
          <label className="control-label">Anställning/hyresavtal</label>
        </p>
        <div className="text-muted">
          Inställningar för löneunderlag eller hyresavtal
        </div>
      </div>
      {employmentType === 'SelfEmployed' ? (
        <div className="row mb3">
          <div className="col-xs-6">
            <FormGroup
              labelText="Avtal"
              text={getEmploymentTypeDescription(employmentType)}
            />
          </div>
          <div className="col-xs-6">
            <FormGroup
              labelText="Månadshyra"
              text={rentString}
            />
          </div>
        </div>
      ) : (
        <div className="row mb3">
          <div className="col-xs-3">
            <FormGroup
              labelText="Avtal"
              text={getEmploymentTypeDescription(employmentType)}
            />
          </div>
          <div className="col-xs-3">
            <FormGroup
              labelText="Timmar"
              text={getTimeString(workMinutesPerDay * workDaysPerWeek)}
            />
          </div>
          <div className="col-xs-3">
            <FormGroup
              labelText="Dagar"
              text={workDaysPerWeek}
            />
          </div>
          <div className="col-xs-3">
            <FormGroup
              labelText="Lunch"
              text={getTimeString(lunchMinutesPerDay)}
            />
          </div>
        </div>
      )}
      <Button small white onClick={() => setShowModal(true)}>Ändra avtal</Button>
      {showModal && (
        <StaffEmploymentModal
          user={user}
          onSubmit={onSaveEmployment}
          onClose={() => setShowModal(false)}
        />
      )}
    </Panel>
  );
};

export default StaffEmployment;
