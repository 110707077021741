import moment from 'moment';
import s from 'underscore.string';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { getColumnHeadersStyle } from '@Utils/embedded-util';
import { notifyColumnHeaderState } from '@Utils/wk-embed-bridges';
import { getScheduleBlockForColumn } from '@Components/calendar/grid/grid-util';
import { calendar } from '@Utils/preference-keys';
import { colors } from '@Components/ui/styled/variables';
import { getColumnsSelector, getGridProps, getScheduleBlockSelector } from './grid-selectors';
import ColumnWebIndicator from './column-web-indicator';
import {
  LABEL_COLUMN_HEIGHT_OTHER_MODE,
  LABEL_COLUMN_HEIGHT_PHONE_MODE,
  TIME_COLUMN_WIDTH_OTHER_MODE,
  TIME_COLUMN_WIDTH_PHONE_MODE
} from './constants';

class ColumnHeaders extends Component {
  static propTypes = {
    viewMode: PropTypes.oneOf(['week', 'day']).isRequired,
    entityType: PropTypes.oneOf(['my-resource', 'resource', 'group']).isRequired,
    timeColumnWidth: PropTypes.number.isRequired,
    phoneMode: PropTypes.bool.isRequired,
    scrollBars: PropTypes.bool.isRequired,
    scrollBarWidth: PropTypes.number.isRequired,
    columns: PropTypes.array.isRequired,
    resourcesById: PropTypes.object.isRequired
  };

  componentDidMount() {
    // This will make the today indicator move to the next day at midnight..
    //
    const date = new Date();
    const secToNextTopOfMinute = (60 - date.getSeconds());
    this.initialTimer = setTimeout(() => {
      this.timer = setInterval(() => this.forceUpdate(), 60000);
      this.forceUpdate();
    }, secToNextTopOfMinute * 1000);
  }

  componentWillUnmount() {
    clearTimeout(this.initialTimer);
    clearInterval(this.timer);
  }

  componentDidUpdate(prevProps) {
    ReactTooltip.rebuild();
    const { calendarViewState } = this.props;
    const currentLoadingState = calendarViewState.get('loadingViewData');
    const previousLoadingState = prevProps.calendarViewState.get('loadingViewData');
    if (currentLoadingState === false && previousLoadingState === true) {
      setTimeout(() => notifyColumnHeaderState({ loadingDone: true }), 1);
    }
  }

  render() {
    const {
      columns, scrollBars, scrollBarWidth, timeColumnWidth, labelColumnHeight,
      phoneMode, columnHeadersStyle, scheduleBlocks, showHolidays, entityType
    } = this.props;
    const scrollBar = scrollBars ? <td width={scrollBarWidth} /> : null;
    const widthPct = 100 / columns.length;
    const style = i => ({
      left: `${i * widthPct}%`,
      width: `${widthPct}%`,
      fontSize: phoneMode ? '11px' : '12px'
    });

    return (
      <div id="columncontainer" style={columnHeadersStyle}>
        <table>
          <tbody>
            <tr>
              <td className="column-time" style={{ width: timeColumnWidth }} />
              <td className="column-labels" style={{ height: labelColumnHeight }}>
                {columns.map((column, i) => {
                  const block = getScheduleBlockForColumn(scheduleBlocks, column);
                  const isHoliday = showHolidays && block?.publicHoliday;
                  const holidayName = showHolidays && block?.holidayName;
                  const className = isHoliday ? 'header-item vrule holiday' : 'header-item vrule';
                  return (
                    <div id={`ch${i}`} key={`ch${i}`} style={style(i)} className={className} title={holidayName}>
                      {this.columnLabel(column, block)}
                    </div>
                  );
                })}
              </td>
              {scrollBar}
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  columnLabel(column, block) {
    const { viewMode, entityType, columns } = this.props;

    if (viewMode === 'week') {
      return this.weekDayLabel(column, block);
    }
    return entityType === 'group' || columns.length > 1
      ? this.groupLabel(column, block)
      : this.resourceDayLabel(column, block);
  }

  columnWebIndicator(column, block) {
    const { phoneMode, showWebBookingIndicator, showHolidays } = this.props;

    return showWebBookingIndicator && (
      <ColumnWebIndicator column={column} block={block} phoneMode={phoneMode} showHolidays={showHolidays} />
    );
  }

  resourceDayLabel(column, block) {
    const { viewMode } = this.props;
    const label = viewMode === 'day'
      ? moment(column.date).format('ddd D MMM, YYYY')
      : moment(column.date).format('dd D');

    return (
      <div className="resource-label">
        {this.columnWebIndicator(column, block)}
        <span>{label}</span>
      </div>
    );
  }

  weekDayLabel(column, block) {
    const { viewMode, phoneMode } = this.props;
    const isToday = moment(column.date).isSame(new Date(), 'day');
    const hlToday = viewMode === 'week' && isToday;
    const dayFormat = phoneMode ? 'dd' : 'ddd';
    const circleSize = phoneMode ? 18 : 22;

    const style = {
      borderRadius: '50%',
      width: hlToday ? circleSize : '',
      height: circleSize,
      background: hlToday ? colors.brandPink : '',
      display: 'inline-block',
      verticalAlign: 'middle',
      color: hlToday ? 'white' : '',
      margin: phoneMode ? '-2px 0 1px 2px' : '-1px 0 1px 2px',
      padding: phoneMode ? '1px' : '2px'
    };

    return (
      <div className="date-label">
        {this.columnWebIndicator(column, block)}
        <span>
          {s.capitalize(moment(column.date).format(dayFormat))}
          <span style={style}>{moment(column.date).format('D')}</span>
        </span>
      </div>
    );
  }

  groupLabel(column, block) {
    const { resourcesById } = this.props;
    const resource = resourcesById.get(column.resourceIds[0]);

    return (
      <div className="group-label">
        {this.columnWebIndicator(column, block)}
        <span>{resource.name}</span>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { resourcesById, locationConfig, calendarViewState } = state;
  const { viewMode, entityType } = ownProps.routeParams;

  const scheduleBlocks = getScheduleBlockSelector(state, ownProps);
  const columns = getColumnsSelector(state, ownProps);
  const gridProps = getGridProps(state, ownProps);
  const phoneMode = state.mainViewState.get('phoneMode');
  const timeColumnWidth = phoneMode ? TIME_COLUMN_WIDTH_PHONE_MODE : TIME_COLUMN_WIDTH_OTHER_MODE;
  const labelColumnHeight = phoneMode ? LABEL_COLUMN_HEIGHT_PHONE_MODE : LABEL_COLUMN_HEIGHT_OTHER_MODE;
  const showWebBookingIndicator = locationConfig.get(calendar.showWebBookingIndicator);
  const showHolidays = locationConfig.get(calendar.showHolidays);

  return {
    viewMode,
    entityType,
    scrollBars: gridProps.scrollBars,
    scrollBarWidth: gridProps.scrollBarWidth,
    timeColumnWidth,
    labelColumnHeight,
    phoneMode,
    columns,
    scheduleBlocks,
    resourcesById,
    showWebBookingIndicator,
    showHolidays,
    columnHeadersStyle: getColumnHeadersStyle(state),
    calendarViewState
  };
};

export default connect(
  mapStateToProps
)(ColumnHeaders);
