import React, { useCallback, useEffect, useMemo, useState } from 'react';

import ModalDialog from '@Components/dialogs/modal-dialog';
import Button from '@Components/ui/button';
import TextInput from '@Components/inputs/text-input';
import { ModalContent, ModalFooter } from '@Components/dialogs/dialog-styles';
import { txt } from '@Utils/i18n-util';
import useIntersection from '@Hooks/useIntersection';

import msg from './data-export.msg';

const InputItem = ({ children, onVisible }) => {
  const ref = React.useRef();
  const inViewport = useIntersection(ref, '0px');

  useEffect(() => {
    if (inViewport) {
      onVisible();
    }
  }, [inViewport, onVisible]);

  return (
    <div ref={ref} className="item">
      <span className="ellipsis">
        {children}
      </span>
    </div>
  );
};

const SelectModal = ({ onClose, title, items, groups, groupKey, getItemTitle, selected, onChange, sortGroups }) => {
  const [searchValue, setSearchValue] = useState('');
  const [collapsed, setCollapsed] = useState({});

  const filter = useCallback((name) => {
    const values = searchValue?.toLowerCase().split(' ') ?? [];
    const titleArr = name?.toLowerCase().split(' ') ?? [];
    return values.every(v => titleArr.some(t => t.indexOf(v) >= 0));
  }, [searchValue]);

  const filteredItems = useMemo(() => items.filter(item => filter(getItemTitle(item))),
    [items, filter, getItemTitle]);

  const filteredGroups = useMemo(() => {
    const arr = groups && groups.filter(group => {
      return filter(group.name) || filteredItems.some(({ id }) => group[groupKey] && group[groupKey].includes(id));
    }) || [];
    return sortGroups ? sortGroups(arr) : arr;
  }, [groups, filteredItems, searchValue]);

  const isSelected = useCallback(item => !!selected.includes(item.id), [selected]);

  const groupSelected = useCallback(
    (group) => group[groupKey] && group[groupKey].every(id => selected.includes(id)), [selected]
  );

  const onGroupCollapse = useCallback((id) => {
    setCollapsed(prev => {
      const nextVal = !prev[id];
      const ids = groups.map(({ id }) => id);
      let res = {};
      if (!!nextVal && prev._ALL) {
        ids.forEach(id => {
          res[id] = true;
        });
        return { ...res, [id]: false, _ALL: false };
      }
      res = { ...prev, [id]: nextVal };
      return { ...res, _ALL: !!ids.every(id => !!res[id]) };
    });
  }, [groups]);

  return (
    <ModalDialog
      contentSize="large"
      title={title}
      closeButtonText={txt(msg.btnClose)}
      onClose={onClose}
    >
      <ModalContent>
        <TextInput
          meta={{}}
          input={{
            placeholder: 'Filter',
            value: searchValue,
            onChange: e => setSearchValue(e.target.value ?? '')
          }}
        />
        <div className="multiselect-modal">
          <div className="collapse-row mb-2">
            <div className="all-buttons">
              <Button
                gray
                small
                className="btn btn-default"
                style={{ display: 'flex', marginRight: '1rem' }}
                onClick={() => onChange('_ALL', { [groupKey]: items.map(item => item.id) })}
              >
                <span>{items.every(isSelected) ? txt(msg.deselectAll) : txt(msg.selectAll)}</span>
              </Button>
              <Button
                gray
                small
                disabled={!searchValue}
                className="btn btn-default"
                style={{ display: 'flex' }}
                onClick={() => onChange('_ALL', { [groupKey]: filteredItems.map(item => item.id) })}
              >
                <span>{!filteredItems.every(isSelected) ? txt(msg.selectAllFiltered) : txt(msg.deselectAllFiltered)}</span>
              </Button>
            </div>
            <button
              className="btn btn-default icon-button"
              onClick={() => setCollapsed(() => ({ _ALL: !collapsed._ALL }))}
            >
              <i className={`far fa-arrows-${collapsed._ALL ? 'from-dotted-line' : 'to-dotted-line'}`} />
            </button>
          </div>
          <div className="scroll-container">
            {groups ? filteredGroups
              .map((group, i) => (
                <div key={group?.id ?? i}>
                  <div className="collapse-row">
                    <label>
                      <input
                        type="checkbox"
                        checked={groupSelected(group)}
                        onChange={() => onChange(group.id, group)}
                      />
                      <span>{group.name ?? 'some-group'}</span>
                    </label>
                    <i
                      className={collapsed._ALL || collapsed[group.id] ? 'fa fa-fw fa-chevron-right' : 'fa fa-fw fa-chevron-down'}
                      onClick={() => onGroupCollapse(group.id)}
                    />
                  </div>
                  <table className="table table-striped table-borderless">
                    <tbody>
                      {(!collapsed._ALL && !collapsed[group.id]) && filteredItems
                        .filter(item => group[groupKey] && group[groupKey]?.includes(item.id))
                        .map((item, i) => (
                          <tr key={item.id ?? i}>
                            <td style={{ borderTop: 'none' }}>
                              <label>
                                <input
                                  type="checkbox"
                                  checked={isSelected(item)}
                                  onChange={() => onChange(item.id)}
                                />
                                {getItemTitle(item) ?? 'some-value'}
                              </label>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              )) : (
                <table className="table table-striped table-borderless">
                  <tbody>
                    {!collapsed._ALL && filteredItems.map((item, i) => (
                      <tr key={item?.id + i ?? i}>
                        <td style={{ borderTop: 'none' }}>
                          <label>
                            <input
                              type="checkbox"
                              checked={isSelected(item)}
                              onChange={() => onChange(item.id)}
                            />
                            {getItemTitle(item) ?? 'some-value'}
                          </label>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
            )}
          </div>
        </div>
      </ModalContent>
      <ModalFooter>
        <Button gray small onClick={onClose}>{txt(msg.btnClose)}</Button>
      </ModalFooter>
    </ModalDialog>
  );
};

const ModalSelector = ({
  showLabel = true,
  title,
  placeholder,
  modalTitle,
  items,
  onChange,
  values,
  groups,
  groupKey = 'resourceIds',
  sortGroups,
  getItemTitle,
  onModal,
  error
}) => {
  const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = useState(values ?? []);
  const [visibleCount, setVisibleCount] = useState(0);

  useEffect(() => {
    setSelected(values);
  }, [values]);

  useEffect(() => {
    onModal && onModal(showModal);
  }, [showModal]);

  const onShowModal = useCallback(() => setShowModal(true), []);

  const onHideModal = useCallback(() => setShowModal(false), []);

  const handleChange = useCallback((id, group) => {
    let newIds = [...selected];
    if (group && group[groupKey]) {
      if (group[groupKey].every(id => newIds?.includes(id))) {
        newIds = newIds.filter(id => !group[groupKey].includes(id));
      } else {
        newIds = newIds.filter(id => !group[groupKey].includes(id));
        newIds = [...newIds, ...group[groupKey]];
      }
    } else {
      newIds = selected.includes(id) ? newIds.filter(s => s !== id) : [...newIds, id];
    }
    onChange(newIds);
  }, [onChange, groups, selected, groupKey]);

  const getTitle = useCallback((item) => {
    const title = getItemTitle(item);
    return typeof title === 'string' ? title : 'title';
  }, [getItemTitle]);

  const getSelectedItemTitle = useCallback((id) => getTitle(items.find(it => it.id === id)), [items, getTitle]);

  const onCountChange = useCallback((i) => {
    if (!showModal) {
      setVisibleCount(i + 1);
    }
  }, [showModal]);

  return (
    <div className={error ? 'form-group has-error' : 'form-group'}>
      {showLabel && <label className="control-label">{title}</label>}
      <div className="select-items-limited-input" onClick={onShowModal}>
        <div className="items-row">
          {!showModal && selected.length
            ? selected.map((id, i) => (
              <InputItem key={id} onVisible={() => onCountChange(i)}>{getSelectedItemTitle(id)}</InputItem>
            ))
            : <div className="no-content">{placeholder ?? 'No selected'}</div>}
        </div>
        {!showModal && selected.length > visibleCount ? (
          <div className="item last">
            {`+ ${selected.length - visibleCount} ${txt(msg.more)}`}
          </div>
        ) : null}
      </div>
      {error && <span className="help-block">{error}</span>}
      {showModal && (
        <SelectModal
          onClose={onHideModal}
          title={modalTitle ?? title}
          items={items}
          groups={groups}
          sortGroups={sortGroups}
          groupKey={groupKey ?? 'resourceIds'}
          getItemTitle={getTitle}
          selected={selected}
          onChange={handleChange}
        />
      )}
    </div>
  );
};

export default ModalSelector;
