import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import FormButtonsFooter from '@Components/ui/form-buttons-footer';
import HookFormInput from '@Components/inputs/hook-form-input';

const fields = [
  { id: 'sendLimitIndividual', label: 'Individuella SMS' },
  { id: 'sendLimitBulk', label: 'SMS-utskick' }
];

const AccountPrefsSmsLimitsForm = ({ onClose, onSubmit, initialValues, loading }) => {
  const methods = useForm({ defaultValues: initialValues });

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {fields.map(({ id, label }) => (
          <HookFormInput
            key={id}
            id={id}
            label={label}
            type="number"
            unitLabel="st"
            initialValue={initialValues[id]}
            registerOptions={{ required: true, valueAsNumber: true, min: 0 }}
          />
        ))}
        <FormButtonsFooter
          onClose={onClose}
          handleSubmit={methods.handleSubmit}
          submitSucceeded={false}
          submitting={loading}
        />
      </form>
    </FormProvider>
  );
};

export default AccountPrefsSmsLimitsForm;
