import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import FormButtonsFooter from '@Components/ui/form-buttons-footer';
import DatePickerLabelInput from '@Components/ui/date-picker/date-picker-label-input';
import moment from 'moment';
import SelectForm from '@Components/ui/select/select-form';
import { typeOptions } from '@Components/sysadmin/accounts/constants';
import SelectLocation from '@Components/sysadmin/accounts/select-location';
import omit from 'lodash/omit';

const AccountInvoiceForm = ({ onClose, onSubmit, initialValues }) => {
  const { register, handleSubmit, formState: { errors } } = useForm();

  const handleChangeDate = (type, value) => {
    if (type === 'periodStart') {
      setTimeStart(moment(value));
    } else {
      setTimeEnd(moment(value));
    }
  };

  const [timeStart, setTimeStart] = useState(moment(initialValues.periodStart || undefined));
  const [timeEnd, setTimeEnd] = useState(moment(initialValues.periodEnd || undefined));

  const onSubmitForm = (data) => onSubmit({
    ...data,
    periodStart: moment(timeStart).format('YYYY-MM-DD'),
    periodEnd: moment(timeEnd).format('YYYY-MM-DD')
  });

  const type = omit({ ...register('type') }, 'ref');
  const locationId = omit({ ...register('locationId') }, 'ref');
  const periodStart = omit({ ...register('periodStart') }, 'ref');
  const periodEnd = omit({ ...register('periodEnd') }, 'ref');

  return (
    <div>
      <form autoComplete="off" className="dialog" onSubmit={handleSubmit(onSubmitForm)}>
        <SelectLocation
          {...locationId}
          defaultValue={initialValues.locationId}
        />
        <SelectForm
          {...type}
          labelText="Type"
          options={typeOptions}
          defaultValue={initialValues.type}
        />
        {errors.type && <span className="alert-danger">Required</span>}
        <div className="form-group">
          <DatePickerLabelInput
            {...periodStart}
            label="Period Start"
            date={timeStart}
            handleChangeDate={(date) => handleChangeDate('periodStart', date)}
          />
        </div>
        <div className="form-group">
          <div className="form-control-container">
            <DatePickerLabelInput
              {...periodEnd}
              label="Period End"
              date={timeEnd}
              handleChangeDate={(date) => handleChangeDate('periodEnd', date)}
            />
          </div>
        </div>
        <div className="form-group">
          <div className="form-control-container">
            <label className="control-label" htmlFor="tag">
              Tag
            </label>
            <input
              className="form-control"
              defaultValue={initialValues.tag}
              {...register('tag')}
            />
          </div>
        </div>
        <div className="form-group">
          <div className="form-control-container">
            <label className="control-label" htmlFor="units">
              units
            </label>
            <input
              className="form-control"
              defaultValue={initialValues.units}
              {...register('units', { valueAsNumber: true, required: true })}
            />
            {errors.units && <span className="alert-danger">Required</span>}
          </div>
        </div>
        <div className="form-group">
          <div className="form-control-container">
            <label className="control-label" htmlFor="price">
              price
            </label>
            <input
              className="form-control"
              defaultValue={initialValues.price}
              {...register('price', { valueAsNumber: true, required: true })}
            />
            {errors.price && <span className="alert-danger">Required</span>}
          </div>
        </div>
        <div className="form-group">
          <div className="form-control-container">
            <label className="control-label" htmlFor="altText">
              altText
            </label>
            <input
              className="form-control"
              defaultValue={initialValues.altText}
              {...register('altText')}
            />
          </div>
        </div>
        <FormButtonsFooter
          onClose={onClose}
          handleSubmit={handleSubmit}
          submitSucceeded={false}
          submitting={false}
        />
      </form>
    </div>
  );
};

export default AccountInvoiceForm;
