import { List, fromJS } from 'immutable';
import { CLEAR_LOCATION_STATE } from '@State/account-actions';
import { POS_INVOICES_FETCHED, POS_INVOICE_STATUS_UPDATED } from './invoice-actions';

export function invoices(state = List(), action = null) {
  switch (action.type) {
    case POS_INVOICES_FETCHED:
      return fromJS(action.invoices);

    case POS_INVOICE_STATUS_UPDATED: {
      const index = state.findIndex(g => g.get('id') === action.invoice.id);
      if (index === -1) {
        return state;
      }
      const invoice = state.get(index);
      const { fortnoxInvoiceNumber, fortnoxOcrNumber } = action.invoice;
      return state.set(index, invoice.merge({
        ...action.invoice,
        invoiceNumber: fortnoxInvoiceNumber || invoice.get('invoiceNumber'),
        ocrNumber: fortnoxOcrNumber || invoice.get('ocrNumber')
      }));
    }

    case CLEAR_LOCATION_STATE:
      return state.clear();

    default:
      return state;
  }
}
