import React, { Component } from 'react';
import { connect } from 'react-redux';
import { change, destroy } from 'redux-form';
import { Redirect } from 'react-router';
import { toggleProductsSortable } from '@State/admin-view-reducer';
import Loader from '@Components/ui/loader';
import { firstItemIdInGroup } from '@State/action-helpers';
import {
  fetchProducts,
  fetchProductGroups,
  deleteProductGroup,
  deleteProduct,
  updateProduct,
  updateProductGroup,
  addProductBarcode,
  deleteProductBarcode
} from '@State/products-actions';
import { getSectionUrl } from '@Utils/navigate';
import ProductForm from './product-form';
import ProductList from './product-list';
import SortableListBanner from '../../sortable-list/sortable-list-banner';
import ProductListTools from './product-list-tools';
import ProductsBarcodeListener from './products-barcode-listener';

class Products extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      filter: ''
    };
  }

  componentDidMount() {
    this.props.loadData().then(() => this.setState({ loading: false }));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.productId !== this.props.productId) {
      this.props.resetForm();
    }
  }

  componentWillUnmount() {
    if (this.props.sortable) {
      this.props.toggleSortable(false);
    }
  }

  handleToggleSorting = () => {
    this.props.toggleSortable(!this.props.sortable);
  };

  filterChange = e => {
    this.setState({ filter: e.target.value });
  };

  renderNonSortable() {
    return (
      <div className="columns-header">
        <div className="columns-filter">
          <input
            type="text"
            className="form-control"
            placeholder="Filter"
            onChange={this.filterChange}
          />
        </div>
      </div>
    );
  }

  render() {
    const { loading, filter } = this.state;
    const {
      products,
      product,
      productGroups,
      productId,
      routeParams
    } = this.props;

    if (loading) {
      return <Loader />;
    }

    if (!product && !products.isEmpty()) {
      return (
        <Redirect
          to={getSectionUrl(
            routeParams,
            'admin/products',
            firstItemIdInGroup(productGroups, 'articleIds')
          )}
        />
      );
    }

    return (
      <div className="columns-container">
        <div className="columns-sidebar">
          <div className="columns-header">
            <h1>Produkter</h1>
            <ProductListTools routeParams={this.props.routeParams} />
          </div>
          {this.props.sortable ? (
            <SortableListBanner
              sortable={this.props.sortable}
              onToggleSorting={this.handleToggleSorting}
            />
          ) : (
            this.renderNonSortable()
          )}
          <ProductList
            onDeleteGroup={this.props.deleteProductGroup}
            onUpdateGroup={this.props.updateProductGroup}
            routeParams={routeParams}
            selectedId={productId}
            sortable={this.props.sortable}
            filter={filter}
          />
        </div>
        <div className="columns-content">
          <ProductForm
            onSubmit={this.props.updateProduct}
            onAddBarcode={this.props.addProductBarcode}
            onDeleteBarcode={this.props.deleteProductBarcode}
            onDelete={this.props.deleteProduct}
            productId={productId}
            product={product}
            initialValues={product}
          />
        </div>
        <ProductsBarcodeListener routeParams={routeParams} />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { products, productGroups, adminViewState } = state;
  const routeParams = ownProps.match.params;
  const productId = parseInt(ownProps.match.params.id);

  const product = productId && products && products.get(productId);

  return {
    productId,
    product,
    routeParams,
    products,
    productGroups,
    sortable: adminViewState.get('productsSortable')
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadData: () => Promise.all([
    dispatch(fetchProducts()),
    dispatch(fetchProductGroups())
  ]),
  updateProduct: product => dispatch(updateProduct(product)),
  addProductBarcode: (productId, barcode) => dispatch(addProductBarcode(productId, barcode)),
  deleteProductBarcode: (productId, barcode) => dispatch(deleteProductBarcode(productId, barcode)),
  deleteProduct: productId => dispatch(deleteProduct(productId)),
  updateProductGroup: group => dispatch(updateProductGroup(group)),
  deleteProductGroup: groupId => dispatch(deleteProductGroup(groupId)),
  resetForm: () => dispatch(destroy('product-form')),
  updateFormQuantity: (quantity) => dispatch(change('product-form', 'stockQuantity', quantity)),
  toggleSortable: sortable => dispatch(toggleProductsSortable(sortable))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Products);
