import React, { Component } from 'react';
import { connect } from 'react-redux';
import { pos } from '@Utils/preference-keys';
import { getJsonPreferences, mergeJsonPreferences, getInitialValues } from '@State/preferences-actions';
import PosConfigForm from './pos-config-form';

class PosConfig extends Component {
  componentDidMount() {
    this.props.getPosConfig();
  }

  render() {
    const { initialValues, savePosConfig } = this.props;

    return (
      <div className="subnav-container">
        <PosConfigForm
          initialValues={initialValues}
          onSubmit={savePosConfig}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    initialValues: getInitialValues(state, pos)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPosConfig: () => {
      dispatch(getJsonPreferences());
    },
    savePosConfig: (values) => {
      return dispatch(mergeJsonPreferences(values));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PosConfig);
