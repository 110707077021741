import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { fromJS } from 'immutable';
import { useDispatch, useSelector } from 'react-redux';

import { getOrgWideCustomerDb } from '@State/selectors';
import { fetchInvoice } from '@State/invoice-actions';
import { TitleBlock, PanelWrapper, RowFormGroups } from '@Components/customers/customer-styles';
import { Column, FormGroup } from '@Components/ui/styled/main';
import CustomerDetailSaleSpecification from '@Components/customers/customer-detail/sales/customer-detail-sale-specification';
import Loader from '@Components/ui/loader';
import { getInvoiceStatusLabel } from '@Utils/invoice-util';

const CustomerDetailInvoice = ({ selectedSale }) => {
  const dispatch = useDispatch();
  const [invoice, setInvoice] = useState(null);
  const [loading, setLoading] = useState(false);

  const orgWideCustomerDb = useSelector(getOrgWideCustomerDb);
  const locationNames = useSelector(state => state.locationNames);

  useEffect(() => {
    const { posOrgId, saleInvoiceId } = selectedSale;
    setLoading(true);
    dispatch(fetchInvoice(posOrgId, saleInvoiceId))
      .then((invoice) => setInvoice(invoice))
      .finally(() => setLoading(false));
  }, [selectedSale]);

  if (loading) {
    return <Loader topPosition />;
  }

  const {
    invoiceDate, invoiceDueDate, invoiceNumber, provider, providerId,
    invoiceItems, incVatAmount, pdfUrl, locationId
  } = invoice || {};

  return (
    <>
      <TitleBlock>Faktura</TitleBlock>
      <PanelWrapper defaultCursor>
        {orgWideCustomerDb && (
          <RowFormGroups>
            <FormGroup
              labelText="Plats"
              text={locationNames.get(locationId) || '-'}
            />
          </RowFormGroups>
        )}
        <RowFormGroups>
          <Column size="50">
            <FormGroup
              labelText="Fakturanummer"
              text={provider === 'Fortnox' ? (
                <>
                  <img src="/fortnox-icon.png" className="invoice-provider" />
                  {providerId}
                </>
              ) : invoiceNumber}
            />
          </Column>
          <Column size="50">
            <FormGroup
              labelText="Status"
              text={getInvoiceStatusLabel(invoice || {})}
            />
          </Column>
        </RowFormGroups>
        <RowFormGroups>
          <Column size="50">
            <FormGroup
              labelText="Fakturadatum"
              text={moment(invoiceDate).format('L')}
            />
          </Column>
          <Column size="50">
            <FormGroup
              labelText="Förfallodatum"
              text={moment(invoiceDueDate).format('L')}
            />
          </Column>
        </RowFormGroups>
      </PanelWrapper>
      <TitleBlock>Specifikation</TitleBlock>
      <PanelWrapper defaultCursor>
        <CustomerDetailSaleSpecification
          items={fromJS(invoiceItems)}
          paidAmount={incVatAmount}
        />
      </PanelWrapper>
      {pdfUrl && (
        <PanelWrapper defaultCursor>
          <a href={pdfUrl} target="_blank">
            <i className="fal fa-file-alt mr1" />
            Ladda ner faktura
          </a>
        </PanelWrapper>
      )}
    </>
  );
};

export default CustomerDetailInvoice;
