import { defineMessages } from 'react-intl';

export default defineMessages({
  txtConfirmCancel: {
    id: 'calendar.bkf.cancel.txtConfirmCancel',
    defaultMessage: 'Cancel booking?'
  },
  txtOverrideCancellation: {
    id: 'calendar.bkf.cancel.txtOverrideCancellation',
    defaultMessage: 'Note that the time of the booking has passed the cancellation policy'
  },
  lblSendConfirmation: {
    id: 'calendar.bkf.cancel.lblSendConfirmation',
    defaultMessage: 'Send confirmation to:'
  },
  lblRefundPayment: {
    id: 'calendar.bkf.cancel.lblRefundPayment',
    defaultMessage: 'Refund/credit payment'
  },
  lblDeleteBooking: {
    id: 'calendar.bkf.cancel.lblDeleteBooking',
    defaultMessage: 'Delete booking'
  },
  btnCancel: {
    id: 'calendar.bkf.cancel.btnCancel',
    defaultMessage: 'Back'
  },
  btnConfirm: {
    id: 'calendar.bkf.cancel.btnConfirm',
    defaultMessage: 'Confirm cancellation'
  },
  btnCancellingProgress: {
    id: 'calendar.bkf.cancel.btnCancellingProgress',
    defaultMessage: 'Cancelling booking...'
  }
});
