import { defineMessages } from 'react-intl';

export default defineMessages({
  lblIncVat: {
    id: 'calendar.bkf.price.lblIncVat',
    defaultMessage: '(inc. VAT)'
  },
  lblExVat: {
    id: 'calendar.bkf.price.lblExVat',
    defaultMessage: 'Ex. VAT'
  },
  lblVat: {
    id: 'calendar.bkf.price.lblVat',
    defaultMessage: 'VAT'
  },
  lblServices: {
    id: 'calendar.bkf.price.lblServices',
    defaultMessage: 'Services'
  },
  lblProducts: {
    id: 'calendar.bkf.price.lblProducts',
    defaultMessage: 'Products'
  },
  lblDiscount: {
    id: 'calendar.bkf.price.lblDiscount',
    defaultMessage: 'Discount'
  },
  lblPrice: {
    id: 'calendar.bkf.price.lblPrice',
    defaultMessage: 'Price'
  },
  lblPriceToPay: {
    id: 'calendar.bkf.price.lblPriceToPay',
    defaultMessage: 'Price to pay'
  },
  btnClose: {
    id: 'calendar.bkf.price.btnClose',
    defaultMessage: 'Close'
  },
  btnAddPrice: {
    id: 'calendar.bkf.price.btnAddPrice',
    defaultMessage: 'Add price'
  }
});
