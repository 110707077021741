import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from '@Components/ui/modal';
import { dismissOnboarding } from '@State/account-actions';
import { getShowFeaturesOnboarding } from '@State/selectors';
import FeaturesConfig from '../admin/features-config';

class FeaturesOnboardingModal extends Component {
  render() {
    const { showOnboarding, dismissOnboarding } = this.props;

    return (
      <Modal
        titleText="Error"
        underlayClickExits={false}
        includeDefaultStyles={false}
        dialogClass="dialog-content onboarding"
        underlayClass="dialog-container"
        initialFocus=".dialog-content"
        onExit={dismissOnboarding}
        mounted={showOnboarding}
      >
        <div>
          <h2>Välkommen till Cliento!</h2>
          <p className="lead">
            Välj tillval för att  anpassa Cliento till din verksamhet.
          </p>
          <FeaturesConfig modal />
          <p className="text-muted">
            Svårt att bestämma nu? Slå på eller av tillvalen senare under Inställningar - Tillval.
          </p>
          <div className="text-center">
            <br />
            <button onClick={dismissOnboarding} className="arrow">Börja använda Cliento</button>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    showOnboarding: getShowFeaturesOnboarding(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dismissOnboarding: () => {
      dispatch(dismissOnboarding());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FeaturesOnboardingModal);
