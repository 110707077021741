import React from 'react';
import styled from 'styled-components';
import ModalDialog from '@Components/dialogs/modal-dialog';
import Panel from '@Components/ui/styled/panel';
import SupplierProductInfo from '../products/supplier-product-info';

const DialogTitle = styled.div`
  font-size: 15px;
  font-weight: 600;
  padding: 10px 0;
  margin-bottom: 10px;
  text-align: center;
`;

const ConfirmAddProduct = ({ supplierProduct, onAddProduct, onClose }) => {
  return (
    <ModalDialog
      onEnter={onAddProduct}
      onClose={onClose}
      hideCloseButton
      buttons={[{
        name: 'Lägg till produkt',
        primary: true,
        onClick: onAddProduct
      }, {
        name: 'Avbryt',
        gray: true,
        onClick: onClose
      }]}
    >
      <DialogTitle>
        <p>Ny produkt</p>
        <p>Vill du lägga till produkten?</p>
      </DialogTitle>
      {supplierProduct ? (
        <SupplierProductInfo supplierProduct={supplierProduct} />
      ) : (
        <Panel>
          <p className="mt2 mb2">
            Produktinfo saknas för denna streckkod
          </p>
        </Panel>
      )}
    </ModalDialog>
  );
};

export default ConfirmAddProduct;
