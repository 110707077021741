import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import DatePicker, { registerLocale } from 'react-datepicker';
import { DATE_CALENDAR_PICKER_FORMAT } from '@Utils/time-constants';
import sv from 'date-fns/locale/sv';
import { classes } from '../utils';

registerLocale('sv', sv);

const DatePickerCalendarInput = ({
  name, minDate, maxDate, filterDate, date, onChange, onFocus, disabled, className
}) => {
  const classNames = classes({ 'datepicker-input': true, [className]: true });
  return (
    <div className={classNames}>
      <DatePicker
        preventOpenOnFocus
        className="form-control"
        selected={date?.toDate()}
        maxDate={maxDate}
        minDate={minDate}
        previousMonthButtonLabel=""
        nextMonthButtonLabel=""
        fixedHeight
        name={name}
        disabled={disabled}
        dateFormat={DATE_CALENDAR_PICKER_FORMAT}
        showWeekNumbers
        locale={moment.locale()}
        popperClassName="calendar-popout"
        onChange={onChange}
        onFocus={onFocus}
        filterDate={filterDate}
      />
      <span className="input-modern-style-icon input-group-addon">
        <i
          className="far fa-calendar-alt datepicker-input-icon"
          aria-hidden="true"
        />
      </span>
    </div>
  );
};

DatePickerCalendarInput.propTypes = {
  date: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
};

export default DatePickerCalendarInput;
