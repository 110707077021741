import { defineMessages } from 'react-intl';

export default defineMessages({
  genderMaleLabel: {
    id: 'utils.customerUtil.maleLabel',
    defaultMessage: 'Male'
  },
  genderFemaleLabel: {
    id: 'utils.customerUtil.femaleLabel',
    defaultMessage: 'Female'
  },
  genderDefault: {
    id: 'utils.customerUtil.genderDefault',
    defaultMessage: 'None'
  },
  channelWebLabel: {
    id: 'utils.customerUtil.channelWebLabel',
    defaultMessage: 'Web booking'
  },
  channelCalLabel: {
    id: 'utils.customerUtil.channelCalLabel',
    defaultMessage: 'Calendar'
  },
  smsPreReminderLabel: {
    id: 'utils.customerUtil.smsPreReminderLabel',
    defaultMessage: 'Reminder SMS'
  },
  smsPostReminder1Label: {
    id: 'utils.customerUtil.smsPostReminder1Label',
    defaultMessage: 'Follow up SMS #1'
  },
  smsPostReminder2Label: {
    id: 'utils.customerUtil.smsPostReminder2Label',
    defaultMessage: 'Follow up SMS #2'
  },
  smsStatusSENT: {
    id: 'utils.customerUtil.smsStatusSENT',
    defaultMessage: 'Sent -'
  },
  smsStatusDELIVERED: {
    id: 'utils.customerUtil.smsStatusDELIVERED',
    defaultMessage: 'Delivered -'
  },
  smsStatusDELIVERY_FAILED: {
    id: 'utils.customerUtil.smsStatusDELIVERY_FAILED',
    defaultMessage: 'Unable to deliver -'
  },
  smsStatusDELIVERY_UNKNOWN: {
    id: 'utils.customerUtil.smsStatusDELIVERY_UNKNOWN',
    defaultMessage: 'Unknown delivery status -'
  },
  smsStatusSEND_REJECTED: {
    id: 'utils.customerUtil.smsStatusSEND_REJECTED',
    defaultMessage: 'Unable to send -'
  }
});
