import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { payment } from '@Utils/preference-keys';
import SubmitButton from '@Components/ui/submit-button';
import {
  createValidator, required, number, minValue, maxValue, maxLength
} from '@Utils/validation';
import TextInputHorizontal from '../../inputs/text-input-horizontal';
import TagsInfo from '../../inputs/tags-info';
import PosUnitSelectField from './pos-unit-select-field';

const stylesTextBlock = { marginTop: 5, marginBottom: 10 };

class PaymentConfigForm extends Component {
  normalizeStatement = (value) => {
    return value && value.replace(/['"<>]*/, '');
  };

  render() {
    const { ecomPosUnits, handleSubmit } = this.props;

    return (
      <form autoComplete="off" className="form-horizontal" onSubmit={handleSubmit}>

        <div className="row">
          <div className="col-sm-offset-3 col-sm-6">
            <h3>Inställningar för betalning</h3>
          </div>
        </div>

        <Field name={payment.sellerWeb} component={TextInputHorizontal} label="Webbplats" />
        <Field name={payment.sellerEmail} component={TextInputHorizontal} label="Mejl" />

        <div className="row">
          <div className="col-sm-offset-3 col-sm-6">
            <p className="text-muted">
              Uppgifterna visas i sidfoten på kvitton.
              Företagsuppgifter och adress hämtas från inställningar för kassaregister.
            </p>
          </div>
        </div>

        <hr />
        <Field name={payment.logoUrl} component={TextInputHorizontal} label="Adress till logotyp" />
        <div className="row">
          <div className="col-sm-offset-3 col-sm-9">
            <p className="text-muted">
              Logotypen måste vara i JPG- eller PNG-format och bör vara minst 500 pixlar bred.
              <br />
              Om ingen logotyp anges visas Clientos logotyp.
            </p>
          </div>
        </div>

        <hr />

        <Field
          name={payment.statementDescriptor}
          component={TextInputHorizontal}
          label="Text på kontoutdrag"
          normalize={this.normalizeStatement}
        />
        <div className="row">
          <div className="col-sm-offset-3 col-sm-5">
            <p className="text-muted">
              Visas på kundens kontoutdrag. Max 22 tecken, kan inte innehålla specialtecken som
              &lt;, &gt;, ", ', och får inte innehålla endast siffror.
            </p>
          </div>
        </div>

        <hr />

        <Field
          name={payment.chargeDescription}
          component={TextInputHorizontal}
          label="Beskrivning för bokföring"
        />
        <div className="row">
          <div className="col-sm-offset-3 col-sm-5">
            <TagsInfo booking customer />
          </div>
        </div>

        <hr />

        <div className="form-group">
          <label className="col-sm-3 control-label">Momssats</label>
          <div className="col-sm-4">
            <Field
              name={payment.defaultVatPct}
              component="input"
              type="number"
              className="form-control inline-sm"
              parse={val => val && parseInt(val)}
              min={0}
              max={100}
            />
            <p className="form-control-static inline-md">%</p>
          </div>
        </div>
        <div className="form-group">
          <div className="col-sm-offset-3 col-sm-4">
            <div className="checkbox">
              <label>
                <Field name={payment.showVat} component="input" type="checkbox" />
                Visa momsspecifikation på kvitto
              </label>
            </div>
            <div className="checkbox">
              <label>
                <Field name={payment.pricesExVat} component="input" type="checkbox" />
                Priser anges exklusive moms
              </label>
            </div>
          </div>
        </div>

        <hr />

        <div className="form-group">
          <label className="col-sm-3 control-label">Betalningsmottagare</label>
          <div className="col-sm-5">
            <PosUnitSelectField />
            <p className="text-muted" style={stylesTextBlock}>
              Standardinställning för mottagare av onlinebetalningar.
              Detta kan även ställas in per resurs.
            </p>
          </div>
        </div>

        <hr />

        <div className="form-group">
          <div className="col-sm-offset-3 col-sm-5">
            <SubmitButton {...this.props} />
          </div>
        </div>
      </form>
    );
  }
}

const validator = createValidator({
  [payment.sellerName]: [required],
  [payment.sellerVatNo]: [required],
  [payment.addressLine1]: [required],
  [payment.postCode]: [required],
  [payment.area]: [required],
  [payment.statementDescriptor]: [required, maxLength(22)],
  [payment.defaultVatPct]: [required, number, minValue(0), maxValue(100)]
});

export default reduxForm({
  form: 'payment-config',
  validate: validator,
  enableReinitialize: true
})(PaymentConfigForm);
