import React from 'react';
import PropTypes from 'prop-types';

const SelectForm = ({ labelText, options = [], ...props }) => {
  return (
    <div className="form-group">
      <label className="control-label">{labelText}</label>
      <div className="select-container">
        <select
          {...props}
          className="form-control"
        >
          {options.map((opt) => (
            <option key={opt.value} value={opt?.value}>{opt?.text}</option>
          ))}
        </select>
      </div>
    </div>
  );
};

SelectForm.propTypes = {
  options: PropTypes.array,
  labelText: PropTypes.string,
  onChangeSelect: PropTypes.func
};

export default SelectForm;
