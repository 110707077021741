import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getTrialStatus } from '@State/selectors';
import { isCustomerFeedbackDismissed, saveCustomerFeedbackDismissed } from '@Utils/local-settings';
import Modal from '@Components/ui/modal';

const CustomerFeedbackBanner = () => {
  const trialStatus = useSelector(getTrialStatus);

  const [showBanner, setShowBanner] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const toggleBanner = () => {
    setShowBanner(!showBanner);

    if (showBanner) {
      saveCustomerFeedbackDismissed();
    }
  };

  const toggleModal = () => {
    setShowModal(!showModal);

    if (!showModal) {
      toggleBanner();
    }
  };

  if (trialStatus === 'Trial') {
    return null;
  }

  return (
    <div className="customer-feedback-container">
      <button className="customer-feedback-button" onClick={toggleBanner}>
        <i className="fa fa-comments-question-check" />
      </button>
      {showBanner && (
        <div className="customer-feedback-banner">
          <i className="fa fa-comments-question-check" />
          <div>
            <button className="close" onClick={toggleBanner}>
              <i className="fa fa-times" />
            </button>
            <h4>Har du feedback?</h4>
            <p>
              Vi tar gärna emot förslag på förbättringar och nya funktioner.
              Skriv till oss på chatten!
            </p>
            {/*
            <h4>Vilka funktioner saknar du i Cliento?</h4>
            <p>
              Nu kan du vara med och påverka vilka funktioner vi prioriterar i vår roadmap.
            </p>
            <button className="btn-modern" onClick={toggleModal}>
              Rösta på förslag
            </button>
            */}
          </div>
        </div>
      )}
      {showModal && (
        <Modal
          titleText=" "
          focusTrapPaused
          verticallyCenter
          underlayClickExits
          onExit={toggleModal}
        >
          <div className="customer-feedback-modal">
            <button onClick={toggleModal} className="customer-feedback-modal-close">
              <i className="fa fa-times" />
            </button>
            <iframe
              tabIndex={0}
              title="Tyck till om Cliento"
              className="customer-feedback-modal-iframe"
              src="https://cliento.featureupvote.com/"
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default CustomerFeedbackBanner;
