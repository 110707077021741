import React, { useState } from 'react';
import { connect } from 'react-redux';
import { DropDownItem, DropdownHeader } from '@Components/ui/drop-down/dropdown';
import TBDropdown from '@Components/ui/tb-dropdown';
import { txt } from '@Utils/i18n-util';
import msg from './date-navigator-skip-weeks.msg';

const buttonStyle = { marginLeft: 10 };

const WeekItems = ({ txt, onClick }) => {
  const weeks = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  return weeks.map(week => {
    return (
      <DropDownItem key={week} onClick={() => onClick(week)}>
        {week === 1
          ? txt(msg.week, { week })
          : txt(msg.weeks, { week })}
      </DropDownItem>
    );
  });
};

const DateNavigatorSkipWeeks = ({ phoneMode, tabletMode, onClick }) => {
  const [open, setOpen] = useState(false);

  const handleClick = (week) => {
    onClick(week);
    setOpen(false);
  };

  return (
    <TBDropdown
      small
      icon="fa-calendar-plus"
      style={buttonStyle}
      phoneMode={phoneMode}
      tabletMode={tabletMode}
      open={open}
      setOpen={setOpen}
    >
      <DropdownHeader>{txt(msg.skipWeeks)}</DropdownHeader>
      <WeekItems txt={txt} onClick={handleClick} />
    </TBDropdown>
  );
};

const mapStateToProps = (state) => {
  const { mainViewState } = state;
  return {
    tabletMode: mainViewState.get('tabletMode'),
    phoneMode: mainViewState.get('phoneMode')
  };
};

export default connect(mapStateToProps)(DateNavigatorSkipWeeks);
