import React from 'react';
import { isMobile } from '@Utils/phone-number-parser';
import { formatPhoneNumber } from '@Utils/phone-util';
import useMarkdownToHtml from '@Hooks/useMarkdownToHtml';
import LabelTextGroup from './label-text-group';

const CampaignContentPanel = ({ campaign, children = null }) => {
  const emailTextHtml = useMarkdownToHtml(campaign?.get('emailText'));

  const campaignObj = campaign?.toJS() || {};
  const { type, smsText, emailSubject, smsSender, emailFromName, emailReplyTo } = campaignObj;
  const smsSenderName = isMobile(smsSender) ? formatPhoneNumber(smsSender) : smsSender;

  return (
    <div className="campaign-content select-text">
      {children}
      {type === 'Sms' && (
        <>
          <LabelTextGroup label="Avsändare">
            <strong>{smsSenderName || 'Cliento'}</strong>
          </LabelTextGroup>
          <LabelTextGroup label="Meddelande">
            {smsText}
          </LabelTextGroup>
        </>
      )}
      {type === 'Email' && (
        <>
          <LabelTextGroup label="Avsändare">
            <strong>{emailFromName || 'Cliento'}</strong>{' '}
            {emailReplyTo && <span>&lt;{emailReplyTo}&gt;</span>}
          </LabelTextGroup>
          <LabelTextGroup label="Ämne">
            <strong>{emailSubject}</strong>
          </LabelTextGroup>
          <LabelTextGroup className="mt2" label="Meddelande">
            {/* eslint-disable-next-line react/no-danger */}
            <div dangerouslySetInnerHTML={{ __html: emailTextHtml }} />
          </LabelTextGroup>
        </>
      )}
    </div>
  );
};

export default CampaignContentPanel;
