import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useKeyPress from '@Hooks/useKeyPress';
import { toggleBKFModal } from '@State/bkf/actions';
import { bkfModals } from '@State/bkf/constants';
import {
  vehicleAttributeValue, vehicleOwnerFields, vehicleBaseFields, vehiclePreferenceKeys, isOwnerUnknown
} from '@Utils/vehicle-util';
import Modal from '@Components/ui/modal';
import { DialogTitleBtn } from '@Components/dialogs/dialog-styles';
import Button from '@Components/ui/button';

function renderTableRow(key, value) {
  return value != null ? (
    <tr key={key}>
      <td><strong>{vehiclePreferenceKeys[key] || key}</strong></td>
      <td className="text-right">{vehicleAttributeValue(key, value)}</td>
    </tr>
  ) : null;
}

const VehicleModal = () => {
  const dispatch = useDispatch();
  const vehicle = useSelector(({ bkf }) => bkf.get('vehicle'));
  const { vehicleRegNo, vehicleDescription, vehicleAttributes } = vehicle || {};

  const onCloseForm = () => {
    dispatch(toggleBKFModal(bkfModals.vehicle, false));
  };

  useKeyPress(27, onCloseForm);

  return (
    <Modal
      titleText="Fordon"
      focusTrapPaused
      focusDialog
      underlayClickExits={false}
      mounted
      dialogClass="modal-dialog"
    >
      <div className="modal-content">
        <div className="modal-body">
          <div className="pull-right">
            <DialogTitleBtn onClick={onCloseForm}>Stäng</DialogTitleBtn>
          </div>
          <h4 className="mt0 mb0">{vehicleRegNo}</h4>
          <p className="text-muted">{vehicleDescription}</p>
          {!isOwnerUnknown(vehicleAttributes) && (
            <>
              <h5>Ägare</h5>
              <table className="table table-striped">
                <tbody>
                  {vehicleOwnerFields.map((key) => {
                    return renderTableRow(key, vehicleAttributes[key]);
                  })}
                </tbody>
              </table>
            </>
          )}
          <h5>Fordonsdata</h5>
          <table className="table table-striped">
            <tbody>
              {vehicleBaseFields.map((key) => {
                return renderTableRow(key, vehicleAttributes[key]);
              })}
            </tbody>
          </table>
          <h5>Övrig teknisk data</h5>
          <table className="table table-striped">
            <tbody>
              {Object.entries(vehicleAttributes).map(([key, value]) => {
                if (vehicleOwnerFields.indexOf(key) !== -1 || vehicleBaseFields.indexOf(key) !== -1) {
                  return null;
                }
                return renderTableRow(key, value);
              })}
            </tbody>
          </table>
          <div className="text-right">
            <Button gray small onClick={onCloseForm}>Stäng</Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default VehicleModal;
