import React, { memo } from 'react';
import PropTypes from 'prop-types';

const RadioLabel = ({
  value, checkedValue, handleToggle, label
}) => (
  <div className="radio">
    <label>
      <input
        type="radio"
        value={value}
        checked={checkedValue === value}
        onChange={handleToggle}
      />
      {label}
    </label>
  </div>
);

RadioLabel.propTypes = {
  value: PropTypes.string,
  checkedValue: PropTypes.string,
  label: PropTypes.string,
  handleToggle: PropTypes.func
};

export default memo(RadioLabel);
