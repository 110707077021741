import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSysAdmin } from '@State/selectors';
import { getPosOrg } from '@State/pos-selectors';
import { downloadReceiptSie, resetReceiptCopyCount } from '@State/pos-actions';
import { Button } from '@Components/ui/styled/main';
import ProgressButton from '@Components/ui/progress-button';
import Popover from '@Components/ui/popover';
import ReceiptAccountingModal from './receipt-accounting-modal';

const ReceiptTools = ({ receipt }) => {
  const dispatch = useDispatch();
  const posOrg = useSelector(getPosOrg);
  const isSysAdmin = useSelector(getSysAdmin);

  const [showTools, setShowTools] = useState(false);
  const [showAccounting, setShowAccounting] = useState(false);

  const onDownloadSie = () => {
    return dispatch(downloadReceiptSie(posOrg.get('id'), receipt.get('receiptId')))
      .then(() => setShowTools(false));
  };

  const onResetCount = () => {
    return dispatch(resetReceiptCopyCount(posOrg.get('id'), receipt.get('receiptId')))
      .then(() => setShowTools(false));
  };

  const onShowAccounting = () => {
    setShowTools(false);
    setShowAccounting(true);
  };

  const toolsPopoverBody = (
    <div className="Popover-dialog-sm button-wrapper">
      <div className="menu-button-wrapper">
        <Button small gray block onClick={onShowAccounting}>Visa bokföring</Button>
        <ProgressButton small gray block onClick={onDownloadSie}>Ladda ner SIE-fil</ProgressButton>
        {receipt.get('copyCount') > 0 && (
          <ProgressButton small gray block onClick={onResetCount}>Tillåt kopia igen</ProgressButton>
        )}
      </div>
    </div>
  );

  return isSysAdmin ? (
    <>
      <Popover
        preferPlace="below"
        isOpen={showTools}
        onOuterAction={() => setShowTools(false)}
        body={toolsPopoverBody}
        className="Popover-big-radius"
      >
        <Button gray small marginLeft onClick={() => setShowTools(!showTools)}>Verktyg</Button>
      </Popover>
      {showAccounting && (
        <ReceiptAccountingModal
          posOrg={posOrg}
          receipt={receipt}
          onClose={() => setShowAccounting(false)}
        />
      )}
    </>
  ) : null;
};

export default ReceiptTools;
