import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from '@Components/ui/button';
import SubmitButton from '@Components/ui/submit-button';
import { Flex } from '@Components/ui/styled/main';
import { txt } from '@Utils/i18n-util';
import msg from './form-buttons-footer.msg';

const FormButtonsStyled = styled(Flex)`
  margin-top: 30px;
  button:not(:last-child) {
    margin-right: 10px;
  }
`;

const FormButtonsFooter = ({
  onClose, handleSubmit, pristine, submitting, submitSucceeded,
  submitText, alwaysEnabled, cancelText, submitDisabled
}) => (
  <FormButtonsStyled right>
    <Button gray small onClick={onClose}>{cancelText || txt(msg.btnCancel)}</Button>
    <SubmitButton
      disabled={(pristine && !alwaysEnabled) || submitDisabled}
      submitting={submitting}
      submitSucceeded={submitSucceeded}
      onClick={handleSubmit}
    >
      {submitText}
    </SubmitButton>
  </FormButtonsStyled>
);

FormButtonsFooter.propTypes = {
  onClose: PropTypes.func,
  handleSubmit: PropTypes.func
};

export default FormButtonsFooter;
