import React, { useState } from 'react';
import ModalDialog from '@Components/dialogs/modal-dialog';
import ConfirmPopover from '@Components/ui/confirm-popover';
import Button from '@Components/ui/button';
import AlertWithIcon from '@Components/ui/alert-with-icon';

const ProductBarcodeModal = ({
  EANs, onClose, onDelete, onAddBarcode
}) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const hasEan = EANs?.length > 0;

  return (
    <ModalDialog
      contentSize="small"
      onClose={onClose}
      title="Streckkoder"
      closeOnClickOutside={false}
      closeButtonText="Stäng"
      focusTrapPaused
    >
      {hasEan ? (
        <table className="table table-striped select-text">
          <tbody>
            {EANs.map(ean => {
              return (
                <tr key={ean}>
                  <td>{ean}</td>
                  <td className="text-right">
                    <ConfirmPopover
                      text={`Ta bort streckkod ${ean}?`}
                      confirmText="Ta bort"
                      onClose={() => setShowConfirm(null)}
                      onConfirm={() => onDelete(ean)}
                      isOpen={showConfirm === ean}
                    >
                      <Button tiny gray onClick={() => setShowConfirm(ean)}>Ta bort</Button>
                    </ConfirmPopover>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <AlertWithIcon warning>
          Det finns inga streckkoder för denna produkt
        </AlertWithIcon>
      )}
      <Button gray small marginTop onClick={onAddBarcode}>Lägg till streckkod</Button>
    </ModalDialog>
  );
};

export default ProductBarcodeModal;
