import React, { useEffect } from 'react';
import styled from 'styled-components';
import useKeyPress from '@Hooks/useKeyPress';
import { useDispatch } from 'react-redux';
import { toggleBKFModal } from '@State/bkf/actions';
import { bkfModals } from '@State/bkf/constants';

const ImageOverlay = styled.div`
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.8);
  padding: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100000;
  cursor: default;

  button {
    position: absolute;
    top: 40px;
    right: 40px;
    background: transparent;
    border: 0;
    outline: 0;
    color: #fff;
    height: 30px;
    width: 30px;

    i {
      color: rgba(255, 255, 255, 0.7);
      font-size: 22px;
    }
    &:hover i {
      color: rgba(255, 255, 255, 0.9);
    }
  }

  img {
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
  }
`;

const CustomerDetailLogImageOverlay = ({ image, onClose, onPrevious, onNext }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(toggleBKFModal(bkfModals.image, true));

    return () => {
      dispatch(toggleBKFModal(bkfModals.image, false));
    };
  }, []);

  const hideImage = (ev) => {
    if (typeof ev?.stopPropagation === 'function') {
      ev.stopPropagation();
    }
    onClose();
  };

  useKeyPress(27, hideImage);
  useKeyPress(37, onPrevious, [image]);
  useKeyPress(39, onNext, [image]);

  return (
    <ImageOverlay onClick={(ev) => ev.stopPropagation()}>
      <button tabIndex={-1} onClick={hideImage}>
        <i className="fa fa-times" />
      </button>
      <img src={image.url} />
    </ImageOverlay>
  );
};

export default CustomerDetailLogImageOverlay;
