import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import CreatePosOrgDialog from '@Components/admin/pos-config/create-pos-org-dialog';
import { createPosOrg } from '@State/pos-config-actions';
import { getPosOrgUrl, navigate } from '@Utils/navigate';

const PosToolbar = ({ routeParams }) => {
  const [showDialog, setShowDialog] = useState(false);
  const dispatch = useDispatch();

  const onHideDialog = () => setShowDialog(false);
  const onShowDialog = () => setShowDialog(true);

  const onCreatePosOrg = (data) => {
    return dispatch(createPosOrg(data))
      .then(({ id }) => navigate(getPosOrgUrl(routeParams, id)))
      .then(() => onHideDialog());
  };

  return (
    <>
      <div>
        <button type="button" className="btn btn-cta" onClick={onShowDialog}>Nytt företag</button>
      </div>
      {showDialog && <CreatePosOrgDialog onHideDialogs={onHideDialog} onCreatePosOrg={onCreatePosOrg} />}
    </>
  );
};

export default PosToolbar;
