import React from 'react';
import ReactDataSheet from 'react-datasheet';

const columns = [
  { id: 'account', width: 200, name: 'Konto', itemClass: 'text-left' },
  { id: 'debit', width: 100, name: 'Debit' },
  { id: 'credit', width: 100, name: 'Kredit' },
  { id: 'costCenter', width: 100, name: 'KS', itemClass: 'text-left' },
  { id: 'coacode', width: 100, name: 'BAS-konto', itemClass: 'text-left' },
  { id: 'formattedTransactionInfo', width: 200, name: 'Beskrivning', itemClass: 'text-left' }
];

function getGrid(rows) {
  const grid = [];
  if (!rows || rows.length === 0) {
    return grid;
  }

  const header = [];
  columns.forEach(({ name, width }) => {
    header.push({
      readOnly: true,
      value: name,
      overflow: 'clip',
      className: 'col-header',
      width
    });
  });
  grid.push(header);

  rows.forEach(item => {
    const row = [];
    columns.forEach(({ id, itemClass }) => {
      row.push({
        readOnly: true,
        value: item[id],
        overflow: 'clip',
        className: itemClass
      });
    });
    grid.push(row);
  });

  return grid;
}

const AccountingDataSheet = ({ rows }) => {
  const grid = getGrid(rows);

  return (
    <ReactDataSheet
      overflow="clip"
      data={grid}
      valueRenderer={cell => cell.value}
      isCellNavigable={(cell) => cell.className !== 'header'}
    />
  );
};

export default AccountingDataSheet;
