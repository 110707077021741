import moment from 'moment';
import React, { Component } from 'react';
import { getSectionUrl, navigate } from '@Utils/navigate';
import ColumnListItem from '@Components/ui/column/column-list-item';

class StockReportList extends Component {
  onItemClick = item => {
    navigate(getSectionUrl(this.props.routeParams, 'reports/stock', item.get('id')));
  };

  render() {
    const { routeParams, stockReports } = this.props;
    const currentId = parseInt(routeParams.id);

    return (
      <>
        <div className="columns-header">
          <h1>Lagerrapporter</h1>
        </div>
        <div className="columns-list">
          {stockReports && stockReports.map(stockReport => {
            const { id, label, createdTs } = stockReport.toJS();

            return (
              <div key={id}>
                <ColumnListItem
                  onClick={() => this.onItemClick(stockReport)}
                  selected={id === currentId}
                  header={label}
                >
                  {moment(createdTs).format('LL')}
                </ColumnListItem>
              </div>
            );
          })}
        </div>
      </>
    );
  }
}

export default StockReportList;
