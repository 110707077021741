import { defineMessages } from 'react-intl';

export default defineMessages({
  sendSmsTitle: {
    id: 'customers.sendSms.sendSmsTitle',
    defaultMessage: 'Send SMS'
  },
  lblSmsSender: {
    id: 'customers.sendSms.lblSmsSender',
    defaultMessage: 'Sender'
  },
  lblSmsReceiver: {
    id: 'customers.sendSms.lblSmsReceiver',
    defaultMessage: 'Recipient'
  },
  lblSmsMessage: {
    id: 'customers.sendSms.lblSmsMessage',
    defaultMessage: 'Message'
  },
  btnSend: {
    id: 'customers.sendSms.btnSend',
    defaultMessage: 'Send'
  },
  btnClose: {
    id: 'customers.sendSms.btnClose',
    defaultMessage: 'Close'
  },
  smsSentText: {
    id: 'customers.sendSms.smsSentText',
    defaultMessage: 'Message sent successfully'
  },
  smsNotEnabledText: {
    id: 'customers.sendSms.smsNotEnabledText',
    defaultMessage: 'SMS is not enabled for the account'
  }
});
