import React, { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getReportParams } from '@Components/reports/reports-helpers';
import { downloadSalesReportExcel, downloadSalesReportSie, emailSalesReport } from '@State/report-actions';
import SendReportForm from '@Components/pos/reports/send-report-form';
import Popover from '@Components/ui/popover';
import Button from '@Components/ui/button';

const SalesReportToolbar = (props) => {
  const dispatch = useDispatch();
  const [showDownload, setShowDownload] = useState(false);
  const [showSendReport, setShowSendReport] = useState(false);
  const reportParams = useSelector(state => getReportParams(state, props));

  const handleDownloadExcel = useCallback((ev) => {
    ev.preventDefault();
    ev.target.blur();
    const { posOrgId, userId, start, end } = reportParams;
    dispatch(downloadSalesReportExcel(posOrgId, start, end, userId))
      .then(() => setShowDownload(false));
  }, [reportParams]);

  const handleDownloadSie = useCallback((ev) => {
    ev.preventDefault();
    ev.target.blur();
    const { posOrgId, userId, start, end } = reportParams;
    dispatch(downloadSalesReportSie(posOrgId, start, end, userId))
      .then(() => setShowDownload(false));
  }, [reportParams]);

  const handleEmail = useCallback((ev) => {
    ev.preventDefault();
    ev.target.blur();
    setShowSendReport(true);
  }, []);

  const sendEmail = useCallback(({ email }) => {
    const { posOrgId, userId, start, end } = reportParams;
    return dispatch(emailSalesReport(email, posOrgId, start, end, userId));
  }, [reportParams]);

  const popoverBody = (
    <div className="Popover-dialog-sm button-wrapper">
      <div className="menu-button-wrapper">
        <Button small gray block onClick={handleDownloadSie}>
          SIE-fil
        </Button>
        <Button small gray block onClick={handleDownloadExcel}>
          Excel-fil
        </Button>
      </div>
    </div>
  );

  return (
    <>
      <Popover
        preferPlace="below"
        isOpen={showDownload}
        onOuterAction={() => setShowDownload(false)}
        body={popoverBody}
        className="Popover-big-radius"
      >
        <Button onClick={() => setShowDownload(!showDownload)} gray small>Ladda ner</Button>
      </Popover>
      <Button onClick={handleEmail} gray small>Mejla</Button>
      {showSendReport && (
        <SendReportForm
          onClose={() => setShowSendReport(false)}
          onSubmit={sendEmail}
        />
      )}
    </>
  );
};

export default SalesReportToolbar;
