import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import debounce from 'lodash/debounce';

import {
  CUSTOMER_PAGE_SIZE,
  CUSTOMER_SEARCH_DEBOUNCE,
  CUSTOMER_SEARCH_MIN_LENGTH
} from '@Utils/constants';

import {
  searchCustomers,
  fetchCustomersList,
  deleteCustomer,
  customerClearForm
} from '@State/customer-actions';
import { fetchPosOrgs } from '@State/pos-config-actions';
import { fetchGroupsAndResources } from '@State/resource-actions';
import { getFeatures } from '@State/selectors';

import CustomerDetail from '@Components/customers/customer-detail/customer-detail';
import CustomersList from '@Components/customers/customers-list';
import Pagination from '@Components/ui/pagination';
import ColumnSearch from '@Components/ui/column/column-search';
import CustomerIdContext from '@Components/customers/customer-id-context';
import Loader from '@Components/ui/loader';
import { txt } from '@Utils/i18n-util';
import msg from './customers-list.msg';

const CustomersPage = ({ match }) => {
  const dispatch = useDispatch();
  const { EnablePOS } = useSelector(getFeatures);

  const onFetchCustomers = (limit, offset) => dispatch(fetchCustomersList(limit, offset));
  const onDeleteCustomer = (id) => dispatch(deleteCustomer(id));
  const onSearchCustomers = (text) => dispatch(searchCustomers(text));
  const onClearCustomerForm = () => dispatch(customerClearForm());
  const onFetchGroupsAndResources = () => dispatch(fetchGroupsAndResources());
  const onFetchPosOrgs = () => dispatch(fetchPosOrgs());

  const routeParams = { ...match.params };
  const customers = useSelector(state => state.customers);
  const selectedCustomer = useSelector(state => state.customerById).toJS();
  const resourcesById = useSelector(state => state.resourcesById);

  const [customerEntries, setCustomerEntries] = useState(customers.get('customerEntries'));
  const [totalCount, setTotalCount] = useState(customers.get('totalCount'));
  const [loading, setLoading] = useState(true);

  const [customerId, setCustomerId] = useState(routeParams?.id);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchText, setSearchText] = useState('');

  const onSearchCustomersDebounce = useCallback(debounce(onSearchCustomers, CUSTOMER_SEARCH_DEBOUNCE), []);

  const getCustomersAPI = useCallback(async () => {
    setLoading(true);
    try {
      await onFetchCustomers(CUSTOMER_PAGE_SIZE);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    setCustomerEntries(customers.get('customerEntries'));
    setTotalCount(customers.get('totalCount'));
  }, [customers]);

  useEffect(() => {
    if (resourcesById && resourcesById.isEmpty()) {
      onFetchGroupsAndResources();
    }
    if (EnablePOS) {
      onFetchPosOrgs();
    }
    getCustomersAPI();
  }, []);

  useEffect(() => {
    if (+routeParams.id !== +customerId && selectedCustomer.id === undefined) {
      return setCustomerId(routeParams.id);
    }
    if (selectedCustomer && selectedCustomer.id && selectedCustomer.id !== +customerId) {
      return setCustomerId(selectedCustomer.id);
    }
  }, [routeParams.id]);

  const handleConfirmDeleteCustomer = async () => {
    await onDeleteCustomer(customerId);
    onClearCustomerForm();
  };

  const handleSearch = (e) => {
    const text = e.target.value;
    // need if user remove prev search and set empty string get all customers
    if (text === '') getCustomersAPI();

    setSearchText(text);
    if (text.length > CUSTOMER_SEARCH_MIN_LENGTH) {
      onSearchCustomersDebounce(text);
    }
  };

  const handlePageClick = (selectedPage) => {
    const offset = selectedPage * CUSTOMER_PAGE_SIZE;
    setCurrentPage(selectedPage);
    onFetchCustomers(CUSTOMER_PAGE_SIZE, offset);
  };

  const pageCount = Math.ceil(totalCount / CUSTOMER_PAGE_SIZE);
  const isShowPagination = !searchText && pageCount > 1;
  const isShowForm = customerId;

  if (customerEntries && !customerEntries.isEmpty() && !routeParams.id) {
    const cId = customerEntries.first().get('customerId');
    const { org, loc } = routeParams;
    return <Redirect to={`/${org}/${loc}/customers/${cId}/overview`} />;
  }

  if (loading && !customerEntries) {
    return (
      <div className="columns-wrapper">
        <Loader />
      </div>
    );
  }

  return (
    <div className="columns-wrapper">
      <div className="columns-container">
        <div className="columns-sidebar">
          <div className="columns-header">
            <h1>{txt(msg.hdrCustomers)}</h1>
            <span className="customer-count">{txt(msg.lblCount, { count: totalCount || 0 })}</span>
          </div>
          <div className="columns-header">
            <ColumnSearch
              searchValue={searchText}
              onSearch={handleSearch}
              placeholder={txt(msg.lblSearch)}
            />
          </div>
          <CustomersList
            routeParams={routeParams}
            customerEntries={customerEntries}
            selectedCustomerId={customerId}
          />
          {isShowPagination && (
          <Pagination
            pageCount={pageCount}
            handlePageClick={handlePageClick}
            currentPage={currentPage}
          />
          )}
        </div>
        <div className="columns-content">
          <div className="columns-content-container width-medium">
            {isShowForm
              ? (
                <CustomerIdContext.Provider value={+customerId}>
                  <CustomerDetail
                    routeParams={routeParams}
                    onConfirmDelete={handleConfirmDeleteCustomer}
                  />
                </CustomerIdContext.Provider>
              )
              : null}
          </div>
        </div>
      </div>
    </div>
  );
};

CustomersPage.propTypes = {
  onFetchCustomers: PropTypes.func,
  onDeleteCustomer: PropTypes.func,
  onUpdateCustomer: PropTypes.func,
  onCreateCustomer: PropTypes.func,
  onSearchCustomers: PropTypes.func,
  onFetchGroupsAndResources: PropTypes.func
};

export default CustomersPage;
