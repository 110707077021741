import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { getVatRegNo } from '@Utils/i18n-util';
import { createOrgInvoiceCustomer, updatePaymentMethod } from '@State/sysadmin-actions';
import ButtonModalForm from '@Components/dialogs/button-modal-form';
import AccountInvoiceCustomerForm from '../account-invoice-customer-form';

const AccountHeaderBtnInvoiceCustomer = ({ id, subscription }) => {
  const dispatch = useDispatch();

  const initialValues = {
    companyName: subscription.get('orgName'),
    companyRegNo: subscription.get('companyRegNo'),
    companyVatNo: getVatRegNo(subscription.get('companyRegNo'), 'SE'),
    countryCode: 'SE',
    vatType: 'SEVAT',
    invoiceMethod: 'Email',
    invoiceInterval: 'Quaterly',
    invoiceEmail: subscription.getIn(['contacts', 'Billing', 'email'])
      || subscription.getIn(['contacts', 'Owner', 'email'])
  };

  const onSubmitInvoiceCustomer = useCallback(async (values) => {
    await dispatch(updatePaymentMethod({ orgId: id, paymentMethod: 'Invoice' }));
    await dispatch(createOrgInvoiceCustomer({ orgId: id, ...values }));
  }, [id]);

  return (
    <ButtonModalForm
      btnText="Lägg om till faktura"
      onSubmit={onSubmitInvoiceCustomer}
    >
      {({ ...props }) => (
        <AccountInvoiceCustomerForm
          {...props}
          initialValues={initialValues}
        />
      )}
    </ButtonModalForm>
  );
};

export default AccountHeaderBtnInvoiceCustomer;
