import React from 'react';
import { useSelector } from 'react-redux';
import { reportingFormat } from '@Utils/format';
import { getTransactionTypeName } from '@Utils/pos-utils';

const SalesReportCashLedger = ({ ledger }) => {
  const cashiersById = useSelector(state => state.cashiersById);

  return (
    <table className="table table-condensed table-report">
      <thead>
        <tr>
          <th className="col-xs-2">Typ</th>
          <th className="text-right">Belopp</th>
          <th className="col-xs-1" />
          <th>Kassör</th>
          <th className="col-xs-4">Kommentar</th>
        </tr>
      </thead>
      <tbody>
        {ledger.map(({
          id, type, amount, comment, createdBy
        }) => (
          <tr key={id}>
            <td className="child">{getTransactionTypeName(type)}</td>
            <td className="text-right">{reportingFormat(amount)}</td>
            <td />
            <td>{cashiersById?.get(createdBy)?.get('name') || '-'}</td>
            <td>{comment}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default SalesReportCashLedger;
