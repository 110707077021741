import moment from 'moment';
import { pos } from './preference-keys';

export function getInvoiceStatusLabel(invoice) {
  const { status, paidDate, annulledDate, debtCollectionDate } = invoice;
  switch (status) {
    case 'Paid':
      return `Betald ${paidDate ? moment(paidDate).format('L') : ''}`;

    case 'Credited':
      return `Krediterad ${paidDate ? moment(paidDate).format('L') : ''}`;

    case 'Annulled':
      return `Makulerad ${annulledDate ? moment(annulledDate).format('L') : ''}`;

    case 'DebtCollection':
      return `Inkasso ${debtCollectionDate ? moment(debtCollectionDate).format('L') : ''}`;

    default:
      return 'Ej betald';
  }
}

export const getInvoicePrefsValidation = (invoicePrefs) => ({
  useFortnox: invoicePrefs[pos.invoiceUseFortnox],
  hasAccountNumber: invoicePrefs[pos.invoiceBGNumber] || invoicePrefs[pos.invoicePGNumber],
  hasDueDays: invoicePrefs[pos.invoiceDueDays]
});

export const getInvoiceUseFortnoxService = (invoicePrefs) => {
  return invoicePrefs[pos.invoiceUseFortnox] && invoicePrefs[pos.invoiceFortnoxService]
    && invoicePrefs[pos.invoiceFortnoxService] !== 'NONE';
};

export const getInvoiceUseEmailConfig = (invoicePrefs) => {
  const useFortnox = invoicePrefs[pos.invoiceUseFortnox];
  const useFortnoxService = getInvoiceUseFortnoxService(invoicePrefs);
  return useFortnox && !useFortnoxService;
};

export function invoiceCustomerValid(customer, invoicePrefs) {
  const useFortnoxInvoiceService = getInvoiceUseFortnoxService(invoicePrefs);
  const hasRequiredFields = customer && customer.get('orgOrPno') && customer.get('address1')
    && customer.get('postCode') && customer.get('area');

  return !useFortnoxInvoiceService || hasRequiredFields;
}
