import React, { useState } from 'react';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import CurrencyUtil from '@Utils/currency-util';
import { fetchGiftCardByCode, updateGiftCard } from '@State/gift-card-actions';
import { trimString } from '@Utils/validation';
import TextInput from '@Components/inputs/text-input';
import ModalDialog from '@Components/dialogs/modal-dialog';
import GiftCardSummaryModal from '@Components/pos/dialogs/gift-card-summary-modal';
import { getSaleCustomerGiftCards, getSaleItems } from '@State/pos-selectors';
import ColumnListItem from '@Components/ui/column/column-list-item';
import AlertWithIcon from '@Components/ui/alert-with-icon';

const formSelector = formValueSelector('gift-card-payment');

const GiftCardPayment = ({
  totalAmount, submitting, handleSubmit, onSubmit, onClose
}) => {
  const dispatch = useDispatch();
  const saleItems = useSelector(getSaleItems);
  const giftCards = useSelector(getSaleCustomerGiftCards);
  const voucherCode = useSelector(state => formSelector(state, 'voucherCode'));

  const [giftCard, setGiftCard] = useState(null);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);

  const title = `Att betala ${CurrencyUtil.accountCurrency(totalAmount, 2)}`;

  const handleFetchGiftCard = ({ voucherCode }) => {
    setLoading(true);
    return dispatch(fetchGiftCardByCode(voucherCode)).then((giftCard) => {
      setGiftCard(giftCard);
    }).catch(() => {
      setError(`Hittade inget presentkort med kod ${voucherCode}`);
    }).finally(() => {
      setLoading(false);
    });
  };

  const handleEditGiftCard = (id, values) => {
    return dispatch(updateGiftCard(id, values))
      .then((giftCard) => setGiftCard(giftCard));
  };

  const handleGiftCardPayment = () => {
    return onSubmit(giftCard);
  };

  if (!giftCard) {
    return (
      <ModalDialog
        onClose={onClose}
        closeOnClickOutside={false}
        title={giftCards?.length > 0
          ? 'Välj presentkort'
          : `Att betala ${CurrencyUtil.accountCurrency(totalAmount, 2)}`}
        hideCloseButton
        buttons={[{
          name: 'Fortsätt',
          primary: true,
          loading: submitting || loading,
          disabled: !voucherCode,
          onClick: handleSubmit(handleFetchGiftCard)
        }, {
          name: 'Avbryt',
          gray: true,
          onClick: onClose
        }]}
      >
        <form onSubmit={handleSubmit(handleFetchGiftCard)} autoComplete="off" className="dialog">
          {giftCards?.map(({ id, code, description, remainingValue }) => {
            return (
              <ColumnListItem
                key={id}
                disabled={loading}
                icon="fa fa-gift-card text-success"
                onClick={() => handleFetchGiftCard({ voucherCode: code })}
                subHeader={CurrencyUtil.accountCurrency(remainingValue, 2)}
                subText={description}
                header={code}
              />
            );
          })}
          {giftCards?.length > 0 && (
            <div className="text-ruler">
              <span>ELLER</span>
            </div>
          )}
          <div className="text-center">
            <Field
              type="text"
              name="voucherCode"
              component={TextInput}
              placeholder="Scanna eller skriv in kod"
              normalize={trimString}
              autoFocus
            />
          </div>
        </form>
        {error && (
          <AlertWithIcon icon="fa fa-exclamation-circle" error>{error}</AlertWithIcon>
        )}
      </ModalDialog>
    );
  }

  return (
    <GiftCardSummaryModal
      title={title}
      submitText="Betala"
      giftCard={giftCard}
      saleItems={saleItems}
      onClose={onClose}
      totalAmount={totalAmount}
      onSubmit={handleGiftCardPayment}
      onEditGiftCard={handleEditGiftCard}
    />
  );
};

export default reduxForm({
  form: 'gift-card-payment'
})(GiftCardPayment);
