import React, { useState } from 'react';
import { pos } from '@Utils/preference-keys';
import Button from '@Components/ui/button';
import PosOrgConfigToggle from './pos-org-config-toggle';
import PosOrgInvoiceModal from './pos-org-invoice-modal';

const PosOrgPaymentInvoice = (props) => {
  const { posOrg } = props;
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const hideInvoiceModal = () => setShowInvoiceModal(false);

  return (
    <PosOrgConfigToggle
      posOrg={posOrg}
      preference={pos.paymentMethodInvoice}
      label="Faktura"
      description="Visa faktura som betalsätt i kassan och skapa fakturor"
    >
      <Button small outlinePrimary onClick={() => setShowInvoiceModal(true)}>Fakturainställningar</Button>
      {showInvoiceModal && (
        <PosOrgInvoiceModal
          posOrg={posOrg}
          onClose={hideInvoiceModal}
          onSaved={hideInvoiceModal}
        />
      )}
    </PosOrgConfigToggle>
  );
};

export default PosOrgPaymentInvoice;
