import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import { DATE_PICKER_FORMAT } from '@Utils/time-constants';
import {
  createInvoiceItem,
  deleteInvoiceApi,
  fetchLocationsByIdApi,
  fetchOrganizationByIdApi,
  fetchOrganizationInvoice,
  updateInvoiceItem
} from '@State/sysadmin-actions';
import { getSysadminStateInvoice, getSysadminStateOrganizationById } from '@Components/sysadmin/accounts/accounts-selector';
import { PanelBody, PanelDefault, PanelHeading } from '@Components/ui/styled/panel';
import Loader from '@Components/ui/loader';
import ModalDialog from '@Components/dialogs/modal-dialog';
import AccountsContext from '@Components/sysadmin/accounts/accounts-context';
import AccountInvoiceForm from '@Components/sysadmin/accounts/account-detail/account-invoices/account-invoice-form';
import AccountInvoiceHeader
  from '@Components/sysadmin/accounts/account-detail/account-invoices/account-invoice-header';
import AccountInvoiceTable
  from '@Components/sysadmin/accounts/account-detail/account-invoices/account-invoice-table';
import BtnCreateFortnoxInvoice from './btn-create-fortnox-invoice';

const defaultItem = {
  type: 'FixedPriceResources',
  periodStart: '',
  periodEnd: '',
  locationName: '',
  tag: '',
  units: 0,
  price: 0
};

const AccountInvoices = () => {
  const dispatch = useDispatch();
  const { routeParams } = useContext(AccountsContext);
  const subscription = useSelector(state => getSysadminStateOrganizationById(state));
  const invoices = useSelector(state => getSysadminStateInvoice(state));
  const [invoiceLoading, setInvoiceLoading] = useState(true);
  const id = routeParams && +routeParams?.id || '';
  const showCreateInvoice = subscription?.get('paymentMethod') === 'Invoice';

  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const openModalChangeInfo = (item) => setSelectedInvoice(item);

  const hideModalChangeInfo = () => setSelectedInvoice(null);
  const handleAddNewInvoice = (invoiceUUID) => setSelectedInvoice({ ...defaultItem, invoiceUUID });

  const onSubmitNewInvoice = (values) => {
    const params = {
      ...values,
      type: values.type || selectedInvoice.type,
      locationId: values.locationId !== '' ? +values.locationId : null,
      invoiceUUID: selectedInvoice.invoiceUUID
    };
    // need to get ID from just created ITEM
    if (selectedInvoice?.id) {
      dispatch(updateInvoiceItem({
        ...params,
        invoiceItemId: selectedInvoice.id
      }));
    } else {
      dispatch(createInvoiceItem(params));
    }
    hideModalChangeInfo();
  };

  const onConfirmDeleteInvoice = ({ invoiceUUID, invoiceItemId }) => {
    dispatch(deleteInvoiceApi({ invoiceUUID, invoiceItemId }));
  };

  useEffect(() => {
    (async function fetchInvoice() {
      const fromDate = moment().subtract(12, 'months').format(DATE_PICKER_FORMAT);
      const toDate = moment().format(DATE_PICKER_FORMAT);

      setInvoiceLoading(true);
      await dispatch(fetchOrganizationByIdApi(id));
      await dispatch(fetchOrganizationInvoice({ fromDate, toDate, id }));
      setInvoiceLoading(false);
    }());

    (async function fetchLocations() {
      await dispatch(fetchLocationsByIdApi(id));
    }());
  }, [routeParams.id]);

  if (invoiceLoading && invoices.size === 0) {
    return <Loader />;
  }
  if (!invoiceLoading && invoices.size === 0) {
    return (<div>Empty invoices</div>);
  }
  return (
    <>
      {selectedInvoice
      && (
      <ModalDialog onClose={hideModalChangeInfo}>
        <AccountInvoiceForm
          onClose={hideModalChangeInfo}
          onSubmit={onSubmitNewInvoice}
          initialValues={selectedInvoice}
        />
      </ModalDialog>
      )}
      {showCreateInvoice && (
        <div className="mb3 mt1">
          <BtnCreateFortnoxInvoice invoices={invoices} id={routeParams.id} />
        </div>
      )}
      {invoices.map((invoice) => (
        <PanelDefault key={invoice.get('uuid')}>
          <PanelHeading>
            <AccountInvoiceHeader handleAddNewInvoice={handleAddNewInvoice} invoice={invoice} />
          </PanelHeading>
          <PanelBody>
            {invoice?.get('items')
            && (
            <AccountInvoiceTable
              onConfirmDelete={onConfirmDeleteInvoice}
              invoiceUUID={invoice?.get('uuid')}
              items={invoice?.get('items')}
              isPaid={invoice?.get('status') === 'Paid'}
              onClickRow={openModalChangeInfo}
            />
            )}
          </PanelBody>
        </PanelDefault>
      ))}
    </>
  );
};

export default AccountInvoices;
