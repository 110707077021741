import React, { useState } from 'react';
import CustomerInformationForm from '@Components/customers/customer-detail/overview/customer-information-form';
import ModalDialog from '@Components/dialogs/modal-dialog';
import { txt } from '@Utils/i18n-util';
import msg from './customer-modal-tabs.msg';

const CustomerModalTabs = ({
  hideModalChangeInfo, initialData, onSubmit
}) => {
  const [activeTab, setActiveTab] = useState('info');

  const handleTabClick = (e, tab) => {
    e.preventDefault();
    setActiveTab(tab);
  };

  const getClassTab = (tab) => activeTab === tab ? 'tabs__item tabs__item--active' : 'tabs__item';

  return (
    <ModalDialog
      onClose={hideModalChangeInfo}
    >
      <ul className="tabs__list modal-margins">
        <li className={getClassTab('info')}>
          <a href="#" onClick={e => handleTabClick(e, 'info')}>{txt(msg.customerDetails)}</a>
        </li>
        <li className={getClassTab('address')}>
          <a href="#" onClick={e => handleTabClick(e, 'address')}>{txt(msg.address)}</a>
        </li>
      </ul>
      <CustomerInformationForm
        showInfo={activeTab === 'info'}
        onClose={hideModalChangeInfo}
        onSubmit={onSubmit}
        initialValues={initialData}
      />
    </ModalDialog>
  );
};

export default CustomerModalTabs;
