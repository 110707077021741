import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getRouteParams, getSysAdmin } from '@State/selectors';
import { fetchNotificationSubscriptions } from '@State/admin-config-actions';
import { getNotificationPreferences, saveNotificationPreferences } from '@State/preferences-actions';
import { notificationFormToPrefs, notificationPrefsToForm } from '@Utils/notification-util';
import SysadminLabel from '@Components/ui/sysadmin-label';
import NotificationsForm from './notifications-form';

class Notifications extends Component {
  state = {
    subscriptions: null
  };

  componentDidMount() {
    if (!this.props.preferences) {
      this.props.getNotificationConfig();
    }
    if (this.props.isSysAdmin) {
      this.props.fetchNotificationSubscriptions()
        .then(({ subscriptions }) => this.setState({ subscriptions }));
    }
  }

  render() {
    const { subscriptions } = this.state;
    const { routeParams, isSysAdmin } = this.props;
    const initialValues = this.props.preferences
      ? notificationPrefsToForm(this.props.preferences.toJS())
      : null;

    return (
      <div className="subnav-container">
        <NotificationsForm
          routeParams={routeParams}
          initialValues={initialValues}
          onSubmit={this.submitForm}
        />
        {isSysAdmin && subscriptions && (
          <>
            <hr className="mt5 mb4" />
            <h4>
              Alla notiser för kontot
              <SysadminLabel />
            </h4>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Action</th>
                  <th>Destination</th>
                  <th>LoginId</th>
                  <th>ResourceId</th>
                </tr>
              </thead>
              <tbody>
                {subscriptions.map(subscription => {
                  const { type, action, mobilePhoneNumber, email, loginId, resourceId } = subscription;
                  return (
                    <tr key={loginId + resourceId + action}>
                      <td>{type}</td>
                      <td>{action}</td>
                      <td>{mobilePhoneNumber || email}</td>
                      <td>{loginId}</td>
                      <td>{resourceId}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        )}
      </div>
    );
  }

  submitForm = (values) => {
    const data = notificationFormToPrefs(values);
    return this.props.saveNotificationConfig(data);
  };
}

Notifications.propTypes = {
  preferences: PropTypes.object
};

const mapStateToProps = (state, ownProps) => {
  const { preferencesViewState } = state;

  return {
    isSysAdmin: getSysAdmin(state),
    routeParams: getRouteParams(state, ownProps),
    preferences: preferencesViewState.get('notifications')
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getNotificationConfig: () => {
      dispatch(getNotificationPreferences());
    },
    saveNotificationConfig: (data) => {
      return dispatch(saveNotificationPreferences(data));
    },
    fetchNotificationSubscriptions: () => {
      return dispatch(fetchNotificationSubscriptions());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
