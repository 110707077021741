import React from 'react';
import { Field, reduxForm } from 'redux-form';
import TextInput from '@Components/inputs/text-input';
import FormButtonsFooter from '@Components/ui/form-buttons-footer';
import AlertWithIcon from '@Components/ui/alert-with-icon';
import { required } from '@Utils/validation';

const InvoiceEmailForm = ({
  submitting, submitSucceeded, handleSubmit, onClose, submitError
}) => {
  return (
    <form autoComplete="off" className="dialog" onSubmit={handleSubmit}>
      <Field
        name="invoiceEmailSubject"
        component={TextInput}
        validate={[required]}
        label="Ämne"
      />
      <Field
        name="invoiceEmailBody"
        component={TextInput}
        validate={[required]}
        label="Meddelande"
        rows={5}
      />
      {submitError && (
        <AlertWithIcon error>
          Kunde inte skapa faktura. {submitError.response?.data?.message || submitError.message}
        </AlertWithIcon>
      )}
      <FormButtonsFooter
        onClose={onClose}
        cancelText="Tillbaka"
        submitting={submitting}
        submitSucceeded={submitSucceeded}
        handleSubmit={handleSubmit}
        submitText="Skapa och skicka faktura"
      />
    </form>
  );
};

export default reduxForm({
  form: 'invoice-form',
  destroyOnUnmount: false
})(InvoiceEmailForm);
