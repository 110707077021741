import axios from 'axios';
import Moment from 'moment';
import { sortBy } from 'lodash';
import { extendMoment } from 'moment-range';
import { ignoreClosedBlock } from '@Utils/schedule-utils';
import { scheduleBlockToMomentDates } from '@Utils/time-util';
import { isTimeInSchedule, resourceFromColIdx } from '@Components/calendar/grid/grid-state-helper';
import {
  checkStatus, fetchErrorHandler, fetchPut, axiosDefault, prefixUrl, axiosErrorHandler
} from '@Utils/ajax-util';

import {
  DELETED_SCHEDULE_EXCEPTION,
  FETCHED_SCHEDULE_EXCEPTIONS,
  FETCHED_SCHEDULES,
  UPDATED_USE_LOCATION_SCHEDULE,
  FETCHED_ADMIN_SCHEDULE_RESOURCE,
  SET_SCHEDULE_BLOCKS,
  SCHEDULE_SAVED,
  FETCHED_PUBLIC_HOLIDAYS
} from '@State/schedule-constants';

export function schedulesResourceFetched(data) {
  return {
    type: FETCHED_ADMIN_SCHEDULE_RESOURCE,
    data
  };
}

export function schedulesUseLocationScheduleUpdated(data) {
  return {
    type: UPDATED_USE_LOCATION_SCHEDULE,
    ...data
  };
}

export function schedulesMainFetched(schedules, newScheduleKey) {
  return {
    type: FETCHED_SCHEDULES,
    schedules,
    newScheduleKey
  };
}

export function publicHolidaysFetched(holidays) {
  return {
    type: FETCHED_PUBLIC_HOLIDAYS,
    holidays
  };
}

const moment = extendMoment(Moment);

export function scheduleSaved(schedule) {
  return {
    type: SCHEDULE_SAVED,
    schedule
  };
}

export function scheduleExceptionsLoaded(exceptions) {
  return {
    type: FETCHED_SCHEDULE_EXCEPTIONS,
    exceptions
  };
}

export function schedulesExceptionsDeleted(date) {
  return {
    type: DELETED_SCHEDULE_EXCEPTION,
    date
  };
}

function getScheduleUrl(resourceId, key) {
  return prefixUrl(resourceId
    ? `/schedules/resource/${resourceId}/customSchedule/${key}`
    : `/schedules/location/${key}`);
}

export function createSchedule(data, resourceId) {
  const url = getScheduleUrl(resourceId, '');
  const config = axiosDefault();
  let newScheduleKey = null;

  return (dispatch) => {
    return axios.post(url, data, config)
      .then((res) => {
        newScheduleKey = res.data.key ?? `${res.data.id}-${data.validFrom}`;
      })
      .then(() => resourceId
        ? dispatch(fetchResourceSchedules(resourceId, newScheduleKey))
        : dispatch(fetchSchedules(newScheduleKey)))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function saveSchedule(data) {
  const url = getScheduleUrl(data.resourceId, data.key);
  const config = axiosDefault();
  return (dispatch) => {
    return axios.put(url, data, config)
      .then(() => dispatch(scheduleSaved(data)))
      .catch(error => {
        axiosErrorHandler(error, dispatch);
        throw error;
      });
  };
}

export function deleteSchedule(key, resourceId) {
  const url = getScheduleUrl(resourceId, key);
  const config = axiosDefault();
  return (dispatch) => {
    return axios.delete(url, config)
      .then(() => resourceId ? dispatch(fetchResourceSchedules(resourceId)) : dispatch(fetchSchedules()))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function fetchSchedules(newScheduleKey) {
  const url = prefixUrl('/schedules/location/');
  const config = axiosDefault();
  return (dispatch) => {
    return axios.get(url, config)
      .then(res => dispatch(schedulesMainFetched(res.data, newScheduleKey)))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function toggleResourceLocationSchedule(resourceId, useLocationSchedule) {
  const url = prefixUrl(`/schedules/resource/${resourceId}/preferences`);
  const config = axiosDefault();
  return (dispatch) => {
    return axios.put(url, { useLocationSchedule }, config)
      .then(() => dispatch(schedulesUseLocationScheduleUpdated({ resourceId, useLocationSchedule })))
      .then(() => useLocationSchedule ? null : dispatch(fetchResourceSchedules(resourceId)))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function fetchResourceSchedules(resourceId, newScheduleKey) {
  const url = prefixUrl(`/schedules/resource/${resourceId}`);
  const config = axiosDefault();
  return (dispatch) => {
    return axios.get(url, config)
      .then(res => dispatch(schedulesResourceFetched({ resourceId, ...res.data, newScheduleKey })))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function fetchPublicHolidays(year) {
  const url = prefixUrl(`/public-holidays/${year}/`);
  const config = axiosDefault();
  return (dispatch) => {
    return axios.get(url, config)
      .then(res => dispatch(publicHolidaysFetched({ ...res.data })))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function fetchPublicHolidaysIfNeeded(year) {
  return (dispatch, getState) => {
    const { adminSchedules } = getState();
    const holidays = adminSchedules.get('holidays');
    const firstDate = holidays?.getIn([0, 'date']);

    if (firstDate?.includes(String(year))) {
      return Promise.resolve();
    }
    return dispatch(fetchPublicHolidays(year));
  };
}

export function editSchedule(event, routeParams) {
  return (dispatch, getState) => {
    const { exceptionType } = event;

    // New solution using booking form
    if (exceptionType) {
      return exceptionType === 'open'
        ? dispatch(markOpen(event))
        : dispatch(markClosed(event));
    }

    // Old solution for app backwards compatibility
    const state = getState();
    const { schedulesByResource } = state;

    const resource = resourceFromColIdx(state, routeParams, event.colIdx);
    if (!resource) {
      throw Error('Could not determine resource from column');
    }
    const open = !isTimeInSchedule(schedulesByResource, event.startTime, resource.id);

    if (open) {
      dispatch(markOpen({
        startTime: event.startTime,
        endTime: event.endTime,
        resourceId: resource.id
      }));
    } else {
      dispatch(markClosed({
        startTime: event.startTime,
        endTime: event.endTime,
        resourceId: resource.id
      }));
    }
  };
}

export function fetchScheduleExceptions(resourceId) {
  const url = prefixUrl(`/schedules/resource/${resourceId}/exceptions/`);
  const config = axiosDefault();

  return (dispatch) => {
    return axios.get(url, config)
      .then(res => dispatch(scheduleExceptionsLoaded(res.data)))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function deleteScheduleExceptions(resourceId, date) {
  const prefix = date ? `${resourceId}/exceptions/${date}` : `${resourceId}/exceptions/ALL`;
  const url = prefixUrl(`/schedules/resource/${prefix}`);
  const config = axiosDefault();

  return (dispatch) => {
    return axios.delete(url, config)
      .then(() => dispatch(schedulesExceptionsDeleted(date)))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function updateScheduleExceptions(resourceId, blocks) {
  return (dispatch) => {
    const url = prefixUrl(`/schedules/resource/${resourceId}/exceptions/`);
    return fetch(url, fetchPut({ resourceId, blocks }))
      .then(res => dispatch(checkStatus(res)))
      .then(() => dispatch(scheduleBlocksUpdated(resourceId, blocks)))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function getScheduleBlocksForUpdate(exception) {
  return (dispatch, getState) => {
    const { schedulesByResource } = getState();
    const { resourceId, exceptionType, startTime } = exception;
    const day = moment(startTime).format('YYYY-MM-DD');
    const schedule = schedulesByResource.get(resourceId);

    console.log('getScheduleBlocksForUpdate', { schedule });

    const scheduleBlocks = exceptionType === 'open'
      ? addOpenBlock(schedule, exception)
      : closeBlock(schedule, exception);
    const affectedBlocks = scheduleBlocks.filter(b => b.day === day);

    if (affectedBlocks.length === 0) {
      const dayProps = getScheduleDayProps(schedule, day);
      return [{ day, start: '00:00', end: '00:00', closed: true, ...dayProps }];
    }

    return mergeAdjacentBlocks(affectedBlocks);
  };
}

export function markOpen(block) {
  return (dispatch) => {
    const blocks = dispatch(getScheduleBlocksForUpdate({
      exceptionType: 'open',
      ...block
    }));

    return dispatch(updateScheduleExceptions(block.resourceId, blocks));
  };
}

export function markClosed(block) {
  return (dispatch) => {
    const blocks = dispatch(getScheduleBlocksForUpdate({
      exceptionType: 'close',
      ...block
    }));

    return dispatch(updateScheduleExceptions(block.resourceId, blocks));
  };
}

export function scheduleBlocksUpdated(resourceId, blocks) {
  return (dispatch, getState) => {
    const { schedulesByResource } = getState();
    const schedule = schedulesByResource.get(resourceId);
    const { bookingMaxDaysInAdvance } = schedule;

    if (!blocks || blocks.length === 0) {
      return;
    }

    console.log('scheduleBlocksUpdated', { schedule });

    const newBlocks = schedule.blocks
      .filter(b => b.day !== blocks[0].day)
      .concat(blocks);

    return dispatch({
      type: SET_SCHEDULE_BLOCKS,
      resourceId,
      bookingMaxDaysInAdvance,
      blocks: newBlocks
    });
  };
}

function mapExceptionToScheduleBlock(schedule, exception, start, end) {
  const day = start.format('YYYY-MM-DD');
  const dayProps = getScheduleDayProps(schedule, day);
  const { type, exceptionType, startTime, endTime, ...block } = exception;

  return {
    ...block,
    ...dayProps,
    day,
    start: start.format('HH:mm'),
    end: end.format('HH:mm')
  };
}

function openTypeEquals(openTypeA, openTypeB) {
  if (!openTypeA && !openTypeB) {
    return true;
  }
  return openTypeA === openTypeB;
}

function isSameTypeAndAdjacent(blockA, blockB) {
  if (!blockA || !blockB) {
    return false;
  }
  return openTypeEquals(blockA.openType, blockB.openType)
    && blockA.end.substring(0, 5) === blockB.start.substring(0, 5);
}

function mergeAdjacentBlocks(blocks) {
  const merged = [];
  const sorted = sortBy(blocks, b => b.start);

  let start = null;
  for (let i = 0; i < sorted.length; i += 1) {
    const block = sorted[i];
    const isLast = i === sorted.length - 1;
    const next = !isLast ? sorted[i + 1] : null;

    if (!start) {
      start = block.start;
    }

    if (!isSameTypeAndAdjacent(block, next)) {
      merged.push({ ...block, start });
      start = null;
    }
  }
  return merged;
}

function updateExistingBlocks(schedule, newRange) {
  const blocks = [];
  if (schedule) {
    for (const existingBlock of schedule.blocks.filter(ignoreClosedBlock)) {
      const mb = scheduleBlockToMomentDates(existingBlock);
      const range = moment.range(mb.blStart, mb.blEnd);

      if (newRange.overlaps(range)) {
        for (const splitBlock of range.subtract(newRange)) {
          blocks.push(mapExceptionToScheduleBlock(schedule, existingBlock, splitBlock.start, splitBlock.end));
        }
      } else {
        blocks.push(existingBlock);
      }
    }
  }
  return blocks;
}

function closeBlock(schedule, block) {
  const newRange = moment.range(block.startTime, block.endTime);
  const blocks = updateExistingBlocks(schedule, newRange);
  return blocks;
}

function addOpenBlock(schedule, block) {
  const start = moment(block.startTime);
  const end = moment(block.endTime);
  const newRange = moment.range(start, end);
  const blocks = updateExistingBlocks(schedule, newRange);
  blocks.push(mapExceptionToScheduleBlock(schedule, block, start, end));
  return blocks;
}

function getScheduleDayProps(schedule, date) {
  console.log('getScheduleDayProps', { schedule });

  const refDataBlock = schedule.blocks.find(a => moment(a.day).isSame(date, 'day'));

  const {
    vipOpen = false, webOpen = true, closedMax = false, publicHoliday = false, holidayName = null
  } = refDataBlock || {};

  return {
    vipOpen,
    webOpen,
    closedMax,
    publicHoliday,
    holidayName
  };
}
