import styled, { css } from 'styled-components';

export default styled.span`
  ${p => p?.pullRight && css`
    margin-left: auto;
  `};
  ${p => p?.pullLeft && css`
     margin-right: auto;
  `};
   ${p => p?.width && css`
    width: ${p.width};
  `}
`;
