import React from 'react';
import orderBy from 'lodash/orderBy';
import { reportingCount, reportingFormat } from '@Utils/format';
import { getTotal } from '@Components/reports/reports-helpers';

const CommissionReportItemRows = ({ productRows, commissionPct }) => {
  return (
    <table className="table table-condensed table-report">
      <thead>
        <tr>
          <th className="col-xs-4">Produkt</th>
          <th className="text-right">Antal</th>
          <th className="text-right">Exkl. moms</th>
          <th className="text-right">Inköpspris</th>
          <th className="text-right">Vinst</th>
          <th className="text-right">
            Provision
            {commissionPct ? ` (${commissionPct}%)` : null}
          </th>
        </tr>
      </thead>
      <tbody>
        {orderBy(productRows, ['description'], ['asc']).map(({
          description, netQty, netAmountExVat, netPriceIn, netMargin, commission
        }, index) => (
          <tr key={index}>
            <td>{description}</td>
            <td className="text-right">{reportingCount(netQty)}</td>
            <td className="text-right">{reportingFormat(netAmountExVat)}</td>
            <td className="text-right">
              {netPriceIn ? reportingFormat(netPriceIn) : '-'}
            </td>
            <td className="text-right">{reportingFormat(netMargin)}</td>
            <td className="text-right">{reportingFormat(commission)}</td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <th>Summa</th>
          <th className="text-right">{reportingCount(getTotal(productRows, 'netQty'))}</th>
          <th className="text-right">{reportingFormat(getTotal(productRows, 'netAmountExVat'))}</th>
          <th className="text-right">{reportingFormat(getTotal(productRows, 'netPriceIn'))}</th>
          <th className="text-right">{reportingFormat(getTotal(productRows, 'netMargin'))}</th>
          <th className="text-right">{reportingFormat(getTotal(productRows, 'commission'))}</th>
        </tr>
      </tfoot>
    </table>
  );
};

export default CommissionReportItemRows;
