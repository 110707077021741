import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pos } from '@Utils/preference-keys';
import { mergeOrgSettings } from '@State/pos-config-actions';
import { getPosOrgGiftCardPrefs } from '@State/pos-config-selectors';
import PosOrgConfigToggle from './pos-org-config-toggle';
import PosOrgGiftCardForm from './pos-org-gift-card-form';

const PosOrgPaymentGiftCard = (props) => {
  const { posOrg } = props;
  const dispatch = useDispatch();

  const handleSubmit = useCallback((values) => {
    dispatch(mergeOrgSettings(posOrg.get('id'), values));
  }, [posOrg]);

  const giftCardPrefs = useSelector(state => getPosOrgGiftCardPrefs(state, props));

  return (
    <PosOrgConfigToggle
      posOrg={posOrg}
      preference={pos.paymentMethodGiftCard}
      label="Presentkort"
      description="Aktiverar försäljning och inlösen av presentkort"
    >
      <PosOrgGiftCardForm initialValues={giftCardPrefs} onSubmit={handleSubmit} />
    </PosOrgConfigToggle>
  );
};

export default PosOrgPaymentGiftCard;
