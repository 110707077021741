import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalDialog from '@Components/dialogs/modal-dialog';
import Button from '@Components/ui/button';
import { hasUserGroupAccess, hasUserResourceAccess } from '@Components/admin/staff-config/staff-config-selectors';
import LabelSwitchInput from '@Components/ui/label-switch-input';
import { addUserAccess, removeUserAccess } from '@State/users-actions';
import { ModalContent, ModalFooter } from '@Components/dialogs/dialog-styles';
import AlertWithIcon from '@Components/ui/alert-with-icon';

const StaffUserAccess = ({ user, onClose }) => {
  const dispatch = useDispatch();
  const orderedGroups = useSelector(state => state.orderedGroups);
  const resourcesById = useSelector(state => state.resourcesById);

  const hasPrivilegedAccess = user.get('userRole') !== 'UnprivilegedUser'
    && user.get('userRole') !== 'UnprivilegedUserWithReports';

  const onChangeToggle = (access) => {
    if (access.isOn) {
      return dispatch(removeUserAccess(user.get('id'), access));
    }
    return dispatch(addUserAccess(user.get('id'), access));
  };

  return (
    <ModalDialog
      contentSize="medium"
      title="Avancerade rättigheter"
      closeButtonText="Stäng"
      onClose={onClose}
    >
      <ModalContent>
        {hasPrivilegedAccess ? (
          <AlertWithIcon icon="fa fa-lock-open">
            Användaren har en roll som ger rättighet att se samtliga grupper och resurser.
          </AlertWithIcon>
        ) : (
          <AlertWithIcon icon="fa fa-lock">
            Välj vilka grupper och/eller resurser som användaren har rättighet att se.
          </AlertWithIcon>
        )}
        {!hasPrivilegedAccess && orderedGroups.sortBy(g => g.get('name')).map((item) => {
          const groupAccess = {
            entity: 'Grp',
            entityId: item.get('id'),
            isOn: hasUserGroupAccess(user, item.get('id'))
          };
          return (
            <table key={item.get('id')} className="table table-striped">
              <thead>
                <tr>
                  <th>
                    <LabelSwitchInput
                      noBorder
                      id={groupAccess.entityId}
                      key={groupAccess.entityId}
                      isOn={groupAccess.isOn}
                      label={item.get('name')}
                      handleToggle={() => onChangeToggle(groupAccess)}
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {item.get('resourceIds').map((resourceId) => {
                  const resource = resourcesById.get(resourceId);
                  const resourceAccess = {
                    entity: 'Res',
                    entityId: resource.id,
                    isOn: hasUserResourceAccess(user, resource.id)
                  };
                  return (
                    <tr key={resource.id}>
                      <td>
                        <LabelSwitchInput
                          noBorder
                          id={resource.id}
                          key={resource.id}
                          isOn={resourceAccess.isOn}
                          labelClass="label-child"
                          label={resource.name}
                          handleToggle={() => onChangeToggle(resourceAccess)}
                          disabled={groupAccess.isOn}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          );
        })}
      </ModalContent>
      <ModalFooter>
        <Button gray small onClick={onClose}>Stäng</Button>
      </ModalFooter>
    </ModalDialog>
  );
};

export default StaffUserAccess;
