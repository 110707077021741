import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';

import Flex from '@Components/ui/styled/flex-item';
import TextInput from '@Components/inputs/text-input';
import { createValidator, required } from '@Utils/validation';

import ModalDialog from '@Components/dialogs/modal-dialog';
import {
  BtnBack, DialogTitleRow, DialogTitle, GrayText, DialogTitleBtn
} from '@Components/dialogs/dialog-styles';

const CreatePosOrgAddress = ({
  onPrev, onNext, onHide, ...formState
}) => {
  const [isClicked, setIsClicked] = useState(true);
  const onClickNext = () => {
    setIsClicked(false);
    const errors = formState.validate();
    if (formState.valid) {
      onNext();
    }
    if (Object.keys(errors).length > 0) {
      // eslint-disable-next-line array-callback-return
      Object.keys(errors).map((item) => {
        formState.touch(item);
      });
    }
  };
  const buttonsAction = [
    {
      name: 'Nästa',
      primary: true,
      type: 'submit',
      disabled: !isClicked && formState.invalid,
      onClick: onClickNext
    }
  ];

  return (
    <ModalDialog
      contentSize="medium"
      onClose={onHide}
      buttons={buttonsAction}
    >
      <form autoComplete="off" className="dialog">
        <DialogTitle>
          <BtnBack onClick={onPrev}><i className="fas fa-chevron-left" /></BtnBack>
          <DialogTitleRow leftBtn center>Kassaregistrets adress</DialogTitleRow>
          <DialogTitleBtn onClick={onHide}>Avbryt</DialogTitleBtn>
        </DialogTitle>
        <Flex>
          <Field
            name="posAddressLine1"
            component={TextInput}
            label="Gatuadress"
            placeholder="Gatuadress"
          />
        </Flex>
        <Flex>
          <Flex rightMargin>
            <Field
              name="posPostCode"
              type="number"
              component={TextInput}
              label="Postnummer"
              placeholder="Postnummer"
            />
          </Flex>
          <Flex>
            <Field
              name="posArea"
              component={TextInput}
              label="Postort"
              placeholder="Postort"
            />
          </Flex>
        </Flex>
        <Flex>
          <GrayText>
            Om kassaregistret används på en annan adress än den där
            företaget är registrerat så anger du kassaregistrets adress här
          </GrayText>
        </Flex>
      </form>
    </ModalDialog>
  );
};

const validator = createValidator({
  posAddressLine1: [required],
  posPostCode: [required],
  posArea: [required]
});

export default reduxForm({
  form: 'create-pos-org',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: validator
})(CreatePosOrgAddress);
