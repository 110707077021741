import React from 'react';
import CurrencyUtil from '@Utils/currency-util';
import { reportingCount, reportingFormat } from '@Utils/format';
import { getTotal } from '@Components/reports/reports-helpers';
import { Text } from '@Components/ui/styled/main';

const SalesReportVoucherRows = ({ voucherSales, voucherRedeems }) => {
  let exVatAmount = 0;
  const redeemedCount = getTotal(voucherRedeems, 'redeemQty');
  const multiPurposeRedeems = voucherRedeems.filter(v => v.accountingType === 'MultiPurpose');
  const redeemedDiscount = getTotal(multiPurposeRedeems, 'discountAmount');

  return (
    <>
      <table className="table table-condensed table-report">
        <thead>
          <tr>
            <th className="col-xs-3">Sålda</th>
            <th className="text-right">Värde</th>
            <th className="text-right">Moms</th>
            <th className="text-right">Antal</th>
            <th className="text-right">Totalt värde</th>
            <th className="text-right">Rabatter</th>
            <th className="text-right">Exkl. moms</th>
            <th className="text-right">Inkl. moms</th>
          </tr>
        </thead>
        <tbody>
          {voucherSales.map(({
            description, accountingType, vatPct, initialValue, saleQty,
            saleAmount, discountAmount, vatAmount, incVatAmount
          }, index) => {
            const isMultiPurpose = accountingType === 'MultiPurpose';
            exVatAmount += incVatAmount - vatAmount;
            return (
              <tr key={index}>
                <td>{description}</td>
                <td className="text-right">{reportingFormat(initialValue)}</td>
                <td className="text-right">{isMultiPurpose ? '-' : `${vatPct}%`}</td>
                <td className="text-right">{saleQty}</td>
                <td className="text-right">{reportingFormat(saleAmount)}</td>
                <td className="text-right">{reportingFormat(discountAmount)}</td>
                <td className="text-right">{isMultiPurpose ? '-' : reportingFormat(incVatAmount - vatAmount)}</td>
                <td className="text-right">{isMultiPurpose ? '-' : reportingFormat(incVatAmount)}</td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <th>Summa</th>
            <th />
            <th />
            <th className="text-right">{reportingCount(getTotal(voucherSales, 'saleQty'))}</th>
            <th className="text-right">{reportingFormat(getTotal(voucherSales, 'saleAmount'))}</th>
            <th className="text-right">{reportingFormat(getTotal(voucherSales, 'discountAmount'))}</th>
            <th className="text-right">{reportingFormat(exVatAmount)}</th>
            <th className="text-right">{reportingFormat(getTotal(voucherSales, 'incVatAmount'))}</th>
          </tr>
        </tfoot>
      </table>
      <table className="table table-condensed table-report">
        <thead>
          <tr>
            <th className="col-xs-4">Inlösta</th>
            <th className="text-right">Moms</th>
            <th className="text-right">
              Antal
              {redeemedCount !== 0 && <sup>1</sup>}
            </th>
            <th className="text-right">Totalt värde</th>
            <th className="text-right">
              Rabatter
              {redeemedDiscount !== 0 && <sup>2</sup>}
            </th>
          </tr>
        </thead>
        <tbody>
          {voucherRedeems.map(({
            description, accountingType, vatPct, redeemQty,
            redeemedValue, discountAmount
          }, index) => {
            const isMultiPurpose = accountingType === 'MultiPurpose';
            return (
              <tr key={index}>
                <td>{description}</td>
                <td className="text-right">{isMultiPurpose ? '-' : `${vatPct}%`}</td>
                <td className="text-right">{reportingCount(redeemQty)}</td>
                <td className="text-right">{reportingFormat(redeemedValue)}</td>
                <td className="text-right">{isMultiPurpose ? reportingFormat(discountAmount) : '-'}</td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <th>Summa</th>
            <th />
            <th className="text-right">{reportingCount(redeemedCount)}</th>
            <th className="text-right">{reportingFormat(getTotal(voucherRedeems, 'redeemedValue'))}</th>
            <th className="text-right">{reportingFormat(redeemedDiscount)}</th>
          </tr>
        </tfoot>
      </table>
      <Text marginTop="15px" fs={13} muted>
        {redeemedCount !== 0 && (
        <div>
          <sup>1</sup> Avser antal inlösen av presentkort och kan innehålla både helt och delvis inlösta presentkort.
        </div>
        )}
        {redeemedDiscount !== 0 && (
        <div>
          <sup>2</sup> Avser rabatt lämnad vid försäljning av presentkort som medger en minskning av det redovisade
          momsbeloppet vid inlösen av flerfunktionsvoucher.
        </div>
        )}
      </Text>
    </>
  );
};

export default SalesReportVoucherRows;
