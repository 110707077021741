import { defineMessages } from 'react-intl';

export default defineMessages({
  txtConfirmRefund: {
    id: 'calendar.bkf.refund.txtConfirmRefund',
    defaultMessage: 'Refund booking?'
  },
  btnCancel: {
    id: 'calendar.bkf.refund.btnCancel',
    defaultMessage: 'Cancel'
  },
  btnConfirm: {
    id: 'calendar.bkf.refund.btnConfirm',
    defaultMessage: 'Refund'
  },
  btnRefunding: {
    id: 'calendar.bkf.refund.btnRefunding',
    defaultMessage: 'Refunding...'
  }
});
