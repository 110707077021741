import moment from 'moment';
import React from 'react';
import { Text } from '@Components/ui/styled/main';

const FortnoxSyncStatus = ({ syncStatus }) => {
  if (!syncStatus || !syncStatus.status) {
    return null;
  }

  const {
    status, statusUpdateTs, firstRunTs, latestRunTs, lastError,
    syncTransactionsFromDate, latestSyncedTransactionDate
  } = syncStatus;

  return (
    <>
      <Text fs={13} marginTop="20px" marginBottom>
        Status: <strong>{status}</strong><br />
        Första körning: {firstRunTs ? <strong>{moment(firstRunTs).format('LLLL')}</strong> : '-'}<br />
        Senaste körning: {latestRunTs ? <strong>{moment(latestRunTs).format('LLLL')}</strong> : '-'}<br />
        Första datum att bokföra: {syncTransactionsFromDate ? <strong>{moment(syncTransactionsFromDate).format('LL')}</strong> : '-'}<br />
        Senaste bokfört datum: {latestSyncedTransactionDate ? <strong>{moment(latestSyncedTransactionDate).format('LL')}</strong> : '-'}<br />
      </Text>
      {lastError && (
        <Text danger marginBottom>
          {lastError}
        </Text>
      )}
      <Text muted fs={11}>
        Uppdaterad: {moment(statusUpdateTs).format('LLLL')}<br />
      </Text>
    </>
  );
};

export default FortnoxSyncStatus;
