import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';

class CompanyInfoForm extends Component {
  render() {
    const { handleSubmit, submitting, onCancel } = this.props;

    return (
      <form autoComplete="off" onSubmit={handleSubmit} className="form-horizontal">
        <div className="form-group">
          <label className="control-label col-xs-3">Företagsnamn</label>
          <div className="col-xs-5">
            <Field name="orgName" component="input" type="text" className="form-control" />
          </div>
        </div>

        <div className="form-group">
          <label className="control-label col-xs-3">Organisationsnr.</label>
          <div className="col-xs-5">
            <Field name="companyRegNo" component="input" type="text" className="form-control" />
          </div>
        </div>

        <div className="form-group">
          <div className="col-xs-offset-3 col-xs-9">
            <button type="submit" className="btn-save" disabled={submitting}>Spara</button> &nbsp;
            <button type="button" className="btn btn-default" disabled={submitting} onClick={onCancel}>Avbryt</button>
          </div>
        </div>
      </form>
    );
  }
}

export default reduxForm({
  form: 'contacts-company-info-form'
})(CompanyInfoForm);
