import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field } from 'redux-form';
import {
  BEFORE_AFTER, FIRST_AVAILABLE, INTERVAL, CUSTOM, intervalOptions
} from '@Utils/web-booking';

const stylesControlContext = { marginBottom: 5 };
const stylesLabelFontWeighRegular = { fontWeight: 400 };
const stylesLabelFontWeighBold = { fontWeight: 700 };
const stylesCheckbox = { marginTop: 10 };
const stylesCheckboxSmallTopMargin = { marginTop: 5 };

const IntervalField = ({ interval }) => {
  return (
    <>
      <div className="select-container">
        <Field name="interval" component="select" className="form-control" style={stylesCheckbox}>
          {intervalOptions.map(minutes => (
            <option key={minutes} value={minutes}>
              {minutes} minuters intervall
            </option>
          ))}
          <option value={CUSTOM}>Eget intervall</option>
        </Field>
      </div>
      {interval === CUSTOM && (
        <div className="form-group mt2">
          <div className="col-sm-2">
            <Field
              name="customInterval"
              component="input"
              type="number"
              className="form-control"
              min={1}
              max={1440}
            />
          </div>
          <p className="form-control-static">minuters intervall</p>
        </div>
      )}
    </>
  );
};

class WebBookingStrategy extends Component {
  render() {
    const { slotFinderStrategy, propertyName, interval } = this.props;

    const isInterval = !slotFinderStrategy || slotFinderStrategy === INTERVAL;
    const isFirstAvailable = slotFinderStrategy === FIRST_AVAILABLE;
    const isBeforeAfter = slotFinderStrategy === BEFORE_AFTER;

    return (
      <div>
        <p className="form-control-static" style={stylesControlContext}>
          Välj hur Cliento ska föreslå tider i onlinebokningen
        </p>
        <div className="well well-sm">

          <label className="radio-inline" style={stylesLabelFontWeighRegular}>
            <Field name={propertyName} component="input" type="radio" value={BEFORE_AFTER} />
            Smart bokning <span style={stylesLabelFontWeighBold}>(Rekommenderas)</span>
          </label>

          <div className="checkbox-description">
            Minimerar luckor och obokad tid genom att föreslå tider innan och efter befintliga bokningar, samt första och sista tiden på dagen

            {isBeforeAfter
            && (
            <div>
              <div className="checkbox" style={stylesCheckbox}>
                <label>
                  <Field name="excludeLastInDay" component="input" type="checkbox" />
                  Föreslå inte sista tiden för dagen
                </label>
              </div>
              <div className="checkbox" style={stylesCheckboxSmallTopMargin}>
                <label>
                  <Field name="useIntervalFallback" component="input" type="checkbox" />
                  Föreslå tider med intervall på dagar med få bokningar
                </label>
                <IntervalField interval={interval} />
              </div>
            </div>
            )}
          </div>

          <label className="radio-inline" style={stylesLabelFontWeighRegular}>
            <Field name={propertyName} component="input" type="radio" value={FIRST_AVAILABLE} />
            Första lediga
          </label>
          <div className="checkbox-description">
            Föreslår första lediga tid på dagen eller för- och eftermiddag, samt även luckor som passar bokningens längd.

            {isFirstAvailable
              ? (
                <div className="select-container">
                  <Field name="breakPoints" component="select" className="form-control" style={stylesCheckbox} defaultValue="00:00">
                    <option value="00:00">Dagens första lediga tid</option>
                    <option value="12:00">Första tiderna på för- och eftermiddag</option>
                  </Field>
                </div>
              ) : null}
          </div>

          <label className="radio-inline" style={stylesLabelFontWeighRegular}>
            <Field name={propertyName} component="input" type="radio" value={INTERVAL} />
            Intervall
          </label>
          <div className="checkbox-description">
            Föreslår tider enligt ett intervall, t.ex. var 60:e minut, fungerar bäst om du främst har bokningar som passar intervallet.
            {isInterval ? <IntervalField interval={interval} /> : null}
          </div>
        </div>
      </div>
    );
  }
}

WebBookingStrategy.propTypes = {
  slotFinderStrategy: PropTypes.string,
  propertyName: PropTypes.string.isRequired
};

export default WebBookingStrategy;
