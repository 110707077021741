import React from 'react';
import Table, {
  TableBody, TableHead, TableRow, TableTh
} from '@Components/ui/styled/table';
import AccountInvoiceTableItem
  from '@Components/sysadmin/accounts/account-detail/account-invoices/account-invoice-table-item';

const AccountInvoiceTable = ({
  items, isPaid, invoiceUUID, onClickRow, onConfirmDelete
}) => (
  <Table>
    <TableHead>
      <TableRow>
        <TableTh width="150px">Type</TableTh>
        <TableTh>Location</TableTh>
        <TableTh>Period start</TableTh>
        <TableTh>Period end</TableTh>
        <TableTh>Tag</TableTh>
        <TableTh right width="40px">Units</TableTh>
        <TableTh right>Amount</TableTh>
        <TableTh paddingLeft="25px">Alt text</TableTh>
        <TableTh />
      </TableRow>
    </TableHead>
    <TableBody>
      {items.map((item) => {
        const onClick = () => invoiceUUID && !isPaid ? onClickRow({ ...item?.toJS(), invoiceUUID }) : null;
        return (
          <TableRow key={item.get('id')} onClick={onClick}>
            <AccountInvoiceTableItem
              item={item}
              isPaid={isPaid}
              invoiceUUID={invoiceUUID}
              onConfirmDelete={onConfirmDelete}
            />
          </TableRow>
        );
      })}
    </TableBody>
  </Table>
);

export default AccountInvoiceTable;
