import React from 'react';
import PropTypes from 'prop-types';
import NavTabsLink from '@Components/ui/nav-tabs-link';

const sections = [
  { navId: 'schedule', name: 'Scheman' },
  { navId: 'exception', name: 'Undantag' },
  { navId: 'holidays', name: 'Helgdagar' }
];

const ScheduleSubNav = ({ routeParams }) => (
  <NavTabsLink
    subUrl="admin/schedules"
    routeParams={routeParams}
    sections={sections}
  />
);

ScheduleSubNav.propTypes = {
  routeParams: PropTypes.object
};

export default ScheduleSubNav;
