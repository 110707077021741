import React from 'react';
import { Field, reduxForm } from 'redux-form';

import TextInput from '@Components/inputs/text-input';
import ModalDialog from '@Components/dialogs/modal-dialog';
import SelectField from '@Components/inputs/select-field';
import { required } from '@Utils/validation';

const TerminalAssociateForm = ({
  onClose, handleSubmit, submitting, posUnits, terminals, initialValues
}) => (
  <ModalDialog
    title="Koppla kortterminal"
    onClose={onClose}
    buttons={[{
      name: 'Koppla kortterminal',
      primary: true,
      type: 'submit',
      loading: submitting,
      onClick: handleSubmit
    }]}
  >
    <form onSubmit={handleSubmit}>
      <Field
        name="terminalId"
        component={SelectField}
        label="Terminal"
        validate={required}
        parse={val => val ? parseInt(val) : null}
        disabled={!!initialValues.terminalId}
      >
        <option style={{ display: 'none' }}>Välj terminal</option>
        {terminals?.map(terminal => (
          <option key={terminal.get('id')} value={terminal.get('id')}>
            {terminal.get('name') || terminal.get('vendorDeviceString') || 'Okänd terminal'}
          </option>
        ))}
      </Field>
      <Field
        name="vunitId"
        component={SelectField}
        label="Kassa"
        validate={required}
        parse={val => val ? parseInt(val) : null}
        disabled={!!initialValues.vunitId}
      >
        <option style={{ display: 'none' }}>Välj kassa</option>
        {posUnits?.map(posUnit => (
          <option key={posUnit.get('id')} value={posUnit.get('id')}>
            {posUnit.get('posOrgName')}: {posUnit.get('name')}
          </option>
        ))}
      </Field>
      <Field name="merchId" component={TextInput} label="Merchant/Bax ID" />
    </form>
  </ModalDialog>
);

export default reduxForm({
  form: 'terminal-associate-form'
})(TerminalAssociateForm);
