import React from 'react';
import ModalDialog from '@Components/dialogs/modal-dialog';
import { DialogContent } from '@Components/dialogs/dialog-styles';

const ConfirmCsvDownload = ({ loading, onClose, onDownloadCsv }) => {
  return (
    <ModalDialog
      title="Urval för stort"
      onClose={onClose}
      hideCloseButton
      buttons={[{
        name: 'Ladda ner CSV',
        primary: true,
        onClick: onDownloadCsv,
        loading
      }, {
        name: 'Ändra urval',
        gray: true,
        onClick: onClose,
        disabled: loading
      }]}
    >
      <DialogContent tabIndex={0}>
        Urvalet är för stort för att ladda ner som Excel-fil.
        Vill du ladda ner en CSV-fil istället?
      </DialogContent>
    </ModalDialog>
  );
};

export default ConfirmCsvDownload;
