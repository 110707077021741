import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';

import Button from '@Components/ui/button';
import ButtonModalForm from '@Components/dialogs/button-modal-form';
import FormButtonsFooter from '@Components/ui/form-buttons-footer';
import { updateTerminateAccount, updateUnTerminateAccount } from '@State/sysadmin-actions';
import Popover from '@Components/ui/popover';
import ConfirmBlock from '@Components/ui/confirm-block';

const AccountHeaderBtnTerminate = ({ terminated, id }) => {
  const dispatch = useDispatch();
  const { handleSubmit } = useForm();
  const [when, setWhen] = useState('Now');
  const [showMenu, setShowMenu] = useState(false);

  const onSubmit = useCallback(async () => {
    return dispatch(updateTerminateAccount({ orgId: id, when }));
  }, [when, terminated, id]);

  const onUnterminate = async () => {
    await dispatch(updateUnTerminateAccount({ orgId: id }));
    setShowMenu(false);
  };

  return (
    <>
      {terminated
        ? (
          <Popover
            preferPlace="below"
            isOpen={showMenu}
            onOuterAction={() => setShowMenu(false)}
            body={(
              <div className="Popover-dialog-sm button-wrapper">
                <ConfirmBlock
                  title="Återaktivera konto?"
                  cancelText="Avbryt"
                  confirmText="Återaktivera"
                  onCancel={() => setShowMenu(false)}
                  onConfirm={onUnterminate}
                />
              </div>
            )}
          >
            <Button
              primary
              marginRight
              small
              noBorder
              bold
              onClick={() => setShowMenu(true)}
            >
              Återaktivera
            </Button>
          </Popover>
        )
        : (
          <ButtonModalForm btnText="Avsluta" marginRight={false} small gray={false} bold className="btn-delete" onSubmit={onSubmit}>
            {({ onClose, onSubmit, loading }) => (
              <form onSubmit={handleSubmit(onSubmit)}>
                <h4>Avsluta konto?</h4>
                <div className="radio">
                  <label>
                    <input type="radio" value="Now" checked={when === 'Now'} onChange={() => setWhen('Now')} /> Direkt
                  </label>
                </div>
                <div className="radio">
                  <label>
                    <input type="radio" value="EndOfMonth" checked={when === 'EndOfMonth'} onChange={() => setWhen('EndOfMonth')} />
                    Vid slutet av månaden
                  </label>
                </div>
                <FormButtonsFooter
                  submitText="Avsluta"
                  type="submit"
                  onClose={onClose}
                  submitting={loading}
                  handleSubmit={onSubmit}
                  submitSucceeded
                />
              </form>
            )}
          </ButtonModalForm>
        )}
    </>
  );
};

AccountHeaderBtnTerminate.propTypes = {
  terminated: PropTypes.bool,
  id: PropTypes.string
};

export default AccountHeaderBtnTerminate;
