import { pick } from 'ramda';
import { fromJS, Map, List } from 'immutable';
import { CLEAR_LOCATION_STATE } from './account-actions';
import { doMoveGroup, doMoveItem } from './action-helpers';
import {
  SERVICES_LOADED,
  CUSTOM_VALUES_UPDATED,
  SERVICE_GROUP_ADDED,
  SERVICE_GROUP_UPDATED,
  SERVICE_GROUP_DELETED,
  SERVICE_ADDED,
  SERVICE_UPDATED,
  SERVICE_PREFS_UPDATED,
  SERVICE_ATTRIBS_UPDATED,
  SERVICE_MULTIVAT_UPDATED,
  SERVICE_MULTIRESOURCE_UPDATED,
  SERVICE_CUSTOM_FIELDS_UPDATED,
  SERVICE_DELETED,
  SERVICE_MOVED,
  SERVICE_GROUP_MOVED,
  SERVICE_RESOURCE_MAPPINGS_LOADED,
  SERVICE_RESOURCE_MAPPING_ADDED,
  SERVICE_RESOURCE_MAPPING_DELETED,
  SERVICE_RESOURCE_MAPPINGS_SET,
  SERVICE_ADDON_MAPPINGS_LOADED,
  SERVICE_ADDON_MAPPING_ADDED,
  SERVICE_ADDON_MAPPING_DELETED,
  serviceMappingValues
} from './services-actions';

export function servicesById(state = Map(), action = null) {
  switch (action.type) {
    case CLEAR_LOCATION_STATE:
      return state.clear();

    case SERVICES_LOADED: {
      return state.clear().withMutations((map) => {
        for (const service of action.services.services) {
          map.set(service.id, service);
        }
      });
    }
    case SERVICE_ADDED:
      return state.set(action.service.id, action.service);

    case SERVICE_UPDATED:
      return state.set(action.service.id, action.service);

    case SERVICE_PREFS_UPDATED: {
      const { srvId, prefs } = action;
      return state.set(srvId, { ...state.get(srvId), prefs });
    }

    case SERVICE_ATTRIBS_UPDATED: {
      const { srvId, attributes } = action;
      return state.set(srvId, { ...state.get(srvId), attributes });
    }

    case SERVICE_MULTIVAT_UPDATED: {
      const { srvId, multiVat, multiVatRows } = action;
      return state.set(srvId, { ...state.get(srvId), multiVat, multiVatRows });
    }

    case SERVICE_MULTIRESOURCE_UPDATED: {
      const { srvId, multiResource, multiResourceRules } = action;
      return state.set(srvId, { ...state.get(srvId), multiResource, multiResourceRules });
    }

    case SERVICE_CUSTOM_FIELDS_UPDATED: {
      const { srvId, customFields } = action;
      return state.set(srvId, { ...state.get(srvId), customFields });
    }

    case SERVICE_DELETED:
      return state.delete(action.serviceId);

    default:
      return state;
  }
}

export function orderedServiceGroups(state = List(), action = null) {
  switch (action.type) {
    case CLEAR_LOCATION_STATE:
      return state.clear();

    case SERVICES_LOADED:
      return fromJS(action.services.groups);

    case SERVICE_GROUP_MOVED: {
      const addonGroup = state.find(g => g.get('addon'));
      const groups = doMoveGroup(state.filter(g => !g.get('addon')), action.moveAction);
      return addonGroup ? groups.push(addonGroup) : groups;
    }

    case SERVICE_MOVED:
      return doMoveItem(state, action.moveAction, 'serviceIds');

    case SERVICE_GROUP_ADDED:
      return state.unshift(fromJS(action.group));

    case SERVICE_GROUP_UPDATED: {
      const { id, name, color } = action.group;
      const index = state.findIndex(g => g.get('id') === id);
      return state.setIn([index, 'name'], name).setIn([index, 'color'], color);
    }

    case SERVICE_GROUP_DELETED: {
      const index = state.findIndex(g => g.get('id') === action.groupId);
      return state.delete(index);
    }

    case SERVICE_ADDED: {
      const index = state.findIndex(g => g.get('id') === action.groupId);

      // Add automatically added addon service group
      if (index === -1 && action.service.addon) {
        return state.push(fromJS({
          id: action.groupId,
          name: 'Tilläggstjänster',
          serviceIds: [action.service.id],
          addon: true
        }));
      }
      const serviceIds = state.getIn([index, 'serviceIds']).unshift(action.service.id);
      return state.setIn([index, 'serviceIds'], serviceIds);
    }

    case SERVICE_DELETED: {
      const { serviceId } = action;

      let serviceIndex = -1;
      const groupIndex = state.findIndex((g) => {
        serviceIndex = g.get('serviceIds').indexOf(serviceId);
        return serviceIndex !== -1;
      });

      const serviceIds = state.getIn([groupIndex, 'serviceIds']).delete(serviceIndex);
      return state.setIn([groupIndex, 'serviceIds'], serviceIds);
    }
    default:
      return state;
  }
}

export function resourceServiceIds(state = Map(), action = null) {
  switch (action.type) {
    case CLEAR_LOCATION_STATE:
      return state.clear();

    case SERVICES_LOADED: {
      return state.clear().withMutations((map) => {
        for (const resource of action.services.resources) {
          map.set(resource.id, resource.serviceIds);
        }
      });
    }

    case SERVICE_RESOURCE_MAPPING_ADDED: {
      const { serviceId, resourceId } = action;
      const newServiceIds = [...state.get(resourceId), serviceId];
      return state.set(resourceId, newServiceIds);
    }

    case SERVICE_RESOURCE_MAPPING_DELETED: {
      const { serviceId, resourceId } = action;
      const newServiceIds = state.get(resourceId).filter(id => id !== serviceId);
      return state.set(resourceId, newServiceIds);
    }

    default:
      return state;
  }
}

export function resourceServiceMappingsByCombinedId(state = Map(), action = null) {
  switch (action.type) {
    case CLEAR_LOCATION_STATE:
      return state.clear();

    case SERVICE_RESOURCE_MAPPINGS_LOADED:
      return state.merge(action.serviceMappings);

    case SERVICE_RESOURCE_MAPPING_ADDED: {
      const { serviceId, resourceId, mapping } = action;
      return state.set(`${resourceId}:${serviceId}`, fromJS(mapping));
    }

    case SERVICE_RESOURCE_MAPPING_DELETED: {
      const { serviceId, resourceId } = action;
      return state.delete(`${resourceId}:${serviceId}`);
    }

    case SERVICE_RESOURCE_MAPPINGS_SET:
      return setServiceResourceMappings(state, action);

    case CUSTOM_VALUES_UPDATED: {
      const { serviceId, resourceId, mapping } = action;
      return state.set(`${resourceId}:${serviceId}`, fromJS(mapping));
    }

    case SERVICE_UPDATED:
      return setServiceMappingDefaultValues(state, action);

    default:
      return state;
  }
}

function setServiceResourceMappings(state, action) {
  const { serviceId, resourceIds, mapping } = action;
  const keys = resourceIds.map(resourceId => `${resourceId}:${serviceId}`);

  const newState = state.withMutations((state) => {
    state.forEach((value, key, iterator) => {
      if (value.get('serviceId') === serviceId && !keys.includes(key)) {
        iterator.delete(key);
      }
    });
  });

  return newState.withMutations((state) => {
    keys.forEach((key) => {
      if (!state.has(key)) {
        state.set(key, fromJS(mapping));
      }
    });
  });
}

function setServiceMappingDefaultValues(state, action) {
  const { service } = action;
  const values = pick(serviceMappingValues, service);

  return state.withMutations((mappings) => {
    mappings.forEach((mapping, key) => {
      if (mapping.get('serviceId') === service.id && !mapping.get('customValues')) {
        mappings.set(key, mapping.merge(values));
      }
    });
  });
}

export function addonServiceMappingsById(state = Map(), action = null) {
  switch (action.type) {
    case CLEAR_LOCATION_STATE:
      return state.clear();

    case SERVICE_ADDON_MAPPINGS_LOADED:
      return state.withMutations((map) => {
        for (const mapping of action.addonMappings) {
          map.set(mapping.srvId, mapping.addonSrvIds);
        }
      });

    case SERVICE_ADDON_MAPPING_ADDED: {
      const { serviceId, addonServiceId } = action;
      const mappings = List(state.get(serviceId)).push(addonServiceId);
      return state.set(serviceId, mappings);
    }

    case SERVICE_ADDON_MAPPING_DELETED: {
      const { serviceId, addonServiceId } = action;
      const mappings = List(state.get(serviceId)).filter(id => id !== addonServiceId);
      return state.set(serviceId, mappings);
    }

    default:
      return state;
  }
}
