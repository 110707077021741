import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import NoContent from '@Components/ui/no-content';
import { ReportTitle, ReportTitleButtons } from '@Components/pos/style';
import { deleteReleaseNote, toggleReleaseNotePublished } from '@State/sysadmin-actions';
import useMarkdownToHtml from '@Hooks/useMarkdownToHtml';
import Button from '@Components/ui/button';
import PopoverDeleteButton from '@Components/ui/popover-delete-button';
import LabelSwitchInput from '@Components/ui/label-switch-input';
import Panel from '@Components/ui/styled/panel';
import { Flex } from '@Components/ui/styled/main';
import Label from '@Components/ui/label';
import ReleaseNotesModal from '@Components/dialogs/release-notes-modal';
import ReleaseNotesEditModal from './release-notes-edit-modal';
import { ReleaseNoteLabels, getReleaseNoteColor } from './release-notes-helpers';
import ReleaseNotesImage from './release-notes-image';

const ReleaseNotesOverview = ({ routeParams }) => {
  const dispatch = useDispatch();
  const { id: routeId } = useParams();
  const [showEditModal, setShowEditModal] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);

  const releaseNote = useSelector(state => state.sysadminReleaseNotes.find(r => +r.get('id') === +routeId));
  const { title, label, date, imageUrl, published } = useMemo(() => releaseNote?.toJS() || {}, [releaseNote]);

  const textHtml = useMarkdownToHtml(releaseNote?.get('text'));

  const onDelete = () => dispatch(deleteReleaseNote(+routeId));
  const onTogglePublished = () => dispatch(toggleReleaseNotePublished(+routeId, !published));

  return (
    <>
      {!releaseNote ? (
        <div className="text-center">
          <NoContent icon="fa fa-bullhorn">
            Det finns inga release notes
          </NoContent>
        </div>
      ) : (
        <div>
          <ReportTitle>
            <div>
              <h3 className="mt0 mb2">{title}</h3>
              <Flex vCenter>
                <Label bgColor={getReleaseNoteColor(label)} className="mr1 mt0">
                  {ReleaseNoteLabels[label] || label}
                </Label>&nbsp;
                <span>
                  <i className={published ? 'far fa-check mr1' : 'far fa-cancel mr1'} />
                  {date}
                </span>
              </Flex>
            </div>
            <ReportTitleButtons className="hidden-print">
              <div className="release-note-publish">
                <LabelSwitchInput
                  noBorder
                  id="published"
                  label="Publicerad"
                  isOn={!!published}
                  handleToggle={onTogglePublished}
                  confirmEnable="Vill du publicera denna release note?"
                  confirmDisable="Vill du avpublicera denna release note?"
                />
              </div>
              <Button small gray marginRight onClick={() => setShowEditModal(true)}>
                Ändra
              </Button>
              <PopoverDeleteButton onDelete={onDelete} />
            </ReportTitleButtons>
          </ReportTitle><br />
          <Panel className="select-text">
            {/* eslint-disable-next-line react/no-danger */}
            <div dangerouslySetInnerHTML={{ __html: textHtml }} />
          </Panel>
          <ReleaseNotesImage id={+routeId} imageUrl={imageUrl} /><br />
          <Button small gray onClick={() => setShowPreviewModal(true)}>
            <i className="far fa-magnifying-glass mr1" />
            Förhandsgranska
          </Button>
        </div>
      )}
      {showEditModal && (
        <ReleaseNotesEditModal
          releaseNote={releaseNote}
          routeParams={routeParams}
          onClose={() => setShowEditModal(false)}
        />
      )}
      {showPreviewModal && (
        <ReleaseNotesModal
          preview={releaseNote.toJS()}
          onClose={() => setShowPreviewModal(false)}
        />
      )}
    </>
  );
};

export default ReleaseNotesOverview;
