import React from 'react';
import { useSelector } from 'react-redux';
import { groupBy, sortBy } from 'lodash';
import CurrencyUtil from '@Utils/currency-util';

import { txt } from '@Utils/i18n-util';
import Bouncefix from '../booking/bouncefix';
import msg from '../booking/search-service.msg';

const FindTimeSlots = ({ onCancel, onSelect, height, maxHeight }) => {
  const resourcesById = useSelector(state => state.resourcesById);
  const slots = useSelector(state => state.findTime.get('slots'));

  const styleBookingForm = { height, maxHeight };

  const slotsByDate = groupBy(slots, s => s.date);
  const dates = Object.keys(slotsByDate).sort();

  return (
    <div className="booking-form">
      <div className="booking-form-header">
        <div className="cancel">
          <a href="#" onClick={onCancel}>
            <i className="fa fa-chevron-left" /> Tillbaka
          </a>
        </div>
        <h4 className="title">
          {txt(msg.lblTitle)}
        </h4>
        <div className="save" />
      </div>

      <Bouncefix className="booking-form-body" style={styleBookingForm}>
        <div className="booking-form-suggestions-container">
          <div className="booking-form-suggestions">
            {dates.map((date) => {
              return (
                <>
                  <label>{date}</label>
                  {sortBy(slotsByDate[date], s => s.time)
                    .map((slot) => {
                      return (
                        <div className="booking-form-suggestion" onClick={() => onSelect(slot)}>
                          <strong>{slot.time}</strong>{' - '}
                          {resourcesById.get(slot.resourceId).name}{' '}
                          <span className="text-muted">
                            ({slot.length} min, {CurrencyUtil.accountCurrency(slot.price)})
                          </span>
                        </div>
                      );
                    })}
                </>
              );
            })}
          </div>
        </div>
      </Bouncefix>
    </div>
  );
};

export default FindTimeSlots;
