import axios from 'axios';
import { fromByteArray } from 'base64-js';
import { utf8ToBytes } from '@Utils/utf8tobytes';
import { axiosErrorHandler, handleInvalidToken, logError, prefixUrl } from '@Utils/ajax-util';
import { getAuthTokenCookie, setAuthTokenCookie } from '@Utils/cookies';

export const CONNECT_FAILED = 'CONNECT_FAILED';
export const AUTHENTICATING = 'AUTHENTICATING';
export const AUTH_FAILED = 'AUTH_FAILED';
export const USER_AUTHENTICATED = 'USER_AUTHENTICATED';
export const NO_AUTH_TOKEN = 'NO_AUTH_TOKEN';

function authenticating() {
  return {
    type: AUTHENTICATING
  };
}

function authenticated(userSession, accessToken) {
  localStorage.setItem('accessToken', accessToken);
  return {
    type: USER_AUTHENTICATED,
    userSession
  };
}

function authFailed() {
  return {
    type: AUTH_FAILED
  };
}

function networkFailure(response) {
  return {
    type: CONNECT_FAILED,
    response
  };
}

function authenticatedOauth(response, userSession) {
  const { access_token } = response;

  localStorage.setItem('accessToken', access_token);
  setAuthTokenCookie(access_token);

  return {
    type: USER_AUTHENTICATED,
    userSession
  };
}

export function getAccessToken() {
  // Give preference to cookie auth token
  //
  const cookie = getAuthTokenCookie();
  if (cookie) {
    localStorage.setItem('accessToken', cookie);
  }
  return localStorage.getItem('accessToken');
}

export function tryExistingAccessToken() {
  const accessToken = getAccessToken();
  const url = prefixUrl('/auth/user-session');

  return (dispatch) => {
    if (!accessToken) {
      dispatch({ type: NO_AUTH_TOKEN });
      return Promise.reject();
    }

    const request = {
      headers: { Authorization: `Bearer ${accessToken}` }
    };

    dispatch(authenticating());
    return axios.get(url, request)
      .then(response => response.data)
      .then(json => dispatch(authenticatedOauth({ access_token: accessToken }, json)))
      .catch(error => {
        axiosErrorHandler(error, dispatch);
        throw error;
      });
  };
}

export function authenticate(_authRequest) {
  const authRequest = _authRequest || { accessToken: localStorage.getItem('accessToken') };

  if (!(_authRequest || localStorage.getItem('accessToken'))) {
    return {
      type: NO_AUTH_TOKEN
    };
  }

  const url = prefixUrl('/oauth/token');

  return (dispatch) => {
    const clearText = 'cliento-login-client:';
    const accessToken = fromByteArray(utf8ToBytes(clearText));
    const fpp = `grant_type=password&client_id=cliento-login-client&username=${encodeURIComponent(authRequest.username)}&password=${encodeURIComponent(authRequest.password)}`;
    dispatch(authenticating());

    const userSession = {
      username: authRequest.username
    };

    return axios({
      method: 'post',
      url,
      data: fpp,
      headers: { Authorization: `Basic ${accessToken}` }
    })
      .then(res => res.data)
      .then(json => dispatch(authenticatedOauth(json, userSession)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          dispatch(authFailed());
        } else {
          logError(error);
          dispatch(networkFailure(error.message));
        }
      });
  };
}
