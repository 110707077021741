import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { setUserOperatorPin } from '@State/users-actions';
import LabelSwitchInput from '@Components/ui/label-switch-input';
import { Panel, Button, Flex } from '@Components/ui/styled/main';
import { FlexOne } from '@Components/pos/style';
import OperatorLogin from '@Components/pos/operator-login';
import StaffAssignCashierRoles from '@Components/admin/staff-config/staff-assign-cashier-roles';

const StaffPosRoles = ({
  dispatch, id, name, posRoles, operatorPin, isOn, onChangeToggle
}) => {
  const posOrgs = useSelector(state => state.posOrgs);
  const [showSetPin, setShowSetPin] = useState(false);
  const [showCashierRoles, setShowCashierRoles] = useState(false);
  const onShowCashierRoles = () => setShowCashierRoles(!showCashierRoles);
  const onHideCashierRoles = () => setShowCashierRoles(false);

  const onShowSetPin = (e) => {
    e.preventDefault();
    setShowSetPin(true);
  };

  const onHideSetPin = () => setShowSetPin(false);

  const onSetPin = async (pin) => {
    await dispatch(setUserOperatorPin(id, pin));
    onHideSetPin();
  };

  return (
    <>
      <Panel>
        <div className="form-group">
          <LabelSwitchInput
            noBorder
            label="Kassör"
            isOn={isOn}
            id="posRoleEnabled"
            handleToggle={onChangeToggle}
            description="Användaren kan använda kassaregistret"
          />
        </div>
        {isOn && (
          <>
            <div className="form-group">
              <Flex fullWidth>
                <FlexOne marginRight="15px">
                  <label className="control-label">Behörighet till kassor</label>
                  <div className="select-items-form-control" onClick={onShowCashierRoles}>
                    {posRoles?.length > 0
                      ? posRoles.map((r) => {
                        const posOrg = posOrgs.find(o => o.get('id') === r.posOrgId);
                        return <div key={r.posOrgId} className="item">{posOrg?.get('companyName')}</div>;
                      })
                      : <div className="no-content">Inga kassor valda</div>}
                  </div>
                </FlexOne>
                <div>
                  <label className="control-label">PIN-kod</label><br />
                  <Button small className="btn btn-default" onClick={onShowSetPin}>{operatorPin || '----'}</Button>
                </div>
              </Flex>
            </div>
            {/* Hide this until properly implemented in POS
            <Field
              name="srvPricesFromResourceId"
              component={SelectField}
              onChange={onChangeResource}
              label="Använd priser från resurs"
              helpText="Använd resursens priser på tjänster i kassan"
            >
              <option value="">Ingen resurs</option>
              {resourcesById.valueSeq().map(r => (
                <option key={r.id} value={r.id}>{r.name}</option>
              ))}
            </Field>
            */}
          </>
        )}
      </Panel>
      {showCashierRoles && (
        <StaffAssignCashierRoles
          onClose={onHideCashierRoles}
          name={name}
          id={id}
        />
      )}
      {showSetPin && (
        <OperatorLogin
          onSubmit={onSetPin}
          onCancel={onHideSetPin}
          title="Välj PIN-kod"
          loadingTitle="Sparar..."
        />
      )}
    </>
  );
};

StaffPosRoles.propTypes = {
  id: PropTypes.number,
  isOn: PropTypes.bool,
  onChangeToggle: PropTypes.func
};

export default StaffPosRoles;
