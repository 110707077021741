import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { formatPhoneNumber } from '@Utils/phone-util';
import CurrencyUtil from '@Utils/currency-util';
import ModalDialog from '@Components/dialogs/modal-dialog';
import { getUseKlarnaPayments } from '@Utils/pos-utils';
import { cancelPayment, fetchPaymentStatus, paymentResult } from '@State/pos-actions';
import Text from '@Components/ui/styled/text-element';
import Panel from '@Components/ui/styled/panel';
import { Flex } from '@Components/ui/styled/main';
import Loader from '@Components/ui/loader';
import DialogAlert from '@Components/dialogs/dialog-alert';
import PaymentStatusUpdate from './payment-status-update';

const ContentContainer = styled.div`
  margin: 10px 20px 20px 20px;
  text-align: center;
`;

const LoaderContainer = styled.div`
  position: relative;
  height: 100px;
`;

const KlarnaPayment = ({
  totalAmount, onSubmit, onClose, posOrgPrefs, customer, transactions
}) => {
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const posUnit = useSelector(state => state.pos.get('posUnit'));
  const transactionStatus = useSelector(({ posSale }) => {
    return posSale.get('transactionStatus') || posSale.getIn(['txProgress', 'transactionStatus']);
  });
  const saleId = useSelector(state => state.posSale.get('id'));
  const qrCodeUrl = useSelector(state => state.posSale.get('qrCodeUrl'));

  const useKlarnaPayments = getUseKlarnaPayments(posOrgPrefs, transactions);
  const customerPhoneNumber = useKlarnaPayments
    ? formatPhoneNumber(customer?.phoneNumber)
    : null;

  const [phoneNumber, setPhoneNumber] = useState(customerPhoneNumber || '');
  const [editPhoneNumber, setEditPhoneNumber] = useState(!customerPhoneNumber);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [editPhoneNumber]);

  const onResetPaymentResult = () => {
    dispatch(paymentResult(posUnit.get('vunitId'), null));
  };

  const onRecipientChange = (ev) => {
    setPhoneNumber(ev.target.value);
  };

  const onEditPhoneNumber = () => {
    setEditPhoneNumber(true);
    setPhoneNumber('');
  };

  if (transactionStatus === 'Rejected') {
    return (
      <DialogAlert
        warning
        icon="fa fa-exclamation-triangle"
        text="Betalning kunde inte genomföras, eller avbröts av användaren"
        onClose={onResetPaymentResult}
      />
    );
  }

  if (transactionStatus === 'InProgress') {
    return (
      <ModalDialog
        title="Betala med Klarna"
        hideCloseButton
        closeOnClickOutside={false}
        buttons={[{
          name: 'Avbryt',
          gray: true,
          onClick: () => dispatch(cancelPayment(saleId))
            .then(onResetPaymentResult)
        }]}
      >
        <ContentContainer>
          <PaymentStatusUpdate
            saleId={saleId}
            fetchPaymentStatus={() => dispatch(fetchPaymentStatus(saleId))}
          />
          {qrCodeUrl ? (
            <img src={qrCodeUrl} className="pos-klarna-qr-code" />
          ) : (
            <LoaderContainer>
              <Loader />
            </LoaderContainer>
          )}
          <Text>
            Öppna betalningslänk i SMS eller scanna
            QR-koden för att genomföra betalningen
          </Text>
        </ContentContainer>
      </ModalDialog>
    );
  }

  return (
    <ModalDialog
      title="Betala med Klarna"
      onClose={onClose}
      closeOnClickOutside={false}
      onEnter={() => onSubmit(totalAmount, useKlarnaPayments, phoneNumber)}
      buttons={[useKlarnaPayments ? {
        name: 'Skicka betalningslänk',
        primary: true,
        disabled: !phoneNumber,
        onClick: () => onSubmit(totalAmount, true, phoneNumber)
      } : {
        name: 'Betalning skapad',
        primary: true,
        onClick: () => onSubmit(totalAmount, false)
      }, useKlarnaPayments && !qrCodeUrl && {
        name: 'Visa QR-kod',
        gray: true,
        onClick: () => onSubmit(totalAmount, true)
      }]}
    >
      <ContentContainer>
        <Text marginBottom={!qrCodeUrl} fs={30}>{CurrencyUtil.accountCurrency(totalAmount, 2)}</Text>
        {!useKlarnaPayments && (
          <Text muted>
            Skapa betalning i Klarna och bekräfta för att slutföra köpet
          </Text>
        )}
      </ContentContainer>
      {useKlarnaPayments && !qrCodeUrl && (
        <Panel onClick={editPhoneNumber ? null : onEditPhoneNumber}>
          {editPhoneNumber ? (
            <Flex>
              <img src="/klarna.svg" className="pos-phone-input-logo" />
              <input
                type="tel"
                ref={inputRef}
                value={phoneNumber}
                placeholder="Ange mobilnummer"
                className="pos-phone-input-number"
                onChange={onRecipientChange}
              />
            </Flex>
          ) : (
            <Flex>
              <img src="/klarna.svg" className="pos-phone-input-logo" />
              <div className="pos-phone-input-number">{customerPhoneNumber}</div>
              <i className="fa fa-times-circle pos-phone-input-edit" />
            </Flex>
          )}
        </Panel>
      )}
    </ModalDialog>
  );
};

export default KlarnaPayment;
