import React from 'react';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import { reportingCount, reportingFormat } from '@Utils/format';
import { getTotal } from '@Components/reports/reports-helpers';
import { getItemDescription } from '@Utils/pos-utils';

const SalesReportItemRows = ({ salesRows }) => {
  const groupedItems = groupBy(salesRows, r => r.group || 'Övrigt');
  const groups = Object.keys(groupedItems).sort();

  return (
    <>
      {groups.map((group) => (
        <table key={group} className="table table-condensed table-report">
          <thead>
            <tr>
              <th className="col-xs-4">{group}</th>
              <th className="text-right">Antal köp</th>
              <th className="text-right">Antal återköp</th>
              <th className="text-right">Återköpt</th>
              <th className="text-right">Rabatter</th>
              <th className="text-right">Exkl. moms</th>
              <th className="text-right">Inkl. moms</th>
            </tr>
          </thead>
          <tbody>
            {orderBy(groupedItems[group], ['netAmount'], ['desc']).map(({
              description, type, saleQty, refundQty, refundAmount, discountAmount, netAmountExVat, netAmount
            }, index) => (
              <tr key={index}>
                <td className="child">{getItemDescription(description, type)}</td>
                <td className="text-right">{reportingCount(saleQty)}</td>
                <td className="text-right">{reportingCount(refundQty)}</td>
                <td className="text-right">{reportingFormat(refundAmount)}</td>
                <td className="text-right">{reportingFormat(discountAmount)}</td>
                <td className="text-right">{reportingFormat(netAmountExVat)}</td>
                <td className="text-right">{reportingFormat(netAmount)}</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <th>Summa</th>
              <th className="text-right">{reportingCount(getTotal(groupedItems[group], 'saleQty'))}</th>
              <th className="text-right">{reportingCount(getTotal(groupedItems[group], 'refundQty'))}</th>
              <th className="text-right">{reportingFormat(getTotal(groupedItems[group], 'refundAmount'))}</th>
              <th className="text-right">{reportingFormat(getTotal(groupedItems[group], 'discountAmount'))}</th>
              <th className="text-right">{reportingFormat(getTotal(groupedItems[group], 'netAmountExVat'))}</th>
              <th className="text-right">{reportingFormat(getTotal(groupedItems[group], 'netAmount'))}</th>
            </tr>
          </tfoot>
        </table>
      ))}
    </>
  );
};

export default SalesReportItemRows;
