import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import { required, trimString } from '@Utils/validation';
import TextInput from '@Components/inputs/text-input';
import ModalDialog from '@Components/dialogs/modal-dialog';

const ProductAddBarcode = ({
  handleSubmit, submitting, invalid, onClose, onSubmit
}) => {
  const [errorMessage, setErrorMessage] = useState(null);

  const onAddBarcode = (values) => {
    return onSubmit(values)
      .then(() => onClose())
      .catch((error) => setErrorMessage(error.message));
  };

  return (
    <ModalDialog
      contentSize="small"
      onClose={onClose}
      title="Lägg till streckkod"
      buttons={[{
        name: 'Spara',
        primary: true,
        onClick: handleSubmit(onAddBarcode),
        loading: submitting,
        disabled: invalid
      }]}
    >
      <form onSubmit={handleSubmit(onAddBarcode)} autoComplete="off" className="dialog">
        <Field
          type="text"
          name="barcode"
          component={TextInput}
          label="Scanna eller skriv in kod"
          meta={{ touched: true, error: errorMessage }}
          normalize={trimString}
          validate={required}
          autoFocus
        />
      </form>
    </ModalDialog>
  );
};

export default reduxForm({
  form: 'product-add-barcode'
})(ProductAddBarcode);
