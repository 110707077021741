import React from 'react';
import { classes } from '@Components/ui/utils';
import '@Components/inputs/checkbox-styled.less';

const CheckBoxEl = ({
  input,
  label,
  leftPosition = false,
  circle = false,
  center = false,
  disabled = false,
  onClick = null,
  children = null
}) => {
  const classList = classes({
    checkboxEl: true,
    circle,
    disabled,
    center,
    leftPosition
  });

  return (
    <div className={classList} onClick={onClick}>
      <input id={input.name} type="checkbox" checked={input.value} {...input} readOnly />
      <label htmlFor={input.name}>
        {label}
        {children}
      </label>
    </div>
  );
};

export default CheckBoxEl;
