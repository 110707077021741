import { defineMessages } from 'react-intl';

export default defineMessages({
  btnCancel: {
    id: 'app.confirmBlock.btnCancel',
    defaultMessage: 'Cancel'
  },
  btnDelete: {
    id: 'app.confirmBlock.btnDelete',
    defaultMessage: 'Delete'
  }
});
