import React, { useCallback, useContext, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import AccountOverviewContext from '@Components/sysadmin/accounts/account-detail/account-overview/account-overview-context';
import AccountOverviewBlock from '@Components/sysadmin/accounts/account-detail/account-overview/account-overview-block';
import ButtonModalForm from '@Components/dialogs/button-modal-form';
import { updateCreditAmount, updatePaidUntil, updateTrialDays } from '@State/sysadmin-actions';
import AccountOverviewTrialDaysForm
  from '@Components/sysadmin/accounts/account-detail/account-overview/account-overview-trial-days-form';
import AccountPaidUntilForm
  from '@Components/sysadmin/accounts/account-detail/account-overview/account-overview-paid-until-form';
import AccountCreditAmountForm
  from '@Components/sysadmin/accounts/account-detail/account-overview/account-overview-credit-amount-form';

const dateFormat = (string) => string ? moment(string).format('YYYY-MM-DD') : '-';

const priceFormat = (string) => `${string} kr`;

const placeholder = (v) => !v ? '-' : v;

const accountFields = [
  [{ prop: 'orgName', title: 'overviewOrgName' }, { prop: 'companyRegNo', title: 'overviewCompanyRegNo' }],
  [
    { prop: 'name', title: 'Kundens för- och efternamn', renderText: placeholder },
    { prop: 'email', title: 'E-postadress', renderText: placeholder },
    { prop: 'phone', title: 'Telefonnummer', renderText: placeholder }
  ]
];

const AccountOverviewCompany = () => {
  const dispatch = useDispatch();
  const { id, subscription } = useContext(AccountOverviewContext);
  const haveTrial = !!subscription.get('trial');

  const onSubmitTrialDays = useCallback(async (data) => {
    return dispatch(updateTrialDays({ orgId: id, ...data }));
  }, [id]);

  const onSubmitSetPaid = useCallback(async (values = {}) => {
    if (moment(values.paidUntil).isValid()) {
      return dispatch(updatePaidUntil({ orgId: id, paidUntil: values.paidUntil }));
    }
  }, [id]);

  const onSubmitSetAmount = useCallback(async (values = {}) => {
    return dispatch(updateCreditAmount({ orgId: id, amount: values.amount }));
  }, [id]);

  const statusFields = useMemo(() => [
    [
      { prop: 'created', title: 'Skapad datum', format: dateFormat },
      { prop: 'paidUntil',
        title: 'Betalt t.om.',
        renderText: (value) => (
          <ButtonModalForm btnText={dateFormat(value)} link onSubmit={onSubmitSetPaid}>
            <AccountPaidUntilForm initialValues={{ paidUntil: value }} />
          </ButtonModalForm>
        ) },
      { getProp: (s) => dateFormat(s.signup?.sfLeadCreatedTs), title: 'Lead skapat' }
    ],
    ...(haveTrial ? [[
      {
        prop: 'trialDays',
        title: 'overviewTrialDays',
        renderText: (value) => (
          <ButtonModalForm btnText={`${value} dagar`} link onSubmit={onSubmitTrialDays}>
            <AccountOverviewTrialDaysForm initialValues={{ trialDays: value }} />
          </ButtonModalForm>
        )
      },
      { prop: 'trialEnd', title: 'overviewTrialEnd', format: dateFormat }
    ]] : []),
    [
      { prop: 'activationTime', title: 'Aktiverat', format: dateFormat },
      { prop: 'paymentMethod', title: 'Betalsätt' },
      { prop: 'stripeCustId', title: 'Stripe ID' }
    ],
    [
      { prop: 'creditAmount',
        title: 'Kreditbelopp',
        renderText: (value) => (
          <ButtonModalForm btnText={priceFormat(value)} link onSubmit={onSubmitSetAmount}>
            <AccountCreditAmountForm initialValues={{ creditAmount: value }} />
          </ButtonModalForm>
        )
      },
      { prop: 'monthlyDiscount', title: 'Månadsrabatt', format: priceFormat },
      { prop: 'smsBalance', title: 'SMS kredit', format: priceFormat }
    ]
  ], [onSubmitSetAmount, onSubmitSetPaid, subscription]);

  const values = useMemo(() => ({
    ...subscription.toJS(),
    ...(haveTrial && subscription.get('trial').toJS()),
    ...subscription?.getIn(['contacts', 'Owner'])?.toJS()
  }), [subscription]);

  return (
    <>
      <AccountOverviewBlock data={values} title="Företagsuppgifter" columnsQuan={3} columns={accountFields} />
      <AccountOverviewBlock data={values} title="Status" columnsQuan={3} columns={statusFields} />
    </>
  );
};

export default AccountOverviewCompany;
