import React from 'react';
import { classes } from '@Components/ui/utils';
import PulseLoader from '@Components/ui/pulse-loader';

const SelectField = ({
  input, children, placeholder, label, helpText, disabled,
  meta: { touched, error }, className, width, loading
}) => {
  const classNames = classes({
    'form-group': true,
    'has-error': touched && error,
    [className]: className
  });

  return (
    <div className={classNames}>
      {label && <label className="control-label" htmlFor={input.name}>{label}</label>}
      <div className="select-container" style={{ width }}>
        {loading ? <PulseLoader className="select-loader" /> : null}
        <select className="form-control" placeholder={placeholder} id={input.name} disabled={disabled} {...input}>
          {children}
        </select>
      </div>
      {touched && error && <span className="help-block">{error}</span>}
      {helpText && <span className="form-control-description">{helpText}</span>}
    </div>
  );
};

export default SelectField;
