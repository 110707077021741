import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUserTitle } from '@State/users-actions';
import StaffAssignResources from '@Components/admin/staff-config/staff-assign-resources';
import Panel from '@Components/ui/styled/panel';
import AutoSaveTextInput from '@Components/inputs/auto-save-text-input';

const StaffResources = ({ userId }) => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.usersById.find((user) => user.get('id') === userId));

  const [showAssignResources, setShowAssignResources] = useState(false);
  const onShowAssignResources = () => setShowAssignResources(true);
  const onHideAssignResources = () => setShowAssignResources(false);

  const handleSave = useCallback(({ title }) => {
    dispatch(setUserTitle(userId, title));
  }, [user]);

  return user ? (
    <Panel>
      <div className="form-group">
        <label className="control-label">Koppla till resurser</label>
        <div className="select-items-form-control" onClick={onShowAssignResources}>
          {!user.get('associatedResources').isEmpty()
            ? user.get('associatedResources').map(r => (
              <div key={r.get('resourceId')} className="item">{r.get('resourceName')}</div>
            ))
            : <div className="no-content">Ingen resurs</div>}
        </div>
      </div>
      {showAssignResources && (
        <StaffAssignResources user={user} onClose={onHideAssignResources} />
      )}
      <AutoSaveTextInput
        name="title"
        label="Titel/benämning"
        placeholder="T.ex. Frisör, Terapeut eller Mekaniker"
        initialValue={user.get('title')}
        onSave={handleSave}
      />
    </Panel>
  ) : null;
};

export default StaffResources;
