import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pos } from '@Utils/preference-keys';
import { mergeOrgSettings } from '@State/pos-config-actions';
import { getPosOrgPaymentMethodOtherPrefs } from '@State/pos-config-selectors';
import PosOrgConfigToggle from './pos-org-config-toggle';
import PosOrgOtherForm from './pos-org-other-form';

const PosOrgPaymentOther = (props) => {
  const { posOrg, number } = props;
  const dispatch = useDispatch();

  const handleSubmit = useCallback((values) => {
    dispatch(mergeOrgSettings(posOrg.get('id'), values));
  }, [posOrg]);

  const prefix = pos.paymentMethodOther + number;
  const otherPrefs = useSelector(state => getPosOrgPaymentMethodOtherPrefs(state, props));

  return (
    <PosOrgConfigToggle
      posOrg={posOrg}
      preference={pos.paymentMethodOther + number}
      label="Annat betalsätt"
      description="Visa annat betalsätt i kassan, som tex. kuponger"
    >
      <PosOrgOtherForm
        prefix={prefix}
        form={`${prefix}-form`}
        initialValues={otherPrefs}
        onSubmit={handleSubmit}
      />
    </PosOrgConfigToggle>
  );
};

export default PosOrgPaymentOther;
