import axios from 'axios';
import moment from 'moment';
import {
  axiosDefault,
  axiosErrorHandler,
  checkStatusAxios,
  prefixUrl
} from '@Utils/ajax-util';

export const DELIVERIES_FETCHED = 'DELIVERIES_FETCHED';
export const DELIVERY_FETCHED = 'DELIVERY_FETCHED';
export const DELIVERY_CREATED = 'DELIVERY_CREATED';
export const DELIVERY_DELETED = 'DELIVERY_DELETED';
export const DELIVERY_FINISHED = 'DELIVERY_FINISHED';
export const DELIVERY_ITEM_ADDED = 'DELIVERY_ITEM_ADDED';
export const DELIVERY_ITEM_UPDATED = 'DELIVERY_ITEM_UPDATED';
export const DELIVERY_ITEM_DELETED = 'DELIVERY_ITEM_DELETED';

export function fetchDeliveries() {
  return dispatch => {
    const url = prefixUrl('/inventory/delivery/');
    const config = axiosDefault();

    return axios
      .get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then(({ result }) => dispatch({ type: DELIVERIES_FETCHED, items: result }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function fetchDelivery(id) {
  return dispatch => {
    const url = prefixUrl(`/inventory/delivery/${id}`);
    const config = axiosDefault();

    return axios
      .get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then(delivery => dispatch({ type: DELIVERY_FETCHED, delivery }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function createDelivery(delivery) {
  return dispatch => {
    const url = prefixUrl('/inventory/delivery/create');
    const config = axiosDefault();

    return axios
      .post(url, delivery, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then(({ id }) => {
        dispatch({ type: DELIVERY_CREATED, delivery: { ...delivery, id } });
        return id;
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function deleteDelivery(deliveryId) {
  return dispatch => {
    const url = prefixUrl(`/inventory/delivery/${deliveryId}`);
    const config = axiosDefault();

    return axios
      .delete(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then(() => dispatch({ type: DELIVERY_DELETED, deliveryId }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function addDeliveryItem(deliveryItem) {
  return dispatch => {
    const { deliveryId, itemId, price, deliveredQuantity } = deliveryItem;
    const url = prefixUrl(`/inventory/delivery/${deliveryId}/items/${itemId}/delivery`);
    const config = axiosDefault();

    return axios
      .post(url, { priceIn: price, deliveredQuantity }, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then(({ deliveryRowId }) => {
        const newDeliveryItem = { ...deliveryItem, deliveryRowId };
        dispatch({ type: DELIVERY_ITEM_ADDED, deliveryItem: newDeliveryItem });
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function updateDeliveryRow(deliveryItem) {
  const { deliveryId, deliveryRowId, deliveredQuantity, remainingQuantity, price } = deliveryItem;
  return (dispatch) => {
    const url = prefixUrl(`/inventory/delivery/${deliveryId}/rows/${deliveryRowId}`);
    const config = axiosDefault();

    return axios
      .put(url, { deliveredQuantity, remainingQuantity, priceIn: price }, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then(data => {
        dispatch({ type: DELIVERY_ITEM_UPDATED, deliveryItem });
        return data;
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function deleteDeliveryRow(deliveryItem) {
  const { deliveryId, deliveryRowId } = deliveryItem;
  return (dispatch) => {
    const url = prefixUrl(`/inventory/delivery/${deliveryId}/rows/${deliveryRowId}`);
    const config = axiosDefault();

    return axios
      .delete(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then(data => {
        dispatch({ type: DELIVERY_ITEM_DELETED, deliveryItem });
        return data;
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function setDeliveryFinished(deliveryId, comment = null) {
  return dispatch => {
    const url = prefixUrl(`/inventory/delivery/${deliveryId}/commit`);
    const config = axiosDefault();

    return axios
      .post(url, { comment }, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then(() => dispatch({ type: DELIVERY_FINISHED, deliveryId, finishedTs: moment().toISOString() }))
      .catch(error => {
        axiosErrorHandler(error, dispatch);
        throw error;
      });
  };
}
