import React from 'react';
import Panel from '@Components/ui/styled/panel';
import { useFormContext } from 'react-hook-form';

const PanelRadio = ({ input, value, disabled = false, label, description = '', children = null }) => {
  const { watch, setValue } = useFormContext();
  const [selectedValue] = watch([input.name]);
  const isSelected = selectedValue === value;

  const handleClick = (ev) => {
    ev.stopPropagation();
    setValue(input.name, value);
  };

  return (
    <Panel onClick={!isSelected && !disabled ? handleClick : null}>
      <div className={disabled ? 'radio panel-radio disabled' : 'panel-radio radio'}>
        <label>
          <input type="radio" value={value} {...input} />
          {label}
          <p>{description}</p>
        </label>
      </div>
      {isSelected && children && (
        <div className="panel-radio-children">
          {children}
        </div>
      )}
    </Panel>
  );
};

export default PanelRadio;
