import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import FormButtonsFooter from '@Components/ui/form-buttons-footer';
import HookFormInput from '@Components/inputs/hook-form-input';

const fields = [
  { id: 'companyName', label: 'Company name', required: true },
  { id: 'companyRegNo', label: 'Company reg. no.', required: true },
  { id: 'companyVatNo', label: 'Company VAT no.', required: true },
  { id: 'customerRef', label: 'Customer ref.' },
  { id: 'address1', label: 'Address', required: true },
  { id: 'address2', label: '' },
  { id: 'postCode', label: 'Post code', required: true },
  { id: 'area', label: 'Area', required: true },
  {
    id: 'countryCode',
    label: 'Country code',
    type: 'select',
    options: [{ value: 'SE', title: 'Sweden' }, { value: 'FI', title: 'Finland' }],
    required: true
  },
  {
    id: 'vatType',
    label: 'VAT type',
    type: 'select',
    options: [{ value: 'SEVAT', title: 'Sweden' }, { value: 'EUVAT', title: 'EU' }, { value: 'EXPORT', title: 'Åland' }],
    required: true
  },
  { id: 'invoiceEmail', label: 'Invoice email', required: true },
  {
    id: 'invoiceMethod',
    label: 'Invoice method',
    type: 'select',
    options: [{ value: 'Email', title: 'E-mail' }, { value: 'Post', title: 'Post' }, { value: 'EFaktura', title: 'E-faktura' }],
    required: true
  },
  {
    id: 'invoiceInterval',
    label: 'Invoice interval',
    type: 'select',
    options: [{ value: 'Monthly', title: 'Monthly' }, { value: 'Quaterly', title: 'Quaterly' }, { value: 'Manual', title: 'Manual' }],
    required: true
  }
];

const AccountInvoiceCustomerForm = ({ onClose, onSubmit, initialValues = {}, loading }) => {
  const methods = useForm({ defaultValues: initialValues });
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {fields.map(({ id, label, required, type, options }) => (
          <HookFormInput
            key={id}
            label={label}
            id={id}
            options={options}
            initialValue={initialValues[id]}
            type={type}
            registerOptions={{ required }}
          />
        ))}
        <FormButtonsFooter
          onClose={onClose}
          handleSubmit={methods.handleSubmit}
          submitting={loading}
        />
      </form>
    </FormProvider>
  );
};

export default AccountInvoiceCustomerForm;
