import React from 'react';
import { reportingFormat, reportingCount } from '@Utils/format';
import { Row, Panel } from '@Components/ui/styled/main';
import { FlexOne, ReportTextLabel } from '@Components/pos/style';

const SalesReportSummaryTotal = ({
  refundCount: totalRefundCount, saleCount: totalSaleCount, totalRefundsAmount,
  netAmount, discountSummary, tipSummary, transactionTypeSummary, totalSalesAmount
}) => {
  const { count: saleCount, totalExVat: saleTotalExVat, total: saleTotal } = transactionTypeSummary?.Sale || {};
  const { count: refundCount, totalExVat: refundTotalExVat, total: refundTotal } = transactionTypeSummary?.Refund || {};
  const { quantity: discountQuantity, amount: discountAmount } = discountSummary || {};
  const { quantity: tipQuantity, amount: tipAmount } = tipSummary || {};
  return (
    <>
      <Panel>
        <Row>
          <FlexOne>
            <ReportTextLabel>
              <span>Bruttoförsäljning</span>
              <p className="mb0">{reportingFormat(totalSalesAmount)}</p>
            </ReportTextLabel>
          </FlexOne>
          <FlexOne>
            <ReportTextLabel>
              <span>Återköpt</span>
              <p className="mb0">{reportingFormat(totalRefundsAmount)}</p>
            </ReportTextLabel>
          </FlexOne>
          <FlexOne>
            <ReportTextLabel>
              <span>Nettoförsäljning</span>
              <p className="mb0">{reportingFormat(netAmount)}</p>
            </ReportTextLabel>
          </FlexOne>
          <FlexOne>
            <ReportTextLabel>
              <span>Antal köp</span>
              <p className="mb0">{reportingCount(totalSaleCount)}</p>
            </ReportTextLabel>
          </FlexOne>
          <FlexOne>
            <ReportTextLabel>
              <span>Antal återköp</span>
              <p className="mb0">{reportingCount(totalRefundCount)}</p>
            </ReportTextLabel>
          </FlexOne>
        </Row>
      </Panel>
      {(saleCount > 0 || refundCount > 0) && (
        <>
          <h5 className="mt3">Försäljningsöversikt</h5>
          <Panel>
            <table className="table table-condensed table-report">
              <thead>
                <tr>
                  <th>Typ</th>
                  <th className="col-xs-3 text-right">Exkl. moms</th>
                  <th className="col-xs-3 text-right">Inkl. moms</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Försäljning</td>
                  <td className="col-xs-3 text-right">{reportingFormat(saleTotalExVat)}</td>
                  <td className="col-xs-3 text-right">{reportingFormat(saleTotal)}</td>
                </tr>
                <tr>
                  <td>Returer</td>
                  <td className="col-xs-3 text-right">{reportingFormat(refundTotalExVat)}</td>
                  <td className="col-xs-3 text-right">{reportingFormat(refundTotal)}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>Summa</th>
                  <th className="col-xs-3 text-right">{reportingFormat((saleTotalExVat || 0) + (refundTotalExVat || 0))}</th>
                  <th className="col-xs-3 text-right">{reportingFormat((saleTotal || 0) + (refundTotal || 0))}</th>
                </tr>
              </tfoot>
            </table>
            {(discountQuantity > 0 || tipQuantity > 0) && (
              <table className="table table-condensed table-report">
                <thead>
                  <tr>
                    <th>Typ</th>
                    <th className="col-xs-3 text-right">Antal</th>
                    <th className="col-xs-3 text-right">Summa</th>
                  </tr>
                </thead>
                <tbody>
                  {discountQuantity > 0 && (
                    <tr>
                      <td>Rabatter</td>
                      <td className="col-xs-3 text-right">{reportingCount(discountQuantity)}</td>
                      <td className="col-xs-3 text-right">{reportingFormat(discountAmount)}</td>
                    </tr>
                  )}
                  {tipQuantity > 0 && (
                    <tr>
                      <td>Dricks</td>
                      <td className="col-xs-3 text-right">{reportingCount(tipQuantity)}</td>
                      <td className="col-xs-3 text-right">{reportingFormat(tipAmount)}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </Panel>
        </>
      )}
    </>
  );
};

export default SalesReportSummaryTotal;
