import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getSectionUrl, navigate } from '@Utils/navigate';
import { getDateText, getStaffReportDefaultLabel, reportsQuerySelector } from '@Components/reports/reports-helpers';
import { getStaffReports, deleteStaffReport, downloadStaffReportPdf } from '@State/report-actions';
import Button from '@Components/ui/button';
import PopoverDeleteButton from '@Components/ui/popover-delete-button';
import StaffReportSnapshot from './staff-report-snapshot';
import StaffReportRows from './staff-report-rows';

class StaffReport extends Component {
  state = {
    downloading: false
  };

  componentDidMount() {
    const { reportsViewState } = this.props;
    const staffReports = reportsViewState.get('staffReports');
    if (!staffReports) {
      this.props.getStaffReports();
    }
  }

  handleDownloadReport = (ev) => {
    ev.preventDefault();
    ev.target.blur();
    const { routeParams } = this.props;
    const reportId = parseInt(routeParams.id);
    this.setState({ downloading: true });
    this.props.downloadStaffReportPdf(reportId)
      .then(() => setTimeout(() => this.setState({ downloading: false }), 5000));
  };

  handleDeleteReport = (reportId) => {
    return this.props.deleteStaffReport(reportId)
      .then(() => navigate(getSectionUrl(this.props.routeParams, 'reports/staff')));
  };

  render() {
    const { reportsViewState, routeParams } = this.props;
    const staffReport = reportsViewState.get('staff');
    const staffReports = reportsViewState.get('staffReports');
    const snapshotData = staffReport && !staffReport.isEmpty();
    const report = staffReports?.find(r => r.get('reportId') === parseInt(routeParams.id));

    return (
      <>
        {snapshotData && !report && this.renderSnapshotData(staffReport.toJS())}
        {report && this.renderReportData(report)}
      </>
    );
  }

  renderSnapshotData(staffReport) {
    const { staffState, reportsQuery } = this.props;
    const { entries } = staffReport;
    return (
      <StaffReportSnapshot
        startDate={reportsQuery.startDate}
        endDate={reportsQuery.endDate}
        staffState={staffState}
        entries={entries}
      />
    );
  }

  renderReportData(report) {
    const startDate = moment(report.get('startDate'));
    const endDate = moment(report.get('endDate'));
    const hasApprovedRow = report.get('rows')?.find(r => r.get('approvedTs'));

    return (
      <div>
        <div className="pull-right">
          <Button
            loading={this.state.downloading}
            onClick={this.handleDownloadReport}
            marginRight={!hasApprovedRow}
            gray
            small
          >
            Ladda ner
          </Button>
          {!hasApprovedRow && (
            <PopoverDeleteButton onDelete={() => this.handleDeleteReport(report.get('reportId'))} />
          )}
        </div>
        <h3 className="mt0 mb3">
          {report.get('label') || getStaffReportDefaultLabel(startDate)}<br />
          <small>{getDateText(startDate, endDate)}</small>
        </h3>
        <StaffReportRows report={report.toJS()} />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { staffState, reportsViewState, resourcesById } = state;

  return {
    staffState,
    resourcesById,
    reportsViewState,
    routeParams: ownProps.match.params,
    reportsQuery: reportsQuerySelector(state, ownProps)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getStaffReports: () => dispatch(getStaffReports()),
    deleteStaffReport: (reportId) => dispatch(deleteStaffReport(reportId)),
    downloadStaffReportPdf: (reportId) => dispatch(downloadStaffReportPdf(reportId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StaffReport);
