import React, { useState } from 'react';
import styled from 'styled-components';
import CustomerDetailLogImageOverlay from './customer-detail-log-image-overlay';

const ImagesContainer = styled.div`
  max-width: 100%;
  overflow: auto;
  margin-top: 10px;

  &:first-child {
    margin-top: 0;
  }
`;

const Image = styled.img`
  height: 80px;
  margin-bottom: 5px;
  margin-right: 10px;
  border-radius: 5px;
  cursor: pointer;
`;

const CustomerDetailLogImages = ({ images }) => {
  const [showImage, setShowImage] = useState(null);

  const imageClick = (ev, image) => {
    ev.stopPropagation();
    setShowImage(image);
  };

  const hideImage = (ev) => {
    if (typeof ev?.stopPropagation === 'function') {
      ev.stopPropagation();
    }
    setShowImage(null);
  };

  const handlePrevious = () => {
    const index = images.indexOf(showImage);
    if (index > 0) {
      setShowImage(images[index - 1]);
    }
  };

  const handleNext = () => {
    const index = images.indexOf(showImage);
    if (index < images.length - 1) {
      setShowImage(images[index + 1]);
    }
  };

  return images?.length > 0 && (
    <>
      <ImagesContainer>
        {images.map((image) => (
          <Image
            key={image.id}
            src={image.url}
            onClick={(ev) => imageClick(ev, image)}
          />
        ))}
      </ImagesContainer>
      {showImage && (
        <CustomerDetailLogImageOverlay
          image={showImage}
          onClose={hideImage}
          onPrevious={handlePrevious}
          onNext={handleNext}
        />
      )}
    </>
  );
};

export default CustomerDetailLogImages;
