import React, { memo, useCallback } from 'react';
import { Flex } from '@Components/ui/styled/main';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSysadminStateControlFilterType,
  getSysadminStateControlSort
} from '@Components/sysadmin/accounts/accounts-selector';
import { toggleSysadminControl } from '@State/sysadmin-actions';
import { accountsRadioFields, getSysAdminSortLabelByFilter } from '@Utils/sysadmin-util';
import RadioLabel from '@Components/ui/radio-label';
import { AccountsFilterStyled } from '@Components/sysadmin/accounts/accounts-styles';

const AccountsFilter = ({ onFilter, notInUse }) => {
  const dispatch = useDispatch();
  const filterType = useSelector(state => getSysadminStateControlFilterType(state));
  const sort = useSelector(state => getSysadminStateControlSort(state));

  const handleSort = useCallback(() => dispatch(toggleSysadminControl('SORT', !sort)), [sort]);
  const label = getSysAdminSortLabelByFilter(filterType);

  const handleToggle = useCallback((ev) => {
    dispatch(toggleSysadminControl('FILTER_TYPE', ev.target.value));
    onFilter(ev.target.value);
  }, []);

  return (
    <AccountsFilterStyled notInUse={notInUse}>
      {Object.entries(accountsRadioFields).map(([label, value]) => (
        <RadioLabel
          checkedValue={filterType}
          handleToggle={handleToggle}
          value={value}
          label={label}
          key={label}
        />
      ))}
      {/*
      <Flex right>
        <label className="sorted" onClick={handleSort}>
          {label}
          {sort ? <i className="fad fa-sort-up" /> : <i className="fad fa-sort-down" />}
        </label>
      </Flex>
      */}
    </AccountsFilterStyled>
  );
};

export default memo(AccountsFilter);
