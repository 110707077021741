import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import CurrencyUtil from '@Utils/currency-util';
import { Panel } from '@Components/ui/styled/main';
import Row from '@Components/ui/styled/row';
import { FlexOne, ReportTextLabel } from '@Components/pos/style';
import { reportingCount, reportingFormat } from '@Utils/format';
import { getGiftCardType } from '@Utils/pos-utils';
import AlertWithIcon from '@Components/ui/alert-with-icon';
import { getTotal, getReportDateText } from '../reports-helpers';

class GiftCardReport extends Component {
  render() {
    const { reportsViewState } = this.props;
    const giftCardReport = reportsViewState.get('giftCards');

    return (
      <div>
        {giftCardReport && !giftCardReport.get('vouchers').isEmpty()
          ? this.renderTable(giftCardReport)
          : this.renderNoData()}
      </div>
    );
  }

  renderTable(giftCardReport) {
    const { reportingDate, match } = this.props;
    const { vouchers } = giftCardReport.toJS();
    const isLedger = match.params.id === 'ledger';
    const isRedeemed = match.params.id === 'redeemed';

    return (
      <>
        {isLedger && (
          <AlertWithIcon icon="fa fa-info-circle">
            Visar antal och saldo för giltiga presentkort per den {reportingDate}
          </AlertWithIcon>
        )}
        <Panel>
          <Row>
            <FlexOne>
              <ReportTextLabel>
                <span>Antal</span>
                <p className="mb0">{reportingCount(vouchers.length)}</p>
              </ReportTextLabel>
            </FlexOne>
            <FlexOne>
              <ReportTextLabel>
                <span>{isLedger ? 'Saldo' : 'Inlöst'}</span>
                <p className="mb0">
                  {reportingFormat(getTotal(vouchers, isLedger ? 'remainingPerDate' : 'redeemedPerDate'))}
                </p>
              </ReportTextLabel>
            </FlexOne>
          </Row>
        </Panel>
        <Panel>
          {isLedger && this.renderLedgerTable(vouchers)}
          {isRedeemed && this.renderRedeemedTable(vouchers)}
        </Panel>
      </>
    );
  }

  renderLedgerTable(vouchers) {
    return (
      <table className="table table-condensed table-report select-text">
        <thead>
          <tr>
            <th>Nummer/kod</th>
            <th>Saldo</th>
            <th>Sålt</th>
            <th>Giltigt t.o.m.</th>
            <th>Typ</th>
          </tr>
        </thead>
        <tbody>
          {vouchers && vouchers.map(giftCard => {
            const {
              id, code, accountingType, vatPct, remainingPerDate,
              soldTs, noRedeemAfterTs
            } = giftCard;

            return (
              <tr key={id}>
                <td>{code}</td>
                <td>{CurrencyUtil.accountCurrency(remainingPerDate, 0)}</td>
                <td>{soldTs ? moment(soldTs).format('L') : '-'}</td>
                <td>{noRedeemAfterTs ? moment(noRedeemAfterTs).format('L') : '-'}</td>
                <td>{getGiftCardType(accountingType, vatPct)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  renderRedeemedTable(vouchers) {
    return (
      <table className="table table-condensed table-report select-text">
        <thead>
          <tr>
            <th>Nummer/kod</th>
            <th>Inlöst</th>
            <th>Belopp</th>
            <th>Typ</th>
          </tr>
        </thead>
        <tbody>
          {vouchers && vouchers.map(giftCard => {
            const {
              id, code, accountingType, vatPct,
              redeemedPerDate, lastRedeemTs
            } = giftCard;

            return (
              <tr key={id}>
                <td>{code}</td>
                <td>{lastRedeemTs ? moment(lastRedeemTs).format('L') : '-'}</td>
                <td>{CurrencyUtil.accountCurrency(redeemedPerDate, 0)}</td>
                <td>{getGiftCardType(accountingType, vatPct)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  renderNoData() {
    const { match } = this.props;
    const isLedger = match.params.id === 'ledger';

    return (
      <AlertWithIcon icon="fa fa-info-circle">
        {isLedger
          ? 'Det finns inga aktiva presentkort för valt datum.'
          : 'Det finns inga inlösta presentkort för vald period.'}
      </AlertWithIcon>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { reportsViewState } = state;

  return {
    reportsViewState,
    reportingDate: getReportDateText(state, ownProps)
  };
};

export default connect(mapStateToProps)(GiftCardReport);
