import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';

export default function useTimer(startTime, handleTimeDiff) {
  const [time, setTime] = useState(startTime);
  const timerRef = useRef(null);

  if (time === 0) {
    clearInterval(timerRef.current);
  }
  useEffect(() => {
    if (!moment(startTime).isValid()) {
      return startTime;
    }
    setTime(handleTimeDiff(startTime));
    timerRef.current = setInterval(() => {
      setTime(handleTimeDiff(startTime));
    }, 1000);
    return () => clearInterval(timerRef.current);
  }, [startTime]);

  return time;
}
