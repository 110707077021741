import { defineMessages } from 'react-intl';

export default defineMessages({
  lblOrgNo: {
    id: 'customers.bookings.lblOrgNo',
    defaultMessage: 'Org. no'
  },
  lblOrgName: {
    id: 'customers.bookings.lblOrgName',
    defaultMessage: 'Org name'
  },
  lblVehicleRegNo: {
    id: 'customers.bookings.lblVehicleRegNo',
    defaultMessage: 'Reg. no'
  },
  lblVehicleDesc: {
    id: 'customers.bookings.lblVehicleDesc',
    defaultMessage: 'Vehicle'
  },
  lblResourceName: {
    id: 'customers.bookings.lblResourceName',
    defaultMessage: 'Resource'
  },
  lblBookedAs: {
    id: 'customers.bookings.lblBookedAs',
    defaultMessage: 'Booked as'
  },
  lblService: {
    id: 'customers.bookings.lblService',
    defaultMessage: 'Service'
  },
  lblBookingNo: {
    id: 'customers.bookings.lblBookingNo',
    defaultMessage: 'Booking no'
  },
  lblCreated: {
    id: 'customers.bookings.lblCreated',
    defaultMessage: 'Created'
  },
  lblChannel: {
    id: 'customers.bookings.lblChannel',
    defaultMessage: 'Channel'
  },
  lblUpdated: {
    id: 'customers.bookings.lblUpdated',
    defaultMessage: 'Updated'
  },
  lblCancelled: {
    id: 'customers.bookings.lblCancelled',
    defaultMessage: 'Cancelled'
  },
  lblNote: {
    id: 'customers.bookings.lblNote',
    defaultMessage: 'Note'
  }
});
