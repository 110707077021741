import styled from 'styled-components';
import { colors } from '@Components/ui/styled/variables';

export default styled.span`
  display: inline-block;
  vertical-align: middle;
  width: ${props => props.w ? `${props.w}px` : '13px'} ;
  height: ${props => props.w ? `${props.w}px` : '13px'} ;
  margin-left: ${props => props.mrl ? `${props.mrl}px` : '0'} ;
  margin-right: ${props => props.mrr || '12px'} ;
  border-radius: 50%;
  background-color: ${props => props.bg || colors.grayCircle};
  color: #fff;
  font-weight: 700;
  font-size: 11px;
  line-height: ${props => props.w ? `${props.w}px` : '13px'} ;
  opacity: ${props => props.disabled ? '.65' : '1'};
`;
