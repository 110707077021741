import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDefaultVatPct } from '@State/selectors';
import { addDeliveryItem, updateDeliveryRow } from '@State/delivery-actions';
import { createProduct, adhocProductDelivery, productOutDelivery, fetchProductDeliveries, lookupSupplierProduct, createProductGroup } from '@State/products-actions';
import LargeNumpadModal from '@Components/inputs/large-numpad-modal';
import DialogLoader from '@Components/dialogs/dialog-loader';
import AddProductModal from './add-product-modal';
import ProductDeliveryModal from './product-delivery-modal';

const ProductDelivery = ({
  deliveryId, barcode, product, onResetProduct, onSelectProduct, onClose, onProductAdded
}) => {
  const dispatch = useDispatch();
  const defaultVatPct = useSelector(getDefaultVatPct);
  const deliveryItems = useSelector(state => state.deliveryItems);
  const productGroups = useSelector(state => state.productGroups);
  const deliveryMode = useSelector(state => state.adminViewState.get('productsDelivery') === true);
  const isOutDelivery = useSelector(state => state.adminViewState.get('productsDeliveryDirection') === 'out');

  const existingItem = useMemo(() => {
    return product
      ? deliveryItems.find(i => i.itemId === product.id)
      : null;
  }, [deliveryItems, product]);

  const [searching, setSearching] = useState(false);
  const [supplierProduct, setSupplierProduct] = useState(null);
  const [currentProduct, setCurrentProduct] = useState(product);
  const productId = product?.id;
  const [loading, setLoading] = useState(productId && !isOutDelivery);
  const shouldFetchDeliveries = productId && !isOutDelivery && !existingItem;

  const supplierGroup = useMemo(() => {
    if (productGroups && supplierProduct) {
      return productGroups.find(g => g.get('name').toLowerCase() === supplierProduct.brand.toLowerCase());
    }
  }, [productGroups, supplierProduct]);

  useEffect(() => {
    if (barcode && !product && !supplierProduct) {
      setSearching(true);
      dispatch(lookupSupplierProduct(barcode))
        .then((sp) => sp && setSupplierProduct(sp))
        .finally(() => setSearching(false));
    }
  }, [barcode, product, supplierProduct]);

  useEffect(() => {
    if (product !== currentProduct) {
      // Set quantity and amount from current delivery
      const quantity = existingItem?.deliveredQuantity || 0;
      const amount = existingItem?.price || 0;
      setCurrentProduct(product ? { ...product, quantity, amount } : null);
    }
  }, [product]);

  useEffect(() => {
    // Set amount from last delivery
    if (shouldFetchDeliveries) {
      setLoading(true);
      dispatch(fetchProductDeliveries(productId))
        .then(({ rows }) => {
          if (rows?.length > 0) {
            const lastRow = rows[rows.length - 1];
            setCurrentProduct({ ...product, amount: lastRow.price });
          }
        })
        .finally(() => setLoading(false));
    }
  }, [shouldFetchDeliveries]);

  const onCloseDelivery = () => {
    if (deliveryMode) {
      onResetProduct();
    } else {
      onClose();
    }
  };

  const createGroupAndProduct = (newProduct, supplierProduct) => {
    if (newProduct.groupId === 'NEW') {
      return dispatch(createProductGroup({ name: supplierProduct.brand, showInPos: true }))
        .then((groupId) => {
          return dispatch(createProduct({ ...newProduct, groupId }, supplierProduct));
        });
    }
    return dispatch(createProduct(newProduct, supplierProduct));
  };

  const onAddProduct = ({ barcode, name, brand, groupId, stocked, priceOut }) => {
    const newProduct = {
      EAN: barcode, name, brand, groupId, stocked, priceOut, vatPct: defaultVatPct, showInPos: true
    };
    return createGroupAndProduct(newProduct, supplierProduct)
      .then((savedProduct) => {
        if (typeof onProductAdded === 'function') {
          onProductAdded(savedProduct);
        }
        if (stocked) {
          const amount = supplierProduct?.glp || 0;
          setCurrentProduct({ ...savedProduct, amount });
        } else {
          onCloseDelivery();
        }
      });
  };

  const onProductDelivery = ({ quantity, amount }) => {
    if (deliveryId) {
      const deliveryItem = {
        deliveryId,
        itemId: currentProduct.id,
        deliveryRowId: existingItem?.deliveryRowId,
        deliveredQuantity: quantity,
        remainingQuantity: quantity,
        price: amount
      };
      if (existingItem) {
        return dispatch(updateDeliveryRow(deliveryItem))
          .then(onCloseDelivery);
      }
      return dispatch(addDeliveryItem(deliveryItem))
        .then(onCloseDelivery);
    }
    return dispatch(adhocProductDelivery(currentProduct, quantity, amount))
      .then(onCloseDelivery);
  };

  const onProductOutDelivery = ({ quantity }) => {
    return dispatch(productOutDelivery(currentProduct, quantity))
      .then(() => onClose());
  };

  if (searching) {
    return <DialogLoader title="Söker produkt..." />;
  }

  if (barcode && !currentProduct || currentProduct && !currentProduct.id) {
    const showNewGroup = !supplierGroup && supplierProduct;
    const defaultGroup = showNewGroup ? 'NEW' : productGroups.first().get('id');

    return (
      <AddProductModal
        initialValues={{
          barcode,
          groupId: supplierGroup?.get('id') || defaultGroup,
          stocked: true,
          name: currentProduct?.name || supplierProduct?.productName,
          priceOut: supplierProduct?.rrp
        }}
        barcode={barcode}
        supplierProduct={supplierProduct}
        showNewGroup={showNewGroup}
        groups={productGroups}
        onSubmit={onAddProduct}
        onClose={onClose}
      />
    );
  }
  if (deliveryMode && !barcode && !currentProduct) {
    return (
      <ProductDeliveryModal
        onSelect={onSelectProduct}
        onClose={onClose}
      />
    );
  }
  if (currentProduct) {
    return (
      <LargeNumpadModal
        loading={loading}
        onSave={isOutDelivery ? onProductOutDelivery : onProductDelivery}
        onClose={deliveryMode ? onResetProduct : onClose}
        item={currentProduct}
        amountLabel="Inköpspris / st (exkl. moms)"
        helpText={isOutDelivery
          ? 'Ange antal som du vill göra utleverans på.'
          : 'Ange antal för inleverans samt inköpspris på denna leverans.'}
        warningLabel={existingItem
          ? 'Denna produkt finns redan med i pågående leverans'
          : null}
        hintLabel={shouldFetchDeliveries && !loading && !currentProduct.amount
          ? 'Du kan låta inköpspriset vara 0 och korrigera det senare'
          : null}
        title={currentProduct.name}
        showAmount={!isOutDelivery}
        showQuantity
      />
    );
  }
  return null;
};

export default ProductDelivery;
