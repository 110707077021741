import React from 'react';
import UserMenuButton from '@Components/nav/user-menu-button';
import MobileResourceSelector from './mobile-resource-selector';
import ViewModeSwitcher from './viewmode-switcher';
import CalendarHeaderToolbarButtons from './calendar-header-toolbar-buttons';
import DateNavigator from './date-navigator';

const CalendarHeaderPhone = ({ dateLabel, routeParams, viewDateStartEnd }) => (
  <header className="section-header ui-section-column calendar-style">
    <div className="ui-section-row top">
      <MobileResourceSelector routeParams={routeParams} dateLabel={dateLabel} />
      <CalendarHeaderToolbarButtons routeParams={routeParams} />
      <UserMenuButton routeParams={routeParams} />
    </div>
    <div className="ui-section-row bottom">
      <DateNavigator phoneMode routeParams={routeParams} viewDateStartEnd={viewDateStartEnd} />
      <ViewModeSwitcher phoneMode routeParams={routeParams} />
    </div>
  </header>
);

export default CalendarHeaderPhone;
