import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { generateApiKey } from '@State/users-actions';
import { Panel, Button } from '@Components/ui/styled/main';

const StaffApiKeyRow = ({ created, apiKey }) => {
  const [copied, setCopied] = useState(false);

  const onCopyToClipboard = () => {
    navigator.clipboard.writeText(apiKey).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    }, (error) => {
      console.error('Could not copy text: ', error);
    });
  };

  return (
    <tr>
      <td>{moment(created).format('LL')}</td>
      <td>{apiKey}</td>
      <td>
        <a href="#" onClick={onCopyToClipboard} title="Kopiera till urklipp">
          {copied
            ? <i className="far fa-fw fa-check text-success" />
            : <i className="far fa-fw fa-copy text-muted" />}
        </a>
      </td>
    </tr>
  );
};

const StaffApiKeys = ({ user }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const onGenerateApiKey = () => {
    setLoading(true);
    dispatch(generateApiKey(user.id))
      .finally(() => setLoading(false));
  };

  return (
    <Panel>
      <div className="form-group">
        <p className="mb0">
          <label className="control-label">Integrationer</label>
        </p>
        <div className="text-muted">
          API-nycklar för integration med externa system, t.ex. Zapier
        </div>
      </div>
      {user?.apiKeys?.length > 0 && (
        <table className="table">
          <tbody>
            {user.apiKeys.map(key => <StaffApiKeyRow key={key.id} {...key} />)}
          </tbody>
        </table>
      )}
      <Button small white loading={loading} onClick={onGenerateApiKey}>Skapa API-nyckel</Button>
    </Panel>
  );
};

StaffApiKeys.propTypes = {
  id: PropTypes.number,
  userRole: PropTypes.string,
  onChangeToggle: PropTypes.func,
  isOn: PropTypes.bool,
  loginType: PropTypes.string
};

export default StaffApiKeys;
