import React, { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { Button, Flex, Column } from '@Components/ui/styled/main';
import { fsw, colors } from '@Components/ui/styled/variables';
import Text from '@Components/ui/styled/text-element';
import Timer from '@Components/staff/timer';
import {
  fetchStaff, fetchStaffEntries, updatedEntriesCheckOut, updatedEntriesCheckIn
} from '@State/staff-actions';
import StaffEditStartEntryModal from '@Components/staff/staff-edit-start-entry-modal';
import { getTimeISO } from '@Utils/time-util';
import { staffJournalStatus } from '@State/view-actions';

const LabelStyled = styled.span`
  font-weight: ${fsw.bold};
  font-size: 13px;
  line-height: 17px;
  color: ${colors.formLabel};
`;

const TextAssignedStyled = styled(Text)`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

const BlockStatus = styled.div`
  height: 80px;
  padding-top: 20px;
`;

const TimeStyled = styled(Text)`
  color: ${colors.blueTime};
  line-height: 24px;
`;

const JustCheckinBlock = styled.div`
  font-size: 15px;
  font-weight: ${fsw.bold};
  color: #000;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 8px;
  background-color: ${colors.bgLightGreen};
`;

const StaffUserDetailAssignStatus = ({
  disabledCheckIn, name, userId, checkinTime
}) => {
  const dispatch = useDispatch();
  const [checkinTimeState, setCheckinTimeState] = useState(checkinTime);
  const [openCheckInTimeModal, setOpenCheckInTimeModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [isJustCheckIn, setIsJustCheckIn] = useState(false);

  const fetchData = async () => {
    return Promise.all([
      dispatch(fetchStaff()),
      dispatch(fetchStaffEntries(userId))
    ]);
  };

  useEffect(() => {
    setIsJustCheckIn(false);
  }, [userId]);

  useEffect(() => {
    setCheckinTimeState(checkinTime);
  }, [checkinTime]);

  const showTimeModal = () => setOpenCheckInTimeModal(true);
  const hideTimeModal = () => setOpenCheckInTimeModal(false);

  const handleCheckOut = async () => {
    setSubmitting(true);
    const checkoutTime = getTimeISO();
    try {
      await dispatch(updatedEntriesCheckOut({ userId, checkoutTime }));
      dispatch(staffJournalStatus());
      await fetchData();
    } finally {
      setSubmitting(false);
    }
  };

  const onChangeCheckInTime = async (checkinTime) => {
    try {
      await dispatch(updatedEntriesCheckIn({ userId, checkinTime }));
    } finally {
      setIsJustCheckIn(true);
      dispatch(staffJournalStatus());
      setTimeout(async () => {
        await fetchData();
        setIsJustCheckIn(false);
      }, 3000);
    }
  };

  const renderStatus = () => {
    if (isJustCheckIn) {
      return (
        <JustCheckinBlock>
          {name} är nu incheckad
        </JustCheckinBlock>
      );
    }
    if (checkinTimeState) {
      return (
        <Flex vCenter fullWidth>
          <TextAssignedStyled fs={17} muted>
            <Column>
              <LabelStyled>Incheckad</LabelStyled>
              <TimeStyled bold fs={17}>
                <Timer checkinTime={checkinTimeState} />
              </TimeStyled>
            </Column>
            <Button loading={submitting} disabled={submitting} small deny onClick={handleCheckOut}>Checka ut</Button>
          </TextAssignedStyled>
        </Flex>
      );
    }
    return (
      <Flex vCenter size={1} fullWidth>
        <Text fs={15} bold muted>Utcheckad</Text>
        <Button disabled={disabledCheckIn} small success onClick={showTimeModal}>Checka in</Button>
      </Flex>
    );
  };

  return (
    <BlockStatus>
      {renderStatus()}
      {openCheckInTimeModal
        && (
        <StaffEditStartEntryModal
          handleSubmit={onChangeCheckInTime}
          onClose={hideTimeModal}
        />
        )}
    </BlockStatus>
  );
};

StaffUserDetailAssignStatus.propTypes = {
  userId: PropTypes.number,
  isAssigned: PropTypes.bool,
  checkinTime: PropTypes.string
};

export default memo(StaffUserDetailAssignStatus);
