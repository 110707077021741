import React from 'react';
import { Modal } from 'react-overlays';
import { useDispatch, useSelector } from 'react-redux';
import { isEmbeddedInApp } from '@Utils/embedded-util';
import { handleInvalidToken } from '@Utils/ajax-util';
import { logMeOut } from '@Utils/account';

const AccountTerminatedAlert = () => {
  const dispatch = useDispatch();
  const embeddedInApp = useSelector(isEmbeddedInApp);

  const handleLogout = (ev) => {
    ev.preventDefault();

    if (embeddedInApp) {
      return dispatch(handleInvalidToken(false));
    }

    logMeOut();
  };

  return (
    <Modal className="dialog-container" show autoFocus={false} enforceFocus={false}>
      <div className="dialog-content">
        <h2>
          <i className="fa fa-exclamation-triangle" /> Kontot är avslutat
        </h2>
        <hr />
        <p>
          Vänligen kontakta oss på support@cliento.com om du vill återaktivera kontot.
        </p>
        <hr />
        <a href="#" onClick={handleLogout} className="button arrow">Logga ut</a>
      </div>
    </Modal>
  );
};

export default AccountTerminatedAlert;
