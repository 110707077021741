import { Map, fromJS } from 'immutable';
import omit from 'lodash/omit';
import { CLEAR_LOCATION_STATE } from './account-actions';
import {
  CASHIERS_LOADED, USERS_LOADED, USER_ADDED, USER_UPDATED, USER_PIN_UPDATED, USER_DELETED,
  USER_POS_ROLES_LOADED, USER_POS_ROLE_UPDATED, USER_POS_ROLE_DELETED, USER_NAME_UPDATED,
  USER_NAME_CHECKED, USER_ADDED_LOGIN, USER_REPLACED_LOGIN, USER_STAFF_PNO_UPDATED,
  USER_STAFF_JOURNAL_DISABLED, USER_RESOURCE_ADDED, USER_RESOURCE_REMOVED, USER_POS_ROLE_LOADED, USER_LOGIN_CLEAR,
  USER_POS_ROLE_TOGGLE_UPDATED, USER_LOGIN_CHANGED, USER_STAFF_JOURNAL_ENABLED, USER_PERMISSIONS_UPDATED,
  USER_APIKEYS_LOADED, USER_APIKEY_GENERATED, USER_ACCESS_ADDED, USER_ACCESS_REMOVED, USER_POS_RESOURCE_UPDATED,
  USER_EMPLOYMENT_UPDATED, USER_SALES_TERMS_UPDATED, USER_TITLE_UPDATED, USER_PRESENTATION_UPDATED
} from './users-actions';

export function usersById(state = Map(), action = null) {
  switch (action.type) {
    case USER_NAME_CHECKED:
      return state.setIn([+action.userId, 'userNameChecked'], action.user);

    case USER_ADDED_LOGIN:
      return state.setIn([+action.userId, 'userRole'], action.user?.userRole)
        .setIn([+action.userId, 'loginType'], 'PW')
        .setIn([+action.userId, 'username'], action.user?.username)
        .setIn([+action.userId, 'userNameChecked'], { registered: true, validationPassed: true });

    case USER_LOGIN_CLEAR:
      return state.setIn([+action.userId, 'userNameChecked'], null);

    case USER_REPLACED_LOGIN:
      return state.setIn([+action.userId, 'userRole'], action.user?.userRole)
        .setIn([+action.userId, 'loginType'], 'PW')
        .setIn([+action.userId, 'username'], action.user?.username);

    case USER_STAFF_JOURNAL_DISABLED:
      return state.setIn([action.userId, 'showInStaffJournal'], false);
    case USER_STAFF_JOURNAL_ENABLED:
      return state.setIn([action.userId, 'showInStaffJournal'], true);

    case USER_STAFF_PNO_UPDATED:
      return state
        .setIn([action.userId, 'showInStaffJournal'], true)
        .setIn([action.userId, 'pno'], action.pno);

    case USER_TITLE_UPDATED:
      return state.setIn([+action.userId, 'title'], action.title);

    case USER_PRESENTATION_UPDATED:
      return state.setIn([+action.userId, 'presentation'], action.presentation);

    case USER_RESOURCE_ADDED: {
      const { userId, resource } = action;
      const associatedResources = state.getIn([userId, 'associatedResources']);
      if (associatedResources) {
        const newResources = associatedResources.push(fromJS(resource));
        return state.setIn([userId, 'associatedResources'], newResources);
      }
      return state;
    }

    case USER_RESOURCE_REMOVED: {
      const { userId, resourceId } = action;
      const associatedResources = state.getIn([userId, 'associatedResources']);
      if (associatedResources) {
        const newResources = associatedResources.filter(r => r.get('resourceId') !== resourceId);
        return state.setIn([userId, 'associatedResources'], newResources);
      }
      return state;
    }

    case USER_ACCESS_ADDED: {
      const { userId, access } = action;
      const entityAcls = state.getIn([userId, 'entityAcls']);
      if (entityAcls) {
        const newEntityAcls = entityAcls.push(fromJS(access));
        return state.setIn([userId, 'entityAcls'], newEntityAcls);
      }
      return state;
    }

    case USER_ACCESS_REMOVED: {
      const { userId, access } = action;
      const { entity, entityId } = access;
      const entityAcls = state.getIn([userId, 'entityAcls']);
      if (entityAcls) {
        const newEntityAcls = entityAcls.filter(r => !(r.get('entity') === entity && r.get('entityId') === entityId));
        return state.setIn([userId, 'entityAcls'], newEntityAcls);
      }
      return state;
    }

    case USER_POS_ROLE_LOADED:
      return state.get(action.userId)
        ? state.setIn([action.userId, 'posRoles'], fromJS(action.roles))
        : state;

    case USER_POS_ROLE_DELETED: {
      const { userId, posOrgId } = action;
      const posRoles = state.getIn([userId, 'posRoles']);
      if (posRoles) {
        const newPosRoles = posRoles.filter((role) => role.get('posOrgId') !== posOrgId);
        return state.setIn([userId, 'posRoles'], fromJS(newPosRoles));
      }
      return state;
    }

    case USER_POS_ROLE_TOGGLE_UPDATED: {
      if (action.userId) {
        return state.setIn([action.userId, 'posRoleEnabled'], fromJS(action.isEnabled));
      }
      return state;
    }

    case USER_PERMISSIONS_UPDATED: {
      if (action.userId) {
        return state.setIn([action.userId, 'permissions'], action.permissions.split(','));
      }
      return state;
    }

    case USER_POS_ROLE_UPDATED:
      const { userId, posOrgId } = action;
      const posRoles = state.getIn([userId, 'posRoles']);
      if (posRoles) {
        let newPosRoles = posRoles.toJS();
        const isExistPosRoles = newPosRoles?.find(({ posOrgId }) => action.posOrgId === posOrgId);
        if (isExistPosRoles) {
          newPosRoles = newPosRoles.map(
            (role) => role.posOrgId === action.posOrgId
              ? { ...role, cashierRole: action.cashierRole }
              : role
          );
        } else {
          // push new role
          newPosRoles.push(omit(action, 'type'));
        }
        return state.setIn([userId, 'posRoles'], fromJS(newPosRoles));
      }
      // if not exist posRoles
      return state.setIn([userId, 'posRoles'], fromJS([{ userId, posOrgId, cashierRole: action.cashierRole }]));

    case CLEAR_LOCATION_STATE:
      return state.clear();

    case USERS_LOADED: {
      return state.clear().withMutations((map) => {
        for (const user of action.users) {
          if (user.id) {
            map.set(user.id, fromJS(user));
          }
        }
      });
    }

    case USER_ADDED:
    case USER_UPDATED: {
      if (action.user.id) {
        return state.set(action.user.id, fromJS(action.user));
      }
      return state;
    }

    case USER_NAME_UPDATED:
      return state.setIn([action.userId, 'name'], action.name);

    case USER_PIN_UPDATED:
      return state.setIn([action.userId, 'operatorPin'], action.operatorPin);

    case USER_DELETED: {
      if (action.userId) {
        return state.delete(action.userId);
      }
      return state;
    }

    case USER_LOGIN_CHANGED: {
      if (action.userId) {
        return state.setIn([action.userId, 'loginEnabled'], action.isUserLoginEnabled);
      }
      return state;
    }

    case USER_POS_RESOURCE_UPDATED:
      return state.setIn([action.userId, 'srvPricesFromResourceId'], action.resourceId);

    case USER_APIKEYS_LOADED: {
      return state.withMutations((map) => {
        for (const key of action.keys) {
          const userId = key.ownerLocUserId;
          if (map.has(userId)) {
            const keys = map.getIn([userId, 'apiKeys']) || [];
            map.setIn([userId, 'apiKeys'], keys.concat([key]));
          }
        }
      });
    }

    case USER_APIKEY_GENERATED: {
      const { userId, key } = action;
      const keys = state.getIn([userId, 'apiKeys']) || [];
      return state.setIn([userId, 'apiKeys'], keys.concat([key]));
    }

    case USER_EMPLOYMENT_UPDATED:
      return state.setIn([action.userId, 'employment'], action.employment);

    case USER_SALES_TERMS_UPDATED:
      return state.setIn([action.userId, 'salesTerms'], action.salesTerms);

    default:
      return state;
  }
}

export function invitesById(state = Map(), action = null) {
  switch (action.type) {
    case CLEAR_LOCATION_STATE:
      return state.clear();

    case USERS_LOADED: {
      return state.clear().withMutations((map) => {
        for (const user of action.users) {
          if (user.inviteId) {
            map.set(user.inviteId, fromJS(user));
          }
        }
      });
    }
    case USER_ADDED:
    case USER_UPDATED: {
      if (action.user.inviteId) {
        return state.set(action.user.inviteId, fromJS(action.user));
      }
      return state;
    }

    case USER_DELETED: {
      if (action.inviteId) {
        return state.delete(action.inviteId);
      }
      return state;
    }

    default:
      return state;
  }
}

export function userPosRoles(state = Map(), action = null) {
  switch (action.type) {
    case CLEAR_LOCATION_STATE:
      return state.clear();

    case USER_POS_ROLES_LOADED: {
      return state.clear().withMutations((map) => {
        for (const role of action.roles) {
          const { userId, posOrgId, cashierRole } = role;
          map.setIn([userId, posOrgId], cashierRole);
        }
      });
    }

    case USER_POS_ROLE_UPDATED: {
      const { userId, posOrgId, cashierRole } = action;
      return state.setIn([userId, posOrgId], cashierRole);
    }

    case USER_POS_ROLE_DELETED: {
      const { userId, posOrgId } = action;
      return state.deleteIn([userId, posOrgId]);
    }

    default:
      return state;
  }
}

export function cashiersById(state = Map(), action = null) {
  switch (action.type) {
    case CASHIERS_LOADED: {
      return state.clear().withMutations((map) => {
        for (const cashier of action.cashiers) {
          if (cashier.id) {
            map.set(cashier.id, fromJS(cashier));
          }
        }
      });
    }

    case USER_NAME_UPDATED:
      return state.setIn([action.userId, 'name'], action.name);

    case USER_DELETED:
      return action.userId ? state.delete(action.userId) : state;

    case USER_POS_ROLE_TOGGLE_UPDATED:
    case CLEAR_LOCATION_STATE:
      return state.clear();

    default:
      return state;
  }
}
