import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearAndCloseBKF, loadBKFBooking } from '@State/bkf/actions';
import BookingForm from '@Components/calendar/booking/booking-form';
import Popover from '@Components/ui/popover';
import Button from '@Components/ui/button';

const stylesPopover = { zIndex: 1000 };

const OngoingPopover = ({ id, routeParams }) => {
  const dispatch = useDispatch();
  const customerFormVisible = useSelector(state => state.cf.get('formVisible'));
  const showModal = useSelector(state => state.bkf.get('showModal'));
  const showForm = useSelector(state => state.bkf.get('id') === id);

  const onShowForm = () => dispatch(loadBKFBooking(id));
  const onHideForm = () => dispatch(clearAndCloseBKF());
  const onOuterAction = () => {
    if (!customerFormVisible && !showModal) {
      onHideForm();
    }
  };

  return (
    <Popover
      isOpen={showForm}
      body={showForm && (
        <BookingForm
          id={id}
          routeParams={routeParams}
          onClose={onHideForm}
        />
      )}
      style={stylesPopover}
      onOuterAction={onOuterAction}
      className="Popover-booking"
      preferPlace="row"
      enterExitTransitionDurationMs={0}
      refreshIntervalMs={500}
    >
      <Button tiny white marginLeft onClick={onShowForm}>
        <i className="far fa-calendar-alt" />
      </Button>
    </Popover>
  );
};

export default OngoingPopover;
