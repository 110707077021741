import React from 'react';
import ReactTooltip from 'react-tooltip';

const LabelTooltip = ({ text }) => {
  return (
    <>
      <i className="fa fa-info-circle ml1" data-tip={text} />
      <ReactTooltip effect="solid" place="top" className="label-tooltip" />
    </>
  );
};

export default LabelTooltip;
