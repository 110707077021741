import React, { Component } from 'react';
import { Field } from 'redux-form';
import TextareaAutosize from 'react-textarea-autosize';
import { email } from '@Utils/preference-keys';
import { getPreviewText } from '@Utils/templates';
import TagsInfo from './tags-info';

const styleFormGroup = { marginBottom: 0 };
const styleTags = { marginTop: 10 };

class EmailIcsConfig extends Component {
  render() {
    const {
      includeIcs, icsHeader, icsLocation, icsNotes, previewValues
    } = this.props;

    return (
      <div className="form-group" style={styleFormGroup}>
        <div className="col-sm-6">
          <div className="checkbox form-control-static">
            <label>
              <Field name={email.includeIcs} component="input" type="checkbox" />
              Bifoga kalenderinbjudan i bokningsbekräftelser
            </label>
          </div>
          <p className="text-muted">
            Kalenderinbjudan i bokningsbekräftelsen gör så att dina kunder
            kan lägga till bokningen i sin kalender.
          </p>
          {includeIcs
            ? (
              <div className="well well-sm" style={styleFormGroup}>
                <label className="control-label">Titel</label>
                <Field name={email.icsHeader} component="input" type="text" className="form-control" />

                <label className="control-label">Plats/adress</label>
                <Field name={email.icsLocation} component="input" type="text" className="form-control" />

                <label className="control-label">Extra information</label>
                <Field name={email.icsNotes} component={this.textArea} />

                <div style={styleTags}>
                  <TagsInfo resource service location />
                </div>
              </div>
            ) : null}
        </div>
        {includeIcs
          ? (
            <div className="col-sm-6">
              <div className="form-control-static">&nbsp;</div>
              <p>
                <br />
                <strong>Förhandsgranskning</strong>
              </p>
              <div className="alert alert-info">
                <p><strong>{getPreviewText(icsHeader, previewValues)}</strong></p>
                <p>{getPreviewText(icsLocation, previewValues)}</p>
                <hr />
                <p className="text-wrap">{getPreviewText(icsNotes, previewValues)}</p>
              </div>
            </div>
          ) : null}
      </div>
    );
  }

  textArea = props => (
    <TextareaAutosize {...props.input} minRows={3} className="form-control" />
  );
}

export default EmailIcsConfig;
