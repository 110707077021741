import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import useOnClickOutside from '@Hooks/useOnClickOutside';
import DropDown from '@Components/ui/drop-down/dropdown';
import { Button } from '@Components/ui/styled/main';

export const DropdownContainer = styled.div`
  position: absolute;
  top: 100%;
  margin-top: ${p => p.small ? '0' : '4px'};
  right: -2px;
  z-index: 999;
`;

const ButtonDropdown = ({
  children, icon, label = '', small, disabled, ...rest
}) => {
  const ref = useRef();
  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);

  useOnClickOutside(ref, handleClose);

  return (
    <Button small gray {...rest} disabled={disabled} onClick={() => setOpen(!open)} ref={ref}>
      {icon && <i className={icon} />}
      {label && <span>{label}</span>}
      {open && children && (
        <DropdownContainer small={small}>
          <DropDown small={small} light onClose={handleClose}>
            {children}
          </DropDown>
        </DropdownContainer>
      )}
    </Button>
  );
};

export default ButtonDropdown;
