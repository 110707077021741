import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from '@Components/ui/button';
import Loader from '@Components/ui/loader';
import ModalDialog from '@Components/dialogs/modal-dialog';
import { createIcalToken, getIcalFeedUrl } from '@State/resource-actions';

const googleHelpUrl = 'https://support.google.com/calendar/answer/37100?hl=sv';
const appleHelpUrl = 'https://support.apple.com/sv-se/guide/calendar/icl1022/mac';

const IcalModal = ({ resourceId, onClose }) => {
  const dispatch = useDispatch();

  const [icalToken, setIcalToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [copied, setCopied] = useState(false);

  const icalFeed = getIcalFeedUrl(icalToken);

  const onCopyToClipboard = () => {
    navigator.clipboard.writeText(icalFeed).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    }, (error) => {
      console.error('Could not copy text: ', error);
    });
  };

  useEffect(() => {
    if (resourceId) {
      dispatch(createIcalToken(resourceId))
        .then(({ token }) => setIcalToken(token))
        .finally(() => setLoading(false));
    }
  }, [resourceId]);

  return (
    <ModalDialog
      title="Synkronisera med kalender"
      contentSize="large"
      onClose={onClose}
      closeButtonText="Stäng"
    >
      {loading && <Loader />}

      <p>
        Använd denna länk för att visa dina Cliento-bokningar i din kalender.
      </p>

      <div className="well well-sm">
        <a href="#" className="pull-right ml2" onClick={onCopyToClipboard} title="Kopiera till urklipp">
          {copied
            ? <i className="far fa-fw fa-check text-success" />
            : <i className="far fa-fw fa-copy text-muted" />}
        </a>
        {icalFeed}
      </div>

      <p className="mb3">
        För mer information om hur du lägger till prenumerationen
        hänvisar vi dokumentationen för din kalender.
      </p>
      <p>
        <a href={googleHelpUrl} target="_blank">
          <i className="fa fa-external-link-alt mr1" />
          Lägg till prenumeration i Google kalender
        </a>
      </p>
      <p>
        <a href={appleHelpUrl} target="_blank">
          <i className="fa fa-external-link-alt mr1" />
          Lägg till prenumeration i Apple kalender
        </a>
      </p>

      <div className="text-right">
        <Button gray small onClick={onClose}>Stäng</Button>
      </div>
    </ModalDialog>
  );
};

export default IcalModal;
