import { fromJS } from 'immutable';
import { createSelector } from 'reselect';

const productsSelector = state => state.products;
const productGroupsSelector = state => state.productGroups;

export const getAllProducts = createSelector(productsSelector, products => products);
export const getVisibleProducts = createSelector(productsSelector, products => products.filter(p => p.showInPos));
export const getStockedProducts = createSelector(productsSelector, products => products.filter(p => p.stocked));
export const getStockedProductGroups = createSelector(
  productGroupsSelector,
  getStockedProducts,
  (productGroups, products) => {
    return productGroups.filter(g => g.get('articleIds').some(id => products.get(id)));
  }
);

export const getColorStockProducts = createSelector(
  productsSelector,
  products => products.filter(p => p.unit && p.unitAmount)
);

export const getColorStockProductGroups = createSelector(
  productGroupsSelector,
  getColorStockProducts,
  (productGroups, stockProducts) => {
    const stockProductIds = stockProducts.map(p => p.id).toArray();
    return productGroups.filter(g => {
      const includesAnyIds = stockProductIds.map(id => g.get('articleIds').includes(id));

      return includesAnyIds.some(val => val === true);
    });
  }
);

export const getProductGroups = createSelector(
  getVisibleProducts,
  productGroupsSelector,
  (products, productGroups) => {
    const groups = productGroups.filter(g => g.get('showInPos') && !g.get('articleIds').isEmpty());
    const favoriteIds = products.filter(p => p.showInFavs).map(p => p.id).toArray();
    const favorites = favoriteIds.length > 0
      ? { id: 'favs', name: 'Favoriter', color: null, articleIds: favoriteIds }
      : null;

    return favorites
      ? groups.insert(0, fromJS(favorites))
      : groups;
  }
);

export const getVoucherTemplateProducts = createSelector(
  state => state.voucherTemplates,
  (voucherTemplates) => {
    return voucherTemplates?.map((template) => {
      const { id, description, initialValue, comment } = template.toJS();
      return {
        id,
        type: 'Voucher',
        name: description,
        price: initialValue,
        comment
      };
    });
  }
);
