import React, { Component } from 'react';
import { Field } from 'redux-form';
import { company } from '@Utils/preference-keys';
import TextInputHorizontal from './text-input-horizontal';

class CompanyAddress extends Component {
  render() {
    const { disabled } = this.props;
    return (
      <>
        <Field name={company.addressLine1} component={TextInputHorizontal} label="Adress" placeholder="Gatuadress" disabled={disabled} />

        <div className="form-group">
          <div className="col-sm-offset-3 col-sm-5">
            <div className="address-form-group">
              <Field name={company.postCode} placeholder="Postnr" component="input" className="form-control input-postcode" disabled={disabled} />
              <Field name={company.area} placeholder="Ort" component="input" className="form-control input-area" disabled={disabled} />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CompanyAddress;
