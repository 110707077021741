import { defineMessages } from 'react-intl';

export default defineMessages({
  txtConfirmDelete: {
    id: 'calendar.bkf.delete.txtConfirmDelete',
    defaultMessage: 'Delete booking?'
  },
  btnCancel: {
    id: 'calendar.bkf.delete.btnCancel',
    defaultMessage: 'Cancel'
  },
  btnConfirm: {
    id: 'calendar.bkf.delete.btnConfirm',
    defaultMessage: 'Delete'
  },
  btnDeleting: {
    id: 'calendar.bkf.delete.btnDeleting',
    defaultMessage: 'Deleting...'
  }
});
