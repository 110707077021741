import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';

import { createFortnoxInvoice } from '@State/sysadmin-actions';
import ModalDialog from '@Components/dialogs/modal-dialog';
import FormButtonsFooter from '@Components/ui/form-buttons-footer';
import { Button } from '@Components/ui/styled/main';

const BtnCreateFortnoxInvoice = ({ id, invoices }) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, reset, formState: { isSubmitting } } = useForm();

  const [showCreateInvoice, setShowCreateInvoice] = useState(false);

  const onSubmit = (values) => {
    dispatch(createFortnoxInvoice({ orgId: id, ...values }));
    reset('', {
      keepValues: false
    });
    onHideModal();
  };

  const onShowModal = () => setShowCreateInvoice(true);

  const onHideModal = () => {
    setShowCreateInvoice(false);
  };

  return (
    <>
      {showCreateInvoice && (
        <ModalDialog
          title="Create Fortnox invoice"
          onClose={onHideModal}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            {invoices?.filter(i => i.get('status') !== 'Open').map(invoice => (
              <div className={invoice.get('fortnoxInvoiceId') ? 'checkbox disabled' : 'checkbox'}>
                <label>
                  <input
                    type="checkbox"
                    value={invoice.get('periodKey')}
                    disabled={!!invoice.get('fortnoxInvoiceId')}
                    {...register('periodKeys')}
                  />
                  {invoice.get('periodKey')}{' '}
                  {invoice.get('fortnoxInvoiceId') && (
                    <small>(invoice sent)</small>
                  )}
                </label>
              </div>
            ))}
            <FormButtonsFooter
              submitText="Create invoice"
              onClose={onHideModal}
              handleSubmit={handleSubmit}
              submitSucceeded={false}
              submitting={isSubmitting}
            />
          </form>
        </ModalDialog>
      )}
      <Button gray small onClick={onShowModal}>
        Create Fortnox invoice
      </Button>
    </>
  );
};

export default BtnCreateFortnoxInvoice;
