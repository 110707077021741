import React, { useState } from 'react';
import { TableTd } from '@Components/ui/styled/table';
import CurrencyUtil from '@Utils/currency-util';
import Button from '@Components/ui/button';
import Popover from '@Components/ui/popover';
import ConfirmBlock from '@Components/ui/confirm-block';

const AccountInvoiceTableItem = ({ invoiceUUID, isPaid, item, onConfirmDelete }) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const onHide = (e) => {
    if (e) e.stopPropagation();
    setShowConfirm(false);
    return false;
  };
  const onConfirmDeleteInvoice = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    onConfirmDelete({ invoiceUUID, invoiceItemId: item.get('id') });
    setShowConfirm(false);
  };
  const onShowPopup = (e) => {
    if (e) e.stopPropagation();
    setShowConfirm(true);
    return false;
  };

  return (
    <>
      <TableTd width="150px">{item.get('type')}</TableTd>
      <TableTd>{item.get('locationId')}</TableTd>
      <TableTd>{item.get('periodStart') || '-'}</TableTd>
      <TableTd>{item.get('periodEnd') || '-'}</TableTd>
      <TableTd>{item.get('tag') || '-'}</TableTd>
      <TableTd right width="40px">{item.get('units') || '-'}</TableTd>
      <TableTd right>{CurrencyUtil.currency(item.get('price'), 2)}</TableTd>
      <TableTd paddingLeft="25px">{item.get('altText') || '-'}</TableTd>
      <TableTd right>
        {invoiceUUID && !isPaid && (
        <Popover
          onOuterAction={onHide}
          isOpen={showConfirm}
          className="Popover-big-radius"
          body={(
            <div className="Popover-content-small">
              <ConfirmBlock
                title="Are you sure?"
                onCancel={onHide}
                onConfirm={onConfirmDeleteInvoice}
              />
            </div>
          )}
        >
          <Button small className="btn-delete" onClick={onShowPopup}>X</Button>
        </Popover>
        )}
      </TableTd>
    </>
  );
};

export default AccountInvoiceTableItem;
