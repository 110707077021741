import React from 'react';

const CheckBox = ({ input, disabled, label, meta: { warning } }) => (
  <div className={disabled ? 'checkbox disabled' : 'checkbox'}>
    <label>
      <input type="checkbox" checked={input.value} {...input} />
      {' '}{label}{' '}
      {warning && <i className="fa fa-fw fa-info-circle text-highlight-warn" title={warning} />}
    </label>
  </div>
);

export default CheckBox;
