import moment from 'moment';

export function saveGridSize(size) {
  localStorage.setItem('gridSize', size);
}

export function getGridSize() {
  const gridSize = localStorage.getItem('gridSize');
  if (gridSize == null) {
    return 'small';
  }
  return gridSize;
}

export function saveHighContrastEnabled(enabled) {
  localStorage.setItem('highContrast', enabled);
}

export function isHighContrastEnabled() {
  return localStorage.getItem('highContrast') === 'true';
}

export function saveShowChipTooltipEnabled(enabled) {
  localStorage.setItem('showChipTooltip', enabled);
}

export function isShowChipTooltipEnabled() {
  return localStorage.getItem('showChipTooltip') !== 'false';
}

export function saveExternalKeyboardEnabled(enabled) {
  localStorage.setItem('externalKeyboard', enabled);
}

export function isExternalKeyboardEnabled() {
  return localStorage.getItem('externalKeyboard') === 'true';
}

export function getEmbedSettings() {
  return {
    embedMode: localStorage.getItem('embedMode'),
    embedVersion: localStorage.getItem('embedVersion'),
    embedTopIndent: localStorage.getItem('embedTopIndent'),
    embedBottomIndent: localStorage.getItem('embedBottomIndent'),
    mainNav: localStorage.getItem('mainNav')
  };
}

function setEmbedSettings(embedMode, embedVersion, embedTopIndent, embedBottomIndent, mainNav) {
  localStorage.setItem('embedMode', embedMode);
  embedVersion && localStorage.setItem('embedVersion', embedVersion);
  embedTopIndent && localStorage.setItem('embedTopIndent', embedTopIndent);
  embedBottomIndent && localStorage.setItem('embedBottomIndent', embedBottomIndent);
  mainNav && localStorage.setItem('mainNav', mainNav);
}

function clearEmbedSettings() {
  localStorage.removeItem('embedMode');
  localStorage.removeItem('embedVersion');
  localStorage.removeItem('embedTopIndent');
  localStorage.removeItem('embedBottomIndent');
  localStorage.removeItem('mainNav');
}

export function updateEmbedSettings() {
  const { searchParams } = new URL(window.location.href);
  const embedMode = searchParams.get('embedMode');
  const embedVersion = searchParams.get('embedVersion');
  const embedTopIndent = searchParams.get('ti');
  const embedBottomIndent = searchParams.get('bi');
  const mainNav = searchParams.get('mainNav');

  // Dont reset embedMode if querystring is empty, only reset if specied by 'none'
  //
  if (embedMode === 'none') {
    clearEmbedSettings();
  } else if (embedMode) {
    setEmbedSettings(embedMode, embedVersion, embedTopIndent, embedBottomIndent, mainNav);
  }
}

export function saveCustomerFeedbackDismissed() {
  localStorage.setItem('feedbackDismissed', moment().valueOf().toString());
}

export function isCustomerFeedbackDismissed() {
  return !!localStorage.getItem('feedbackDismissed');
}
