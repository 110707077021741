import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { colors } from '@Components/ui/styled/variables';
import useKeyPress from '@Hooks/useKeyPress';

const Ul = styled.ul`
  position: relative;
  width: ${p => p.small ? '180px' : '300px'};
  z-index: 1020;
  display: block;
  padding: 4px 0;
  margin: 0 0 0 0;
  list-style: none;
  font-size: 13px;
  text-align: left;
  background-color: ${colors.grayDarkCool};
  color: white;
  border: 1px solid ${colors.gray};
  border-radius: 4px;
  max-height: ${p => p.maxHeight ? `${p.maxHeight}px` : '700px'};
  overflow-y: auto;
  overflow-x: hidden;
  text-overflow: ellipsis;
  cursor: default;

  li a i {
    font-size: 13px;
  }

  ${props => props.light && css`
    background-color: ${colors.softGray10};
    border-color: ${colors.softGray80};
    color: ${colors.textColor};

    li.dropdown-header {
      color: ${colors.textColor};
    }

    li > a {
      color: ${colors.textColor};
      :hover {
        color: ${colors.textColor};
        background-color: ${colors.softGray40};
      }
    }

    input {
      background: ${colors.softGray20};
      ::placeholder {
        color: ${colors.colorGrayLabel};
      }
    }
  `}
`;

const Li = styled.li`
  position: relative;

  &.dropdown-header {
    display: block;
    cursor: default;
    text-transform: none;
    white-space: nowrap;
    padding: 3px 20px;
    font-size: 13px;
    font-weight: 500;
    color: ${colors.primaryLight};
    text-overflow: ellipsis;
    overflow-x: hidden;
  }

  .label-news {
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const A = styled.a`
  display: block;
  user-select: none;
  clear: both;
  white-space: nowrap;
  padding: 7px 20px 7px 20px;
  line-height: 13px;
  font-weight: 400;
  color: ${colors.grayLight};
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;

  :active, :focus, :hover {
    text-decoration: none;
    color: ${colors.grayLight};
  }
  :hover {
    background-color: ${colors.primaryDark};
  }

  ${p => p.disabled && css`
    cursor: default;
    background: transparent;
    pointer-events: none;
    opacity: 0.5;
  `}
`;

const Input = styled.input`
  padding: 4px 20px 4px 30px;
  border: 0;
  width: 100%;
  outline: none;
  background: ${colors.gray};
  ::placeholder {
    color: ${colors.grayLight};
  }
`;

const Check = styled.i`
  float: right;
`;
const Icon = styled.i`
  margin-left: -3px;
  margin-right: 10px;
`;

export const DropDownDivider = styled.li`
  background: ${colors.softGray};
  height: 1px;
  margin: 7.5px 0;
  overflow: hidden;
  cursor: default;
`;

const styleFloatR = { float: 'right' };

export const DropdownHeader = ({ children, href, onClick }) => {
  return (
    <Li className="dropdown-header" onClick={onClick}>{children}</Li>
  );
};

export const DropDownItem = ({
  children, href = '', onClick, checked = false, icon = '', noLink = false, disabled = false
}) => {
  const icls = `fa fa-fw ${icon}`;
  return (
    <Li>
      {href
        ? (
          <AnchOrLink href={href} noLink={noLink} disabled={disabled}>
            {icon && <Icon className={icls} />}
            {checked && <Check className="fas fa-check" style={styleFloatR} /> }
            {children}
          </AnchOrLink>
        )
        : (
          <A onClick={onClick} disabled={disabled}>
            {icon && <Icon className={icls} />}
            {checked && <Check className="fas fa-check" style={styleFloatR} /> }
            { children }
          </A>
        ) }
    </Li>
  );
};

const AnchOrLink = ({ children, href, noLink, disabled }) => {
  if (noLink) {
    return (<A href={href} disabled={disabled}>{children}</A>);
  }
  return (
    <A as={Link} to={href} disabled={disabled}>{children}</A>
  );
};

export const DropDownTextInput = ({ onChange, placeholder }) => {
  return (
    <Li>
      <Input type="text" onChange={onChange} placeholder={placeholder} />
    </Li>
  );
};

const DropDown = ({ children, small, light, onClose }) => {
  useKeyPress(27, onClose);

  const gridClientHeight = useSelector(state => state.gridViewState.get('gridClientHeight'));
  const maxHeight = gridClientHeight > 0 ? gridClientHeight + 30 : null;

  return (
    <Ul small={small} light={light} maxHeight={maxHeight}>
      {children}
    </Ul>
  );
};

export default DropDown;
