import React, { memo } from 'react';
import PropTypes from 'prop-types';
import AccountsListItem from '@Components/sysadmin/accounts/accounts-list/accounts-list-item';
import Loader from '@Components/ui/loader';

const AccountsList = ({
  onSelectItem, loading, routeParamsId, routeParamsTabId, list
}) => {
  if (loading) return <Loader />;
  return (
    <>
      {list.map((item) => (
        <AccountsListItem
          key={item.get('id')}
          item={item}
          onSelectItem={onSelectItem}
          routeParamsTabId={routeParamsTabId}
          isSelected={routeParamsId === item.get('id')}
        />
      ))}
    </>
  );
};

AccountsList.propTypes = {
  list: PropTypes.object,
  routeParams: PropTypes.object,
  loading: PropTypes.bool
};

export default memo(AccountsList);
