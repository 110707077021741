import React, { useState, useCallback, useEffect } from 'react';
import uniqBy from 'lodash/uniqBy';

import { DraggableListContainer, DraggableListItem } from '@Components/ui/draggable-list';
import { txt } from '@Utils/i18n-util';
import CustomFieldsForm from '@Components/services/custom-fields-form';
import { Button } from '@Components/ui/styled/main';
import { FieldsDropDown, ServicesDropDown } from '@Components/services/custom-fields-imports';

import msg from './custom-fields-editor.msg';

const fieldTypesIcons = {
  date: 'fa-light fa-calendar',
  text: 'fa-light fa-language',
  select: 'fa-light fa-calendar-lines',
  checkbox: 'fa-light fa-square-check'
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const normalizeKey = ({ label = '', key = 'new-field', ...rest }) => ({
  ...rest,
  label,
  key: key === 'new-field'
    ? label.toLowerCase().split(' ').join('-')
    : key
});

const CustomFieldsEditor = ({ customFields, onUpdateCustomFields, routeParams }) => {
  const [fields, setFields] = useState(customFields || []);
  const [editing, setEditing] = useState(null);
  const [rearrange, setRearrange] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const onUpdateFields = useCallback((fields) => {
    setLoading(true);
    onUpdateCustomFields(fields)
      .then(() => {
        setEditing(null);
        setSuccess(() => true);
      })
      .finally(() => {
        setLoading(false);
        setFields(fields);
      });
  }, [onUpdateCustomFields]);

  const onDragEnd = useCallback((result) => {
    if (!result.destination) return;
    setFields((prevFields) => reorder(prevFields, result.source.index, result.destination.index));
  }, []);

  const addField = useCallback((field, isNew) => {
    if (!isNew) {
      onUpdateFields([...fields, field]);
      return;
    }
    setFields([...fields, field]);
    setEditing(field);
  }, [fields, onUpdateFields]);

  const removeField = useCallback(() => {
    if (!editing) return;
    const newFields = fields?.filter(field => field.key !== editing.key);
    onUpdateFields(newFields);
  }, [editing, fields]);

  const onSubmit = useCallback((field) => {
    onUpdateFields(fields?.map(f => normalizeKey(f.key === field.key ? field : f)));
  }, [fields]);

  const handleReorderToggle = useCallback((e) => {
    e.preventDefault();
    if (!rearrange) {
      setRearrange(true);
      return;
    }
    onUpdateFields(fields);
    setRearrange(false);
  }, [rearrange, fields]);

  useEffect(() => {
    setFields((fields) => customFields ?? fields);
  }, [customFields]);

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setSuccess(() => false);
      }, 1000);
    }
  }, [success]);

  const onImport = useCallback((service) => {
    onUpdateFields(uniqBy([...fields, ...(service.customFields ?? [])], 'key'));
  }, [fields]);

  const handleClose = useCallback(() => {
    setEditing(() => null);
    setFields((fields) => fields.filter(f => !!f.label));
  }, []);

  return (
    <div className="custom-fields-panel">
      {editing && (
        <CustomFieldsForm
          onClose={handleClose}
          initialValues={editing}
          onRemove={removeField}
          onSubmit={onSubmit}
        />
      )}
      <div className="alert alert-info">
        {txt(msg.description)}
      </div>
      <div className="custom-fields-actions">
        <span>
          <FieldsDropDown
            onClick={addField}
            disabled={rearrange}
            filter={({ key }) => !fields.some((f) => f.key === key)}
            routeParams={routeParams}
          /> &nbsp;
          <ServicesDropDown
            onClick={onImport}
            disabled={rearrange}
            filter={s => s?.customFields?.length}
            routeParams={routeParams}
          />
        </span>
        <span>
          <span>{(loading || success) && `${txt(success ? msg.saved : msg.saving)}`}</span> &nbsp;
          <Button small gray disabled={!fields.length} onClick={handleReorderToggle}>
            <i className="fa-light fa-arrow-down-arrow-up" />
          </Button>
        </span>
      </div>
      {rearrange && (
        <div className="alert rearrange-banner">
          {txt(msg.reorderDescription)}
        </div>
      )}
      <DraggableListContainer className="custom-fields-editor" onDragEnd={onDragEnd}>
        {fields.map((field, index) => (
          <DraggableListItem
            key={field.key}
            id={field.key}
            index={index}
            className="custom-field"
            isDragDisabled={!rearrange}
            onClick={() => !rearrange && setEditing(field)}
          >
            <div className="head">
              <span className={`field-label ${field.required ? 'required' : ''}`}>
                {field.label}
                {field.required && <i className="fa-solid fa-asterisk" />}
              </span>
              {rearrange ? (
                <div className="drag-handler"><i className="fa-solid fa-bars" /></div>
              ) : (
                <span>
                  <button className="button edit">
                    {field.type === 'select' && <span>{field.options?.length} x </span>}
                    <i className={fieldTypesIcons[field.type]} />
                  </button>
                </span>
              )}
            </div>
            {!rearrange && (
              <>
                {field.helpText && <article>{field.helpText}</article>}
                <span className="field-key">{field.key}</span>
              </>
            )}
          </DraggableListItem>
        ))}
      </DraggableListContainer>
    </div>
  );
};

export default CustomFieldsEditor;
