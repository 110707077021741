import moment from 'moment';
import { upperFirst } from 'lodash';
import React, { useMemo } from 'react';
import { formatPhoneNumber } from '@Utils/phone-util';
import { Panel, Row } from '@Components/ui/styled/main';
import { FlexOne, ReportTextLabel } from '@Components/pos/style';

const OngoingList = ({ bookingsById }) => {
  const filteredBookings = useMemo(() => bookingsById.sortBy(b => b.startTime)
    .filter((b) => {
      return b.type === 'SimpleBooking' && b.customerId
        && b.status === 'Booked' && b.sales.length > 0;
    }), [bookingsById]);

  const totalResources = filteredBookings.reduce((a, b) => a + b.resources.length, 0);

  return (
    <div className="section-content">
      <div className="columns-container">
        <div className="columns-content">
          <div className="columns-content-container width-large">
            <div className="columns-content-body with-padding">
              <h4>{upperFirst(moment().format('LLLL'))}</h4>
              <Panel>
                <Row>
                  <FlexOne>
                    <ReportTextLabel>
                      <span>Antal bokningar</span>
                      <p className="mb0">{filteredBookings.size}</p>
                    </ReportTextLabel>
                  </FlexOne>
                  <FlexOne>
                    <ReportTextLabel>
                      <span>Antal platser</span>
                      <p className="mb0">{totalResources}</p>
                    </ReportTextLabel>
                  </FlexOne>
                </Row>
              </Panel>
              <table className="table table-condensed table-report mt3">
                <thead>
                  <tr>
                    <th>Namn</th>
                    <th>Bokad tid</th>
                    <th>Platser</th>
                    <th>Övriga fält</th>
                    <th>Telefonnummer</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredBookings.valueSeq().map((booking) => {
                    return (
                      <tr>
                        <td>{booking.customerName}</td>
                        <td>
                          {booking.startTime.format('HH:mm')}{' - '}
                          {booking.endTime.format('HH:mm')}
                        </td>
                        <td>{booking.resources.length}</td>
                        <td>
                          {booking.customFields?.map(({ value }) => value).filter(v => v).join(', ')}
                        </td>
                        <td>{formatPhoneNumber(booking.customerPhoneNumber)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OngoingList;
