import React from 'react';
import PropTypes from 'prop-types';
import { getSectionUrl } from '@Utils/navigate';
import Segment, { SegmentItem } from '@Components/ui/tb-segment';
import { CenterBlocks } from '@Components/ui/styled/main';

class PosHeaderNav extends React.Component {
  static propTypes = {
    routeParams: PropTypes.object.isRequired
  };

  render() {
    const { routeParams } = this.props;
    const { subsection } = routeParams;

    return (
      <CenterBlocks flex={1}>
        <Segment>
          <SegmentItem large href={getSectionUrl(routeParams, 'pos')} active={!subsection}>Ta betalt</SegmentItem>
          <SegmentItem large href={getSectionUrl(routeParams, 'pos', 'receipts')} active={subsection === 'receipts'}>Kvitton</SegmentItem>
          <SegmentItem large href={getSectionUrl(routeParams, 'pos', 'reports')} active={subsection === 'reports'}>Dagrapporter</SegmentItem>
        </Segment>
      </CenterBlocks>
    );
  }
}

export default PosHeaderNav;
