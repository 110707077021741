import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DialogCenterRow } from '@Components/dialogs/dialog-styles';
import ModalDialog from '@Components/dialogs/modal-dialog';
import LabelSwitchInput from '@Components/ui/label-switch-input';
import DDModern, {
  DDTextTitle, TextEllipsisStyledItem, DDSubList, DDLabel, DDList, DDListItem, DDWrapper
} from '@Components/ui/drop-down/drop-down-modern';

const DialogCopy = ({
  listResources, listGroups, title, onCancel, onConfirm
}) => {
  const [settings, setSettings] = useState(false);
  const [serviceMappings, setServiceMappings] = useState(false);
  const [schedules, setSchedules] = useState(false);
  const [scheduleExceptions, setScheduleExceptions] = useState(false);
  const [selectedItemResources, setSelectedItemResources] = useState(listResources[0]);
  const [selectedItemTo, setSelectedItemTo] = useState(listResources[1]);
  const [isSelectResources, setIsSelectResources] = useState(listResources[1]);

  return (
    <ModalDialog
      title={title}
      onClose={onCancel}
      buttons={[
        {
          name: 'OK',
          primary: true,
          onClick: () => {
            onConfirm({
              settings,
              serviceMappings,
              schedules,
              scheduleExceptions,
              isCallCopyResource: !!isSelectResources,
              id: isSelectResources ? isSelectResources.id : selectedItemTo.id
            });
          }
        }
      ]}
    >
      <>
        <DDTextTitle>Frän resurs:</DDTextTitle>
        <DDModern listTitle={selectedItemResources?.name}>
          <DDWrapper>
            <DDList>
              {listResources.map((item) => (
                <DDListItem
                  onClick={() => setSelectedItemResources(item)}
                  key={item.id}
                >
                  <TextEllipsisStyledItem width={240}>{item?.name}</TextEllipsisStyledItem>
                </DDListItem>
              ))}
            </DDList>
          </DDWrapper>
        </DDModern>
        <br />
        <DDTextTitle>Till:</DDTextTitle>
        <DDModern listTitle={selectedItemTo?.name}>
          <DDWrapper>
            <DDList>
              <DDLabel>Resurser:</DDLabel>
              <DDSubList>
                {listResources.map((item) => (
                  <DDListItem
                    onClick={() => {
                      setIsSelectResources(item);
                      setSelectedItemTo(item);
                    }}
                    key={item.id}
                  >
                    <TextEllipsisStyledItem width={240}>{item?.name}</TextEllipsisStyledItem>
                  </DDListItem>
                ))}
              </DDSubList>
              <DDLabel>Grupper:</DDLabel>
              <DDSubList>
                {listGroups.map((item) => (
                  <DDListItem
                    onClick={() => {
                      setIsSelectResources(false);
                      setSelectedItemTo(item);
                    }}
                    key={item.id}
                  >
                    <TextEllipsisStyledItem width={240}>{item?.name}</TextEllipsisStyledItem>
                  </DDListItem>
                ))}
              </DDSubList>
            </DDList>
          </DDWrapper>
        </DDModern>
        <br />
        <DialogCenterRow>
          <LabelSwitchInput
            noBorder
            label="Inställningar"
            id="settings"
            isOn={settings}
            handleToggle={() => setSettings(!settings)}
          />
        </DialogCenterRow>
        <DialogCenterRow>
          <LabelSwitchInput
            noBorder
            label="Tjänster"
            id="services"
            isOn={serviceMappings}
            handleToggle={() => setServiceMappings(!serviceMappings)}
          />
        </DialogCenterRow>

        <DialogCenterRow>
          <LabelSwitchInput
            noBorder
            label="Schema"
            id="schema"
            isOn={schedules}
            handleToggle={() => setSchedules(!schedules)}
          />
        </DialogCenterRow>

        <DialogCenterRow>
          <LabelSwitchInput
            noBorder
            label="Undantag från schemat"
            id="exceptionsSchedule"
            isOn={scheduleExceptions}
            handleToggle={() => setScheduleExceptions(!scheduleExceptions)}
          />
        </DialogCenterRow>
      </>
    </ModalDialog>
  );
};

DialogCopy.propTypes = {
  listResources: PropTypes.array,
  listGroups: PropTypes.array,
  title: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func
};

export default DialogCopy;
