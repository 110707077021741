import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { classes } from '@Components/ui/utils';

const ColumnListItem = ({
  onClick, disabled = false, subHeader = null, subTextRight = null, headerClass = '',
  selected, icon = null, header, children = null, subText = null
}) => {
  const classList = classes({
    'columns-list-item': true,
    disabled,
    selected,
    'read-only': !onClick
  });

  const subHeaderClass = subText || children
    ? 'list-item-header pull-right'
    : 'list-item-sub-header';

  return (
    <div className={classList} onClick={onClick}>
      {subHeader && <div className={subHeaderClass}>{subHeader}</div>}
      <div className={`list-item-header no-wrap ${headerClass}`}>
        {icon && (typeof icon === 'string' ? <i className={icon} /> : icon)}
        {header}
      </div>
      {subTextRight && <div className="list-item-sub-header">{subTextRight}</div>}
      <div className="list-item-subtext">
        {subText || children}
      </div>
    </div>
  );
};

ColumnListItem.propTypes = {
  headerClass: PropTypes.string,
  header: PropTypes.string,
  subHeader: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  subText: PropTypes.string,
  subTextRight: PropTypes.string,
  selected: PropTypes.bool,
  onClick: PropTypes.func
};

export default memo(ColumnListItem);
