import React, { useEffect, useState } from 'react';
import moment from 'moment';
import ReactDataSheet from 'react-datasheet';
import { scheduleTimeTypes } from '@Utils/booking-util';
import { hoursToMinutes, minutesToHours } from '../reports-helpers';

function getMinutes(items, timeType) {
  return items?.find(i => i.timeType === timeType)?.minutes;
}

function getHeaderRow() {
  const header = [{ readOnly: true, className: 'col-header' }];
  Object.values(scheduleTimeTypes).forEach((timeType) => {
    header.push({ readOnly: true, value: timeType, className: 'col-header' });
  });
  return header;
}

function getDateRow(date, items, totals, holidays, readOnly) {
  const publicHoliday = holidays?.find(h => h.get('date') === date);
  const isSunday = moment(date).weekday() === 6;
  const isHoliday = publicHoliday || isSunday;
  const row = [{
    readOnly: true,
    value: date,
    className: isHoliday ? 'row-header holiday' : 'row-header',
    displayValue: publicHoliday?.get('name')
  }];

  Object.keys(scheduleTimeTypes).forEach((timeType) => {
    const minutes = getMinutes(items, timeType);
    totals[timeType] += minutes || 0;
    row.push({ readOnly, value: minutesToHours(minutes) });
  });
  return row;
}

function getFooterRow(totals) {
  const header = [{ readOnly: true, value: '', className: 'row-header' }];
  Object.keys(scheduleTimeTypes).forEach((timeType) => {
    header.push({ readOnly: true, value: minutesToHours(totals[timeType]), className: 'footer' });
  });
  return header;
}

function getUpdatedFooterColumn(grid, column) {
  let total = 0;
  for (let row = 1; row < grid.length - 1; row++) {
    total += hoursToMinutes(grid[row][column].value);
  }
  return { readOnly: true, value: minutesToHours(total), className: 'footer' };
}

function getGrid(startDate, endDate, entries, holidays, readOnly) {
  const totals = {};
  for (const timeType of Object.keys(scheduleTimeTypes)) {
    totals[timeType] = 0;
  }
  const dates = [];
  for (let date = moment(startDate); date.isSameOrBefore(endDate); date.add(1, 'd')) {
    dates.push(date.format('YYYY-MM-DD'));
  }

  const grid = [getHeaderRow()];
  dates.forEach((date) => {
    const items = entries ? entries[date] : null;
    grid.push(getDateRow(date, items, totals, holidays, readOnly));
  });
  grid.push(getFooterRow(totals));
  return grid;
}

function getEntries(grid) {
  const entries = {};
  grid.forEach(row => {
    const date = row[0].value;
    if (date) {
      let index = 1;
      const items = [];
      Object.keys(scheduleTimeTypes).forEach((timeType) => {
        const minutes = hoursToMinutes(row[index].value);
        if (minutes > 0) {
          items.push({ timeType, minutes, comment: null });
        }
        index++;
      });
      if (items.length > 0) {
        entries[date] = items;
      }
    }
  });
  return entries;
}

const StaffReportDatasheet = ({ startDate, endDate, entries, holidays, saveEntries, readOnly }) => {
  const [grid, setGrid] = useState(getGrid(startDate, endDate, entries, holidays, readOnly));

  useEffect(() => {
    setGrid(getGrid(startDate, endDate, entries, holidays, readOnly));
  }, [entries, holidays, readOnly]);

  const handleCellChanged = (changes) => {
    let hasError = false;
    const pattern = /^\d{0,2}[:]?\d{0,2}$/;
    const newGrid = grid.map(row => [...row]);
    changes.forEach(({ cell, row, col, value }) => {
      const valid = String(value).match(pattern);
      value = valid ? minutesToHours(hoursToMinutes(value)) : value;
      newGrid[row][col] = { ...cell, value, className: !valid ? 'error' : '' };
      newGrid[grid.length - 1][col] = getUpdatedFooterColumn(newGrid, col);

      if (!valid) {
        hasError = true;
      }
    });
    setGrid(newGrid);

    if (!hasError) {
      saveEntries(getEntries(newGrid));
    }
  };

  return (
    <ReactDataSheet
      data={grid}
      valueRenderer={cell => cell.displayValue || cell.value}
      onCellsChanged={handleCellChanged}
      isCellNavigable={(cell) => cell.className !== 'header'}
    />
  );
};

export default StaffReportDatasheet;
