import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import ScheduleTimelineItem from '@Components/schedule/schedule-timeline-item';
import ScheduleCreateNewButton from '@Components/schedule/schedule-create-new-button';
import { classes } from '@Components/ui/utils';

const DEFAULT_WIDTH_ITEM = 247;

const ScheduleHeaderSlider = ({
  handleToggleModalCopySchedule, resourceId, currentSchedule, nowSchedule, schedules, handleSetSchedule
}) => {
  const timelineRef = useRef();

  const [indexSelectedSchedule, setIndexNowSchedule] = useState(schedules.findIndex(({ key }) => currentSchedule?.key === key));
  const [visibleItems, setVisibleItems] = useState(0);

  useEffect(() => {
    if (currentSchedule?.key !== schedules[indexSelectedSchedule]?.key) {
      const newIndex = schedules.findIndex(({ key }) => currentSchedule?.key === key);
      setIndexNowSchedule(newIndex);
      setLeft(newIndex * DEFAULT_WIDTH_ITEM);
    }

    setVisibleItems(Math.floor(timelineRef.current?.offsetWidth / DEFAULT_WIDTH_ITEM));
  }, []);

  useEffect(() => {
    const currentScheduleIndex = schedules.findIndex(({ key }) => currentSchedule?.key === key);
    setLeft(currentScheduleIndex * DEFAULT_WIDTH_ITEM);
  }, [currentSchedule]);

  const initialLeft = (indexSelectedSchedule - 1) * DEFAULT_WIDTH_ITEM;
  const [left, setLeft] = useState(initialLeft > 0 ? initialLeft : 0);

  const isPrevDisabled = left <= 0;
  const classPrev = classes({
    'slick-arrow': true,
    'slick-prev': true,
    'slick-disabled': isPrevDisabled
  });

  const leftItems = left / DEFAULT_WIDTH_ITEM;
  const isNextDisabled = leftItems + visibleItems >= schedules.length;
  const classNext = classes({
    'slick-arrow': true,
    'slick-next': true,
    'slick-disabled': isNextDisabled
  });

  const onPrev = () => {
    !isPrevDisabled && setLeft(left - DEFAULT_WIDTH_ITEM);
  };

  const onNext = () => {
    !isNextDisabled && setLeft(left + DEFAULT_WIDTH_ITEM);
  };

  const styleList = {
    overflow: 'visible',
    width: `${schedules.length * DEFAULT_WIDTH_ITEM}px`,
    transform: `translate3d(-${left}px, 0px, 0px)`,
    transition: 'transform 500ms'
  };

  return (
    <div className="schedule">
      <div className={classPrev} onClick={onPrev} />
      <div className="schedule-timeline" ref={timelineRef}>
        <div className="schedule-timeline-list" style={styleList}>
          {schedules.map(({
            key, isPast, name, validFrom, validUntil
          }, index, arr) => (
            <ScheduleTimelineItem
              isPast={isPast}
              resourceId={resourceId}
              handleToggleModalCopySchedule={handleToggleModalCopySchedule}
              key={key + index}
              id={key}
              isActive={currentSchedule.key === key}
              isNowSchedule={nowSchedule?.key === key}
              onClick={handleSetSchedule}
              validFrom={validFrom}
              validUntil={validUntil}
              isShowArrow={arr.length - 1 !== index}
            />
          ))}
        </div>
      </div>
      <ScheduleCreateNewButton id={resourceId} />
      <div className={classNext} onClick={onNext} />
    </div>
  );
};

ScheduleHeaderSlider.propTypes = {
  currentSchedule: PropTypes.object,
  schedules: PropTypes.array,
  nowSchedule: PropTypes.object,
  handleSetSchedule: PropTypes.func
};

export default ScheduleHeaderSlider;
