import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { saveUserSalesTerms } from '@State/users-actions';
import { getSalesTermsDescription } from '@Utils/users';
import { Panel, Button, FormGroup } from '@Components/ui/styled/main';
import StaffSalesTermsModal from './staff-sales-terms-modal';

const StaffSalesTerms = ({ user }) => {
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);

  const onSaveSalesTerms = (values) => {
    return dispatch(saveUserSalesTerms(user.id, values))
      .finally(() => setShowModal(false));
  };

  const { type, commissionPct, markupPct } = user?.salesTerms || {};

  return (
    <Panel>
      <div className="form-group">
        <p className="mb0">
          <label className="control-label">Villkor för produktförsäljning</label>
        </p>
        <div className="text-muted">
          Inställningar för produktförsäljning och provision
        </div>
      </div>
      <div className="row mb3">
        <div className="col-xs-6">
          <FormGroup
            labelText="Villkor"
            text={getSalesTermsDescription(type)}
          />
        </div>
        {type === 'Commission' && (
          <div className="col-xs-6">
            <FormGroup
              labelText="Provision"
              text={commissionPct ? `${commissionPct}%` : '-'}
            />
          </div>
        )}
        {type === 'NetMarkup' && (
          <div className="col-xs-6">
            <FormGroup
              labelText="Påslag"
              text={markupPct ? `${markupPct}%` : '-'}
            />
          </div>
        )}
      </div>
      <Button small white onClick={() => setShowModal(true)}>Ändra villkor</Button>
      {showModal && (
        <StaffSalesTermsModal
          user={user}
          onSubmit={onSaveSalesTerms}
          onClose={() => setShowModal(false)}
        />
      )}
    </Panel>
  );
};

export default StaffSalesTerms;
