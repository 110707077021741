import React from 'react';
import { FieldArray, reduxForm } from 'redux-form';
import FormButtonsFooter from '@Components/ui/form-buttons-footer';
import Panel from '@Components/ui/styled/panel';
import GiftCardFormRows from './gift-card-form-rows';

const GiftCardForm = ({
  submitting, submitSucceeded, handleSubmit, onClose, defaultValues
}) => {
  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <Panel>
        <FieldArray
          name="giftCardRows"
          component={GiftCardFormRows}
          props={{ defaultValues }}
        />
      </Panel>
      <FormButtonsFooter
        onClose={onClose}
        submitting={submitting}
        submitSucceeded={submitSucceeded}
        handleSubmit={handleSubmit}
        submitText="Importera presentkort"
        cancelText="Stäng"
      />
    </form>
  );
};

export default reduxForm({
  form: 'gift-card-form'
})(GiftCardForm);
