import { formatOrgNo } from '@Utils/luhn-util';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { reportsQuerySelector } from '@Components/reports/reports-helpers';
import SalesReportVoucherRows from '@Components/reports/sales/sales-report-voucher-rows';
import SalesReportCategoryRows from '@Components/reports/sales/sales-report-category-rows';
import SalesReportItemRows from '@Components/reports/sales/sales-report-item-rows';
import SalesReportStaffRows from '@Components/reports/sales/sales-report-staff-rows';
import SalesReportSummaryTotal from '@Components/reports/sales/sales-report-summary-total';
import SalesReportSummaryUser from '@Components/reports/sales/sales-report-summary-user';
import SalesReportVatSummary from '@Components/reports/sales/sales-report-vat-summary';
import SalesReportPaymentSummary from '@Components/reports/sales/sales-report-payment-summary';
import { Panel } from '@Components/ui/styled/main';

class SalesReport extends Component {
  render() {
    const { reportsViewState, reportsQuery } = this.props;
    const { posOrg } = reportsQuery;
    const salesReport = reportsViewState.get('sales');
    const showReport = salesReport && !salesReport.isEmpty() && posOrg;

    return (
      <div>
        {showReport
          ? this.renderTable(salesReport.toJS(), reportsQuery)
          : this.renderNoData()}
      </div>
    );
  }

  renderTable(salesReport, reportsQuery) {
    const { posOrg, user } = reportsQuery;
    const {
      saleCount, refundCount, paymentsSummary, vatSummary
    } = salesReport;

    return (
      <Fragment key={posOrg.get('id')}>
        {user ? (
          <SalesReportSummaryUser {...salesReport} />
        ) : (
          <SalesReportSummaryTotal {...salesReport} />
        )}
        {(saleCount > 0 || refundCount > 0) && (
          <>
            {!user && (
              <>
                <h5 className="mt3">Försäljning per betalsätt</h5>
                <Panel>
                  <SalesReportPaymentSummary paymentsSummary={paymentsSummary} />
                </Panel>
                <h5 className="mt3">Försäljning per momssats</h5>
                <Panel>
                  <SalesReportVatSummary {...vatSummary} />
                </Panel>
              </>
            )}
            <h5 className="mt3">Försäljning per kategori</h5>
            <Panel>
              <SalesReportCategoryRows {...salesReport} />
            </Panel>
            <h5 className="mt3">Presentkort</h5>
            <Panel>
              <SalesReportVoucherRows {...salesReport} />
            </Panel>
            <h5 className="mt3">Försäljning per produkt</h5>
            <Panel>
              <SalesReportItemRows {...salesReport} />
            </Panel>
            {!user && (
              <>
                <h5 className="mt3">Försäljning per personal</h5>
                <Panel>
                  <SalesReportStaffRows {...salesReport} />
                </Panel>
              </>
            )}
          </>
        )}
      </Fragment>
    );
  }

  renderNoData() {
    return (
      <div className="alert alert-info">
        <i className="fa fa-info-circle" /> Det finns ingen försäljning för vald period.
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { reportsViewState } = state;

  return {
    reportsViewState,
    reportsQuery: reportsQuerySelector(state, ownProps)
  };
};

export default connect(mapStateToProps)(SalesReport);
