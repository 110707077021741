import React from 'react';
import { reportingFormat, reportingCount } from '@Utils/format';
import { Row, Panel } from '@Components/ui/styled/main';
import { FlexOne, ReportTextLabel } from '@Components/pos/style';
import { getTotal } from '../reports-helpers';

const SalesReportSummaryUser = ({ salesRows, transactionCounts }) => {
  const netAmount = getTotal(salesRows, 'netAmount');
  const refundAmount = getTotal(salesRows, 'refundAmount');
  const grossAmount = (netAmount || 0) + Math.abs(refundAmount || 0);

  return (
    <Panel>
      <Row>
        <FlexOne>
          <ReportTextLabel>
            <span>Bruttoförsäljning</span>
            <p className="mb0">{reportingFormat(grossAmount)}</p>
          </ReportTextLabel>
        </FlexOne>
        <FlexOne>
          <ReportTextLabel>
            <span>Återköpt</span>
            <p className="mb0">{reportingFormat(refundAmount)}</p>
          </ReportTextLabel>
        </FlexOne>
        <FlexOne>
          <ReportTextLabel>
            <span>Nettoförsäljning</span>
            <p className="mb0">{reportingFormat(netAmount)}</p>
          </ReportTextLabel>
        </FlexOne>
        <FlexOne>
          <ReportTextLabel>
            <span>Antal köp</span>
            <p className="mb0">{reportingCount(transactionCounts?.Sale?.count || 0)}</p>
          </ReportTextLabel>
        </FlexOne>
        <FlexOne>
          <ReportTextLabel>
            <span>Antal återköp</span>
            <p className="mb0">{reportingCount(transactionCounts?.Refund?.count || 0)}</p>
          </ReportTextLabel>
        </FlexOne>
      </Row>
    </Panel>
  );
};

export default SalesReportSummaryUser;
