import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { mergeOrgSettings, testSwishIntegration } from '@State/pos-config-actions';
import ModalDialog from '@Components/dialogs/modal-dialog';
import { getSysAdmin } from '@State/selectors';
import { getPosOrgSwishPrefs } from '@State/pos-config-selectors';
import { pos } from '@Utils/preference-keys';
import AutoSaveTextInput from '@Components/inputs/auto-save-text-input';
import Button from '@Components/ui/button';
import AlertWithIcon from '@Components/ui/alert-with-icon';
import ProgressButton from '@Components/ui/progress-button';
import { getUseSwishIntegration } from '@Utils/pos-utils';
import { Flex } from '@Components/ui/styled/main';

const PosOrgSwishModal = ({ posOrg, onClose }) => {
  const dispatch = useDispatch();
  const isSysadmin = useSelector(getSysAdmin);

  const swishPrefs = useSelector(state => getPosOrgSwishPrefs(state, { posOrg }));
  const {
    [pos.swishPayeeAlias]: swishPayeeAlias, [pos.swishCertId]: swishCertId
  } = swishPrefs || {};
  const useSwishIntegration = getUseSwishIntegration(swishPrefs);

  const [swishVerified, setSwishVerified] = useState(false);
  const [swishError, setSwishError] = useState(false);
  const notVerified = !swishVerified && !swishError;

  const handleSave = useCallback((values) => {
    return dispatch(mergeOrgSettings(posOrg.get('id'), values));
  }, [posOrg]);

  const handleActivate = () => {
    return handleSave({ [pos.useSwishIntegration]: true })
      .then(() => onClose());
  };

  const handleDeactivate = () => {
    return handleSave({ [pos.useSwishIntegration]: false })
      .then(() => {
        setSwishVerified(false);
        setSwishError(false);
      });
  };

  const handleTestSwish = useCallback(() => {
    return dispatch(testSwishIntegration(posOrg.get('id')))
      .then(() => {
        setSwishVerified(true);
        setSwishError(false);
      })
      .catch(() => setSwishError(true));
  }, [posOrg]);

  return (
    <ModalDialog
      contentSize="small"
      title="Integration med Swish Handel"
      onClose={onClose}
      hideCloseButton
    >
      {useSwishIntegration ? (
        <AlertWithIcon success icon="fa fa-check-circle">
          Integration med Swish Handel är aktiverad
        </AlertWithIcon>
      ) : (
        <AlertWithIcon>
          För att aktivera Swish-integration krävs ett avtal med din bank för Swish Handel. När du ansöker om avtal
          via din bank behöver du uppge <strong>Cliento by Yabie</strong> som teknisk leverantör.
        </AlertWithIcon>
      )}

      <AutoSaveTextInput
        label="Swish-nummer"
        name={pos.swishPayeeAlias}
        initialValue={swishPayeeAlias}
        onSave={handleSave}
      />
      {isSysadmin && (
        <AutoSaveTextInput
          label="Swish-certifikat"
          name={pos.swishCertId}
          initialValue={swishCertId}
          onSave={handleSave}
        />
      )}

      <AlertWithIcon
        warning={notVerified && !useSwishIntegration}
        success={swishVerified}
        error={swishError}
        icon={swishVerified ? 'fa fa-check-circle' : null}
      >
        {notVerified && !useSwishIntegration && (
          <p>Innan integrationen kan aktiveras behöver du verifiera att den fungerar.</p>
        )}
        {notVerified && useSwishIntegration && (
          <p>Du kan testa integrationen för att verifiera att den fungerar som den ska.</p>
        )}
        {swishVerified && <div>Integrationen fungerar! Du kan nu aktivera Swish Handel.</div>}
        {swishError && <p>Kunde inte ansluta till Swish. Kontrollera inställningarna och försök igen.</p>}
        {!swishVerified && (
          <ProgressButton small white onClick={handleTestSwish}>
            Verifiera integration
          </ProgressButton>
        )}
      </AlertWithIcon>
      <Flex fullWidth>
        <div>
          {useSwishIntegration && (
            <ProgressButton small gray onClick={handleDeactivate}>
              Avaktivera integration
            </ProgressButton>
          )}
        </div>
        <div>
          <Button small gray onClick={onClose}>
            Stäng
          </Button>
          {!useSwishIntegration && (
            <ProgressButton small primary marginLeft disabled={!swishVerified} onClick={handleActivate}>
              Aktivera Swish Handel
            </ProgressButton>
          )}
        </div>
      </Flex>
    </ModalDialog>
  );
};

export default PosOrgSwishModal;
