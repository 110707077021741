import React from 'react';
import { useSelector } from 'react-redux';
import { navigateAsync, navigateSearchAsync } from '@Utils/navigate';
import { getSearchFromResourceIds } from '@Utils/url-util';
import ResourceEntry from './resource-entry';

const Navigate = (href, cb, scrollToFirstElement, resourceId) => ev => {
  ev.preventDefault();
  if (cb) cb();
  if (scrollToFirstElement) scrollToFirstElement(resourceId);
  navigateAsync(href);
};

const GroupEntry = (props) => {
  const {
    group, resources, collapsedIds, onOptionClick, routeParams, groupTargetUrlResolver,
    scrollToFirstElement, collapsibleGroups, onToggleCollapse, resourcesInView
  } = props;
  const { id, name, resourceIds } = group;
  const { entityType, entityId } = routeParams;
  const multiResourceMode = useSelector(state => state.calendarViewState.get('multiResourceMode'));

  const groupResources = resourceIds.map(id => resources[id]).filter(r => r);
  const isCollapsed = collapsedIds && collapsedIds.indexOf(group.id) !== -1;

  const collapseIcon = isCollapsed ? 'fa fa-chevron-right' : 'fa fa-chevron-down';
  const active = (entityType === 'group' && id === entityId && !multiResourceMode);
  const itemClasses = active ? 'list-group-item group-item active' : 'list-group-item group-item';
  const linkTarget = groupTargetUrlResolver(id);

  const handleToggleCollapsed = (ev, id, collapse) => {
    ev.stopPropagation();
    ev.preventDefault();
    onToggleCollapse(id, collapse);
  };

  const handleMultiResourceClick = (ev) => {
    ev.stopPropagation();
    ev.preventDefault();
    const resourceIdsInView = resourcesInView.map(r => r.id);
    const notInView = resourceIds.filter(id => !resourceIdsInView.includes(id));

    if (notInView.length === 0) {
      const newResourceIds = resourceIdsInView.filter(ri => !resourceIds.includes(ri));
      navigateSearchAsync(getSearchFromResourceIds(newResourceIds));
    } else {
      const newResourceIds = [...resourceIdsInView, ...notInView];
      navigateSearchAsync(getSearchFromResourceIds(newResourceIds));
    }
  };

  return (
    <li className={group.id === 0 ? 'my-resources' : null}>
      <a
        className={itemClasses}
        href="#"
        onClick={multiResourceMode
          ? handleMultiResourceClick
          : Navigate(linkTarget, onOptionClick, scrollToFirstElement, id)}
      >
        {collapsibleGroups && (
          <button className="toggle-collapsed" onClick={(ev) => handleToggleCollapsed(ev, id, !isCollapsed)}>
            <i className={collapseIcon} />
          </button>
        )}
        {id === 0 && <i className="fa fa-users" />}
        <span className="name">{id === 0 ? 'Mina resurser' : name}</span>
      </a>
      {!isCollapsed && (
        <ul className="list-group">
          {groupResources.map(resource => (
            <ResourceEntry
              key={resource.id}
              resource={resource}
              {...props}
            />
          ))}
        </ul>
      )}
    </li>
  );
};

export default GroupEntry;
