import React from 'react';
import ModalDialog from '@Components/dialogs/modal-dialog';
import { DialogContent } from '@Components/dialogs/dialog-styles';
import { Panel } from '@Components/ui/styled/main';

const PosOrderModal = ({ onClose }) => {
  const onOpenChat = (ev) => {
    ev.preventDefault();
    window.$zopim?.livechat.window.show();
  };
  return (
    <ModalDialog
      contentSize="large"
      closeButtonText="Stäng"
      title=" "
      onClose={onClose}
    >
      <DialogContent>
        <div className="text-center">
          <img src="/shopping-sales.svg" className="pos-image" />
        </div>
        <h4 className="text-center">
          Kontakta oss för offert eller om du vill veta mer
        </h4><br />
        <Panel>
          <div className="pos-order-contact">
            <i className="fa fa-phone" />&nbsp; <a href="tel:+468321616">08-32 16 16</a><br />
            <i className="fa fa-comment" />&nbsp; <a href="#" onClick={onOpenChat}>Chatta med oss</a><br />
            <i className="fa fa-envelope" />&nbsp; <a href="mailto:support@cliento.com">support@cliento.com</a>
          </div>
        </Panel>
      </DialogContent>
    </ModalDialog>
  );
};

export default PosOrderModal;
