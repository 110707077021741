import { defineMessages } from 'react-intl';

export default defineMessages({
  btnLabel: {
    id: 'calendar.undoButton.label',
    defaultMessage: 'Undo'
  },
  bookingChanged: {
    id: 'calendar.undoButton.bookingChanged',
    defaultMessage: 'The time or duration has been changed, click on {icon} to undo.'
  },
  dontShowAgain: {
    id: 'calendar.undoButton.dontShowAgain',
    defaultMessage: 'Dont show again'
  }
});
