import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSysAdmin } from '@State/selectors';
import { addTerminalAssociation } from '@State/pos-config-actions';
import { getOnpremPosUnits } from '@State/pos-config-selectors';
import { Button, Panel } from '@Components/ui/styled/main';
import { GrayLabelText } from '@Components/admin/pos-config/style';
import TerminalPanel from './terminal-panel';
import TerminalAssociateForm from './terminal-associate-form';

const PosOrgTerminals = () => {
  const dispatch = useDispatch();
  const isSysAdmin = useSelector(getSysAdmin);
  const allTerminals = useSelector(state => state.allTerminals);
  const allOnpremPosUnits = useSelector(state => state.allOnpremPosUnits);
  const posUnits = useSelector(getOnpremPosUnits);
  const [associateFormId, showAssociateForm] = useState(null);

  const hideForm = (ev) => {
    ev && ev.preventDefault();
    showAssociateForm(null);
  };

  const associateTerminal = (values) => {
    return dispatch(addTerminalAssociation(values))
      .then(() => hideForm());
  };

  return (
    <>
      <GrayLabelText>Kortterminaler</GrayLabelText>
      {posUnits && posUnits.map((posUnit) => {
        const vunitId = posUnit.get('id');
        const association = allOnpremPosUnits.find(a => a.get('id') === vunitId);
        const terminalId = association?.get('terminalId');
        if (terminalId) {
          const terminal = allTerminals.find(t => t.get('id') === terminalId);
          return (
            <TerminalPanel key={vunitId} terminal={terminal} posUnit={association} />
          );
        }
        return (
          <Panel key={vunitId}>
            <p>Ingen terminal kopplad till {posUnit.get('posOrgName')} ({posUnit.get('name')})</p>
            {isSysAdmin && (
              <Button small outlinePrimary onClick={() => showAssociateForm(vunitId)}>Koppla terminal</Button>
            )}
          </Panel>
        );
      })}
      {associateFormId && (
        <TerminalAssociateForm
          onSubmit={associateTerminal}
          onClose={hideForm}
          terminals={allTerminals}
          posUnits={allOnpremPosUnits}
          initialValues={{
            vunitId: associateFormId
          }}
        />
      )}
    </>
  );
};

export default PosOrgTerminals;
