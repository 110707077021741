import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { destroy } from 'redux-form';
import { useSelector, useDispatch } from 'react-redux';
import useCustomer from '@Components/customers/useCustomer';
import { getPosOrgInvoicePrefs } from '@State/pos-config-selectors';
import { getInvoicePrefsValidation, getInvoiceUseEmailConfig } from '@Utils/invoice-util';
import { createInvoiceCustomer, fetchInvoiceCustomer, syncInvoiceCustomer, updateInvoiceCustomer } from '@State/invoice-actions';
import { fetchContactsByOrgId } from '@State/pos-config-actions';
import ModalDialog from '@Components/dialogs/modal-dialog';
import DialogAlert from '@Components/dialogs/dialog-alert';
import PosOrgInvoiceModal from '@Components/admin/pos-config/pos-org-invoice-modal';
import DialogLoader from '@Components/dialogs/dialog-loader';
import { pos } from '@Utils/preference-keys';
import { getPosOrg, getSaleDiscount } from '@State/pos-selectors';
import InvoiceForm from './invoice-form';
import InvoiceCustomerForm, { defaultValues } from './invoice-customer-form';
import InvoiceCustomerSelect from './invoice-customer-select';
import InvoiceEmailForm from './invoice-email-form';

const InvoiceModal = ({ totalAmount, grossAmount, saleItems, onSubmit, onClose, isLoading }) => {
  const dispatch = useDispatch();
  const posOrg = useSelector(getPosOrg);
  const discount = useSelector(state => getSaleDiscount(state));
  const invoicePrefs = useSelector(state => getPosOrgInvoicePrefs(state, { posOrg }));
  const useEmailConfig = getInvoiceUseEmailConfig(invoicePrefs);
  const { customerId, selectedCustomer } = useCustomer();

  useEffect(() => {
    if (selectedCustomer?.invoiceCustomerId) {
      dispatch(fetchInvoiceCustomer(selectedCustomer?.invoiceCustomerId))
        .then((res) => setInvoiceCustomer(res));
    } else {
      setInvoiceCustomer(null);
    }
  }, [selectedCustomer]);

  useEffect(() => {
    if (invoicePrefs[pos.invoiceCopyToBillingContact]) {
      dispatch(fetchContactsByOrgId(posOrg.get('id')))
        .then(({ contacts }) => setBillingContact(contacts.Billing));
    }
    return () => {
      dispatch(destroy('invoice-form'));
    };
  }, []);

  const [submitError, setSubmitError] = useState(null);
  const [showEmailForm, setShowEmailForm] = useState(false);
  const [billingContact, setBillingContact] = useState(null);
  const [invoiceCustomer, setInvoiceCustomer] = useState(null);
  const handleSelectCustomer = (customer) => {
    setInvoiceCustomer(customer);
    setSubmitError(null);
  };
  const handleResetCustomer = () => {
    setInvoiceCustomer(null);
    setSubmitError(null);
  };

  const [showNewCustomer, setShowNewCustomer] = useState(false);
  const handleNewCustomer = () => setShowNewCustomer(true);
  const handleCloseNewCustomer = () => setShowNewCustomer(false);

  const [showUpdateCustomer, setShowUpdateCustomer] = useState(null);
  const handleUpdateCustomer = (customer) => setShowUpdateCustomer(customer);
  const handleCloseUpdateCustomer = () => setShowUpdateCustomer(null);

  const { useFortnox, hasDueDays, hasAccountNumber } = getInvoicePrefsValidation(invoicePrefs);
  const hasMissingConfig = !useFortnox && !hasAccountNumber || useFortnox && !hasDueDays;
  const [showInvoicePrefs, setShowInvoicePrefs] = useState(hasMissingConfig);
  const handleCloseInvoicePrefs = () => setShowInvoicePrefs(false);

  const onCreateCustomer = (values) => {
    return dispatch(createInvoiceCustomer(posOrg.get('id'), values))
      .then(customer => {
        setInvoiceCustomer(customer);
        handleCloseNewCustomer();
        setSubmitError(null);
      });
  };

  const onUpdateCustomer = (values) => {
    return dispatch(updateInvoiceCustomer(posOrg.get('id'), values))
      .then(customer => {
        setInvoiceCustomer(customer);
        handleCloseUpdateCustomer();
        setSubmitError(null);
      });
  };

  const onSyncCustomer = () => {
    return dispatch(syncInvoiceCustomer(posOrg.get('id'), invoiceCustomer.get('id')))
      .then(fortnoxCustomerNumber => {
        setInvoiceCustomer(invoiceCustomer.set('fortnoxCustomerNumber', fortnoxCustomerNumber));
      });
  };

  const customerRef = invoicePrefs[pos.useCustomerInvoiceRef] && selectedCustomer
    ? selectedCustomer.name
    : null;

  const initialValues = useMemo(() => {
    return {
      invoiceDate: moment().startOf('d').format('L'),
      invoiceDueDate: moment().startOf('d').add(invoicePrefs[pos.invoiceDueDays], 'd').format('L'),
      invoiceDueDays: invoicePrefs[pos.invoiceDueDays],
      latePaymentInterest: invoicePrefs[pos.latePaymentInterest],
      invoiceEmailSubject: useEmailConfig ? invoicePrefs[pos.invoiceEmailSubject] : null,
      invoiceEmailBody: useEmailConfig ? invoicePrefs[pos.invoiceEmailBody] : null
    };
  }, [invoicePrefs]);

  const handleSubmit = (data) => {
    if (useEmailConfig && !showEmailForm && data.emailInvoice) {
      setShowEmailForm(true);
      return;
    }
    return onSubmit({
      ...data,
      customerId,
      invoiceCustomerId: invoiceCustomer.get('id'),
      emailTo: data.emailInvoice ? {
        email: invoiceCustomer.get('email'),
        name: invoiceCustomer.get('customerName')
      } : null,
      copyTo: billingContact ? {
        email: billingContact.email,
        name: billingContact.name
      } : null,
      latePaymentInterest: data.latePaymentInterest || 0,
      emailSubject: data.invoiceEmailSubject,
      emailBody: data.invoiceEmailBody
    }).catch((error) => {
      setSubmitError(error);
    });
  };

  if (useFortnox && !!discount) {
    return (
      <DialogAlert
        warning
        icon="fa fa-exclamation-triangle"
        text="Kvittorabatt stöds inte på Fortnox-faktura. Lägg rabatt på varje artikel istället."
        onClose={onClose}
      />
    );
  }

  if (isLoading) {
    return <DialogLoader title="Skapar faktura..." />;
  }

  if (showNewCustomer) {
    return (
      <ModalDialog
        title="Ny mottagare"
        hideCloseButton
        contentSize="large"
        closeOnClickOutside={false}
        onClose={handleCloseNewCustomer}
      >
        <InvoiceCustomerForm
          posOrg={posOrg}
          initialValues={defaultValues}
          onSubmit={onCreateCustomer}
          onClose={handleCloseNewCustomer}
        />
      </ModalDialog>
    );
  }

  if (showUpdateCustomer) {
    return (
      <ModalDialog
        title="Uppdatera mottagare"
        hideCloseButton
        contentSize="large"
        closeOnClickOutside={false}
        onClose={handleCloseUpdateCustomer}
      >
        <InvoiceCustomerForm
          posOrg={posOrg}
          initialValues={showUpdateCustomer}
          onSubmit={onUpdateCustomer}
          onClose={handleCloseUpdateCustomer}
        />
      </ModalDialog>
    );
  }

  if (showInvoicePrefs) {
    return (
      <PosOrgInvoiceModal
        posOrg={posOrg}
        onSaved={handleCloseInvoicePrefs}
        onClose={onClose}
      />
    );
  }

  return (
    <ModalDialog
      title="Skapa faktura"
      hideCloseButton
      contentSize="large"
      closeOnClickOutside={false}
      onClose={onClose}
    >
      <InvoiceCustomerSelect
        posOrgId={posOrg.get('id')}
        selectedCustomer={invoiceCustomer}
        onSelectCustomer={handleSelectCustomer}
        onNewCustomer={handleNewCustomer}
        onResetCustomer={handleResetCustomer}
        onUpdateCustomer={handleUpdateCustomer}
        readonly={showEmailForm}
      />
      {showEmailForm ? (
        <InvoiceEmailForm
          submitError={submitError}
          onSubmit={handleSubmit}
          onClose={() => setShowEmailForm(false)}
        />
      ) : (
        <InvoiceForm
          posOrgId={posOrg.get('id')}
          invoicePrefs={invoicePrefs}
          saleItems={saleItems}
          grossAmount={grossAmount}
          totalAmount={totalAmount}
          customerRef={customerRef}
          initialValues={initialValues}
          selectedCustomer={invoiceCustomer}
          onCreateCustomer={onSyncCustomer}
          billingContact={billingContact}
          useEmailConfig={useEmailConfig}
          submitError={submitError}
          onSubmit={handleSubmit}
          onClose={onClose}
        />
      )}
    </ModalDialog>
  );
};

export default InvoiceModal;
