import React from 'react';
import styled from 'styled-components';
import { Text, Panel } from '@Components/ui/styled/main';
import { formatOrgNo } from '@Utils/luhn-util';

import { colors } from '@Components/ui/styled/variables';
import LabelSwitchInput from '@Components/ui/label-switch-input';

const PanelStyled = styled(Panel)`
  > div {
    margin-bottom: 0px;
  }
  div:last-child { margin-bottom: 0}
  padding: 10px 15px;
  label { font-weight: bold }
`;

const getState = (cashierRole) => {
  switch (cashierRole) {
    case 'Admin':
      return {
        cashier: true,
        admin: true
      };
    case 'Cashier':
      return {
        cashier: true,
        admin: false
      };
    default:
      return {
        cashier: false,
        admin: false
      };
  }
};

const StaffAssignCashierRolesOrg = ({ posOrg, posRole, onChangeToggle }) => {
  const { companyName, companyOrgNo } = posOrg ? posOrg.toJS() : {};
  const { cashier, admin } = getState(posRole?.cashierRole);

  const handleChangeCashier = () => {
    if (cashier) {
      return onChangeToggle(posRole?.posOrgId, '', true);
    }
    if (!cashier && !admin) {
      return onChangeToggle(posRole?.posOrgId, 'Cashier');
    }
    if (!cashier && admin) {
      return onChangeToggle(posRole?.posOrgId, 'Admin');
    }
  };

  const handleChangeAdmin = () => {
    if (!admin) {
      return onChangeToggle(posRole?.posOrgId, 'Admin');
    }
    if (admin && cashier) {
      return onChangeToggle(posRole?.posOrgId, 'Cashier');
    }
  };

  return (
    <>
      <div className="mb1">
        <Text bold muted fs={14}>
          {companyName} {formatOrgNo(companyOrgNo || '')}
        </Text>
      </div>
      <PanelStyled bgColor={colors.bgGrayLight}>
        <LabelSwitchInput
          noBorder
          label="Kassör"
          isOn={cashier}
          id={`cashier${companyOrgNo}_${posRole.posOrgId}`}
          handleToggle={handleChangeCashier}
        />
        <LabelSwitchInput
          noBorder
          label="Access till dagrapporter m.m."
          isOn={admin}
          disabled={!cashier}
          id={`admin${companyOrgNo}_${posRole.posOrgId}`}
          handleToggle={handleChangeAdmin}
        />
      </PanelStyled>
    </>
  );
};

export default StaffAssignCashierRolesOrg;
