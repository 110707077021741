export const typeOptions = [
  {
    value: 'FixedPriceResources',
    text: 'FixedPriceResources'
  },
  {
    value: 'Resource',
    text: 'Resource'
  },
  {
    value: 'Location',
    text: 'Location'
  },
  {
    value: 'SmsTopUp',
    text: 'SmsTopUp'
  },
  {
    value: 'Sms',
    text: 'Sms'
  },
  {
    value: 'Discount',
    text: 'Discount'
  },
  {
    value: 'Vunit',
    text: 'Vunit'
  },
  {
    value: 'ExtraVunit',
    text: 'ExtraVunit'
  },
  {
    value: 'Terminal',
    text: 'Terminal'
  },
  {
    value: 'Hardware',
    text: 'Hardware'
  }
];
