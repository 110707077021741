import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '@Components/ui/styled/variables';
import { formatPhoneNumber, isMobile } from '@Utils/phone-util';
import { Text } from '@Components/ui/styled/main';
import { getTimeStaffCreatedTime } from '@Utils/time-util';
import { getLogIcon } from '@Components/customers/customer-styles';
import EventCard from '@Components/ui/event-card';
import CustomerDetailLogImages from './customer-detail-log-images';

function getSmsContent(comment) {
  try {
    return JSON.parse(comment);
  } catch {
    return null;
  }
}

const CustomerDetailLog = ({
  comment, created, handlerSelectLogs, id, type, files, commenter
}) => {
  const isSms = type === 'Sms';
  const onClick = () => handlerSelectLogs && handlerSelectLogs(id);
  const smsContent = isSms ? getSmsContent(comment) : null;
  const { sender, msisdn, message } = smsContent || {};
  const smsSender = isMobile(sender) ? formatPhoneNumber(sender) : sender;
  const hasText = message || comment;

  return (
    <EventCard
      isSelectable={!isSms}
      borderColor={isSms ? colors.smsBg : colors.logsBg}
      handler={onClick}
      icon={getLogIcon(type)}
    >
      {smsContent && (
        <Text muted fs="13">Från {smsSender} till {formatPhoneNumber(msisdn)}</Text>
      )}
      {hasText && <Text fontWeight={600} dark fs="13">{message || comment}</Text>}
      <CustomerDetailLogImages images={files} />
      <Text marginTop="5px" muted fs="13">
        {getTimeStaffCreatedTime(created)}
        {commenter && ` av ${commenter}`}
      </Text>
    </EventCard>
  );
};

CustomerDetailLog.propTypes = {
  comment: PropTypes.string,
  created: PropTypes.string
};

export default CustomerDetailLog;
