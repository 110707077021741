import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import FormButtonsFooter from '@Components/ui/form-buttons-footer';
import HookFormInput from '@Components/inputs/hook-form-input';

const AccountOverviewTrialDaysForm = ({ onClose, onSubmit, loading, initialValues = {} }) => {
  const methods = useForm({ defaultValues: initialValues });

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <HookFormInput
          label="Trial days"
          id="trialDays"
          type="number"
          registerOptions={{ required: true, valueAsNumber: true, min: 0 }}
        />
        <FormButtonsFooter
          onClose={onClose}
          handleSubmit={methods.handleSubmit}
          submitting={loading}
          submitSucceeded
        />
      </form>
    </FormProvider>
  );
};

export default AccountOverviewTrialDaysForm;
