import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import moment from 'moment';

import { createReleaseNote, updateReleaseNote } from '@State/sysadmin-actions';
import { getSectionUrl, navigate } from '@Utils/navigate';
import ModalDialog from '@Components/dialogs/modal-dialog';
import ReleaseNotesForm from './release-notes-form';

const ReleaseNotesEditModal = ({ isNew = false, releaseNote = null, onClose, routeParams }) => {
  const { id: routeId } = useParams();
  const dispatch = useDispatch();

  const methods = useForm({
    defaultValues: releaseNote
      ? releaseNote.toJS()
      : { date: moment().format('L') }
  });

  const onSubmit = useCallback(async (values) => {
    const method = isNew ? createReleaseNote : updateReleaseNote;
    const res = await dispatch(method(values));

    if (res?.id && res.id !== parseInt(routeId)) {
      navigate(getSectionUrl(routeParams, `release-notes/${res.id}`));
    }
    onClose();
  }, [routeId]);

  return (
    <ModalDialog
      title="Release notes"
      onClose={onClose}
      contentSize="large"
      closeOnClickOutside={false}
    >
      <FormProvider {...methods}>
        <ReleaseNotesForm onSubmit={onSubmit} onClose={onClose} />
      </FormProvider>
    </ModalDialog>
  );
};

export default ReleaseNotesEditModal;
