import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
import { config } from '@Utils/preference-keys';
import { fetchLocationConfig } from '@State/account-actions';
import { viewDateFromDate } from '@Utils/time-util';

class SwitchLocation extends Component {
  componentDidMount() {
    // WHen this component loads, it's job is to fetch the location specific configuration for the current user
    // such as assigned resource id, location role, preferred viewMode etc..  So that it can render the correct
    // main view
    //

    // this.props.fetchConfig();
  }

  render() {
    return <Redirect push={false} to={getCalendarStartPath(this.props)} />;
  }
}

function getCalendarStartPath(props) {
  const { org, loc } = props.match.params;
  const thisWeek = viewDateFromDate('week', moment());
  const today = moment().format('YYYY-MM-DD');

  const url = props.resourceId == null ? `/${org}/${loc}/calendar/day/${today}/group/0`
    : `/${org}/${loc}/calendar/week/${thisWeek}/resource/${props.resourceId}`;

  return url;
}

const mapStateToProps = (state, ownProps) => {
  return {
    resourceId: state.locationConfig.get(config.resourceId),
    locationRole: state.locationConfig.get(config.locationRole)
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchConfig: () => {
      dispatch(fetchLocationConfig());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SwitchLocation);
