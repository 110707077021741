import React, { useState } from 'react';
import { TableTd } from '@Components/ui/styled/table';
import CurrencyUtil from '@Utils/currency-util';
import Popover from '@Components/ui/popover';
import ConfirmBlock from '@Components/ui/confirm-block';
import { Button } from '@Components/ui/styled/main';

const AccountOverviewHardwareTableItem = ({ item, onConfirm }) => {
  const [showConfirmDeleteInvoice, setShowConfirmDeleteInvoice] = useState(false);
  const onHide = (e) => {
    if (e) e.stopPropagation();
    setShowConfirmDeleteInvoice(false);
    return false;
  };

  const onConfirmDeleteHardware = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    onConfirm(item.get('id'));
    setShowConfirmDeleteInvoice(false);
  };
  const onShowPopup = (e) => {
    if (e) e.stopPropagation();
    setShowConfirmDeleteInvoice(true);
    return false;
  };

  return (
    <>
      <TableTd>
        {item.get('chargeType') || '-'}
      </TableTd>
      <TableTd>
        {item.get('description') || '-'}
      </TableTd>
      <TableTd>
        {item.get('contractStartTime') || '-'}
      </TableTd>
      <TableTd>
        {item.get('contractLength') || '-'}
      </TableTd>
      <TableTd width="20px">
        {item.get('units') || '-'}
      </TableTd>
      <TableTd right>
        {item.get('price') ? CurrencyUtil.currency(item.get('price'), 2) : '-'}
      </TableTd>
      <TableTd paddingLeft="25px" right>
        {item.get('id') && (
        <Popover
          onOuterAction={onHide}
          isOpen={showConfirmDeleteInvoice}
          className="Popover-big-radius"
          body={(
            <div className="Popover-content-small">
              <ConfirmBlock
                title="Are you sure?"
                onCancel={onHide}
                onConfirm={onConfirmDeleteHardware}
              />
            </div>
          )}
        >
          <Button small className="btn-delete" onClick={onShowPopup}>X</Button>
        </Popover>
        )}
      </TableTd>
    </>
  );
};

export default AccountOverviewHardwareTableItem;
