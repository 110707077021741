import React from 'react';
import { connect } from 'react-redux';
import { getFeatures } from '@State/selectors';

const TagsInfo = ({
  time, resource, service, customer, booking, bookingLink, location, features
}) => {
  const company = features.EnableCompanyBooking;
  const vehicle = features.EnableVehicleBooking;
  return (
    <div className="tags-info">
      <div><strong>Taggar med information från bokningen:</strong></div>
      {time && (
      <div>
        <samp>#TIDPUNKT</samp> = Datum och tid för bokningen<br />
        <samp>#DATUM</samp> = Endast datum för bokningen
      </div>
      )}
      {resource && (
      <div>
        <samp>#RESURS</samp> = Namn på resursen
      </div>
      )}
      {service && (
      <div>
        <samp>#TJÄNST</samp> = Namn på tjänsten
      </div>
      )}
      {booking && (
      <div>
        <samp>#BOKNINGSREF</samp> = Bokningsreferens
      </div>
      )}
      {location && (
      <div>
        <samp>#FÖRETAG</samp> = Ert företagsnamn
      </div>
      )}
      {location && (
      <div>
        <samp>#TEL</samp> = Ert telefonnummer
      </div>
      )}
      {location && (
      <div>
        <samp>#ADRESS</samp> = Er address (gatunamn, ort)
      </div>
      )}
      {location && (
      <div>
        <samp>#ADRESSLÅNG</samp> = Er fullständiga adress (inkl postnr och land)
      </div>
      )}
      {customer && (
      <div>
        <samp>#KUNDNAMN</samp> = Kundens namn
      </div>
      )}
      {company && (
      <div>
        <samp>#KUNDORGNR</samp> = Kundens org.nr
      </div>
      )}
      {company && (
      <div>
        <samp>#KUNDORGNAMN</samp> = Kundens företagsnamn
      </div>
      )}
      {vehicle && (
      <div>
        <samp>#FORDONREG</samp> = Fordonets reg.nr
      </div>
      )}
      {bookingLink && (
      <div>
        <samp>#BOKNINGSLÄNK</samp> = Länk för att visa/avboka bokning
      </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    features: getFeatures(state)
  };
};

export default connect(mapStateToProps)(TagsInfo);
