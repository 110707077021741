import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import uniqBy from 'lodash/uniqBy';
import isEqual from 'lodash/isEqual';
import { useDispatch } from 'react-redux';

import { navigate, getCustomersUrl } from '@Utils/navigate';
import { customerClearForm } from '@State/customer-actions';
import { classes } from '@Components/ui/utils';
import { formatPhoneNumber } from '@Utils/phone-util';
import { txt } from '@Utils/i18n-util';
import msg from './customers-list.msg';

const CustomersList = ({ routeParams, selectedCustomerId, customerEntries }) => {
  const dispatch = useDispatch();

  const onSelect = useCallback((customerId) => {
    if (+routeParams.id !== +customerId) {
      dispatch(customerClearForm());
      navigate(getCustomersUrl(routeParams, customerId, routeParams.tab));
    }
  }, [routeParams.id, routeParams.tab]);

  return (
    <>
      <div className="columns-list">
        {customerEntries && customerEntries.size > 0
          ? (
            <>
              {uniqBy(customerEntries.toJS(), 'customerId').map(
                ({
                  phoneNumber, otherPhoneNumber, email, name, customerId
                }) => {
                  const isSelectedItem = selectedCustomerId && +selectedCustomerId === +customerId;
                  const onSelectItem = () => onSelect(customerId);
                  const classList = classes({
                    'columns-list-item': true,
                    selected: isSelectedItem
                  });
                  return (
                    <div className={classList} onClick={onSelectItem} key={customerId}>
                      <div className="list-item-header no-wrap">{name}</div>
                      {(phoneNumber || otherPhoneNumber || email) && (
                      <div className="list-item-subtext">
                        {phoneNumber && formatPhoneNumber(phoneNumber)}
                        {phoneNumber && otherPhoneNumber && '/'}
                        {otherPhoneNumber && formatPhoneNumber(otherPhoneNumber)}
                        {(phoneNumber || otherPhoneNumber) && <br />}
                        {email}
                      </div>
                      )}
                    </div>
                  );
                }
              )}
            </>
          )
          : (
            <div>
              <div className="columns-list-help">
                <em>{txt(msg.txtNoCustomers)}</em>
              </div>
            </div>
          )}
      </div>
    </>
  );
};

CustomersList.propTypes = {
  onSelect: PropTypes.func
};

export default memo(
  CustomersList,
  (prevProps, nextProps) => !!isEqual(prevProps, nextProps)
);
