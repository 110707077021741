import React from 'react';
import ModalDialog from '@Components/dialogs/modal-dialog';
import { DialogContent, DialogTitle } from '@Components/dialogs/dialog-styles';

const DialogPosNotAvailable = ({
  onClose, title, text, subText
}) => (
  <ModalDialog
    onClose={onClose}
    buttons={[{
      name: 'OK',
      primary: true,
      onClick: onClose
    }]}
  >
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <p>{text}</p>
      <p>{subText}</p>
    </DialogContent>
  </ModalDialog>
);

export default DialogPosNotAvailable;
