import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { calendar } from '@Utils/preference-keys';
import { getScheduleBlockSelector, getGridProps } from './grid-selectors';
import { blockClosed } from './grid-util';

const backgroundColor = (renderClosed, scheduleEditMode, openType) => {
  if (renderClosed) {
    return 'rgba(255, 255, 255, 0.6)';
  }
  if (!scheduleEditMode) {
    return '#fff';
  }
  switch (openType) {
    case 'Overtime':
      return 'rgb(255, 230, 230)';
    case 'Normal':
      return 'rgb(225, 240, 255)';
    default:
      return '#fff';
  }
};

const style = (block, renderClosed, scheduleEditMode) => {
  return {
    width: `${block.widthPct}%`,
    left: `${block.leftPosPct}%`,
    top: block.topPx,
    height: block.heightPx,
    pointerEvents: 'none',
    position: 'absolute',
    background: backgroundColor(renderClosed, scheduleEditMode, block.openType),
    zIndex: 3
  };
};

const ScheduleBlock = ({ props, block }) => {
  const { darkenClosedWebBookingDays, scheduleEditMode } = props;
  const { day } = block;
  const dayPast = moment(day).isBefore(moment(), 'day');
  const closed = blockClosed(block);
  const renderClosed = darkenClosedWebBookingDays && (dayPast || closed);
  return (
    <div
      style={style(block, renderClosed, scheduleEditMode)}
      className="block"
    >
      {block.comment && scheduleEditMode && (
        <div className="block-label">{block.comment}</div>
      )}
    </div>
  );
};

class ColumnScheduleBlocks extends Component {
  static propTypes = {
    scheduleBlocks: PropTypes.array.isRequired,
    scheduleEditMode: PropTypes.bool.isRequired,
    highContrast: PropTypes.bool.isRequired
  };

  render() {
    const { scheduleBlocks } = this.props;

    return (
      <div>
        {scheduleBlocks.map((block, i) => (
          <ScheduleBlock
            props={this.props}
            block={block}
            id={`block${i}`}
            key={`block${i}`}
          />
        ))}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { gridViewState, locationConfig } = state;
  const { pixelsPerRow, columnWidth } = getGridProps(state, ownProps);

  try {
    const scheduleBlocks = getScheduleBlockSelector(state, ownProps);
    const darkenClosedWebBookingDays = locationConfig.get(calendar.darkenClosedWebBookingDays);

    return {
      scheduleBlocks,
      scheduleEditMode: gridViewState.get('scheduleEditMode'),
      highContrast: gridViewState.get('highContrast'),
      rowsPerHour: gridViewState.get('rowsPerHour'),
      pixelsPerRow,
      columnWidth,
      darkenClosedWebBookingDays
    };
  } catch (e) {
    console.log(e);
  }
};

export default connect(mapStateToProps)(ColumnScheduleBlocks);
