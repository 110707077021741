import React, { useState } from 'react';
import moment from 'moment';
import { pos } from '@Utils/preference-keys';
import ModalDialog from '@Components/dialogs/modal-dialog';
import DialogAlert from '@Components/dialogs/dialog-alert';
import AlertWithIcon from '@Components/ui/alert-with-icon';
import GiftCardForm from './gift-card-form';

const GiftCardModal = ({ posOrgPrefs, onSubmit, onClose }) => {
  const [saved, setSaved] = useState(0);
  const [error, setError] = useState(null);

  const defaultValidity = moment.duration(posOrgPrefs[pos.defaultVoucherValidity]);
  const defaultValues = {
    description: 'Presentkort',
    accountingType: posOrgPrefs[pos.defaultVoucherType],
    vatPct: posOrgPrefs[pos.defaultSinglePurposeVoucherVatPct],
    soldDate: moment().startOf('d').format('YYYY-MM-DD'),
    noRedeemAfterDate: moment().startOf('d').add(defaultValidity).format('YYYY-MM-DD')
  };

  const initialValues = {
    giftCardRows: [defaultValues]
  };

  const handleSubmit = ({ giftCardRows }) => {
    setError(null);
    return onSubmit(giftCardRows)
      .then(() => setSaved(giftCardRows.length))
      .catch(error => setError(error));
  };

  if (saved > 0) {
    return (
      <DialogAlert
        success
        icon="fa fa-check-circle"
        text={`${saved} presentkort importerades`}
        onClose={onClose}
      />
    );
  }

  return (
    <ModalDialog
      title="Importera presentkort"
      closeButtonText="Stäng"
      contentSize="full"
      closeOnClickOutside={false}
      onClose={onClose}
    >
      <AlertWithIcon icon="fa fa-info-circle">
        Använd detta formulär för att importera tidigare sålda presentkort.
      </AlertWithIcon>
      {error && (
        <AlertWithIcon warning icon="fa fa-exclamation-circle">
          Kunde inte importera presentkort. Kontrollera uppgifterna och försök igen.
        </AlertWithIcon>
      )}
      <GiftCardForm
        onSubmit={handleSubmit}
        initialValues={initialValues}
        defaultValues={defaultValues}
        onClose={onClose}
      />
    </ModalDialog>
  );
};

export default GiftCardModal;
