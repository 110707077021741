import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import LabelSwitchInput from '@Components/ui/label-switch-input';
import { PanelWrapper } from '@Components/customers/customer-styles';
import { toggleBooking, toggleSmsReminders, toggleAllowMarketing, toggleReviewReminders } from '@State/customer-actions';
import { txt } from '@Utils/i18n-util';
import msg from './customer-detail-control.msg';

const CustomerDetailControl = ({ selectedCustomer = {} }) => {
  const dispatch = useDispatch();

  const {
    id, smsReminders, allowMarketing, reviewReminders, blockWebBooking
  } = selectedCustomer;

  const handleChangeBlockWebBooking = ({ target: { checked } }) => {
    return dispatch(toggleBooking(id, checked));
  };
  const handleChangeSmsReminders = ({ target: { checked } }) => {
    return dispatch(toggleSmsReminders(id, checked));
  };
  const handleChangeAllowMarketing = ({ target: { checked } }) => {
    return dispatch(toggleAllowMarketing(id, checked));
  };
  const handleChangeReviewReminders = ({ target: { checked } }) => {
    return dispatch(toggleReviewReminders(id, checked));
  };

  return (
    <>
      <PanelWrapper smallPadding defaultCursor>
        <LabelSwitchInput
          noBorder
          label={txt(msg.lblSmsReminders)}
          id="smsReminders"
          isOn={smsReminders}
          handleToggle={handleChangeSmsReminders}
        />
      </PanelWrapper>
      <PanelWrapper smallPadding defaultCursor>
        <LabelSwitchInput
          noBorder
          label={txt(msg.lblAllowMarketing)}
          id="allowMarketing"
          isOn={allowMarketing}
          handleToggle={handleChangeAllowMarketing}
        />
      </PanelWrapper>
      <PanelWrapper smallPadding defaultCursor>
        <LabelSwitchInput
          noBorder
          label={txt(msg.lblReviewReminders)}
          id="reviewReminders"
          isOn={reviewReminders}
          handleToggle={handleChangeReviewReminders}
        />
      </PanelWrapper>
      <PanelWrapper smallPadding defaultCursor>
        <LabelSwitchInput
          noBorder
          label={txt(msg.lblBlockWebBooking)}
          id="blockWebBooking"
          isOn={blockWebBooking}
          handleToggle={handleChangeBlockWebBooking}
        />
      </PanelWrapper>
    </>
  );
};

export default CustomerDetailControl;
