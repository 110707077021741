import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getShowMainNav } from '@Utils/embedded-util';
import { staffJournalStatus } from '@State/view-actions';
import CustomerFeedbackBanner from '@Components/dialogs/customer-feedback-banner';
import Sections from './sections';

const Nav = ({ isSysadmin, showMainNav, fetchConfig, routeParams }) => {
  const navClasses = isSysadmin ? 'main-nav sysadmin' : 'main-nav';

  useEffect(() => {
    !isSysadmin && showMainNav && fetchConfig();
  }, []);

  return showMainNav ? (
    <nav className={navClasses}>
      <div className="main-nav-logo">
        <img src="/cliento-white.svg" />
      </div>
      <Sections routeParams={routeParams} isSysadmin={isSysadmin} />
      <CustomerFeedbackBanner />
    </nav>
  ) : null;
};

const mapStateToProps = (state, { match }) => {
  const isSysadmin = match.url.startsWith('/sysadmin');

  return {
    isSysadmin,
    showMainNav: getShowMainNav(state),
    routeParams: match.params
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchConfig: () => {
    dispatch(staffJournalStatus());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Nav);
