export function isPrivateMode() {
  try {
    const key = 'localStorageTest';
    localStorage.setItem(key, true);
    localStorage.removeItem(key);
    return false;
  } catch (error) {
    return true;
  }
}

export function isMobile() {
  const ua = navigator.userAgent.toLowerCase(),
    platform = navigator.platform.toLowerCase(),
    platformName = ua.match(/ip(?:ad|od|hone)/) ? 'ios' : (ua.match(/(?:webos|android)/) || platform.match(/mac|win|linux/) || ['other'])[0];
  return /ios|android|webos/.test(platformName);
}

function isSafari() {
  return /Safari/.test(navigator.userAgent) && !window.MSStream;
}

function isiOSDevice() {
  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
}

export function getPrivateModeUrl() {
  if (isSafari()) {
    if (isiOSDevice()) {
      return 'https://support.apple.com/sv-se/HT203036';
    }

    return 'https://support.apple.com/kb/PH21413?viewlocale=sv_SE&locale=sv_SE';
  }
  return null;
}
