import React from 'react';
import { useSelector } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import FormButtonsFooter from '@Components/ui/form-buttons-footer';
import { createValidator, maxValue, minValue, required } from '@Utils/validation';
import TextInput from '@Components/inputs/text-input';
import SelectField from '@Components/inputs/select-field';
import DatePickerField from '@Components/ui/date-picker/date-picker-field';

const formSelector = formValueSelector('voucher-form');
const voucherCode = value => value && value.toUpperCase().replace(/[^\w\d]/, '');

const VoucherForm = ({
  voucher, submitting, submitSucceeded, handleSubmit, onClose
}) => {
  const isPercentage = useSelector(state => formSelector(state, 'discountType') === 'Percentage');

  return (
    <form autoComplete="off" className="dialog" onSubmit={handleSubmit}>
      <Field
        type="text"
        name="code"
        label="Rabattkod"
        autoFocus={!voucher}
        disabled={!!voucher}
        component={TextInput}
        normalize={voucherCode}
      />

      <div className="row">
        <div className="col-xs-6">
          <Field name="noRedeemBefore" label="Giltig från" component={DatePickerField} />
        </div>
        <div className="col-xs-6">
          <Field name="noRedeemAfter" label="Giltig till" component={DatePickerField} />
        </div>
      </div>

      <div className="row">
        <div className="col-xs-6">
          <Field name="discountType" label="Typ av rabatt" component={SelectField}>
            <option value="Amount">Belopp</option>
            <option value="Percentage">Procent</option>
          </Field>
        </div>
        <div className="col-xs-6">
          {isPercentage ? (
            <Field type="number" name="pctValue" label="Värde (%)" component={TextInput} />
          ) : (
            <Field type="number" name="amountValue" label="Värde (kr)" component={TextInput} />
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-xs-6">
          <Field type="number" name="minimumSpend" label="Minsta belopp (kr)" component={TextInput} />
        </div>
        <div className="col-xs-6">
          <Field type="number" name="maxRedeems" label="Max antal användningar" component={TextInput} />
        </div>
      </div>

      {isPercentage && (
        <div className="row">
          <div className="col-xs-6">
            <Field type="number" name="maxRebate" label="Maximal rabatt (kr)" component={TextInput} />
          </div>
        </div>
      )}

      <FormButtonsFooter
        onClose={onClose}
        submitting={submitting}
        submitSucceeded={submitSucceeded}
        handleSubmit={handleSubmit}
        submitText={voucher ? 'Spara rabattkod' : 'Lägg till rabattkod'}
        cancelText="Stäng"
      />
    </form>
  );
};

const validator = createValidator({
  code: [required],
  noRedeemBefore: [required],
  noRedeemAfter: [required],
  discountType: [required],
  pctValue: [required, minValue(1), maxValue(100)],
  amountValue: [required, minValue(1)],
  minimumSpend: [required],
  maxRebate: [required]
});

export default reduxForm({
  form: 'voucher-form',
  validate: validator
})(VoucherForm);
