import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { destroy } from 'redux-form';
import PropTypes from 'prop-types';
import { config } from '@Utils/preference-keys';
import { getVatRegNo } from '@Utils/i18n-util';

import CreatePosOrgCompany from '@Components/admin/pos-config/create-pos-org-company';
import CreatePosOrgAddress from '@Components/admin/pos-config/create-pos-org-address';
import CreatePosOrgOwner from '@Components/admin/pos-config/create-pos-org-owner';
import CreatePosOrgConfirm from '@Components/admin/pos-config/create-pos-org-confirm';

const CreatePosOrg = ({ onCreatePosOrg, onHideDialogs }) => {
  const [isCheckedAddress, setIsCheckedAddress] = useState(false);
  const [showDialog, setShowDialog] = useState('Company');
  const dispatch = useDispatch();

  const region = useSelector(state => state.locationConfig.get(config.region));

  useEffect(() => {
    return () => {
      dispatch(destroy('create-pos-org'));
    };
  }, []);

  const hide = () => {
    onHideDialogs();
    setShowDialog('');
  };

  const onShowCompany = () => setShowDialog('Company');
  const onShowOwner = () => setShowDialog('Owner');
  const onShowConfirm = () => setShowDialog('Confirm');
  const onShowAddress = () => setShowDialog('Address');

  const onPrevOwner = () => isCheckedAddress ? onShowAddress() : onShowCompany();
  const onPrevCompany = () => isCheckedAddress ? onShowAddress() : onShowOwner();

  const onSubmit = ({
    companyOrgNo, companyAddressLine1, companyPostCode, companyArea,
    posAddressLine1, posPostCode, posArea, ...values
  }) => {
    const vatRegNo = getVatRegNo(companyOrgNo, region);

    const companyAddress = {
      addressLine1: companyAddressLine1,
      country: region,
      postCode: companyPostCode,
      area: companyArea
    };

    const posAddress = posAddressLine1 ? {
      addressLine1: posAddressLine1,
      country: region,
      postCode: posPostCode,
      area: posArea
    } : null;

    return onCreatePosOrg({
      ...values, companyAddress, posAddress, vatRegNo, companyOrgNo
    });
  };

  switch (showDialog) {
    case 'Company':
      return (
        <CreatePosOrgCompany
          region={region}
          setIsCheckedAddress={setIsCheckedAddress}
          isCheckedAddress={isCheckedAddress}
          onNext={onPrevCompany}
          onHide={hide}
        />
      );
    case 'Address':
      return <CreatePosOrgAddress onPrev={onShowCompany} onNext={onShowOwner} onHide={hide} />;
    case 'Owner':
      return <CreatePosOrgOwner onPrev={onPrevOwner} onNext={onShowConfirm} onHide={hide} />;
    case 'Confirm':
      return <CreatePosOrgConfirm onSubmit={onSubmit} onPrev={onShowOwner} onHide={hide} />;
    default: return null;
  }
};

CreatePosOrg.propTypes = {
  onCreatePosOrg: PropTypes.func,
  onHideDialogs: PropTypes.func
};

export default CreatePosOrg;
