import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getGridProps } from './grid-selectors';

export class ColumnRulers extends Component {
  static propTypes = {
    columnCount: PropTypes.number.isRequired,
    pixelsPerRow: PropTypes.number.isRequired,
    rowsPerHour: PropTypes.number.isRequired
  };

  render() {
    return (
      <div>
        {this.renderRulers()}
      </div>
    );
  }

  renderRulers() {
    const { pixelsPerRow, rowsPerHour, columnCount } = this.props;
    const gridHeight = pixelsPerRow * rowsPerHour * 24;
    const widthPct = 100 / columnCount;

    const rows = [];

    for (let i = 0; i < columnCount; i++) {
      rows.push(
        <div
          id={`cr${i}`}
          key={`cr${i}`}
          style={{ left: `${i * widthPct}%`, height: gridHeight, position: 'absolute' }}
          className="vrule gutter column-item"
        />
      );
    }
    return rows;
  }
}

const mapStateToProps = (state, ownProps) => {
  const { pixelsPerRow, rowsPerHour, columnCount } = getGridProps(state, ownProps);

  return {
    columnCount,
    pixelsPerRow,
    rowsPerHour
  };
};

export default connect(
  mapStateToProps
)(ColumnRulers);
