import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getOrganisationsUrl } from '@Utils/navigate';
import { isEmbeddedInApp } from '@Utils/embedded-util';

class AccountInfoAlert extends Component {
  render() {
    const { trialStatus, accountStatus } = this.props;

    if (trialStatus === 'TrialExpired') {
      return this.renderAccountBlocked('Din gratis provperiod är slut');
    } if (accountStatus === 'BlockedManual' || accountStatus === 'BlockedPaymentRequired') {
      return this.renderAccountBlocked('Ditt konto är låst');
    }
    return null;
  }

  renderAccountBlocked(title) {
    const { embeddedInApp } = this.props;

    return (
      <div className="dialog-container">
        <div className="dialog-content">
          <h2>{title}</h2>
          <hr />
          <p>För att fortsätta använda Cliento behöver du ange ett kreditkort så vi kan debitera dig för ditt användande.</p>

          {embeddedInApp ? (
            <p>Logga in i Cliento via webbläsaren på en dator, surfplatta eller din telefon för mer information, eller kontakta oss om du har några frågor.</p>
          ) : (
            <>
              <p>
                Gå till <a href={getOrganisationsUrl(this.props.match.params)}>Abonnemang</a> för mer information om våra priser, eller kontakta oss om du har några frågor.
              </p>
              <hr />
              <a href={getOrganisationsUrl(this.props.match.params)} className="button arrow">Gå till Abonnemang</a>
            </>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    trialStatus: state.appState.get('trialStatus'),
    accountStatus: state.appState.get('accountStatus'),
    embeddedInApp: isEmbeddedInApp(state)
  };
};

export default connect(mapStateToProps)(AccountInfoAlert);
