import React, { useState } from 'react';
import moment from 'moment';

import ModalDialog from '@Components/dialogs/modal-dialog';
import FormButtonsFooter from '@Components/ui/form-buttons-footer';

function getNextDateToSync(syncStatus) {
  const {
    syncTransactionsFromDate, latestSyncedTransactionDate
  } = syncStatus;

  if (latestSyncedTransactionDate) {
    return moment(latestSyncedTransactionDate).add(1, 'day');
  }
  return moment(syncTransactionsFromDate);
}

const PosOrgFortnoxSyncModal = ({ onClose, onRunSync, syncStatus }) => {
  const [allDays, setAllDays] = useState(false);
  const [exporting, setExporting] = useState(false);

  const nextDateToSync = getNextDateToSync(syncStatus);
  const lastDateToSync = allDays
    ? moment().subtract(1, 'day').startOf('D')
    : nextDateToSync;

  const handleAllDays = (ev) => {
    setAllDays(ev.target.checked);
  };

  const handleSubmit = (ev) => {
    ev?.preventDefault();
    setExporting(true);
    const start = nextDateToSync.format('YYYY-MM-DD');
    const end = lastDateToSync.format('YYYY-MM-DD');
    onRunSync(start, end).catch(() => setExporting(false));
  };

  return (
    <ModalDialog
      title="Skicka bokföring till Fortnox"
      closeOnClickOutside={false}
      onClose={onClose}
      hideCloseButton
    >
      <form onSubmit={handleSubmit}>
        Datum att bokföra: <strong>{nextDateToSync.format('LL')}</strong><br />
        Sista datum att bokföra: <strong>{allDays ? lastDateToSync.format('LL') : '-'}</strong><br />
        <div className="checkbox">
          <label>
            <input type="checkbox" checked={allDays} onChange={handleAllDays} />
            Skicka alla dagar fram till idag
          </label>
        </div>
        <FormButtonsFooter
          alwaysEnabled
          onClose={onClose}
          submitting={exporting}
          handleSubmit={handleSubmit}
          submitText="Skicka bokföring"
        />
      </form>
    </ModalDialog>
  );
};

export default PosOrgFortnoxSyncModal;
