import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toggleScheduleEditMode } from '@State/view-actions';
import { getIsMultiResourceView, getResourcesInView } from '@State/calendar-selectors';
import TB from '@Components/ui/tb-button';
import { txt } from '@Utils/i18n-util';
import msg from './schedule-toggle.msg';

class ScheduleToggle extends Component {
  render() {
    const { scheduleEditMode, phoneMode, isMultiResourceView, resourcesInView } = this.props;
    const disabled = isMultiResourceView || resourcesInView.length === 0;

    return (
      <TB
        label={!phoneMode && txt(msg.btnLabel)}
        disabled={disabled}
        icon="fa fa-calendar-alt"
        onClick={this.handleClick}
        active={scheduleEditMode}
        phoneMode={phoneMode}
      />
    );
  }

  handleClick = (ev) => {
    this.props.toggleScheduleEditMode(!this.props.scheduleEditMode);
    ev.currentTarget.blur();
  };
}

ScheduleToggle.propTypes = {
  scheduleEditMode: PropTypes.bool.isRequired,
  toggleScheduleEditMode: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => {
  return {
    resourcesInView: getResourcesInView(state, ownProps),
    isMultiResourceView: getIsMultiResourceView(state, ownProps),
    scheduleEditMode: state.gridViewState.get('scheduleEditMode'),
    phoneMode: state.mainViewState.get('phoneMode')
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleScheduleEditMode: (state) => {
      dispatch(toggleScheduleEditMode(state));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleToggle);
