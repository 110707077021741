import moment from 'moment';
import React, { Component } from 'react';
import { getSectionUrl, navigate } from '@Utils/navigate';
import ColumnListItem from '@Components/ui/column/column-list-item';

class StockTakingList extends Component {
  onItemClick = item => {
    navigate(getSectionUrl(this.props.routeParams, 'admin/stock-taking', item.id));
  };

  render() {
    const { routeParams } = this.props;
    const currentId = parseInt(routeParams.id);

    return (
      <>
        <div className="columns-header">
          <h1>Inventering</h1>
        </div>
        <div className="columns-list">

          {this.props.stockTakes.valueSeq()
            .map(stockTake => {
              const { id, label, itemCount, finishedTs } = stockTake;

              return (
                <div key={id}>
                  <ColumnListItem
                    onClick={() => this.onItemClick(stockTake)}
                    selected={id === currentId}
                    header={label}
                    subHeader={`${itemCount || 0} artiklar`}
                  >
                    {finishedTs ? (
                      <>
                        <i className="far fa-circle-check text-success" />
                        {moment(finishedTs).format('LL')}
                      </>
                    ) : 'Pågående'}
                  </ColumnListItem>
                </div>
              );
            })}
        </div>
      </>
    );
  }
}

export default StockTakingList;
