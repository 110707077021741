import { createSelector } from 'reselect';
import { accounting, pos } from '@Utils/preference-keys';

function getPosPrefs(posObj, keys) {
  const map = {};
  keys.forEach(key => {
    map[key] = posObj.getIn(['prefs', key]);
  });
  return map;
}

export const getPosOrgSwishPrefs = createSelector(
  (state, props) => props.posOrg,
  (posOrg) => {
    if (!posOrg) {
      return null;
    }
    return getPosPrefs(posOrg, [
      pos.swishPayeeAlias,
      pos.swishCertId,
      pos.useSwishIntegration
    ]);
  }
);

export const getPosOrgKlarnaPrefs = createSelector(
  (state, props) => props.posOrg,
  (posOrg) => {
    if (!posOrg) {
      return null;
    }
    return getPosPrefs(posOrg, [
      pos.klarnaUsername,
      pos.klarnaPassword
    ]);
  }
);

export const getPosOrgGiftCardPrefs = createSelector(
  (state, props) => props.posOrg,
  (posOrg) => {
    if (!posOrg) {
      return null;
    }
    return getPosPrefs(posOrg, [
      pos.defaultVoucherType,
      pos.defaultSinglePurposeVoucherVatPct,
      pos.defaultVoucherValidity
    ]);
  }
);

export const getPosOrgPaymentMethodOtherPrefs = createSelector(
  (state, props) => props.posOrg,
  (state, props) => props.number,
  (posOrg, number) => {
    if (!posOrg) {
      return null;
    }
    const prefix = pos.paymentMethodOther + number;
    return {
      [`${prefix}Name`]: posOrg.getIn(['prefs', `${prefix}Name`])
    };
  }
);

export const getPosOrgInvoicePrefs = createSelector(
  (state, props) => props.posOrg,
  (posOrg) => {
    if (!posOrg) {
      return null;
    }
    return getPosPrefs(posOrg, [
      pos.invoiceBGNumber,
      pos.invoicePGNumber,
      pos.invoiceDueDays,
      pos.latePaymentInterest,
      pos.useCustomerInvoiceRef,
      pos.invoiceUseWindowedEnvelope,
      pos.invoiceCopyToBillingContact,
      pos.invoiceUseFortnox,
      pos.invoiceFortnoxService,
      pos.invoiceFortnoxBookAndSend,
      pos.invoiceEmailSubject,
      pos.invoiceEmailBody
    ]);
  }
);

export const getPosOrgAccountingPrefs = createSelector(
  (state, props) => props.posOrg,
  (posOrg) => {
    if (!posOrg) {
      return null;
    }
    const prefs = {};
    Object.values(accounting).forEach((value) => {
      prefs[value] = posOrg.getIn(['prefs', value]);
    });
    return prefs;
  }
);

export const getPosUnitReceiptPrefs = (posUnit) => {
  return getPosPrefs(posUnit, [
    'addressLine1',
    'addressPostCode',
    'addressArea',
    'companyWebAddress',
    'companyEmail',
    'companyPhoneNo',
    'receiptMessage',
    pos.showCashierNameOnReceipt,
    pos.showCustomerNameOnReceipt,
    pos.showCustomerPnoOnReceipt,
    pos.showProductBrandOnReceipt,
    pos.showVoucherCodeOnReceipt,
    pos.showVoucherCodeOnRedeemReceipt,
    pos.showVoucherBalanceOnReceipt,
    pos.showUpcomingBookingsOnReceipt
  ]);
};

export const getOnpremPosUnits = createSelector(
  state => state.posUnits,
  (posUnits) => {
    return posUnits.filter(u => u.get('type').includes('Onprem'));
  }
);

export const getAllEcomPosUnits = createSelector(
  state => state.allPosUnits,
  (posUnits) => {
    return posUnits.filter(u => u.get('type') === 'Ecom');
  }
);
