import React, { useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import DateRangePicker from '@Components/reports/date-range-picker';
import { downloadCustomerReportExcel, onDataReportValuesChange } from '@State/report-actions';
import { getDataExportFilter, getDataExportList, ReportingType, sortDataExportGroups } from '@Components/reports/reports-helpers';
import Button from '@Components/ui/button';
import { DATE_PICKER_FORMAT } from '@Utils/time-constants';
import { txt } from '@Utils/i18n-util';
import CustomerPreview from '@Components/reports/customer/customer-preview';
import ConfirmCsvDownload from '@Components/reports/data-export/confirm-csv-download';
import ModalSelector from '@Components/reports/data-export/data-export-modal-selector';
import msg from '@Components/reports/data-export/data-export.msg';

const CustomerReport = () => {
  const dispatch = useDispatch();
  const showTopSpenders = useSelector(state => state.locationConfig.get('vunits') > 0);
  const reportTypes = [
    ReportingType.New,
    showTopSpenders ? ReportingType.Top : null
  ].filter(t => t);

  const {
    resourceIds,
    serviceIds,
    type,
    start,
    end
  } = useSelector(getDataExportFilter);
  const {
    resourceGroupsList,
    resourcesList,
    serviceGroupsList,
    servicesList
  } = useSelector(getDataExportList);

  const [loading, setLoading] = useState(false);
  const [preview, setPreview] = useState(false);
  const [fileType, setFileType] = useState('Excel');
  const [excelError, setExcelError] = useState(false);

  const handleDatesChange = useCallback(({ startDate, endDate }) => {
    dispatch(onDataReportValuesChange({
      start: startDate.format(DATE_PICKER_FORMAT),
      end: endDate.format(DATE_PICKER_FORMAT)
    }));
  }, []);

  const handleValuesChange = useCallback((key, values) => {
    dispatch(onDataReportValuesChange({ [key]: values }));
  }, []);

  const onPreviewReport = useCallback(() => {
    setPreview(true);
  }, [type]);

  const onDownloadReport = useCallback((ev, overrideFileType) => {
    setLoading(true);
    dispatch(downloadCustomerReportExcel(overrideFileType || fileType))
      .then(() => setTimeout(() => {
        setLoading(false);
        setExcelError(false);
      }, 5000))
      .catch(() => {
        setExcelError(true);
        setLoading(false);
      });
  }, [fileType]);

  const onDownloadCsv = (ev) => {
    onDownloadReport(ev, 'CSV');
  };

  return (
    <>
      {preview && <CustomerPreview onClose={() => setPreview(false)} />}

      <div className="form-group">
        <label className="control-label">{txt(msg.dateInterval)}</label>
        <div>
          <DateRangePicker
            startDate={moment(start)}
            endDate={moment(end)}
            onDatesChange={handleDatesChange}
          />
        </div>
      </div>

      <div className="form-group" style={{ maxWidth: '380px' }}>
        <label className="control-label">{txt(msg.customersType)}</label>
        <div className="select-container">
          <select
            className="form-control"
            onChange={(ev) => handleValuesChange('type', ev?.target?.value)}
            value={type}
          >
            {reportTypes.map(type => (
              <option
                key={type}
                value={type}
              >
                {txt(msg[`customersType${type}`])}
              </option>
            ))}
          </select>
        </div>
      </div>
      <ModalSelector
        title={txt(msg.resources)}
        items={resourcesList}
        values={resourceIds}
        groups={resourceGroupsList}
        sortGroups={sortDataExportGroups}
        groupKey="entityIds"
        onChange={(values) => handleValuesChange('resourceIds', values)}
        getItemTitle={(resource) => resource?.name ?? 'noname'}
        placeholder={txt(msg.selectResources)}
      />
      <ModalSelector
        title={txt(msg.services)}
        items={servicesList}
        values={serviceIds}
        groups={serviceGroupsList}
        sortGroups={sortDataExportGroups}
        groupKey="entityIds"
        onChange={(values) => handleValuesChange('serviceIds', values)}
        getItemTitle={(service) => service?.name ?? 'noname'}
        placeholder={txt(msg.selectServices)}
      />
      <div className="form-group">
        <label className="control-label">{txt(msg.fileFormat)}</label><br />
        <div className="radio-inline">
          <label>
            <input type="radio" checked={fileType === 'Excel'} onChange={() => setFileType('Excel')} />
            Excel
          </label>
        </div> &nbsp;
        <div className="radio-inline">
          <label>
            <input type="radio" checked={fileType === 'CSV'} onChange={() => setFileType('CSV')} />
            CSV
          </label>
        </div>
      </div>
      <div className="data-report-actions-row">
        <Button small gray onClick={onPreviewReport}>{txt(msg.preview)}</Button>
        <Button small primary onClick={onDownloadReport} loading={loading}>{txt(msg.download)}</Button>
      </div>
      {excelError && (
        <ConfirmCsvDownload
          loading={loading}
          onDownloadCsv={onDownloadCsv}
          onClose={() => setExcelError(false)}
        />
      )}
    </>
  );
};

export default CustomerReport;
