import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleOwnedByLocation, toggleAssociatedToResource } from '@State/customer-actions';
import LabelSwitchInput from '@Components/ui/label-switch-input';
import { PanelWrapper } from '@Components/customers/customer-styles';
import { txt } from '@Utils/i18n-util';
import msg from './customer-detail-owner.msg';

const CustomerDetailOwner = ({ selectedCustomer }) => {
  const dispatch = useDispatch();

  const {
    id: customerId, associatedResourceId: resourceId, ownedByLocation
  } = selectedCustomer || {};

  const resourcesById = useSelector(state => state.resourcesById);
  const [associatedToResource, setAssociatedToResource] = useState(!!resourceId);

  useEffect(() => {
    setAssociatedToResource(!!resourceId);
  }, [resourceId]);

  const handleToggleOwnedByLocation = ({ target: { checked } }) => {
    return dispatch(toggleOwnedByLocation(customerId, checked));
  };
  const handleToggleAssociatedToResource = ({ target: { checked } }) => {
    if (!checked) {
      setAssociatedToResource(false);
      return dispatch(toggleAssociatedToResource(customerId, null));
    }
    setAssociatedToResource(true);
  };
  const handleChangeResource = ({ target: { value } }) => {
    return dispatch(toggleAssociatedToResource(customerId, parseInt(value)));
  };

  return (
    <>
      <PanelWrapper smallPadding>
        <LabelSwitchInput
          noBorder
          label={txt(msg.lblOwnedByLocation)}
          id="ownedByLocation"
          isOn={ownedByLocation}
          handleToggle={handleToggleOwnedByLocation}
          disabled={associatedToResource}
        />
      </PanelWrapper>
      <PanelWrapper smallPadding>
        <LabelSwitchInput
          noBorder
          label={txt(msg.lblAssociatedToResource)}
          id="associatedToResource"
          isOn={associatedToResource}
          handleToggle={handleToggleAssociatedToResource}
          disabled={ownedByLocation}
        />
        {associatedToResource && (
          <div className="form-group">
            <p />
            <div className="select-container">
              <select className="form-control" value={resourceId || '-1'} onChange={handleChangeResource}>
                {!resourceId && <option value="-1" disabled>{txt(msg.lblSelectResource)}</option>}
                {resourcesById && resourcesById.valueSeq().map(({ id, name }) => (
                  <option key={id} value={id}>{name}</option>
                ))}
              </select>
            </div>
          </div>
        )}
      </PanelWrapper>
    </>
  );
};

export default CustomerDetailOwner;
