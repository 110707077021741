import { defineMessages } from 'react-intl';

export default defineMessages({
  day: {
    id: 'calendar.viewModeSwitcher.day',
    defaultMessage: 'Day'
  },
  week: {
    id: 'calendar.viewModeSwitcher.week',
    defaultMessage: 'Week'
  },
  group: {
    id: 'calendar.viewModeSwitcher.group',
    defaultMessage: 'Group'
  }
});
