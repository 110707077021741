import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { deleteProductDelivery, fetchProductDeliveries, updateProductDelivery } from '@State/products-actions';
import CurrencyUtil from '@Utils/currency-util';
import ModalDialog from '@Components/dialogs/modal-dialog';
import Loader from '@Components/ui/loader';
import Panel from '@Components/ui/styled/panel';
import Row from '@Components/ui/styled/row';
import { FlexOne, ReportTextLabel } from '@Components/pos/style';
import Button from '@Components/ui/button';
import AlertWithIcon from '@Components/ui/alert-with-icon';
import ConfirmPopover from '@Components/ui/confirm-popover';
import EditProductDeliveryModal from './edit-product-delivery-modal';

const ProductDeliveriesModal = ({
  productId, name, latestPriceIn, fifoAvgPrice, stockQuantity, onClose
}) => {
  const dispatch = useDispatch();

  const [deliveries, setDeliveries] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const hasDeliveries = deliveries?.length > 0;

  useEffect(() => {
    if (productId) {
      fetchDeliveries();
    }
  }, [productId]);

  const fetchDeliveries = () => {
    return dispatch(fetchProductDeliveries(productId))
      .then(({ rows }) => setDeliveries(rows))
      .finally(() => setLoading(false));
  };

  const onSaveDelivery = (delivery) => {
    return dispatch(updateProductDelivery(delivery))
      .then(() => fetchDeliveries())
      .then(() => setEditing(null));
  };

  const onDeleteDelivery = (delivery) => {
    return dispatch(deleteProductDelivery(delivery))
      .then(() => fetchDeliveries())
      .then(() => setConfirmDelete(false));
  };

  return (
    <ModalDialog
      contentSize="xl"
      onClose={onClose}
      title={`Korrigera ${name}`}
      closeButtonText="Stäng"
      closeOnClickOutside={false}
      focusTrapPaused={confirmDelete}
    >
      {loading && <Loader />}
      {!loading && (
        <Panel>
          <Row>
            <FlexOne>
              <ReportTextLabel>
                <span>Lagersaldo</span>
                <p className="mb0">{stockQuantity || 0}</p>
              </ReportTextLabel>
            </FlexOne>
            <FlexOne>
              <ReportTextLabel>
                <span>Inköpspris</span>
                <p className="mb0">{latestPriceIn ? CurrencyUtil.accountCurrency(latestPriceIn) : '-'}</p>
              </ReportTextLabel>
            </FlexOne>
            <FlexOne>
              <ReportTextLabel>
                <span>Genomsnitt</span>
                <p className="mb0">{fifoAvgPrice ? CurrencyUtil.accountCurrency(fifoAvgPrice) : '-'}</p>
              </ReportTextLabel>
            </FlexOne>
          </Row>
        </Panel>
      )}
      {!loading && !hasDeliveries && (
        <AlertWithIcon warning icon="fa fa-info-circle">
          Det finns inga leveranser att korrigera
        </AlertWithIcon>
      )}
      {!loading && hasDeliveries && (
        <>
          <AlertWithIcon icon="fa fa-info-circle">
            Nedan visas samtliga inleveranser för {name}.
            Du kan ändra eller ta bort den senaste leveransen.
          </AlertWithIcon>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Datum</th>
                <th className="text-right">Lev. antal</th>
                <th className="text-right">Pris / st</th>
                <th className="text-right">Antal kvar</th>
                <th className="text-right" />
              </tr>
            </thead>
            <tbody>
              {deliveries.map((delivery, index) => {
                const { deliveryRowId, deliveryTs, price, deliveredQuantity, remainingQuantity, initial } = delivery;
                const isLastRow = index === deliveries.length - 1;
                return (
                  <tr key={deliveryRowId} className={editing ? 'disabled' : ''}>
                    <td>{moment(deliveryTs).format('lll')}</td>
                    <td className="text-right">{deliveredQuantity}</td>
                    <td className="text-right">{price ? CurrencyUtil.accountCurrency(price) : '-'}</td>
                    <td className="text-right">{remainingQuantity}</td>
                    <td className="text-right">
                      {isLastRow && (
                        <>
                          <Button tiny gray marginRight={!initial} onClick={() => setEditing(delivery)}>Ändra</Button>
                          {!initial && (
                            <ConfirmPopover
                              text="Bekräfta borttagning av leverans?"
                              confirmText="Ta bort"
                              onClose={() => setConfirmDelete(false)}
                              onConfirm={() => onDeleteDelivery(delivery)}
                              isOpen={confirmDelete}
                            >
                              <Button tiny gray onClick={() => setConfirmDelete(true)}>Ta bort</Button>
                            </ConfirmPopover>
                          )}
                        </>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      )}
      {editing && (
        <EditProductDeliveryModal
          initialValues={editing}
          onSubmit={onSaveDelivery}
          onClose={() => setEditing(null)}
        />
      )}
    </ModalDialog>
  );
};

export default ProductDeliveriesModal;
