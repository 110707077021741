import axios from 'axios';
import {
  axiosDefault, axiosErrorHandler, checkStatusAxios, prefixUrl
} from '@Utils/ajax-util';

export const VOUCHERS_FETCHED = 'VOUCHERS_FETCHED';
export const VOUCHER_CREATED = 'VOUCHER_CREATED';
export const VOUCHER_UPDATED = 'VOUCHER_UPDATED';

export function fetchVouchers() {
  return dispatch => {
    const url = prefixUrl('/voucher/discount-voucher/');
    const config = axiosDefault();

    return axios
      .get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then((vouchers) => dispatch({ type: VOUCHERS_FETCHED, vouchers }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function createVoucher(data) {
  return (dispatch) => {
    const url = prefixUrl('/voucher/discount-voucher/');
    const config = axiosDefault();

    return axios
      .post(url, data, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then((voucher) => {
        dispatch({ type: VOUCHER_CREATED, voucher });
        return voucher;
      })
      .catch(error => {
        axiosErrorHandler(error, dispatch);
        throw error;
      });
  };
}

export function updateVoucher(voucher) {
  return (dispatch) => {
    const url = prefixUrl(`/voucher/discount-voucher/${voucher.id}`);
    const config = axiosDefault();

    return axios
      .put(url, voucher, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => dispatch({ type: VOUCHER_UPDATED, voucher }))
      .catch(error => {
        axiosErrorHandler(error, dispatch);
        throw error;
      });
  };
}

export function voidVoucher(id) {
  return (dispatch) => {
    const url = prefixUrl(`/voucher/${id}/void`);
    const config = axiosDefault();
    const voucher = { id, voucherStatus: 'Void' };

    return axios
      .put(url, null, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => dispatch({ type: VOUCHER_UPDATED, voucher }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function validateVoucherCode(code) {
  return dispatch => {
    const url = prefixUrl(`/voucher/validate-code?code=${encodeURIComponent(code)}`);
    const config = axiosDefault();

    return axios
      .get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}
