import { defineMessages } from 'react-intl';

export default defineMessages({
  lblService: {
    id: 'calendar.bkf.print-modal.lblService',
    defaultMessage: 'Service'
  },
  lblResource: {
    id: 'calendar.bkf.print-modal.lblResource',
    defaultMessage: 'Resource'
  },
  lblCustomerDetails: {
    id: 'calendar.bkf.print-modal.lblCustomerDetails',
    defaultMessage: 'Customer details'
  },
  lblCompany: {
    id: 'calendar.bkf.print-modal.lblCompany',
    defaultMessage: 'Company'
  },
  lblVehicle: {
    id: 'calendar.bkf.print-modal.lblVehicle',
    defaultMessage: 'Vehicle'
  },
  lblName: {
    id: 'calendar.bkf.print-modal.lblName',
    defaultMessage: 'Name'
  },
  lblMobilePhone: {
    id: 'calendar.bkf.print-modal.lblMobilePhone',
    defaultMessage: 'Phone'
  },
  lblEmail: {
    id: 'calendar.bkf.print-modal.lblEmail',
    defaultMessage: 'Email'
  },
  lblOrgName: {
    id: 'calendar.bkf.print-modal.lblOrgName',
    defaultMessage: 'Company name'
  },
  lblContactPhone: {
    id: 'calendar.bkf.print-modal.lblContactPhone',
    defaultMessage: 'Phone'
  },
  lbNotes: {
    id: 'calendar.bkf.print-modal.lbNotes',
    defaultMessage: 'Notes'
  },
  btnClose: {
    id: 'calendar.bkf.print-modal.btnClose',
    defaultMessage: 'Close'
  },
  btnPrint: {
    id: 'calendar.bkf.print-modal.btnPrint',
    defaultMessage: 'Print'
  }
});
