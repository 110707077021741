import moment from 'moment';

const getProperties = decimals => ({ minimumFractionDigits: decimals, maximumFractionDigits: decimals });
export const numberFormat = (value, decimals = 2) => parseFloat(value || 0).toLocaleString('sv-SE', getProperties(decimals));

export const dateFormat = (value, format = 'LLL') => moment(value).format(format);

function showValue(value) {
  return value !== undefined && value !== null && value !== 0;
}

export function reportingFormat(value) {
  return showValue(value) ? numberFormat(value) : '-';
}

export function reportingCount(value) {
  return showValue(value) ? value : '-';
}

export function normalizeNumber(value) {
  const normalized = String(value).replace('e', '').replace(' ', '').replace(',', '.');
  return parseFloat(normalized);
}
