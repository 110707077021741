import React from 'react';
import { createSelector } from 'reselect';
import {
  CHANGE_LOCATION_SETTINGS, CHANGE_RESOURCES, hasPermission, SHOW_REPORTS
} from '@Utils/permissions';
import {
  getAccountStatus,
  getCurrentLocation,
  getFeatures,
  getPermissions,
  getStaffJournalStatus,
  getTrialStatus
} from '@State/selectors';
import { actionRequired } from '@Utils/account';
import { classes } from '@Components/ui/utils';
import { txt } from '@Utils/i18n-util';
import msg from '@Components/nav/nav-selectors.msg';

const styleAdmin = {
  position: 'absolute',
  right: '19px',
  top: '7px',
  height: '16px'
};

export const sysadminNavSections = [{
  id: 'accounts',
  name: 'Konton',
  icon: 'fas fa-id-card',
  search: false,
  org: 'sysadmin',
  loc: 'a'
}, {
  id: 'tools',
  name: 'Verktyg',
  icon: 'fas fa-wrench',
  search: false,
  org: 'sysadmin',
  loc: 'a'
}, {
  id: 'release-notes',
  name: 'Release notes',
  icon: 'fa-solid fa-bullhorn',
  search: true,
  org: 'sysadmin',
  loc: 'a'
}, {
  id: 'new-account',
  name: 'Nytt konto',
  icon: 'fa-solid fa-plus',
  search: true,
  org: 'sysadmin',
  loc: 'a'
}];

export const getNavSections = createSelector(
  getCurrentLocation,
  getPermissions,
  getTrialStatus,
  getAccountStatus,
  getFeatures,
  getStaffJournalStatus,
  (locationUser, permissions, trialStatus, accountStatus, locationFeatures, staffJournalStatus) => {
    const {
      ShowSmsCampaign, ShowReports, ShowSettings, ShowOngoing,
      ShowPayments, ShowPOS, EnablePOS, ShowStaffJournal
    } = locationFeatures;

    const sections = [{
      id: 'calendar',
      name: txt(msg.calendar),
      icon: 'far fa-calendar',
      search: true
    }];

    if (ShowOngoing) {
      sections.push({
        id: 'ongoing',
        name: txt(msg.ongoing),
        icon: 'far fa-circle-play'
      });
    }

    if (ShowPOS) {
      sections.push({
        id: 'pos',
        name: txt(msg.pos),
        icon: 'far fa-cash-register',
        search: false,
        news: !EnablePOS
      });
    }

    const getStaffClasses = (staffJournalStatus) => {
      return classes({
        'staff-journal-status-active': staffJournalStatus > 0,
        'staff-journal-status-active-smaller': staffJournalStatus > 9
      });
    };
    if (ShowStaffJournal) {
      sections.push({
        id: 'staff',
        name: txt(msg.staff),
        iconBody: (
          <div className="nav-item-staff">
            <i className="far fa-user">
              {staffJournalStatus ? (
                <span className={getStaffClasses(staffJournalStatus)}>
                  {staffJournalStatus}
                </span>
              ) : null}
            </i>
          </div>
        ),
        search: false
      });
    }

    sections.push({
      id: 'customers',
      name: txt(msg.customers),
      icon: 'far fa-address-book',
      search: false
    });

    if (ShowSmsCampaign) {
      sections.push({
        id: 'campaign',
        name: txt(msg.campaign),
        icon: 'far fa-paper-plane',
        search: false
      });
    }
    if (ShowReports && hasPermission(permissions, SHOW_REPORTS)) {
      sections.push({
        id: 'reports',
        name: txt(msg.reports),
        icon: 'far fa-chart-pie',
        search: false
      });
    }
    if (ShowSettings && (hasPermission(permissions, CHANGE_LOCATION_SETTINGS) || hasPermission(permissions, CHANGE_RESOURCES))) {
      const notification = ShowPayments && actionRequired(trialStatus, accountStatus) ? '!' : null;

      sections.push({
        id: 'admin',
        name: txt(msg.admin),
        iconBody: (
          <i className="img-wrap far fa-cog">
            {notification && (
            <img
              src="/exclamation-circle-duotone.svg"
              style={styleAdmin}
            />
            )}
          </i>
        ),
        search: false
      });
    }
    return sections;
  }
);
