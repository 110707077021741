import React, { memo } from 'react';
import ModalDialog from '@Components/dialogs/modal-dialog';
import { DialogLoaderCenterWrap } from '@Components/dialogs/dialog-styles';
import Loader from '@Components/ui/loader';

const DialogLoader = ({ title = 'Vänligen vänta...' }) => {
  return (
    <ModalDialog title={title} hideCloseButton focusTrapPaused>
      <DialogLoaderCenterWrap tabIndex={0}>
        <Loader />
      </DialogLoaderCenterWrap>
    </ModalDialog>
  );
};
export default memo(DialogLoader);
