import React from 'react';
import ModalDialog from '@Components/dialogs/modal-dialog';
import StaffSalesTermsForm from './staff-sales-terms-form';

const StaffSalesTermsModal = ({ user, onSubmit, onClose }) => {
  return (
    <ModalDialog
      title="Villkor för produktförsäljning"
      hideCloseButton
      closeOnClickOutside={false}
      onClose={onClose}
    >
      <StaffSalesTermsForm
        initialValues={user.salesTerms}
        onSubmit={onSubmit}
        onClose={onClose}
      />
    </ModalDialog>
  );
};

export default StaffSalesTermsModal;
