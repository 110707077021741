import React from 'react';
import CurrencyUtil from '@Utils/currency-util';
import { PanelWrapper, RowFormGroups } from '@Components/customers/customer-styles';
import { Column, FormGroup } from '@Components/ui/styled/main';
import moment from 'moment';
import Row from '@Components/ui/styled/row';

const CustomerGiftCardDetails = ({
  code, soldTs, noRedeemAfterTs, initialValue, remainingValue,
  description, beneficiary, comment
}) => (
  <PanelWrapper defaultCursor>
    <FormGroup labelText="Kod" text={code} />
    <RowFormGroups>
      <Column>
        <FormGroup labelText="Värde" text={CurrencyUtil.accountCurrency(initialValue)} />
      </Column>
      <Column>
        <FormGroup labelText="Värde kvar" text={CurrencyUtil.accountCurrency(remainingValue)} />
      </Column>
    </RowFormGroups>
    <RowFormGroups>
      <Column>
        <FormGroup
          labelText="Sålt"
          text={soldTs ? moment(soldTs).format('ll') : '-'}
        />
      </Column>
      <Column>
        <FormGroup
          labelText="Giltigt t.o.m."
          text={noRedeemAfterTs ? moment(noRedeemAfterTs).format('ll') : '-'}
        />
      </Column>
    </RowFormGroups>
    <FormGroup labelText="Beskrivning" text={description || '-'} />
    <FormGroup labelText="Förmånstagare" text={beneficiary || '-'} />
    <FormGroup labelText="Kommentar" text={comment || '-'} />
  </PanelWrapper>
);

export default CustomerGiftCardDetails;
