import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';

import { updateAccountType } from '@State/sysadmin-actions';
import { colors } from '@Components/ui/styled/variables';
import { getSysAdminLabelProps, isContractOngoing } from '@Utils/sysadmin-util';
import Label from '@Components/ui/label';
import ButtonModalForm from '@Components/dialogs/button-modal-form';
import FormButtonsFooter from '@Components/ui/form-buttons-footer';

const LabelContainer = styled.div`
  display: inline-block;
  margin-left: 10px;

  div + div {
    margin-left: 5px;
  }
`;

const AccountsHeaderLabels = ({ org }) => {
  const orgAccountType = org?.get('orgAccountType');
  const paymentMethod = org?.get('paymentMethod');
  const sfLeadCreated = org?.get('sfLeadCreatedTs');
  const contractStartDate = org?.get('contractStartDate');
  const contractEndDate = org?.get('contractEndDate');
  const statusLabel = org ? getSysAdminLabelProps(org.toJS()) : null;
  const contractOngoing = isContractOngoing(contractStartDate, contractEndDate);

  const dispatch = useDispatch();
  const { handleSubmit } = useForm();
  const [accountType, setAccountType] = useState('Test');

  useEffect(() => {
    setAccountType(orgAccountType);
  }, [orgAccountType]);

  const onSubmit = async () => {
    const orgId = org.get('id');
    await dispatch(updateAccountType({ accountType, orgId }));
  };

  return (
    <LabelContainer>
      <ButtonModalForm
        label={orgAccountType === 'Test' ? colors.labelTest : colors.primary}
        btnText={orgAccountType}
        onSubmit={onSubmit}
      >
        {({ onClose, onSubmit, loading }) => (
          <form onSubmit={handleSubmit(onSubmit)}>
            <h4>Ändra kontotyp</h4>
            <div className="radio">
              <label>
                <input type="radio" value="Live" checked={accountType === 'Live'} onChange={() => setAccountType('Live')} />
                Live
              </label>
            </div>
            <div className="radio">
              <label>
                <input type="radio" value="Test" checked={accountType === 'Test'} onChange={() => setAccountType('Test')} />
                Test
              </label>
            </div>
            <FormButtonsFooter
              onClose={onClose}
              submitting={loading}
              handleSubmit={onSubmit}
              submitSucceeded
            />
          </form>
        )}
      </ButtonModalForm>
      <Label bgColor={paymentMethod === 'Invoice' ? colors.greenCircle : colors.primaryLight}>
        {paymentMethod}
      </Label>
      {statusLabel?.text ? (
        <Label bgColor={statusLabel.color}>{statusLabel.text}</Label>
      ) : null}
      {sfLeadCreated ? (
        <Label bgColor={colors.labelTest}>SF</Label>
      ) : null}
      {contractOngoing ? (
        <Label bgColor={colors.darkGray80}>
          Bundet till {moment(contractEndDate).format('ll')}
        </Label>
      ) : null}
    </LabelContainer>
  );
};

export default AccountsHeaderLabels;
