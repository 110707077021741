import React from 'react';
import styled from 'styled-components';

const Icon = styled.i`
  font-size: 22px;
  margin: 15px 3px 15px 0;
  font-weight: 300;
`;

const Backspace = () => (
  <Icon className="far fas-large fa-backspace" />
);
export default Backspace;
