import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import s from 'underscore.string';
import Highlighter from 'react-highlight-words';
import { resetSearch, saveSearchScrollPos } from '@State/booking-actions';
import PhoneUtil from '@Utils/phone-util';
import { getCalendarUrl, navigate } from '@Utils/navigate';
import { viewDateFromDate } from '@Utils/time-util';
import { styles, visualStylesHC, visualStylesNonHC } from '@Components/calendar/grid/chip-styles';
import { classes } from '@Components/ui/utils';
import { getCurrentLocation, getOrgWideCustomerDb } from '@State/selectors';

const SearchResultItem = ({
  bookingId, resources, services, startTime, status, orgNo, orgName,
  name, phoneNumber, otherPhoneNumber, email, vehicleRegNo, vehicleDescription,
  visualStyles, onClick, selectedBookingId, search, locationId, locationOptions,
  currentLocation, orgWideCustomerDb
}) => {
  const resourcesDeleted = resources.every(r => r.deleted);
  const phoneNumbers = [];
  if (phoneNumber) {
    phoneNumbers.push(PhoneUtil.formatPhoneNumber(phoneNumber));
  }
  if (otherPhoneNumber) {
    phoneNumbers.push(PhoneUtil.formatPhoneNumber(otherPhoneNumber));
  }
  const isOtherLocation = locationId !== currentLocation.locationId;
  const selected = bookingId === selectedBookingId;
  const selectedStyle = selected ? visualStyles.statuses[`${status}Drag`] : null;
  const handStyle = (resourcesDeleted ? {} : { cursor: 'pointer' });
  const chipStyles = {
    ...styles.chips.base,
    ...visualStyles.statuses[status],
    ...selectedStyle,
    ...handStyle
  };

  const locationName = isOtherLocation
    ? locationOptions.valueSeq().find(l => l.locationId === locationId).locName
    : currentLocation.locName;

  return (
    <div
      className={resourcesDeleted ? 'search-result deleted' : 'search-result'}
      onClick={resourcesDeleted ? null : onClick}
      style={chipStyles}
    >
      <section>
        <strong>{name}</strong>
        <div>
          {services?.map(({ id, name }, index) => (
            <span key={id}>
              {index > 0 ? ', ' : ''}{name}
            </span>
          ))}
        </div>
        <div>
          {resources.map(({ id, name, deleted }, index) => (
            <span key={id} className={deleted ? 'text-danger' : ''}>
              {index > 0 ? ', ' : ''}{name}{deleted && ' (borttagen)'}
            </span>
          ))}
        </div>
        <span>{s.capitalize(moment(startTime).format('LLLL'))}</span>
      </section>
      {(vehicleRegNo || vehicleDescription) && (
        <section>
          {vehicleRegNo && <div><Highlighter searchWords={search} textToHighlight={vehicleRegNo} autoEscape /></div>}
          {vehicleDescription && <div><Highlighter searchWords={search} textToHighlight={vehicleDescription} autoEscape /></div>}
        </section>
      )}
      {(orgName || orgNo) && (
        <section>
          {orgName && <div><Highlighter searchWords={search} textToHighlight={orgName} autoEscape /></div>}
          {orgNo && <small><Highlighter searchWords={search} textToHighlight={orgNo} autoEscape /></small>}
        </section>
      )}
      {(name || phoneNumbers.length > 0 || email) && (
        <section>
          {phoneNumbers.length > 0 && <div><Highlighter searchWords={search} textToHighlight={phoneNumbers.join(' / ')} autoEscape /></div>}
          {email && <div><Highlighter searchWords={search} textToHighlight={email} autoEscape /></div>}
        </section>
      )}
      {orgWideCustomerDb && (
        <section className="other-location">
          <strong>
            {locationName}
&nbsp;&nbsp;
            {isOtherLocation && (<small className="pull-right"><i className="fa fa-external-link" /></small>)}
          </strong>
        </section>
      )}
    </div>
  );
};

class SearchResults extends Component {
  componentDidMount() {
    const { scrollPos } = this.props;
    this.resutlListEl.scrollTo({
      top: scrollPos,
      behavior: 'auto'
    });
  }

  bookingClick = (ev, booking) => {
    const { routeParams, locationOptions, orderedGroups } = this.props;
    const location = locationOptions.valueSeq().find(l => l.locationId === booking.get('locationId'));
    const resourceId = booking.get('resources').find(r => r.get('primary')).get('id');
    const myResources = orderedGroups.find(g => g.get('id') === 0);
    const isInMyResources = myResources?.get('resourceIds').includes(resourceId);
    const startTime = moment(booking.get('startTime'));
    const viewDate = viewDateFromDate('week', startTime);
    const newRouteParams = {
      ...routeParams,
      viewMode: 'week',
      entityType: isInMyResources ? 'my-resource' : 'resource',
      entityId: resourceId,
      org: location ? location.orgCtxName : routeParams.org,
      loc: location ? location.locCtxName : routeParams.loc
    };
    const calendarUrl = getCalendarUrl(viewDate, newRouteParams);
    this.props.setScrollPos(this.resutlListEl.scrollTop);
    navigate(`${calendarUrl}#${booking.get('bookingId')}`);
  };

  resetSearch = (ev) => {
    ev.preventDefault();
    this.props.resetSearch();
  };

  render() {
    const {
      showSearch, query, isSearching, bookings, highContrast, selectedBookingId,
      locationOptions, currentLocation, tabletMode, orgWideCustomerDb
    } = this.props;
    const search = query && query.split(' ');
    const matches = (bookings && bookings.size) || 0;
    const visualStyles = highContrast ? visualStylesHC : visualStylesNonHC;
    const grouped = bookings && bookings.groupBy(b => moment(b.get('startTime')).format('MMMM YYYY'));

    const classList = classes({
      'search-results': true,
      ' show': showSearch
    });

    const style = tabletMode ? { paddingTop: '40px' } : {};
    return (
      <div className={classList} style={style} ref={(ref) => { this.resutlListEl = ref; }}>
        <div className="search-results-container">
          {query && !tabletMode && <a href="#" onClick={this.resetSearch} className="reset-search"><i className="fa fa-times-circle" /></a>}
          {query && (
          <p className="search-info">
            {matches} träffar på '
            {query}
            '
          </p>
          )}
          {isSearching && <p className="search-loading">Söker...</p>}
          {grouped && grouped.entrySeq().map(([group, results]) => (
            <div key={group} className="search-group">
              <h5>{group}</h5>
              {results && results.map(result => (
                <SearchResultItem
                  {...result.toJS()}
                  key={result.get('bookingId')}
                  onClick={ev => this.bookingClick(ev, result)}
                  locationOptions={locationOptions}
                  currentLocation={currentLocation}
                  selectedBookingId={selectedBookingId}
                  visualStyles={visualStyles}
                  search={search}
                  orgWideCustomerDb={orgWideCustomerDb}
                />
              ))}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, { routeParams }) => {
  const {
    locationOptions, bookingSearchResults, gridViewState, mainViewState, orderedGroups
  } = state;

  return {
    routeParams,
    locationOptions,
    currentLocation: getCurrentLocation(state, { routeParams }),
    orgWideCustomerDb: getOrgWideCustomerDb(state),
    orderedGroups,
    query: bookingSearchResults.get('query'),
    showSearch: bookingSearchResults.get('showSearch'),
    isSearching: bookingSearchResults.get('isSearching'),
    bookings: bookingSearchResults.get('bookings'),
    scrollPos: bookingSearchResults.get('scrollPos'),
    tabletMode: mainViewState.get('tabletMode'),
    highContrast: gridViewState.get('highContrast'),
    selectedBookingId: routeParams.bookingId
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetSearch: () => {
      dispatch(resetSearch());
    },
    setScrollPos: (scrollPos) => {
      dispatch(saveSearchScrollPos(scrollPos));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchResults);
