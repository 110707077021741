import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import CheckboxStyled from '@Components/inputs/checkbox-styled';
import { setUserPosRole, deleteUserPosRole } from '@State/users-actions';
import Flex from '@Components/ui/styled/flex-item';
import { fsw } from '@Components/ui/styled/variables';
import { PosOrgWrapItem } from './style';

export const PosUserHelpText = styled.div`
  color: #626262;
  font-size: 15px;
`;

export const PosUserFlexOne = styled(PosOrgWrapItem)`
  flex: 1;
  margin-bottom: 0;
  justify-content: center;
`;

const PosUserGrid = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr 1fr;
  color: #000;
  font-size: 15px;
`;

export const PosUserRow = styled(PosUserGrid)`
  padding-top: 15px;
`;

export const TableHeader = styled(PosUserGrid)`
  font-weight: ${fsw.medium};
  .text-center {
    text-align: center;
  }
`;

class PosUserRoles extends Component {
  static propTypes = {
    posOrg: PropTypes.object.isRequired
  };

  state = {
    progress: false,
    saved: false
  };

  selectRole = (ev, isChecked, userId, cashierRole) => {
    if (isChecked) {
      this.setMapping(userId, cashierRole);
    } else {
      this.deleteMapping(userId);
    }
  };

  setMapping = (userId, cashierRole) => {
    this.setState({ loading: true, saved: false });
    return this.props.setUserPosRole(userId, this.props.posOrgId, cashierRole)
      .then(() => this.setState({ loading: false, saved: true }, this.resetSaved));
  };

  deleteMapping = (userId) => {
    this.setState({ loading: true, saved: false });
    return this.props.deleteUserPosRole(userId, this.props.posOrgId)
      .then(() => this.setState({ loading: false, saved: true }, this.resetSaved));
  };

  resetSaved = () => {
    setTimeout(() => { this.setState({ saved: false }); }, 2000);
  };

  render() {
    const { loading, saved } = this.state;
    const { usersById, posOrgId } = this.props;

    if (!posOrgId) {
      return null;
    }

    return (
      <div>
        <PosUserHelpText>
          Välj vilka användare som ska ha behörighet till
          <br />
          kassaregistret, samt vilken behörighetsnivå de ska ha
        </PosUserHelpText>
        <div>
          {loading && <strong className="text-muted">Sparar...</strong>}
          {saved && <strong className="text-success">Sparat!</strong>}
          <br />
          <br />
          <div className="resource-mappings">
            <TableHeader>
              <div>Användare</div>
              <div className="text-center">Kassör</div>
              <div className="text-center">Administatör</div>
            </TableHeader>
            <div>
              {usersById.valueSeq().map(user => this.renderMapping(user))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderMapping(user) {
    const { posOrgId, userPosRoles } = this.props;
    const { loading } = this.state;
    const userId = user.get('id');
    const cashierRole = userPosRoles.getIn([userId, posOrgId]);

    const isCheckedCashier = cashierRole === 'Cashier';
    const isCheckedAdmin = cashierRole === 'Admin';
    return (
      <PosUserRow key={userId}>
        <Flex vCenter>{user.get('name') || user.get('username')}</Flex>
        <PosUserFlexOne>
          <CheckboxStyled
            disabled={loading}
            onClick={ev => this.selectRole(ev, !isCheckedCashier, userId, 'Cashier')}
            input={{ checked: isCheckedCashier }}
            center
          />
        </PosUserFlexOne>
        <PosUserFlexOne>
          <CheckboxStyled
            disabled={loading}
            onClick={ev => this.selectRole(ev, !isCheckedAdmin, userId, 'Admin')}
            input={{ checked: isCheckedAdmin }}
            center
          />
        </PosUserFlexOne>
      </PosUserRow>
    );
  }
}

const mapStateToProps = (state, { posOrg }) => {
  const { usersById, userPosRoles } = state;

  return {
    usersById,
    userPosRoles,
    posOrgId: posOrg && posOrg.get('id')
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserPosRole: (userId, posOrgId, cashierRole) => {
      return dispatch(setUserPosRole(userId, posOrgId, cashierRole));
    },
    deleteUserPosRole: (userId, posOrgId) => {
      return dispatch(deleteUserPosRole(userId, posOrgId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PosUserRoles);
