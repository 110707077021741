import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import CurrencyUtil from '@Utils/currency-util';
import { sms } from '@Utils/preference-keys';
import SubmitButton from '@Components/ui/submit-button';
import {
  createValidator, smsSenderValidation
} from '@Utils/validation';
import { defaultSmsCancelTemplate, defaultSmsConfirmTemplate, defaultSmsReminderTemplate } from '@Utils/templates';
import TextInputHorizontal from '../../inputs/text-input-horizontal';
import TimeRangeInput from '../../inputs/time-range-input';
import SmsTemplateInput from '../../inputs/sms-template-input';

class SmsConfigForm extends Component {
  render() {
    const {
      handleSubmit, smsConfirmPrice, features, previewValues
    } = this.props;

    return (
      <form autoComplete="off" className="form-horizontal" onSubmit={handleSubmit}>

        <div className="row">
          <div className="col-sm-offset-3 col-sm-9">
            <h3>Inställningar för SMS</h3>
          </div>
        </div>

        {features.sender && (
          <div>
            <Field name={sms.smsSender} component={TextInputHorizontal} label="Avsändare" />
            <div className="row">
              <div className="col-sm-offset-3 col-sm-9">
                <p className="text-muted">
                  Max 11 tecken, enbart bokstäver (ej åäö), siffror och understreck (_)<br />
                  Kan även vara ett telefonnummer som mottagaren kan svara till
                </p>
              </div>
            </div>
            <hr />
          </div>
        )}

        {(features.smsOnBookOnline || features.smsOnCancelOnline || features.defaultOptionSmsConfirm) && features.EnableSmsConfirmations && (
          <div>
            {(features.smsOnBookOnline || features.smsOnCancelOnline) && (
              <div className="form-group">
                <label className="col-sm-3 control-label">Bokningsbekräftelse</label>
                <div className="col-sm-9">
                  {features.smsOnBookOnline && (
                    <div className="checkbox">
                      <label>
                        <Field name={sms.onBookOnline} component="input" type="checkbox" />
                        Skicka SMS vid bokning online ({CurrencyUtil.currency(smsConfirmPrice)})
                      </label>
                    </div>
                  )}
                  {features.smsOnCancelOnline && (
                    <div className="checkbox">
                      <label>
                        <Field name={sms.onCancelOnline} component="input" type="checkbox" />
                        Skicka SMS vid avbokning online ({CurrencyUtil.currency(smsConfirmPrice)})
                      </label>
                    </div>
                  )}
                </div>
              </div>
            )}

            {features.defaultOptionSmsConfirm && (
              <div className="form-group">
                <div className="col-sm-offset-3 col-sm-9">
                  <div className="checkbox">
                    <label>
                      <Field name={sms.defaultOptionSmsConfirm} component="input" type="checkbox" />
                      Kryssa i SMS-bekräftelse vid bokning/avbokning i kalendern
                    </label>
                  </div>
                </div>
              </div>
            )}
            <hr />
          </div>
        )}

        {features.reminderSendTimes && (
          <div>
            <div className="form-group">
              <label className="col-sm-3 control-label">Skicka påminnelse innan bokning</label>
              <div className="col-sm-9">
                <Field name={sms.reminderWithin} component="input" type="number" className="form-control inline-sm" min={1} max={120} required />
                <p className="form-control-static inline-md">timmar innan</p>
              </div>
            </div>
            <Field name={sms.reminderSendTimes} component={TimeRangeInput} label="Skicka påminnelser under dessa tider" />
            <hr />
          </div>
        )}

        {features.smsConfirmTemplate && features.EnableSmsConfirmations && (
          <div>
            <div className="form-group">
              <label className="col-sm-3 control-label">Text i bokningsbekräftelse</label>
              <div className="col-sm-9">
                <Field
                  name={sms.confirmTemplate}
                  component={SmsTemplateInput}
                  defaultTemplate={defaultSmsConfirmTemplate()}
                  replaceValues={previewValues}
                />
              </div>
            </div>
          </div>
        )}

        {features.smsReminderTemplate && (
          <div>
            <div className="form-group">
              <label className="col-sm-3 control-label">Text i påminnelse</label>
              <div className="col-sm-9">
                <Field
                  name={sms.reminderTemplate}
                  component={SmsTemplateInput}
                  defaultTemplate={defaultSmsReminderTemplate()}
                  replaceValues={previewValues}
                />
              </div>
            </div>
          </div>
        )}

        {features.smsCancelTemplate && (
          <div>
            <div className="form-group">
              <label className="col-sm-3 control-label">Text i avbokningsbekräftelse</label>
              <div className="col-sm-9">
                <Field
                  name={sms.cancelTemplate}
                  component={SmsTemplateInput}
                  defaultTemplate={defaultSmsCancelTemplate()}
                  replaceValues={previewValues}
                />
              </div>
            </div>
          </div>
        )}

        <hr />

        <div className="form-group">
          <div className="col-sm-offset-3 col-sm-9">
            <SubmitButton {...this.props} />
          </div>
        </div>
      </form>
    );
  }
}

const validator = createValidator({
  [sms.smsSender]: smsSenderValidation
});

export default reduxForm({
  form: 'sms-config',
  validate: validator,
  enableReinitialize: true
})(SmsConfigForm);
