import { createSelector } from 'reselect';
import { getOrganizations, getSelectedOrganization } from '@Utils/sysadmin-util';

const getSysAdminOrganizationsState = (state) => state.sysadminOrganizationsState;
const getSysAdminInvoice = (state) => state.sysadminInvoice;
const getSysadminOrganizationById = (state) => state.sysadminOrganizationById;
const getSysadminLocationsById = (state) => state.sysadminLocationsById;
const getSysadminControl = (state) => state.sysadminControl;
const geSysadminLocationHardwarePricing = (state) => state.sysadminLocationHardwarePricing;
const getSysadminPricePlansState = (state) => state.sysadminPricePlansById;

export const getSysadminLocations = createSelector(
  getSysadminLocationsById,
  sysadminLocationsById => sysadminLocationsById
);

export const getSysadminPricePlans = createSelector(
  getSysadminPricePlansState,
  sysadminPricePlans => sysadminPricePlans
);

export const getSysadminAllOrganizations = createSelector(
  getSysAdminOrganizationsState,
  (sysadminState) => sysadminState
);

export const getSysadminFilteredOrganizations = createSelector(
  getSysAdminOrganizationsState,
  getSysadminControl,
  (sysadminState, sysadminControl) => getOrganizations(sysadminState, sysadminControl)
);

export const getSysadminStateInvoice = createSelector(
  getSysAdminInvoice,
  sysAdminInvoice => sysAdminInvoice
);

export const getSysadminStateSelectedOrganization = createSelector(
  (params, id) => id,
  getSysAdminOrganizationsState,
  (id, organizations) => getSelectedOrganization({ organizations, id })
);

export const getSysadminStateOrganizationById = createSelector(
  getSysadminOrganizationById,
  sysadminOrganizationById => sysadminOrganizationById
);

export const getSysadminStateControlFilterType = createSelector(
  getSysadminControl,
  sysadminControl => sysadminControl.get('filterType')
);

export const getSysadminStateControlSort = createSelector(
  getSysadminControl,
  sysadminControl => sysadminControl.get('sort')
);

export const getSysadminLocationHardwarePricing = createSelector(
  geSysadminLocationHardwarePricing,
  sysadminLocationHardwarePricing => sysadminLocationHardwarePricing
);
