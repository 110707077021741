import { Map } from 'immutable';
import { CLEAR_LOCATION_STATE } from './account-actions';
import { POS_ORGS_FETCHED } from './pos-config-actions';

const TOGGLE_SERVICES_SORTABLE = 'TOGGLE_SERVICES_SORTABLE';
const TOGGLE_RESOURCES_SORTABLE = 'TOGGLE_RESOURCES_SORTABLE';
const TOGGLE_PRODUCTS_SORTABLE = 'TOGGLE_PRODUCTS_SORTABLE';

const TOGGLE_SERVICES_COLLAPSED = 'TOGGLE_SERVICES_COLLAPSED';
const TOGGLE_RESOURCES_COLLAPSED = 'TOGGLE_RESOURCES_COLLAPSED';
const TOGGLE_PRODUCTS_COLLAPSED = 'TOGGLE_PRODUCTS_COLLAPSED';

const TOGGLE_PRODUCTS_DELIVERY = 'TOGGLE_PRODUCTS_DELIVERY';

export function toggleServicesSortable(sortable) {
  return { type: TOGGLE_SERVICES_SORTABLE, sortable };
}

export function toggleResourcesSortable(sortable) {
  return { type: TOGGLE_RESOURCES_SORTABLE, sortable };
}

export function toggleProductsSortable(sortable) {
  return { type: TOGGLE_PRODUCTS_SORTABLE, sortable };
}

export function toggleProductsDelivery(delivery, direction = 'in') {
  return { type: TOGGLE_PRODUCTS_DELIVERY, delivery, direction };
}

export function toggleServicesCollapsed(collapsedIds) {
  return { type: TOGGLE_SERVICES_COLLAPSED, collapsedIds };
}

export function toggleResourcesCollapsed(collapsedIds) {
  return { type: TOGGLE_RESOURCES_COLLAPSED, collapsedIds };
}

export function toggleProductsCollapsed(collapsedIds) {
  return { type: TOGGLE_PRODUCTS_COLLAPSED, collapsedIds };
}

export function adminViewState(state = Map({ servicesSortable: false }), action = null) {
  switch (action.type) {
    case TOGGLE_SERVICES_SORTABLE:
      return state.set('servicesSortable', action.sortable);

    case TOGGLE_RESOURCES_SORTABLE:
      return state.set('resourcesSortable', action.sortable);

    case TOGGLE_PRODUCTS_SORTABLE:
      return state.set('productsSortable', action.sortable);

    case TOGGLE_SERVICES_COLLAPSED:
      return state.set('servicesCollapsedIds', action.collapsedIds);

    case TOGGLE_RESOURCES_COLLAPSED:
      return state.set('resourcesCollapsedIds', action.collapsedIds);

    case TOGGLE_PRODUCTS_COLLAPSED:
      return state.set('productsCollapsedIds', action.collapsedIds);

    case TOGGLE_PRODUCTS_DELIVERY:
      return state.set('productsDelivery', action.delivery)
        .set('productsDeliveryDirection', action.direction);

    case POS_ORGS_FETCHED:
      return state.set('posOrgsLoaded', true);

    case CLEAR_LOCATION_STATE:
      return state.clear();

    default:
      return state;
  }
}
