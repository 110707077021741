import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import shallowEqual from 'fbjs/lib/shallowEqual';
import ReactTooltip from 'react-tooltip';
import { getGridHeaderContainerStyle, getGridContainerStyle } from '@Utils/embedded-util';
import { clearResourceServices } from '@State/bkf/actions';
import { fetchViewData, updateViewDimensions } from '@State/view-actions';
import { getIsMultiResourceView, getResourcesInView } from '@State/calendar-selectors';
import { calculateGridPixelsPerRow, calcGridScrollHeight } from './grid-state-helper';
import ColumnHeadersContainer from './column-headers';
import RowHeadersContainer from './row-headers';
import RowRulers from './row-rulers';
import RowMarker from './row-marker';
import ColumnRulersContainer from './column-rulers';
import ColumnScheduleBlocksContainer from './column-schedule-blocks';
import Chips from './chips';
import GridMarkerContainer from './grid-marker';
import ScheduleEditBannerContainer from './schedule-edit-banner';
import MultiResourceBanner from './multi-resource-banner';
import ClipboardContainer from '../clipboard/clipboard-container';
import { TIME_COLUMN_WIDTH_OTHER_MODE, TIME_COLUMN_WIDTH_PHONE_MODE } from './constants';
import ChipHoverTracker from './chip-hover-tracker';

class Grid extends Component {
  componentDidUpdate(prevProps) {
    if (!shallowEqual(this.props.routeParams, prevProps.routeParams)) {
      this.props.fetchViewData();
    }
  }

  componentDidMount() {
    this.props.clearResourceServices();
    this.props.fetchViewData();

    window.addEventListener('contextmenu', this.preventContextMenu);
  }

  componentWillUnmount() {
    window.removeEventListener('contextmenu', this.preventContextMenu);
  }

  preventContextMenu = (ev) => {
    if (this.element && this.element.contains(ev.target)) {
      ev.preventDefault();
    }
  };

  handleScroll = () => {
    ReactTooltip.hide();
  };

  render() {
    const {
      highContrast, phoneMode, gridScrollHeight, isMultiResourceView, resourcesInView, multiResourceMode,
      gridScrollable, gridContainerStyle, gridHeaderContainerStyle, loadingViewData, scheduleEditMode
    } = this.props;

    const gridStyle = {
      height: gridScrollHeight
    };

    const gridClasses = highContrast || scheduleEditMode ? 'web-booking-closed-hc' : 'web-booking-closed';
    const timeColumnWidth = phoneMode ? TIME_COLUMN_WIDTH_PHONE_MODE : TIME_COLUMN_WIDTH_OTHER_MODE;

    return (
      <div id="calendargrid" ref={(ref) => { this.element = ref; }}>
        <div id="gridheadercontainer" style={gridHeaderContainerStyle}>
          <MultiResourceBanner routeParams={this.props.routeParams} updateViewDimensions={this.props.updateViewDimensions} />
          <ScheduleEditBannerContainer routeParams={this.props.routeParams} updateViewDimensions={this.props.updateViewDimensions} />
          <ClipboardContainer routeParams={this.props.routeParams} updateViewDimensions={this.props.updateViewDimensions} />
          <ColumnHeadersContainer routeParams={this.props.routeParams} />
        </div>
        {isMultiResourceView && <ChipHoverTracker />}
        <div
          id="gridcontainer"
          className={gridScrollable ? '' : 'noscroll'}
          style={gridContainerStyle}
          onScroll={this.handleScroll}
        >
          {multiResourceMode && !loadingViewData && resourcesInView.length === 0 && (
            <div className="no-resources-selected">
              <h2>Inga resurser valda</h2>
            </div>
          )}
          <table>
            <tbody>
              <tr>
                <td style={{ width: timeColumnWidth }}>
                  <RowHeadersContainer routeParams={this.props.routeParams} />
                </td>
                <td id="gridcontainercell">
                  <div id="grid" style={gridStyle} className={gridClasses}>
                    <ColumnScheduleBlocksContainer routeParams={this.props.routeParams} />
                    <ColumnRulersContainer routeParams={this.props.routeParams} />
                    <RowRulers routeParams={this.props.routeParams} />
                    <RowMarker routeParams={this.props.routeParams} />
                    <Chips routeParams={this.props.routeParams} />
                    <GridMarkerContainer routeParams={this.props.routeParams} />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

Grid.propTypes = {
  pixelsPerRow: PropTypes.number.isRequired,
  gridSize: PropTypes.oneOf(['xsmall', 'small', 'large']).isRequired,
  gridScrollHeight: PropTypes.number.isRequired,
  highContrast: PropTypes.bool.isRequired,
  scheduleEditMode: PropTypes.bool.isRequired,
  phoneMode: PropTypes.bool.isRequired
};

const getRowsPerHour = state => state.gridViewState.get('rowsPerHour');
const getGridSize = state => state.gridViewState.get('gridSize');

const pixelsPerRow = createSelector(
  getRowsPerHour,
  getGridSize,
  (rowsPerHour, gridSize) => {
    return calculateGridPixelsPerRow(rowsPerHour, gridSize);
  }
);

const gridScrollHeight = createSelector(
  pixelsPerRow,
  getRowsPerHour,
  (pixelsPerRow, rowsPerHour) => calcGridScrollHeight(pixelsPerRow, rowsPerHour)
);

const mapStateToProps = (state, ownProps) => {
  const {
    calendarViewState, gridViewState, mainViewState, bookingsClipboard
  } = state;

  const gridSize = gridViewState.get('gridSize'),
    rowsPerHour = gridViewState.get('rowsPerHour');

  return {
    loadingViewData: calendarViewState.get('loadingViewData'),
    forceUpdateTs: mainViewState.get('forceUpdateTs'),
    scheduleEditMode: gridViewState.get('scheduleEditMode'),
    showClipboard: bookingsClipboard.size > 0,
    phoneMode: mainViewState.get('phoneMode'),
    pixelsPerRow: pixelsPerRow(state),
    rowsPerHour,
    gridSize,
    gridScrollHeight: gridScrollHeight(state),
    highContrast: gridViewState.get('highContrast'),
    gridScrollable: gridViewState.get('gridScrollable'),
    gridContainerStyle: getGridContainerStyle(state),
    gridHeaderContainerStyle: getGridHeaderContainerStyle(state),
    isMultiResourceView: getIsMultiResourceView(state, ownProps),
    resourcesInView: getResourcesInView(state, ownProps),
    multiResourceMode: calendarViewState.get('multiResourceMode')
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchViewData: () => {
      dispatch(fetchViewData(ownProps.routeParams));
    },
    clearResourceServices: () => {
      dispatch(clearResourceServices());
    },
    updateViewDimensions: () => {
      dispatch(updateViewDimensions());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Grid);
