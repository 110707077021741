import * as R from 'ramda';
import { createSelector } from 'reselect';
import { company, resource as resourceKeys, web } from '@Utils/preference-keys';
import {
  fetchGet, fetchPut, fetchPatch, fetchDelete, checkStatus,
  prefixUrl, prefixOrgUrl, fetchErrorHandler, fetchPost
} from '@Utils/ajax-util';
import { getWebPaymentEnabled } from './selectors';
import { configChanged } from './account-actions';

export const PREFERENCES_LOADED = 'PREFERENCES_LOADED';
export const CHANGE_PREFERENCES = 'CHANGE_PREFERENCES';
export const CHANGE_CONTACT_INFO = 'CHANGE_CONTACT_INFO';
export const RESET_RESOURCE_PREFERENCES = 'RESET_RESOURCE_PREFERENCES';

export const getInitialValues = createSelector(
  state => state.preferencesViewState.get('jsonPreferences'),
  (state, keys) => keys,
  (preferences, keys) => {
    if (!preferences) {
      return null;
    }
    const values = {};
    Object.values(keys).forEach(key => {
      const value = preferences.get(key);
      if (typeof value !== 'undefined') {
        values[key] = value;
      }
    });
    return values;
  }
);

export const getInitialResourceValues = createSelector(
  state => state.preferencesViewState.get('resourcePreferences'),
  state => state.preferencesViewState.get('jsonPreferences'),
  (state, resource) => resource,
  getWebPaymentEnabled,
  (preferences, locationPrefs, { id, hashId, prefs, ...resourceValues }, webPaymentEnabled) => {
    const values = {
      ...resourceValues,
      useCustomValues: preferences?.get('useCustomValues') === true,
      color: preferences?.get('color')
    };
    resourceKeys.forEach(key => {
      const resourceValue = preferences && preferences.get(key);
      const defaultValue = locationPrefs && locationPrefs.get(key);
      values[key] = resourceValue !== undefined && resourceValue !== null ? resourceValue : defaultValue;
    });
    if (webPaymentEnabled && values[web.paymentEnabled] === undefined) {
      values[web.paymentEnabled] = true;
    }
    return values;
  }
);

export const getResourcePreference = (state, key) => {
  return state.preferencesViewState.getIn(['resourcePreferences', key]);
};

function notificationPreferencesSuccess(data) {
  return {
    type: CHANGE_PREFERENCES,
    state: {
      notifications: data
    }
  };
}

function resourcePreferencesSuccess({ prefs, resource }, id) {
  return {
    type: CHANGE_PREFERENCES,
    resourceId: id,
    state: {
      resourcePreferences: prefs,
      resource
    }
  };
}

function resetResourcePreferences(resourceId) {
  return {
    type: RESET_RESOURCE_PREFERENCES,
    resourceId
  };
}

function paymentPreferencesSuccess(data) {
  return {
    type: CHANGE_PREFERENCES,
    state: {
      paymentPreferences: data
    }
  };
}

function customTextsPreferencesSuccess(data) {
  return {
    type: CHANGE_PREFERENCES,
    state: {
      customTextsPreferences: data
    }
  };
}

function privatePreferencesSuccess(data) {
  return {
    type: CHANGE_PREFERENCES,
    state: {
      privatePreferences: data
    }
  };
}

function templatesPreferencesSuccess(data) {
  return {
    type: CHANGE_PREFERENCES,
    state: {
      templatesPreferences: data
    }
  };
}
function commonPreferencesSuccess(data) {
  return {
    type: CHANGE_PREFERENCES,
    state: {
      commonPreferences: data
    }
  };
}

function companyInfoFetched(data) {
  return {
    type: CHANGE_PREFERENCES,
    state: {
      companyInfo: R.omit(['contacts'], data),
      contacts: data.contacts
    }
  };
}

function companyInfoSaved(orgLoc, data) {
  return {
    type: CHANGE_PREFERENCES,
    state: {
      orgLoc,
      companyInfo: data
    }
  };
}

function contactInfoSaved(contactType, data) {
  return {
    type: CHANGE_CONTACT_INFO,
    contactType,
    contact: data
  };
}

function jsonPreferencesLoaded(data) {
  return {
    type: PREFERENCES_LOADED,
    state: {
      jsonPreferences: data
    }
  };
}

export function updateLocalPreferences(data) {
  return {
    type: CHANGE_PREFERENCES,
    state: {
      jsonPreferences: data
    }
  };
}

export function getJsonPreferences(ignoreCache = false) {
  return (dispatch, getState) => {
    const { preferencesViewState } = getState();
    if (!ignoreCache && preferencesViewState.get('jsonPreferencesLoaded')) {
      return Promise.resolve();
    }
    const url = prefixUrl('/preferences/');
    return dispatch(loadPreferences(url, jsonPreferencesLoaded));
  };
}

export function getNotificationPreferences() {
  const url = prefixUrl('/notifications/');
  return loadPreferences(url, notificationPreferencesSuccess);
}

export function saveNotificationPreferences(data) {
  const url = prefixUrl('/notifications/');
  return savePreferences(url, data, notificationPreferencesSuccess);
}

export function getResourcePreferences(resourceId) {
  return (dispatch, getState) => {
    const { resourcesById } = getState();
    if (!resourceId || !resourcesById.get(resourceId)) {
      return;
    }
    const { prefs, ...resource } = resourcesById.get(resourceId);
    dispatch(resetResourcePreferences(resourceId));
    return dispatch(resourcePreferencesSuccess({ prefs, resource }, resourceId));
  };
}

export function mergeResourcePreferences(resourceId, data) {
  const url = prefixUrl(`/resources/${resourceId}/preferences`);
  return (dispatch) => {
    return fetch(url, fetchPatch(data))
      .then(res => dispatch(checkStatus(res)))
      .then(res => dispatch(resourcePreferencesSuccess({ prefs: data }, resourceId)))
      .catch((error) => {
        dispatch(fetchErrorHandler(error));
        throw error;
      });
  };
}

export function replaceResourcePreferences(resourceId, data) {
  const url = prefixUrl(`/resources/${resourceId}/preferences`);
  return (dispatch) => {
    return fetch(url, fetchPut(data))
      .then(res => dispatch(checkStatus(res)))
      .then(res => {
        dispatch(resetResourcePreferences(resourceId));
        dispatch(resourcePreferencesSuccess({ prefs: data }, resourceId));
      })
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function getTemplatesPreferences() {
  const url = prefixUrl('/preferences/templates');
  return loadPreferences(url, templatesPreferencesSuccess);
}

export function saveTemplatesPreferences(data) {
  return (dispatch) => {
    return fetch(prefixUrl('/preferences/templates'), fetchPut(data))
      .then(res => dispatch(checkStatus(res)))
      .then(res => dispatch(templatesPreferencesSuccess(data)))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function getCustomTextsPreferences() {
  const url = prefixUrl('/preferences/custom-texts');
  return loadPreferences(url, customTextsPreferencesSuccess);
}

export function saveCustomTextsPreferences(data) {
  return (dispatch) => {
    return fetch(prefixUrl('/preferences/custom-texts'), fetchPut(data))
      .then(res => dispatch(checkStatus(res)))
      .then(res => dispatch(customTextsPreferencesSuccess(data)))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function getPrivatePreferences() {
  const url = prefixUrl('/preferences/private-prefs');
  return loadPreferences(url, privatePreferencesSuccess);
}

export function savePrivatePreferences(data) {
  return (dispatch) => {
    return fetch(prefixUrl('/preferences/private-prefs'), fetchPut(data))
      .then(res => dispatch(checkStatus(res)))
      .then(res => dispatch(privatePreferencesSuccess(data)))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

function loadPreferences(url, callback, id) {
  return (dispatch) => {
    return fetch(url, fetchGet())
      .then(res => dispatch(checkStatus(res)))
      .then(res => res.json())
      .then(res => dispatch(callback(res, id)))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

function savePreferences(url, data, callback, id) {
  return (dispatch) => {
    return fetch(url, fetchPut(data))
      .then(res => dispatch(checkStatus(res)))
      .then(res => dispatch(callback(data, id)))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function mergeJsonPreferences(data) {
  return (dispatch) => {
    return fetch(prefixUrl('/preferences/'), fetchPatch(data))
      .then(res => dispatch(checkStatus(res)))
      .then(res => dispatch(updateLocalPreferences(data)))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function replaceJsonPreferences(data) {
  return (dispatch) => {
    return fetch(prefixUrl('/preferences/'), fetchPut(data))
      .then(res => dispatch(checkStatus(res)))
      .then(res => dispatch(updateLocalPreferences(data)))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function fetchCompanyInfo() {
  return (dispatch) => {
    return fetch(prefixOrgUrl('/company-info/'), fetchGet())
      .then(res => dispatch(checkStatus(res)))
      .then(res => res.json())
      .then(res => dispatch(companyInfoFetched(res)))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function saveCompanyInfo(orgLoc, data) {
  return (dispatch) => {
    return fetch(prefixOrgUrl('/company-info/'), fetchPut(data))
      .then(res => dispatch(checkStatus(res)))
      .then(res => dispatch(companyInfoSaved(orgLoc, data)))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function saveContactInfo(contactType, data) {
  return (dispatch) => {
    return fetch(prefixOrgUrl(`/contacts/${contactType}`), fetchPut(data))
      .then(res => dispatch(checkStatus(res)))
      .then(res => dispatch(contactInfoSaved(contactType, data)))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function deleteContactInfo(contactType) {
  return (dispatch) => {
    return fetch(prefixOrgUrl(`/contacts/${contactType}`), fetchDelete())
      .then(res => dispatch(checkStatus(res)))
      .then(res => dispatch(contactInfoSaved(contactType, null)))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

const businessDataUrl = (path) => prefixUrl(`/business-data/admin${path}`);

export function validateLocationSlug(slug) {
  return (dispatch) => {
    return fetch(businessDataUrl(`/slug?slug=${slug}`), fetchGet())
      .then(res => dispatch(checkStatus(res)))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function setLocationSlug(slug) {
  return (dispatch) => {
    return fetch(businessDataUrl('/slug'), fetchPut({ slug }))
      .then(res => dispatch(checkStatus(res)))
      .then(() => dispatch(configChanged({ slug })))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function toggleSearchEngine(searchEngineEnabled) {
  return (dispatch) => {
    return fetch(businessDataUrl('/publish'), fetchPost({ searchEngineEnabled }))
      .then(res => dispatch(checkStatus(res)))
      .then(() => dispatch(configChanged({ searchEngineEnabled })))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function updateContactDetails(values) {
  return (dispatch) => {
    return fetch(businessDataUrl('/contact-details'), fetchPut(values))
      .then(res => dispatch(checkStatus(res)))
      .then(res => dispatch(updateLocalPreferences(values)))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}
