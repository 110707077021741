import {
  fetchGet, fetchPost, fetchDelete, checkStatus, prefixUrl, fetchErrorHandler, fetchPut
} from '@Utils/ajax-util';

export const GET_CAMPAIGNS = 'GET_CAMPAIGNS';
export const CREATE_CAMPAIGN = 'CREATE_CAMPAIGN';
export const UPDATE_CAMPAIGN = 'UPDATE_CAMPAIGN';
export const DELETE_CAMPAIGN = 'DELETE_CAMPAIGN';

function fetchCampaignsSuccess(data) {
  return {
    type: GET_CAMPAIGNS,
    state: {
      campaigns: data
    }
  };
}

function createCampaignSuccess(data) {
  return {
    type: CREATE_CAMPAIGN,
    campaign: data
  };
}

function deleteCampaignSuccess(id) {
  return {
    type: DELETE_CAMPAIGN,
    campaignId: id
  };
}

export function updateCampaignSuccess(campaign) {
  return {
    type: UPDATE_CAMPAIGN,
    campaign
  };
}

export function fetchCampaigns() {
  const url = prefixUrl('/marketingcampaign/');
  return (dispatch) => {
    return fetch(url, fetchGet())
      .then(res => dispatch(checkStatus(res)))
      .then(res => res.json())
      .then(res => dispatch(fetchCampaignsSuccess(res.campaigns ?? [])))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function fetchCampaignEstimate(id) {
  const url = prefixUrl(`/marketingcampaign/${id}/estimate`);
  return (dispatch) => {
    return fetch(url, fetchGet())
      .then(res => res.json())
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function fetchCampaignEstimateForSearch(data) {
  const url = prefixUrl('/marketingcampaign/estimate');
  return (dispatch) => {
    return fetch(url, fetchPost(data))
      .then(res => res.json())
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function createCampaign(data) {
  const url = prefixUrl('/marketingcampaign/');
  return (dispatch) => {
    return fetch(url, fetchPost(data))
      .then(res => dispatch(checkStatus(res)))
      .then(res => res.json())
      .then(res => {
        dispatch(createCampaignSuccess({ ...data, ...res, name: data.campaignName, type: data.campaignType }));
        return res;
      })
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function updateCampaign({ id, ...data }) {
  const url = prefixUrl(`/marketingcampaign/${id}`);
  return (dispatch) => {
    return fetch(url, fetchPut(data))
      .then(res => dispatch(checkStatus(res)))
      .then(res => res.json())
      .then(res => {
        dispatch(updateCampaignSuccess({ id: parseInt(id), ...data, ...res }));
        return res;
      })
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function scheduleCampaign(id, data) {
  const url = prefixUrl(`/marketingcampaign/${id}/schedule`);
  return (dispatch) => {
    return fetch(url, fetchPost(data))
      .then(res => dispatch(checkStatus(res)))
      .then(res => res.json())
      .then(res => {
        dispatch(updateCampaignSuccess({ id: parseInt(id), ...data, ...res }));
        return res;
      })
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function fetchCampaignStatus(id) {
  const url = prefixUrl(`/marketingcampaign/${id}/sent-count`);
  return (dispatch) => {
    return fetch(url, fetchGet())
      .then(res => res.json())
      .then(res => {
        dispatch(updateCampaignSuccess({ id: parseInt(id), ...res }));
        return res;
      })
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function fetchCampaignRecipients(id) {
  const url = prefixUrl(`/marketingcampaign/${id}/recipients/`);
  return (dispatch) => {
    return fetch(url, fetchGet())
      .then(res => res.json())
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function fetchCampaignRecipientsForSearch(data) {
  const url = prefixUrl('/marketingcampaign/preview-recipients');
  return (dispatch) => {
    return fetch(url, fetchPost(data))
      .then(res => res.json())
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function cancelCampaign(id) {
  const url = prefixUrl(`/marketingcampaign/${id}/cancel`);
  return (dispatch) => {
    return fetch(url, fetchPut())
      .then(res => dispatch(checkStatus(res)))
      .then(res => res.json())
      .then(res => {
        dispatch(updateCampaignSuccess({ id: parseInt(id), ...res }));
        return res;
      })
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function deleteCampaign(id) {
  const url = prefixUrl(`/marketingcampaign/${id}`);
  return (dispatch) => {
    return fetch(url, fetchDelete())
      .then(res => dispatch(checkStatus(res)))
      .then(res => dispatch(deleteCampaignSuccess(id)))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}
