import axios from 'axios';
import { fromJS } from 'immutable';
import queryString from 'query-string';
import {
  axiosDefault, axiosErrorHandler, checkStatusAxios, prefixUrl
} from '@Utils/ajax-util';
import { pos } from '@Utils/preference-keys';

export const POS_INVOICES_FETCHED = 'POS_INVOICES_FETCHED';
export const POS_INVOICE_STATUS_UPDATED = 'POS_INVOICE_STATUS_UPDATED';

export function fetchInvoices(posOrgId, start, end, status) {
  return (dispatch) => {
    const query = queryString.stringify({ start, end, status });
    const url = prefixUrl(`/pos/org/${posOrgId}/invoice/?${query}`);
    const config = axiosDefault();

    return axios.get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => dispatch({ type: POS_INVOICES_FETCHED, invoices: res.data.invoices }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function fetchInvoicesLedger(posOrgId, ledgerDate) {
  return (dispatch) => {
    const query = queryString.stringify({ ledgerDate });
    const url = prefixUrl(`/pos/org/${posOrgId}/invoice/ledger?${query}`);
    const config = axiosDefault();

    return axios.get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => dispatch({ type: POS_INVOICES_FETCHED, invoices: res.data.invoices }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function fetchInvoice(posOrgId, invoiceId) {
  return (dispatch) => {
    const url = prefixUrl(`/pos/org/${posOrgId}/invoice/${invoiceId}`);
    const config = axiosDefault();

    return axios.get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data.invoice)
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function invoiceStatusUpdated(invoiceId, invoice) {
  return {
    type: POS_INVOICE_STATUS_UPDATED,
    invoice: {
      id: parseInt(invoiceId),
      ...invoice
    }
  };
}

export function setInvoiceStatus(posOrgId, invoice) {
  return (dispatch) => {
    const url = prefixUrl(`/pos/org/${posOrgId}/invoice/${invoice.id}`);
    const config = axiosDefault();

    return axios.put(url, invoice, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => dispatch(invoiceStatusUpdated(invoice.id, invoice)))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function searchInvoiceCustomers(posOrgId, text) {
  const url = prefixUrl(`/pos/invoice/customers/search?posOrgId=${posOrgId}&query=${encodeURIComponent(text)}`);
  const config = axiosDefault();

  return dispatch => axios
    .get(url, config)
    .then(res => dispatch(checkStatusAxios(res)))
    .then(({ data }) => {
      return data.result;
    })
    .catch(error => axiosErrorHandler(error, dispatch));
}

export function fetchInvoiceCustomer(invoiceCustomerId) {
  return (dispatch) => {
    const url = prefixUrl(`/pos/invoice/customers/${invoiceCustomerId}`);
    const config = axiosDefault();

    return axios.get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => fromJS(res.data))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

function normalizeCustomer(customer) {
  if (customer.type !== 'Company') {
    customer.companyName = null;
    customer.companyVatNo = null;
  }
}

function normalizeCustomerAddress(customer) {
  if (customer.type === 'Private') {
    customer.address1 = customer.address1 || '';
    customer.postCode = customer.postCode || '';
    customer.area = customer.area || '';
  }
  return customer;
}

function getInvoiceUseFortnox(state, posOrgId) {
  const posOrg = state.posOrgs.find(o => o.get('id') == posOrgId);
  return posOrg?.getIn(['prefs', pos.invoiceUseFortnox]);
}

export function createInvoiceCustomer(posOrgId, customer) {
  return (dispatch, getState) => {
    const invoiceUseFortnox = getInvoiceUseFortnox(getState(), posOrgId);
    const url = invoiceUseFortnox
      ? prefixUrl(`/pos/fortnox/org/${posOrgId}/customers/`)
      : prefixUrl(`/pos/invoice/org/${posOrgId}/customers/`);
    const config = axiosDefault();
    normalizeCustomer(customer);

    return axios.post(url, normalizeCustomerAddress(customer), config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => {
        const { id, fortnoxCustomerNumber } = res.data;
        return fromJS({ id, fortnoxCustomerNumber, ...customer });
      })
      .catch(error => {
        axiosErrorHandler(error, dispatch);
        throw error;
      });
  };
}

export function updateInvoiceCustomer(posOrgId, customer) {
  return (dispatch, getState) => {
    const invoiceUseFortnox = getInvoiceUseFortnox(getState(), posOrgId);
    const url = invoiceUseFortnox
      ? prefixUrl(`/pos/fortnox/org/${posOrgId}/customers/${customer.id}`)
      : prefixUrl(`/pos/invoice/org/${posOrgId}/customers/${customer.id}`);
    const config = axiosDefault();
    normalizeCustomer(customer);

    return axios.put(url, normalizeCustomerAddress(customer), config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => fromJS(customer))
      .catch(error => {
        axiosErrorHandler(error, dispatch);
        throw error;
      });
  };
}

export function syncInvoiceCustomer(posOrgId, customerId) {
  return (dispatch) => {
    const url = prefixUrl(`/pos/fortnox/org/${posOrgId}/customers/${customerId}/create-in-fortnox`);
    const config = axiosDefault();

    return axios.post(url, null, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data.fortnoxCustomerNumber)
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function refreshInvoiceStatus(posOrgId, invoiceId) {
  return (dispatch) => {
    const url = prefixUrl(`/pos/fortnox/org/${posOrgId}/update-invoice-status/${invoiceId}`);
    const config = axiosDefault();

    return axios.post(url, null, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => dispatch(invoiceStatusUpdated(invoiceId, res.data)))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}
