import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';

import { getCampaignEnabled } from '@State/selectors';
import { createCampaign, deleteCampaign, scheduleCampaign, updateCampaign } from '@State/campaign-actions';
import { getCampaignPayload, getDeliveryTime, getCampaignDefaultValues, getCampaignFormState, getDateFilterType } from '@Components/campaign/campaign-helpers';
import { getOrgLocCampaignUrl, navigate } from '@Utils/navigate';
import ModalDialog from '@Components/dialogs/modal-dialog';
import CampaignType from '@Components/campaign/form/campaign-type';
import CampaignFilter from '@Components/campaign/form/campaign-filter';
import CampaignContent from '@Components/campaign/form/campaign-content';
import CampaignSchedule from '@Components/campaign/form/campaign-schedule';
import CampaignButtonFooter from '@Components/campaign/form/campaign-button-footer';
import DialogAlert from '@Components/dialogs/dialog-alert';
import CampaignConfirm from './campaign-confirm';

const CampaignModal = ({ isNew = false, campaign = null, onClose, initialStep = 0, routeParams }) => {
  const { id: routeId } = useParams();
  const dispatch = useDispatch();
  const [confirmClose, setConfirmClose] = useState(false);
  const [blockModal, setBlockModal] = useState(false);
  const [step, setStep] = useState(initialStep);

  const campaignEnabled = useSelector(getCampaignEnabled);
  const defaultValues = useSelector(getCampaignDefaultValues);
  const methods = useForm({
    defaultValues: campaign
      ? getCampaignFormState(campaign.toJS(), defaultValues)
      : defaultValues
  });
  const formId = methods.watch('id');

  const onHideModal = useCallback(() => {
    if (isNew && formId) {
      setConfirmClose(true);
    } else {
      onClose();
    }
  }, [formId]);

  const onDelete = useCallback(() => {
    dispatch(deleteCampaign(formId));
    onClose();
  }, [formId]);

  const onSubmit = useCallback(async (values) => {
    const payload = getCampaignPayload(values);
    const method = step === 0 && !values.id ? createCampaign : updateCampaign;
    const res = await dispatch(method(payload));
    if (!res || !res.id) {
      return;
    }

    methods.setValue('id', res.id);
    methods.setValue('dateFilterType', getDateFilterType(payload, defaultValues));

    if (step === 4) {
      const deliveryTime = getDeliveryTime(values);
      await dispatch(scheduleCampaign(res.id, { deliveryTime }));
      onClose(false);
      return;
    }
    if (res.id !== parseInt(routeId)) {
      navigate(`${getOrgLocCampaignUrl(routeParams)}/${res.id}`);
    }

    setStep(step < 4 ? step + 1 : 0);
  }, [routeId, step]);

  if (!campaignEnabled) {
    return (
      <DialogAlert
        warning
        title="Skapa nytt utskick"
        icon="fa fa-exclamation-triangle"
        text="Utskick ej tillgängligt. Kontakta support för aktivering."
        onClose={onClose}
      />
    );
  }

  return (
    <>
      {!confirmClose && (
        <ModalDialog onClose={blockModal ? null : onHideModal} contentSize="large" alignTop closeOnClickOutside={false}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              {step === 0 && <CampaignType isNew />}
              {step === 1 && (
                <CampaignFilter
                  setBlockModal={setBlockModal}
                  initialCount={campaign?.get('recipientCount')}
                />
              )}
              {step === 2 && <CampaignContent />}
              {step === 3 && <CampaignSchedule />}
              {step === 4 && <CampaignConfirm />}
              <CampaignButtonFooter
                isNew={isNew}
                step={step}
                setActiveStep={setStep}
                onClose={onHideModal}
              />
            </form>
          </FormProvider>
        </ModalDialog>
      )}
      {confirmClose && (
        <DialogAlert
          text="Vill du spara ett utkast av utskicket?"
          buttons={[{
            primary: true,
            name: 'Spara utkast',
            onClick: onClose
          }, {
            danger: true,
            name: 'Ta bort utkast',
            onClick: onDelete
          }]}
        />
      )}
    </>
  );
};

export default CampaignModal;
