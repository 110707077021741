import React, { memo } from 'react';
import PropTypes from 'prop-types';
import UserMenuButton from '@Components/nav/user-menu-button';
import SmallSearchInput from '@Components/ui/small-search-input';
import SearchField from '@Components/nav/search-field';

const CalendarHeaderUserBlock = ({ isTableMode, routeParams }) => (
  <div className="section-header-right ui-user-block">
    <UserMenuButton routeParams={routeParams} className={isTableMode ? 'ipad-mode' : ''} />
    {isTableMode ? <SmallSearchInput /> : <SearchField />}
  </div>
);

CalendarHeaderUserBlock.propTypes = {
  isTableMode: PropTypes.bool,
  routeParams: PropTypes.object
};

export default memo(CalendarHeaderUserBlock);
