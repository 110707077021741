import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { classes } from '@Components/ui/utils';
import { iconStyle, wrapperStyle } from '@Components/pos/style';
import ButtonBack from '@Components/ui/tb-button-back';

import { getRouteParams } from '@State/selectors';
import { getNavSections } from './nav-selectors';
import UserMenuButton from './user-menu-button';
import SearchField from './search-field';

export const Toolbar = ({ children, className = '' }) => (

  <div className={`section-header-toolbar ${className}`}>
    {children}
  </div>
);

class Header extends PureComponent {
  renderChildren(component) {
    const { type } = React.createElement(component);
    return React.Children.map(this.props.children, (child) => {
      return child && child.type == type ? child : null;
    });
  }

  render() {
    const {
      sections, routeParams, title, subtitle, phoneMode, tabletMode, isShowSearch, className, showBackButton, onBackButtonClick
    } = this.props;
    const section = sections.find(s => s.id === routeParams.section);
    const showSearch = isShowSearch || !phoneMode && !tabletMode && section && section.search;
    const defaultTitle = section && section.name;

    const classList = classes({
      'section-header': true,
      [className]: className
    });

    return (
      <header className={classList}>
        {showBackButton && <ButtonBack style={wrapperStyle} iconStyle={iconStyle} onClick={onBackButtonClick} />}
        <div className="section-header-title">
          <h1>{title || defaultTitle}</h1>
          {subtitle && <h2>{subtitle}</h2>}
        </div>
        <div className="section-header-content">
          {this.renderChildren(Toolbar)}
        </div>
        <div className="section-header-right">
          <UserMenuButton routeParams={routeParams} />
          {showSearch && <SearchField />}
        </div>
      </header>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { mainViewState } = state;
  const routeParams = getRouteParams(state, ownProps);

  return {
    routeParams,
    sections: getNavSections(state, { routeParams }),
    phoneMode: mainViewState.get('phoneMode'),
    tabletMode: mainViewState.get('tabletMode')
  };
};

export default connect(mapStateToProps)(Header);
