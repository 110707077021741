import React, { useCallback, useContext, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import {
  updateAllowEmailCampaign, updateAllowSMS, updateAllowSMSCampaign,
  updateSMSbilling, updateSmsSendLimits
} from '@State/sysadmin-actions';

import ButtonModalForm from '@Components/dialogs/button-modal-form';
import AccountOverviewContext from '../account-overview/account-overview-context';
import AccountOverviewBlock from '../account-overview/account-overview-block';
import AccountPrefsSmsLimitsForm from './account-prefs-sms-limits-form';

const AccountPrefsSms = () => {
  const dispatch = useDispatch();
  const { id, subscription } = useContext(AccountOverviewContext);

  const initialValues = useMemo(() => ({
    sendLimitIndividual: subscription.get('sendLimitIndividual'),
    sendLimitBulk: subscription.get('sendLimitBulk')
  }), [subscription]);

  const onChangeSMSBillingEnabled = useCallback((e) => {
    dispatch(updateSMSbilling(id, e.target.checked));
  }, [id]);

  const onChangeAllowSms = (e) => {
    dispatch(updateAllowSMS(id, e.target.checked));
  };

  const onChangeAllowSmsCampaign = (e) => {
    dispatch(updateAllowSMSCampaign(id, e.target.checked));
  };

  const onChangeAllowEmailCampaign = (e) => {
    dispatch(updateAllowEmailCampaign(id, e.target.checked));
  };

  const onSubmitSmsSendLimits = (values) => {
    return dispatch(updateSmsSendLimits({ orgId: id, ...values }));
  };

  const smsFields = [
    [
      { prop: 'smsBillingEnabled', title: 'overviewSmsBillingEnabled', handleToggle: onChangeSMSBillingEnabled },
      { prop: 'allowSms', title: 'overviewAllowSms', handleToggle: onChangeAllowSms },
      { prop: 'allowSmsCampaign', title: 'overviewAllowSmsCampaign', handleToggle: onChangeAllowSmsCampaign }
    ],
    [
      { prop: 'allowEmailCampaign', title: 'overviewAllowEmailCampaign', handleToggle: onChangeAllowEmailCampaign },
      { prop: 'sendLimitIndividual', getProp: (d) => `${d.get('sentIndividualSms')} / ${d.get('sendLimitIndividual')}`, title: 'overviewSmsLimitIndividual' },
      { prop: 'sendLimitBulk', getProp: (d) => `${d.get('sentBulkSms')} / ${d.get('sendLimitBulk')}`, title: 'overviewSmsLimitBulk' }
    ]
  ];

  return (
    <AccountOverviewBlock
      title="Kommunikation"
      columnsQuan={3}
      columns={smsFields}
      data={subscription}
      button={(
        <ButtonModalForm link btnText="Ändra SMS-gränser" onSubmit={onSubmitSmsSendLimits}>
          <AccountPrefsSmsLimitsForm initialValues={initialValues} />
        </ButtonModalForm>
      )}
    />
  );
};

export default AccountPrefsSms;
