import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import { getSectionUrl } from '@Utils/navigate';
import SubmitButton from '@Components/ui/submit-button';
import TextInputHorizontal from '../../inputs/text-input-horizontal';

class NotificationsForm extends Component {
  render() {
    const { handleSubmit, routeParams } = this.props;

    return (
      <form autoComplete="off" className="form-horizontal" onSubmit={handleSubmit}>

        <div className="row mb4">
          <div className="col-sm-offset-3 col-sm-9">
            <h3>Inställningar för bokningsnotiser</h3>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-offset-3 col-sm-9">
            <h4 className="mb2">Notiser för hela kontot</h4>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-offset-3 col-sm-5">
            <p className="text-muted">
              Här ställer du in om du vill få notiser för alla bokningar som sker på kontot.
            </p>
          </div>
        </div>

        <div className="form-group">
          <label className="col-sm-3 control-label">Bokningsnotiser</label>
          <div className="col-sm-5">
            <div className="select-container">
              <Field name="bookingEmailPreference" component="select" className="form-control">
                <option value="None">Av</option>
                <option value="Web">Onlinebokningar och avbokningar</option>
                <option value="All">Alla bokningar och avbokningar</option>
              </Field>
            </div>
          </div>
        </div>
        <Field
          name="email"
          component={TextInputHorizontal}
          label="Mottagare"
          placeholder="Ange e-postadress"
        />
        <div className="form-group">
          <div className="col-sm-offset-3 col-sm-5">
            <SubmitButton {...this.props} />
          </div>
        </div>

        <hr className="mt5 mb4" />

        <div className="row">
          <div className="col-sm-offset-3 col-sm-9">
            <h4>Notiser för resurser</h4>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-offset-3 col-sm-5">
            <p className="text-muted">
              Notiser för enskilda användare och resurser ställs in under respektive användares inställningar.
            </p>
          </div>
        </div><br />

        <div className="form-group">
          <label className="col-sm-3 control-label" />
          <div className="col-sm-5">
            <Link to={getSectionUrl(routeParams, 'user-config')} className="btn btn-default">Användarinställningar</Link><br />
          </div>
        </div>

      </form>
    );
  }
}

export default reduxForm({
  form: 'notifications',
  enableReinitialize: true
})(NotificationsForm);
