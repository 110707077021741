export const MONTH_DAY_YEAR_SMALL_FORMAT = 'll'; // Sep 4, 1986
export const MONTH_DAY_YEAR_TIME = 'LLL'; // "February 10, 2021 10:19 AM"
export const DAY_MONTH_YEAR_TIME_FORMAT = 'LLLL'; // Thursday, September 4, 1986 8:30 PM
export const DAY_MONTH_YEAR_TIME_SHORT_FORMAT = 'llll'; // "Wed, Jan 13, 2021 10:06 AM"
export const DAY_TEXT_FORMAT = 'dddd'; // Sunday
export const DATE_PICKER_FORMAT = 'YYYY-MM-DD'; // "2021-01-13"
export const SERVER_FORMAT = 'YYYY-MM-DDTHH:mm:ss'; // "2021-01-13"
export const DATE_CALENDAR_PICKER_FORMAT = 'yyyy-MM-dd'; // "2021-01-We"
export const DAY_DATE_FULL = 'dddd D MMM YYYY'; // "Wednesday 13 Jan 2021"
export const DAY_WEEK_DATE_MONTH_YEAR = 'ddd DD MMM YYYY'; // "Wed 07 Oct 2020"
export const DAY_WEEK_DATE_MONTH = 'ddd DD MMM'; // "Wed 07 Oct"
export const DAY_WEEK_DATE_MONTH_YEAR_TIME = 'ddd DD MMM YYYY [kl] HH:mm'; // "Wed 07 Oct 2020 [kl] 10:00"
export const HOURS_MINUTES = 'HH:mm'; // "09:00"
export const HOURS_MINUTES_SECONDS = 'HH:mm:ss'; // "09:00:00"
export const YEAR_MONTH_DAY_H_M_S = 'YYYY-MM-DD HH:mm:ss'; // 2019-02-09 18:52:01
export const MONTH_YEAR = 'MMMM YYYY';
export const DEFAULT_TIME_START = '09:00';
export const DEFAULT_TIME_END = '18:00';
export const DEFAULT_BREAK_TIME_START = '12:00:00';
export const DEFAULT_BREAK_TIME_END = '13:00:00';
