import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CREATE_STAFF_REPORT, hasPermission } from '@Utils/permissions';
import { getSectionUrl, navigate } from '@Utils/navigate';
import { getPermissions } from '@State/selectors';
import { createStaffReport } from '@State/report-actions';
import CreateStaffReportModal from './create-staff-report-modal';

const StaffReportToolbar = (props) => {
  const dispatch = useDispatch();
  const permissions = useSelector(state => getPermissions(state, props));
  const canCreateReport = hasPermission(permissions, CREATE_STAFF_REPORT);

  const [showCreateReport, setShowCreateReport] = useState(false);

  const handleCreateReport = useCallback((values) => {
    return dispatch(createStaffReport(values))
      .then((reportId) => {
        navigate(getSectionUrl(props.routeParams, 'reports/staff', reportId));
        setShowCreateReport(false);
      });
  }, []);

  return (
    <>
      {canCreateReport && (
        <button type="button" className="btn btn-cta" onClick={() => setShowCreateReport(true)}>
          Skapa löneunderlag
        </button>
      )}
      {showCreateReport && (
        <CreateStaffReportModal
          onClose={() => setShowCreateReport(false)}
          onSubmit={handleCreateReport}
        />
      )}
    </>
  );
};

export default StaffReportToolbar;
