import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Flex, Panel } from '@Components/ui/styled/main';
import { ColumnSwitch } from '@Components/admin/json-prefs/admin-config-style';
import LabelSwitchInput from '@Components/ui/label-switch-input';
import { setLocationFeature } from '@State/features-actions';

const AdminFeaturesConfigurations = () => {
  const dispatch = useDispatch();
  const features = useSelector(state => state.adminConfigState.get('features').toJS() || {});

  const onDispatchAdminConfiguration = (featureKey, enabled) => {
    return dispatch(setLocationFeature(featureKey, !enabled));
  };

  const LabelsData = [
    [
      {
        name: 'EnableVehicleRegistrySearch',
        enabled: features.EnableVehicleRegistrySearch
      },
      {
        name: 'EnableVehicleBooking',
        enabled: features.EnableVehicleBooking
      },
      {
        name: 'EnableCompanyBooking',
        enabled: features.EnableCompanyBooking
      },
      {
        name: 'EnableSmsPin',
        enabled: features.EnableSmsPin
      },
      {
        name: 'EnableSms',
        enabled: features.EnableSms
      },
      {
        name: 'EnableSmsConfirmations',
        enabled: features.EnableSmsConfirmations
      }
    ],
    [
      {
        name: 'EnableSmsReminders',
        enabled: features.EnableSmsReminders
      },
      {
        name: 'EnableBetaFeatures',
        enabled: features.EnableBetaFeatures
      },
      {
        name: 'EnableEcomPayments',
        enabled: features.EnableEcomPayments
      },
      {
        name: 'EnablePOS',
        enabled: features.EnablePOS
      },
      {
        name: 'ShowPOS',
        enabled: features.ShowPOS
      }
    ]
  ];

  return (
    <Panel>
      <Flex>
        {LabelsData.map((column, index) => (
          <ColumnSwitch key={index}>
            {column.map(({ name, enabled }) => (
              <LabelSwitchInput
                noBorder
                label={name}
                id={name}
                key={name}
                isOn={enabled}
                handleToggle={() => onDispatchAdminConfiguration(name, enabled)}
              />
            ))}
          </ColumnSwitch>
        ))}
      </Flex>
    </Panel>
  );
};

export default AdminFeaturesConfigurations;
