import React, { useEffect, useState } from 'react';

import CommentBox from '@Components/customers/customer-detail/logs/comment-box';
import useKeyPress from '@Hooks/useKeyPress';

const EventCommentPanel = ({
  isSelected, children, comment, files, onSave, onRemove, disabled, allowFiles
}) => {
  const [showCard, setShowCard] = useState(true);
  useKeyPress(27, () => handleCancel());
  useEffect(() => {
    if (isSelected) {
      setShowCard(false);
    }
  }, [isSelected]);

  const handleSaveComment = async (newComment, files) => {
    await onSave(newComment, files);
    setShowCard(true);
  };

  const handleCancel = () => setShowCard(true);
  const handleChange = () => setShowCard(false);

  return showCard ? (
    <div onClick={disabled ? null : handleChange}>
      {children}
    </div>
  ) : (
    <CommentBox
      active
      allowFiles={allowFiles}
      comment={comment}
      files={files}
      isShowRemove
      onRemove={onRemove}
      onSave={handleSaveComment}
      onCancel={handleCancel}
    />
  );
};

export default EventCommentPanel;
