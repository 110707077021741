import React from 'react';
import styled from 'styled-components';
import { txt } from '@Utils/i18n-util';
import { colors } from '@Components/ui/styled/variables';
import msg from './tb-search.msg';

const Form = styled.form`
  position:relative;
  border: 0;
  margin-top: 5px;
`;

const Input = styled.input`
  padding: 5px 24px 4px 30px;
  border: 0;
  width: 100%;
  outline: none;
  border-radius: 16px;
  background: ${colors.softGray80};
  ::placeholder {
    color: ${colors.textColor};
    font-size:12px;
  }
`;

const IconHolder = styled.span`
  position: absolute;
  left: 11px;
  top: 6px;
  color: ${colors.textColor};
  font-size: 12px;
  height: 12px;
  width: 12px;
`;

const ResetHolder = styled.a`
  position: absolute;
  right: 7px;
  top: 4px;
  color: ${colors.textMuted};
`;

const TBSearch = ({
  className, onSubmit, onClick, onChange, onKeyDown, onBlur, query, inputRef
}) => {
  return (
    <div>
      <Form onSubmit={onSubmit} className={className}>
        {query && <ResetHolder href="#" onClick={onClick}><i className="fa fa-times-circle" /></ResetHolder>}
        <IconHolder><i className="fa fa-search" /></IconHolder>
        <Input
          type="text"
          placeholder={txt(msg.placeholder)}
          value={query}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
          ref={inputRef}
        />
      </Form>
    </div>
  );
};

export default TBSearch;
