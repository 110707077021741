import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { useForm, FormProvider } from 'react-hook-form';

import { sendAccountReportEmail, uploadAccountReport } from '@State/sysadmin-actions';
import ButtonModalForm from '@Components/dialogs/button-modal-form';
import HookFormInput from '@Components/inputs/hook-form-input';
import FormButtonsFooter from '@Components/ui/form-buttons-footer';
import DialogAlert from '@Components/dialogs/dialog-alert';

const AccountReport = ({ upload = false }) => {
  const dispatch = useDispatch();
  const [reportSuccess, setReportSuccess] = useState(false);
  const methods = useForm();

  const options = useMemo(() => {
    const start = moment().startOf('month');
    const values = [];
    for (let i = 1; i <= 12; i++) {
      const month = moment(start).subtract(i, 'month');
      const periodKey = `M${month.format('M')}-${month.format('YYYY')}`;
      values.push({ value: periodKey, title: periodKey });
    }
    return values;
  }, []);

  const onSendReport = ({ email, periodKey }) => {
    const payload = { periodKey, recipient: { email } };
    return dispatch(sendAccountReportEmail(payload)).then(res => {
      methods.reset();
      if (res) setReportSuccess(email);
    });
  };

  const onUploadReport = ({ periodKey }) => {
    return dispatch(uploadAccountReport({ periodKey })).then(res => {
      methods.reset();
      if (res) setReportSuccess(true);
    });
  };

  return (
    <>
      {reportSuccess && (
        <DialogAlert
          success
          title=""
          icon="fa fa-check-circle"
          text={upload
            ? 'Rapporten har laddats upp till S3'
            : `Rapporten har mejlats till ${reportSuccess}`}
          onClose={() => setReportSuccess(false)}
          closeButtonText="Stäng"
        />
      )}
      <ButtonModalForm
        onSubmit={upload ? onUploadReport : onSendReport}
        btnText={upload ? 'Ladda upp kontorapport' : 'Mejla kontorapport'}
      >
        {({ onClose, onSubmit, loading }) => (
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              {!upload && (
                <HookFormInput
                  label="E-post"
                  id="email"
                  initialValue=""
                  type="text"
                  registerOptions={{ required: true }}
                />
              )}
              <div className="form-group">
                <HookFormInput
                  label="Period"
                  id="periodKey"
                  initialValue=""
                  type="select"
                  options={options}
                  registerOptions={{ required: true }}
                />
              </div>
              <FormButtonsFooter
                submitText={upload ? 'Ladda upp' : 'Skicka'}
                onClose={onClose}
                handleSubmit={methods.handleSubmit}
                submitSucceeded={false}
                submitting={loading}
              />
            </form>
          </FormProvider>
        )}
      </ButtonModalForm>
    </>
  );
};

export default AccountReport;
