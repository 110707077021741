import styled, { css } from 'styled-components';

import {
  colors, fs, fsw
} from '@Components/ui/styled/variables';
import { Button, Flex } from '@Components/ui/styled/main';

export const styleFlexWrapBottom = { marginBottom: '18px' };
export const styleDialogReceiptLastRow = { marginBottom: '18px' };

export const DialogLoaderCenterWrap = styled.div`
  position: relative;
  min-height: 200px;
`;

export const DialogAlertContainer = styled.div`
  margin: 40px 0;
  text-align: center;
`;

export const DialogAlertIcon = styled.i`
  font-size: 50px;
  margin-bottom: 10px;
  color: ${colors.grayLight};

  ${p => p.success && css`
    color: ${colors.labelSuccessText};
  `}
  ${p => p.warning && css`
    color: ${colors.eventWarning};
  `}
`;

export const DialogTitleRow = styled(Flex)`
  ${p => !p.leftBtn && css`
    margin-right: -50px;
  `}
`;

export const DialogTitleSaleItemRow = styled.div`
  display: block;
  justify-content: flex-start;
  color: #000;
  opacity: 0.67;
  font-size: 17px;
  margin-bottom: 5px;
  font-weight: 700;
`;

export const DialogTitleSaleItemBig = styled(DialogTitleSaleItemRow)`
  height: 55px;

  small {
    display: block;
    font-weight: 500;
  }
`;

export const DialogTitleBtn = styled.a`
  font-size: 15px;
  line-height: 20px;
  font-weight: 500;
  color: ${colors.textColor};
  text-decoration: none;
  cursor: pointer;
  min-width: 45px;

  &:first-child {
    margin-left: 5px;
  }
  &:last-child {
    margin-left: auto;
    margin-right: 5px;
    text-align: right;
  }
`;

export const DialogCloseX = styled.a`
  font-size: 20px;
  line-height: 20px;
  font-weight: 400;
  width: 50px;
  margin-top: -8px;
  margin-right: -5px;
  text-align: right;
  text-decoration: none;
  cursor: pointer;

  &, &:focus {
    color: ${colors.darkGray40};
  }
  &:hover, &:active {
    color: ${colors.darkGray60};
    text-decoration: none;
  }
`;

export const BtnBack = styled.span`
  width: 40px;
  height: 30px;
  margin-top: -3px;
  margin-right: 20px;
  border-radius: 4px;
  background-color: ${colors.grayLighter};
  color: ${colors.gray};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const GrayText = styled.p`
  color: ${colors.textMuted};
  font-weight: normal;
`;

export const BoldText = styled.p`
  font-weight: 500;
`;

export const BodyWrap = styled.div`
  border-radius: 8px;
  margin-bottom: 22px;
  background-color: rgba(0, 0, 0, 0.03);
  padding: 16px;
  .f2 {
    flex: 2;
  }
  .text-block {
    margin-bottom: 16px;
    span {
      font-weight: 500;
      color: ${colors.textMuted};
    }
    p {
      font-weight: normal;
      color: #000;
      margin-bottom: 0;
    }
  }
`;

export const DialogCenterRowWrap = styled.div`
  margin: 20px;
  text-align: center;
`;

export const DialogConfirmText = styled.div`
  text-align: center;
  font-size: 13px;
  margin-bottom: 16px;
  color: ${colors.gray};
  font-weight: ${fsw.bold};
`;

export const DialogButtonsVertical = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-top: 6px;
`;

export const DialogButtonsHorizontal = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    flex: 1;
    min-width: auto;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const DialogButton = styled(Button)`
  min-width: 150px;
  margin-top: ${p => p.marginTop ? '26px' : '10px'};
  line-height: 1em;
  height: 35px;

  ${p => p.small && css`
    border-radius: 5px;
    font-size: 13px;
    height: 30px;
  `}
`;

export const DialogCenterRow = styled.div`
  display: flex;
  align-items: center;
  .label-text {
    font-size: 15px !important;
  }
`;

export const DialogReceiptFooterWrap = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-left: 8px;
  padding-right: 12px;
  margin: 0 0 10px 0;
  font-weight: ${fsw.medium};
  font-size: ${fs.xl};
`;

export const DialogReceiptContentWrap = styled.div`
  max-height: 250px;
  min-height: 100px;
  overflow-y: scroll;
  border-radius: 8px;
  background-color: ${colors.grayLightest};
  padding: 15px;
  margin-bottom: 15px;
  line-height: 24px;
`;

export const DialogReceiptFullRow = styled.div`
  display: flex;
  font-size: 15px;
  line-height: 22px;
`;

export const DialogReceiptLeftPos = styled.div`
   margin-left: auto;
`;

export const DialogReceiptBold = styled.span`
  font-weight: ${fsw.medium};
`;

export const DialogContent = styled.div`
  font-size: 15px;
  padding: 10px 0;

  ${p => p.center && css`
    text-align: center;
  `};
`;

export const DialogLabel = styled.div`
  font-size: 13px;
  color: ${colors.grayCircle};
  flex: 1;
  div, p {
    text-transform: none;
    font-size: 30px;
    line-height: 30px;
    color: #000;
    margin: 0;
  }
`;

export const DialogTitle = styled.div`
  display: flex;
  width: 100%;
  margin: 0 0 15px 0;
  font-size: ${props => props.large ? '17px' : '15px'};
  font-weight: 700;
  color: ${colors.textColor};
  justify-content: ${props => props.align || 'center'};
  text-align: ${props => props.textAlign || 'left'};
`;

export const DialogInputPin = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  span {
    display: block;
    width: 12px;
    height: 12px;
    border: 2px solid #475561;
    border-radius: 100%;
    margin: 5px 10px;
    transition: background 0.05s ease-out;

    &.is-selected {
      background: #475561;
    }
  }
`;

export const ModalContent = styled.div`
  font-size: 13px;
`;

export const ModalFooter = styled.div`
  margin-top: 20px;
  position: relative;
  justify-content: flex-end;
  text-align: right;
`;
