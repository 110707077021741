import { List, fromJS } from 'immutable';
import { CLEAR_LOCATION_STATE } from '@State/account-actions';
import {
  VOUCHER_TEMPLATES_FETCHED, VOUCHER_TEMPLATE_CREATED,
  VOUCHER_TEMPLATE_UPDATED, VOUCHER_TEMPLATE_DELETED
} from './voucher-template-actions';

export function voucherTemplates(state = List(), action = null) {
  switch (action.type) {
    case VOUCHER_TEMPLATES_FETCHED:
      return fromJS(action.templates);

    case VOUCHER_TEMPLATE_CREATED:
      return state.push(fromJS(action.template));

    case VOUCHER_TEMPLATE_UPDATED: {
      const index = state.findIndex(t => t.get('id') === action.template.id);
      if (index !== -1) {
        return state.set(index, fromJS(action.template));
      }
      return state;
    }

    case VOUCHER_TEMPLATE_DELETED: {
      const index = state.findIndex(t => t.get('id') === action.templateId);
      if (index !== -1) {
        return state.delete(index);
      }
      return state;
    }

    case CLEAR_LOCATION_STATE:
      return state.clear();

    default:
      return state;
  }
}
