import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { isPosUnitClosed, isPosUnitOpenOtherDevice } from '@Utils/pos-utils';
import { switchPosUnitDevice } from '@State/pos-config-actions';
import {
  IconWrapper, Wrapper, BlockStyled, PosInfoBlock
} from '@Components/pos/style';
import {
  Button, Column, Flex, Panel
} from '@Components/ui/styled/main';
import DialogPosOpen from '@Components/pos/dialogs/dialog-pos-open';
import ProgressButton from '@Components/ui/progress-button';
import AlertWithIcon from '@Components/ui/alert-with-icon';

function getBrowserIcon(browser) {
  if (browser && browser.indexOf('Safari') !== -1) {
    return '/browsers/safari.svg';
  }
  if (browser && browser.indexOf('Chrome') !== -1) {
    return '/browsers/chrome.svg';
  }
  if (browser && browser.indexOf('Firefox') !== -1) {
    return '/browsers/firefox.svg';
  }
  if (browser && browser.indexOf('Edge') !== -1) {
    return '/browsers/edge.svg';
  }
  return '/browsers/unknown.svg';
}

const Logo = styled.img`
  height: 36px;
  width: 36px;
  margin: 2px 15px 2px 0;
`;

const PosUnitNotAvailable = ({ posUnit, routeParams }) => {
  const dispatch = useDispatch();

  const [error, setError] = useState(null);
  const [showOpen, setShowOpen] = useState(false);

  const isClosed = isPosUnitClosed(posUnit);
  const isOpenOtherDevice = isPosUnitOpenOtherDevice(posUnit);
  const isOnprem = posUnit.get('type').includes('Onprem');
  const userAgentFields = posUnit.get('userAgentFields').toObject();

  const { OpName, OperatingSystemName, AgentName, IP } = userAgentFields || {};
  const openOnDevice = AgentName ? `${AgentName} på ${OperatingSystemName} (${IP})` : 'Okänd enhet';

  const handleCloseDialogOpen = () => setShowOpen(false);
  const handleShowDialogOpen = () => setShowOpen(true);

  const handleSwitchDeviceToken = () => {
    return dispatch(switchPosUnitDevice(posUnit.get('vunitId')))
      .catch(error => setError(error));
  };

  if (showOpen) {
    return (
      <DialogPosOpen
        routeParams={routeParams}
        onClose={handleCloseDialogOpen}
      />
    );
  }

  return (
    <div className="pos unavailable">
      {isClosed && (
        <BlockStyled>
          <h3>Kassan är stängd</h3>
          <br />
          <Button primary large disabled={!isOnprem} onClick={handleShowDialogOpen}>Öppna kassa</Button>
        </BlockStyled>
      )}
      {isOpenOtherDevice && (
        <BlockStyled>
          <Wrapper>
            <Panel bgColor="#fff">
              <h3>
                <IconWrapper><i className="far fa-exclamation-circle" /></IconWrapper>
                Kassan används på en annan enhet
              </h3>
              <PosInfoBlock>
                <Panel>
                  <Flex>
                    <Logo src={getBrowserIcon(AgentName)} alt={AgentName} />
                    <Column>
                      <p><strong>{OpName}</strong></p>
                      <p>{openOnDevice}</p>
                    </Column>
                  </Flex>
                </Panel>
                {error && (
                  <AlertWithIcon error>
                    Köp pågår! Vänta tills köpet är klart och försök igen.
                  </AlertWithIcon>
                )}
              </PosInfoBlock>
              <div className="text-center mb3">
                <ProgressButton outlinePrimary small onClick={handleSwitchDeviceToken}>
                  Använd på denna enhet
                </ProgressButton>
              </div>
            </Panel>
          </Wrapper>
        </BlockStyled>
      )}
    </div>
  );
};

PosUnitNotAvailable.propTypes = {
  routeParams: PropTypes.object
};

export default PosUnitNotAvailable;
