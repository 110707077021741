import React from 'react';
import { useSelector } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { maxValue, minValue, required } from '@Utils/validation';
import { getEmploymentTypeDescription } from '@Utils/users';
import { getVatRates } from '@State/selectors';
import TextInput from '@Components/inputs/text-input';
import SelectField from '@Components/inputs/select-field';
import FormButtonsFooter from '@Components/ui/form-buttons-footer';

const formSelector = formValueSelector('staff-employment-form');

const StaffEmploymentForm = ({
  pristine, submitting, submitSucceeded, handleSubmit, onClose
}) => {
  const vatRates = useSelector(getVatRates);
  const employmentType = useSelector(state => formSelector(state, 'employmentType'));

  return (
    <form autoComplete="off" className="dialog" onSubmit={handleSubmit}>
      <Field
        name="employmentType"
        component={SelectField}
        label="Avtal"
        validate={[required]}
      >
        <option value="">Välj avtal</option>
        {['FullTime', 'PartTime', 'SelfEmployed'].map(type => (
          <option value={type}>{getEmploymentTypeDescription(type)}</option>
        ))}
      </Field>
      {employmentType === 'SelfEmployed' ? (
        <>
          <Field
            type="number"
            name="monthlyRent"
            component={TextInput}
            label="Månadshyra (exkl. moms)"
            unitLabel="kr"
          />
          <Field name="rentVatPct" component={SelectField} label="Moms månadshyra" width={100}>
            {vatRates.map(vat => <option key={vat} value={vat}>{`${vat}%`}</option>)}
          </Field>
        </>
      ) : (
        <>
          <Field
            type="number"
            name="workHoursPerWeek"
            component={TextInput}
            label="Timmar per vecka"
            validate={[required, minValue(0), maxValue(80)]}
          />
          <Field
            type="number"
            name="workDaysPerWeek"
            component={TextInput}
            label="Dagar per vecka"
            validate={[required, minValue(1), maxValue(7)]}
          />
          <Field
            type="number"
            name="lunchMinutesPerDay"
            component={TextInput}
            label="Lunch (minuter/dag)"
            validate={[required, minValue(0), maxValue(240)]}
          />
        </>
      )}
      <FormButtonsFooter
        onClose={onClose}
        pristine={pristine}
        submitting={submitting}
        submitSucceeded={submitSucceeded}
        handleSubmit={handleSubmit}
        submitText="Spara"
      />
    </form>
  );
};

export default reduxForm({
  form: 'staff-employment-form'
})(StaffEmploymentForm);
