import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Popover from '@Components/ui/popover';
import Button from '@Components/ui/button';
import ConfirmBlock from '@Components/ui/confirm-block';

const ScheduleTimelinePopover = ({
  children, enableDelete, onConfirmDelete, onDuplicateSchedule, ...props
}) => {
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const hideConfirm = () => setShowConfirmDelete(false);
  const showConfirm = () => setShowConfirmDelete(true);

  useEffect(() => {
    setShowConfirmDelete(false);
  }, [props.isOpen]);

  return (
    <Popover
      className="Popover-big-radius"
      {...props}
      body={(
        <div className="Popover-content-small">
          {showConfirmDelete ? (
            <ConfirmBlock
              title="Bekräfta att du vill radera schemat"
              onCancel={hideConfirm}
              onConfirm={onConfirmDelete}
            />
          ) : (
            <div className="button-list">
              <Button small outlinePrimary onClick={onDuplicateSchedule}>Duplicera schema</Button>
              {enableDelete && <Button small className="btn-delete" onClick={showConfirm}>Radera schema</Button>}
            </div>
          )}
        </div>
      )}
    >
      {children}
    </Popover>
  );
};

ScheduleTimelinePopover.propTypes = {
  enableDelete: PropTypes.bool,
  onConfirmDelete: PropTypes.func,
  onDuplicateSchedule: PropTypes.func
};

export default ScheduleTimelinePopover;
