import styled from 'styled-components';

export default styled.div`
    max-width: ${props => props.maxWidth ? `${props.maxWidth}px` : 'auto'};
    width: ${props => !props.isSmallMode && props.width ? `${props.width}px` : 'auto'};
    height: ${props => props.height ? `${props.height}px` : 'auto'};
    white-space: ${props => !props.nowrap ? 'nowrap' : 'none'};
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: ${props => props.align ? props.align : 'left'};
    -webkit-line-clamp:  ${props => props.lineClamp ? props.lineClamp : 2};
    -webkit-box-orient: vertical;
    align-items: baseline;
`;
