import PropTypes from 'prop-types';
import React, { Component } from 'react';
import NavTabsLink from '@Components/ui/nav-tabs-link';

class PartnerConfigTabs extends Component {
  static propTypes = {
    routeParams: PropTypes.object.isRequired
  };

  sections = [
    { navId: 'prefs', name: 'Prefs' },
    { navId: 'custom-texts', name: 'Custom texts' }
  ];

  render() {
    const { routeParams } = this.props;
    return (
      <NavTabsLink
        subUrl="admin/partners"
        routeParams={routeParams}
        sections={this.sections}
      />
    );
  }
}

export default PartnerConfigTabs;
