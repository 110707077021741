import React, { Component } from 'react';
import { connect } from 'react-redux';
import { config, sms } from '@Utils/preference-keys';
import { isMobile, formatPhoneNumber, formatPhoneNumberE164 } from '@Utils/phone-util';
import { getSmsEnabled, getFeatures, getRouteParams } from '@State/selectors';
import { getLongestName, templatePreviewValues } from '@Utils/templates';
import { getJsonPreferences, mergeJsonPreferences, getInitialValues } from '@State/preferences-actions';
import SmsConfigForm from './sms-config-form';
import FeatureNotAvailable from '../../dialogs/feature-not-available';

class SmsConfig extends Component {
  componentDidMount() {
    this.props.getSmsConfig();
  }

  render() {
    const { features, routeParams } = this.props;

    if (features.EnableSms) {
      return this.renderFeature();
    }

    return (
      <FeatureNotAvailable
        leadText="SMS-inställningar är inte tillgängligt för ditt abonnemang"
        routeParams={routeParams}
        showLink
      />
    );
  }

  renderFeature() {
    return this.props.smsEnabled
      ? this.renderConfigForm()
      : this.renderSmsDisabledInfo();
  }

  renderConfigForm() {
    const {
      smsConfirmPrice, features, initialValues, previewValues, saveSmsConfig
    } = this.props;

    return (
      <div className="subnav-container">
        <SmsConfigForm
          initialValues={initialValues}
          smsConfirmPrice={smsConfirmPrice}
          features={features}
          previewValues={previewValues}
          onSubmit={saveSmsConfig}
        />
      </div>
    );
  }

  renderSmsDisabledInfo() {
    const { routeParams } = this.props;
    return (
      <FeatureNotAvailable
        leadText="SMS är inte aktiverat för kontot"
        routeParams={routeParams}
        showLink
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { locationConfig, resourcesById, servicesById } = state;

  const smsConfirmPrice = locationConfig.get(config.smsPrices).find((smsPrice) => {
    return smsPrice.get('priceType') === 'Confirm';
  });

  const resourceName = getLongestName(resourcesById, 'webName');
  const serviceName = getLongestName(servicesById);
  const previewValues = templatePreviewValues({
    resourceName, serviceName, locationConfig
  });

  const initialValues = getInitialValues(state, sms);
  if (initialValues && isMobile(initialValues[sms.smsSender])) {
    initialValues[sms.smsSender] = formatPhoneNumber(initialValues[sms.smsSender]);
  }

  return {
    initialValues,
    features: getFeatures(state),
    smsConfirmPrice: smsConfirmPrice ? smsConfirmPrice.get('price') : null,
    smsEnabled: getSmsEnabled(state),
    routeParams: getRouteParams(state, ownProps),
    previewValues
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSmsConfig: () => {
      dispatch(getJsonPreferences());
    },
    saveSmsConfig: (values) => {
      if (isMobile(values[sms.smsSender])) {
        values[sms.smsSender] = formatPhoneNumberE164(values[sms.smsSender]);
      }
      return dispatch(mergeJsonPreferences(values));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SmsConfig);
