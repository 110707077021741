import React, { Component } from 'react';
import Backspace from '@Components/inputs/backspace';
import { classes } from '@Components/ui/utils';

class Numpad extends Component {
  constructor(props) {
    super(props);

    const bottomLeft = props.pinPad || props.hideZeros ? '' : '00';
    this.numbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', bottomLeft, '0'];
    this.allowedInput = [...this.numbers, 'Backspace', 'Enter'];

    this.handleBackspace = this.handleBackspace.bind(this);
    this.handleNumerical = this.handleNumerical.bind(this);
  }

  componentDidMount() {
    if (window) {
      window.addEventListener('keydown', this.handleKeyDown);
    }
  }

  componentWillUnmount() {
    if (window) {
      window.removeEventListener('keydown', this.handleKeyDown);
    }
  }

  handleKeyDown = e => {
    if (!this.allowedInput.includes(e.key)) return;

    if (!this.props.disabled) {
      e.preventDefault();

      switch (e.key) {
        case 'Backspace':
          this.handleBackspace();
          break;
        case 'Enter':
          this.handleEnter();
          break;
        default:
          this.handleNumerical(e.key);
      }
    }
  };

  handleBackspace = () => {
    const { input, pinPad } = this.props;
    this.props.onChange(pinPad ? '' : input.slice(0, -1));
  };

  handleEnter = () => this.props.onEnter && this.props.onEnter();

  handleNumerical = key => {
    const { input, isPristine } = this.props;
    const initialValue = isPristine ? '' : input;
    this.props.onChange(initialValue.concat(key));
  };

  render() {
    const {
      pinPad, btnTransparent, disabled, backspace
    } = this.props;
    const className = classes({
      numpad: true,
      disabled,
      btnTransparent
    });

    return (
      <div className={className}>
        {this.numbers.map(num => {
          return num !== '' ? (
            <button
              key={num}
              disabled={disabled}
              onClick={() => this.handleNumerical(num)}
            >{num}
            </button>
          ) : (
            <p key="empty" />
          );
        })}
        <button
          className={pinPad ? 'clear' : ''}
          disabled={disabled}
          onClick={() => this.handleBackspace()}
        >
          { backspace || <Backspace />}
        </button>
      </div>
    );
  }
}

export default Numpad;
