import React, { useEffect } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { change, Field, formValueSelector, reduxForm } from 'redux-form';
import { pos } from '@Utils/preference-keys';
import { required } from '@Utils/validation';
import TextInput from '@Components/inputs/text-input';
import FormButtonsFooter from '@Components/ui/form-buttons-footer';
import DatePickerField from '@Components/ui/date-picker/date-picker-field';
import PosInvoiceDueDaysField from '@Components/admin/pos-config/pos-invoice-due-days-field';
import ProgressButton from '@Components/ui/progress-button';
import AlertWithIcon from '@Components/ui/alert-with-icon';
import CheckBoxEl from '@Components/inputs/checkbox-styled';
import Panel from '@Components/ui/styled/panel';
import { invoiceCustomerValid } from '@Utils/invoice-util';
import InvoiceSummaryPanel from './invoice-summary-panel';

const formSelector = formValueSelector('invoice-form');

function getSubmitText(useEmailConfig, emailInvoice, useEfaktura) {
  if (useEmailConfig && emailInvoice) {
    return 'Fortsätt';
  }
  return emailInvoice || useEfaktura
    ? 'Skapa och skicka faktura'
    : 'Skapa faktura';
}

const InvoiceForm = ({
  submitting, submitSucceeded, handleSubmit, onClose, selectedCustomer, customerRef,
  totalAmount, grossAmount, saleItems, form, posOrgId, invoicePrefs, billingContact,
  useEmailConfig, submitError, onCreateCustomer
}) => {
  const dispatch = useDispatch();
  const setValue = (prop, value) => dispatch(change(form, prop, value));
  const invoiceDate = useSelector(state => formSelector(state, 'invoiceDate'));
  const dueDays = useSelector(state => formSelector(state, 'invoiceDueDays'));
  const emailInvoice = useSelector(state => formSelector(state, 'emailInvoice'));
  const useFortnox = invoicePrefs[pos.invoiceUseFortnox];
  const fortnoxBookAndSend = invoicePrefs[pos.invoiceFortnoxBookAndSend];
  const isFortnoxCustomer = selectedCustomer?.get('fortnoxCustomerNumber');
  const fortnoxCustomerError = useFortnox && selectedCustomer && !isFortnoxCustomer;
  const customerValidationError = selectedCustomer && !invoiceCustomerValid(selectedCustomer, invoicePrefs);
  const submitDisabled = fortnoxCustomerError || customerValidationError;
  const emailEnabled = !useFortnox || fortnoxBookAndSend;
  const useEfaktura = useFortnox && selectedCustomer?.get('invoiceMethod') === 'EFaktura';

  useEffect(() => {
    const newCustomerRef = customerRef || selectedCustomer?.get('customerRef');
    if (newCustomerRef) {
      setValue('customerRef', newCustomerRef);
    }
    setValue('emailInvoice', emailEnabled && selectedCustomer?.get('email')
      && selectedCustomer?.get('invoiceMethod') === 'Email');
  }, [selectedCustomer]);

  const refreshDueDate = (newInvoiceDate, newDueDays) => {
    const newDueDate = moment(newInvoiceDate, 'L').add(newDueDays, 'd');
    setValue('invoiceDueDate', newDueDate.format('L'));
  };

  const handleInvoiceDate = (date) => {
    const newInvoiceDate = moment(date);
    refreshDueDate(newInvoiceDate, dueDays);
  };

  const handleDueDate = (date) => {
    const newDueDate = moment(date, 'L');
    const newDueDays = newDueDate.diff(invoiceDate, 'd');
    setValue('invoiceDueDays', newDueDays);
  };

  const handleDueDays = (ev) => {
    refreshDueDate(invoiceDate, ev.target.value);
  };

  const columnClass = useFortnox ? 'col-xs-6' : 'col-xs-4';

  return (
    <form autoComplete="off" className="dialog" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-xs-6">
          <Field
            name="invoiceDate"
            component={DatePickerField}
            label="Fakturadatum"
            onChange={handleInvoiceDate}
          />
        </div>
        <div className="col-xs-6">
          <Field
            name="invoiceDueDate"
            component={DatePickerField}
            label="Förfallodatum"
            onChange={handleDueDate}
          />
        </div>
      </div>
      <div className="row">
        <div className={columnClass}>
          <PosInvoiceDueDaysField
            name="invoiceDueDays"
            onChange={handleDueDays}
            posOrgId={posOrgId}
            invoicePrefs={invoicePrefs}
            shortLabel
          />
        </div>
        {!useFortnox && (
          <div className={columnClass}>
            <Field
              type="number"
              name="latePaymentInterest"
              component={TextInput}
              label="Dröjsmålsränta"
              validate={[required]}
            />
          </div>
        )}
        <div className={columnClass}>
          <Field
            type="text"
            name="customerRef"
            component={TextInput}
            label="Er referens"
          />
        </div>
      </div>
      <InvoiceSummaryPanel
        grossAmount={grossAmount}
        totalAmount={totalAmount}
        saleItems={saleItems}
      />
      {!submitDisabled && useEfaktura && (
        <AlertWithIcon icon="fa fa-info-circle">
          Fakturan kommer att skickas som E-faktura
        </AlertWithIcon>
      )}
      {!submitDisabled && emailEnabled && !useEfaktura && selectedCustomer?.get('email') && (
        <Panel>
          <Field
            name="emailInvoice"
            component={CheckBoxEl}
            label={`Skicka fakturan med e-post till ${selectedCustomer.get('email')}`}
          >
            {!useFortnox && invoicePrefs[pos.invoiceCopyToBillingContact] && billingContact?.email && (
              <div className="text-muted mt1">
                En kopia av fakturan kommer skickas till {billingContact.email}.
              </div>
            )}
          </Field>
        </Panel>
      )}
      {fortnoxCustomerError && (
        <AlertWithIcon
          button={(
            <ProgressButton small primary onClick={onCreateCustomer}>Skapa kund</ProgressButton>
          )}
        >
          Kund måste finnas i Fortnox innan faktura kan skapas
        </AlertWithIcon>
      )}
      {customerValidationError && (
        <AlertWithIcon warning>
          Mottagaren har inte alla obligatoriska uppgifter ifyllda. Faktura kan inte skapas.
        </AlertWithIcon>
      )}
      {submitError && (
        <AlertWithIcon error>
          Kunde inte skapa faktura. {submitError.response?.data?.message || submitError.message}
        </AlertWithIcon>
      )}
      <FormButtonsFooter
        onClose={onClose}
        pristine={!selectedCustomer}
        submitting={submitting}
        submitSucceeded={submitSucceeded}
        submitDisabled={submitDisabled}
        handleSubmit={handleSubmit}
        submitText={getSubmitText(useEmailConfig, emailInvoice, useEfaktura)}
      />
    </form>
  );
};

export default reduxForm({
  form: 'invoice-form',
  destroyOnUnmount: false
})(InvoiceForm);
