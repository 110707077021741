import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Popover from '@Components/ui/popover';
import { createDelivery } from '@State/delivery-actions';
import { getDefaultDateLabel } from '@Components/reports/reports-helpers';
import { getSectionUrl, navigate } from '@Utils/navigate';
import { toggleProductsDelivery } from '@State/admin-view-reducer';
import AddItemForm from '../../sortable-list/add-item-form';

class DeliveryToolbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addDelivery: false
    };
  }

  render() {
    return (
      <>
        {this.renderAddDeliveryButton()}
      </>
    );
  }

  renderAddDeliveryButton() {
    return (
      <Popover
        isOpen={this.state.addDelivery}
        body={this.addDeliveryContent()}
        preferPlace="below"
        className="Popover-big-radius"
        onOuterAction={this.handleCloseDeliveryPopover}
      >
        <button type="button" className="btn btn-cta" onClick={this.handleOpenDeliveryPopover}>Ny inleverans</button>
      </Popover>
    );
  }

  handleOpenDeliveryPopover = () => {
    this.setState({ addDelivery: true });
  };

  handleCloseDeliveryPopover = () => {
    this.setState({ addDelivery: false });
  };

  handleAddDelivery = (group, label) => {
    return this.props
      .onAddDelivery({ label, deliveryDate: moment().format('YYYY-MM-DD') })
      .then(() => {
        this.handleCloseDeliveryPopover();
        this.props.onToggleDelivery(true);
      });
  };

  addDeliveryContent() {
    return (
      <AddItemForm
        onClosePopover={this.handleCloseDeliveryPopover}
        onAddItem={this.handleAddDelivery}
        placeholder="Ange namn på inleveransen"
        name={getDefaultDateLabel('Inleverans')}
        submitText="Skapa"
      />
    );
  }
}

const mapStateToProps = (state) => {
  const { deliveries } = state;

  return {
    deliveries
  };
};

const mapDispatchToProps = (dispatch, { routeParams }) => {
  return {
    onAddDelivery: (delivery) => {
      return dispatch(createDelivery(delivery))
        .then(id => navigate(getSectionUrl(routeParams, 'admin/delivery', id)));
    },
    onToggleDelivery: (delivery) => {
      return dispatch(toggleProductsDelivery(delivery));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryToolbar);
