import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchPrinters, mergePosUnitPrefs } from '@State/pos-config-actions';
import { getOnpremPosUnits } from '@State/pos-config-selectors';
import { getSysAdmin } from '@State/selectors';
import { pos } from '@Utils/preference-keys';
import { GrayLabelText } from '@Components/admin/pos-config/style';
import { Button, Panel } from '@Components/ui/styled/main';
import PrinterPanel from './printer-panel';
import PrinterAssociateForm from './printer-associate-form';

const PosOrgPrinters = () => {
  const dispatch = useDispatch();
  const isSysAdmin = useSelector(getSysAdmin);
  const printers = useSelector(state => state.printers);
  const allOnpremPosUnits = useSelector(state => state.allOnpremPosUnits);
  const posUnits = useSelector(getOnpremPosUnits);
  const [associateFormId, showAssociateForm] = useState(null);

  useEffect(() => {
    dispatch(fetchPrinters());
  }, []);

  const hideForm = (ev) => {
    ev && ev.preventDefault();
    showAssociateForm(null);
  };

  const associatePrinter = ({ vunitId, printerId }) => {
    return dispatch(mergePosUnitPrefs(vunitId, {
      [pos.defaultPrinterId]: parseInt(printerId)
    })).then(() => hideForm());
  };

  return (
    <div className="mb2">
      <GrayLabelText>Skrivare</GrayLabelText>
      {printers && posUnits && posUnits.map((posUnit) => {
        const vunitId = posUnit.get('id');
        const printerId = posUnit.getIn(['prefs', pos.defaultPrinterId]);
        if (printerId) {
          const printer = printers.find(t => t.get('id') === parseInt(printerId));
          if (printer) {
            return (
              <PrinterPanel key={vunitId} printer={printer} posUnit={posUnit} />
            );
          }
        }
        return (
          <Panel key={vunitId}>
            <p>Ingen skrivare kopplad till {posUnit.get('posOrgName')} ({posUnit.get('name')})</p>
            {isSysAdmin && (
              <Button small outlinePrimary onClick={() => showAssociateForm(vunitId)}>Koppla skrivare</Button>
            )}
          </Panel>
        );
      })}
      {associateFormId && (
        <PrinterAssociateForm
          onSubmit={associatePrinter}
          onClose={hideForm}
          printers={printers}
          posUnits={allOnpremPosUnits}
          initialValues={{
            vunitId: associateFormId
          }}
        />
      )}
    </div>
  );
};

export default PosOrgPrinters;
