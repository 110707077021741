import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';

import { email, required } from '@Utils/validation';
import TextInput from '@Components/inputs/text-input';
import ModalDialog from '@Components/dialogs/modal-dialog';
import LabelSwitchInput from '@Components/ui/label-switch-input';

const PosOrgContactsModal = ({ onClose, handleSubmit, onSubmit, initialValues, submitting }) => {
  const [enableBilling, setEnableBilling] = useState(initialValues.enableBilling);

  const onUpdateContacts = (values) => onSubmit({
    ...values,
    enableBilling
  });

  return (
    <ModalDialog
      contentSize="small"
      onClose={onClose}
      title="Ändra kontaktuppgifter"
      buttons={[{
        name: 'Spara',
        primary: true,
        type: 'submit',
        loading: submitting,
        onClick: handleSubmit(onUpdateContacts)
      }]}
    >
      <form onSubmit={handleSubmit(onUpdateContacts)} className="dialog">
        <div className="form-group">
          <Field name="ownerName" component={TextInput} label="Namn" validate={required} />
          <Field name="ownerEmail" component={TextInput} label="E-post" validate={[required, email]} />
          <Field name="ownerPhone" component={TextInput} label="Telefon" labelHint="(valfritt)" />
        </div>
        <LabelSwitchInput
          noBorder
          label="Extra kontakt för redovisning"
          id="showBilling"
          isOn={enableBilling}
          handleToggle={() => setEnableBilling(!enableBilling)}
        />
        {enableBilling && (
          <div className="form-group">
            <Field name="billingName" component={TextInput} label="Namn" validate={required} />
            <Field name="billingEmail" component={TextInput} label="E-post" validate={[required, email]} />
            <Field name="billingPhone" component={TextInput} label="Telefon" labelHint="(valfritt)" />
          </div>
        )}
      </form>
    </ModalDialog>
  );
};

export default reduxForm({
  form: 'pos-org-contacts'
})(PosOrgContactsModal);
