import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getIsMultiResourceView } from '@State/calendar-selectors';
import { isEmbeddedInApp } from '@Utils/embedded-util';
import { txt } from '@Utils/i18n-util';
import msg from './multi-resource-banner.msg';

class MultiResourceBanner extends Component {
  static propTypes = {
    showMultiResourceBanner: PropTypes.bool.isRequired,
    phoneMode: PropTypes.bool.isRequired
  };

  componentDidUpdate(prevProps) {
    if (prevProps.showMultiResourceBanner !== this.props.showMultiResourceBanner) {
      this.props.updateViewDimensions();
    }
  }

  render() {
    const { showMultiResourceBanner, phoneMode } = this.props;

    if (!showMultiResourceBanner) {
      return null;
    }

    const style = { fontSize: phoneMode ? '0.8em' : '1.0em' };
    const text = phoneMode ? txt(msg.compactText) : txt(msg.text);

    return (
      <div id="gridbanner" className="info-banner">
        <i className="far fa-circle-info" /><span style={style}>{text}</span>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const embeddedInApp = isEmbeddedInApp(state);
  const isMultiResourceView = getIsMultiResourceView(state, ownProps);

  return {
    showMultiResourceBanner: !embeddedInApp && isMultiResourceView,
    phoneMode: state.mainViewState.get('phoneMode')
  };
};

export default connect(mapStateToProps)(MultiResourceBanner);
