import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import ButtonModalForm from '@Components/dialogs/button-modal-form';
import DialogAlert from '@Components/dialogs/dialog-alert';
import { importCsvArticles } from '@State/import-actions';
import FormButtonsFooter from '@Components/ui/form-buttons-footer';
import FileUploadField from './file-upload-field';

const AccountImportArticles = ({ location }) => {
  const dispatch = useDispatch();
  const [importSuccess, setImportSuccess] = useState(false);
  const methods = useForm();

  const onImportArticles = ({ file }) => {
    return dispatch(importCsvArticles(location.get('orgCtxName'), location.get('ctxName'), file))
      .then(() => {
        setImportSuccess(true);
        methods.reset();
      });
  };

  return (
    <>
      {importSuccess && (
        <DialogAlert
          success
          title=""
          icon="fa fa-check-circle"
          text={(
            <div>
              Produkterna importerades
            </div>
          )}
          onClose={() => setImportSuccess(false)}
          closeButtonText="Stäng"
        />
      )}
      <ButtonModalForm
        dialogProps={{
          title: 'Importera produkter',
          contentSize: 'large'
        }}
        onClose={() => methods.reset()}
        onSubmit={onImportArticles}
        btnText="Importera produkter"
      >
        {({ onClose, onSubmit, loading }) => (
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <FileUploadField
                value={methods.watch('file')}
                onChange={file => methods.setValue('file', file)}
              />
              <FormButtonsFooter
                submitText="Importera produkter"
                submitting={loading}
                onClose={onClose}
              />
            </form>
          </FormProvider>
        )}
      </ButtonModalForm>
    </>
  );
};

export default AccountImportArticles;
