import styled, { css } from 'styled-components';
import { colors } from '@Components/ui/styled/variables';

export default styled.div`
  font-weight: ${({ fontWeight }) => fontWeight ? `${fontWeight}` : 'normal'};
  font-size: ${({ fs }) => fs ? `${fs}px` : '13px'};
  color: ${({ color }) => color || '#000'};
  padding-bottom: ${({ pb }) => pb ? `${pb}px` : '0px'};
  line-height: ${({ lHeight }) => lHeight ? `${lHeight}px` : 'inherit'};
  opacity: ${({ op }) => op ? `${op}` : 1};
  display: ${({ display }) => display || 'block'};
  ${({ center }) => center && css`
    text-align: center;
  `};
  ${({ marginTop }) => marginTop && css`
    margin-top: ${marginTop};
  `};
  ${({ marginBottom }) => marginBottom && css`
    margin-bottom: 10px;
  `};
  ${({ pullLeftPadding }) => pullLeftPadding && css`
     padding-left: 10px;
     margin-bottom: 4px;
  `};
  ${({ bold }) => bold && css`
    font-weight: 500;
  `};
  ${({ muted }) => muted && css`
    color: ${colors.textMuted};
  `};
  ${({ dark }) => dark && css`
    color: ${colors.darkGray};
  `};
  ${({ primary }) => primary && css`
    color: ${colors.brandBlue};
  `};
  ${({ danger }) => danger && css`
    color: ${colors.danger};
  `};
`;
