import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { pos } from '@Utils/preference-keys';
import { getProductDescription } from '@Utils/pos-utils';
import CurrencyUtil from '@Utils/currency-util';
import { classes } from '@Components/ui/utils';

const getStyleTapItem = (disabled, color) => ({
  cursor: disabled ? 'default' : 'pointer',
  borderColor: color ?? '#d6d6d6'
});

const ProductListItem = ({ onProductClick, item, isDisabled, groupColor, posUnitPrefs }) => {
  const { type, color, priceOut, price, prefs, comment, stocked } = item;
  const { colorway } = prefs || {};
  const showQuantity = stocked && posUnitPrefs && posUnitPrefs[pos.showProductStockQuantity];
  const styleTapItem = getStyleTapItem(isDisabled, color || colorway || groupColor);
  const priceOutValue = CurrencyUtil.accountCurrency(priceOut || price || 0, 2);
  const customAmount = type === 'Voucher' && !price;

  const classList = classes({
    pos__list__products__item: true,
    disabled: isDisabled
  });

  const handleOnTap = () => onProductClick(item);

  return (
    <button
      key={item.id || item.idLocal}
      disabled={isDisabled}
      className={classList}
      onClick={!isDisabled ? handleOnTap : null}
      style={styleTapItem}
    >
      <div className="product-name">
        <p>
          {getProductDescription(item, posUnitPrefs)}
          {comment && <span>{comment}</span>}
        </p>
      </div>
      <div className="item-footer">
        <div className="price">{customAmount ? 'Valfritt belopp' : priceOutValue}</div>
        {showQuantity && <div className="quantity">{item.stockQuantity || '-'}</div>}
      </div>
    </button>
  );
};

ProductListItem.propTypes = {
  onProductClick: PropTypes.func,
  item: PropTypes.object,
  isDisabled: PropTypes.bool,
  showQuantity: PropTypes.bool
};

export default memo(ProductListItem);
