import { defineMessages } from 'react-intl';

export default defineMessages({
  confirmDelete: {
    id: 'customers.detail.confirmDelete',
    defaultMessage: 'Delete customer?'
  },
  lblCustomerNo: {
    id: 'customers.detail.lblCustomerNo',
    defaultMessage: 'Customer no.'
  },
  btnClose: {
    id: 'customers.detail.btnClose',
    defaultMessage: 'Close'
  },
  btnDelete: {
    id: 'customers.detail.btnDelete',
    defaultMessage: 'Delete'
  },
  btnOptions: {
    id: 'customers.detail.btnOptions',
    defaultMessage: 'Options'
  },
  btnSendSms: {
    id: 'customers.detail.btnSendSms',
    defaultMessage: 'Send SMS'
  }
});
