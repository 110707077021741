import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { FormGroup } from '@Components/ui/styled/main';
import ModalDialog from '@Components/dialogs/modal-dialog';
import CustomerNotesForm from '@Components/customers/customer-detail/overview/customer-notes-form';
import { TitleBlock, PanelWrapper, StyledLink } from '@Components/customers/customer-styles';
import { colors } from '@Components/ui/styled/variables';
import { updateCustomerNotes } from '@State/customer-actions';
import { txt } from '@Utils/i18n-util';
import msg from './customer-notes.msg';

const CustomerNotes = ({ notes, customerId }) => {
  const dispatch = useDispatch();
  const [isOpenModalChangeNotes, setOpenChangeNotes] = useState(false);
  const openModalChangeNotes = () => setOpenChangeNotes(true);
  const hideModalChangeNotes = () => setOpenChangeNotes(false);

  const onSubmit = (values) => {
    hideModalChangeNotes();
    dispatch(updateCustomerNotes(customerId, values.notes));
  };

  const bgColorNotes = notes ? colors.notesBg : undefined;
  return (
    <>
      {isOpenModalChangeNotes && (
      <ModalDialog
        onClose={hideModalChangeNotes}
        contentSize="medium"
      >
        <CustomerNotesForm
          onClose={hideModalChangeNotes}
          onSubmit={onSubmit}
          initialValues={{ notes }}
        />
      </ModalDialog>
      )}
      <TitleBlock>{txt(msg.lblNotes)}</TitleBlock>
      <PanelWrapper bgColor={bgColorNotes} onClick={openModalChangeNotes}>
        {notes
          ? <FormGroup text={notes} />
          : <StyledLink>{txt(msg.lblAddNote)}</StyledLink>}
      </PanelWrapper>
    </>
  );
};

export default CustomerNotes;
