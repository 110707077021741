import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { web, resource as resourceKeys, payment, config } from '@Utils/preference-keys';
import { getFeatures } from '@State/selectors';
import {
  getResourcePreferences, mergeResourcePreferences, getInitialResourceValues, getJsonPreferences
} from '@State/preferences-actions';
import { getLongestName, templatePreviewValues } from '@Utils/templates';
import { deleteResource, updateResource } from '@State/resource-actions';
import {
  mapPrefsToForm, mapFormToPrefs
} from '@Utils/web-booking';
import ResourceSettingsForm from './resource-settings-form';

class ResourceSettings extends Component {
  static propTypes = {
    routeParams: PropTypes.object.isRequired,
    resourceId: PropTypes.number.isRequired,
    features: PropTypes.object.isRequired,
    getResourcePreferences: PropTypes.func.isRequired,
    deleteResource: PropTypes.func.isRequired,
    saveResource: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.props.getLocationPreferences();
  }

  render() {
    const {
      resource, initialValues, features, saveResource
    } = this.props;

    return initialValues ? (
      <ResourceSettingsForm
        {...this.props}
        features={features}
        resourceName={resource.name}
        initialValues={initialValues}
        onSubmit={saveResource}
      />
    ) : null;
  }
}

const mapStateToProps = (state, ownProps) => {
  const { locationConfig, resourcesById, servicesById } = state;
  const resourceId = ownProps.routeParams.entityId;
  const resource = resourcesById.get(resourceId);

  const resourceName = resource && resource.webName;
  const serviceName = getLongestName(servicesById);
  const previewValues = templatePreviewValues({
    resourceName, serviceName, locationConfig
  });

  const initialValues = resource && getInitialResourceValues(state, resource);
  if (initialValues) {
    mapPrefsToForm(initialValues);
  }

  return {
    resourceId,
    resource,
    previewValues,
    initialValues,
    features: getFeatures(state),
    slug: locationConfig.get(config.slug)
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const resourceId = ownProps.routeParams.entityId;
  return {
    getResourcePreferences: (resourceId) => {
      dispatch(getResourcePreferences(resourceId));
    },
    getLocationPreferences: () => {
      dispatch(getJsonPreferences());
    },
    saveResource: (values) => {
      mapFormToPrefs(values);

      const {
        name, webName, webBookingEnabled, vipBookingEnabled, useSmsReminders,
        resourceStaffType, useCustomValues, color, [web.paymentEnabled]: paymentEnabled,
        [payment.defaultEcomVunitId]: defaultEcomVunitId
      } = values;
      const resource = {
        name, webName, webBookingEnabled, vipBookingEnabled, useSmsReminders, resourceStaffType
      };
      const preferences = {
        // Color, paymentEnabled and defaultEcomVunitId should be saved as prefs even if useCustomValues is false
        useCustomValues, color, [web.paymentEnabled]: paymentEnabled, [payment.defaultEcomVunitId]: defaultEcomVunitId
      };
      resourceKeys.filter(key => !(key in preferences)).forEach(key => {
        preferences[key] = useCustomValues ? values[key] : null;
      });

      return Promise.all([
        dispatch(updateResource(resourceId, resource)),
        dispatch(mergeResourcePreferences(resourceId, preferences))
      ]);
    },
    deleteResource: () => {
      return dispatch(deleteResource(resourceId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResourceSettings);
