import React, { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import FormButtonsFooter from '@Components/ui/form-buttons-footer';
import HookFormInput from '@Components/inputs/hook-form-input';
import { getNewAccountPricing } from '@State/sysadmin-actions';
import { getSysadminPricePlans } from '@Components/sysadmin/accounts/accounts-selector';

const fields = [
  { id: 'fixedPrice', label: 'Pris/mån (Cliento)', unitLabel: 'kr' },
  { id: 'perResourcePrice', label: 'Extra resurs', unitLabel: 'kr' },
  { id: 'perLocationPrice', label: 'Extra location', unitLabel: 'kr' },
  { id: 'fixedPriceMaxResources', label: 'Inkluderade resurser' },
  { id: 'fixedPriceMaxLocations', label: 'Inkluderade locations' },
  { id: 'fixedPriceMaxVunits', label: 'Inkluderade kassor' }
];

const AccountOverviewPriceForm = ({ onClose, onSubmit, initialValues, loading }) => {
  const dispatch = useDispatch();
  const plans = useSelector(getSysadminPricePlans);
  const { register, ...methods } = useForm({ defaultValues: initialValues });

  const options = plans.get('active')?.toJS() ?? [];
  const legacyOptions = plans.get('legacy')?.toJS() ?? [];

  useEffect(() => {
    if (options.length) return;
    dispatch(getNewAccountPricing())
      .then(() => methods.reset(initialValues))
      .catch(console.error);
  }, [options.length]);

  return (
    <FormProvider {...methods} register={register}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="form-group">
          <div className="form-control-container">
            <label className="control-label" htmlFor="pricePlan">
              Prisplan
            </label>
            <div className="select-container">
              <select
                {...register('pricePlan')}
                className="form-control"
                defaultValue={initialValues.pricePlan}
              >
                <>
                  <optgroup label="Active">
                    {options.map(opt => (
                      <option value={opt.planKey} key={opt.planKey}>{opt.planName}</option>
                    ))}
                  </optgroup>
                  <optgroup label="Legacy">
                    {legacyOptions.map(opt => (
                      <option value={opt.planKey} key={opt.planKey}>{opt.planName}</option>
                    ))}
                  </optgroup>
                </>
              </select>
            </div>
          </div>
        </div>
        {fields.map(({ id, label, unitLabel }) => (
          <HookFormInput
            key={id}
            id={id}
            type="number"
            label={label}
            unitLabel={unitLabel}
            initialValue={initialValues[id]}
            registerOptions={{ required: true, valueAsNumber: true, min: 0 }}
          />
        ))}
        <FormButtonsFooter
          onClose={onClose}
          handleSubmit={methods.handleSubmit}
          submitSucceeded={false}
          submitting={loading}
        />
      </form>
    </FormProvider>
  );
};

export default AccountOverviewPriceForm;
