import React, { Component } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import { showSearch, resetSearch, searchBookings } from '@State/booking-actions';
import TBSearch from './tb-search';

class SearchField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      query: props.query || ''
    };

    this.debouncedSearch = debounce(this.props.searchBookings, 1000);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.isShowingSearch) {
      this.setState({ query: '' });
    }
  }

  showSearch = () => {
    if (this.props.tabletMode || !this.props.isShowingSearch && this.state.query.length > 0) {
      this.props.showSearch();
    } else if (this.props.isShowingSearch && this.state.query.length === 0) {
      this.props.resetSearch();
    }
  };

  onChange = (ev) => {
    this.setState({ query: ev.target.value }, this.showSearch);
    this.debouncedSearch(ev.target.value);
  };

  onSubmit = (ev) => {
    ev.preventDefault();
    this.debouncedSearch(this.state.query);
  };

  onBlur = () => {
    if (!this.props.tabletMode && this.state.query.length === 0) {
      this.props.resetSearch();
    }
  };

  onKeyDown = (ev) => {
    if (ev.keyCode === 27) {
      this.resetSearch(ev);
    }
  };

  resetSearch = (ev) => {
    ev.preventDefault();
    this.setState({ query: '' }, () => this.input.focus());
    this.props.resetSearch();

    // need to show the results view
    if (this.props.tabletMode) {
      this.props.showSearch();
    }
  };

  render() {
    const { className } = this.props;
    const { query } = this.state;
    // const inputClass = query.length > 0 ? 'form-control search focus' : 'form-control search';
    return (
      <TBSearch
        className={className}
        inputRef={(ref) => { this.input = ref; }}
        onSubmit={this.onSubmit}
        onClick={this.resetSearch}
        onChange={this.onChange}
        onKeyDown={this.onKeyDown}
        onBlur={this.onBlur}
        query={query}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const { bookingSearchResults, mainViewState } = state;

  return {
    query: bookingSearchResults.get('query'),
    isShowingSearch: bookingSearchResults.get('showSearch'),
    tabletMode: mainViewState.get('tabletMode')
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchBookings: (query) => {
      return dispatch(searchBookings(query));
    },
    showSearch: () => {
      dispatch(showSearch());
    },
    resetSearch: () => {
      dispatch(resetSearch());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchField);
