import { defineMessages } from 'react-intl';

export default defineMessages({
  lblVehicleRegNo: {
    id: 'calendar.bkf.vehicle.lblVehicleRegNo',
    defaultMessage: 'Reg. no'
  },
  lblVehicleDescription: {
    id: 'calendar.bkf.vehicle.lblVehicleDescription',
    defaultMessage: 'Make/Model'
  },
  lblNoVehicleFound: {
    id: 'calendar.bkf.vehicle.lblNoVehicleFound',
    defaultMessage: '{duration} min'
  },
  lblSearchVehicle: {
    id: 'calendar.bkf.vehicle.lblSearchVehicle',
    defaultMessage: 'Lookup vehicle info '
  },
  btnClear: {
    id: 'calendar.bkf.vehicle.btnClear',
    defaultMessage: 'Clear'
  },
  btnOpen: {
    id: 'calendar.bkf.vehicle.btnOpen',
    defaultMessage: 'Close'
  },
  btnClose: {
    id: 'calendar.bkf.vehicle.btnClose',
    defaultMessage: 'Close'
  },
  btnAddVehicle: {
    id: 'calendar.bkf.vehicle.btnAddVehicle',
    defaultMessage: 'Add vehicle'
  }
});
