import React, { useState } from 'react';
import moment from 'moment';
import { scheduleTimeTypes } from '@Utils/booking-util';
import { Button, Panel } from '@Components/ui/styled/main';
import StaffReportDataModal from './staff-report-data-modal';
import { minutesToHours } from '../reports-helpers';

const StaffReportRows = ({ report }) => {
  const [showDataModal, setShowDataModal] = useState(null);

  return (
    <div className="row">
      {report.rows && report.rows.map(row => (
        <div key={row.id} className="col-xs-6">
          <Panel onClick={() => setShowDataModal(row.id)}>
            <div className="pull-right">
              <Button tiny white onClick={() => setShowDataModal(row.id)}>Visa</Button>
            </div>
            <h5 className="mb3">
              {row.name}<br />
              <small>
                <i className={row.approvedTs
                  ? 'far fa-check-circle text-success'
                  : 'far fa-minus-circle'}
                />{' '}
                {row.approvedTs ? 'Godkänd' : 'Ej godkänd'}
              </small>
            </h5>
            <table width="100%">
              <tbody>
                {Object.keys(scheduleTimeTypes).map((timeType) => {
                  return row.summary[timeType] ? (
                    <tr key={timeType}>
                      <td>{scheduleTimeTypes[timeType]}</td>
                      <td className="text-right">{minutesToHours(row.summary[timeType])}</td>
                    </tr>
                  ) : null;
                })}
              </tbody>
            </table>
          </Panel>
        </div>
      ))}
      {!report.rows && (
        <div className="col-xs-12">
          <div className="alert alert-info">
            <i className="fa fa-info-circle" /> Det finns ingen data i denna rapport.
          </div>
        </div>
      )}
      {showDataModal && (
        <StaffReportDataModal
          report={report}
          row={report.rows?.find(r => r.id === showDataModal)}
          onClose={() => setShowDataModal(null)}
        />
      )}
    </div>
  );
};

export default StaffReportRows;
