import React from 'react';
import { getIconByPayment } from '@Utils/pos-utils';

const iconStyle = { width: '16px', height: '16px' };

const PosPaymentMethod = ({ methods, method, isSelected }) => {
  let iconClass = 'fal fa-receipt';
  if (methods?.some(m => m.includes('Swish')) || method?.includes('Swish')) {
    return <img alt="Swish" style={iconStyle} src={isSelected ? '/swish-active.svg' : '/swish.svg'} />;
  }
  if (methods?.some(m => m.includes('Klarna')) || method?.includes('Klarna')) {
    return <img alt="Klarna" style={iconStyle} src="/klarna.svg" />;
  }
  if (methods?.size > 0) {
    iconClass = getIconByPayment(methods.first());
  }
  if (method) {
    iconClass = getIconByPayment(method);
  }
  return <i className={iconClass} />;
};

export default PosPaymentMethod;
