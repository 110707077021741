import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { getSectionUrl } from '@Utils/navigate';
import NoContent from '@Components/ui/no-content';
import StockReportItems from './stock-report-items';

class StockReport extends Component {
  render() {
    const {
      stockReports,
      stockReportId,
      routeParams
    } = this.props;

    if (!stockReports || stockReports.isEmpty()) {
      return (
        <NoContent icon="fa fa-box-open">
          Det finns inga lagerrapporter.<br />
          Klicka på "Skapa rapport" för att skapa en ny rapport.
        </NoContent>
      );
    }

    if (!stockReportId) {
      return (
        <Redirect
          to={getSectionUrl(
            routeParams,
            'reports/stock',
            stockReports.first().get('id')
          )}
        />
      );
    }

    return (
      <StockReportItems routeParams={routeParams} stockReportId={stockReportId} />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { reportsViewState } = state;
  const stockReportId = parseInt(ownProps.match.params.id);

  return {
    stockReportId,
    stockReports: reportsViewState.get('stockReports'),
    routeParams: ownProps.match.params
  };
};

export default connect(mapStateToProps)(StockReport);
