import React from 'react';
import PulseLoaderLib from 'halogenium/lib/PulseLoader';

const PulseLoader = ({ className }) => (
  <PulseLoaderLib
    color="currentColor"
    size="7px"
    margin="1px 3px 0 3px"
    className={className}
  />
);

export default PulseLoader;
