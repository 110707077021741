import React from 'react';
import { Field, reduxForm } from 'redux-form';
import TextInput from '@Components/inputs/text-input';
import ModalDialog from '@Components/dialogs/modal-dialog';

const PosUnitNameForm = ({
  onClose, handleSubmit, submitting
}) => (
  <ModalDialog
    title="Ändra namn på kassa"
    onClose={onClose}
    buttons={[{
      name: 'Ändra namn',
      primary: true,
      type: 'submit',
      loading: submitting,
      onClick: handleSubmit
    }]}
  >
    <form onSubmit={handleSubmit}>
      <Field name="name" component={TextInput} label="Namn" />
    </form>
  </ModalDialog>
);

export default reduxForm({
  form: 'pos-unit-name-form'
})(PosUnitNameForm);
