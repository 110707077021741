import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { getOrganisationsUrl } from '@Utils/navigate';

export default class FeatureNotAvailable extends Component {
  static propTypes = {
    leadText: PropTypes.string.isRequired,
    routeParams: PropTypes.object.isRequired,
    subText: PropTypes.string,
    showLink: PropTypes.bool
  };

  render() {
    const { leadText, subText, showLink, routeParams } = this.props;

    return (
      <div className="alert alert-warning">
        <p className="lead">
          {leadText}
        </p>
        <p>
          {subText || 'Du kan uppgradera ditt konto när som helst för att få tillgång till funktionen'}
        </p>
        {showLink
          ? (
            <p>
              <br />
              <a href={getOrganisationsUrl(routeParams)} className="btn btn-default">Gå till Abonnemang</a>
            </p>
          ) : null}
      </div>
    );
  }
}
