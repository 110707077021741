import React from 'react';
import { ecom } from '@Utils/preference-keys';
import AutoSaveTextInput from '@Components/inputs/auto-save-text-input';

const PosUnitKlarnaSettings = ({ posUnit, onSave }) => {
  const klarnaUsername = posUnit.getIn(['prefs', ecom.klarnaUsername]);
  const klarnaPassword = posUnit.getIn(['prefs', ecom.klarnaPassword]);

  return (
    <>
      <AutoSaveTextInput
        label="API-nyckel"
        name={ecom.klarnaUsername}
        initialValue={klarnaUsername}
        onSave={onSave}
      />
      <AutoSaveTextInput
        type="password"
        label="Lösenord"
        name={ecom.klarnaPassword}
        initialValue={klarnaPassword}
        onSave={onSave}
      />
    </>
  );
};

export default PosUnitKlarnaSettings;
