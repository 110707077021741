import React from 'react';
import { Field, reduxForm } from 'redux-form';
import TextInput from '@Components/inputs/text-input';

const PosOrgOtherForm = ({ handleSubmit, prefix }) => {
  const handleBlur = () => {
    handleSubmit();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Field
        name={`${prefix}Name`}
        component={TextInput}
        label="Beskrivning"
        placeholder="Annat betalsätt"
        onBlur={handleBlur}
      />
    </form>
  );
};

export default reduxForm({
  enableReinitialize: true
})(PosOrgOtherForm);
