import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
import { config } from '@Utils/preference-keys';
import { viewDateFromDate } from '@Utils/time-util';

const LocationLoader = (props) => {
  const url = lastCalendarRoute(props);
  return <Redirect push={false} to={url} />;
};

function lastCalendarRoute(props) {
  const { lastView, embeddedInApp } = props;
  if (lastView == null) {
    return getCalendarStartPath(props);
  }

  const {
    org, loc, viewDate, viewMode, entityType, entityId, search
  } = lastView;
  const q = embeddedInApp ? '?embeddedInApp' : search;

  if (lastEntityIdExists(props)) {
    return `/${org}/${loc}/calendar/${viewMode}/${viewDate}/${entityType}/${entityId}${q}`;
  }
  return `/${org}/${loc}/calendar/${viewMode}/${viewDate}/${entityType}/0${q}`;
}

function lastEntityIdExists(props) {
  const { lastView, orderedGroups, resourcesById } = props;
  if (lastView == null) {
    return false;
  }

  if (lastView.entityType == 'group') {
    const group = orderedGroups.find(g => g.get('id') === parseInt(lastView.entityId));
    return group != undefined;
  }

  return resourcesById.has(parseInt(lastView.entityId));
}

function getCalendarStartPath(props) {
  const { embeddedInApp } = props;
  const { org, loc } = props.match.params;
  const thisWeek = viewDateFromDate('week', moment());
  const today = moment().format('YYYY-MM-DD');
  const q = embeddedInApp ? '?embeddedInApp' : '';

  return props.resourceId == null ? `/${org}/${loc}/calendar/day/${today}/group/0${q}`
    : `/${org}/${loc}/calendar/week/${thisWeek}/my-resource/${props.resourceId}${q}`;
}

const getEmbeddedInApp = state => state.appState.get('embeddedInApp');

const mapStateToProps = (state, ownProps) => {
  const { orderedGroups, resourcesById, locationConfig } = state;
  const embeddedInApp = getEmbeddedInApp(state);

  return {
    orderedGroups,
    resourcesById,
    lastView: state.calendarViewState.get('lastView'),
    resourceId: locationConfig.get(config.resourceId),
    locationRole: locationConfig.get(config.locationRole),
    embeddedInApp
  };
};

export default connect(mapStateToProps)(LocationLoader);
