import color from 'color';

export const isDark = hex => {
  if (!hex) return null;

  // YIQ equation from http://24ways.org/2010/calculating-color-contrast
  const rgb = color(hex).rgbArray();
  const yiq = (rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000;

  return yiq < 128;
};
