import React from 'react';
import { numberFormat } from '@Utils/format';
import { getTotal } from '@Components/reports/reports-helpers';

const SalesReportVatSummary = ({
  amounts, amountExVat, vatAmounts, totalVat
}) => {
  const netAmountExVat = getTotal(amountExVat);
  const netAmount = getTotal(amounts);

  return (
    <table className="table table-condensed table-report">
      <thead>
        <tr>
          <th>Moms %</th>
          <th className="col-xs-3 text-right">Exkl. moms</th>
          <th className="col-xs-3 text-right">Moms</th>
          <th className="col-xs-3 text-right">Inkl. moms</th>
        </tr>
      </thead>
      <tbody>
        {amounts && Object.keys(amounts).sort((a, b) => a - b).map((key) => {
          return amounts[key] !== 0 && (
            <tr key={key}>
              <td>
                {numberFormat(key)}
                %
              </td>
              <td className="col-xs-3 text-right">{numberFormat(amountExVat[key])}</td>
              <td className="col-xs-3 text-right">{numberFormat(vatAmounts[key])}</td>
              <td className="col-xs-3 text-right">{numberFormat(amounts[key])}</td>
            </tr>
          );
        })}
      </tbody>
      <tfoot>
        <tr>
          <th>Summa</th>
          <th className="col-xs-3 text-right">{numberFormat(netAmountExVat)}</th>
          <th className="col-xs-3 text-right">{numberFormat(totalVat)}</th>
          <th className="col-xs-3 text-right">{numberFormat(netAmount)}</th>
        </tr>
      </tfoot>
    </table>
  );
};

export default SalesReportVatSummary;
