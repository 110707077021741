import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { getVatRegNo, txt } from '@Utils/i18n-util';
import FormGroup from '@Components/ui/styled/form-group';
import Grid, { GridRow } from '@Components/ui/styled/grid';
import { Button, Panel } from '@Components/ui/styled/main';
import ButtonModalForm from '@Components/dialogs/button-modal-form';
import NewAccountForm from '@Components/sysadmin/new-account/new-account-form';
import { createNewYabieAccount, fetchOrganizationsApi, getNewAccountPricing } from '@State/sysadmin-actions';
import DialogAlert from '@Components/dialogs/dialog-alert';
import { getSysadminUrl, navigate } from '@Utils/navigate';
import { getSysadminPricePlans } from '@Components/sysadmin/accounts/accounts-selector';

import msg from './new-account.msg';
import '../../../styles/new-account.less';

export const fields = {
  org: [
    { label: 'orgNameLabel', name: 'orgName', rules: { required: true, min: 2 } },
    { label: 'orgNumberLabel', name: 'orgNumber', rules: { required: true, min: 2 } },
    { label: 'locNameLabel', displayLabel: 'locNameDisplayLabel', name: 'locName', rules: { required: true, min: 2 } },
    { label: 'companyAddressLabel', name: 'companyAddress', rules: { required: true, min: 3 } }
  ],
  owner: [
    { label: 'ownerNameLabel', name: 'ownerName', rules: { required: true, min: 2 } },
    { label: 'ownerEmailLabel', name: 'ownerEmail', rules: { required: true, email: true, min: 3 } }
  ],
  invoice: [
    // InvoiceSettings invoiceSettings:
    // { label: 'invoiceIntervalLabel',
    //   name: 'invoiceInterval',
    //   type: 'select',
    //   rules: { required: true },
    //   options: [
    //     { value: 'Monthly', title: 'invoiceIntervalMonthly' },
    //     { value: 'Quaterly', title: 'invoiceIntervalQuaterly' },
    //     { value: 'Manual', title: 'invoiceIntervalManual' }
    //   ]
    // }, Hardcoded
    { label: 'invoiceMethodLabel',
      name: 'invoiceMethod',
      type: 'select',
      rules: { required: true },
      options: [
        { value: 'Email', title: 'invoiceMethodEmail' },
        { value: 'Post', title: 'invoiceMethodPost' }
      ]
    },
    { label: 'vatTypeLabel',
      name: 'vatType',
      type: 'select',
      rules: { required: true },
      options: [
        { value: 'SEVAT', title: 'vatTypeSEVAT' },
        { value: 'EUVAT', title: 'vatTypeEUVAT' },
        { value: 'EXPORT', title: 'vatTypeEXPORT' }
      ]
    },
    { label: 'companyVatNoLabel', name: 'companyVatNo', rules: { required: true, min: 2 } },
    { label: 'invoiceEmailLabel', name: 'invoiceEmail', rules: { required: true, min: 2 } },
    { label: 'invoiceAddressLabel', name: 'invoiceAddress', rules: { required: true, min: 3 } }
  ],
  pricing: [
    {
      label: 'pricePlanLabel',
      name: 'pricePlan',
      type: 'select',
      rules: { required: true },
      options: [
        { value: 'SMALL', title: 'SMALL' },
        { value: 'LARGE', title: 'LARGE' },
        { value: '_FIXED_SMALL', title: '_FIXED_SMALL' },
        { value: '_FIXED_LARGE', title: '_FIXED_LARGE' },
        { value: '_1701_BASIC', title: '_1701_BASIC' },
        { value: '_1701_S', title: '_1701_S' },
        { value: '_1701_M', title: '_1701_M' },
        { value: '_1701_L', title: '_1701_L' },
        { value: '_1703_DASHL', title: '_1703_DASHL' },
        { value: '_1810_SMS_REMINDER_AND_PIN_ONLY', title: '_1810_SMS_REMINDER_AND_PIN_ONLY' },
        { value: '_1810_SMS_REMINDER_ONLY', title: '_1810_SMS_REMINDER_ONLY' },
        { value: '_1902_FIXED_VEHICLE_SEARCH', title: '_1902_FIXED_VEHICLE_SEARCH' },
        { value: '_2011_KACHING', title: '_2011_KACHING' },
        { value: '_2102_YABIE_SMS_ONLY', title: '_2102_YABIE_SMS_ONLY' },
        { value: '_2201_YABIE_FIXED', title: '_2201_YABIE_FIXED' },
        { value: '_2204_YABIE_BOOKING_BUNDLE1', title: '_2204_YABIE_BOOKING_BUNDLE1' },
        { value: '_2301_BAS', title: '_2301_BAS' },
        { value: '_2301_PLUS', title: '_2301_PLUS' },
        { value: '_2301_MAX', title: '_2301_MAX' }
      ]
    },
    { default: 0, unit: 'kr', label: 'fixedPriceLabel', name: 'fixedPrice', type: 'number', rules: { required: true, min: 0 } },
    { default: 3, label: 'fixedPriceMaxResourcesLabel', name: 'fixedPriceMaxResources', type: 'number', rules: { required: true, min: 0 } },
    { default: 1, label: 'fixedPriceMaxLocationsLabel', name: 'fixedPriceMaxLocations', type: 'number', rules: { required: true, min: 0 } },
    { default: 1, label: 'fixedPriceMaxVunitsLabel', name: 'fixedPriceMaxVunits', type: 'number', rules: { required: true } },
    { default: 0, unit: 'kr', label: 'perResourcePriceLabel', name: 'perResourcePrice', type: 'number', rules: { required: true, min: 0 } },
    { default: 0, unit: 'kr', label: 'perLocationPriceLabel', name: 'perLocationPrice', type: 'number', rules: { required: true, min: 0 } },
    { default: 0.79, unit: 'kr', label: 'smsReminderPriceLabel', name: 'smsReminderPrice', type: 'number', rules: { required: true, min: 0 } },
    { default: 0.79, unit: 'kr', label: 'smsPinPriceLabel', name: 'smsPinPrice', type: 'number', rules: { required: true, min: 0 } },
    { default: 0.50, unit: 'kr', label: 'smsBulkPriceLabel', name: 'smsBulkPrice', type: 'number', rules: { required: true, min: 0 } },
    { default: 0, unit: 'kr', label: 'perInitialVunitPriceLabel', name: 'perInitialVunitPrice', type: 'number', rules: { required: true } },
    { default: 0, unit: 'kr', label: 'perExtraVunitPriceLabel', name: 'perExtraVunitPrice', type: 'number', rules: { required: true } }
  ],
  contract: [
    { label: 'contractStartDateLabel', name: 'contractStartDate', type: 'date', rules: { required: true } },
    { label: 'contractLengthLabel',
      name: 'contractLength',
      type: 'select',
      unit: 'månader',
      rules: { required: true },
      options: [
        { value: '', title: 'Välj bindningstid' },
        { value: '0', title: 'Ingen bindningstid' },
        { value: '12', title: '12 månader' },
        { value: '24', title: '24 månader' },
        { value: '36', title: '36 månader' },
        { value: '48', title: '48 månader' },
        { value: '60', title: '60 månader' }
      ]
    },
    { default: 0, unit: 'kr', label: 'externalInvoiceFixedPriceLabel', name: 'externalInvoiceFixedPrice', type: 'number', helpText: 'Beloppet som ska faktureras via Wasa Kredit eller Nordea' }
  ],
  pos: [
    { label: 'vunitTypeLabel',
      name: 'vunitType',
      type: 'select',
      rules: { required: true },
      options: [
        { value: 'Onprem', title: 'Onprem' },
        { value: 'OnpremNoCCU', title: 'OnpremNoCCU' }
      ]
    },
    { label: 'posAddressLabel', name: 'posAddress', rules: { required: true } }
  ]
};

const columns = {
  pricing: [
    ['pricePlan', 'fixedPrice', 'perInitialVunitPrice'],
    ['fixedPriceMaxResources', 'fixedPriceMaxLocations', 'fixedPriceMaxVunits'],
    ['perResourcePrice', 'perLocationPrice', 'perExtraVunitPrice'],
    ['smsReminderPrice', 'smsPinPrice', 'smsBulkPrice']
  ]
};

const columnsQuantity = 2;

const splitToChunks = (key) => {
  if (columns[key]) {
    return columns[key].map(arr => arr.map(fieldName => fields[key].find(field => field.name === fieldName)));
  }
  return fields[key].reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / columnsQuantity);
    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [];
    }
    resultArray[chunkIndex].push(item);
    return resultArray;
  }, []);
};

const getValue = (val, field) => {
  if (!val && val !== 0) return field.default !== undefined ? field.default : '-';
  if (typeof val === 'string' || typeof val === 'number') {
    if (field.options) {
      const opt = field.options.find(opt => opt.value === val);
      return opt && msg[opt.title] ? txt(msg[opt.title]) : opt?.title || val;
    }
    return field.unit ? `${val} ${field.unit}` : val;
  }
  if (typeof val === 'object' && val.addressLine1) {
    return `${val.addressLine1}, ${val.postCode} ${val.area}`;
  }
  return '-';
};

class NewAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: null,
      error: null,
      success: null,
      submitting: false,
      filledKeys: {},
      id: 2022,
      values: {},
      pricePlans: []
    };
  }

  componentDidMount() {
    const plans = this.props.plans.get('active')?.toJS();
    if (plans) {
      this.setState({
        pricePlans: plans ?? [],
        values: plans[0] ? { ...plans[0].pricing } : {}
      });
      return;
    }
    this.props.getNewAccountPricing()
      .then((res) => this.setState({
        pricePlans: res.active ?? [],
        values: res.active[0] ? { ...res.active[0].pricing } : {}
      }))
      .catch(console.error);
  }

  handleConfirm = async (income = {}) => {
    const { addressLine1, postCode, area, country, useSameAddress, ...values } = income;
    let address = null;
    if (addressLine1 && area) {
      address = { addressLine1, postCode, area, country: 'SE', useSameAddress };
    }
    if (useSameAddress) {
      address = this.state.values.companyAddress;
    }
    this.setState(prev => {
      // eslint-disable-next-line no-nested-ternary
      const addressKey = prev.activeKey === 'org' ? 'companyAddress' : prev.activeKey === 'pos' ? 'posAddress'
        : prev.activeKey === 'invoice' ? 'invoiceAddress' : '';
      return ({
        values: { ...(prev && prev.values && prev.values), ...values, [addressKey]: address },
        filledKeys: { ...prev.filledKeys, [prev.activeKey]: true },
        activeKey: null
      });
    });
  }

  onShowModal = (activeKey) => {
    this.setState({ activeKey });
  }

  getInitialValues = (key) => {
    const { companyAddress, invoiceAddress, posAddress, ...rest } = this.state.values;
    let defVals = { ...rest };
    if (key === 'org' && typeof companyAddress === 'object') {
      defVals = { ...companyAddress, country: 'SE', ...rest };
    }
    if (key === 'invoice') {
      const addr = typeof invoiceAddress === 'object' ? invoiceAddress : companyAddress ?? {};
      defVals = {
        ...addr,
        country: 'SE',
        ...rest,
        invoiceEmail: rest.invoiceEmail ?? rest.ownerEmail,
        companyVatNo: getVatRegNo(rest.orgNumber, 'SE')
      };
    }
    if (key === 'pricing') {
      fields.pricing.forEach(field => {
        defVals[field.name] = defVals[field.name] ?? field.default;
      });
      const plan = this.state.pricePlans[0];
      if (!defVals.pricePlan && !!plan?.planKey) {
        defVals.pricePlan = plan.planKey;
      }
    }
    if (key === 'contract') {
      defVals = {
        contractStartDate: moment().format('YYYY-MM-DD'),
        ...rest
      };
    }
    fields[key]?.forEach(({ name, options }) => {
      if (!defVals[name] && options && options[0] && name !== 'contractLength') {
        defVals[name] = options[0].value;
      }
    });
    return defVals;
  }

  handleCreateAccount = async () => {
    try {
      this.setState({ submitting: true, error: null });
      const { values } = this.state;
      const {
        orgName, locName, orgNumber, ownerName, ownerEmail, vatType,
        invoiceMethod, invoiceEmail, companyVatNo, vunitType, pricePlan,
        fixedPriceMaxResources, fixedPriceMaxLocations, perResourcePrice,
        fixedPrice, fixedPriceMaxVunits, perInitialVunitPrice, perExtraVunitPrice,
        perLocationPrice, smsReminderPrice, smsPinPrice, smsBulkPrice,
        externalInvoiceFixedPrice, companyAddress, invoiceAddress, posAddress
      } = values;
      const contractLength = values.contractLength ? +values.contractLength : 0;
      const contractStartDate = values.contractStartDate
        ? moment(values.contractStartDate).format('YYYY-MM-DD')
        : null;
      const contractEndDate = values.contractStartDate
        ? moment(values.contractStartDate).add(contractLength, 'months').format('YYYY-MM-DD')
        : null;

      const data = {
        orgName,
        locName,
        orgNumber,
        ownerName,
        ownerEmail,
        salesAgent: 'YABIE',
        pricePlanName: pricePlan ?? '_2201_YABIE_FIXED',
        subscriptionPricing: {
          fixedPrice,
          fixedPriceMaxResources,
          fixedPriceMaxLocations,
          perResourcePrice,
          perLocationPrice,
          smsReminderPrice,
          smsPinPrice,
          smsBulkPrice,
          fixedPriceMaxVunits,
          perInitialVunitPrice,
          perExtraVunitPrice,
          externalInvoiceFixedPrice
        },
        paymentMethod: 'Invoice',
        invoiceSettings: { invoiceInterval: 'Quaterly', invoiceMethod, vatType, invoiceEmail, companyVatNo },
        contractLength,
        contractStartDate,
        contractEndDate,

        createPos: !!posAddress,
        vunitType: vunitType === 'null' ? null : vunitType,
        companyAddress,
        invoiceAddress,
        posAddress
      };
      ['companyAddress', 'invoiceAddress', 'posAddress'].forEach(key => {
        if (typeof data[key] === 'object' && data[key]?.useSameAddress) delete data[key].useSameAddress;
      });
      const pricingFields = fields.pricing;
      pricingFields.forEach(({ name, ...field }) => {
        data.subscriptionPricing[name] = values[name] ?? field.default;
      });

      const res = await this.props.createNewYabieAccount(data);
      if (res) {
        const organisationId = res.locationAssociation?.organisationId || res.subscription?.organisationId;
        await this.props.fetchOrganizationsApi({ terminated: true });
        this.setState({ success: true, submitting: false, id: organisationId });
      }
    } catch (e) {
      this.setState({ success: false, submitting: false, error: e.message });
    }
  }

  handleReset = () => {
    this.setState({
      activeKey: null,
      error: null,
      success: null,
      submitting: false,
      filledKeys: {},
      values: {}
    });
  }

  handleRedirect = () => {
    navigate(getSysadminUrl({ id: this.state.id }));
  }

  render() {
    const { filledKeys, values, success, submitting, error, activeKey, pricePlans } = this.state;
    const disabled = !['org', 'owner', 'invoice', 'pricing', 'contract'].every(key => filledKeys[key]);

    return (
      <div className="columns-wrapper">
        <div className="columns-container">
          <div className="columns-content">
            <div className="columns-content-container width-large">
              <div className="columns-content-body">
                <div className="new-account-container">
                  <h3>{success ? <><i className="fa-solid success fa-circle-check" /> {txt(msg.newAccountCreated)}</> : txt(msg.newAccountHeader)}</h3>
                  <div>
                    {Object.keys(fields).map(key => (
                      <React.Fragment key={key}>
                        <div className="new-account-panel-header">
                          <h5>
                            {filledKeys[key] && <i className="fa-solid success fa-circle-check" />}
                            {txt(msg[key])}
                          </h5>
                        </div>
                        <Panel onClick={success || filledKeys[key] ? null : () => this.setState({ activeKey: key })}>
                          <div className={`new-account-panel-button ${filledKeys[key] ? 'filled' : ''}`}>
                            {!success && (
                              <ButtonModalForm
                                onClose={() => this.onShowModal(null)}
                                onShowModal={() => this.onShowModal(key)}
                                showModal={activeKey === key}
                                bold
                                white
                                small
                                gray={false}
                                marginRight={false}
                                btnText={filledKeys[key] ? txt(msg.edit) : txt(key === 'pos' ? msg.fillPos : msg.fill)}
                                onSubmit={this.handleConfirm}
                              >
                                <NewAccountForm
                                  title={txt(msg[key])}
                                  pricePlans={pricePlans}
                                  fieldsKey={key}
                                  initialValues={this.getInitialValues(key)}
                                  defaultAddress={values.companyAddress ?? {}}
                                />
                              </ButtonModalForm>
                            )}
                          </div>
                          {filledKeys[key] ? (
                            <Grid className="select-text">
                              {splitToChunks(key).map((arr, idx) => (
                                <GridRow columns={columns[key] ? columns[key][0].length : arr.length} key={`fields_chunk_${idx}`}>
                                  {arr.map(item => (
                                    <FormGroup
                                      wrapperStyle={{ userSelect: 'text' }}
                                      key={item.name}
                                      labelText={msg[item.label] ? txt(msg[item.displayLabel ?? item.label]) : item.label}
                                      text={getValue(values[item.name], item)}
                                    />
                                  ))}
                                </GridRow>
                              ))}
                            </Grid>
                          ) : null}
                        </Panel>
                      </React.Fragment>
                    ))}
                    {error && <span className="help-block">{error}</span>}
                    {success && (
                      <DialogAlert
                        success
                        title=""
                        icon="fa fa-check-circle"
                        text={`Kontot ${values.orgName} har skapats`}
                        buttons={[
                          {
                            primary: true,
                            name: 'Gå till kontot',
                            onClick: this.handleRedirect
                          },
                          {
                            gray: true,
                            name: 'Skapa nytt',
                            onClick: this.handleReset
                          }
                        ]}
                      />
                    )}
                    <div className="text-center mt4">
                      <Button primary small loading={submitting} onClick={this.handleCreateAccount} disabled={disabled}>
                        {txt(msg.newAccount)}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  plans: getSysadminPricePlans(state)
});

export default connect(mapStateToProps, { createNewYabieAccount, getNewAccountPricing, fetchOrganizationsApi })(NewAccount);
