import moment from 'moment';
import React, { Component } from 'react';
import { getSectionUrl, navigate } from '@Utils/navigate';
import ColumnListItem from '@Components/ui/column/column-list-item';
import { getStaffReportDefaultLabel } from '../reports-helpers';

class StaffReportList extends Component {
  onItemClick = item => {
    navigate(getSectionUrl(this.props.routeParams, 'reports/staff', item?.get('reportId')));
  };

  render() {
    const { routeParams, staffReports, staffReport } = this.props;
    const snapshotStaff = staffReport?.get('entries').size || 0;
    const currentId = parseInt(routeParams.id);

    return (
      <>
        <div className="columns-header">
          <h1>Löneunderlag</h1>
        </div>
        <div className="columns-list">
          <ColumnListItem
            onClick={() => this.onItemClick(null)}
            selected={!currentId}
            header="Nuvarande löneperiod (realtid)"
          >
            {snapshotStaff || '-'} personer
          </ColumnListItem>
          <div className="columns-list-header">
            Sparade löneunderlag
          </div>
          {staffReports && staffReports.map(report => {
            const { reportId, label, createdTs, startDate, rows } = report.toJS();
            const approved = rows?.filter(r => r.approvedTs);
            const defaultLabel = getStaffReportDefaultLabel(startDate);

            return (
              <div key={reportId}>
                <ColumnListItem
                  onClick={() => this.onItemClick(report)}
                  selected={reportId === currentId}
                  header={label || defaultLabel}
                  subHeader={moment(createdTs).format('LL')}
                >
                  {rows?.length === approved?.length && <i className="far fa-circle-check text-success" />}
                  {`${approved?.length} av ${rows?.length} godkända`}
                </ColumnListItem>
              </div>
            );
          })}
        </div>
      </>
    );
  }
}

export default StaffReportList;
