import React, { useState } from 'react';
import { ecom } from '@Utils/preference-keys';
import { setLastVisitedPage } from '@Utils/session';
import { fetchStripeState } from '@State/pos-config-actions';
import { useDispatch } from 'react-redux';
import Button from '@Components/ui/button';

function getStripeConnectUrl(clientId, state) {
  return `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${clientId}&scope=read_write&state=${state}`;
}

const PosUnitStripeSettings = ({ posUnit }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const stripeAccountId = posUnit.getIn(['prefs', ecom.stripeAccountId]);

  const handleClick = () => {
    setLoading(true);
    return dispatch(fetchStripeState(posUnit.get('id')))
      .then(({ clientId, state }) => {
        setLastVisitedPage(window.location.pathname);
        window.location = getStripeConnectUrl(clientId, state);
      })
      .catch(() => setLoading(false));
  };

  return (
    <>
      {stripeAccountId && <p><strong>Kopplad till konto: {stripeAccountId}</strong></p>}
      <Button small white loading={loading} onClick={handleClick}>Koppla Stripe-konto</Button>
    </>
  );
};

export default PosUnitStripeSettings;
