import { Map } from 'immutable';

function getMapOrObjValue(obj, prop) {
  if (!obj) {
    return null;
  }
  return Map.isMap(obj) ? obj.get(prop) : obj[prop];
}

export function isUserAdministrator(user) {
  return getMapOrObjValue(user, 'userRole') === 'Administrator';
}

export function isLocationUserAdministrator(user) {
  return getMapOrObjValue(user, 'locationRole') === 'Administrator';
}

export function isUserOwner(user) {
  return getMapOrObjValue(user, 'organisationRole') === 'OWNER';
}

export function getUserRoleDescription(userRole, orgRole) {
  if (orgRole && orgRole === 'OWNER') {
    return {
      title: 'Kontoägare',
      description: 'Äger kontot, har alla rättigheter'
    };
  }
  switch (userRole) {
    case 'Administrator':
      return {
        title: 'Administratör',
        description: 'Bokar alla och administrerar'
      };
    case 'FrontOfficeUser':
      return {
        title: 'Privilegierad användare',
        description: 'Bokar alla resurser'
      };
    case 'FrontOfficeUserWithReports':
      return {
        title: 'Privilegierad användare & rapporter',
        description: 'Bokar alla resurser'
      };
    case 'UnprivilegedUser':
      return {
        title: 'Användare',
        description: 'Bokar valda resurser'
      };
    case 'UnprivilegedUserWithReports':
      return {
        title: 'Användare & rapporter',
        description: 'Bokar valda resurser'
      };
    case 'OperatorOnly':
      return {
        title: '',
        description: ''
      };
    default:
      return {
        title: userRole,
        description: ''
      };
  }
}

export function getEmploymentTypeDescription(employmentType) {
  switch (employmentType) {
    case 'FullTime':
      return 'Anställd heltid';
    case 'PartTime':
      return 'Anställd deltid';
    case 'SelfEmployed':
      return 'Eget företag';
    default:
      return '-';
  }
}

export function getSalesTermsDescription(salesTerms) {
  switch (salesTerms) {
    case 'Commission':
      return 'Provision på försäljning';
    case 'NetMarkup':
      return 'Påslag på inköpspris';
    default:
      return '-';
  }
}
