import PropTypes from 'prop-types';
import React, { Component } from 'react';
import PulseLoader from '@Components/ui/pulse-loader';
import { classes } from '@Components/ui/utils';
import { txt } from '@Utils/i18n-util';
import msg from './submit-button.msg';

export default class SubmitButton extends Component {
  static propTypes = {
    submitting: PropTypes.bool.isRequired,
    submitSucceeded: PropTypes.bool
  };

  constructor(props) {
    super(props);

    this.state = {
      saved: false
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.submitSucceeded && nextProps.submitSucceeded) {
      this.setState({ saved: true }, this.reset);
    }
  }

  reset = () => {
    setTimeout(() => { this.setState({ saved: false }); }, 2000);
  };

  render() {
    const { saved } = this.state;
    const {
      pristine, submitting, classNames, children, disabled, style = {}
    } = this.props;
    const className = classes({
      btn: true,
      'btn-save': true,
      'btn-regular-size': true,
      saved,
      [classNames]: classNames
    });

    return (
      <button
        type="submit"
        className={className}
        style={style}
        disabled={submitting || pristine || disabled}
      >
        {submitting && <PulseLoader />}
        {!submitting && saved && <span><i className="fa fa-lg fa-check" /></span>}
        {!submitting && !saved && <span>{children || txt(msg.btnSubmit)}</span>}
      </button>
    );
  }
}
