import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Popover from '@Components/ui/popover';
import EditGroupForm from './edit-group-form';

export default class SortableList extends Component {
  static propTypes = {
    groups: PropTypes.object.isRequired,
    groupItemSelector: PropTypes.string.isRequired,
    sortItemSelector: PropTypes.string,
    items: PropTypes.object.isRequired,
    itemContent: PropTypes.func.isRequired,
    selectedId: PropTypes.number,
    onItemClick: PropTypes.func.isRequired,
    updateGroup: PropTypes.func,
    deleteGroup: PropTypes.func,
    moveGroup: PropTypes.func,
    moveItem: PropTypes.func,
    sortable: PropTypes.bool
  };

  state = {
    editGroupId: null,
    editGroupColor: false
  };

  onItemClick = (ev, item) => {
    ev.preventDefault();
    this.props.onItemClick(item);
  };

  collapseGroup = (ev, id) => {
    ev.preventDefault();
    if (this.props.onChangeCollapsedIds) {
      const { collapsedIds = [] } = this.props;
      const newCollapsedIds = [...collapsedIds, id];
      this.props.onChangeCollapsedIds(newCollapsedIds);
    }
  };

  expandGroup = (ev, id) => {
    ev.preventDefault();
    if (this.props.onChangeCollapsedIds) {
      const { collapsedIds = [] } = this.props;
      const newCollapsedIds = [...collapsedIds];
      newCollapsedIds.splice(collapsedIds.indexOf(id), 1);
      this.props.onChangeCollapsedIds(newCollapsedIds);
    }
  };

  editGroup = (ev, id) => {
    ev.preventDefault();
    ev.stopPropagation();
    this.setState({ editGroupId: id });
  };

  saveGroup = values => {
    const { editGroupId } = this.state;
    return this.props
      .updateGroup({ id: editGroupId, ...values })
      .then(() => this.setState({ editGroupId: null }));
  };

  deleteGroup = (ev, id) => {
    ev.preventDefault();
    ev.stopPropagation();
    this.props.deleteGroup(id);
  };

  closeGroupPopover = ev => {
    if (ev) {
      ev.preventDefault();
      ev.stopPropagation();
    }
    if (!this.state.editGroupColor) {
      this.setState({ editGroupId: null });
    }
  };

  handleOpenColorPopover = () => {
    this.setState({ editGroupColor: true });
  };

  handleCloseColorPopover = () => {
    this.setState({ editGroupColor: false });
  };

  editGroupContent = values => {
    return (
      <EditGroupForm
        onClosePopover={this.closeGroupPopover}
        onEditGroup={this.saveGroup}
        colorPickerState={this.state.editGroupColor}
        handleColorPickerOpen={this.handleOpenColorPopover}
        handleColorPickerClose={this.handleCloseColorPopover}
        name={values.name}
        color={values.color}
        checkboxes={this.props.groupCheckboxes?.map(checkbox => ({
          ...checkbox,
          checked: values[checkbox.name]
        }))}
      />
    );
  };

  handleDragEnd = result => {
    if (result.destination == null) {
      return;
    }

    if (result.type === 'GROUP') {
      const groupId = parseInt(result.draggableId.substring(1));
      const destPos = result.destination.index;
      const srcPos = result.source.index;
      const moveAction = {
        groupId,
        srcPos,
        destPos
      };

      this.props.moveGroup(moveAction);

      return;
    }

    const itemId = result.draggableId;
    const srcGrpId = parseInt(result.source.droppableId.substring(1));
    const destGrpId = parseInt(result.destination.droppableId.substring(1));
    const destPos = result.destination.index;
    const srcPos = result.source.index;
    const moveAction = {
      destGrpId,
      srcGrpId,
      srcPos,
      destPos,
      itemId
    };

    this.props.moveItem(moveAction);
  };

  render() {
    return (
      <div className="columns-list">
        <DragDropContext onDragEnd={this.handleDragEnd}>
          <Droppable droppableId="group" type="GROUP">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {this.renderList()}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    );
  }

  renderList() {
    const { groups } = this.props;

    return (
      groups
      && groups.map((group, index) => {
        const draggableId = `g${group.get('id')}`;

        return (
          <Draggable
            draggableId={draggableId}
            key={draggableId}
            index={index}
            isDragDisabled={!this.props.sortable}
          >
            {(provided, snapshot) => {
              const style = {
                filter: snapshot.isDragging
                  ? 'drop-shadow(2px 2px 6px rgba(0, 0, 0, 0.2))'
                  : 'none',
                ...provided.draggableProps.style
              };
              return (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={style}
                >
                  {this.renderGroup(group)}
                </div>
              );
            }}
          </Draggable>
        );
      })
    );
  }

  getGroupItems(group) {
    const { items, groupItemSelector, sortItemSelector } = this.props;
    const itemIds = group.get(groupItemSelector);

    return itemIds.map(id => items.get(id)).filter(v => v)
      .sortBy(sortItemSelector ? s => s[sortItemSelector] : null);
  }

  renderGroup(group) {
    const { collapsedIds } = this.props;
    const { id } = group.toJS();

    const isCollapsed = collapsedIds && collapsedIds.indexOf(id) !== -1;
    const groupItems = this.getGroupItems(group);

    return (
      <Droppable droppableId={`g${id}`} key={`group${id}`}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {group.get('id') === 0
              ? this.render0GroupHeader()
              : this.renderGroupHeader(group)}
            {!isCollapsed && groupItems && groupItems.map(this.renderItem)}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    );
  }

  render0GroupHeader(group) {
    return (
      <div className="columns-list-header" style={{ pointerEvents: 'none' }}>
        <span />
      </div>
    );
  }

  renderGroupHeader(group) {
    const { sortable, hideGroupMenu, collapsedIds } = this.props;
    const { id, name, color } = group.toJS();
    const isCollapsed = collapsedIds && collapsedIds.indexOf(id) !== -1;
    const showGroupMenu = !hideGroupMenu && !sortable;

    return (
      <div
        className="columns-list-header"
        onClick={ev => isCollapsed ? this.expandGroup(ev, id) : this.collapseGroup(ev, id)}
      >
        <div>
          <i className={isCollapsed ? 'fa fa-fw fa-chevron-right' : 'fa fa-fw fa-chevron-down'} />
          {color && <span className="columns-list-color-dot" style={{ background: color }} />}
          <span>{name}</span>
        </div>
        {sortable ? this.renderDragHandle() : null}
        {showGroupMenu ? this.renderGroupMenu(group) : null}
      </div>
    );
  }

  renderGroupMenu(group) {
    const { groupItemSelector } = this.props;
    const { editGroupId } = this.state;

    const jsGroup = group.toJS();
    const { id, name, color } = jsGroup;
    const isEmpty = group.get(groupItemSelector).count() === 0;
    const showEditGroup = editGroupId === id;

    return isEmpty ? (
      <a href="#" onClick={ev => this.deleteGroup(ev, id)}>
        <i className="fa fa-fw fa-minus-circle text-danger" />
      </a>
    ) : (
      <Popover
        isOpen={showEditGroup}
        body={this.editGroupContent(jsGroup)}
        onOuterAction={this.closeGroupPopover}
        preferPlace="above"
        className="Popover-big-radius"
      >
        <a href="#" onClick={ev => this.editGroup(ev, id)}>
          <i className="fa fa-fw fa-ellipsis-h" />
        </a>
      </Popover>
    );
  }

  renderDragHandle() {
    return (
      <span className="pull-right">
        <i className="fa fa-bars" />
      </span>
    );
  }

  renderItem = (item, index) => {
    const { id } = item;
    const { selectedId, sortable } = this.props;
    const className = id === selectedId ? 'columns-list-item selected' : 'columns-list-item';

    return (
      <Draggable
        draggableId={id}
        index={index}
        key={`item${id}`}
        isDragDisabled={!this.props.sortable}
      >
        {(provided, snapshot) => {
          const style = {
            filter: snapshot.isDragging
              ? 'drop-shadow(2px 2px 6px rgba(0, 0, 0, 0.2))'
              : 'none',
            ...provided.draggableProps.style
          };

          return (
            <div
              className={className}
              onClick={ev => this.onItemClick(ev, item)}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={style}
            >
              {sortable && (
                <span className="pull-right">
                  <i className="fa fa-bars" />
                </span>
              )}

              {this.props.itemContent(item)}
            </div>
          );
        }}
      </Draggable>
    );
  };
}
