import React, { memo } from 'react';
import PropTypes from 'prop-types';
import CurrencyUtil from '@Utils/currency-util';
import ColumnListItem from '@Components/ui/column/column-list-item';

const VoucherTemplateList = ({
  voucherTemplates, selectedId, onItemClick
}) => (
  <>
    <br />
    {voucherTemplates.map(template => {
      const {
        id, description, initialValue
      } = template.toJS();

      return (
        <div key={id}>
          <ColumnListItem
            onClick={() => onItemClick(id)}
            selected={id === selectedId}
            header={description}
            subHeader={CurrencyUtil.accountCurrency(initialValue, 0)}
            icon="fa fa-gift"
          />
        </div>
      );
    })}
  </>
);

VoucherTemplateList.propTypes = {
  currentId: PropTypes.number,
  voucherTemplates: PropTypes.object,
  onItemClick: PropTypes.func
};

export default memo(VoucherTemplateList);
