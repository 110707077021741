import React from 'react';
import { connect } from 'react-redux';
import { getRouteParams } from '@State/selectors';
import Header from '@Components/nav/header';

const UserConfigHeader = ({ routeParams, username }) => (
  <Header routeParams={routeParams} title="Användarinställningar" subtitle={username} showBackButton />
);

const mapStateToProps = (state, ownProps) => {
  const { authState } = state;
  return {
    routeParams: getRouteParams(state, ownProps),
    username: authState.get('username')
  };
};

export default connect(mapStateToProps)(UserConfigHeader);
