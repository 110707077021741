import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toggleProductsDelivery } from '@State/admin-view-reducer';
import { pauseScanner, resumeScanner } from '@Utils/barcode';
import { getSectionUrl, navigate } from '@Utils/navigate';
import ProductDelivery from '@Components/inventory/products/product-delivery';

class ProductsBarcodeListener extends Component {
  constructor(props) {
    super(props);

    this.state = {
      productScanned: null,
      deliveryProduct: props.product || null
    };
  }

  componentDidMount() {
    document.addEventListener('barcode:scan', this.handleBarcodeScan);
  }

  componentWillUnmount() {
    document.removeEventListener('barcode:scan', this.handleBarcodeScan);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.product !== nextProps.product) {
      this.setState({ deliveryProduct: nextProps.product });
    }
  }

  navigateToProduct = ({ id }) => {
    const { routeParams } = this.props;
    const { subsection } = routeParams;

    if (subsection === 'products') {
      navigate(getSectionUrl(routeParams, 'admin/products', id));
    }
  };

  handleBarcodeScan = (bcEvent) => {
    const barcode = bcEvent.detail.barcode;
    const { routeParams, products, deliveryMode } = this.props;
    const { subsection } = routeParams;

    const product = products.find(item => item.EAN.indexOf(barcode) !== -1);
    if (!product) {
      this.handleProductScanned({ barcode, product });
    } else if (product && deliveryMode) {
      // Prevent the enter key to trigger submit in numpad
      setTimeout(() => {
        this.handleProductScanned({ barcode, product });
      }, 100);
    } else if (product && !deliveryMode) {
      this.navigateToProduct(product);
    }
  };

  handleProductScanned = ({ barcode, product }) => {
    this.setState({ productScanned: barcode, deliveryProduct: product });
    pauseScanner();
  };

  hideProductDelivery = () => {
    this.setState({ productScanned: null, deliveryProduct: null });
    this.props.toggleDelivery(false);
    resumeScanner();
  };

  setDeliveryProduct = (product) => {
    this.setState({ productScanned: null, deliveryProduct: product });
    pauseScanner();
  };

  resetDeliveryProduct = () => {
    this.setState({ productScanned: null, deliveryProduct: null });
    resumeScanner();
  };

  render() {
    const { deliveryMode, deliveryId } = this.props;
    const { productScanned, deliveryProduct } = this.state;

    return productScanned || deliveryMode ? (
      <ProductDelivery
        deliveryId={deliveryId}
        barcode={productScanned}
        product={deliveryProduct}
        onClose={this.hideProductDelivery}
        onSelectProduct={this.setDeliveryProduct}
        onResetProduct={this.resetDeliveryProduct}
        onProductAdded={this.navigateToProduct}
      />
    ) : null;
  }
}

const mapStateToProps = (state) => {
  const { products, adminViewState } = state;
  const delivery = adminViewState.get('productsDelivery');

  const product = Number.isInteger(delivery) && products
    ? products.find(p => p.id === delivery)
    : null;

  return {
    products,
    product,
    deliveryMode: !!delivery
  };
};

const mapDispatchToProps = (dispatch) => ({
  toggleDelivery: delivery => dispatch(toggleProductsDelivery(delivery))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductsBarcodeListener);
