import React, { Component } from 'react';
import { connect } from 'react-redux';
import { config } from '@Utils/preference-keys';
import {
  fetchCompanyInfo, saveCompanyInfo, saveContactInfo, deleteContactInfo
} from '@State/preferences-actions';
import CompanyInfo from './company-info';
import Contact from './contact';

class ContactsConfig extends Component {
  UNSAFE_componentWillMount() {
    if (!this.props.companyInfo) {
      this.props.fetchCompanyInfo();
    }
  }

  render() {
    const {
      companyInfo, contacts, saveCompanyInfo, saveContactInfo, stableId
    } = this.props;

    const ownerContact = contacts && contacts.get('Owner');
    const billingContact = contacts && contacts.get('Billing');
    const privacyContact = contacts && contacts.get('PrivacyPolicy');

    return (
      <div className="subnav-container">
        <div className="row">
          <div className="col-xs-8">
            <CompanyInfo companyInfo={companyInfo && companyInfo.toJS()} onSubmit={saveCompanyInfo} />
            <br />

            <Contact
              contactType="Owner"
              contact={ownerContact}
              onSubmit={saveContactInfo}
              title="Kontoägare"
              description="Kontaktuppgifter till den som är ansvarig för Cliento hos er."
            />
            <br />

            <Contact
              isOptional
              contactType="PrivacyPolicy"
              contact={privacyContact}
              onSubmit={saveContactInfo}
              title="Personuppgiftsansvarig"
              description="Ange dessa uppgifter om ni har en annan kontakt för personuppgiftshantering."
            />
            <br />

            <Contact
              isOptional
              contactType="Billing"
              contact={billingContact}
              onSubmit={saveContactInfo}
              title="Fakturor och kvitton"
              description="Ange dessa uppgifter om ni har en annan kontakt för kvitton och fakturor."
            />
          </div>
          <div className="col-xs-4">
            <h4>Allmänna Villkor</h4>
            <p>
              <a href="https://cliento.com/docs/cliento_villkor.pdf" target="_blank">
                <i className="far fa-file-pdf" /> Allmänna villkor för Cliento (PDF)
              </a>
            </p>
            <p className="text-muted">
              Här hittar du bland annat information om uppsägningstid, avslut
              och personuppgiftsbiträdesavtal. Genom att använda tjänsten så
              godkänner du dessa villkor.
            </p>
            <br />

            <h4>Information till dina kunder</h4>
            <p>
              <a href={`https://cliento.com/gdpr/${stableId}`} target="_blank">
                <i className="fa fa-external-link-alt" /> Information om personuppgiftsbehandling
              </a>
            </p>
            <p className="text-muted">
              Här hittar du information till dina kunder om vilken behandling av
              personuppgifter som utförs i Cliento. Bl.a ändamål, laglig grund
              och lagringsperiod.
            </p>
            <br />

            <h4>Läs mer om GDPR</h4>
            <p>
              <a href="https://verksamt.se/avtal-fakturering/personuppgifter" target="_blank">
                <i className="fa fa-external-link-alt" /> Läs mer om GDPR på Verksamt.se
              </a>
            </p>
            <p className="text-muted">
              Här hittar du en enkel sammanfattning av GDPR och vad det innebär
              för dig som företagare vid hantering av personuppgifter.
            </p>
            <br />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { preferencesViewState, locationConfig } = state;

  return {
    companyInfo: preferencesViewState.get('companyInfo'),
    contacts: preferencesViewState.get('contacts'),
    stableId: locationConfig.get(config.stableId)
  };
};

const mapDispatchToProps = (dispatch, { match }) => {
  return {
    fetchCompanyInfo: () => {
      dispatch(fetchCompanyInfo());
    },
    saveCompanyInfo: (data) => {
      const orgLoc = `${match.params.org}/${match.params.loc}`;
      return dispatch(saveCompanyInfo(orgLoc, data));
    },
    saveContactInfo: (contactType, data) => {
      if (data.useContactInfo === 'true') {
        return dispatch(saveContactInfo(contactType, data));
      }
      return dispatch(deleteContactInfo(contactType));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactsConfig);
