import React, { Component } from 'react';
import ModalDialog from '@Components/dialogs/modal-dialog';
import CurrencyUtil from '@Utils/currency-util';
import {
  maxLength, convertToFloat, convertToString, isValidInput
} from '@Utils/numpad-util';
import Numpad from '@Components/inputs/numpad';
import CalcDisplay from '@Components/ui/calc-display';

class GiftCardSelectAmount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      amount: '',
      isValid: false
    };
  }

  UNSAFE_componentWillMount() {
    this.setState({
      amount: convertToString(this.props.amount)
    });
  }

  componentDidMount() {
    this.handleNumpadUpdate(this.state.amount);
  }

  handleNumpadUpdate = value => {
    if (isValidInput(value, maxLength)) {
      const isValid = CurrencyUtil.isValidCashAmount(value) && value > 0;
      this.setState({ amount: value, isValid });
    }
  };

  onSubmit = () => {
    return this.props.onSubmit(convertToFloat(this.state.amount));
  };

  render() {
    const { title, onClose } = this.props;
    const { amount, isValid } = this.state;

    return (
      <ModalDialog
        title={title}
        onClose={onClose}
        closeOnClickOutside={false}
        buttons={[{
          name: 'Fortsätt',
          primary: true,
          onClick: this.onSubmit,
          disabled: !isValid
        }]}
      >
        <CalcDisplay
          large
          align="center"
        >
          {CurrencyUtil.accountCurrency(convertToFloat(amount), 2)}
        </CalcDisplay>
        <Numpad
          onChange={this.handleNumpadUpdate}
          onEnter={isValid ? this.onSubmit : null}
          input={amount}
        />
      </ModalDialog>
    );
  }
}

export default GiftCardSelectAmount;
