import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleBKFModal } from '@State/bkf/actions';
import { bkfModals } from '@State/bkf/constants';
import EventsModal from '@Components/dialogs/events-modal';

const BookingEventsModal = () => {
  const dispatch = useDispatch();
  const bookingId = useSelector(({ bkf }) => bkf.get('id'));

  const onCloseForm = () => {
    dispatch(toggleBKFModal(bkfModals.events, false));
  };

  return (
    <EventsModal bookingId={bookingId} onClose={onCloseForm} />
  );
};

export default BookingEventsModal;
