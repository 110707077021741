import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field } from 'redux-form';
import { getSysAdmin } from '@State/selectors';
import { fetchPartners } from '@State/admin-config-actions';
import { GrayText, BoldText } from '@Components/dialogs/dialog-styles';
import SelectField from '@Components/inputs/select-field';
import AlertWithIcon from '@Components/ui/alert-with-icon';

const CreatePosUnitType = ({
  vunitType, termsAccepted, setTermsAccepted
}) => {
  const dispatch = useDispatch();
  const isSysAdmin = useSelector(getSysAdmin);
  const partnersById = useSelector(state => state.partnersById);

  useEffect(() => {
    if (isSysAdmin && partnersById.isEmpty()) {
      dispatch(fetchPartners());
    }
  }, []);

  return (
    <>
      <div className="form-group">
        <Field
          name="vunitType"
          component={SelectField}
          label="Välj typ av kassa"
        >
          {isSysAdmin && <option value="Onprem">Kassa med kontrollenhet</option>}
          {isSysAdmin && <option value="OnpremNoCCU">Kassa utan kontrollenhet</option>}
          <option value="OnpremDemo">TEST - För utvärdering och demo</option>
          {isSysAdmin && <option value="Ecom">Onlinebetalningar</option>}
        </Field>
      </div>
      {isSysAdmin && vunitType === 'Ecom' && (
        <div className="form-group">
          <Field
            name="partnerId"
            component={SelectField}
            label="Välj partner"
          >
            <option value="">Ingen partner</option>
            {partnersById.valueSeq().map((partner) => {
              const { id, name } = partner.toObject();
              return <option key={id} value={id}>{name}</option>;
            })}
          </Field>
        </div>
      )}
      {isSysAdmin ? (
        <div className="checkboxEl">
          <input type="checkbox" checked={termsAccepted} onChange={setTermsAccepted} id="acceptTerms" />
          <label htmlFor="acceptTerms">
            <BoldText>Jag godkänner Clientos allmänna villkor</BoldText>
            <GrayText>
              Debitering av kassaregister tillkommer. Om kontrollenhet registreras
              skickas uppgifterna till Skatteverket.
            </GrayText>
          </label>
        </div>
      ) : (
        <AlertWithIcon warning icon="fa fa-circle-exclamation">
          Ingen kontrollenhet registreras. Kassan får ej användas för försäljning i din verksamhet.
          Kontakta support för att registrera kassa med kontrollenhet.
        </AlertWithIcon>
      )}
    </>
  );
};

export default CreatePosUnitType;
