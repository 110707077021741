import React, { Component } from 'react';
import RichTextEditor, { createValueFromString, createEmptyValue } from 'react-rte';

const defaultToolbarConfig = {
  display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN'],
  INLINE_STYLE_BUTTONS: [
    { label: 'Fet', style: 'BOLD' },
    { label: 'Kursiv', style: 'ITALIC' }
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: 'Brödtext', style: 'unstyled' },
    { label: 'Rubrik 1', style: 'header-one' },
    { label: 'Rubrik 2', style: 'header-two' },
    { label: 'Rubrik 3', style: 'header-three' }
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: 'UL', style: 'unordered-list-item' },
    { label: 'OL', style: 'ordered-list-item' }
  ]
};

const simpleToolbarConfig = {
  display: ['INLINE_STYLE_BUTTONS', 'LINK_BUTTONS'],
  INLINE_STYLE_BUTTONS: [
    { label: 'Fet', style: 'BOLD' },
    { label: 'Kursiv', style: 'ITALIC' }
  ]
};

class RichTextEditorField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: createEmptyValue()
    };
  }

  componentDidMount() {
    const { value } = this.props.input;

    if (value) {
      this.setValue(value);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const valueChanged = this.props.input.value !== nextProps.input.value;

    if (valueChanged && nextProps.meta.pristine) {
      this.setValue(nextProps.input.value);
    }
  }

  setValue = (value) => {
    this.setState({ value: createValueFromString(String(value), 'markdown') });
  };

  onChange = (value) => {
    this.setState({ value });
    const normalized = value.toString('markdown').replace('++', '');
    this.props.input.onChange(normalized);
  };

  render() {
    const { simple, className, editorClassName } = this.props;
    const toolbarConfig = simple ? simpleToolbarConfig : defaultToolbarConfig;

    return (
      <div className={className}>
        <RichTextEditor
          toolbarConfig={toolbarConfig}
          value={this.state.value}
          onChange={this.onChange}
          className="text-editor"
          editorClassName={editorClassName ?? 'text-editor-canvas'}
          toolbarClassName="text-editor-toolbar"
        />
      </div>
    );
  }
}

export default RichTextEditorField;
