import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import ModalDialog from '@Components/dialogs/modal-dialog';
import Button from '@Components/ui/button';
import { getAvailableResources, hasAssociatedResource } from '@Components/admin/staff-config/staff-config-selectors';
import LabelSwitchInput from '@Components/ui/label-switch-input';
import { addUserResource, removeUserResource } from '@State/users-actions';
import { ModalContent, ModalFooter } from '@Components/dialogs/dialog-styles';
import AlertWithIcon from '@Components/ui/alert-with-icon';

const sortResources = (a, b) => a.name.localeCompare(b.name, 'sv');

const assignResourceRow = (resource, onChangeToggle) => (
  <tr key={resource.id}>
    <td>
      <LabelSwitchInput
        noBorder
        id={resource.id}
        key={resource.id}
        isOn={resource.isChecked}
        label={resource.name}
        handleToggle={() => onChangeToggle(resource)}
        switchLocked={resource.isLocked}
        disabled={resource.isLocked}
      />
    </td>
  </tr>
);

const StaffAssignResources = ({ user, onClose }) => {
  const dispatch = useDispatch();
  const resourcesById = useSelector(state => state.resourcesById);
  const availableResources = useSelector(state => getAvailableResources(state, { id: user.get('id') }));

  const mappedResources = resourcesById.valueSeq().map(resource => {
    const isChecked = hasAssociatedResource(user, resource.id);
    const isLocked = !availableResources.get(resource.id);
    return { ...resource, isLocked, isChecked };
  });

  const openResources = mappedResources.filter(r => !r.isLocked);
  const lockedResources = mappedResources.filter(r => r.isLocked);

  const onChangeToggle = (resource) => {
    const isActive = hasAssociatedResource(user, resource.id);
    if (isActive) {
      return dispatch(removeUserResource(user.get('id'), resource));
    }
    return dispatch(addUserResource(user.get('id'), resource));
  };

  return (
    <ModalDialog
      contentSize="medium"
      title="Koppla till resurser"
      closeButtonText="Stäng"
      onClose={onClose}
    >
      <ModalContent>
        <AlertWithIcon icon="fa fa-info-circle">
          Kopplingen styr vilka resurser en användare får notiser för.
          Den ger också användaren åtkomst till resursens schema.
        </AlertWithIcon>
        {!openResources.isEmpty() && (
          <table className="table table-striped">
            <tbody>
              {openResources.sort(sortResources).map((resource) => {
                return assignResourceRow(resource, onChangeToggle);
              })}
            </tbody>
          </table>
        )}
        {!lockedResources.isEmpty() && (
          <table className="table table-striped">
            <tbody>
              {lockedResources.sort(sortResources).map((resource) => {
                return assignResourceRow(resource, onChangeToggle);
              })}
            </tbody>
          </table>
        )}
        <div className="text-muted mt2">
          <i className="fa fa-lock" /> = Resursen är kopplad till en annan användare
        </div>
      </ModalContent>
      <ModalFooter>
        <Button gray small onClick={onClose}>Stäng</Button>
      </ModalFooter>
    </ModalDialog>
  );
};

StaffAssignResources.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  onClose: PropTypes.func
};

export default StaffAssignResources;
