import React, { useState } from 'react';
import DialogAlert from '@Components/dialogs/dialog-alert';
import { getSectionUrl, navigate } from '@Utils/navigate';

const FinishStockTake = ({ routeParams, stockTake, stockReportId, onClose, onSubmit }) => {
  const [progress, setProgress] = useState(false);
  const [saved, setSaved] = useState(false);

  const handleSubmit = () => {
    setProgress(true);
    return onSubmit().then(() => {
      setSaved(true);
    }).catch(() => {
      setProgress(false);
    });
  };

  const onShowReport = () => {
    navigate(getSectionUrl(routeParams, 'reports/stock', stockReportId));
  };

  if (saved) {
    return (
      <DialogAlert
        success
        title="Klarmarkera inventering"
        icon="fa fa-check-circle"
        text="Inventeringen har klarmarkerats och en lagerrapport har skapats"
        onClose={onClose}
        buttons={[{
          primary: true,
          name: 'Visa rapport',
          onClick: onShowReport
        }, {
          gray: true,
          name: 'Stäng',
          onClick: onClose
        }]}
      />
    );
  }

  return (
    <DialogAlert
      title="Klarmarkera inventering"
      icon="fa fa-box"
      text={`Klarmarkera inventering och uppdatera lagersaldo för ${stockTake.itemCount} artiklar?`}
      buttons={[{
        name: 'Uppdatera lagersaldo',
        primary: true,
        onClick: handleSubmit,
        loading: progress
      }, {
        name: 'Avbryt',
        gray: true,
        onClick: onClose
      }]}
    />
  );
};

export default FinishStockTake;
