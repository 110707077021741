import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import RichTextEditorField from '@Components/inputs/rich-text-editor';
import HookFormInput from '@Components/inputs/hook-form-input';
import { smsSenderHookValidation } from '@Utils/validation';
import Panel from '@Components/ui/styled/panel';
import SmsLengthLabel from '@Components/inputs/sms-length-label';
import { emailReplyToEnabled } from '../campaign-helpers';

const CampaignContent = () => {
  const { watch, setValue, register, unregister, getValues, formState: { errors } } = useFormContext();
  const [type, smsText = '', emailReplyTo, emailText] = watch(['campaignType', 'smsText', 'emailReplyTo', 'emailText']);

  useEffect(() => {
    return () => {
      const { smsSender, smsText, emailFromName, emailReplyTo, emailSubject, emailText } = getValues();

      if (!smsSender) unregister('smsSender');
      if (!smsText) unregister('smsText');
      if (!emailFromName) unregister('emailFromName');
      if (!emailReplyTo) unregister('emailReplyTo');
      if (!emailSubject) unregister('emailSubject');
      if (!emailText) unregister('emailText');
    };
  }, []);

  const isSms = type === 'Sms';
  const senderClass = errors?.smsSender ? 'form-group has-error' : 'form-group';
  const [showReplyTo, setShowReplyTo] = useState(!isSms && emailReplyToEnabled(emailReplyTo));

  const toggleShowReplyTo = (ev) => {
    setShowReplyTo(ev.target.checked);

    if (!ev.target.checked) {
      setValue('emailReplyTo', null);
      unregister('emailReplyTo');
    }
  };

  return (
    <>
      {isSms ? (
        <>
          <div className={senderClass}>
            <h4>Avsändare</h4>
            <p className="text-muted">
              Max 11 tecken, enbart bokstäver (ej åäö), siffror och understreck (_)<br />
              Kan även vara ett telefonnummer som mottagaren kan svara till
            </p>
            <HookFormInput id="smsSender" placeholder="Ange avsändare" registerOptions={smsSenderHookValidation} />
          </div>
          <div className="form-group mt3 mb3">
            <h4>Meddelande</h4>
            <p className="text-muted">
              Skriv ditt meddelande. Meddelandet kan vara upp till 916 tecken.
              Observera att om meddelandet överstiger 160 tecken så kommer mer än
              ett meddelande per mottagare att skickas.
            </p>
            <div>
              <textarea {...register('smsText', { required: true })} rows={5} className="form-control" maxLength={916} />
              <p className="mt1">
                <SmsLengthLabel campaign text={smsText} />
              </p>
            </div>
          </div>
        </>
      ) : (
        <>
          <h4>Avsändare</h4>
          <Panel>
            <HookFormInput id="emailFromName" placeholder="Ange avsändare" registerOptions={{ required: true }} />
            <div className="checkbox">
              <label>
                <input type="checkbox" checked={showReplyTo} onChange={toggleShowReplyTo} />
                Tillåt att kunder svarar på mejlutskick
              </label>
            </div>
            {showReplyTo && (
              <>
                <p className="text-muted">
                  Ange en mejladress så att kunden kan svara på mejlutskick.
                </p>
                <HookFormInput id="emailReplyTo" type="email" registerOptions={{ required: true }} />
              </>
            )}
          </Panel>
          <h4>Ämne</h4>
          <HookFormInput id="emailSubject" registerOptions={{ required: true }} />
          <div className="form-group">
            <h4>Innehåll</h4>
            <RichTextEditorField
              meta={{ pristine: false }}
              input={{ value: emailText, onChange: val => setValue('emailText', val) }}
              editorClassName="text-editor-fixed"
            />
          </div>
        </>
      )}
    </>
  );
};

export default CampaignContent;
