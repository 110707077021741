import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getSysAdmin } from '@State/selectors';
import Button from '@Components/ui/button';
import Panel from '@Components/ui/styled/panel';
import { fetchPrinters, addPrinter } from '@State/pos-config-actions';
import AddPrinterModal from './add-printer-modal';
import PrinterPanel from './printer-panel';

class PrintersConfig extends Component {
  state = {
    showAddPrinter: false
  };

  showAddPrinter = () => this.setState({ showAddPrinter: true });

  hideForm = (ev) => {
    ev && ev.preventDefault();
    this.setState({ showAddPrinter: false });
  };

  addPrinter = ({ macAddress }) => {
    return this.props.addPrinter({ macAddress })
      .then(() => this.hideForm());
  };

  componentDidMount() {
    this.props.fetchPrinters();
  }

  render() {
    const { showAddPrinter } = this.state;
    const { printers, isSysAdmin } = this.props;

    return (
      <div className="columns-container">
        <div className="columns-content">
          <div className="columns-content-container width-large">
            <div className="columns-content-body with-padding">
              {printers && printers.map(printer => (
                <PrinterPanel key={printer.get('id')} printer={printer} />
              ))}
              {printers && printers.isEmpty() && (
                <Panel>
                  Det finns inga skrivare för kontot
                </Panel>
              )}
              {isSysAdmin && (
                <Button small primary onClick={this.showAddPrinter}>Lägg till skrivare</Button>
              )}
              {showAddPrinter && (
                <AddPrinterModal
                  onSubmit={this.addPrinter}
                  onClose={this.hideForm}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { printers } = state;

  return {
    printers,
    isSysAdmin: getSysAdmin(state)
  };
};

const mapDispatchToProps = dispatch => ({
  fetchPrinters: () => dispatch(fetchPrinters()),
  addPrinter: printer => dispatch(addPrinter(printer))
});

export default connect(mapStateToProps, mapDispatchToProps)(PrintersConfig);
