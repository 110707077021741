import React, { useState } from 'react';
import TimePicker from 'react-times';
import TimeField from 'react-simple-timefield';
import { classes } from '@Components/ui/utils';

const TimePickerInput = ({
  timeConfig, time, onChange, disabled
}) => {
  const [hasFocus, setFocus] = useState(false);
  const onFocus = (ev) => {
    ev.target.select();
    setFocus(true);
  };
  const onBlur = () => setFocus(false);

  const classListInput = classes({
    'input-group': true,
    'input-modern-style': true,
    focus: hasFocus,
    disabled
  });

  return (
    <div className={classListInput}>
      <TimePicker
        trigger={(
          <TimeField
            value={time}
            onChange={onChange}
            input={<input onFocus={onFocus} onBlur={onBlur} />}
          />
        )}
        time={time}
        timeFormat="HH:mm"
        timeConfig={timeConfig}
        disabled={disabled}
        theme="classic"
        withoutIcon
      />
      <span className="input-modern-style-icon input-group-addon">
        <i className="far fa-clock" />
      </span>
    </div>
  );
};

export default TimePickerInput;
