import { defineMessages } from 'react-intl';

export default defineMessages({

  lblSendReceiptTo: {
    id: 'calendar.bkf.send-receipt.lblSendReceiptTo',
    defaultMessage: 'Send receipt to:'
  },
  placeholderName: {
    id: 'calendar.bkf.send-receipt.placeholderName',
    defaultMessage: 'Name'
  },
  placeholderEmail: {
    id: 'calendar.bkf.send-receipt.placeholderEmail',
    defaultMessage: 'Email'
  },
  btnCancel: {
    id: 'calendar.bkf.send-receipt.btnCancel',
    defaultMessage: 'Cancel'
  },
  btnSend: {
    id: 'calendar.bkf.send-receipt.btnSend',
    defaultMessage: 'Send'
  },
  btnSendProgress: {
    id: 'calendar.bkf.send-receipt.btnSendProgress',
    defaultMessage: 'Sending...'
  }
});
