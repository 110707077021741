import { createSelector } from 'reselect';
import { getRouteParams } from '@State/selectors';
import { getArticleName } from '@Utils/pos-utils';

export const itemFilter = (item, filter) => {
  const itemLower = item.toLowerCase();
  return String(filter).toLowerCase().split(' ').filter(s => s)
    .every(part => itemLower.indexOf(part) !== -1);
};

const groupFilter = (itemIds, filteredItems) => {
  let match = false;
  itemIds.forEach(serviceId => {
    if (filteredItems.has(serviceId)) {
      match = true;
    }
  });
  return match;
};

export const getFilteredResources = createSelector(
  (state, props) => props?.filter,
  state => state.resourcesById,
  (filter, resourcesById) => {
    if (!filter) {
      return resourcesById;
    }
    return resourcesById.filter(resource => {
      return itemFilter(resource.name, filter);
    });
  }
);

export const getFilteredResourceGroups = createSelector(
  (state, props) => props?.filter,
  (state, props) => props?.excludeEmptyGroups,
  state => state.orderedGroups,
  getFilteredResources,
  (filter, excludeEmptyGroups, orderedGroups, filteredResources) => {
    return orderedGroups.filter(group => {
      return (!filter || groupFilter(group.get('resourceIds'), filteredResources))
        && (!excludeEmptyGroups || group.get('resourceIds').count() > 0);
    });
  }
);

export const getAdminResourceGroups = createSelector(
  getFilteredResourceGroups,
  (filteredGroups) => {
    return filteredGroups.filter(group => group.get('id') !== 0);
  }
);

export const getIsAddonServicesSection = createSelector(
  getRouteParams,
  (routeParams) => {
    return routeParams?.subsection === 'addon-services';
  }
);

export const getFilteredServices = createSelector(
  (state, props) => props?.filter,
  state => state.servicesById,
  getIsAddonServicesSection,
  (filter, servicesById, addonServices) => {
    return servicesById.filter(service => {
      const filterMatch = !filter || itemFilter(service.name, filter);
      return filterMatch && service.addon === addonServices;
    });
  }
);

export const getFilteredServiceGroups = createSelector(
  (state, props) => props?.filter,
  state => state.orderedServiceGroups,
  getFilteredServices,
  getIsAddonServicesSection,
  (filter, orderedServiceGroups, filteredServices, addonServices) => {
    return orderedServiceGroups.filter(group => {
      const serviceIds = group.get('serviceIds');
      const filterMatch = !filter || groupFilter(serviceIds, filteredServices);
      const addonMatch = group.get('addon') == addonServices;
      return filterMatch && addonMatch;
    });
  }
);

export const getFilteredProducts = createSelector(
  (state, props) => props?.filter,
  state => state.products,
  (filter, products) => {
    if (!filter) {
      return products;
    }
    return products.filter(product => {
      return itemFilter(getArticleName(product), filter)
        || product.EAN.indexOf(filter) !== -1;
    });
  }
);

export const getFilteredProductGroups = createSelector(
  (state, props) => props?.filter,
  state => state.productGroups,
  getFilteredProducts,
  (filter, productGroups, filteredProducts) => {
    if (!filter) {
      return productGroups;
    }
    return productGroups.filter(group => {
      return groupFilter(group.get('articleIds'), filteredProducts);
    });
  }
);
