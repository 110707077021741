import axios from 'axios';
import moment from 'moment';
import { DATE_PICKER_FORMAT } from '@Utils/time-constants';

import {
  axiosDefault,
  axiosErrorHandler,
  checkStatusAxios,
  prefixUrl
} from '@Utils/ajax-util';

export const STAFF_LIST_FETCHED = 'STAFF_LIST_FETCHED';
export const STAFF_ENTRY_UPDATED = 'STAFF_ENTRY_UPDATED';
export const STAFF_ENTRY_DELETED = 'STAFF_ENTRY_DELETED';
export const STAFF_ENTRY_CREATED = 'STAFF_ENTRY_CREATED';
export const STAFF_PNO_UPDATED = 'STAFF_PNO_UPDATED';

export const STAFF_USER_ENTRIES_FETCHED = 'STAFF_USER_ENTRIES_FETCHED';
export const STAFF_ENTRY_REPORT_FETCHED = 'STAFF_ENTRY_REPORT_FETCHED';

function getStaffUrl(url) {
  return prefixUrl(`/staffjournal/staff${url}`);
}

export function sortStaffEntries(a, b) {
  if ((b.sortingTs === a.sortingTs) && b.deleted || a.deleted) return 1;
  return moment(b.sortingTs).valueOf() > moment(a.sortingTs).valueOf() ? 1 : -1;
}

export function fetchStaff() {
  return (dispatch) => {
    const url = getStaffUrl('/');
    const config = axiosDefault();

    return axios.get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then(({ entries }) => dispatch({ type: STAFF_LIST_FETCHED, entries }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function fetchStaffEntries(id, days = 90) {
  return (dispatch) => {
    const url = getStaffUrl(`/${id}/entries/?days=${days}`);
    const config = axiosDefault();

    return axios.get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then(({ entries }) => {
        dispatch({ type: STAFF_USER_ENTRIES_FETCHED, entries });
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function fetchStaffEntryReport() {
  const start = moment().subtract(2, 'year').format(DATE_PICKER_FORMAT);
  const end = moment().format(DATE_PICKER_FORMAT);

  return (dispatch) => {
    const url = getStaffUrl(`/entries/?start=${start}&end=${end}`);
    const config = axiosDefault();

    return axios.get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then(({ entries }) => {
        dispatch({ type: STAFF_ENTRY_REPORT_FETCHED, entries });
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function deleteEntry({ entryId, ...body }) {
  return (dispatch) => {
    const url = getStaffUrl(`/entries/${entryId}/delete`);
    const config = axiosDefault();

    return axios.post(url, body, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => dispatch({ type: STAFF_ENTRY_DELETED, entryId }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function updateEntry({ userId, entryId, ...body }) {
  return (dispatch) => {
    const url = getStaffUrl(`/${userId}/entries/${entryId}`);
    const config = axiosDefault();

    return axios.put(url, body, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function createEntry({ userId, ...body }) {
  return (dispatch) => {
    const url = getStaffUrl(`/${userId}/entries/`);
    const config = axiosDefault();

    return axios.post(url, body, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => dispatch({ type: STAFF_ENTRY_CREATED, data: { ...body } }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function updatedEntriesCheckIn({ userId, ...body }) {
  return (dispatch) => {
    const url = getStaffUrl(`/${userId}/checkin`);
    const config = axiosDefault();

    return axios.post(url, body, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}
export function updatedEntriesCheckOut({ userId, ...props }) {
  return (dispatch) => {
    const url = getStaffUrl(`/${userId}/checkout`);
    const config = axiosDefault();

    return axios.post(url, { ...props }, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}
