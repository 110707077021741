import React from 'react';
import styled, { css } from 'styled-components';
import { colors } from '@Components/ui/styled/variables';

const StatusIcon = styled.i`
  margin-right: 6px;
  vertical-align: baseline;

  ${props => props.small && css`
    font-size: 11px;
  `}
  ${props => props.error && css`
    color: ${colors.danger};
  `}
  ${props => props.online && css`
    color: ${colors.greenCircle};
  `}
  ${props => props.progress && css`
    color: ${colors.gray};
  `}
  ${props => props.offline && css`
    color: ${colors.grayCircle};
  `}
`;

const PosTerminalStatus = ({ terminal, ...props }) => {
  const { deviceStatus, connectionStatus } = terminal.get('terminalStatus').toObject();
  const isOffline = connectionStatus === 'Offline';
  const isOnline = connectionStatus === 'Online';
  const isReady = deviceStatus === 'Ready';
  const isBusy = deviceStatus === 'Busy';

  if (isOnline && isReady) {
    return <StatusIcon online {...props} className="fa fa-fw fa-circle" />;
  }
  if (isBusy) {
    return <StatusIcon progress {...props} className="fa fa-fw fa-circle-notch fa-spin" />;
  }
  if (isOffline) {
    return <StatusIcon error {...props} className="fa fa-fw fa-circle" />;
  }
  return <StatusIcon offline {...props} className="fa fa-fw fa-circle" />;
};

export default PosTerminalStatus;
