import Immutable, { fromJS, List } from 'immutable';

import {
  GET_BOOKING_LIST, GET_GROUP_BOOKING_LIST, GET_BOOKING_REPORT, GET_PRE_PAYMENT_REPORT,
  GET_SALES_REPORT, GET_SMS_REPORT, GET_STAFF_REPORT, GET_DEFERRED_SALES_REPORT,
  GET_STAFF_REPORTS, SET_STAFF_REPORT_APPROVED, SET_STAFF_REPORT_SUMMARY, GET_GIFT_CARD_REPORT,
  GET_STOCK_REPORTS, GET_STOCK_REPORT_ITEMS, CREATE_STOCK_REPORT, DELETE_STOCK_REPORT
} from './report-actions';

import { CLEAR_LOCATION_STATE } from './account-actions';

export function reportsViewState(state = Immutable.Map({}), action = null) {
  switch (action.type) {
    case CLEAR_LOCATION_STATE:
      return state.clear();

    case GET_BOOKING_LIST:
    case GET_GROUP_BOOKING_LIST:
    case GET_BOOKING_REPORT:
    case GET_SMS_REPORT:
    case GET_SALES_REPORT:
    case GET_DEFERRED_SALES_REPORT:
    case GET_PRE_PAYMENT_REPORT:
    case GET_GIFT_CARD_REPORT:
    case GET_STAFF_REPORT:
    case GET_STAFF_REPORTS:
    case GET_STOCK_REPORTS:
      return state.merge(action.state);

    case SET_STAFF_REPORT_APPROVED: {
      const { reportId, rowId, approvedTs } = action;
      const staffReports = state.get('staffReports');
      const reportIndex = staffReports.findIndex(r => r.get('reportId') === reportId);
      const rowIndex = staffReports.getIn([reportIndex, 'rows']).findIndex(r => r.get('id') === rowId);
      return state.setIn(['staffReports', reportIndex, 'rows', rowIndex, 'approvedTs'], approvedTs);
    }

    case SET_STAFF_REPORT_SUMMARY: {
      const { reportId, rowId, summary } = action;
      const staffReports = state.get('staffReports');
      const reportIndex = staffReports.findIndex(r => r.get('reportId') === reportId);
      const rowIndex = staffReports.getIn([reportIndex, 'rows']).findIndex(r => r.get('id') === rowId);
      return state.setIn(['staffReports', reportIndex, 'rows', rowIndex, 'summary'], summary);
    }

    case CREATE_STOCK_REPORT: {
      const report = fromJS(action.state.report);
      const current = state.get('stockReports') || List();
      const reports = current.insert(0, report);
      return state.set('stockReports', reports);
    }

    case GET_STOCK_REPORT_ITEMS:
      return state.merge(action.state);

    case DELETE_STOCK_REPORT: {
      const reportId = parseInt(action.state.reportId);
      const index = state.get('stockReports').findIndex(r => r.get('id') === reportId);
      return index !== -1 ? state.deleteIn(['stockReports', index]) : state;
    }

    default:
      return state;
  }
}
