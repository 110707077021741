import React, { useMemo } from 'react';
import NavTabsLink from '@Components/ui/nav-tabs-link';
import { useSelector } from 'react-redux';

const PosConfigSubNav = ({ routeParams, enablePos, isSysAdmin }) => {
  const posUnits = useSelector(state => state.posUnits);

  const sections = useMemo(() => {
    const hasPos = posUnits?.some(u => !u.get('ecom'));
    const navItems = [
      { navId: 'settings', name: 'Kassaregister' }
    ];
    enablePos && hasPos && navItems.push({ navId: 'users', name: 'Användare' });
    enablePos && hasPos && navItems.push({ navId: 'terminal', name: 'Skrivare & kortmaskin' });
    enablePos && hasPos && navItems.push({ navId: 'payment', name: 'Betalsätt' });
    navItems.push({ navId: 'accounting', name: 'Bokföring' });
    isSysAdmin && navItems.push({ navId: 'prefs', name: 'Org prefs' });
    return navItems;
  }, [enablePos, isSysAdmin, posUnits]);

  return (
    <NavTabsLink
      subUrl="admin/pos"
      routeParams={routeParams}
      sections={sections}
    />
  );
};

export default PosConfigSubNav;
