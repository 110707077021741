import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchFortnoxCustomerCount, syncFortnoxCustomers } from '@State/fortnox-actions';
import ConfirmActionModal from '@Components/ui/confirm-action-modal';
import ProgressButton from '@Components/ui/progress-button';

function estimatedTime(customerCount) {
  const esimatedSeconds = Math.ceil(customerCount / 3.8);
  const esimatedMinutes = Math.ceil(esimatedSeconds / 60);

  return esimatedSeconds > 60
    ? `${esimatedMinutes} minuter`
    : `${esimatedSeconds} sekunder`;
}

const PosOrgAccountingSyncCustomers = (props) => {
  const { posOrg } = props;
  const posOrgId = posOrg.get('id');

  const dispatch = useDispatch();
  const [syncStarted, setSyncStarted] = useState(false);
  const [showSyncCustomers, setShowSyncCustomers] = useState(false);
  const [customerCount, setCustomerCount] = useState(0);

  const handleSyncCustomers = useCallback(() => {
    return dispatch(fetchFortnoxCustomerCount(posOrgId))
      .then(res => {
        setCustomerCount(res);
        setShowSyncCustomers(true);
      });
  }, [posOrgId]);

  const handleStartSync = useCallback(() => {
    return dispatch(syncFortnoxCustomers(posOrgId))
      .then(() => {
        setSyncStarted(true);
      });
  }, [posOrgId]);

  return (
    <>
      <ProgressButton outlinePrimary small onClick={handleSyncCustomers} disabled={syncStarted}>
        Synkronisera kunder
      </ProgressButton>
      {showSyncCustomers && (
        <ConfirmActionModal
          onConfirm={handleStartSync}
          onCancel={() => setShowSyncCustomers(false)}
          confirmMessage={(
            <div>
              {`Starta synkronisering av ${customerCount} kunder?`}<br />
              {`Beräknad tidsåtgång ca ${estimatedTime(customerCount)}.`}
            </div>
          )}
          successMessage="Synkronisering startad"
          confirmButtonText="Synkronisera kunder"
        />
      )}
    </>
  );
};

export default PosOrgAccountingSyncCustomers;
