/* eslint-disable newline-per-chained-call */
import color from 'color';
import { isDark } from '@Utils/colors';

export const colors = {
  booking: color().rgb(0, 204, 255),
  cancelled: color().rgb(100, 100, 100),
  reservation: color().rgb(255, 220, 45),
  show: color().rgb(38, 199, 102),
  noShow: color().rgb(206, 34, 34),
  web: color().rgb(204, 0, 255),
  fuchsia: color().rgb(200, 50, 200),
  turquoise: color().rgb(14, 204, 185),
  dropIn: color().rgb(60, 121, 204).lighten(0.1),
  askedForPerson: color().rgb(255, 210, 170).darken(0.3),
  timeSlot: color().rgb(240, 250, 240)
};

export const visualStylesHC = {
  askedForPerson: {
    background: colors.askedForPerson.rgbString(),
    border: `1px solid ${colors.askedForPerson.clone().lighten(1.0).rgbString()}`
  },

  dropIn: {
    background: colors.dropIn.rgbString(),
    border: `1px solid ${colors.dropIn.clone().lighten(1.0).rgbString()}`
  },

  pending: {
    background: colors.booking.clone().lighten(0.1).desaturate(0.2).alpha(0.5).rgbString(),
    color: colors.booking.clone().darken(1.0).rgbString()
  },

  pendingWeb: {
    background: colors.web.clone().lighten(0.1).desaturate(0.2).alpha(0.5).rgbString(),
    color: colors.web.clone().lighten(1.0).rgbString()
  },

  statuses: {
    Fuchsia: {
      background: colors.fuchsia.clone().lighten(0.1).desaturate(0.2).rgbString(),
      color: colors.fuchsia.clone().lighten(1.0).rgbString(),
      borderLeft: `3px solid ${colors.fuchsia.clone().saturate(1.0).darken(0.4).rgbString()}`
    },
    FuchsiaDrag: {
      background: colors.fuchsia.clone().lighten(0.2).rgbString()
    },

    Turquoise: {
      background: colors.turquoise.clone().lighten(0.1).desaturate(0.2).rgbString(),
      color: colors.turquoise.clone().lighten(2.0).rgbString(),
      borderLeft: `3px solid ${colors.turquoise.clone().saturate(1.0).darken(0.4).rgbString()}`
    },
    TurquoiseDrag: {
      background: colors.turquoise.clone().lighten(0.2).rgbString()
    },

    Web: {
      background: colors.web.clone().lighten(0.1).desaturate(0.2).rgbString(),
      color: colors.web.clone().lighten(1.0).rgbString(),
      borderLeft: `3px solid ${colors.web.clone().saturate(1.0).darken(0.4).rgbString()}`
    },
    WebDrag: {
      background: colors.web.clone().lighten(0.2).rgbString()
    },

    Booked: {
      background: colors.booking.clone().lighten(0.1).desaturate(0.2).rgbString(),
      color: colors.booking.clone().lighten(1.0).rgbString(),
      borderLeft: `3px solid ${colors.booking.clone().saturate(1.0).darken(0.4).rgbString()}`
    },
    BookedDrag: {
      background: colors.booking.clone().lighten(0.2).rgbString()
    },

    Cancelled: {
      background: colors.cancelled.clone().alpha(0.6).rgbString(),
      color: colors.cancelled.clone().lighten(1.5).rgbString(),
      borderLeft: `3px solid ${colors.cancelled.clone().darken(0.4).rgbString()}`
    },

    Show: {
      background: colors.show.clone().lighten(0.1).rgbString(),
      color: colors.show.clone().darken(1.3).rgbString(),
      borderLeft: `3px solid ${colors.show.clone().saturate(1.0).darken(0.5).rgbString()}`
    },
    ShowDrag: {
      background: colors.show.clone().lighten(0.2).rgbString()
    },

    NoShow: {
      background: colors.noShow.clone().lighten(0.1).desaturate(0.2).rgbString(),
      color: colors.noShow.clone().lighten(1.3).rgbString(),
      borderLeft: `3px solid ${colors.noShow.clone().saturate(1.0).darken(0.4).rgbString()}`
    },
    NoShowDrag: {
      background: colors.noShow.clone().lighten(0.2).rgbString()
    },

    Reservation: {
      background: colors.reservation.clone().lighten(0.1).desaturate(0.1).rgbString(),
      color: colors.reservation.clone().darken(0.8).rgbString(),
      borderLeft: `3px solid ${colors.reservation.clone().darken(0.4).rgbString()}`
    },
    ReservationDrag: {
      background: colors.reservation.clone().lighten(0.2).rgbString()
    },

    TimeSlot: {
      background: colors.timeSlot.rgbString(),
      color: colors.timeSlot.clone().darken(1.0).rgbString(),
      border: `1px dashed ${colors.timeSlot.clone().darken(0.5).rgbString()}`
    }
  }
};

export const visualStylesNonHC = {
  askedForPerson: {
    background: colors.askedForPerson.rgbString()
  },

  dropIn: {
    background: colors.dropIn.rgbString()
  },

  pending: {
    background: colors.booking.clone().lighten(0.8).alpha(0.5).rgbString(),
    color: colors.booking.clone().darken(0.6).rgbString()
  },

  pendingWeb: {
    background: colors.web.clone().lighten(0.8).alpha(0.5).rgbString(),
    color: colors.web.clone().darken(0.6).rgbString()
  },

  statuses: {
    Fuchsia: {
      background: colors.fuchsia.clone().lighten(0.8).rgbString(),
      color: colors.fuchsia.clone().darken(0.6).rgbString(),
      borderLeft: `3px solid ${colors.fuchsia.clone().darken(0.1).rgbString()}`
    },
    FuchsiaDrag: {
      fontWeight: 700,
      color: 'white',
      background: colors.fuchsia.clone().lighten(0.1).rgbString(),
      borderLeft: `3px solid ${colors.fuchsia.clone().darken(0.1).rgbString()}`,
      borderRight: 0,
      borderTop: 0,
      borderBottom: 0
    },

    Turquoise: {
      background: colors.turquoise.clone().lighten(0.8).rgbString(),
      color: colors.turquoise.clone().darken(0.6).rgbString(),
      borderLeft: `3px solid ${colors.turquoise.clone().darken(0.1).rgbString()}`
    },
    TurquoiseDrag: {
      fontWeight: 700,
      color: 'white',
      background: colors.turquoise.clone().lighten(0.1).rgbString(),
      borderLeft: `3px solid ${colors.turquoise.clone().darken(0.1).rgbString()}`,
      borderRight: 0,
      borderTop: 0,
      borderBottom: 0
    },

    Booked: {
      background: colors.booking.clone().lighten(0.8).rgbString(),
      color: colors.booking.clone().darken(0.6).rgbString(),
      borderLeft: `3px solid ${colors.booking.clone().darken(0.1).rgbString()}`
    },
    BookedDrag: {
      fontWeight: 700,
      color: 'white',
      background: colors.booking.clone().lighten(0.1).rgbString(),
      borderLeft: `3px solid ${colors.booking.clone().darken(0.1).rgbString()}`,
      borderRight: 0,
      borderTop: 0,
      borderBottom: 0
    },

    Web: {
      background: colors.web.clone().lighten(0.8).rgbString(),
      color: colors.web.clone().darken(0.6).rgbString(),
      borderLeft: `3px solid ${colors.web.clone().darken(0.1).rgbString()}`
    },
    WebDrag: {
      fontWeight: 700,
      color: 'white',
      background: colors.web.clone().lighten(0.1).rgbString(),
      borderLeft: `3px solid ${colors.web.clone().darken(0.1).rgbString()}`,
      borderRight: 0,
      borderTop: 0,
      borderBottom: 0
    },

    Cancelled: {
      background: colors.cancelled.clone().lighten(0.7).alpha(0.4).rgbString(),
      color: colors.cancelled.clone().darken(0.2).rgbString(),
      borderLeft: `3px solid ${colors.cancelled.clone().darken(0.1).rgbString()}`
    },
    CancelledDrag: {
      background: colors.cancelled.clone().lighten(0.1).rgbString()
    },

    Show: {
      background: colors.show.clone().lighten(0.8).rgbString(),
      color: colors.show.clone().darken(0.7).rgbString(),
      borderLeft: `3px solid ${colors.show.clone().darken(0.1).rgbString()}`
    },
    ShowDrag: {
      fontWeight: 700,
      color: 'white',
      background: colors.show.clone().lighten(0.2).rgbString(),
      borderLeft: `3px solid ${colors.show.clone().darken(0.1).rgbString()}`,
      borderRight: 0,
      borderTop: 0,
      borderBottom: 0
    },

    NoShow: {
      background: colors.noShow.clone().lighten(0.9).rgbString(),
      color: colors.noShow.clone().darken(0.7).rgbString(),
      borderLeft: `3px solid ${colors.noShow.clone().darken(0.1).rgbString()}`
    },
    NoShowDrag: {
      fontWeight: 700,
      color: 'white',
      background: colors.noShow.clone().lighten(0.1).rgbString(),
      borderLeft: `3px solid ${colors.noShow.clone().darken(0.1).rgbString()}`,
      borderRight: 0,
      borderTop: 0,
      borderBottom: 0
    },

    Reservation: {
      background: colors.reservation.clone().lighten(0.6).rgbString(),
      color: colors.reservation.clone().darken(0.8).rgbString(),
      borderLeft: `3px solid ${colors.reservation.clone().darken(0.1).rgbString()}`
    },
    ReservationDrag: {
      fontWeight: 700,
      background: colors.reservation.clone().lighten(0.2).rgbString(),
      borderLeft: `3px solid ${colors.reservation.clone().darken(0.2).rgbString()}`,
      borderRight: 0,
      borderTop: 0,
      borderBottom: 0
    },

    ScheduleDrag: {
      fontWeight: 700,
      background: `repeating-linear-gradient(
        315deg,
        rgba(0, 0, 0, 0.10),
        rgba(0, 0, 0, 0.10) 20px,
        rgba(0, 0, 0, 0.15) 20px,
        rgba(0, 0, 0, 0.15) 40px
      )`
    },

    TimeSlot: {
      background: colors.timeSlot.alpha(0.8).rgbString(),
      color: colors.timeSlot.clone().darken(0.9).rgbString(),
      border: `1px dashed ${colors.timeSlot.clone().darken(0.3).rgbString()}`
    }
  }
};

export const styles = {
  image: {
    height: 10,
    width: 10,
    marginRight: 3,
    marginTop: -3
  },

  icon: {
    marginLeft: 2,
    marginRight: 1,
    marginTop: -4
  },

  label: {
    float: 'right',
    height: 9,
    width: 9,
    marginLeft: 2,
    marginRight: 1,
    marginTop: 4.5,
    borderRadius: 9
  },

  chips: {
    base: {
      borderRadius: 3,
      borderTop: '1px solid rgba(0, 0, 0, 0.1)',
      borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
      borderRight: '1px solid rgba(0, 0, 0, 0.1)',
      boxSizing: 'border-box'
    },

    placeholder: {
      borderRadius: 3,
      boxSizing: 'border-box',
      boxShadow: 'none',
      border: '1px dashed grey',
      background: color().rgb(255, 255, 255).alpha(0.5).rgbString(),
      color: color().rgb(0, 0, 0).alpha(0.5).rgbString(),
      float: 'right'
    },

    dragging: {
      zIndex: 9999,
      boxShadow: '0 3px 5px rgba(0, 0, 0, 0.3)'
    }
  }
};

export function chipStatusStyle(type, status = 'Booked', highContrast) {
  const chipStatus = type === 'Reservation' ? 'Reservation' : status;
  const visualStyles = highContrast ? visualStylesHC : visualStylesNonHC;
  return visualStyles.statuses[chipStatus];
}

const hexStr = /^#[0-9a-f]{6}/i;
const isDynamicColor = (color) => color && hexStr.test(color);

function hasDynamicColors(colorway, services, serviceColors, resourceColor) {
  return isDynamicColor(colorway)
    || services?.some(s => isDynamicColor(serviceColors[s.id]))
    || isDynamicColor(resourceColor);
}

function getStateColor(colorway, highContrast, dragging) {
  const highlight = highContrast || dragging;
  const highlightHC = highContrast && dragging;

  let darken = 0;
  if (highlight) {
    darken += 0.2;
  }
  if (highlightHC) {
    darken += 0.2;
  }

  return color(colorway).darken(darken).rgbString();
}

function getTextColor(background, highContrast) {
  if (isDark(background)) {
    return highContrast ? 'rgba(255, 255, 255, 1.0)' : 'rgba(255, 255, 255, 0.9)';
  }
  return color(background).darken(highContrast ? 1.0 : 0.8).rgbString();
}

function getBorderColor(background) {
  return isDark(background)
    ? color(background).lighten(0.6).rgbString()
    : color(background).darken(0.4).rgbString();
}

export function getBaseColor(services, serviceColors, colorway, resourceColor) {
  /* Ignore this for now while we decide what to do with resource colors
  if (resourceColor) {
    return resourceColor;
  }
  */
  return services?.length > 0
    ? serviceColors[services[0].id] || colorway
    : colorway;
}

export function getDynamicColorStyles(colorway, services, serviceColors, highContrast, dragging, resourceColor) {
  if (hasDynamicColors(colorway, services, serviceColors, resourceColor)) {
    const baseColor = getBaseColor(services, serviceColors, colorway, resourceColor);
    const background = getStateColor(baseColor, highContrast, dragging);

    return baseColor ? {
      background,
      color: getTextColor(background, highContrast),
      borderLeft: `3px solid ${getBorderColor(background)}`
    } : null;
  }
  return null;
}

export function getServiceBlockStyles(service, serviceColors, status, highContrast, showFullSize) {
  const visualStyles = highContrast ? visualStylesHC : visualStylesNonHC;
  const statusStyles = visualStyles.statuses[status];
  const baseColor = serviceColors[service?.id] || statusStyles?.background;
  const darken = showFullSize ? 0.1 : 0.3;

  return {
    background: color(baseColor).darken(darken).rgbString(),
    color: getTextColor(baseColor, highContrast)
  };
}

export function getVisualStyles(highContrast) {
  return highContrast ? visualStylesHC : visualStylesNonHC;
}
