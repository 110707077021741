import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSysAdmin } from '@State/selectors';
import {
  createPosUnit, downloadPosUnitSkvReport, fetchPosUnitsByOrgId,
  mergePosUnitPrefs, replacePosUnitPrefs, setPosUnitName
} from '@State/pos-config-actions';
import { getPosUnitReceiptPrefs } from '@State/pos-config-selectors';
import {
  PosContentWrap, ButtonWrap, POSTitle, GrayText, PosConfigCell, PosConfigRow
} from '@Components/admin/pos-config/style';
import {
  getPosNameByType, getPosUnitDescription, getPosUnitTitleForType, sortPosUnits
} from '@Utils/pos-utils';
import PosUnitSettings from '@Components/admin/pos-config/pos-unit-settings';
import PosUnitReceiptSettings from '@Components/admin/pos-config/pos-unit-receipt-settings';
import JsonEditorModal from '@Components/ui/jsoneditor/jsoneditor-modal';
import PosUnitCcuInfo from '@Components/admin/pos-config/pos-unit-ccu-info';
import PosUnitSkvReportModal from '@Components/admin/pos-config/pos-unit-skv-report-modal';
import { Button, Panel } from '@Components/ui/styled/main';
import PosUnitTools from './pos-unit-tools';
import PosUnitNameForm from './pos-unit-name-form';
import PosUnitEcomSettings from './pos-unit-ecom-settings';
import CreatePosUnitDialog from './create-pos-unit-dialog';

const PosOrgUnits = ({ posOrgId, posOrg, posUnits }) => {
  const dispatch = useDispatch();
  const isSysAdmin = useSelector(getSysAdmin);

  const [showCreatePosUnit, setShowCreatePosUnit] = useState(false);
  const [nameDialogVunitId, setNameDialogVunitId] = useState(null);
  const [settingsDialogVunitId, setSettingsDialogVunitId] = useState(null);
  const [ecomDialogVunitId, setEcomDialogVunitId] = useState(null);
  const [receiptDialogVunitId, setReceiptDialogVunitId] = useState(null);
  const [prefsDialogVunitId, setPrefsDialogVunitId] = useState(null);
  const [skvReportVunitId, setSkvReportVunitId] = useState(null);
  const [ccuInfoVunitId, setCcuInfoVunitId] = useState(null);

  const posUnitInitialValues = useMemo(() => {
    return {
      ...posOrg.get('companyAddress').toObject(),
      vunitType: 'Onprem'
    };
  }, [posOrg]);

  const handleCreatePosUnit = ({ addressLine1, postCode, area, vunitType, partnerId }) => {
    const posUnit = {
      address: {
        ...posOrg.get('companyAddress').toObject(),
        addressLine1,
        postCode,
        area
      },
      type: vunitType,
      partnerId
    };
    return dispatch(createPosUnit(posOrgId, posUnit))
      .then(() => dispatch(fetchPosUnitsByOrgId(posOrgId)))
      .then(() => setShowCreatePosUnit(false));
  };

  const handleUpdateName = ({ name }) => {
    return dispatch(setPosUnitName(nameDialogVunitId, name))
      .then(() => setNameDialogVunitId(null));
  };

  const handleUpdateReceiptSettings = (values) => {
    return dispatch(mergePosUnitPrefs(receiptDialogVunitId, values))
      .then(() => setReceiptDialogVunitId(null));
  };

  const handleUpdatePosUnitPrefs = (values) => {
    return dispatch(replacePosUnitPrefs(prefsDialogVunitId, values));
  };

  const handleDownloadSkvReport = ({ start, end }) => {
    return dispatch(downloadPosUnitSkvReport(skvReportVunitId, start, end))
      .then(() => setSkvReportVunitId(null));
  };

  const getPosUnit = (vunitId) => posUnits.find(u => u.get('id') === vunitId);
  const getReceiptPrefs = (vunitId) => getPosUnitReceiptPrefs(getPosUnit(vunitId));
  const getJsonPrefs = (vunitId) => getPosUnit(vunitId)?.get('prefs').toJS();
  const getPosUnitName = (vunitId) => getPosUnit(vunitId)?.get('name');

  return (
    <>
      {posUnits.sort(sortPosUnits).map((posUnit) => {
        const { id, name, type, prefs, open, active, ccuRegisterId } = posUnit.toJS();
        const showPosUnit = isSysAdmin || type !== 'ExtPayments';
        const isOnprem = type.includes('Onprem');

        return showPosUnit && (
          <Panel key={id}>
            <PosConfigRow>
              <PosConfigCell>
                {isOnprem ? (
                  <POSTitle>
                    {name}
                    {open && <small> - Öppen</small>}
                    {!active && <small> - Inaktiverad</small>}
                    {!open && active && <small> - Stängd</small>}
                  </POSTitle>
                ) : (
                  <POSTitle>{getPosUnitTitleForType(type) || name}</POSTitle>
                )}
                <PosContentWrap className="select-text">
                  {isOnprem ? (
                    <p>{prefs?.addressLine1}, {prefs?.addressPostCode} {prefs?.addressArea}</p>
                  ) : (
                    <GrayText>{getPosUnitDescription(type)}</GrayText>
                  )}
                </PosContentWrap>
                {isOnprem && (
                  <PosContentWrap className="select-text">
                    {ccuRegisterId && <GrayText>Kassabeteckning: {ccuRegisterId}</GrayText>}
                    <GrayText>{getPosNameByType(type)}</GrayText>
                  </PosContentWrap>
                )}
              </PosConfigCell>
              <PosConfigCell>
                <ButtonWrap>
                  {isOnprem ? (
                    <>
                      <Button small outlinePrimary onClick={() => setSettingsDialogVunitId(id)}>Inställningar</Button>
                      <Button small outlinePrimary marginLeft onClick={() => setReceiptDialogVunitId(id)}>Kvittoinställningar</Button>
                    </>
                  ) : (
                    type === 'Ecom' && (
                      <Button small outlinePrimary onClick={() => setEcomDialogVunitId(id)}>Inställningar</Button>
                    )
                  )}
                  <PosUnitTools
                    posUnit={posUnit}
                    posOrgId={posOrgId}
                    isOnprem={isOnprem}
                    onShowSetName={() => setNameDialogVunitId(id)}
                    onShowSkvReport={() => setSkvReportVunitId(id)}
                    onShowVunitPrefs={() => setPrefsDialogVunitId(id)}
                    onShowCcuInfo={() => setCcuInfoVunitId(id)}
                  />
                </ButtonWrap>
              </PosConfigCell>
            </PosConfigRow>
          </Panel>
        );
      })}
      {isSysAdmin && (
        <Button small primary onClick={() => setShowCreatePosUnit(true)}>Lägg till kassaregister</Button>
      )}
      {showCreatePosUnit && (
        <CreatePosUnitDialog
          initialValues={posUnitInitialValues}
          onSubmit={handleCreatePosUnit}
          onClose={() => setShowCreatePosUnit(false)}
        />
      )}
      {nameDialogVunitId && (
        <PosUnitNameForm
          initialValues={{ name: getPosUnitName(nameDialogVunitId) }}
          onSubmit={handleUpdateName}
          onClose={() => setNameDialogVunitId(null)}
        />
      )}
      {settingsDialogVunitId && (
        <PosUnitSettings
          posUnit={getPosUnit(settingsDialogVunitId)}
          onClose={() => setSettingsDialogVunitId(null)}
        />
      )}
      {ecomDialogVunitId && (
        <PosUnitEcomSettings
          posUnit={getPosUnit(ecomDialogVunitId)}
          onClose={() => setEcomDialogVunitId(null)}
        />
      )}
      {receiptDialogVunitId && (
        <PosUnitReceiptSettings
          initialValues={getReceiptPrefs(receiptDialogVunitId)}
          onSubmit={handleUpdateReceiptSettings}
          onClose={() => setReceiptDialogVunitId(null)}
        />
      )}
      {prefsDialogVunitId && (
        <JsonEditorModal
          json={getJsonPrefs(prefsDialogVunitId)}
          title="Vunit prefs"
          onSave={handleUpdatePosUnitPrefs}
          onClose={() => setPrefsDialogVunitId(null)}
        />
      )}
      {skvReportVunitId && (
        <PosUnitSkvReportModal
          posOrgId={posOrgId}
          posUnitId={skvReportVunitId}
          onSubmit={handleDownloadSkvReport}
          onClose={() => setSkvReportVunitId(null)}
        />
      )}
      {ccuInfoVunitId && (
        <PosUnitCcuInfo
          posOrgId={posOrgId}
          posUnitId={ccuInfoVunitId}
          onClose={() => setCcuInfoVunitId(null)}
        />
      )}
    </>
  );
};

export default PosOrgUnits;
