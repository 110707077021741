import React from 'react';
import { Field, reduxForm } from 'redux-form';
import TextInput from '@Components/inputs/text-input';
import { pos } from '@Utils/preference-keys';
import { getInvoiceUseEmailConfig } from '@Utils/invoice-util';
import FormButtonsFooter from '@Components/ui/form-buttons-footer';
import { required } from '@Utils/validation';
import CheckBoxEl from '@Components/inputs/checkbox-styled';
import AlertWithIcon from '@Components/ui/alert-with-icon';
import PosInvoiceDueDaysField from './pos-invoice-due-days-field';

const PosOrgInvoiceForm = ({
  posOrgId, invoicePrefs, billingContact, handleSubmit,
  pristine, submitting, submitSucceeded, onClose
}) => {
  const useFortnox = invoicePrefs[pos.invoiceUseFortnox];
  const useEmailConfig = getInvoiceUseEmailConfig(invoicePrefs);

  return (
    <form onSubmit={handleSubmit} className="dialog">
      {!useFortnox && (
        <>
          <Field
            name={pos.invoiceBGNumber}
            component={TextInput}
            label="Bankgiro-nummer"
          />
          <Field
            name={pos.invoicePGNumber}
            component={TextInput}
            label="PlusGiro-nummer"
          />
        </>
      )}
      <PosInvoiceDueDaysField
        posOrgId={posOrgId}
        invoicePrefs={invoicePrefs}
        name={pos.invoiceDueDays}
      />
      {!useFortnox && (
        <Field
          type="number"
          name={pos.latePaymentInterest}
          component={TextInput}
          label="Dröjsmålsränta (%)"
          validate={[required]}
        />
      )}
      <Field
        name={pos.useCustomerInvoiceRef}
        component={CheckBoxEl}
        label="Använd kundens namn som referens"
      />
      {!useFortnox && (
        <>
          <Field
            name={pos.invoiceUseWindowedEnvelope}
            component={CheckBoxEl}
            label="Placera adress för fönsterkuvert (C5)"
          />
          <Field
            name={pos.invoiceCopyToBillingContact}
            component={CheckBoxEl}
            label="Skicka kopia till e-post för redovisning"
            disabled={!billingContact}
          >
            <div className="text-muted mt1">
              {billingContact
                ? `E-post: ${billingContact.get('email')}`
                : 'Ingen redovisningskontakt angiven'}
            </div>
          </Field>
        </>
      )}
      {useEmailConfig && (
        <>
          <h5 className="mt5">Inställningar för e-post</h5>
          <Field
            name={pos.invoiceEmailSubject}
            component={TextInput}
            validate={[required]}
            label="Ämne"
          />
          <Field
            name={pos.invoiceEmailBody}
            component={TextInput}
            validate={[required]}
            label="Meddelande"
            rows={5}
          />
          <AlertWithIcon icon="fa fa-lightbulb">
            Du kan använda Fortnox parametrar för att lägga till dynamiskt innehåll i ämnesfältet.
            T.ex. <strong>{'{no}'}</strong> för fakturanummer eller <strong>{'{name}'}</strong> för kundens namn.
          </AlertWithIcon>
        </>
      )}
      <FormButtonsFooter
        onClose={onClose}
        pristine={pristine}
        submitting={submitting}
        submitSucceeded={submitSucceeded}
        handleSubmit={handleSubmit}
        alwaysEnabled
      />
    </form>
  );
};

export default reduxForm({
  form: 'pos-org-invoice-form',
  enableReinitialize: true
})(PosOrgInvoiceForm);
