import React, { useCallback, useMemo } from 'react';
import ModalDialog from '@Components/dialogs/modal-dialog';
import StaffEmploymentForm from './staff-employment-form';

const StaffEmploymentModal = ({ user, onSubmit, onClose }) => {
  const handleSubmit = useCallback((values) => {
    const {
      employmentType, workHoursPerWeek, workDaysPerWeek,
      lunchMinutesPerDay, monthlyRent, rentVatPct
    } = values;

    return onSubmit({
      employmentType,
      workMinutesPerDay: (workHoursPerWeek * 60) / workDaysPerWeek,
      workDaysPerWeek,
      lunchMinutesPerDay,
      monthlyRent,
      rentVatPct
    });
  }, []);

  const initialValues = useMemo(() => {
    const {
      employmentType, workMinutesPerDay, workDaysPerWeek,
      lunchMinutesPerDay, monthlyRent, rentVatPct
    } = user.employment;
    return {
      employmentType,
      workHoursPerWeek: (workMinutesPerDay * workDaysPerWeek) / 60,
      workDaysPerWeek,
      lunchMinutesPerDay,
      monthlyRent,
      rentVatPct: rentVatPct ?? '0'
    };
  }, [user]);

  return (
    <ModalDialog
      title="Anställning/hyresavtal"
      hideCloseButton
      closeOnClickOutside={false}
      onClose={onClose}
    >
      <StaffEmploymentForm
        initialValues={initialValues}
        onSubmit={handleSubmit}
        onClose={onClose}
      />
    </ModalDialog>
  );
};

export default StaffEmploymentModal;
