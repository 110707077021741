import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { toggleServicesSortable, toggleServicesCollapsed } from '@State/admin-view-reducer';
import { duplicateService } from '@State/services-actions';
import { getPermissions, getFeatures, getChangeJsonPrefsPermission, getDefaultVatPct } from '@State/selectors';
import { getServiceConfigUrl, navigate } from '@Utils/navigate';
import CollapseToolbarButtons from '../sortable-list/collapse-toolbar-buttons';

class ServiceListTools extends Component {
  static propTypes = {
    onToggleSorting: PropTypes.func.isRequired,
    routeParams: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="columns-header-tools">
        <button type="button" className="btn btn-header-toolbar" onClick={this.handleToggleSortable}>
          <i className="fa fa-sort-amount-down" />
        </button>
        <CollapseToolbarButtons
          onExpandAll={() => this.props.onToggleCollapse([])}
          onCollapseAll={() => this.props.onToggleCollapse(this.props.groupIds)}
        />
      </div>
    );
  }

  handleToggleSortable = () => {
    return this.props.onToggleSorting(!this.props.sortable);
  };
}

const mapStateToProps = (state, ownProps) => {
  const serviceId = parseInt(ownProps.id);
  const { servicesById, orderedServiceGroups, adminViewState } = state;
  const service = servicesById && serviceId && servicesById.get(serviceId);

  const filteredServices = ownProps.filter
    ? servicesById.filter(srv => {
      return (
        srv.name.toLowerCase().indexOf(ownProps.filter.toLowerCase()) !== -1
      );
    })
    : servicesById;

  const filteredServiceGroups = ownProps.filter
    ? orderedServiceGroups.filter(group => {
      let match = false;
      group.get('serviceIds').forEach(srvId => {
        if (filteredServices.has(srvId)) {
          match = true;
        }
      });
      return match;
    })
    : orderedServiceGroups;

  const groupIds = filteredServiceGroups.map(item => item.get('id')).toJS();

  return {
    groupIds,
    collapsedIds: adminViewState.get('servicesCollapsedIds'),
    sortable: adminViewState.get('servicesSortable'),
    orderedServiceGroups,
    permissions: getPermissions(state, ownProps),
    features: getFeatures(state),
    canChangeJsonPrefs: getChangeJsonPrefsPermission(state, ownProps),
    defaultVatPct: getDefaultVatPct(state),
    serviceId,
    service
  };
};

const mapDispatchToProps = (dispatch, { routeParams }) => {
  return {
    onToggleCollapse: (collapsedIds) => {
      return dispatch(toggleServicesCollapsed(collapsedIds));
    },

    onDuplicateService: (serviceId, name) => {
      return dispatch(duplicateService(serviceId, name))
        .then(srv => navigate(getServiceConfigUrl(routeParams, srv.id)));
    },
    onToggleSorting: (sortable) => {
      return dispatch(toggleServicesSortable(sortable));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceListTools);
