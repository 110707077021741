import { defineMessages } from 'react-intl';

export default defineMessages({
  show: {
    id: 'customers.bookings.labels.show',
    defaultMessage: 'Show'
  },
  noShow: {
    id: 'customers.bookings.labels.noShow',
    defaultMessage: 'No show'
  },
  dropIn: {
    id: 'customers.bookings.labels.dropIn',
    defaultMessage: 'Drop in'
  },
  askedForPerson: {
    id: 'customers.bookings.labels.askedForPerson',
    defaultMessage: 'Booked person'
  }
});
