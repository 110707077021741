import moment from 'moment';
import throttle from 'lodash/throttle';
import { connect } from 'react-redux';
import { Component } from 'react';
import { pos as posKeys } from '@Utils/preference-keys';
import { logoutOperator } from '@State/pos-actions';
import { addWakeCallback, removeWakeCallback } from '@Utils/wake-me-up';
import { getAutoLoginOperator } from '@State/pos-selectors';

function dispatchCustomEvent(event, detail) {
  document.dispatchEvent(new CustomEvent(event, { detail }));
}

export function pauseOperatorTimer() {
  dispatchCustomEvent('timer:control', { pause: true });
}

export function resumeOperatorTimer() {
  dispatchCustomEvent('timer:control', { pause: false });
}
class OperatorAutoLogout extends Component {
  constructor(props) {
    super(props);

    this.pauseTimer = false;
    this.eventListener = throttle(this.onActivity, 1000);
    this.eventTypes = ['click', 'mousemove', 'keydown', 'touchstart'];
  }

  componentDidMount() {
    if (this.shouldRun(this.props)) {
      this.startTimer();
    }
    addWakeCallback(this.handleWake);
    document.addEventListener('timer:control', this.handleTimerControl);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      operator, useOperatorPinCode, useOperatorIdleTimeout,
      logoutOnNavigate, isSectionPos
    } = this.props;

    const isRunning = operator && useOperatorIdleTimeout;
    const shouldBeRunning = this.shouldRun(nextProps);

    if (!isRunning && shouldBeRunning) {
      this.startTimer();
    } else if (isRunning && !shouldBeRunning) {
      this.stopTimer();
    }

    const pinCodeEnabled = !useOperatorPinCode && nextProps.useOperatorPinCode;
    if (operator && pinCodeEnabled) {
      this.props.logoutOperator();
    }

    if (logoutOnNavigate && isSectionPos && !nextProps.isSectionPos) {
      this.props.logoutOperator();
    }
  }

  componentWillUnmount() {
    this.stopTimer();
    removeWakeCallback(this.handleWake);
    document.removeEventListener('timer:control', this.handleTimerControl);
  }

  handleWake = () => {
    if (!this.props.autoLoginOperator) {
      setTimeout(() => this.props.logoutOperator(), 500);
    }
  };

  handleTimerControl = bcEvent => {
    if (bcEvent.detail.pause) {
      this.pauseTimer = true;
      this.stopTimer();
    } else {
      this.pauseTimer = false;
      if (this.shouldRun(this.props)) {
        this.startTimer();
      }
    }
  };

  shouldRun = (props) => {
    return props.operator && props.useOperatorIdleTimeout
      && !props.autoLoginOperator && !this.pauseTimer;
  };

  startTimer = () => {
    this.lastActivity = moment();
    this.checkInterval = setInterval(this.checkTimeout, 1000);

    for (const eventType of this.eventTypes) {
      window.addEventListener(eventType, this.eventListener);
    }
  }

  stopTimer() {
    clearInterval(this.checkInterval);

    for (const eventType of this.eventTypes) {
      window.removeEventListener(eventType, this.eventListener);
    }
  }

  onActivity = () => {
    this.lastActivity = moment();
  };

  checkTimeout = () => {
    const timeoutLimit = moment().subtract(this.props.operatorIdleTimeout, 'seconds');

    if (this.lastActivity < timeoutLimit) {
      this.props.logoutOperator();
    }
  };

  render() {
    return null;
  }
}

const mapStateToProps = (state, props) => {
  const { pos, locationConfig } = state;

  return {
    operator: pos.get('operator'),
    autoLoginOperator: getAutoLoginOperator(state),
    useOperatorPinCode: locationConfig.get(posKeys.useOperatorPinCode),
    useOperatorIdleTimeout: locationConfig.get(posKeys.useOperatorIdleTimeout),
    operatorIdleTimeout: locationConfig.get(posKeys.operatorIdleTimeout) || 60,
    logoutOnNavigate: locationConfig.get(posKeys.logoutOnNavigate),
    isSectionPos: props.match.params.section === 'pos'
  };
};

const mapDispatchToProps = dispatch => ({
  logoutOperator: () => dispatch(logoutOperator())
});

export default connect(mapStateToProps, mapDispatchToProps)(OperatorAutoLogout);
