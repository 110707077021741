import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import ModalDialog from '@Components/dialogs/modal-dialog';
import Flex from '@Components/ui/styled/flex-item';
import TextInput from '@Components/inputs/text-input';

import {
  DialogTitleRow, BtnBack, DialogTitleBtn, DialogTitle
} from '@Components/dialogs/dialog-styles';
import { createValidator, required, email } from '@Utils/validation';

const CreatePosOrgOwner = ({
  onHide, onPrev, onNext, ...formState
}) => {
  const [isClicked, setIsClicked] = useState(true);
  const onClickNext = () => {
    setIsClicked(false);
    const errors = formState.validate();
    if (formState.valid) {
      onNext();
    }
    if (Object.keys(errors).length > 0) {
      Object.keys(errors).map((item) => {
        return formState.touch(item);
      });
    }
  };
  const buttonsAction = [
    {
      name: 'Nästa',
      primary: true,
      type: 'submit',
      disabled: !isClicked && formState.invalid,
      onClick: onClickNext
    }
  ];

  return (
    <ModalDialog
      contentSize="medium"
      onClose={onHide}
      buttons={buttonsAction}
    >
      <form autoComplete="off" className="dialog">
        <DialogTitle>
          <BtnBack onClick={onPrev}><i className="fas fa-chevron-left" /></BtnBack>
          <DialogTitleRow leftBtn center>Kontoägare</DialogTitleRow>
          <DialogTitleBtn onClick={onHide}>Avbryt</DialogTitleBtn>
        </DialogTitle>
        <Flex>
          <Field
            name="contactName"
            component={TextInput}
            label="Kontoägare"
            placeholder="För- och efternamn"
          />
        </Flex>
        <Flex>
          <Field
            name="contactEmail"
            component={TextInput}
            label="Mejl"
            placeholder="Din mejladress"
          />
        </Flex>
      </form>
    </ModalDialog>
  );
};

const validator = createValidator({
  contactName: [required],
  contactEmail: [required, email]
});

export default reduxForm({
  form: 'create-pos-org',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: validator
})(CreatePosOrgOwner);
