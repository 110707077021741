import React from 'react';
import PropTypes from 'prop-types';
import { getDiffTime } from '@Utils/time-util';
import useTimer from '@Hooks/useTimer';

const Timer = ({ checkinTime }) => {
  const checkinTimeState = useTimer(checkinTime, getDiffTime);
  return (
    <span>{checkinTimeState}</span>
  );
};

Timer.propTypes = {
  checkinTime: PropTypes.string
};

export default Timer;
