import React, { useState } from 'react';
import moment from 'moment';
import Panel from '@Components/ui/styled/panel';
import { scheduleTimeTypes } from '@Utils/booking-util';
import Button from '@Components/ui/button';
import StaffReportDataModal from './staff-report-data-modal';
import { minutesToHours } from '../reports-helpers';

function getMinutes(items, timeType) {
  return items?.find(i => i.timeType === timeType)?.minutes;
}

const StaffReportSnapshot = ({ startDate, endDate, entries, staffState }) => {
  const [showDataModal, setShowDataModal] = useState(null);

  return (
    <div className="row">
      {staffState.map((user) => {
        const userId = user.get('id');
        const userEntries = entries[userId];

        if (!userEntries) {
          return null;
        }

        const totals = {};
        for (const timeType of Object.keys(scheduleTimeTypes)) {
          totals[timeType] = 0;
        }
        for (let date = moment(startDate); date.isSameOrBefore(endDate); date.add(1, 'd')) {
          const items = userEntries[date.format('YYYY-MM-DD')];
          Object.keys(scheduleTimeTypes).forEach((timeType) => {
            const minutes = getMinutes(items, timeType);
            totals[timeType] += minutes || 0;
          });
        }

        return userEntries && (
          <div key={userId} className="col-xs-6">
            <Panel onClick={() => setShowDataModal(user)}>
              <div className="pull-right">
                <Button tiny white onClick={() => setShowDataModal(user)}>Visa</Button>
              </div>
              <h5 className="mb3">{user.get('name')}</h5>
              <table width="100%">
                <tbody>
                  {Object.keys(scheduleTimeTypes).map((timeType) => {
                    return totals[timeType] ? (
                      <tr key={timeType}>
                        <td>{scheduleTimeTypes[timeType]}</td>
                        <td className="text-right">{minutesToHours(totals[timeType])}</td>
                      </tr>
                    ) : null;
                  })}
                </tbody>
              </table>
            </Panel>
          </div>
        );
      })}
      {showDataModal && (
        <StaffReportDataModal
          data={entries[showDataModal.get('id')]}
          report={{
            header: showDataModal.get('name'),
            startDate: startDate.format('YYYY-MM-DD'),
            endDate: endDate.format('YYYY-MM-DD')
          }}
          onClose={() => setShowDataModal(null)}
        />
      )}
    </div>
  );
};

export default StaffReportSnapshot;
