import React from 'react';
import { useSelector } from 'react-redux';
import { ecom } from '@Utils/preference-keys';
import { getSysAdmin } from '@State/selectors';
import AutoSaveTextInput from '@Components/inputs/auto-save-text-input';

const PosUnitSwishSettings = ({ posUnit, onSave }) => {
  const isSysAdmin = useSelector(getSysAdmin);
  const swishPayeeAlias = posUnit.getIn(['prefs', ecom.swishPayeeAlias]);
  const swishCertId = posUnit.getIn(['prefs', ecom.swishCertId]);

  return (
    <>
      <AutoSaveTextInput
        label="Swish-nummer"
        name={ecom.swishPayeeAlias}
        initialValue={swishPayeeAlias}
        onSave={onSave}
      />
      {isSysAdmin && (
        <AutoSaveTextInput
          label="Namn på certifikat"
          name={ecom.swishCertId}
          initialValue={swishCertId}
          onSave={onSave}
        />
      )}
    </>
  );
};

export default PosUnitSwishSettings;
