import React from 'react';
import { Field, reduxForm } from 'redux-form';
import ModalDialog from '@Components/dialogs/modal-dialog';
import SelectField from '@Components/inputs/select-field';
import { required } from '@Utils/validation';

const PrinterAssociateForm = ({
  onClose, handleSubmit, submitting, posUnits, printers, initialValues
}) => (
  <ModalDialog
    title="Koppla skrivare"
    onClose={onClose}
    buttons={[{
      name: 'Koppla skrivare',
      primary: true,
      type: 'submit',
      loading: submitting,
      onClick: handleSubmit
    }]}
  >
    <form onSubmit={handleSubmit}>
      <Field
        name="printerId"
        component={SelectField}
        label="Skrivare"
        validate={[required]}
        parse={val => val ? parseInt(val) : null}
      >
        <option style={{ display: 'none' }}>Välj skrivare</option>
        {printers?.map(printer => (
          <option key={printer.get('id')} value={printer.get('id')}>
            {printer.get('modelName') || 'Okänd skrivare'} ({printer.get('macAddress')})
          </option>
        ))}
      </Field>
      <Field
        name="vunitId"
        component={SelectField}
        label="Kassa"
        validate={required}
        normalize={val => val ? parseInt(val) : null}
        disabled={!!initialValues.vunitId}
      >
        <option style={{ display: 'none' }}>Välj kassa</option>
        {posUnits?.map(posUnit => (
          <option key={posUnit.get('id')} value={posUnit.get('id')}>
            {posUnit.get('posOrgName')}: {posUnit.get('name')}
          </option>
        ))}
      </Field>
    </form>
  </ModalDialog>
);

export default reduxForm({
  form: 'printer-associate-form'
})(PrinterAssociateForm);
