import React, { Component } from 'react';
import ContactForm from './contact-form';

const stylesMarginBottom = { marginBottom: 20 };
const styles33 = { width: '33%' };
const styles66 = { width: '66%' };

class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showForm: false
    };
  }

  showForm = (ev) => {
    ev.preventDefault();
    this.setState({ showForm: true });
  };

  hideForm = () => {
    this.setState({ showForm: false });
  };

  onSubmit = (data) => {
    return this.props.onSubmit(this.props.contactType, data)
      .then(() => this.hideForm());
  };

  render() {
    const { showForm } = this.state;
    const {
      contact, contactType, title, description, isOptional
    } = this.props;
    const contactInfo = contact && contact.toJS();
    const formName = `contact-info-${contactType.toLowerCase()}`;
    const isOwner = contactType === 'Owner';

    const initialValues = {
      useContactInfo: contactInfo || isOwner ? 'true' : 'false',
      ...contactInfo
    };

    return (
      <div>
        <h4>{title}</h4>
        <p className="text-muted" style={stylesMarginBottom}>{description}</p>
        {showForm && (
          <ContactForm form={formName} initialValues={initialValues} onSubmit={this.onSubmit} onCancel={this.hideForm} isOptional={isOptional} />
        )}
        {contact && !showForm && (
          <table className="table table-no-border">
            <tbody>
              <tr>
                <td style={styles33}>Kontaktperson</td>
                <td style={styles66}><strong>{contactInfo.name}</strong></td>
              </tr>
              <tr>
                <td>Mejl</td>
                <td><strong>{contactInfo.email}</strong></td>
              </tr>
              <tr>
                <td>Telefon</td>
                <td><strong>{contactInfo.phone}</strong></td>
              </tr>
              <tr>
                <td />
                <td><a href="#" onClick={this.showForm}>Ändra uppgifter</a></td>
              </tr>
            </tbody>
          </table>
        )}
        {!contact && !showForm && (
          <table className="table table-no-border">
            <tbody>
              <tr>
                <td style={styles33}>Kontaktperson</td>
                <td style={styles66}><strong>Samma som kontoägare</strong></td>
              </tr>
              <tr>
                <td />
                <td><a href="#" onClick={this.showForm}>Ändra uppgifter</a></td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
    );
  }
}

export default Contact;
