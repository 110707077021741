import { defineMessages } from 'react-intl';

export default defineMessages({
  lblLatestBooking: {
    id: 'calendar.bkf.search-customer.lblLatestBooking',
    defaultMessage: 'Latest booking'
  },
  lblVehicleInfoSource: {
    id: 'calendar.bkf.search-customer.lblVehicleInfoSource',
    defaultMessage: 'Information sourced from "bilregistret"'
  },
  btnAddItem: {
    id: 'calendar.bkf.search-customer.btnAddItem',
    defaultMessage: 'Add {description}'
  },
  placeholderAddItem: {
    id: 'calendar.bkf.search-customer.placeholderAddItem',
    defaultMessage: 'Search or add {description}'
  },
  btnBack: {
    id: 'calendar.bkf.search-customer.btnBack',
    defaultMessage: 'Back'
  },
  lblTitle: {
    id: 'calendar.bkf.search-customer.lblTitle',
    defaultMessage: 'Choose {description}'
  },
  lblVehicle: {
    id: 'calendar.bkf.search-customer.lblVehicle',
    defaultMessage: 'vehicle'
  },
  lblCompany: {
    id: 'calendar.bkf.search-customer.lblCompany',
    defaultMessage: 'company'
  },
  lblCustomer: {
    id: 'calendar.bkf.search-customer.lblCustomer',
    defaultMessage: 'customer'
  }
});
