import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import TextInput from '@Components/inputs/text-input';
import Popover from '@Components/ui/popover';
import ConfirmBlock from '@Components/ui/confirm-block';

const PopoverPersonalNumber = ({
  preferPlace = 'row', onUpdate, isOpenPopover, onHidePopup, children
}) => {
  const refInput = useRef();
  const [number, setNumber] = useState('');
  const [disableConfirm, setDisableConfirm] = useState(true);

  useEffect(() => {
    if (refInput.current && refInput.current.focus) {
      refInput.current.focus();
    }
  }, [isOpenPopover]);

  const onChangeNumber = (e) => {
    setNumber(e.target.value);
    setDisableConfirm(e.target.value === '');
  };

  const onConfirm = (e) => {
    if (e) {
      e.preventDefault();
    }
    return !disableConfirm && onUpdate(number);
  };

  const input = {
    ref: refInput,
    placeholder: 'YYYYMMDD-XXXX',
    value: number,
    onChange: onChangeNumber,
    maxLength: 13
  };

  const renderBody = (
    <form onSubmit={onConfirm}>
      <div className="Popover-dialog-sm staff-popup-form">
        <ConfirmBlock
          disableConfirm={disableConfirm}
          confirmText="Spara"
          onCancel={onHidePopup}
          onConfirm={onConfirm}
        >
          <TextInput
            meta={{}}
            label="Personnummer"
            input={input}
          />
        </ConfirmBlock>
      </div>
    </form>

  );

  return (
    <Popover
      className="Popover-big-radius"
      preferPlace={preferPlace}
      isOpen={isOpenPopover}
      onOuterAction={onHidePopup}
      body={renderBody}
    >
      {children}
    </Popover>
  );
};

PopoverPersonalNumber.propTypes = {
  onUpdate: PropTypes.func,
  isOpenPopover: PropTypes.bool,
  hidePopup: PropTypes.func,
  showPopover: PropTypes.func
};

export default PopoverPersonalNumber;
