export function doMoveItem(state, moveAction, itemsProperty) {
  const {
    srcGrpId, destGrpId, srcPos, destPos, itemId
  } = moveAction;

  // Move within the same group
  //
  if (srcGrpId === destGrpId) {
    const grpIdx = state.findIndex(g => g.get('id') === srcGrpId);
    const grp = state.get(grpIdx);

    const itemIds = grp.get(itemsProperty).delete(srcPos).insert(destPos, itemId);
    const modifiedGroup = grp.set(itemsProperty, itemIds);

    return state.set(grpIdx, modifiedGroup);
  }

  // Move between groups
  //
  const srcIdx = state.findIndex(g => g.get('id') === srcGrpId);
  const dstIdx = state.findIndex(g => g.get('id') === destGrpId);

  const srcGrp = state.get(srcIdx);
  const dstGrp = state.get(dstIdx);

  const modifiedSrcGrp = srcGrp.set(itemsProperty, srcGrp.get(itemsProperty).delete(srcPos));
  const modifiedDstGrp = dstGrp.set(itemsProperty, dstGrp.get(itemsProperty).insert(destPos, itemId));

  return state.set(srcIdx, modifiedSrcGrp).set(dstIdx, modifiedDstGrp);
}

export function doMoveGroup(state, moveAction) {
  const { srcPos, destPos } = moveAction;
  const offset = state.first().get('id') === 0 ? 1 : 0;

  const group = state.get(srcPos + offset);
  return state.delete(srcPos + offset).insert(destPos + offset, group);
}

export function firstItemIdInGroup(groups, itemListPropName, items) {
  const group = groups.find((value) => {
    return value.get(itemListPropName)
      .filter(id => !items || items.get(id)).first();
  });

  return group && group.get(itemListPropName)
    .filter(id => !items || items.get(id)).first();
}
