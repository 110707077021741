import React, { useCallback, useState } from 'react';
import moment from 'moment';
import { useForm } from 'react-hook-form';

import DatePickerLabelInput from '@Components/ui/date-picker/date-picker-label-input';
import FormButtonsFooter from '@Components/ui/form-buttons-footer';
import { getDate } from '@Utils/time-util';

const AccountPaidUntilForm = ({ onClose, onSubmit, loading, initialValues = {} }) => {
  const { handleSubmit, setValue } = useForm();
  const [paidUntil, setPaidUntil] = useState(initialValues.paidUntil);

  const handleChangeDate = (value, e) => {
    if (!e?.onInputInvoked) {
      setPaidUntil(value);
      setValue('paidUntil', value);
    }
  };

  const onHandleSubmit = useCallback(async () => {
    return onSubmit({ paidUntil: getDate(paidUntil) });
  }, [paidUntil]);

  return (
    <form onSubmit={handleSubmit(onHandleSubmit)}>
      <DatePickerLabelInput
        label="Paid until"
        date={moment(paidUntil).isValid() ? moment(paidUntil) : moment()}
        handleChangeDate={handleChangeDate}
      />
      <FormButtonsFooter
        onClose={onClose}
        handleSubmit={handleSubmit}
        submitting={loading}
        submitSucceeded
      />
    </form>
  );
};

export default AccountPaidUntilForm;
