import React, { Component } from 'react';
import { connect } from 'react-redux';
import StaffReportList from './staff-report-list';

class StaffReportSidebar extends Component {
  render() {
    const { reportsViewState, routeParams } = this.props;
    const staffReport = reportsViewState.get('staff');
    const staffReports = reportsViewState.get('staffReports');

    return (
      <div className="columns-sidebar">
        <StaffReportList
          routeParams={routeParams}
          staffReports={staffReports}
          staffReport={staffReport}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { reportsViewState } = state;

  return {
    reportsViewState,
    routeParams: ownProps.match.params
  };
};

export default connect(mapStateToProps)(StaffReportSidebar);
