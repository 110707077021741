import moment from 'moment';
import { isValidPno } from '@Utils/luhn-util';

export const composeValidators = (...validators) => (value, values) => {
  return validators.reduce((error, validator) => {
    return error || (validator && validator(value, values));
  }, undefined);
};

export const trimString = value => value && value.trim();

const isEmpty = value => value === undefined || value === null || value === '';
const join = rules => (value, data) => rules.map(rule => rule(value, data)).filter(error => !!error)[0];

export function required(value) {
  if (isEmpty(value)) {
    return 'Måste fyllas i';
  }
}

const minLengths = {};
export function minLength(min) {
  if (!minLengths[min]) {
    minLengths[min] = (value) => {
      if (!isEmpty(value) && value.length < min) {
        return `Måste vara minst ${min} tecken`;
      }
    };
  }
  return minLengths[min];
}

const maxLengths = {};
export function maxLength(max) {
  if (!maxLengths[max]) {
    maxLengths[max] = (value) => {
      if (!isEmpty(value) && value.length > max) {
        return `Får inte vara mer än ${max} tecken`;
      }
    };
  }
  return maxLengths[max];
}

const minValues = {};
export const minValue = min => {
  if (!minValues[min]) {
    minValues[min] = value => value && value < min ? `Måste vara minst ${min}` : undefined;
  }
  return minValues[min];
};

const maxValues = {};
export const maxValue = max => {
  if (!maxValues[max]) {
    maxValues[max] = value => value && value > max ? `Får vara högst ${max}` : undefined;
  }
  return maxValues[max];
};

export function pattern(pattern, error) {
  return (value) => {
    if (!isEmpty(value) && !value.match(pattern)) {
      return error || `Måste matcha ${pattern}`;
    }
  };
}

export function timePeriod(minPeriodString, maxDurationString) {
  return (value) => {
    const duration = moment.duration(value);
    const minDuration = moment.duration(minPeriodString);
    const maxDuration = moment.duration(maxDurationString);

    if (minDuration && duration < minDuration) {
      return `Måste vara minst ${minDuration.humanize()}`;
    }
    if (maxDuration && duration > maxDuration) {
      return `Får inte vara mer än ${maxDuration.humanize()}`;
    }
  };
}

export function createValidator(rules) {
  return (data = {}) => {
    const errors = {};
    Object.keys(rules).forEach((key) => {
      const rule = join([].concat(rules[key])); // concat enables both functions and arrays of functions
      const error = rule(data[key], data);
      if (error) {
        errors[key] = error;
      }
    });
    return errors;
  };
}

export const number = value => value && isNaN(Number(value)) ? 'Måste vara ett nummer' : undefined;

export const email = value => value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,8}$/i.test(value) ? 'Ogiltig e-postadress' : undefined;

export const companyOrgNoValid = (value) => value && !/^\d{6}-\d{4}$/gm.test(value) ? 'Formatet stämmer inte' : undefined;

export const pnoValid = (value) => value && !isValidPno(value) ? 'Ogiltigt personnummer' : undefined;

export const phoneNumber = value => value && !/^(0|\+)[- 0-9]{7,15}$/.test(value) ? 'Ogiltigt telefonnummer' : undefined;

export const date = value => value && !moment(value, 'YYYY-MM-DD', true).isValid() ? 'Ogiltigt datum' : undefined;

export const smsSenderValidation = [
  required,
  maxLength(11),
  pattern(/^[a-z0-9_]+$/i, 'Får endast innehålla bokstäver (ej åäö), siffror och understreck (_)')
];

export const smsSenderHookValidation = {
  required: { value: true, message: 'Måste fyllas i' },
  maxLength: { value: 11, message: 'Får inte vara mer än 11 tecken' },
  pattern: {
    value: /^[a-z0-9_]+$/i,
    message: 'Max 11 tecken, enbart bokstäver (ej åäö), siffror och understreck (_)'
  }
};
