import React, { Component } from 'react';
import Decimal from 'decimal.js-light';
import { connect } from 'react-redux';
import CurrencyUtil from '@Utils/currency-util';
import { getDiscountDescription, getItemDescription } from '@Utils/pos-utils';
import LargeNumpadModal from '@Components/inputs/large-numpad-modal';
import { getAllProducts } from '@State/products-selectors';

class EditSaleItemModal extends Component {
  onSave = ({ discountType, discountValue, quantity, amount, comment, cashierId }) => {
    const { item } = this.props;
    const baseItem = (item || {});
    const isVoucherSale = baseItem.itemType === 'VoucherSale';

    const description = baseItem.itemType === 'Discount'
      ? getDiscountDescription(discountValue, discountType)
      : baseItem.description;

    return this.props.onSave({
      ...baseItem,
      showEditModal: false,
      discountType,
      discountValue,
      quantity,
      amount,
      description,
      comment: isVoucherSale ? baseItem.comment : comment,
      voucherBeneficiary: isVoucherSale ? comment : null,
      sellerId: cashierId || null
    });
  };

  getAmountPresets = () => {
    const { item, allProducts } = this.props;

    if (item && item.articleId) {
      const product = allProducts.find(p => p.id === item.articleId);
      const { stocked, latestPriceIn, vatPct } = product || {};

      if (stocked && latestPriceIn) {
        const amount = new Decimal(latestPriceIn).times(100 + vatPct).toNumber();
        return [{
          label: `Inpris: ${CurrencyUtil.accountCurrency(amount / 100, 2)}`,
          tooltip: (
            `Exkl. moms: ${CurrencyUtil.accountCurrency(latestPriceIn, 2)}<br />
            Inkl. moms: ${CurrencyUtil.accountCurrency(amount / 100, 2)}`
          ),
          amount
        }];
      }
    }
    return null;
  }

  render() {
    const { item, onClose, grossAmount, cashiersById } = this.props;
    const { description, itemType, comment } = item || {};
    const title = item ? getItemDescription(description, itemType) : 'Rabatt';
    const isNotDiscount = item && itemType !== 'Discount';
    const isVoucherSale = itemType === 'VoucherSale';
    const selectPrice = item && item.showEditModal && item.amount === 0;
    const itemComment = isVoucherSale ? item.voucherBeneficiary : comment;

    return (
      <LargeNumpadModal
        onSave={this.onSave}
        onClose={onClose}
        title={title}
        item={{ ...item, comment: itemComment }}
        selectedType={selectPrice ? 'amount' : null}
        grossAmount={grossAmount}
        showQuantity={isNotDiscount && !isVoucherSale}
        showAmount={isNotDiscount && !isVoucherSale}
        showCashier={isNotDiscount}
        commentPlaceholder={isVoucherSale ? 'Förmånstagare' : null}
        cashiersById={cashiersById}
        showComment
        showDiscount
        amountPresets={this.getAmountPresets()}
      />
    );
  }
}

const mapStateToProps = state => ({
  cashiersById: state.cashiersById,
  allProducts: getAllProducts(state)
});

export default connect(mapStateToProps)(EditSaleItemModal);
