import { defineMessages } from 'react-intl';

export default defineMessages({
  overview: {
    id: 'customers.tabs.overview',
    defaultMessage: 'Overview'
  },
  bookings: {
    id: 'customers.tabs.bookings',
    defaultMessage: 'Bookings'
  },
  logs: {
    id: 'customers.tabs.logs',
    defaultMessage: 'Logs'
  },
  payments: {
    id: 'customers.tabs.payments',
    defaultMessage: 'Payments'
  },
  giftCards: {
    id: 'customers.tabs.giftCards',
    defaultMessage: 'Gift cards'
  }
});
