import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSysAdmin } from '@State/selectors';
import { deletePosUnit, downloadReceiptPreview, setPosUnitActive } from '@State/pos-config-actions';
import { Button } from '@Components/ui/styled/main';
import ProgressButton from '@Components/ui/progress-button';
import Popover from '@Components/ui/popover';
import ConfirmBlock from '@Components/ui/confirm-block';

const PosUnitTools = ({
  posOrgId, posUnit, isOnprem, onShowSetName, onShowSkvReport, onShowVunitPrefs, onShowCcuInfo
}) => {
  const dispatch = useDispatch();
  const isSysAdmin = useSelector(getSysAdmin);
  const [showTools, setShowTools] = useState(false);
  const [confirmDelete, showConfirmDelete] = useState(false);
  const [confirmActivate, showConfirmActivate] = useState(false);
  const [confirmDeactivate, showConfirmDeactivate] = useState(false);

  const { id, name, ccuRegisterId, open, active } = posUnit.toJS();

  const handleActivatePosUnit = () => dispatch(setPosUnitActive(posOrgId, id, true)).then(handleClose);
  const handleDeactivatePosUnit = () => dispatch(setPosUnitActive(posOrgId, id, false)).then(handleClose);
  const handleDeletePosUnit = () => dispatch(deletePosUnit(posOrgId, id));
  const handleReceiptPreview = () => dispatch(downloadReceiptPreview(id));
  const handleShowSetName = () => { onShowSetName(id); handleClose(); };
  const handleShowVunitPrefs = () => { onShowVunitPrefs(id); handleClose(); };
  const handleShowCcuInfo = () => { onShowCcuInfo(id); handleClose(); };
  const handleShowSkvReport = () => { onShowSkvReport(id); handleClose(); };
  const handleClose = () => {
    setShowTools(false);
    showConfirmDelete(false);
    showConfirmActivate(false);
    showConfirmDeactivate(false);
  };

  const popoverBody = useMemo(() => {
    if (confirmDelete) {
      return (
        <div className="Popover-dialog-sm button-wrapper">
          <ConfirmBlock
            confirmDanger
            confirmText="Ta bort"
            title={`Ta bort ${name}`}
            onCancel={() => showConfirmDelete(false)}
            onConfirm={handleDeletePosUnit}
          />
        </div>
      );
    }
    if (confirmActivate) {
      return (
        <div className="Popover-dialog-sm button-wrapper">
          <ConfirmBlock
            confirmText="Aktivera"
            title={`Aktivera ${name}`}
            onCancel={() => showConfirmActivate(false)}
            onConfirm={handleActivatePosUnit}
          />
        </div>
      );
    }
    if (confirmDeactivate) {
      return (
        <div className="Popover-dialog-sm button-wrapper">
          <ConfirmBlock
            confirmDanger
            confirmText="Inaktivera"
            title={`Inaktivera ${name}`}
            onCancel={() => showConfirmDeactivate(false)}
            onConfirm={handleDeactivatePosUnit}
          />
        </div>
      );
    }
    return (
      <div className="Popover-dialog-sm button-wrapper">
        <div className="menu-button-wrapper">
          {isOnprem && <Button small block gray onClick={handleShowSetName}>Ändra namn</Button>}
          {isOnprem && <ProgressButton small block gray onClick={handleReceiptPreview}>Ladda ner testkvitto</ProgressButton>}
          {isOnprem && ccuRegisterId && <Button small block gray onClick={handleShowCcuInfo}>Info om kontrollenhet</Button>}
          {isSysAdmin && (
            <>
              {isOnprem && <Button small block gray onClick={handleShowSkvReport}>Journalminne</Button>}
              <Button small block gray onClick={handleShowVunitPrefs}>Vunit prefs</Button>
              {!open && active && <Button small block danger onClick={() => showConfirmDeactivate(true)}>Inaktivera</Button>}
              {!open && !active && <Button small block gray onClick={() => showConfirmActivate(true)}>Aktivera</Button>}
              {!open && <Button small block danger onClick={() => showConfirmDelete(true)}>Ta bort</Button>}
            </>
          )}
        </div>
      </div>
    );
  }, [confirmDelete, confirmActivate, confirmDeactivate]);

  return isSysAdmin || isOnprem ? (
    <Popover
      preferPlace="below"
      isOpen={showTools}
      onOuterAction={handleClose}
      body={popoverBody}
      className="Popover-big-radius"
    >
      <Button outlinePrimary small marginLeft onClick={() => setShowTools(!showTools)}>
        <i className="far fa-cog" />
      </Button>
    </Popover>
  ) : null;
};

export default PosUnitTools;
