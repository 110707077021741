import React from 'react';
import PropTypes from 'prop-types';
import DatePickerCalendarInput from '@Components/ui/date-picker/date-picker-calendar-input';

const DatePickerLabelInput = ({
  label, subLabel, date, handleChangeDate, disabled, filterDate
}) => (
  <div className="form-group input-white">
    <label className="control-label">{label}</label>&nbsp;
    <small className="sub-label">{subLabel}</small>
    <div className="input-date">
      <DatePickerCalendarInput
        filterDate={filterDate}
        date={date}
        onChange={handleChangeDate}
        disabled={disabled}
      />
    </div>
  </div>
);

DatePickerLabelInput.propTypes = {
  label: PropTypes.string,
  subLabel: PropTypes.string,
  handleChangeDate: PropTypes.func,
  disabled: PropTypes.bool
};

export default DatePickerLabelInput;
