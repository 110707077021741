import React from 'react';
import { getNotificationDescription } from '@Utils/notification-util';
import { Panel } from '@Components/ui/styled/main';
import LabelSwitchInput from '@Components/ui/label-switch-input';

const Notifications = ({ username = null, keys, prefs, title, onChange }) => {
  const handleToggle = (key) => {
    return onChange(key, !prefs[key]);
  };

  return (
    <>
      <Panel>
        <h5 className="mb2">{title}</h5>
        {username ? (
          <p className="text-muted">
            Notiser skickas till <strong>{username}</strong>.
          </p>
        ) : (
          <p className="text-muted">
            Pushnotiser behöver tillåtas/aktiveras i appen.
          </p>
        )}

        {keys.map(key => (
          <LabelSwitchInput
            noBorder
            id={key}
            key={key}
            label={getNotificationDescription(key)}
            isOn={prefs ? !!prefs[key] : false}
            handleToggle={() => handleToggle(key)}
          />
        ))}
      </Panel>
    </>
  );
};

export default Notifications;
