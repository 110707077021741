import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { config } from '@Utils/preference-keys';
import { fetchFortnoxConfigStatus } from '@State/fortnox-actions';
import ModalDialog from '@Components/dialogs/modal-dialog';
import { Button } from '@Components/ui/styled/main';
import { mergeOrgSettings } from '@State/pos-config-actions';
import AlertWithIcon from '@Components/ui/alert-with-icon';

const fortnoxErrors = [
  'accounting_FinancialYear',
  'accounting_voucherSeries'
];

const accounts = {
  accounting_cashAccount: 'Kassa',
  accounting_bankAccount: 'Bankkonto',
  accounting_cardAccount: 'Kortbetalningar',
  accounting_swishAccount: 'Swish',
  accounting_klarnaAccount: 'Klarna',
  accounting_invoiceAccount: 'Faktura',
  accounting_Other1Account: 'Annat betalsätt 1',
  accounting_Other2Account: 'Annat betalsätt 2',
  accounting_Other3Account: 'Annat betalsätt 3',
  accounting_Other4Account: 'Annat betalsätt 4',
  accounting_Other5Account: 'Annat betalsätt 5',

  accounting_salesProductsStdRate: 'Försäljning produkter {Standard}% moms',
  accounting_salesProductsReducedRateA: 'Försäljning produkter {ReducedA}% moms',
  accounting_salesProductsReducedRateB: 'Försäljning produkter {ReducedB}% moms',
  accounting_salesProductsZeroRate: 'Försäljning produkter 0% moms',
  accounting_salesServicesStdRate: 'Försäljning tjänster {Standard}% moms',
  accounting_salesServicesReducedRateA: 'Försäljning tjänster {ReducedA}% moms',
  accounting_salesServicesReducedRateB: 'Försäljning tjänster {ReducedB}% moms',
  accounting_salesServicesZeroRate: 'Försäljning tjänster 0% moms',
  accounting_salesSPVoucherStdRate: 'Försäljning presentkort {Standard}% moms',
  accounting_salesSPVoucherReducedRateA: 'Försäljning presentkort {ReducedA}% moms',
  accounting_salesSPVoucherReducedRateB: 'Försäljning presentkort {ReducedB}% moms',
  accounting_salesSPVoucherZeroRate: 'Försäljning presentkort 0% moms',
  accounting_salesStdRate: 'Försäljning {Standard}% moms',
  accounting_salesReducedRateA: 'Försäljning {ReducedA}% moms',
  accounting_salesReducedRateB: 'Försäljning {ReducedB}% moms',
  accounting_salesZeroRate: 'Försäljning 0% moms',

  accounting_vatOutStdRate: 'Utgående moms {Standard}%',
  accounting_vatOutReducedRateA: 'Utgående moms {ReducedA}%',
  accounting_vatOutReducedRateB: 'Utgående moms {ReducedB}%',

  accounting_roundingAccount: 'Öresavrundning',
  accounting_discountsAccount: 'Rabatter',
  accounting_TipsPayable: 'Dricks',
  accounting_soldVouchersAccount: 'Sålda presentkort',
  accounting_SalesSPVoucherRedeem: 'Inlösen av enfunktionsvoucher',
  accounting_UnearnedRevenuePrepaid: 'Förbetalning via insättning',
  accounting_DeferredServiceSale: 'Skuldkonto vid bokning av tjänst'
};

const editableKeys = Object.keys(accounts);

const replaceVatRate = (str, vatRates) => {
  vatRates.keySeq().forEach(vatType => {
    str = str.replace(`{${vatType}}`, vatRates.get(vatType));
  });
  return str;
};

const groups = [
  { name: 'Betalsätt', statuses: [...editableKeys].slice(0, 11) },
  { name: 'Försäljningskonton', statuses: [...editableKeys].slice(11, 27) },
  { name: 'Momskonton', statuses: [...editableKeys].slice(27, 30) },
  { name: 'Övrigt', statuses: [...editableKeys].slice(30, 36) },
  { name: '', statuses: [] }
];

const AccountingConfigStatus = ({ posOrgId, onClose, isAuthenticated, prefs }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [configStatus, setConfigStatus] = useState(groups.map(group => ({
    ...group, statuses: group.statuses.map(key => ({ key, account: prefs[key] }))
  })));
  const [error, setError] = useState(null);
  const [editing, setEditing] = useState(null);
  const [statusErrors, setStatusErrors] = useState(null);

  const vatRates = useSelector(state => state.locationConfig.get(config.currentVatRates));
  const accountStrings = useMemo(() => {
    const strings = {};
    Object.keys(accounts).forEach(key => {
      strings[key] = replaceVatRate(accounts[key], vatRates);
    });
    return strings;
  }, [vatRates]);

  useEffect(() => {
    setConfigStatus(groups => groups.map(group => ({
      ...group, statuses: group.statuses.map(it => ({ ...it, account: prefs[it.key] }))
    })));
  }, [prefs]);

  const onBlur = useCallback((e) => {
    const value = e?.target?.value;
    if (!editing || error || !value || value.toString() === prefs[editing.key].toString()) return;
    setLoading(true);
    dispatch(mergeOrgSettings(posOrgId, { [editing.key]: value }))
      .then(() => {
        return fetchData();
      })
      .finally(() => {
        if (!isAuthenticated) setLoading(false);
        setEditing(null);
      });
  }, [error, prefs, editing]);

  const onChange = useCallback((e) => {
    const value = e?.target?.value;
    if (value > 9999 || value < 1000) {
      setError(true);
    } else {
      setError(false);
    }
    setEditing((prev = {}) => ({ ...prev, account: value }));
  }, []);

  const fetchData = useCallback(() => {
    if (!isAuthenticated) return;
    setLoading(true);
    dispatch(fetchFortnoxConfigStatus(posOrgId))
      .then((statuses) => {
        setStatusErrors(statuses.filter(s => s.errorCode !== 0));
        setConfigStatus(groups => groups.map((group) => {
          if (group.name === '') {
            return {
              ...group,
              statuses: statuses.filter(({ key }) => {
                return !editableKeys.includes(key) && !fortnoxErrors.includes(key);
              })
            };
          }
          return {
            ...group,
            statuses: group.statuses.map(status => {
              const found = statuses.find(it => it.key === status.key);
              return found ? { ...status, ...found } : status;
            })
          };
        }));
        setLoading(false);
      }).catch(() => {
        setLoading(false);
      });
  }, [posOrgId, isAuthenticated]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <ModalDialog
      contentSize={isAuthenticated ? 'xxl' : 'large'}
      title="Kontoplan"
      closeButtonText="Stäng"
      onClose={onClose}
    >
      {statusErrors?.length > 0 && (
        <AlertWithIcon warning icon="fa fa-exclamation-triangle">
          Fel i konfigurationen hittades. Du behöver åtgärda felen nedan för att Fortnox-synkronisering ska fungera.
          <ul className="mt2 mb0">
            {fortnoxErrors.map(key => {
              const err = statusErrors.find(e => e.key === key);
              return err ? <li>{err.message}</li> : null;
            })}
            {statusErrors.some(err => !fortnoxErrors.includes(err.key)) && (
              <li>Kontrollera rödmarkerade konton i listan.</li>
            )}
          </ul>
        </AlertWithIcon>
      )}
      <table className="table table-condensed table-product-form select-text">
        <tbody>
          {configStatus.map(group => !group.statuses.length ? null : (
            <React.Fragment key={group.name}>
              <tr className="table-th-separate">
                <th>{group.name}</th>
                <th className="text-right">{group.name ? 'Konto' : ''}</th>
                {isAuthenticated && (
                  <>
                    <th />
                    <th>{group.name ? 'Namn i Fortnox' : ''}</th>
                    <th />
                  </>
                )}
              </tr>
              {group.statuses.map(({ key, account, accountDescription, message, errorCode }) => {
                if (key.includes('ReducedRateC')) {
                  return null;
                }
                return account === -1 && errorCode === 0 ? null : (
                  <tr key={key ?? ''}>
                    <td>{accountStrings[key] ? accountStrings[key] : key}</td>
                    <td className="table-td-editable">
                      {(editableKeys.includes(key)) ? (
                        <input
                          className={`form-control ${editing?.key === key && error ? 'error' : ''}`}
                          autoFocus={false}
                          style={{ maxWidth: '55px', textAlign: 'center' }}
                          value={editing && editing.key === key ? editing.account : account}
                          type="number"
                          onFocus={() => {
                            setEditing({ key, account });
                            setError(null);
                          }}
                          onBlur={onBlur}
                          onChange={onChange}
                        />
                      ) : (<strong>{account === -1 ? 'Fel' : account}</strong>)}
                    </td>
                    {isAuthenticated && (
                      <>
                        <td>&nbsp;</td>
                        <td>{accountDescription || message}</td>
                        {loading ? (
                          <td className="text-right">
                            <i className="fa fa-spinner fa-spin text-muted" />
                          </td>
                        ) : (
                          <td className="text-right">
                            {errorCode === 0 ? (
                              <i className="fa fa-check-circle text-success" />
                            ) : (
                              <i className="fa fa-times-circle text-danger" />
                            )}
                          </td>
                        )}
                      </>
                    )}
                  </tr>
                );
              })}
            </React.Fragment>
          ))}
        </tbody>
      </table>
      <div className="text-right">
        <Button gray small onClick={onClose}>Stäng</Button>
      </div>
    </ModalDialog>
  );
};

export default AccountingConfigStatus;
