import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getChangeJsonPrefsPermission } from '@State/selectors';
import NavTabsLink from '@Components/ui/nav-tabs-link';

class ResourceSubNav extends Component {
  static propTypes = {
    routeParams: PropTypes.object.isRequired,
    locationFeatures: PropTypes.object.isRequired
  };

  sections() {
    const features = this.props.locationFeatures.toJS();
    const sections = [];

    features.ShowGeneralSettings
      && sections.push({ navId: 'settings', name: 'Inställningar' });
    features.ShowServicesSettings
      && sections.push({ navId: 'services', name: 'Tjänster' });
    this.props.canChangeJsonPrefs
      && sections.push({ navId: 'prefs', name: 'Prefs' });

    return sections;
  }

  render() {
    const { routeParams } = this.props;
    return (
      <NavTabsLink
        subUrl="admin/resources"
        routeParams={routeParams}
        sections={this.sections()}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { locationFeatures } = state;

  return {
    canChangeJsonPrefs: getChangeJsonPrefsPermission(state, ownProps),
    locationFeatures
  };
};

export default connect(mapStateToProps)(ResourceSubNav);
