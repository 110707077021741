import PropTypes from 'prop-types';
import React from 'react';
import { DropDownItem } from '@Components/ui/drop-down/dropdown';

const UserMenuLocation = ({ location, currentLocation, routeParams }) => {
  const {
    orgCtxName, locCtxName, orgLoc, orgName, locName
  } = location;
  const orgLocName = orgName === locName ? orgName : `${orgName} (${locName})`;
  const { section, subsection = '' } = routeParams;
  const url = `/${orgCtxName}/${locCtxName}/${section}/${subsection}`;
  return (
    <DropDownItem href={url} active={currentLocation.orgLoc === orgLoc}>{orgLocName}</DropDownItem>
  );
};

UserMenuLocation.propTypes = {
  location: PropTypes.object.isRequired,
  currentLocation: PropTypes.object.isRequired
};

export default UserMenuLocation;
