import moment from 'moment';
import { scheduleBlockToMomentDates } from '@Utils/time-util';
import { getIsMultiResourceView, getResourceFromColIdx, getResourcesInView } from '@State/calendar-selectors';

export function isTimeInSchedule(schedulesByResource, time, resourceId) {
  const schedule = schedulesByResource.get(resourceId);
  if (schedule == null) return false;

  for (const block of schedule.blocks) {
    const mb = scheduleBlockToMomentDates(block);
    const range = moment.range(mb.blStart, mb.blEnd);
    if (rangeContains(range, time)) {
      return true;
    }
  }
  return false;
}

function rangeContains(range, time) {
  // NOTE! range.contains() is broken in moment-range, so doing my own implementation here..
  // Issue at GH: https://github.com/rotaready/moment-range/issues/154

  const { start } = range;
  const { end } = range;
  return time.isBetween(start, end, null, '[)');
}

export function resourceFromColIdx(state, routeParams, colIdx) {
  return getResourceFromColIdx(state, { routeParams, colIdx });
}

function mapResourceForBooking(resource, primary = false) {
  const { id, name, resourceType, attributes } = resource;
  return {
    id, name, resourceType, attributes, primary
  };
}

export function getResourcesForNewBooking(state, routeParams, colIdx) {
  const isMultiResourceView = getIsMultiResourceView(state, { routeParams });

  if (isMultiResourceView) {
    const { entityType, entityId } = routeParams;
    const primaryId = entityType !== 'group' ? entityId : null;
    const resourcesInView = getResourcesInView(state, { routeParams });
    return resourcesInView.map((resource, index) => {
      const isPrimary = primaryId ? resource.id === primaryId : index === 0;
      return mapResourceForBooking(resource, isPrimary);
    });
  }

  const resource = resourceFromColIdx(state, routeParams, colIdx);
  return resource ? [mapResourceForBooking(resource, true)] : [];
}

export function calcGridScrollHeight(pixelsPerRow, rowsPerHour) {
  return (pixelsPerRow * rowsPerHour * 24);
}

export function calculateGridPixelsPerRow(rowsPerHour, gridSize) {
  let sizeFactor = 1;

  switch (gridSize) {
    case 'xsmall':
      sizeFactor = 0.5;
      break;
    case 'small':
      sizeFactor = 1;
      break;
    case 'large':
      sizeFactor = 2;
      break;
  }

  const rowsPerHourFactor = (Math.log10(rowsPerHour + 1) ** 4) + 0.016667;
  const gridPixelsPerRow = Math.round((rowsPerHour / rowsPerHourFactor + 2) * sizeFactor / 5) * 5;

  return gridPixelsPerRow;
}

/**
 * Returns an array of moment days for the given week string, eg. 2016-23, 2016-24
 *
 * @param viewDate
 * @returns {Array}
 */
function weekDates(viewDate) {
  const days = [];
  const startOfWeek = moment(viewDate, 'GGGG-WW').startOf('isoWeek'); // will be 00:00

  for (let i = 0; i < 7; i++) {
    days.push(moment(startOfWeek)); // Defensive copy as the add() call is mutating the instance
    startOfWeek.add(1, 'd');
  }
  return days;
}

function getColumn(momentDate, entityId, resourceIds) {
  return {
    id: `${entityId}:${momentDate.format('YYYY-MM-DD')}`,
    resourceIds: resourceIds || [entityId],
    date: momentDate.format('YYYY-MM-DD'), // Format date as string as it's used to compare the column.date to the schedule.day which is a string
    momentDate
  };
}

function resourceColumns(viewDate, entityId) {
  const momentDate = moment(viewDate, 'YYYY-MM-DD');
  return [
    getColumn(momentDate, entityId)
  ];
}

const defaultArray = [];

function groupColumns(viewDate, resourceIds) {
  const momentDate = moment(viewDate, 'YYYY-MM-DD');
  return resourceIds.map((resId) => getColumn(momentDate, resId));
}

export function dayColumns(viewDate, entityType, entityId, resourceIds) {
  switch (entityType) {
    case 'group':
      return groupColumns(viewDate, resourceIds);
    case 'resource':
    case 'my-resource':
      return resourceIds.length > 1
        ? groupColumns(viewDate, resourceIds)
        : resourceColumns(viewDate, entityId);
    default:
      console.error(`unknown entityType: ${entityType}`);
      return defaultArray;
  }
}

export function weekColumns(viewDate, entityType, entityId, resourceIds) {
  return weekDates(viewDate)
    .map((date) => getColumn(date, entityId, resourceIds));
}
