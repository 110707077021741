import React from 'react';
import { colors } from './styled/variables';
import Label from './label';

const SysadminLabel = (props) => (
  <Label
    bgColor={colors.darkGray80}
    className="ml2"
    {...props}
  >
    Sysadmin
  </Label>
);

export default SysadminLabel;
