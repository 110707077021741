import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popover from '@Components/ui/popover';
import { Button } from '@Components/ui/styled/main';
import { getSysadminLocations } from '@Components/sysadmin/accounts/accounts-selector';
import { useSelector } from 'react-redux';

const AccountHeaderBtnRedirect = ({ buttonText, getHREF, iconClass }) => {
  const sysadminLocations = useSelector(state => getSysadminLocations(state));
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const hideConfirm = () => setShowConfirmDelete(false);
  const showConfirm = () => setShowConfirmDelete(true);
  const hasMoreLocations = sysadminLocations?.size > 1;
  const iconClasses = iconClass + (buttonText ? ' mr1' : '');

  const handleClick = () => {
    window.open(getHREF(sysadminLocations.first()));
  };

  if (!hasMoreLocations) {
    return (
      <Button marginRight gray small onClick={handleClick}>
        <i className={iconClasses} />
        <span>{buttonText}</span>
      </Button>
    );
  }

  return (
    <Popover
      className="Popover-big-radius"
      isOpen={showConfirmDelete}
      onOuterAction={hideConfirm}
      preferPlace="below"
      body={(
        <div className="Popover-content">
          <div className="menu-button-wrapper">
            {sysadminLocations.map((location) => (
              <a
                className="btn btn-block btn-default"
                target="_blank"
                key={location.get('id')}
                href={getHREF(location)}
              >
                {location.get('name')}
              </a>
            ))}
          </div>
        </div>
      )}
    >
      <Button marginRight gray small onClick={showConfirm}>
        <i className={iconClasses} />
        <span>{buttonText}</span>
      </Button>
    </Popover>
  );
};

AccountHeaderBtnRedirect.propTypes = {
  buttonText: PropTypes.string,
  getHREF: PropTypes.func
};

export default AccountHeaderBtnRedirect;
