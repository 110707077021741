import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { CHANGE_JSON_PREFS, SHOW_REPORTS } from '@Utils/permissions';
import LabelSwitchInput from '@Components/ui/label-switch-input';
import { updateUserPermissions } from '@State/users-actions';

const availablePermissions = [
  CHANGE_JSON_PREFS,
  SHOW_REPORTS
];

function getPermissionLabel(permission) {
  switch (permission) {
    case CHANGE_JSON_PREFS:
      return 'Tillåt användaren ändra JSON-inställningar';
    case SHOW_REPORTS:
      return 'Tillåt användaren se rapporter';
    default:
      return permission;
  }
}

const StaffRolePermissions = ({ user }) => {
  const dispatch = useDispatch();
  const permissionsArray = user.get('permissions') || [];

  const onChangeToggle = useCallback((permission) => {
    const newPermissions = permissionsArray.indexOf(permission) !== -1
      ? permissionsArray.filter(p => p !== permission)
      : permissionsArray.concat([permission]);

    return dispatch(updateUserPermissions(user.get('id'), newPermissions.join(',')));
  }, [user]);

  return (
    <div className="mt3">
      {availablePermissions.map((permission) => {
        const isEnabled = permissionsArray.indexOf(permission) !== -1;
        return (
          <LabelSwitchInput
            noBorder
            id={permission}
            key={permission}
            isOn={isEnabled}
            label={getPermissionLabel(permission)}
            handleToggle={() => onChangeToggle(permission)}
          />
        );
      })}
    </div>
  );
};

export default StaffRolePermissions;
