import React, { useEffect } from 'react';
import { Field } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { payment } from '@Utils/preference-keys';
import { fetchAllPosUnits } from '@State/pos-config-actions';
import { getAllEcomPosUnits } from '@State/pos-config-selectors';

const PosUnitSelectField = () => {
  const dispatch = useDispatch();
  const ecomPosUnits = useSelector(getAllEcomPosUnits);

  useEffect(() => {
    if (ecomPosUnits.isEmpty()) {
      dispatch(fetchAllPosUnits());
    }
  }, []);

  return (
    <div className="select-container">
      <Field
        name={payment.defaultEcomVunitId}
        component="select"
        className="form-control"
        parse={val => val ? parseInt(val) : null}
      >
        <option disabled>Välj betalningsmottagare</option>
        {ecomPosUnits?.map(posUnit => (
          <option key={posUnit.get('id')} value={posUnit.get('id')}>
            {posUnit.get('posOrgName')}
          </option>
        ))}
      </Field>
    </div>
  );
};

export default PosUnitSelectField;
