import moment from 'moment';
import { capitalize } from 'underscore.string';
import isString from 'lodash/isString';

import { DAY_MONTH_YEAR_TIME_FORMAT, DAY_TEXT_FORMAT } from '@Utils/time-constants';

// TODO could be move to time-utils
export const formatDate = (value, format) => {
  if (value) {
    const date = moment(value);
    if (date.isSame('9999-01-01')) return 'tillsvidare';
    return capitalize(date.format(isString(format) ? format : DAY_MONTH_YEAR_TIME_FORMAT));
  }
};

export const getDayName = (day) => capitalize(moment(day === 7 ? 0 : day, 'd').format(DAY_TEXT_FORMAT));
