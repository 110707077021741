import React from 'react';
import ReactPopover from 'react-popover';

const stylePopover = { zIndex: 11000 };

const Popover = (props) => {
  return (
    <ReactPopover
      enterExitTransitionDurationMs={0}
      refreshIntervalMs={150}
      style={stylePopover}
      {...props}
    >
      {props.children}
    </ReactPopover>
  );
};

export default Popover;
