import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { colors } from '@Components/ui/styled/variables';
import SearchInput from '@Components/nav/search-field';
import { resetSearch, showSearch } from '@State/booking-actions';
import useOnClickOutside from '@Hooks/useOnClickOutside';
import { classes } from '@Components/ui/utils';

const SearchStyle = styled.div`
  .small-search {
    position: absolute !important;
    top: 78px;
    z-index: 1;
    width: 234px;
    right: 8px;

    input {
      background:#fff;
      color: ${colors.textColor};
      ::placeholder {
        color: ${colors.textMuted} !important;
        opacity: 1;
        margin-left: auto;
      }
    }
    .fa-search {
      color: ${colors.textMuted};
    }
    i {
      color: ${colors.grayBGLight};
    }
  }
`;

const IconWrap = styled.div`
  i {
    width: 26px;
    height: 26px;
    border-radius: 15px;
    background-color: ${props => props.opened ? colors.gray : colors.grayLight};
    color: ${props => props.opened ? '#fff' : colors.gray};
    ::before {
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 26px;
        width: 26px;
    }
  }
  i.opened {
    color: ${props => props.opened ? '#fff' : colors.gray};
  }
`;

const SmallSearchInput = ({
  showSearch, resetSearch, className, style
}) => {
  const ref = useRef();
  const [isShowSearch, setIsShowSearch] = useState(false);

  useEffect(() => {
    return () => {
      resetSearch();
    };
  }, [showSearch]);

  const handleToggleShowSearch = () => {
    setIsShowSearch(!isShowSearch);

    if (!isShowSearch) {
      showSearch();
    } else {
      resetSearch();
    }
  };

  useOnClickOutside(ref, () => setIsShowSearch(false));

  const classList = classes({
    'fa fa-search': true,
    opened: isShowSearch
  });

  return (
    <SearchStyle style={style} className={className}>
      <IconWrap opened={isShowSearch} onClick={handleToggleShowSearch}>
        <i className={classList} />
      </IconWrap>
      {isShowSearch && <SearchInput className="small-search" />}
    </SearchStyle>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    showSearch: () => {
      dispatch(showSearch());
    },
    resetSearch: () => {
      dispatch(resetSearch());
    }
  };
};

export default connect(null, mapDispatchToProps)(SmallSearchInput);
