import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import Popover from '@Components/ui/popover';
import { getVoucherTemplateDefaultValues } from '@State/selectors';
import { createVoucherTemplate } from '@State/voucher-template-actions';
import { getSectionUrl, navigate } from '@Utils/navigate';
import AddItemForm from '../../sortable-list/add-item-form';

class VoucherTemplateActionButtons extends Component {
  static propTypes = {
    onAddVoucherTemplate: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      addVoucherTemplate: false
    };
  }

  render() {
    return (
      <>
        {this.renderAddVoucherTemplateButton()}
      </>
    );
  }

  renderAddVoucherTemplateButton() {
    return (
      <Popover
        isOpen={this.state.addVoucherTemplate}
        body={this.addVoucherTemplateContent()}
        preferPlace="below"
        className="Popover-big-radius"
        onOuterAction={this.handleCloseVoucherTemplatePopover}
      >
        <button type="button" className="btn btn-cta" onClick={this.handleOpenVoucherTemplatePopover}>Lägg till mall</button>
      </Popover>
    );
  }

  handleOpenVoucherTemplatePopover = () => {
    this.setState({ addVoucherTemplate: true });
  };

  handleCloseVoucherTemplatePopover = () => {
    this.setState({ addVoucherTemplate: false });
  };

  handleAddVoucherTemplate = (groupId, description) => {
    return this.props
      .onAddVoucherTemplate({
        description,
        ...this.props.defaultValues
      })
      .then(() => this.handleCloseVoucherTemplatePopover());
  };

  addVoucherTemplateContent() {
    return (
      <AddItemForm
        onClosePopover={this.handleCloseVoucherTemplatePopover}
        onAddItem={this.handleAddVoucherTemplate}
        placeholder="Ange namn på mallen"
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultValues: getVoucherTemplateDefaultValues(state)
  };
};

const mapDispatchToProps = (dispatch, { routeParams }) => {
  return {
    onAddVoucherTemplate: (voucherTemplate) => {
      return dispatch(createVoucherTemplate(voucherTemplate))
        .then(({ id }) => navigate(getSectionUrl(routeParams, 'admin/voucher-templates', id)));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VoucherTemplateActionButtons);
