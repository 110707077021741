import { useEffect } from 'react';
import { addWakeCallback, removeWakeCallback } from '@Utils/wake-me-up';

export function useReloadOnWake(callback) {
  useEffect(() => {
    window.addEventListener('online', handleOnline);
    addWakeCallback(handleWake);

    return () => {
      window.removeEventListener('online', handleOnline);
      removeWakeCallback(handleWake);
    };
  }, []);

  const handleOnline = () => {
    console.info("Browser reported 'online' will try to reload viewData");
    setTimeout(() => callback(), 500);
  };

  const handleWake = () => {
    console.info('Awake from sleep detected - will try to reload viewData');

    setTimeout(() => {
      if (window.navigator.onLine) {
        callback();
      } else {
        console.info('Browser not online as reported by window.navigator.onLine, skipping reloadViewData');
      }
    }, 500);
  };
}
