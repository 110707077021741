import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { classes } from '@Components/ui/utils';

const NavItemLink = ({
  withoutId, tabId, subUrl, query = '',
  navId, name, org, loc, tab, id, label
}) => {
  const url = withoutId
    ? `/${org}/${loc}/${subUrl}/${navId}${query}`
    : `/${org}/${loc}/${subUrl}/${id}/${navId}${query}`;

  const urlId = tab || tabId || id;
  const classNames = classes({
    tabs__item: true,
    'tabs__item--active': urlId === navId || !urlId && !navId
  });
  return (
    <li className={classNames}>
      <Link to={url}>
        {name}
        {label && <span className="tabs__item__label">{label}</span>}
      </Link>
    </li>
  );
};

const NavTabsLink = ({
  withoutId = false, sections, bottomMargin, subUrl,
  query, routeParams, className, ...data
}) => {
  const classNames = classes({
    tabs__list: true,
    'bottom-margin': bottomMargin,
    [className]: className
  });

  return (
    <ul className={classNames}>
      {sections.map(section => (
        <NavItemLink
          key={section.navId}
          withoutId={withoutId}
          subUrl={subUrl}
          query={query}
          {...section}
          {...routeParams}
          {...data}
        />
      ))}
    </ul>
  );
};

NavTabsLink.propTypes = {
  sections: PropTypes.array,
  subUrl: PropTypes.string,
  withoutId: PropTypes.bool
};

export default memo(NavTabsLink);
