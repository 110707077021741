import axios from 'axios';
import {
  axiosDefault, axiosErrorHandler, checkStatusAxios, prefixUrl
} from '@Utils/ajax-util';

export const VOUCHER_TEMPLATES_FETCHED = 'VOUCHER_TEMPLATES_FETCHED';
export const VOUCHER_TEMPLATE_CREATED = 'VOUCHER_TEMPLATE_CREATED';
export const VOUCHER_TEMPLATE_UPDATED = 'VOUCHER_TEMPLATE_UPDATED';
export const VOUCHER_TEMPLATE_DELETED = 'VOUCHER_TEMPLATE_DELETED';

export function fetchVoucherTemplates() {
  return dispatch => {
    const url = prefixUrl('/voucher/templates/');
    const config = axiosDefault();

    return axios
      .get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then(({ templates }) => dispatch({ type: VOUCHER_TEMPLATES_FETCHED, templates }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

function normalizeVoucherTemplate(template) {
  if (template.accountingType === 'MultiPurpose') {
    template.vatPct = 0;
  }
}

export function createVoucherTemplate(data) {
  return (dispatch) => {
    const url = prefixUrl('/voucher/templates/');
    const config = axiosDefault();
    normalizeVoucherTemplate(data);

    return axios
      .post(url, data, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then(({ id }) => {
        const template = { id, ...data };
        dispatch({ type: VOUCHER_TEMPLATE_CREATED, template });
        return template;
      })
      .catch(error => {
        axiosErrorHandler(error, dispatch);
        throw error;
      });
  };
}

export function updateVoucherTemplate(template) {
  return (dispatch) => {
    const url = prefixUrl(`/voucher/templates/${template.id}`);
    const config = axiosDefault();
    normalizeVoucherTemplate(template);

    return axios
      .put(url, template, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then(() => dispatch({ type: VOUCHER_TEMPLATE_UPDATED, template }))
      .catch(error => {
        axiosErrorHandler(error, dispatch);
        throw error;
      });
  };
}

export function deleteVoucherTemplate(templateId) {
  return (dispatch) => {
    const url = prefixUrl(`/voucher/templates/${templateId}`);
    const config = axiosDefault();

    return axios
      .delete(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => dispatch({ type: VOUCHER_TEMPLATE_DELETED, templateId }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}
