export function formatVehicleRegNo(value) {
  if (!value) {
    return null;
  }
  return value.toUpperCase().replace(/\s/, '');
}

export const vehiclePreferenceKeys = {
  vehicle_eeg: 'EEG',
  vehicle_vin: 'VIN-nummer',
  vehicle_make: 'Fabrikat',
  vehicle_co2_1: 'CO2',
  vehicle_co2_2: 'CO2 2',
  vehicle_co2_3: 'CO2 3',
  vehicle_color: 'Färg',
  vehicle_hitch: 'Dragkrok',
  vehicle_model: 'Modell',
  vehicle_nox_1: 'NOx',
  vehicle_nox_2: 'NOx 2',
  vehicle_nox_3: 'NOx 3',
  vehicle_regNo: 'Reg.nummer',
  vehicle_width: 'Bredd (mm)',
  vehicle_chassi: 'Chassi',
  vehicle_colour: 'Färg',
  vehicle_fuel_1: 'Bränsle',
  vehicle_fuel_2: 'Bränsle 2',
  vehicle_fuel_3: 'Bränsle 3',
  vehicle_height: 'Höjd (mm)',
  vehicle_length: 'Längd (mm)',
  vehicle_hitch_2: 'Dragkrok 2',
  vehicle_category: 'Kategori',
  vehicle_rim_back: 'Fälgdimension fram',
  vehicle_bus_class: 'Bussklass',
  vehicle_eco_class: 'Ekonomiklass',
  vehicle_euro_ncap: 'Euro NCAP',
  vehicle_modelYear: 'Årsmodell',
  vehicle_ownerName: 'Namn',
  vehicle_ownerType: 'Typ',
  vehicle_rim_front: 'Fälgdimension fram',
  vehicle_thc_nox_1: 'THC+NOx',
  vehicle_thc_nox_2: 'THC+NOx 2',
  vehicle_thc_nox_3: 'THC+NOx 3',
  vehicle_tire_back: 'Däckdimension bak',
  vehicle_top_speed: 'Maxhastighet (km/h)',
  vehicle_axel_width: 'Axelbredd (mm)',
  vehicle_power_hp_1: 'Motoreffekt (hp)',
  vehicle_power_hp_2: 'Motoreffekt (hp) 2',
  vehicle_power_hp_3: 'Motoreffekt (hp) 3',
  vehicle_power_kw_1: 'Motoreffekt (kw)',
  vehicle_power_kw_2: 'Motoreffekt (kw) 2',
  vehicle_power_kw_3: 'Motoreffekt (kw) 3',
  vehicle_tire_front: 'Däckdimension fram',
  vehicle_kerb_weight: 'Tjänstevikt (kg)',
  vehicle_load_weight: 'Maxlast (kg)',
  vehicle_vehicleYear: 'Fordonsår',
  vehicle_axle_width_2: 'Axelbredd (mm) 2',
  vehicle_axle_width_3: 'Axelbredd (mm) 3',
  vehicle_ownerOrgOrPn: 'Personnr/org.nr',
  vehicle_total_weight: 'Totalvikt (kg)',
  vehicle_transmission: 'Växellåda',
  vehicle_work_vehicle: 'Arbetsfordon',
  vehicle_chassi_code_1: 'Chassikod',
  vehicle_chassi_code_2: 'Chassikod 2',
  vehicle_consumption_1: 'Bränsleförbrukning',
  vehicle_consumption_2: 'Bränsleförbrukning 2',
  vehicle_consumption_3: 'Bränsleförbrukning 3',
  vehicle_sound_level_1: 'Ljudnivå (dB)',
  vehicle_sound_level_2: 'Ljudnivå (dB) 2',
  vehicle_sound_level_3: 'Ljudnivå (dB) 3',
  vehicle_emission_class: 'Utsläppsklass',
  vehicle_trailer_weight: 'Max släpvikt (kg)',
  vehicle_carriage_weight: 'Transportvikt (kg)',
  vehicle_cylinder_volume: 'Cylindervolym (cm3)',
  vehicle_ownerAdressArea: 'Postort',
  vehicle_axle_width_min_1: 'Min axelbredd (mm)',
  vehicle_axle_width_min_2: 'Min axelbredd (mm) 2',
  vehicle_axle_width_min_3: 'Min axelbredd (mm) 3',
  vehicle_four_wheel_drive: 'Fyrhjulsdrift',
  vehicle_fuel_combination: 'Bränslekombination',
  vehicle_ownerAdressLine1: 'Adressrad 1',
  vehicle_ownerAdressLine2: 'Adressrad 2',
  vehicle_ownerAdressLine3: 'Adressrad 3',
  vehicle_passenger_airbag: 'Airbag passagerarsida',
  vehicle_trailer_weight_b: 'Max släpvikt, B-körkort (kg)',
  vehicle_trailer_weight_be: 'Max släpvikt, BE-körkort (kg)',
  vehicle_number_of_bus_seats: 'Antal säten',
  vehicle_ownerAdressPostCode: 'Postnummer',
  vehicle_number_of_passengers: 'Antal passagerare',
  vehicle_unbraked_trailer_weight: 'Max släpvikt, obromsat (kg)'
};

export const vehicleOwnerFields = [
  'vehicle_ownerType',
  'vehicle_ownerOrgOrPn',
  'vehicle_ownerName',
  'vehicle_ownerAdressLine1',
  'vehicle_ownerAdressLine2',
  'vehicle_ownerAdressLine3',
  'vehicle_ownerAdressPostCode',
  'vehicle_ownerAdressArea'
];

export const vehicleBaseFields = [
  'vehicle_make',
  'vehicle_model',
  'vehicle_color',
  'vehicle_modelYear',
  'vehicle_fuel_1',
  'vehicle_transmission',
  'vehicle_vin'
];

function vehicleOwnerTypeValue(value) {
  switch (value) {
    case 'person': return 'Privatperson';
    case 'company': return 'Företag';
    default: return value;
  }
}

function vehicleTransmissionValue(value) {
  switch (value) {
    case 1: return 'Manuell';
    case 2: return 'Automat';
    default: return value;
  }
}

function vehicleFuelValue(value) {
  switch (value) {
    case 1: return 'Bensin';
    case 2: return 'Diesel';
    case 3: return 'El';
    case 7: return 'Etanol';
    default: return value;
  }
}

export function vehicleAttributeValue(key, value) {
  if (value === true) {
    return 'Ja';
  }
  if (value === false) {
    return 'Nej';
  }
  if (key === 'vehicle_ownerType') {
    return vehicleOwnerTypeValue(value);
  }
  if (key === 'vehicle_transmission') {
    return vehicleTransmissionValue(value);
  }
  if (key.indexOf('vehicle_fuel') !== -1) {
    return vehicleFuelValue(value);
  }
  return value;
}

export function isOwnerUnknown(vehicleAttributes) {
  return vehicleAttributes.vehicle_ownerType === 'unknown';
}

export function getVehicleInfo(vehicleRegNo, vehicleDescription) {
  return [vehicleRegNo, vehicleDescription].filter(s => s).join(' - ');
}
