import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { setPassword } from '@State/user-actions';
import ModalDialog from '@Components/dialogs/modal-dialog';
import DialogAlert from '@Components/dialogs/dialog-alert';
import SetPasswordForm from './set-password-form';

const SetPasswordModal = ({ userId, onClose }) => {
  const dispatch = useDispatch();

  const [passwordSet, setPasswordSet] = useState(false);
  const [passwordError, setPasswordError] = useState(null);

  const onSubmit = ({ password }) => {
    return dispatch(setPassword(userId, password))
      .then(() => setPasswordSet(true))
      .catch((error) => {
        setPasswordError(error);
        throw error;
      });
  };

  if (passwordSet) {
    return (
      <DialogAlert
        success
        title="Byt lösenord"
        icon="fa fa-check-circle"
        text="Lösenordet har ändrats"
        onClose={onClose}
      />
    );
  }

  return (
    <ModalDialog
      hideCloseButton
      contentSize="medium"
      title="Byt lösenord"
      closeButtonText="Stäng"
      closeOnClickOutside={false}
      onClose={onClose}
    >
      <SetPasswordForm
        onClose={onClose}
        onSubmit={onSubmit}
        passwordError={passwordError}
      />
    </ModalDialog>
  );
};

export default SetPasswordModal;
