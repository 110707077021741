const s = require('underscore.string');

module.exports = function PhoneNumber(number, countryCode, nddPrefix, isInternational) {
  this.number = number;
  this.countryCode = countryCode;
  this.nddPrefix = nddPrefix;
  this.isInternational = isInternational;

  this.localized = function localized() {
    if (this.isInternational) {
      return this.e164formatted();
    }
    return this.withNDD();
  };

  this.withNDD = function withNDD() {
    return (this.number === undefined || s.isBlank(this.number)) ? '' : this.nddPrefix + this.number;
  };

  this.withCountryCode = function withCountryCode() {
    return (this.number === undefined || s.isBlank(this.number)) ? '' : this.countryCode + this.number;
  };

  this.e164formatted = function e164formatted() {
    return `+${this.withCountryCode()}`;
  };
};
