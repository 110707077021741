import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { classes } from '@Components/ui/utils';
import { blockClosed } from './grid-util';

export default class ColumnWebIndicator extends Component {
  static propTypes = {
    block: PropTypes.object
  };

  render() {
    const { block, showHolidays } = this.props;

    let closed = true,
      dayPast = false,
      text = 'Onlinebokning stängd, schemat är stängt';

    if (block) {
      const { day } = block;
      closed = blockClosed(block);
      dayPast = moment(day).isBefore(moment(), 'day');
      text = toolTipText(block, dayPast, showHolidays);
    }

    const indicatorClass = classes({
      'web-indicator': true,
      open: !closed,
      closed
    });

    return (
      <div className="web-indicator-container" data-tip={text}>
        <div className={indicatorClass} />
      </div>
    );
  }
}

const toolTipText = ({
  webOpen, vipOpen, closed, closedMax, publicHoliday, holidayName, bookingMaxDaysInAdvance
}, dayPast, showHolidays) => {
  if (!webOpen && !vipOpen) {
    return 'Onlinebokning avstängd för denna resurs';
  }
  if (dayPast) {
    return 'Onlinebokning stängd, dagen är passerad';
  }
  if (closed && publicHoliday && showHolidays) {
    return `Onlinebokning stängd, ${holidayName}`;
  }
  if (closedMax) {
    return `Onlinebokning stängd, över ${bookingMaxDaysInAdvance} dagar bort`;
  }
  if (closed) {
    return 'Onlinebokning stängd, schemat är stängt';
  }
  if (webOpen && vipOpen) {
    return 'Web & VIP öppen, dagen är bokningsbar';
  }
  if (webOpen) {
    return 'Onlinebokning öppen, dagen är bokningsbar';
  }
  if (vipOpen) {
    return 'VIP-bokning öppen, dagen är bokningsbar';
  }
};
