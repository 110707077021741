import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import { required } from '@Utils/validation';
import TextInput from '@Components/inputs/text-input';
import ModalDialog from '@Components/dialogs/modal-dialog';
import SelectField from '@Components/inputs/select-field';
import FormButtonsFooter from '@Components/ui/form-buttons-footer';
import ConfirmAddProduct from '@Components/pos/dialogs/confirm-add-product';
import SupplierProductInfo from '@Components/pos/products/supplier-product-info';
import CheckBoxEl from '@Components/inputs/checkbox-styled';

const AddProductModal = ({
  handleSubmit, submitting, invalid, onClose, groups, barcode, supplierProduct, showNewGroup
}) => {
  const [confirmed, setConfirmed] = useState(!barcode);

  if (!confirmed) {
    return (
      <ConfirmAddProduct
        supplierProduct={supplierProduct}
        onAddProduct={() => setTimeout(() => setConfirmed(true), 100)}
        onClose={onClose}
      />
    );
  }

  return (
    <ModalDialog
      onEnter={handleSubmit}
      onClose={onClose}
      title="Ny produkt"
      hideCloseButton
    >
      <form onSubmit={handleSubmit} autoComplete="off" className="dialog">
        {supplierProduct && (
          <SupplierProductInfo supplierProduct={supplierProduct} />
        )}
        {!supplierProduct && (
          <>
            <Field
              type="text"
              name="name"
              component={TextInput}
              label="Namn på produkten"
              validate={required}
              autoFocus
            />
            <Field
              type="text"
              name="brand"
              component={TextInput}
              label="Varumärke"
            />
          </>
        )}
        <Field
          label="Grupp"
          name="groupId"
          component={SelectField}
          validate={[required]}
        >
          {showNewGroup && (
            <option value="NEW">*NY* - {supplierProduct.brand}</option>
          )}
          {groups.map(group => (
            <option key={group.get('id')} value={group.get('id')}>
              {group.get('name')}
            </option>
          ))}
        </Field>
        <Field
          type="number"
          name="priceOut"
          component={TextInput}
          label="Försäljningspris (inkl. moms)"
          validate={required}

        />
        <div className="form-group">
          <Field
            name="stocked"
            component={CheckBoxEl}
            label="Aktivera lagerhantering"
          />
        </div>
        <FormButtonsFooter
          onClose={onClose}
          pristine={invalid}
          submitting={submitting}
          handleSubmit={handleSubmit}
          submitText="Lägg till produkt"
          cancelText="Avbryt"
        />
      </form>
    </ModalDialog>
  );
};

export default reduxForm({
  form: 'add-product-form'
})(AddProductModal);
