import React from 'react';
import { useSelector } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import ModalDialog from '@Components/dialogs/modal-dialog';
import DialogNotification from '@Components/dialogs/dialog-notification';
import TextInput from '@Components/inputs/text-input';

const selector = formValueSelector('receipt-email-form');

export const SendReceiptForm = ({
  handleSubmit, onClose, submitting, submitSucceeded
}) => {
  if (!submitting && submitSucceeded) {
    const email = useSelector(state => selector(state, 'email'));
    return (
      <DialogNotification title="Kvitto skickat" onClose={onClose}>
        Kvittot har mejlats till {email}.
      </DialogNotification>
    );
  }

  return (
    <ModalDialog
      title="Skicka kvitto"
      onClose={onClose}
      buttons={[{
        primary: true,
        name: submitting ? 'Skickar kvitto...' : 'Skicka kvitto',
        disabled: submitting,
        onClick: handleSubmit
      }]}
    >
      <form onSubmit={handleSubmit} className="dialog">
        <Field
          component={TextInput}
          name="email"
          placeholder="Ange mejladress"
          className="form-control"
          autoFocus
        />
      </form>
    </ModalDialog>
  );
};

export default reduxForm({
  form: 'receipt-email-form'
})(SendReceiptForm);
