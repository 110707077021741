import { defineMessages } from 'react-intl';

export default defineMessages({
  noGiftCardsTitle: {
    id: 'customers.giftCards.noGiftCardsTitle',
    defaultMessage: 'No gift cards'
  },
  noGiftCardsText: {
    id: 'customers.giftCards.noGiftCardsText',
    defaultMessage: 'There are no gift cards for this customer'
  },
  lblDetails: {
    id: 'customers.giftCards.lblDetails',
    defaultMessage: 'Details'
  }
});
