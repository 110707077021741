import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createUser, fetchUsers } from '@State/users-actions';
import { getSectionUrl, navigate } from '@Utils/navigate';

import Button from '@Components/ui/tb-button';
import StaffConfigNewUser from '@Components/admin/staff-config/staff-config-new-user';

const StaffConfigToolbar = ({ routeParams }) => {
  const dispatch = useDispatch();

  const [isAddUser, setIsAddUser] = useState(false);
  const toggleAddUser = useCallback(() => setIsAddUser(!isAddUser), []);

  const hideAddUser = useCallback((e) => {
    e && e.preventDefault();
    setIsAddUser(false);
  }, []);

  const handleAddUser = useCallback(async (user) => {
    const { userId } = await dispatch(createUser(user));
    hideAddUser();
    await dispatch(fetchUsers());
    return navigate(getSectionUrl(routeParams, 'admin/users', userId));
  }, []);

  return (
    <StaffConfigNewUser
      isOpenPopover={isAddUser}
      onHidePopup={hideAddUser}
      onSubmit={handleAddUser}
    >
      <button type="button" className="btn btn-cta" onClick={toggleAddUser}>
        Lägg till användare
      </button>
    </StaffConfigNewUser>
  );
};

export default StaffConfigToolbar;
