import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import DialogAlert from '@Components/dialogs/dialog-alert';

const TerminalDisconnectError = ({ onRetry }) => {
  const [progress, setProgress] = useState(false);

  const handleRetry = async () => {
    try {
      setProgress(true);
      await onRetry();
    } finally {
      setProgress(false);
    }
  };

  return (
    <DialogAlert
      buttons={[{
        name: 'Försök igen',
        primary: true,
        loading: progress,
        onClick: handleRetry
      }]}
      icon="fa fa-exclamation-triangle"
      warning
      text={(
        <div>
          Kontakten med terminalen avbröts under pågående köp.
          Kontrollera att terminalen är ansluten och försök igen.
        </div>
      )}
    />
  );
};

export default TerminalDisconnectError;
