import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { activateAccount } from '@State/sysadmin-actions';
import ButtonModalForm from '@Components/dialogs/button-modal-form';
import AccountActivateForm from '../account-activate-form';

const AccountHeaderBtnActivate = ({ id }) => {
  const dispatch = useDispatch();

  const onSubmitActivate = useCallback(async ({ pricePlan }) => {
    await dispatch(activateAccount(id, pricePlan));
  }, [id]);

  return (
    <ButtonModalForm
      btnText="Activate"
      onSubmit={onSubmitActivate}
    >
      {({ ...props }) => (
        <AccountActivateForm {...props} />
      )}
    </ButtonModalForm>
  );
};

export default AccountHeaderBtnActivate;
