import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import isString from 'lodash/isString';
import moment from 'moment';

import { convertExceptions } from '@Utils/schedule-utils';
import { deleteScheduleExceptions } from '@State/schedule-actions';
import { DAY_DATE_FULL } from '@Utils/time-constants';
import ConfirmPopover from '@Components/ui/confirm-popover';

const ScheduleExceptions = ({ resourceId, exceptions }) => {
  const dispatch = useDispatch();
  const [isShowConfirmation, setIsShowConfirmation] = useState(false);
  const hideConfirmation = () => setIsShowConfirmation(false);
  const showConfirmation = () => setIsShowConfirmation(true);

  const handleDelete = (date) => {
    if (isString(date)) {
      return dispatch(deleteScheduleExceptions(resourceId, date));
    }
    dispatch(deleteScheduleExceptions(resourceId));
    hideConfirmation();
  };

  return (
    <div className="schedule-exceptions">
      <table className="table table-striped">
        <thead>
          <tr>
            <th className="col-xs-3">Datum</th>
            <th className="col-xs-3">Bokningsbar</th>
            <th className="col-xs-3">Ej bokningsbar</th>
            <th className="col-xs-3">
              <ConfirmPopover
                text="Ta bort alla undantag permanent?"
                confirmText="Ta bort alla"
                onClose={hideConfirmation}
                onConfirm={handleDelete}
                isOpen={isShowConfirmation}
              >
                <button className="pull-right btn-link text-bold text-active" onClick={showConfirmation}>Ta bort alla undantag</button>
              </ConfirmPopover>
            </th>
          </tr>
        </thead>
        <tbody>
          {convertExceptions(exceptions).map(({
            date, closed, end, start, type
          }, index) => {
            const handler = () => handleDelete(date);
            return (
              <tr key={index}>
                <td className="col-xs-3 text-bold">
                  {moment(date).format(DAY_DATE_FULL)}
                </td>
                <td className="col-xs-3 text-bold text-main-time">
                  {type === 'CLOSED_ALLDAY'
                    ? <span className="text-danger">Stängt</span>
                    : <span>{start && end && `${start} - ${end}`}</span>}
                </td>
                <td className="col-xs-3 text-bold text-break-time">
                  {closed.map(({ start, end }, index) => (
                    <div key={index}>
                      {start} - {end}
                    </div>
                  ))}
                </td>
                <td className="col-xs-3 text-bold text-active">
                  <div className="pull-right">
                    <button className="btn-link text-bold text-active" onClick={handler}>Ta bort</button>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

ScheduleExceptions.propTypes = {
  exceptions: PropTypes.array
};

export default ScheduleExceptions;
