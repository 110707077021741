import moment from 'moment';
import { company } from './preference-keys';

export function defaultSmsConfirmTemplate() {
  return 'Tack för din bokning hos #RESURS #TIDPUNKT. Mvh #FÖRETAG\n\nBoka av: #BOKNINGSLÄNK';
}

export function defaultSmsReminderTemplate() {
  return 'Hej! Glöm inte din din bokning hos #RESURS #TIDPUNKT. Välkommen!';
}

export function defaultSmsCancelTemplate() {
  return 'AVBOKAD: #TIDPUNKT hos #RESURS. Mvh #FÖRETAG';
}

const joinAddress = (parts) => parts.filter(p => p).join(', ');

const shortAddress = (locationConfig) => [
  locationConfig.get(company.addressLine1),
  locationConfig.get(company.area)
];

const longAddress = (locationConfig) => [
  locationConfig.get(company.addressLine1),
  locationConfig.get(company.postCode),
  locationConfig.get(company.area),
  'Sverige'
];

export function templatePreviewValues({
  resourceName, serviceName, locationConfig
}) {
  return {
    '#TIDPUNKT': moment('2019-09-26 15:00').format('LLLL').replace('kl. ', ''),
    '#RESURS': resourceName || 'Maria Andersson',
    '#TJÄNST': serviceName || 'Klippning',
    '#FÖRETAG': locationConfig.get(company.companyName) || 'Cliento',
    '#TEL': locationConfig.get(company.contactNumber) || '08-410 224 60',
    '#ADRESSLÅNG': joinAddress(longAddress(locationConfig)) || 'Regeringsgatan 67, 111 56 Stockholm, Sverige',
    '#ADRESS': joinAddress(shortAddress(locationConfig)) || 'Regeringsgatan 67, Stockholm',
    '#BOKNINGSLÄNK': 'clien.to/x1x2x3xy',
    '#BOKNINGSREF': 'x1x2x3xy',
    '#KUNDNAMN': 'Lars Johansson',
    '#KUNDORGNR': '555555-5555',
    '#KUNDORGNAMN': 'Företaget AB',
    '#FORDONREG': 'ABC123'
  };
}

export function getPreviewText(value, replaceValues) {
  if (!value) {
    return value;
  }
  for (const key in replaceValues) {
    value = value.replace(new RegExp(key, 'gi'), replaceValues[key]);
  }
  return value;
}

export function getLongestName(entitiesById, param = 'name') {
  return entitiesById && entitiesById.size > 0
    ? entitiesById.reduce((a, b) => { return a[param].length > b[param].length ? a : b; })[param]
    : null;
}
