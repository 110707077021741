import React, { useLayoutEffect } from 'react';

const DELAY_TIME_TYPING = 400;

export default function useTimeout({
  handler, depends, delay = DELAY_TIME_TYPING, checkerFunction = () => true
}) {
  useLayoutEffect(() => {
    if (checkerFunction && checkerFunction()) {
      const timer = setTimeout(() => {
        handler();
      }, delay);
      return () => clearTimeout(timer);
    }
  }, [depends]);
}
