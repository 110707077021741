import PropTypes from 'prop-types';
import React, { Component } from 'react';
import GroupEntry from './group-entry';

export default class ResourceList extends Component {
  static propTypes = {
    routeParams: PropTypes.object.isRequired,
    groups: PropTypes.array.isRequired,
    resources: PropTypes.object.isRequired
  };

  render() {
    const { groups, resourceListViewMode } = this.props;
    const isCompact = resourceListViewMode === 'Compact';

    return (
      <div className={isCompact ? 'resource-list compact' : 'resource-list'}>
        <ul className="list-group">
          {groups.map(group => (
            <GroupEntry
              key={group.id}
              {...this.props}
              group={group}
            />
          ))}
        </ul>
      </div>
    );
  }
}
