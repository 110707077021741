import React, { useEffect, useState } from 'react';
import { Field } from 'redux-form';
import { useDispatch } from 'react-redux';
import { required } from '@Utils/validation';
import { pos } from '@Utils/preference-keys';
import SelectField from '@Components/inputs/select-field';
import TextInput from '@Components/inputs/text-input';
import { fetchFortnoxTermsOfPayments } from '@State/fortnox-actions';

const defaultTermsOfPayments = [
  { Code: 0, Description: '0 dagar' },
  { Code: 10, Description: '10 dagar' },
  { Code: 15, Description: '15 dagar' },
  { Code: 20, Description: '20 dagar' },
  { Code: 30, Description: '30 dagar' }
];

const PosInvoiceDueDaysField = ({ posOrgId, invoicePrefs, name, shortLabel = false, onChange = null }) => {
  const dispatch = useDispatch();
  const useFortnox = !!invoicePrefs[pos.invoiceUseFortnox];

  const label = 'Betalningsvillkor';
  const [loading, setLoading] = useState(false);
  const [termsOfPayments, setTermsOfPayments] = useState(defaultTermsOfPayments);

  useEffect(() => {
    if (useFortnox) {
      setLoading(true);
      dispatch(fetchFortnoxTermsOfPayments(posOrgId))
        .then(terms => setTermsOfPayments(terms))
        .finally(() => setLoading(false));
    }
  }, [posOrgId]);

  return useFortnox ? (
    <Field
      name={name}
      label={label}
      component={SelectField}
      validate={[required]}
      onChange={onChange}
      loading={loading}
    >
      {termsOfPayments.filter(({ Code }) => !isNaN(Code)).map(({ Code, Description }) => (
        <option key={Code} value={Code}>{Description}</option>
      ))}
    </Field>
  ) : (
    <Field
      type="number"
      name={name}
      component={TextInput}
      label={shortLabel ? label : `${label} (dagar)`}
      validate={[required]}
      onChange={onChange}
    />
  );
};

export default PosInvoiceDueDaysField;
