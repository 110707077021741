import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import CurrencyUtil from '@Utils/currency-util';
import { getPosOrgPrefs } from '@State/pos-selectors';
import { PosSaleRow, PosSaleTransactionRow, PosSaleRowTotal } from '@Components/pos/style';
import { getPaymentMethodName } from '@Utils/pos-utils';
import PosPaymentMethod from '../pos-payment-method';

const SaleFooter = ({
  totalValue,
  paidAmountValue,
  remainingAmountValue,
  transactions,
  prePayments
}) => {
  const posOrgPrefs = useSelector(getPosOrgPrefs);
  const [showTransactions, setShowTransactions] = useState(false);
  const hasTransactions = !transactions?.isEmpty();
  const hasPrePayments = prePayments?.size > 0;

  return (
    <>
      <PosSaleRow bold>
        <p>Totalt</p>
        <p>{totalValue}</p>
      </PosSaleRow>
      <PosSaleRow bold onClick={() => setShowTransactions(!showTransactions)}>
        <p>
          Betalt
          {(hasTransactions || hasPrePayments) && (
            <i className={showTransactions ? 'fa fa-chevron-circle-down' : 'fa fa-chevron-circle-right'} />
          )}
        </p>
        <p>{paidAmountValue}</p>
      </PosSaleRow>
      {hasPrePayments && showTransactions && prePayments.map((payment) => (
        <PosSaleTransactionRow key={payment.get('paymentId')}>
          <p>
            <PosPaymentMethod method="PrepaidBankPayment" />
            {getPaymentMethodName('PrepaidBankPayment')}
          </p>
          <p>{CurrencyUtil.accountCurrency(payment.get('amount'), 2)}</p>
        </PosSaleTransactionRow>
      ))}
      {hasTransactions && showTransactions && transactions.map((transaction) => {
        const paymentMethod = transaction.get('paymentMethodName') || transaction.get('paymentMethod');
        return (
          <PosSaleTransactionRow key={transaction.get('paymentId')}>
            <p>
              <PosPaymentMethod method={paymentMethod} />
              {getPaymentMethodName(paymentMethod, posOrgPrefs)}
            </p>
            <p>{CurrencyUtil.accountCurrency(transaction.get('amount'), 2)}</p>
          </PosSaleTransactionRow>
        );
      })}
      <PosSaleRowTotal>
        <p>Att betala</p>
        <p>{remainingAmountValue}</p>
      </PosSaleRowTotal>
    </>
  );
};

SaleFooter.propTypes = {
  totalValue: PropTypes.string,
  paidAmountValue: PropTypes.string,
  remainingAmountValue: PropTypes.string,
  transactions: PropTypes.object
};

export default SaleFooter;
