import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { mergePosUnitPrefs } from '@State/pos-config-actions';
import { Panel } from '@Components/ui/styled/main';
import LabelSwitchInput from '@Components/ui/label-switch-input';
import { pos } from '@Utils/preference-keys';

const preference = pos.useDeferredServiceSale;

const PosOrgDeferredSalesToggle = () => {
  const dispatch = useDispatch();
  const posUnits = useSelector(state => state.posUnits);
  const ecomPosUnit = posUnits?.find(u => u.get('type') === 'Ecom');

  const onToggle = useCallback(() => {
    const newValue = !ecomPosUnit.getIn(['prefs', preference]);
    return dispatch(mergePosUnitPrefs(ecomPosUnit.get('id'), {
      [preference]: newValue
    }));
  }, [preference, posUnits]);

  const isOn = ecomPosUnit
    ? !!ecomPosUnit.getIn(['prefs', preference])
    : false;

  return (
    <Panel maxWidth="550px">
      <div className="form-group">
        <LabelSwitchInput
          noBorder
          label="Bokför onlinebetalning via skuldkonto"
          id={preference}
          isOn={isOn}
          handleToggle={onToggle}
          disabled={!ecomPosUnit}
          description="Bokför betalda bokningar på skuldkonto tills bokningen har passerats, därefter bokförs beloppet på intäktskonto"
        />
      </div>
    </Panel>
  );
};

export default PosOrgDeferredSalesToggle;
