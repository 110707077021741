import React, { Component } from 'react';
import isEqual from 'lodash/isEqual';
import JSONEditor from 'jsoneditor';
import styled from 'styled-components';

import '@Components/ui/jsoneditor/styles.less';
import SubmitButton from '@Components/ui/submit-button';
import Text from '@Components/ui/styled/text-element';

const TitleWrap = styled.div`
  display: flex;
`;
const ButtonWrap = styled.div`
  margin-left: auto;
  margin-bottom: 8px;
`;

class JSONEditorForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHaveErrors: false
    };

    this.onSave = this.onSave.bind(this);
    this.onFormat = this.onFormat.bind(this);
  }

  componentDidMount() {
    const options = {
      mode: 'code',
      mainMenuBar: true
    };

    this.jsoneditor = new JSONEditor(this.container, options);
    this.jsoneditor.set(this.props.json);
    this.jsoneditor.aceEditor.setOptions({
      showGutter: false,
      maxLines: Infinity,
      highlightActiveLine: true,
      highlightSelectedWord: true
    });

    this.jsoneditor.aceEditor.getSession().on('changeAnnotation', () => {
      this.setState({
        isHaveErrors: this.jsoneditor.aceEditor.getSession().$annotations.length > 0
      });
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.id !== prevProps.id || this.props.json !== prevProps.json) {
      this.jsoneditor.set(this.props.json);
    }
  }

  componentWillUnmount() {
    if (this.jsoneditor) {
      this.jsoneditor.destroy();
    }
  }

  onSave(ev) {
    ev.preventDefault();
    const text = this.jsoneditor.get();
    JSON.stringify(text); // this is check for valid json

    this.setState({ submitting: true });
    this.props.onSubmit(text).then(() => {
      this.setState({
        submitSucceeded: true,
        submitting: false
      });
    });
  }

  onFormat(e) {
    e.preventDefault();
    this.jsoneditor.set(JSON.parse(JSON.stringify(this.jsoneditor.get(), null, 2)));
  }

  getDisabled() {
    try {
      return isEqual(this.props.json, this?.jsoneditor?.get());
    } catch (e) {
      return true;
    }
  }

  render() {
    const { isHaveErrors, submitting = false, submitSucceeded = false } = this.state;
    const { title, readonly } = this.props;
    const isDisabled = this.getDisabled();
    return (
      <form onSubmit={this.onSave}>
        <TitleWrap>
          {title && <h4>{title}</h4>}
          {!readonly && (
            <ButtonWrap>
              <button
                className="btn btn-default"
                onClick={this.onFormat}
              >
                Formatera
              </button> &nbsp;
              <SubmitButton
                submitting={submitting}
                submitSucceeded={submitSucceeded}
                disabled={isDisabled}
              >
                Spara
              </SubmitButton>
            </ButtonWrap>
          )}
        </TitleWrap>
        {isHaveErrors && <Text danger small bold>Ogiltigt JSON-format</Text>}
        <div className="jsoneditor-react-container" ref={elem => { this.container = elem; }} />
      </form>
    );
  }
}

export default JSONEditorForm;
