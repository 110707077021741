import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toggleResourcesCollapsed } from '@State/admin-view-reducer';
import {
  renameGroup, deleteGroup, moveResource, moveGroup
} from '@State/resource-actions';
import { getAdminResourceGroups, getFilteredResources } from '@Utils/filter-selectors';
import { getResourceConfigUrl, navigate } from '@Utils/navigate';
import { getResourceIcon } from '@Utils/resource-util';
import SortableList from '../sortable-list/sortable-list';

class ResourceList extends Component {
  render() {
    const {
      filteredGroups, filteredResources, routeParams, sortable, onToggleCollapse, collapsedIds,
      moveResource, moveGroup, updateResourceGroup, deleteResourceGroup
    } = this.props;

    return (
      <SortableList
        onChangeCollapsedIds={onToggleCollapse}
        collapsedIds={collapsedIds}
        groups={filteredGroups}
        groupItemSelector="resourceIds"
        items={filteredResources}
        itemContent={this.itemContent}
        selectedId={parseInt(routeParams.id)}
        onItemClick={this.onItemClick}
        updateGroup={updateResourceGroup}
        deleteGroup={deleteResourceGroup}
        moveGroup={moveGroup}
        moveItem={moveResource}
        sortable={sortable}
      />
    );
  }

  onItemClick = item => {
    const { routeParams } = this.props;
    navigate(getResourceConfigUrl(routeParams, item.id, routeParams.tab));
  };

  itemContent = item => {
    const { name, resourceType, prefs } = item;
    const { color } = prefs || {};

    return (
      <div>
        <i className={getResourceIcon(resourceType)} />
        {/* Hide this for now while we decide what to do with resource colors
        {color && <span className="columns-list-color-dot" style={{ background: color }} />}
        */}
        <span className="list-item-header">{name}</span>
      </div>
    );
  };
}

const mapStateToProps = (state, ownProps) => {
  const { adminViewState } = state;

  return {
    collapsedIds: adminViewState.get('resourcesCollapsedIds'),
    filteredResources: getFilteredResources(state, ownProps),
    filteredGroups: getAdminResourceGroups(state, ownProps)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onToggleCollapse: (collapsedIds) => {
      return dispatch(toggleResourcesCollapsed(collapsedIds));
    },
    moveResource: moveAction => {
      return dispatch(moveResource(moveAction));
    },
    moveGroup: moveAction => {
      return dispatch(moveGroup(moveAction));
    },
    updateResourceGroup: group => {
      return dispatch(renameGroup(group));
    },
    deleteResourceGroup: groupId => {
      return dispatch(deleteGroup(groupId));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResourceList);
