import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import { createValidator, required, trimString } from '@Utils/validation';
import TextInput from '@Components/inputs/text-input';
import ModalDialog from '@Components/dialogs/modal-dialog';
import DialogLoader from '@Components/dialogs/dialog-loader';
import { forceCreateReceipts } from '@State/pos-actions';
import { useDispatch } from 'react-redux';

const DialogPosFixSale = ({
  handleSubmit, submitting, onClose
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleFixSale = ({ saleId }) => {
    setLoading(true);
    return dispatch(forceCreateReceipts(saleId))
      .then(() => onClose())
      .catch(() => setLoading(false));
  };

  if (loading) {
    return <DialogLoader title="Rättar kvitto..." />;
  }

  return (
    <ModalDialog
      contentSize="small"
      onClose={onClose}
      title="Rätta kvitto"
      buttons={[{
        name: 'Rätta kvitto',
        primary: true,
        onClick: handleSubmit(handleFixSale),
        loading: submitting
      }]}
    >
      <form onSubmit={handleFixSale} autoComplete="off" className="dialog">
        <Field
          type="text"
          name="saleId"
          component={TextInput}
          label="Skriv in SaleId"
          normalize={trimString}
          autoFocus
        />
      </form>
    </ModalDialog>
  );
};

const validator = createValidator({
  saleId: [required]
});

export default reduxForm({
  form: 'pos-fix-sale',
  validate: validator
})(DialogPosFixSale);
