import React, { useState } from 'react';
import useIsMounted from '@Hooks/useIsMounted';
import { Button } from '@Components/ui/styled/main';

const ProgressButton = ({ onClick, children, ...props }) => {
  const isMounted = useIsMounted();
  const [progress, setProgress] = useState(false);

  const handleClick = () => {
    setProgress(true);
    onClick().finally(() => {
      if (isMounted()) {
        setProgress(false);
      }
    });
  };

  return (
    <Button loading={progress} onClick={handleClick} {...props}>
      {children}
    </Button>
  );
};

export default ProgressButton;
