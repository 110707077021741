import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  getSysadminStateOrganizationById
} from '@Components/sysadmin/accounts/accounts-selector';
import { fetchOrganizationByIdApi } from '@State/sysadmin-actions';

import Loader from '@Components/ui/loader';
import AccountOverviewContext from '../account-overview/account-overview-context';
import AccountPrefsGeneral from './account-prefs-general';
import AccountPrefsSms from './account-prefs-sms';
import { useFetchAccount } from '../../useFetchAccount';

const AccountPrefs = () => {
  const dispatch = useDispatch();

  const subscription = useSelector(state => getSysadminStateOrganizationById(state));

  const [id, loading] = useFetchAccount(async (orgId) => {
    await dispatch(fetchOrganizationByIdApi(orgId));
  });

  if (loading && subscription.size === 0) {
    return <Loader />;
  }

  return (
    <div>
      <AccountOverviewContext.Provider value={{ id, subscription }}>
        <AccountPrefsGeneral />
        <AccountPrefsSms />
      </AccountOverviewContext.Provider>
    </div>
  );
};

export default AccountPrefs;
