import React, { useMemo } from 'react';
import { createValueFromString } from 'react-rte';

export default function useMarkdownToHtml(markdown) {
  return useMemo(() => {
    return markdown
      ? createValueFromString(markdown, 'markdown').toString('html')
      : null;
  }, [markdown]);
}
