import React, { useEffect } from 'react';
import { Redirect } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { routeParams } from '@State/selectors';
import { getJSONConfigUrl } from '@Utils/navigate';

import {
  getJsonPreferences,
  getTemplatesPreferences,
  getPrivatePreferences,
  getCustomTextsPreferences
} from '@State/preferences-actions';
import { getAdminConfig } from '@State/admin-config-actions';
import AdminConfiguration from '@Components/admin/json-prefs/admin-config-general';
import JSONNav from '@Components/admin/json-prefs/json-subnav';
import AdminConfigPrefs from '@Components/admin/json-prefs/admin-config-prefs';
import AdminConfigTemplates from '@Components/admin/json-prefs/admin-config-templates';
import AdminConfigPrivatePrefs from '@Components/admin/json-prefs/admin-config-private-prefs';
import AdminConfigCustomTexts from '@Components/admin/json-prefs/admin-config-custom-texts';

const Container = styled.div`
  padding: 20px;
  position: relative;
  height: 100%;
`;

const url = '/:org/:loc/:section(admin)/:subsection(json-prefs)/';

const JSONPrefs = (props) => {
  const dispatch = useDispatch();

  const rp = { ...routeParams(props.match.params), ...props.match.params };

  useEffect(() => {
    if (rp.tab === 'general') {
      dispatch(getAdminConfig());
    }
    if (rp.tab === 'prefs') {
      dispatch(getJsonPreferences(true));
    }
    if (rp.tab === 'templates') {
      dispatch(getTemplatesPreferences());
    }
    if (rp.tab === 'private-prefs') {
      dispatch(getPrivatePreferences());
    }
    if (rp.tab === 'custom-texts') {
      dispatch(getCustomTextsPreferences());
    }
  }, [rp.tab]);

  if (!rp.tab) {
    return (<Redirect to={getJSONConfigUrl(rp, 'general')} />);
  }

  return (
    <>
      <JSONNav routeParams={rp} />
      <Container>
        <Switch>
          <Route
            path={`${url}general`}
            component={AdminConfiguration}
          />
          <Route
            path={`${url}prefs`}
            component={AdminConfigPrefs}
          />
          <Route
            path={`${url}templates`}
            component={AdminConfigTemplates}
          />
          <Route
            path={`${url}private-prefs`}
            component={AdminConfigPrivatePrefs}
          />
          <Route
            path={`${url}custom-texts`}
            component={AdminConfigCustomTexts}
          />
        </Switch>
      </Container>
    </>
  );
};

JSONPrefs.propTypes = {
};

export default JSONPrefs;
