import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ModalDialog from '@Components/dialogs/modal-dialog';
import { Button, Flex } from '@Components/ui/styled/main';
import Loader from '@Components/ui/loader';
import { fetchReceiptAccounting, recreateReceiptAccounting } from '@State/pos-actions';
import ProgressButton from '@Components/ui/progress-button';
import AccountingDataSheet from './accounting-data-sheet';

const ReceiptAccountingModal = ({ posOrg, receipt, onClose }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [accounting, setAccounting] = useState(null);
  const { previous, updated } = accounting || {};

  const posOrgId = posOrg.get('id');
  const receiptId = receipt.get('receiptId');

  useEffect(() => {
    setLoading(true);
    dispatch(fetchReceiptAccounting(posOrgId, receiptId))
      .then((data) => {
        setAccounting(data);
        setLoading(false);
      });
  }, [posOrgId]);

  const onRecreateAccounting = () => {
    return dispatch(recreateReceiptAccounting(posOrgId, receiptId))
      .then((data) => {
        setAccounting(data);
      });
  };

  return (
    <ModalDialog
      contentSize="full"
      title="Visa bokföring"
      closeButtonText="Stäng"
      onClose={onClose}
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          {updated && (
            <>
              <h4>Updated</h4>
              {updated.map(({ voucherId, transactionDate, diff, rows }) => {
                return (
                  <div key={voucherId}>
                    <h5>{transactionDate} (diff: {diff})</h5>
                    <AccountingDataSheet rows={rows} />
                    <br />
                  </div>
                );
              })}
              <br />
            </>
          )}
          <h4>{updated ? 'Previous' : 'Current'}</h4>
          {previous?.map(({ voucherId, transactionDate, diff, rows }) => {
            return (
              <div key={voucherId}>
                <h5>{transactionDate} (diff: {diff})</h5>
                <AccountingDataSheet rows={rows} />
                <br />
              </div>
            );
          })}
        </>
      )}
      <br />
      <Flex>
        <Flex>
          {receipt.get('transactionType') === 'Sale' && (
            <ProgressButton
              gray
              small
              disabled={previous?.length > 1}
              onClick={onRecreateAccounting}
            >
              Skapa om bokföring
            </ProgressButton>
          )}
        </Flex>
        <Flex right>
          <Button gray small onClick={onClose}>Stäng</Button>
        </Flex>
      </Flex>
    </ModalDialog>
  );
};

export default ReceiptAccountingModal;
