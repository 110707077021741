import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { getParkedSales, getSaleGrossAmount, getSaleId } from '@State/pos-selectors';
import {
  addDiscount, voidSale, parkSale, unparkSale, removeCustomerFromSale
} from '@State/pos-actions';
import ConfirmActionModal from '@Components/ui/confirm-action-modal';
import EditSaleItemModal from '@Components/pos/sale/edit-sale-item-modal';
import SaleToolbarButtons from '@Components/pos/sale/sale-toolbar-buttons';
import SaleParkingModal from './sale-parking-modal';

class SaleToolbar extends PureComponent {
  state = {
    modal: null
  };

  showModal = (modal) => {
    this.setState({ modal });
  };

  hideModal = () => this.setState({ modal: null });

  addDiscount = (discount) => {
    return this.props.addDiscount(discount)
      .then(this.hideModal);
  }

  parkSale = () => {
    return this.props.parkSale()
      .then(this.hideModal);
  }

  unparkSale = (sale) => {
    if (this.props.hasOpenSale) {
      return this.props.parkSale()
        .then(() => this.props.unparkSale(sale))
        .then(this.hideModal);
    }
    return this.props.unparkSale(sale)
      .then(this.hideModal);
  }

  voidSale = () => {
    return this.props.voidSale()
      .then(this.hideModal);
  }

  removeCustomer = () => {
    return this.props.removeCustomer()
      .then(this.hideModal);
  }

  render() {
    const { modal } = this.state;
    const {
      routeParams, parkedSales, partialPaid, isSaleEmpty, grossAmount
    } = this.props;

    return (
      <>
        <SaleToolbarButtons
          routeParams={routeParams}
          parkedSalesCount={parkedSales.size}
          partialPaid={partialPaid}
          isSaleEmpty={isSaleEmpty}
          showModal={this.showModal}
        />
        {modal === 'addDiscount' && (
          <EditSaleItemModal
            onSave={this.addDiscount}
            onClose={this.hideModal}
            grossAmount={grossAmount}
          />
        )}
        {modal === 'voidSale' && (
          <ConfirmActionModal
            confirmMessage="Vill du rensa kvittot?"
            confirmButtonText="Rensa kvitto"
            onConfirm={this.voidSale}
            onCancel={this.hideModal}
          />
        )}
        {modal === 'removeCustomer' && (
          <ConfirmActionModal
            confirmMessage="Vill du ta bort kunden från kvittot?"
            confirmButtonText="Ta bort"
            onConfirm={this.removeCustomer}
            onCancel={this.hideModal}
          />
        )}
        {modal === 'saleParking' && (
          <SaleParkingModal
            isSaleEmpty={isSaleEmpty}
            parkedSales={parkedSales}
            onPark={this.parkSale}
            onUnpark={this.unparkSale}
            onClose={this.hideModal}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    hasOpenSale: !!getSaleId(state),
    parkedSales: getParkedSales(state),
    grossAmount: getSaleGrossAmount(state)
  };
};

const mapDispatchToProps = dispatch => ({
  addDiscount: discount => dispatch(addDiscount(discount)),
  parkSale: () => dispatch(parkSale()),
  unparkSale: (sale) => dispatch(unparkSale(sale)),
  voidSale: () => dispatch(voidSale()),
  removeCustomer: () => dispatch(removeCustomerFromSale())
});

export default connect(mapStateToProps, mapDispatchToProps)(SaleToolbar);
