import React, {
  useContext, useEffect, useMemo
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { getCustomerGiftCardsUrl } from '@Utils/navigate';
import { fetchCustomerGiftCards } from '@State/customer-actions';
import { sortEventByTimeAndReverse } from '@Utils/customer-util';
import customerIdContext from '@Components/customers/customer-id-context';

import Row from '@Components/ui/styled/row';
import CustomerGiftCardDetails from '@Components/customers/customer-detail/gift-cards/customer-gift-card-details';
import CustomerEmptyTab from '@Components/customers/customer-detail/customer-empty-tab';
import CustomerGiftCardList from '@Components/customers/customer-detail/gift-cards/customer-gift-card-list';
import Loader from '@Components/ui/loader';
import {
  ScrollContent, ScrollWrapper, ColumnRight, ColumnLeft, ContentBlock, TitleBlock
} from '@Components/customers/customer-styles';
import { txt } from '@Utils/i18n-util';
import msg from './customer-gift-cards.msg';

const CustomerGiftCards = ({ routeParams, tabId, onSelectGiftCard }) => {
  const dispatch = useDispatch();
  const id = tabId || routeParams?.tabId;
  const customerId = +useContext(customerIdContext);
  const customerGiftCards = useSelector((state) => state.customerGiftCards);
  const phoneMode = useSelector(state => state.mainViewState.get('phoneMode'));

  const giftCards = customerGiftCards.get('giftCards');
  const loading = customerGiftCards.get('loading');

  const selectedGiftCard = useMemo(() => {
    if (giftCards.length === 0) return null;
    if (id) {
      const itemById = giftCards.find((g) => g.id === +id);
      if (itemById) return (itemById);
    } else {
      const firstValid = sortEventByTimeAndReverse(giftCards.filter(g => g.valid));
      if (firstValid?.length > 0) return (firstValid[0]);

      const firstInvalid = sortEventByTimeAndReverse(giftCards.filter(g => !g.valid));
      if (firstInvalid?.length > 0) return (firstInvalid[0]);
    }
  }, [giftCards, id]);

  useEffect(() => {
    dispatch(fetchCustomerGiftCards(customerId));
  }, [customerId]);

  const handlerOnClick = (item) => {
    onSelectGiftCard(item.id, 'gift-cards');
  };

  if (giftCards && giftCards.length > 0 && routeParams && !routeParams?.tabId && selectedGiftCard) {
    return <Redirect to={getCustomerGiftCardsUrl(routeParams, selectedGiftCard?.id)} />;
  }

  if (loading) {
    return <Loader topPosition />;
  }

  if (giftCards.length === 0) {
    return (
      <CustomerEmptyTab
        title={txt(msg.noGiftCardsTitle)}
        text={txt(msg.noGiftCardsText)}
      />
    );
  }

  const renderGiftCardDetails = () => {
    return (
      <>
        <TitleBlock>{txt(msg.lblDetails)}</TitleBlock>
        <ContentBlock>
          <CustomerGiftCardDetails {...selectedGiftCard} />
        </ContentBlock>
      </>
    );
  };

  const renderGiftCards = () => (
    <CustomerGiftCardList
      isSelectable
      selectedEventId={selectedGiftCard?.id}
      onSelectEvent={handlerOnClick}
      items={giftCards}
    />
  );

  if (phoneMode) {
    return (
      <Row scrollContainer>
        <ScrollWrapper>
          <ScrollContent>
            {renderGiftCards()}
          </ScrollContent>
        </ScrollWrapper>
      </Row>
    );
  }

  return (
    <Row scrollContainer>
      <ColumnLeft>
        <ScrollWrapper>
          <ScrollContent>
            {renderGiftCards()}
          </ScrollContent>
        </ScrollWrapper>
      </ColumnLeft>
      <ColumnRight>
        <ScrollWrapper>
          <ScrollContent>
            {renderGiftCardDetails()}
          </ScrollContent>
        </ScrollWrapper>
      </ColumnRight>
    </Row>
  );
};

export default CustomerGiftCards;
