import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import CustomerDetailOverview from '@Components/customers/customer-detail/overview/customer-detail-overview';
import CustomerBookings from '@Components/customers/customer-detail/bookings/customer-bookings';
import CustomerDetailLogs from '@Components/customers/customer-detail/logs/customer-detail-logs';
import CustomerDetailSales from '@Components/customers/customer-detail/sales/customer-detail-sales';
import CustomerGiftCards from '@Components/customers/customer-detail/gift-cards/customer-gift-cards';
import { openCustomerTab } from '@State/cf-actions';

const CustomerModalBody = ({
  isLoading, customerId, selectedCustomer, selectedTab, tabId, routeParams
}) => {
  const dispatch = useDispatch();
  const onSelectTabId = (tabId, tab) => {
    dispatch(openCustomerTab({ tab, tabId }));
  };
  const renderBody = (tab) => {
    switch (tab) {
      case 'bookings':
        return <CustomerBookings tabId={tabId} onSelectBooking={onSelectTabId} />;

      case 'logs':
        return <CustomerDetailLogs tabId={tabId} />;

      case 'sales':
        return <CustomerDetailSales tabId={tabId} onSelectSales={onSelectTabId} />;

      case 'gift-cards':
        return <CustomerGiftCards tabId={tabId} onSelectGiftCard={onSelectTabId} />;

      default:
        return (
          <CustomerDetailOverview
            modalView
            routeParams={routeParams}
            isLoadingCustomer={isLoading}
            customerId={customerId}
            selectedCustomer={selectedCustomer}
            tabId={tabId}
          />
        );
    }
  };
  return (
    <div className="tab-content">
      <div className="tab-pane active" id={`#${selectedTab}`} key={selectedTab}>
        {renderBody(selectedTab)}
      </div>
    </div>
  );
};

CustomerModalBody.propTypes = {
  selectedIndex: PropTypes.number,
  isModal: PropTypes.bool,
  component: PropTypes.node,
  styleBody: PropTypes.object
};

export default memo(CustomerModalBody);
