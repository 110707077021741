import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { toggleResourcesSortable, toggleResourcesCollapsed } from '@State/admin-view-reducer';
import { getChangeJsonPrefsPermission, getFeatures, getPermissions } from '@State/selectors';
import { hasPermission, CHANGE_RESOURCES } from '@Utils/permissions';
import { getResourceConfigUrl, navigate } from '@Utils/navigate';
import CollapseToolbarButtons from '../sortable-list/collapse-toolbar-buttons';

class ResourceListTools extends Component {
  static propTypes = {
    permissions: PropTypes.array.isRequired,
    features: PropTypes.object.isRequired,
    onToggleSorting: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="columns-header-tools">
        {this.renderSortButton()}
        {this.renderCollapseExpandButton()}
      </div>
    );
  }

  renderCollapseExpandButton() {
    return (
      <CollapseToolbarButtons
        onExpandAll={() => this.props.onToggleCollapse([])}
        onCollapseAll={() => this.props.onToggleCollapse(this.props.groupIds)}
      />
    );
  }

  renderSortButton() {
    const { permissions, features } = this.props;
    const canChangeResource = hasPermission(permissions, CHANGE_RESOURCES);
    const { ShowEditGroupResource } = features;

    if (!canChangeResource || !ShowEditGroupResource) {
      return null;
    }

    return (
      <button type="button" className="btn btn-header-toolbar" onClick={this.handleToggleSortable}>
        <i className="fa fa-sort-amount-down" />
      </button>
    );
  }

  handleToggleSortable = () => {
    this.props.onToggleSorting(!this.props.sortable);
  };
}

const mapStateToProps = (state, ownProps) => {
  const resourceId = parseInt(ownProps.id);
  const { orderedGroups, adminViewState, resourcesById } = state;
  const resource = resourcesById && resourceId && resourcesById.get(resourceId);

  const filteredResources = ownProps.filter
    ? resourcesById.filter(srv => {
      return (
        srv.name.toLowerCase().indexOf(ownProps.filter.toLowerCase()) !== -1
      );
    })
    : resourcesById;

  const filteredGroups = ownProps.filter
    ? orderedGroups.filter(group => {
      let match = false;
      group.get('resourceIds').forEach(srvId => {
        if (filteredResources.has(srvId)) {
          match = true;
        }
      });
      return match;
    })
    : orderedGroups;

  const groupIds = filteredGroups.map(item => item.get('id')).toJS();

  return {
    groupIds,
    resourcesById,
    sortable: adminViewState.get('resourcesSortable'),
    collapsedIds: adminViewState.get('resourcesCollapsedIds'),
    orderedGroups,
    permissions: getPermissions(state, ownProps),
    features: getFeatures(state),
    canChangeJsonPrefs: getChangeJsonPrefsPermission(state, ownProps),
    resourceId,
    resource
  };
};

const mapDispatchToProps = (dispatch, { routeParams, id }) => {
  return {
    onToggleCollapse: (collapsedIds) => {
      return dispatch(toggleResourcesCollapsed(collapsedIds));
    },
    onToggleSorting: (sortable) => {
      return dispatch(toggleResourcesSortable(sortable));
    },
    onDuplicateResource: (resourceId, name) => {
      return dispatch(duplicateResource(resourceId, name)).then(res => navigate(getResourceConfigUrl(routeParams, res.id)));
    },
    doCopy: ({ isCallCopyResource, id: idTarget, ...data }) => {
      if (isCallCopyResource) {
        return dispatch(saveCopyToResource(id, idTarget, data));
      }
      return dispatch(saveCopyToGroup(id, idTarget, data));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResourceListTools);
