import React from 'react';
import { pos } from '@Utils/preference-keys';
import { OtherPaymentMethods } from '@Utils/pos-utils';
import PosOrgConfigToggle from './pos-org-config-toggle';
import PosOrgPaymentGiftCard from './pos-org-payment-gift-card';
import PosOrgPaymentOther from './pos-org-payment-other';
import PosOrgPaymentInvoice from './pos-org-payment-invoice';
import PosOrgPaymentSwish from './pos-org-payment-swish';
import PosOrgPaymentKlarna from './pos-org-payment-klarna';

const PosOrgPayment = ({ posOrg }) => {
  return (
    <>
      {/* <PosOrgConfigToggle
        posOrg={posOrg}
        preference={pos.paymentTipEnabled}
        label="Dricks"
        description="Visa alternativ för att lägga till dricks vid betalning"
      /> */}
      <PosOrgConfigToggle
        posOrg={posOrg}
        preference={pos.paymentMethodCard}
        label="Kort"
        description="Kortbetalning med integrerad eller extern kortterminal"
      />
      <PosOrgConfigToggle
        posOrg={posOrg}
        preference={pos.paymentMethodCash}
        label="Kontant"
        description="Visa kontant betalning som betalsätt i kassan"
      />
      <PosOrgPaymentSwish posOrg={posOrg} />
      <PosOrgPaymentKlarna posOrg={posOrg} />
      {posOrg.getIn(['prefs', pos.invoiceUseFortnox]) ? (
        <PosOrgConfigToggle
          posOrg={posOrg}
          preference={pos.paymentMethodFortnoxInvoice}
          label="Faktura"
          description="Visa faktura via Fortnox som betalsätt i kassan"
        />
      ) : (
        <PosOrgPaymentInvoice posOrg={posOrg} />
      )}
      <PosOrgPaymentGiftCard posOrg={posOrg} />
      {OtherPaymentMethods.map((number) => {
        const isThisActive = posOrg.getIn(['prefs', pos.paymentMethodOther + number]);
        const isPrevActive = posOrg.getIn(['prefs', pos.paymentMethodOther + (number - 1)]);

        return isThisActive || isPrevActive || number === 1
          ? <PosOrgPaymentOther key={number} posOrg={posOrg} number={number} />
          : null;
      })}
    </>
  );
};

export default PosOrgPayment;
