import React, { useEffect, useState } from 'react';
import { change, Field, reduxForm } from 'redux-form';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import ModalDialog from '@Components/dialogs/modal-dialog';
import FormButtonsFooter from '@Components/ui/form-buttons-footer';
import TextInput from '@Components/inputs/text-input';
import DateRangePicker from '../date-range-picker';
import { getStaffReportDefaultLabel } from '../reports-helpers';

const dateString = date => date.format('YYYY-MM-DD');

const CreateStaffReportModal = ({ form, onClose, handleSubmit, submitting }) => {
  const dispatch = useDispatch();
  const setValue = (prop, value) => dispatch(change(form, prop, value));

  const users = useSelector(({ staffState }) => staffState.filter(s => {
    return !s.get('deleted');
  }));

  const [labelChanged, setLabelChanged] = useState(false);
  const [startDate, setStartDate] = useState(moment().subtract(1, 'M').startOf('M'));
  const [endDate, setEndDate] = useState(moment().subtract(1, 'M').endOf('M'));

  const allUserIds = users?.map(u => u.get('id'));
  const [userIds, setUserIds] = useState([]);

  useEffect(() => {
    setValue('label', getStaffReportDefaultLabel(startDate));
    setValue('startDate', dateString(startDate));
    setValue('endDate', dateString(endDate));
    setValue('locUserIds', userIds);
  }, []);

  const handleLabelChange = (val) => {
    setLabelChanged(true);
  };

  const handleDatesChange = ({ startDate, endDate }) => {
    handleStartDate(startDate);
    handleEndDate(endDate);
  };

  const handleStartDate = (date) => {
    const newStartDate = moment(date);
    setValue('startDate', dateString(newStartDate));
    setStartDate(newStartDate);

    if (!labelChanged) {
      setValue('label', getStaffReportDefaultLabel(newStartDate));
    }
  };

  const handleEndDate = (date) => {
    const newEndDate = moment(date);
    setValue('endDate', dateString(newEndDate));
    setEndDate(newEndDate);
  };

  const updateUserIds = (ids) => {
    setUserIds(ids);
    setValue('locUserIds', ids);
  };

  const handleUserId = (ev, userId) => {
    if (ev.target.checked) {
      updateUserIds([...userIds, userId]);
    } else {
      updateUserIds(userIds.filter(id => id !== userId));
    }
  };

  const selectAllUserIds = (ev) => {
    ev.preventDefault();
    ev.target.blur();
    updateUserIds(allUserIds);
  };

  return (
    <ModalDialog
      title="Skapa löneunderlag"
      hideCloseButton
      contentSize="medium"
      closeOnClickOutside={false}
      onClose={onClose}
    >
      <form autoComplete="off" className="dialog" onSubmit={handleSubmit}>
        <Field
          type="text"
          name="label"
          label="Rubrik"
          placeholder="Rubrik på löneunderlaget"
          component={TextInput}
          onChange={handleLabelChange}
        />
        <div className="form-group">
          <label className="control-label">Datumintervall</label>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onDatesChange={handleDatesChange}
          />
        </div>
        <div className="form-group">
          <label className="control-label">Välj personal</label>{' '}&nbsp;
          <a href="#" onClick={selectAllUserIds}>Välj alla</a>
          <div className="row">
            {users.map((user) => {
              const userId = user.get('id');
              const selected = userIds.includes(userId);
              return (
                <div key={userId} className="col-xs-6">
                  <div className="checkbox mb0">
                    <label>
                      <input type="checkbox" checked={selected} onChange={ev => handleUserId(ev, userId)} />
                      {user.get('name')}
                    </label>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <FormButtonsFooter
          alwaysEnabled
          onClose={onClose}
          submitting={submitting}
          handleSubmit={handleSubmit}
          submitText="Skapa löneunderlag"
        />
      </form>
    </ModalDialog>
  );
};

export default reduxForm({
  form: 'create-staff-report-modal'
})(CreateStaffReportModal);
