function setSessionValue(name, value) {
  if (value) {
    sessionStorage.setItem(name, value);
  } else {
    sessionStorage.removeItem(name);
  }
}

export function getSessionOrgLoc() {
  return {
    org: sessionStorage.getItem('org'),
    loc: sessionStorage.getItem('loc')
  };
}

export function setSessionOrgLoc(org, loc) {
  setSessionValue('org', org);
  setSessionValue('loc', loc);
}

export function getSessionPosUnit() {
  return sessionStorage.getItem('pos');
}

export function setSessionPosUnit(posUnit) {
  setSessionValue('pos', posUnit);
}

export function getSessionOpToken() {
  return sessionStorage.getItem('opToken');
}

export function setSessionOpToken(opToken) {
  setSessionValue('opToken', opToken);
}

export function getLastVisitedPage() {
  return sessionStorage.getItem('lastPage');
}

export function setLastVisitedPage(path) {
  setSessionValue('lastPage', path);
}
