import axios from 'axios';
import moment from 'moment';
import {
  axiosDefault,
  axiosErrorHandler,
  checkStatusAxios,
  prefixUrl
} from '@Utils/ajax-util';

export const STOCK_TAKES_FETCHED = 'STOCK_TAKES_FETCHED';
export const STOCK_TAKE_FETCHED = 'STOCK_TAKE_FETCHED';
export const STOCK_TAKE_CREATED = 'STOCK_TAKE_CREATED';
export const STOCK_TAKE_UPDATED = 'STOCK_TAKE_UPDATED';
export const STOCK_TAKE_DELETED = 'STOCK_TAKE_DELETED';
export const STOCK_TAKE_FINISHED = 'STOCK_TAKE_FINISHED';
export const STOCK_TAKE_ITEMS_TOGGLE = 'STOCK_TAKE_ITEMS_TOGGLE';
export const STOCK_TAKE_ITEM_QUANTITY = 'STOCK_TAKE_ITEM_QUANTITY';

export function fetchStockTakes() {
  return dispatch => {
    const url = prefixUrl('/inventory/stocktake/');
    const config = axiosDefault();

    return axios
      .get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then(({ items }) => dispatch({ type: STOCK_TAKES_FETCHED, items }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function fetchStockTake(id) {
  return dispatch => {
    const url = prefixUrl(`/inventory/stocktake/${id}`);
    const config = axiosDefault();

    return axios
      .get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then(stockTake => dispatch({ type: STOCK_TAKE_FETCHED, stockTake }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function createStockTake(stockTake) {
  return dispatch => {
    const url = prefixUrl('/inventory/stocktake/create');
    const config = axiosDefault();

    return axios
      .post(url, stockTake, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then(({ id }) => {
        dispatch({ type: STOCK_TAKE_CREATED, stockTake: { ...stockTake, id } });
        return id;
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function deleteStockTake(stockTakeId) {
  return dispatch => {
    const url = prefixUrl(`/inventory/stocktake/${stockTakeId}`);
    const config = axiosDefault();

    return axios
      .delete(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then(() => dispatch({ type: STOCK_TAKE_DELETED, stockTakeId }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function toggleStockTakeItems(stockTakeId, ids, include) {
  return dispatch => {
    const flag = include ? 'include' : 'exclude';
    const url = prefixUrl(`/inventory/stocktake/${stockTakeId}/items/${flag}`);
    const config = axiosDefault();

    return axios
      .put(url, { ids }, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then(() => dispatch({ type: STOCK_TAKE_ITEMS_TOGGLE, stockTakeId, ids, include }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function setStockTakeItemQuantity(stockTakeId, itemId, quantity) {
  return dispatch => {
    const url = prefixUrl(`/inventory/stocktake/${stockTakeId}/item/${itemId}/quantity`);
    const config = axiosDefault();

    return axios
      .put(url, { quantity }, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then(() => dispatch({ type: STOCK_TAKE_ITEM_QUANTITY, stockTakeId, itemId, quantity }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function setStockTakeFinished(stockTakeId, comment = null) {
  return dispatch => {
    const url = prefixUrl(`/inventory/stocktake/${stockTakeId}/mark-as-finished`);
    const config = axiosDefault();

    return axios
      .post(url, { comment }, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then(() => dispatch({ type: STOCK_TAKE_FINISHED, stockTakeId, finishedTs: moment().toISOString() }))
      .catch(error => {
        axiosErrorHandler(error, dispatch);
        throw error;
      });
  };
}
