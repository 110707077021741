import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePickerCalendarInput from '@Components/ui/date-picker/date-picker-calendar-input';
import { classes } from '../utils';

const DatePickerField = ({
  input: { name, value, onChange }, label, helpText, disabled, meta: { touched, error }, className
}) => {
  const classNames = classes({
    'form-group': true,
    'has-error': touched && error,
    [className]: className
  });

  const [date, setDate] = useState(value ? moment(value, 'L') : null);

  useEffect(() => {
    if (value !== date?.format('L')) {
      setDate(value ? moment(value, 'L') : null);
    }
  }, [value]);

  const handleChangeDate = (date) => {
    const newDate = date ? moment(date) : null;
    onChange(newDate?.format('L'));
    setDate(newDate);
  };

  return (
    <div className={classNames}>
      {label && <label className="control-label" htmlFor={name}>{label}</label>}
      <DatePickerCalendarInput
        name={name}
        date={date}
        onChange={handleChangeDate}
        disabled={disabled}
      />
      {touched && error && <span className="help-block">{error}</span>}
      {helpText && <span className="form-control-description">{helpText}</span>}
    </div>
  );
};

export default DatePickerField;
