import styled, { css } from 'styled-components';

const commonCell = css`
  :first-child{
    padding-left: 0;
  }
  padding-left: 10px;
  text-align: ${p => p?.right ? 'end' : 'left'};
  ${p => p?.paddingLeft && css`
    padding-left: ${p.paddingLeft};
  `};
  ${p => p?.width && css`
    width: ${p.width};
  `};
`;

export default styled.table`
  width: 100%;
  table-layout: fixed;
  cursor: ${p => p.onClick ? 'pointer' : 'default'};
`;

export const TableRow = styled.tr`
  cursor: ${p => p.onClick ? 'pointer' : 'default'};
`;
export const TableBody = styled.tbody`

`;
export const TableHead = styled.thead`
`;

export const TableTh = styled.th`
  ${commonCell};
`;
export const TableTd = styled.td`
  ${commonCell};
`;
