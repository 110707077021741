import React from 'react';
import { useSelector } from 'react-redux';
import { getSysAdmin } from '@State/selectors';
import { Row, Flex } from '@Components/ui/styled/main';
import AdminConfigInfo from '@Components/admin/json-prefs/admin-config-info';
import AdminConfigI18n from '@Components/admin/json-prefs/admin-config-i18n';
import AdminConfigFeatures from '@Components/admin/json-prefs/admin-config-features';
import { GrayText, SwitchStyle } from '@Components/admin/json-prefs/admin-config-style';
import Loader from '@Components/ui/loader';

const AdminConfigGeneral = () => {
  const isSysAdmin = useSelector(getSysAdmin);
  const adminConfig = useSelector(state => state.adminConfigState);

  if (adminConfig.isEmpty()) {
    return <Loader />;
  }
  const {
    location, supportedTimeZones, supportedLocales, supportedRegions, supportedCurrencies
  } = adminConfig.toJS();

  return (
    <>
      <Row>
        <Flex>
          <GrayText>
            <AdminConfigInfo {...location} />
          </GrayText>
          {isSysAdmin && (
            <GrayText>
              <AdminConfigI18n
                supportedTimeZones={supportedTimeZones}
                supportedLocales={supportedLocales}
                supportedRegions={supportedRegions}
                supportedCurrencies={supportedCurrencies}
              />
            </GrayText>
          )}
        </Flex>
      </Row>
      {isSysAdmin && (
        <SwitchStyle>
          <AdminConfigFeatures />
        </SwitchStyle>
      )}
    </>
  );
};

export default AdminConfigGeneral;
