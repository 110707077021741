import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ChangePassword from '@Components/user-config/change-password';
import Notifications from '@Components/user-config/notifications';
import {
  fetchPasswordPolicy, changePassword, fetchUserNotifications,
  setUserNotifications
} from '@State/user-actions';
import { notification } from '@Utils/preference-keys';

class UserConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowingChangePasswordForm: false,
      notifications: null
    };
  }

  componentDidMount() {
    this.props.fetchPasswordPolicy();
    this.props.fetchUserNotifications(this.props.loginId)
      .then(notifications => this.setState({ notifications }));
  }

  handleChangeShowForm = (isShowForm = false) => {
    this.setState({ isShowingChangePasswordForm: isShowForm });
  }

  onChangePassword = (request) => {
    this.props.changePassword(request);
  };

  onChangeNotification = (key, value) => {
    const notifications = {
      ...this.state.notifications,
      [key]: value
    };
    this.props.setUserNotifications(this.props.loginId, notifications);
    this.setState({ notifications });
  };

  render() {
    const { userConfigViewState, username } = this.props;
    const { isShowingChangePasswordForm, notifications } = this.state;
    const {
      passwordPolicy, wrongPassword, changePasswordSuccess, isChangingPassword
    } = userConfigViewState.toJS();

    return (
      <div className="section-content">
        <div className="columns-container">
          <div className="columns-content">
            <div className="columns-content-container width-medium">
              <div className="columns-content-body with-padding">
                <Notifications
                  title="Mejlnotiser"
                  username={username}
                  keys={Object.values(notification.email)}
                  prefs={notifications}
                  onChange={this.onChangeNotification}
                />
                <Notifications
                  title="Pushnotiser"
                  keys={Object.values(notification.push)}
                  prefs={notifications}
                  onChange={this.onChangeNotification}
                />
                <ChangePassword
                  showForm={isShowingChangePasswordForm}
                  onChangeShowForm={this.handleChangeShowForm}
                  passwordPolicy={passwordPolicy}
                  wrongPassword={wrongPassword}
                  changePasswordSuccess={changePasswordSuccess}
                  isChangingPassword={isChangingPassword}
                  onChangePassword={this.onChangePassword}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

UserConfig.propTypes = {
  username: PropTypes.string.isRequired,
  userConfigViewState: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  const { authState, userConfigViewState } = state;
  return {
    loginId: authState.get('loginId'),
    username: authState.get('username'),
    userConfigViewState
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPasswordPolicy: () => dispatch(fetchPasswordPolicy()),
    fetchUserNotifications: (loginId) => dispatch(fetchUserNotifications(loginId)),
    changePassword: (request) => dispatch(changePassword(request)),
    setUserNotifications: (userId, notifications) => {
      dispatch(setUserNotifications(userId, notifications));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserConfig);
