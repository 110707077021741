import React, { PureComponent } from 'react';
import ProgressButton from '@Components/ui/progress-button';
import { getSysAdmin } from '@State/selectors';
import { connect } from 'react-redux';
import { fetchTerminalRefData, refreshTerminals } from '@State/pos-config-actions';
import Panel from '@Components/ui/styled/panel';
import TerminalPanel from './terminal-panel';

class Terminals extends PureComponent {
  componentDidMount() {
    this.props.fetchTerminalRefData();
  }

  render() {
    const { allTerminals, refreshTerminals, isSysAdmin } = this.props;

    return (
      <div className="columns-container">
        <div className="columns-content">
          <div className="columns-content-container width-large">
            <div className="columns-content-body with-padding">
              {allTerminals && allTerminals.map(terminal => (
                <TerminalPanel key={terminal.get('id')} terminal={terminal} />
              ))}
              {allTerminals && allTerminals.isEmpty() && (
                <Panel>
                  Det finns inga terminaler för kontot
                </Panel>
              )}
              {isSysAdmin && (
                <div>
                  <ProgressButton small primary onClick={refreshTerminals}>Hämta terminaler från Nets</ProgressButton>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { allTerminals } = state;

  return {
    allTerminals,
    isSysAdmin: getSysAdmin(state)
  };
};

const mapDispatchToProps = dispatch => ({
  fetchTerminalRefData: () => dispatch(fetchTerminalRefData()),
  refreshTerminals: () => dispatch(refreshTerminals())
});

export default connect(mapStateToProps, mapDispatchToProps)(Terminals);
