import React from 'react';
import PropTypes from 'prop-types';
import ResourceServiceItem from './resource-service-item';

const styleWidthFifty = { width: '50%' };

export default class ResourceServiceGroup extends React.Component {
  static propTypes = {
    groupName: PropTypes.string.isRequired,
    services: PropTypes.object.isRequired,
    serviceMappings: PropTypes.object.isRequired
  };

  render() {
    const {
      groupName, services, resourceId, serviceMappings
    } = this.props;

    return (
      <div className="resource-service-group">
        <h4>{groupName}</h4>
        {/* <a onClick={this.toggleFilter}>Välj alla</a> */}
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col" style={{ width: 1 }} />
              <th scope="col" style={{ width: '55%' }} />
              <th scope="col" style={{ width: '25%' }} />
              <th scope="col" style={{ width: '20%' }} />
            </tr>
          </thead>
          <tbody>
            {services.map((service) => {
              const mappingId = `${resourceId}:${service.id}`;
              const serviceMapping = serviceMappings.get(mappingId);
              const customValues = serviceMapping && serviceMapping.toJS();

              return (
                <ResourceServiceItem
                  key={service.id}
                  active={!!serviceMapping}
                  selectService={this.props.selectService}
                  setCustomValues={this.props.setCustomValues}
                  {...service}
                  {...customValues}
                />
              );
            })}
          </tbody>
          <tfoot>
            <tr>

              <td colSpan="4">
                <span className="pull-right">
                  <i className="fa fa-exclamation-circle text-info" /> = Egna värden inställda för tjänsten
                </span>
              </td>

            </tr>
          </tfoot>
        </table>
      </div>
    );
  }
}
