import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import { txt } from '@Utils/i18n-util';

import useValueChanged from '@Hooks/useValueChanged';
import HookFormInput from '@Components/inputs/hook-form-input';
import FormButtonsFooter from '@Components/ui/form-buttons-footer';
import { fields } from '@Components/sysadmin/new-account/new-account';
import LabelSwitchInput from '@Components/ui/label-switch-input';

import msg from './new-account.msg';

const NewAccountForm = ({ title, onClose, onSubmit, initialValues, loading, fieldsKey, defaultAddress, pricePlans = [] }) => {
  const methods = useForm({ defaultValues: initialValues });
  const pricePlan = methods.watch('pricePlan');

  useValueChanged(pricePlan, () => {
    const plan = pricePlans.find(plan => plan.planKey === pricePlan);
    if (plan) {
      methods.reset({ ...plan.pricing, pricePlan });
    }
  });

  const planOptions = useMemo(() => pricePlans.map(({ planKey, planName }) => ({
    value: planKey,
    title: planName
  })), [pricePlans]);

  return (
    <FormProvider {...methods}>
      <h4 className="new-account-form-header">{title}</h4>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {fields[fieldsKey] && fields[fieldsKey]
          .map(({
            name,
            label,
            type,
            options,
            unit,
            rules = {},
            ...rest
          }) => name === 'companyAddress' || name === 'posAddress' || name === 'invoiceAddress'
            ? (
              <AddressForm
                key={name}
                isCompanyAddress={name === 'companyAddress'}
                defaultAddress={defaultAddress}
                initialValues={initialValues}
              />
            )
            : (
              <HookFormInput
                key={name}
                label={msg[label] ? txt(msg[label]) : label}
                id={name}
                initialValue={initialValues[name]}
                type={type}
                unitLabel={unit}
                options={name === 'pricePlan' ? planOptions : options?.map(opt => ({
                  ...opt,
                  title: msg[opt.title] ? txt(msg[opt.title]) : opt.title
                }))}
                {...rest}
                registerOptions={{ valueAsNumber: type === 'number', ...rules }}
              />
            ))}
        <FormButtonsFooter
          onClose={onClose}
          handleSubmit={methods.handleSubmit}
          submitSucceeded={false}
          submitting={loading}
        />
      </form>
    </FormProvider>
  );
};

const AddressForm = ({ submitting, isCompanyAddress, defaultAddress = {}, initialValues = {} }) => {
  const { setValue } = useFormContext();
  const [useSame, setUseSame] = useState(false);

  const handleCheck = useCallback(() => {
    const isSame = !useSame;
    ['addressLine1', 'postCode', 'area'].forEach((key) => {
      setValue(key, isSame ? defaultAddress[key] : initialValues[key]);
    });
    setValue('useSameAddress', isSame);
    setUseSame(isSame);
  }, [initialValues, defaultAddress, useSame]);

  useEffect(() => {
    if (!isCompanyAddress && initialValues.useSameAddress === undefined) handleCheck();
  }, []);

  return (
    <>
      {!isCompanyAddress && (
        <div>
          <div className="form-group">
            <LabelSwitchInput
              noBorder
              label="Använd företagets adress"
              id="useSameAddress"
              isOn={useSame}
              handleToggle={handleCheck}
            />
          </div>
        </div>
      )}
      <HookFormInput
        key="addressLine1"
        label="Gatuadress"
        id="addressLine1"
        initialValue={initialValues.addressLine1 ?? defaultAddress.addressLine1}
        type="text"
        registerOptions={{ required: true }}
        disabled={submitting || useSame}
      />
      <div className="new-account-form-address-row">
        <HookFormInput
          key="postCode"
          label="Postnummer"
          id="postCode"
          className="postCode"
          initialValue={initialValues.postCode ?? defaultAddress.postCode}
          type="text"
          disabled={submitting || useSame}
          registerOptions={{ required: true }}
        />
        <HookFormInput
          key="area"
          label="Postort"
          id="area"
          className="area"
          initialValue={initialValues.area ?? defaultAddress.area}
          type="text"
          disabled={submitting || useSame}
          registerOptions={{ required: true }}
        />
      </div>
    </>
  );
};

export default NewAccountForm;
