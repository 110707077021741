import { defineMessages } from 'react-intl';

export default defineMessages({
  bookingRef: {
    id: 'calendar.bkf.details.bookingRef',
    defaultMessage: 'Booking ref'
  },
  bookedAs: {
    id: 'calendar.bkf.details.bookedAs',
    defaultMessage: 'Booked as'
  },
  created: {
    id: 'calendar.bkf.details.created',
    defaultMessage: 'Created'
  },
  updated: {
    id: 'calendar.bkf.details.updated',
    defaultMessage: 'Updated'
  },
  cancelled: {
    id: 'calendar.bkf.details.cancelled',
    defaultMessage: 'Cancelled'
  },
  paid: {
    id: 'calendar.bkf.details.paid',
    defaultMessage: 'Paid'
  },
  refunded: {
    id: 'calendar.bkf.details.refunded',
    defaultMessage: 'Refunded'
  },
  reviewRequestSent: {
    id: 'calendar.bkf.details.reviewRequestSent',
    defaultMessage: 'Review request sent'
  },
  reviewPosted: {
    id: 'calendar.bkf.details.reviewPosted',
    defaultMessage: 'Review posted'
  },
  viaWebText: {
    id: 'calendar.bkf.details.viaWebText',
    defaultMessage: 'via web'
  },
  viaWebVacci: {
    id: 'calendar.bkf.details.viaWebVacci',
    defaultMessage: 'via Vacci'
  },
  viaDefault: {
    id: 'calendar.bkf.details.viaDefault',
    defaultMessage: 'via Cliento Calendar'
  },
  cancelledViaWeb: {
    id: 'calendar.bkf.details.cancelledViaWeb',
    defaultMessage: 'by customer'
  },
  cancelledViaCal: {
    id: 'calendar.bkf.details.cancelledViaCal',
    defaultMessage: 'by Cliento user'
  },
  smsStatusSendAt: {
    id: 'calendar.bkf.details.smsStatusSendAt',
    defaultMessage: 'send at'
  },
  smsStatusSentAt: {
    id: 'calendar.bkf.details.smsStatusSentAt',
    defaultMessage: 'sent at'
  },
  smsStatusDeliveredAt: {
    id: 'calendar.bkf.details.smsStatusDeliveredAt',
    defaultMessage: 'delivered at'
  },
  smsStatusNotSent: {
    id: 'calendar.bkf.details.smsStatusNotSent',
    defaultMessage: 'not sent'
  },
  smsStatusDefault: {
    id: 'calendar.bkf.details.smsStatusDefault',
    defaultMessage: 'failed delivery'
  },
  smsDisabledCustomer: {
    id: 'calendar.bkf.details.smsDisabledCustomer',
    defaultMessage: 'disabled for customer'
  },
  smsDisabledResource: {
    id: 'calendar.bkf.details.smsDisabledResource',
    defaultMessage: 'disabled for resource'
  }
});
