import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { sms, email } from '@Utils/preference-keys';
import PhoneUtil from '@Utils/phone-util';
import { getResourceFromColIdx } from '@State/calendar-selectors';
import { getSmsEnabled } from '@State/selectors';
import { txt } from '@Utils/i18n-util';
import msg from './confirm-move.msg';

class ConfirmMove extends React.Component {
  static propTypes = {

    startTime: PropTypes.object.isRequired,
    customerEmail: PropTypes.string,
    customerPhoneNumber: PropTypes.string,
    copyOnPaste: PropTypes.bool,

    enableSmsConfirmations: PropTypes.bool.isRequired,
    enableEmailConfirmations: PropTypes.bool.isRequired,
    defaultOptionSmsConfirm: PropTypes.bool.isRequired,
    defaultOptionEmailConfirm: PropTypes.bool.isRequired,

    onClosePopover: PropTypes.func.isRequired,
    onConfirmMove: PropTypes.func.isRequired

  };

  constructor(props) {
    super(props);
    this.state = {
      sendSmsConfirmation: props.customerPhoneNumber && props.smsEnabled && props.defaultOptionSmsConfirm,
      sendEmailConfirmation: props.customerEmail && props.defaultOptionEmailConfirm
    };
  }

  handleSmsConfirmChange = (ev) => {
    this.setState({ sendSmsConfirmation: ev.target.checked });
  };

  handleEmailConfirmationChange = (ev) => {
    this.setState({ sendEmailConfirmation: ev.target.checked });
  };

  handleConfirmMove = (ev) => {
    ev.preventDefault();
    this.props.onConfirmMove(this.state);
  };

  render() {
    // These comes from ownProps
    const { startTime, customerEmail, customerPhoneNumber, columnResource, booking } = this.props;
    const { sendSmsConfirmation, sendEmailConfirmation } = this.state;

    const primaryResource = booking.resources.find(r => r.primary);
    const resource = columnResource?.id === primaryResource?.id ? null : columnResource?.name;
    const time = booking.startTime.isSame(startTime) ? null : startTime.format('LLLL');

    const destination = [resource, time].filter(d => d).join(', ');
    const promptText = this.props.copyOnPaste
      ? txt(msg.txtConfirmCopy, { destination })
      : txt(msg.txtConfirmMove, { destination });

    return (
      <div className="Popover-content">
        <div className="booking-form-block nopointer">
          <p>
            <strong>
              {promptText}
            </strong>
          </p>

          {this.shouldRenderSms() && (
            <div className="checkbox">
              <label>
                <input type="checkbox" checked={sendSmsConfirmation} onChange={this.handleSmsConfirmChange} />
                {txt(msg.lblSendSMS, { phoneNumber: PhoneUtil.formatPhoneNumber(customerPhoneNumber) })}
              </label>
            </div>
          )}

          {this.shouldRenderEmail() && (
            <div className="checkbox">
              <label>
                <input type="checkbox" checked={sendEmailConfirmation} onChange={this.handleEmailConfirmationChange} />
                {txt(msg.lblSendEmail, { email: customerEmail })}
              </label>
            </div>
          )}
        </div>

        <div className="text-right">
          <button type="button" className="btn btn-default" onClick={this.props.onClosePopover}>{txt(msg.btnCancel)}</button> &nbsp;
          <button type="button" className="btn-save" onClick={this.handleConfirmMove}>{this.props.copyOnPaste ? txt(msg.btnCopy) : txt(msg.btnMove)}</button>
        </div>
      </div>
    );
  }

  shouldRenderSms() {
    return this.props.customerPhoneNumber && this.props.enableSmsConfirmations;
  }

  shouldRenderEmail() {
    return this.props.customerEmail && this.props.enableEmailConfirmations;
  }
}

const mapStateToProps = (state, ownProps) => {
  const { locationConfig, locationFeatures, bookingsById } = state;
  const booking = bookingsById.get(ownProps.bookingId);
  const smsEnabled = getSmsEnabled(state);

  return {
    booking,
    smsEnabled,
    enableEmailConfirmations: locationFeatures.get('EnableEmailConfirmations'),
    enableSmsConfirmations: smsEnabled && locationFeatures.get('EnableSmsConfirmations'),
    defaultOptionSmsConfirm: locationConfig.get(sms.defaultOptionSmsConfirm),
    defaultOptionEmailConfirm: locationConfig.get(email.defaultOptionEmailConfirm),
    columnResource: getResourceFromColIdx(state, ownProps)
  };
};

export default connect(mapStateToProps)(ConfirmMove);
