import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { getSysAdminLabelProps } from '@Utils/sysadmin-util';
import AccountsListItemSubHeader from '@Components/sysadmin/accounts/accounts-list/accounts-list-item-sub-header';
import ColumnListItem from '@Components/ui/column/column-list-item';

const AccountsListItem = ({
  onSelectItem, routeParamsTabId, item, isSelected
}) => {
  const {
    id, name, staffEmails = [], ...itemProps
  } = item ? item?.toJS() : {};

  const onClickByListItem = () => onSelectItem(id, routeParamsTabId);
  const labelData = getSysAdminLabelProps({ ...itemProps });

  return (
    <ColumnListItem
      onClick={onClickByListItem}
      selected={isSelected}
      header={name}
      subHeader={(
        <AccountsListItemSubHeader
          {...labelData}
          {...itemProps}
        />
      )}
      subText={staffEmails[0] ?? ''}
      subTextRight={null}
    />
  );
};

AccountsListItem.propTypes = {
  item: PropTypes.object,
  isSelected: PropTypes.bool,
  onSelectItem: PropTypes.func
};

export default memo(AccountsListItem);
