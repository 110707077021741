import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { getTotal, reportsQuerySelector } from '@Components/reports/reports-helpers';
import { reportingFormat } from '@Utils/format';
import { Panel } from '@Components/ui/styled/main';

class PrePaymentsReport extends Component {
  render() {
    const { reportsViewState } = this.props;
    const prePayments = reportsViewState.get('prePayments');
    const showReport = prePayments && !prePayments.isEmpty() && !prePayments.get('rows').isEmpty();

    return (
      <div>
        {showReport ? this.renderTable(prePayments.toJS()) : this.renderNoData()}
      </div>
    );
  }

  renderTable({ rows }) {
    return (
      <Panel>
        <table className="table table-condensed table-report">
          <thead>
            <tr>
              <th>Datum</th>
              <th>Källa</th>
              <th>Referens</th>
              <th>Kund</th>
              <th>Resurs</th>
              <th className="text-right">Belopp</th>
            </tr>
          </thead>
          <tbody>
            {rows.map(({
              id, transactionDate, resourceName, customerName, amount,
              source, providerRef
            }) => (
              <tr key={id}>
                <td>{moment(transactionDate).format('L')}</td>
                <td>{source}</td>
                <td>{providerRef}</td>
                <td>{resourceName}</td>
                <td>{customerName}</td>
                <td className="text-right">{reportingFormat(amount)}</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <th>Summa</th>
              <th />
              <th />
              <th />
              <th />
              <th className="text-right">{reportingFormat(getTotal(rows, 'amount'))}</th>
            </tr>
          </tfoot>
        </table>
      </Panel>
    );
  }

  renderNoData() {
    return (
      <div className="alert alert-info">
        <i className="fa fa-info-circle" /> Det finns inga förbetalningar för vald period.
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { reportsViewState } = state;

  return {
    reportsViewState,
    reportsQuery: reportsQuerySelector(state, ownProps)
  };
};

export default connect(mapStateToProps)(PrePaymentsReport);
