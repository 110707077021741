import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import EventCommentPanel from '@Components/customers/customer-detail/logs/event-comment-panel';
import CustomerDetailLog from '@Components/customers/customer-detail/logs/customer-detail-log';

const CustomerDetailLogActions = ({
  isSelected, customerId, id, type, comment, files, createdTs, commenter,
  onUpdate, onDelete, allowFiles
}) => {
  const dispatch = useDispatch();

  const onUpdateComment = (comment, files) => dispatch(onUpdate(customerId, id, comment, files));
  const onDeleteComment = () => dispatch(onDelete(customerId, id));

  return (
    <EventCommentPanel
      isSelected={isSelected}
      comment={comment}
      files={files}
      onSave={onUpdateComment}
      onRemove={onDeleteComment}
      disabled={type === 'Sms'}
      allowFiles={allowFiles}
    >
      <CustomerDetailLog
        key={id}
        created={createdTs}
        comment={comment}
        commenter={commenter}
        files={files}
        type={type}
      />
    </EventCommentPanel>
  );
};

CustomerDetailLogActions.propTypes = {
  comment: PropTypes.string,
  created: PropTypes.string
};

export default CustomerDetailLogActions;
