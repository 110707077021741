import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getSysadminStateOrganizationById, getSysadminStateSelectedOrganization } from './accounts-selector';
import AccountsContext from './accounts-context';

export function useFetchAccount(fetchMethod) {
  const { routeParams } = useContext(AccountsContext);
  const { id } = routeParams;
  const [loading, setLoading] = useState(true);
  const selectedOrg = useSelector(state => getSysadminStateSelectedOrganization(state, id));
  const subscription = useSelector(state => getSysadminStateOrganizationById(state));

  useEffect(() => {
    setLoading(true);
    (async function fetchOrganization() {
      if (selectedOrg && (selectedOrg.get('id') !== +id || !subscription || id)) {
        const orgId = selectedOrg.get('id') || id;
        setLoading(true);
        if (fetchMethod) {
          await fetchMethod(orgId);
        }
        setLoading(false);
      }
    }());
  }, [id]);

  return [id, loading];
}
