import React, { useContext, useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { sortEventByTimeAndReverse } from '@Utils/customer-util';
import { deleteOrgComment, fetchOrgLogEntries, saveOrgComment, updateOrgComment } from '@State/sysadmin-actions';
import CommentBox from '@Components/customers/customer-detail/logs/comment-box';
import CustomerDetailLogActions from '@Components/customers/customer-detail/logs/customer-detail-log-actions';
import Loader from '@Components/ui/loader';
import { Row } from '@Components/ui/styled/main';
import { LogWrapper } from '@Components/customers/customer-styles';
import AccountsContext from '../accounts-context';

const LogsContainer = styled.div`
  margin: 10px 20px;
`;

const AccountLogs = () => {
  const dispatch = useDispatch();
  const { routeParams } = useContext(AccountsContext);
  const { id: orgId } = routeParams;

  const state = useSelector((state) => state.sysadminLogEntries);
  const logs = useMemo(() => sortEventByTimeAndReverse(state.get('logs').toJS()), [state]);
  const loading = useMemo(() => state.get('loading'), [state]);

  const [selectedLog, setSelectedLog] = useState(null);

  const onSaveComment = (comment) => dispatch(saveOrgComment(orgId, comment.trim()));
  const handleSelect = (id) => setSelectedLog(logs?.find((log) => log.id === id));

  useEffect(() => {
    dispatch(fetchOrgLogEntries(orgId));
  }, [orgId]);

  return (
    <Row scrollContainer>
      <LogWrapper>
        <CommentBox
          newComment
          comment=""
          onSave={onSaveComment}
        />
        {loading && <Loader topPosition />}
        {logs.length > 0 && (
          <LogsContainer>
            {logs.map(({ id, ...log }) => {
              return (
                <CustomerDetailLogActions
                  key={id}
                  isSelected={id === selectedLog?.id}
                  onClick={() => handleSelect(id)}
                  onUpdate={updateOrgComment}
                  onDelete={deleteOrgComment}
                  customerId={orgId}
                  id={id}
                  {...log}
                />
              );
            })}
          </LogsContainer>
        )}
      </LogWrapper>
    </Row>
  );
};

export default AccountLogs;
