import PropTypes from 'prop-types';
import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import shallowEqual from 'fbjs/lib/shallowEqual';
import { isEmbeddedInApp } from '@Utils/embedded-util';
import { getColumnsSelector, getGridProps } from './grid-selectors';
import ChipColumn from './chip-column';
import GridScroller from './grid-scroller';

const stylesHeightFull = { height: '100%' };
const stylesChip = {
  zIndex: 1,
  height: '100%',
  background: 'transparent'
};

class Chips extends Component {
  static propTypes = {
    columns: PropTypes.array.isRequired,
    gridProps: PropTypes.object.isRequired,
    routeParams: PropTypes.object.isRequired,
    schedulesByResource: PropTypes.object.isRequired,
    bookingsById: PropTypes.object.isRequired,
    todayInView: PropTypes.bool.isRequired
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.scrollOnBookingRefresh = true;
    new GridScroller(this.props.gridProps).scrollToTime(9, 0, false);
  }

  componentDidUpdate(prevProps) {
    const {
      schedulesByResource, bookingsById, gridProps, routeParams, highlightResourceIds
    } = this.props;

    const { viewDate: pViewDate, viewMode: pViewMode } = prevProps.routeParams;
    const { viewDate, viewMode } = routeParams;

    if (!shallowEqual(this.props.routeParams, prevProps.routeParams) && !(viewMode === pViewMode && viewDate === pViewDate)) {
      this.scrollOnBookingRefresh = true;
    }

    // Only scroll if new route, and after schedules&bookings arrive
    //
    if (this.scrollOnBookingRefresh
      && (
        prevProps.schedulesByResource !== schedulesByResource
        || prevProps.bookingsById !== bookingsById
      )
    ) {
      new GridScroller(gridProps, schedulesByResource, bookingsById, routeParams).scrollToFirstElement(true);
      this.scrollOnBookingRefresh = false;
    }

    if (prevProps.bookingsById !== bookingsById || prevProps.highlightResourceIds !== highlightResourceIds) {
      setTimeout(() => ReactTooltip.rebuild(), 100);
    }
  }

  render() {
    return (
      <div
        id="eventOwner"
        ref="eventOwner"
        style={stylesChip}
      >
        {this.renderChips()}
      </div>
    );
  }

  renderChips() {
    const { columns, gridProps } = this.props;

    return (
      <div style={stylesHeightFull}>
        {columns.map((column, i) => {
          return (
            <ChipColumn
              key={column.id}
              gridProps={gridProps}
              columnIndex={i}
              column={column}
              routeParams={this.props.routeParams}
            />
          );
        })}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const columns = getColumnsSelector(state, ownProps);
  const gridProps = getGridProps(state, ownProps);
  const { viewDateStartEnd } = gridProps;
  const todayInView = moment().isBetween(viewDateStartEnd.start, viewDateStartEnd.end, 'day', '[)');

  return {
    columns,
    gridProps,
    schedulesByResource: state.schedulesByResource,
    bookingsById: state.bookingsById,
    highlightResourceIds: state.gridViewState.get('highlightResourceIds'),
    embeddedInApp: isEmbeddedInApp(state),
    todayInView
  };
};

export default connect(
  mapStateToProps
)(Chips);
