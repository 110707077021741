import React from 'react';
import Grid, { GridCell, GridRow } from '@Components/ui/styled/grid';
import FormGroup from '@Components/ui/styled/form-group';
import { getDate } from '@Utils/time-util';
import CurrencyUtil from '@Utils/currency-util';
import { Button } from '@Components/ui/styled/main';

const AccountInvoiceHeader = ({ handleAddNewInvoice, invoice }) => {
  const totalAmount = invoice.get('items')?.toJS()?.reduce((prevVal, nextVal) => {
    return prevVal + nextVal.price;
  }, 0);

  const onClick = () => handleAddNewInvoice(invoice.get('uuid'));

  return (
    <Grid>
      <GridRow columns={5}>
        <FormGroup
          labelText="Period"
          text={invoice.get('periodKey')}
        />
        <FormGroup
          labelText="Created"
          text={invoice.get('createdTime') ? getDate(invoice.get('createdTime')) : '-'}
        />
        <FormGroup
          labelText="Invoice date"
          text={invoice.get('invoiceDate')}
        />
        <FormGroup
          labelText="Fortnox invoice"
          text={invoice.get('fortnoxInvoiceId')}
        />
        <div className="text-right">
          {invoice.get('status') !== 'Paid' && (
            <Button primary small onClick={onClick}>
              Add invoice row
            </Button>
          )}
        </div>
      </GridRow>
      <GridRow columns={5}>
        <FormGroup
          labelText="Total amount"
          text={CurrencyUtil.currency(totalAmount, 2)}
        />
        <FormGroup
          labelText="Status"
          text={invoice.get('status')}
        />
        <FormGroup
          labelText="Paid date"
          text={invoice.get('paidTime') ? getDate(invoice.get('paidTime')) : '-'}
        />
        <FormGroup
          labelText="Payment source"
          text={invoice.get('paymentSource') || '-'}
        />
      </GridRow>
    </Grid>
  );
};
export default AccountInvoiceHeader;
