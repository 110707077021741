import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getServiceColors } from '@State/calendar-selectors';
import { txt } from '@Utils/i18n-util';
import BookingServiceItem from '../booking/booking-service-item';
import msg from './find-time-service.msg';

class FindTimeService extends Component {
  handleClick = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    this.props.onSearchStart();
  };

  render() {
    const { services, serviceColors } = this.props;
    const hasServices = services && services.size > 0;

    if (!hasServices) {
      return (
        <button className="booking-form-block button" onClick={this.props.onSearchStart}>
          <i className="fa fa-cog" /> {txt(msg.btnSelectService)}
        </button>
      );
    }

    return (
      <div className="booking-form-block" onClick={this.handleClick}>
        <div className="block-buttons">
          <button className="btn-label" tabIndex={-1}>{txt(msg.btnChange)}</button>
        </div>
        <div className="service-list">
          {services.valueSeq().map(service => {
            return (
              <BookingServiceItem
                key={service.id}
                serviceColors={serviceColors}
                {...service}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { findTime } = state;

  return {
    services: findTime.get('services'),
    serviceColors: getServiceColors(state)
  };
};

export default connect(mapStateToProps)(FindTimeService);
